import React, { useEffect } from 'react';
import AlertModal from '../../utils/modal/Modal';
import { Translation } from 'react-i18next';
import i18n from '../../i18n/i18n';

import EditIcon from '../../assets/options-modal/icon_edit.svg';
import FollowIcon from '../../assets/options-modal/icon_follow.svg';
import MessageIcon from '../../assets/options-modal/icon_message.svg';
import ShareAsMessageIcon from '../../assets/options-modal/icon_share_as_message.svg';
import ShareUrlIcon from '../../assets/options-modal/icon_share_url.svg';
import ShareIcon from '../../assets/options-modal/icon_share.svg';
import TrashIcon from '../../assets/options-modal/icon_trash.svg';
import WarningIcon from '../../assets/options-modal/icon_warning.svg';
import CloseIcon from '../../assets/options-modal/icon_close.svg';
import CopyIcon from '../../assets/options-modal/icon_copy.svg';
import QuitMemberIcon from '../../assets/options-modal/icon_quit_member.svg';
import GiveLeadershipIcon from '../../assets/options-modal/icon_give_leadership.svg';

import './optionsModal.css';

const OptionModal = (props) => {
  const { isOpen, onRequestClose, menuTitle, show, darkMode } = props;

  const Options = ({ onClick, darkMode, title, img, showOption, show }) => {
    if (!showOption) {
      return null;
    }
    return (
      <li
        className='OptionModal__Options'
        onClick={onClick}
      >
        <p className='OptionModal__Options-p'>{title}</p>
        <img src={img} alt='' className='OptionModal__Options-img' />
      </li>
    );
  };

  useEffect(() => {
    window.addEventListener('keydown', (e) => handleKeyDown(e));
    return () => {
      window.removeEventListener('keydown', (e) => handleKeyDown(e));
    }
    // eslint-disable-next-line
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      onRequestClose();
    }
  }

  const Opts = [
    {
      showOption: show?.message?.display ?? false,
      title: show?.message?.title ?? i18n.t('OPTIONS_MODAL.sendMsg'),
      onClick: show?.message?.onClick ?? null,
      img: darkMode ? MessageIcon : MessageIcon,
    },
    {
      showOption: show?.follow?.display ?? false,
      title: show?.follow?.title ?? i18n.t('OPTIONS_MODAL.follow'),
      onClick: show?.follow?.onClick ?? null,
      img: darkMode ? FollowIcon : FollowIcon,
    },
    {
      showOption: show?.edit?.display ?? false,
      title: show?.edit?.title ?? i18n.t('OPTIONS_MODAL.edit'),
      onClick: show?.edit?.onClick ?? null,
      img: darkMode ? EditIcon : EditIcon,
    },
    {
      showOption: show?.share?.display ?? false,
      title: show?.share?.title ?? i18n.t('OPTIONS_MODAL.share'),
      onClick: show?.share?.onClick ?? null,
      img: darkMode ? ShareIcon : ShareIcon,
    },
    {
      showOption: show?.shareUrl?.display ?? false,
      title: show?.shareUrl?.title ?? i18n.t('OPTIONS_MODAL.shareUrl'),
      onClick: show?.shareUrl?.onClick ?? null,
      img: darkMode ? ShareUrlIcon : ShareUrlIcon,
    },
    {
      showOption: show?.shareAsMsg?.display ?? false,
      title: show?.shareAsMsg?.title ?? i18n.t('OPTIONS_MODAL.shareAsMsg'),
      onClick: show?.shareAsMsg?.onClick ?? null,
      img: darkMode ? ShareAsMessageIcon : ShareAsMessageIcon,
    },
    {
      showOption: show?.copyMsg?.display ?? false,
      title: show?.copyMsg?.title ?? i18n.t('MESSAGES.copy'),
      onClick: show?.copyMsg?.onClick ?? null,
      img: darkMode ? CopyIcon : CopyIcon,
    },
    {
      showOption: show?.delete?.display ?? false,
      title: show?.delete?.title ?? i18n.t('OPTIONS_MODAL.delete'),
      onClick: show?.delete?.onClick ?? null,
      img: darkMode ? TrashIcon : TrashIcon,
    },
    {
      showOption: show?.removeMsg?.display ?? false,
      title: show?.removeMsg?.title ?? i18n.t('MESSAGES.remove'),
      onClick: show?.removeMsg?.onClick ?? null,
      img: darkMode ? TrashIcon : TrashIcon,
    },
    {
      showOption: show?.removeAllMsg?.display ?? false,
      title: show?.removeAllMsg?.title ?? i18n.t('MESSAGES.removeForAll'),
      onClick: show?.removeAllMsg?.onClick ?? null,
      img: darkMode ? TrashIcon : TrashIcon,
    },
    {
      showOption: show?.report?.display ?? false,
      title: show?.report?.title ?? i18n.t('OPTIONS_MODAL.report'),
      onClick: show?.report?.onClick ?? null,
      img: darkMode ? WarningIcon : WarningIcon,
    },
    {
      showOption: show?.block?.display ?? false,
      title: show?.block?.title ?? i18n.t('OPTIONS_MODAL.report'),
      onClick: show?.block?.onClick ?? null,
      img: show?.block?.image ?? (darkMode ? WarningIcon : WarningIcon),
    },
    {
      showOption: show?.giveLeadership?.display ?? false,
      title: show?.giveLeadership?.title ?? 'Give Leadership',
      onClick: show?.giveLeadership?.onClick ?? null,
      img: darkMode ? GiveLeadershipIcon : GiveLeadershipIcon,
    },
    {
      showOption: show?.quitMember?.display ?? false,
      title: show?.quitMember?.title ?? 'Quit member',
      onClick: show?.quitMember?.onClick ?? null,
      img: darkMode ? QuitMemberIcon : QuitMemberIcon,
    },
  ];

  return (
    <Translation>
      {(t) => (
        <AlertModal
          isOpen={isOpen}
          outsideClick={onRequestClose}
          overlayClassName='OptionMenu__Overlay'
          modalClassName='OptionMenu__Container'
        >
          <>
            <p className='OptionModal__Title'>{menuTitle ?? t('OPTIONS_MODAL.title')}</p>
            <ul className='OptionModal__List'>
              {Opts.map((item, index) => (
                <Options {...item} key={index} />
              ))}
              <Options
                showOption
                title={t('OPTIONS_MODAL.closeBtn')}
                img={darkMode ? CloseIcon : CloseIcon}
                onClick={onRequestClose}
              />
            </ul>
          </>
        </AlertModal>
      )}
    </Translation>
  );
};

export default OptionModal;
