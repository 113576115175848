import React from 'react';
import RegularInput from '../../utils/app-inputs/regular-input/regular-input';
import SendButton from '../../utils/send-button/send-button';
import { emailsRegex } from '../../utils/regex';
import { sendRestorePasswordEmail } from './restorePasswordActions';
import './restore-password.css';
import i18n from '../../i18n/i18n';
import Alert from '../../utils/alert/Alert';
import { Translation } from 'react-i18next';
import { AppLoader } from '../../utils/animations/animations';

class RestorePassword extends React.Component {
  state = {
    loading: false,
    email: '',
  };

  sendEmail = (event) => {
    event.preventDefault();
    const { email } = this.state;
    if (email && email.match(emailsRegex)) {
      this.setState({ loading: true }, async () => {
        try {
          const response = await sendRestorePasswordEmail(email);
          this.showModal(true, 'success', response.body);
          // console.log('SendEmail:', response);
          this.setState({ loading: false });
        } catch (e) {
          // console.log("sendEmail / e: ", e);
          this.setState({ loading: false });
          const error = e && e.data && e.data.error ? e.data.error : null;
          const errorMessage =
            error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : error && error.message
              ? error.message
              : i18n.t('GENERAL_ERRORS.serverError');
          // this.toggleMessageModal('error', true, errorMessage)
          this.showModal(true, 'error', errorMessage);
          // console.log('SendEmail:', errorMessage);
        }
      });
    } else {
      if (!email) {
        this.showModal(true, 'warning', i18n.t('GENERAL_ERRORS.missingEmail'));
        return false;
      }
      if (email && !email.match(emailsRegex)) {
        this.showModal(true, 'warning', i18n.t('GENERAL_ERRORS.invalidEmail'));
        return false;
      }
      this.showModal(true, 'warning', i18n.t('GENERAL_ERRORS.missingFields'));
    }
  };

  showModal = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  render() {
    const { email } = this.state;
    return (
      <Translation>
        {(t) => (
          <div className='RestorePassword__Container'>
            <form
              className='RestorePassword__Form'
              onSubmit={(event) => this.sendEmail(event)}
            >
              <h1 className='RestorePassword__Title'>
                {t('RESTORE_PASSWORD.title')}
              </h1>
              <RegularInput
                name='email'
                placeholder='E-mail'
                value={email}
                isWhite
                onChange={this.handleInputChange}
              />
              <p>{t('RESTORE_PASSWORD.text')}</p>
              <SendButton
                className='RestorePassword__Button'
                textContent={t('RESTORE_PASSWORD.button')}
                disabled={this.state.loading}
                renderDisabled={
                  <AppLoader size='small' />
                }
                style={{
                  pointerEvents: this.state.loading ? 'none' : 'unset',
                }}
              />
            </form>
            <Alert
              isOpen={this.state.isAlertOpen}
              content={this.state.alertContent}
              type={this.state.alertType}
              onRequestClose={() =>
                this.setState({
                  isAlertOpen: false,
                })
              }
            />
          </div>
        )}
      </Translation>
    );
  }
}

export default RestorePassword;
