import React from 'react';
import { makeAchievementSeen } from '../../pages/dashboard/dashboard-actions';
import Aplause from '../../assets/achievement/aplause.wav';
import AchievementIconDark from '../../assets/achievement/achievement_ribbon_dark.svg';
import AchievementIcon from '../../assets/achievement/achievement_ribbon.svg';
import AlertModal from '../../utils/modal/Modal';
import { useTranslation } from 'react-i18next';
import * as colors from '../../utils/color-palatte';
import './achievementModal.css';
import AchievementItem from '../achievemen-item/achievementItem';
import { AppLoader } from '../../utils/animations/animations';

const NewAchievementModal = (props) => {
  const { newAchievement, isOpen, onRequestClose, darkMode, user } = props;

  const [loading, setLoading] = React.useState(false);
  const [isSmall, setIsSmall] = React.useState(false)

  const makeAchievementSeenFunc = async () => {
    setLoading(true);
    makeAchievementSeen(newAchievement.id);
    setLoading(false);
  };

  const rvlRef = React.useRef();
  let observer = null;

  React.useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line
      observer = new ResizeObserver((entries) => {
        const boxElem = entries[0]
        if (boxElem.contentRect.width < 550 ) {
          setIsSmall(true)
        } else {
          setIsSmall(false)
        }
      });
      if (observer) {
        observer.observe(rvlRef.current);
      }
      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }
    // eslint-disable-next-line
  }, []);

  const { t } = useTranslation();

  return (
    <AlertModal
      isOpen={isOpen}
      overlayClassName='NewChievModal__Overlay'
      modalClassName='NewChievModal__Container'
      style={{ backgroundColor: darkMode ? colors.UNSATURATED_BLACK_BLUE : colors.WHITE_MAIN }}
      useConffeti={true}
      outsideClicl={onRequestClose}
      ref={rvlRef}
      numberOfPieces={isSmall ? 150 : 300}
    >
      <div className='NewChievModal__Title' style={{ borderBottom: `1px solid ${darkMode ? colors.WHITE_MAIN : '#343434'}` }}>
        <img src={darkMode ? AchievementIcon : AchievementIconDark} alt='' />
        <h1 style={{ color: darkMode ? colors.WHITE_MAIN : '#343434' }}>{t('ACHIEVEMENTS.newAchievement')}</h1>
        <img src={darkMode ? AchievementIcon : AchievementIconDark} alt='' />
      </div>
      <div className='NewChievModal__Content'>
        <AchievementItem user={user} item={newAchievement} darkMode={darkMode} />
        <button className='NewChievModal__Button' onClick={() => makeAchievementSeenFunc()}>
          {loading ? <AppLoader size='small' color={colors.WHITE_MAIN} /> : t('continue')}
        </button>
        <audio
          // controls
          autoPlay
        >
          <source src={Aplause} type='audio/wav' />
        </audio>
      </div>
    </AlertModal>
  );
};

export default NewAchievementModal;
