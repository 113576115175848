import { postData } from '../../fetch';

export const sendRestorePasswordEmail = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(
        `/user/send-change-password-email-web/${email}`
      );
      // console.log('sendRestorePassword response:', response)
      resolve(response);
    } catch (e) {
      reject(e);
      // console.log('SendRestorePassword:', e)
    }
  });
};
