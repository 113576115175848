import React from 'react';
import { createContact } from './contactUsActions';
import Input from '../../utils/input/input';
import TextArea from '../../utils/textArea/textArea';
import SendButton from '../../utils/send-button/send-button';
import Facebook from '../../assets/contact-us/Facebook.svg';
import Instagram from '../../assets/contact-us/Instagram.svg';
// import Youtube from '../../assets/contact-us/youtube.svg';
import Twitter from '../../assets/contact-us/Twitter.svg';
import { AppLoader } from '../../utils/animations/animations';
import { Translation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import SafeURL from '../../utils/safe-url';
import { connect } from 'react-redux';
import './contact-us.css';
import Alert from '../../utils/alert/Alert';
import { WHITE_MAIN, WARNING_RED, FEDERAL_BLUE_SOFT } from '../../utils/color-palatte';
import { COMPANY_FACEBOOK, COMPANY_IG, COMPANY_TWITTER } from '../../config';
import WarningIcon from '../../assets/contact-us/warning_icon.svg';
import { withRouter } from 'react-router-dom';

function NetWorkMap({ linkTo, target, networkImage }) {
  return (
    <SafeURL
      dangerousURL={linkTo}
      newTab={target}
      text={<img src={networkImage} alt='socialmedia' style={{ width: '50px', cursor: 'pointer', margin: 20 }} />}
    />
  );
}

class ContactUs extends React.Component {
  state = {
    message: '',
    isLoading: false,
  };

  /**
   * https://stackoverflow.com/questions/44936028/progress-bar-with-axios
   */

  onContact = (event) => {
    event.preventDefault();
    const { message } = this.state;
    const { user } = this.props;
    if (message) {
      this.setState({ isLoading: true }, async () => {
        try {
          const data = {
            username: user.username,
            message: message,
            email: user.email,
          };
          await createContact(data);
          // console.log("onContact / response: ", response);
          setTimeout(() => {
            this.showAlert(true, i18n.t('FAQ.CONTACT_US.messageSent'), 'success');
            this.setState({
              isLoading: false,
              username: '',
              email: '',
              message: '',
            });
          }, 1000);
        } catch (e) {
          // console.log("onContact / error: ", e);
          const error =
            e && e.data && e.data.error && e.data.error.message ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, error, 'wrong', () => this.setState({ onCloseModal: false }));
          this.setState({ isLoading: false });
        }
      });
    } else {
      if (!message) {
        this.showAlert(true, i18n.t('FAQ.CONTACT_US.messageIsRequired'), 'warning');
      }
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  // componentDidMount() {
  //   this.showAlert(true, undefined, 'send');
  // }

  showAlert = (isOpen, content, type) => {
    this.setState({
      alertType: type,
      alertContent: content,
      isAlertOpen: isOpen,
    });
  };

  render() {
    const Items = [
      {
        linkTo: COMPANY_TWITTER,
        target: true,
        networkImage: Twitter,
      },
      {
        linkTo: COMPANY_IG,
        target: true,
        networkImage: Instagram,
      },
      {
        linkTo: COMPANY_FACEBOOK,
        target: true,
        networkImage: Facebook,
      },
      // {
      //   linkTo: 'https://youtube.com',
      //   target: true,
      //   networkImage: Youtube,
      // },
    ];

    const { message, isLoading } = this.state;
    const { user, darkMode } = this.props;

    return (
      <Translation>
        {(t) => (
          <div className='ContactUs__Container'>
            <h1 className='ContactUs__h1' style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE_SOFT }}>
              {t('FAQ.CONTACT_US.title')}
            </h1>
            <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE_SOFT }} className='ContactUs__First-p'>
              {t('FAQ.CONTACT_US.dialogue')}:
            </p>
            <form className='ContactUs__Form' noValidate onSubmit={(e) => this.onContact(e, t)}>
              <Input
                placeholder={t('FAQ.CONTACT_US.name')}
                type='text'
                width='100%'
                // className={`${firstInputContainer1}`}
                value={user ? user?.username : ''}
                onChange={this.handleInputChange}
                disabled={isLoading}
                style={{ pointerEvents: 'none' }}
                borderColor={darkMode ? WHITE_MAIN : null}
                darkMode={darkMode}
              />
              <Input
                placeholder={t('FAQ.CONTACT_US.email')}
                type='text'
                // className={`${firstInputContainer2}`}
                width='100%'
                value={user ? user.email : ''}
                showErrors={this.state.showInputsErrorContact}
                onChange={this.handleInputChange}
                disabled={isLoading}
                isRequired
                style={{ pointerEvents: 'none' }}
                borderColor={darkMode ? WHITE_MAIN : null}
                darkMode={darkMode}
              />
              <TextArea
                rows='4'
                cols='15'
                placeholder={t('FAQ.CONTACT_US.description')}
                name='message'
                showErrors={this.state.showInputsErrorContact}
                className='MsgBox'
                onChange={user ? this.handleInputChange : null}
                value={message}
                disabled={isLoading}
                borderColor={darkMode ? WHITE_MAIN : null}
                styles={{ pointerEvents: user ? 'auto' : 'none' }}
                isRequired
                darkMode={darkMode}
              />
              {!user && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexFlow: 'row nowrap',
                    backgroundColor: '#0008',
                    width: 'fit-content',
                    borderRadius: '20px',
                    margin: '0 auto',
                    marginTop: 10,
                    padding: '8px 20px',
                    boxSizing: 'border-box',
                  }}
                >
                  <img src={WarningIcon} alt='warning' style={{ width: '25px', marginRight: 10 }} />
                  <p style={{ color: WARNING_RED, fontSize: '1.2rem', margin: 0 }}>{t('FAQ.CONTACT_US.warningInfo')}</p>
                </div>
              )}
              {user && (
                <SendButton
                  disabled={isLoading}
                  renderDisabled={<AppLoader size='40px' color={WHITE_MAIN} />}
                  textContent={t('FAQ.CONTACT_US.send')}
                  style={{ marginTop: '20px' }}
                />
              )}
            </form>
            <h1 className='ContactUs__h1' style={{ margin: '10px 0' }}>
              {t('FAQ.CONTACT_US.socialMedia')}
            </h1>
            <section className='ContactUs__Network-flex'>
              {Items.map((item) => (
                <NetWorkMap {...item} />
              ))}
            </section>
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              // success={this.state.alertSuccess}
              // warning={this.state.alertWarning}
              type={this.state.alertType}
              // progress={this.state.progress}
            />
          </div>
        )}
      </Translation>
    );
  }
}

const ContactUsWithRouter = withRouter(ContactUs);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsWithRouter);
