import React from 'react';
import './style.css';
import { connect } from 'react-redux';
import * as colors from '../../utils/color-palatte';
import { isUserValid } from '../../utils/isUserValid';
import { cancelPayment, getPaymentDetails } from '../user-payments/user-payments-actions';
import Alert from '../../utils/alert/Alert';
import PaymentItem from '../../components/payments/PaymentItem';
import { AppLoader } from '../../utils/animations/animations';
import i18n from '../../i18n/i18n';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';

class PaymentDetails extends React.Component {
  state = {
    fetching: true,
    payment: null,
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(true, this._isMounted);
    if (this._isMounted) this.fetchPayment();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  fetchPayment = () => {
    return new Promise(async (resolve, reject) => {
      const { payment } = this.props.match.params;
      try {
        const response = await getPaymentDetails(payment);
        // console.log("fetchPayment / response: ", response);
        this.setState({
          fetching: false,
          payment: response[0],
        });
        resolve();
      } catch (e) {
        if (e.status === 404) {
          this.setState({ fetching: false });
          resolve();
        } else {
          reject(e);
          // console.log("fetchPayment / error: ", e)
          this.setState({ fetching: false });
          // const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
          // this.showAlert(true, 'wrong', '', error);
        }
      }
    });
  };

  cancelPaymentFunc = (paymentId) => {
    return new Promise(async (resolve, reject) => {
      const { cancelLoading } = this.state;
      if (!cancelLoading) {
        this.setState({ cancelLoading: true }, async () => {
          try {
            await cancelPayment(paymentId);
            this.fetchPayment(paymentId);
            resolve();
          } catch (e) {
            if (e.status === 404) {
              this.setState({ cancelLoading: false });
              resolve();
            } else {
              reject(e);
              // console.log("fetchPayment / error: ", e)
              this.setState({ cancelLoading: false });
              const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
              this.showAlert(true, 'error', error);
            }
          }
        });
      }
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalContent: content,
    });
  };

  render() {
    const { payment, fetching, cancelLoading } = this.state;
    const { user, darkMode } = this.props;
    // console.log('payment: ', payment);
    return (
      <div className='paymentDetailsContainer' style={{ width: '100%', maxWidth: 800, margin: 'auto' }}>
        {fetching && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 100px)',
            }}
          >
            <AppLoader color={darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE} size='big' />
          </div>
        )}
        {payment ? (
          <PaymentItem
            cancelLoading={cancelLoading}
            cancelPayment={this.cancelPaymentFunc}
            darkMode={darkMode}
            item={payment}
            fetching={fetching}
            isDetails={true}
            // onEndReached={this.onEndReached}
            onRefreshPosts={this.fetchPayment}
            // header={this.postsHeader}
            user={user}
            historyObj={this.props.history}
            // style={{ marginBottom: 150 }}
          />
        ) : null}
        {!payment && !fetching ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 100px)',
            }}
          >
            <NotFoundItem isBlue={darkMode} type='payment' text={i18n.t('PAYMENTS_LIST.noSearchResults')} />
          </div>
        ) : null}
        <Alert
          isOpen={this.state.isModalOpen}
          type={this.state.modalType}
          content={this.state.modalContent}
          onRequestClose={() => this.setState({ isModalOpen: false })}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
