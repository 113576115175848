import React from // useState
'react';
import { FEDERAL_BLUE } from '../../color-palatte';
import './checkbox.css';

const CheckBox = (props) => {
  const {
    className,
    id,
    isWhite,
    checkboxClassName,
    style,
    name,
    value,
    checked,
    disabled,
    onChange,
    onClick,
  } = props;

  // const [isChecked, setIsChecked] = useState(false);

  // const toggleChecked = () => {
  //   setIsChecked(!isChecked);
  // };

  return (
    <div className={`${className} CheckBox__Container`}>
      <input
        type='checkbox'
        id={id}
        className={`${
          isWhite ? 'CheckBox__White' : 'CheckBox'
        } ${checkboxClassName}`}
        style={Object.assign(
          {
            border: `2px solid ${isWhite ? 'white' : FEDERAL_BLUE}`,
            opacity: disabled ? '0.5' : undefined,
            cursor: disabled ? undefined : 'pointer',
          },
          style
        )}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
      />
    </div>
  );
};

export default CheckBox;
