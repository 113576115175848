import React from 'react';
import { withRouter } from 'react-router-dom';
import { isUserValid } from '../../utils/isUserValid';
import { getUserFollowers } from './FollowersActions';
import { reportUser } from '../profile/profileActions';
import { connect } from 'react-redux';
import moment from 'moment';
import i18n from '../../i18n/i18n';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import SearchInput from '../../utils/app-inputs/search-input/searchInput';
import UserItem from '../../components/user-item/userItem';
import './Followers.css';
import getStat from '../../utils/getStat';
import Alert from '../../utils/alert/Alert';
import { AppLoader } from '../../utils/animations/animations';
import { FEDERAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';
import { throttle } from 'lodash';

class Followers extends React.Component {
  state = {
    fetching: false,
    skipItems: 0,
    limitItems: 5,
    plusPostNumber: 5,
    keepIncreasingCounter: false,
    search: '',
    userFollowers: [],
  };

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    isUserValid(false, this._isMounted);
    if (this._isMounted) {
      this.onRefreshPosts();
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  fetchFollowers = async () => {
    const { skipItems, limitItems, fetching, search, userFollowers } = this.state;
    const { user } = this.props;
    const routeUserId = this.props.match.params.userId;
    const userId = routeUserId ? routeUserId : user._id;
    // console.log('called');
    if (!fetching) {
      this.setState({ fetching: true }, async () => {
        try {
          const followers = await getUserFollowers(userId, skipItems, limitItems, search);
          const positions = await getStat('positions');
          // console.log("getPosts / followers: ", followers);
          const finalFollowers = followers
            .concat(userFollowers)
            .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
            .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
          this.setState({
            userFollowers: finalFollowers.filter((user) => !user.block),
            fetching: false,
            keepIncreasingCounter: followers.length > 0,
            positions,
          });
        } catch (e) {
          if (e.status === 404) {
            // console.log(e)
            this.setState({ fetching: false });
          } else {
            // console.log("fetchFollowers / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        }
      });
    }
  };

  onRefreshPosts = () => {
    const { plusPostNumber } = this.state;
    this.setState({ skipItems: 0, limitItems: plusPostNumber, userFollowers: [] }, () => {
      this.fetchFollowers();
    });
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchFollowers();
        }
      );
    } else {
      this.fetchFollowers();
    }
  };

  report = (userId) => {
    const { user } = this.props;
    this.setState({ fetching: true }, async () => {
      try {
        const response = await reportUser(userId, user._id);
        this.showAlert(true, 'success', response);
        this.setState({ fetching: false, isPostMenuOpen: false });
      } catch (e) {
        // console.log("report / error: ", e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
        this.setState({ fetching: false });
      }
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  throtled = throttle(async () => {
    this.onRefreshPosts();
  }, 1000);

  handleInputChange = ({ target }) => {
    this.setState(
      {
        [target.name]: target.value,
      },
      () => this.throtled()
    );
  };

  render() {
    const { fetching, userFollowers, positions } = this.state;
    const { user, darkMode } = this.props;

    // console.log('userFollowers', userFollowers);
    return (
      <ScrollableDiv onEndReach={this.onEndReached} className='Followers__Container'>
        <div
          style={{
            width: '100%',
            marginBottom: '30px',
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SearchInput
            darkMode={darkMode}
            className='Followers__SearchInput'
            name='search'
            value={this.state.search}
            onChange={this.handleInputChange}
            placeholder={i18n.t('FOLLOWERS.searchPlaceholder')}
            autoComplete='off'
          />
        </div>
        {userFollowers?.length > 0
          ? userFollowers.map((item, i) => (
              <UserItem
                key={i}
                item={item}
                positions={positions}
                report={this.report}
                darkMode={darkMode}
                user={user}
                userId={user._id}
              />
            ))
          : null}
        <div
          style={{
            width: '100%',
            minHeight: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: darkMode ? WHITE_MAIN : '#000',
          }}
        >
          {fetching && <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />}
          {!fetching && userFollowers?.length <= 0 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NotFoundItem isBlue={darkMode} text={i18n.t('FOLLOWERS.citizenNotFound')} />
            </div>
          ) : null}
        </div>
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        />
      </ScrollableDiv>
    );
  }
}

const FollowersWithRouter = withRouter(Followers);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowersWithRouter);
