import moment from 'moment';
import i18n from '../i18n/i18n';
import React from 'react'
import { Trans } from 'react-i18next';

export const handlePostDate = (date) => {
  let dateToshow = '';
  let now = moment(new Date());
  let pastDate = moment(date);
  let durationBetweenDates = moment.duration(now.diff(pastDate));
  let days = Math.round(durationBetweenDates.asDays());
  let hours = Math.round(durationBetweenDates.asHours());
  let minutes = Math.round(durationBetweenDates.asMinutes());
  let seconds = Math.round(durationBetweenDates.asSeconds());
  const showDaysAgo = days < 7;
  const showHoursAgo = hours < 24;
  const showMinutesAgo = minutes < 60;
  const showNow = seconds < 60;
  // console.log("now: ", now);
  // console.log("pastDate: ", pastDate);
  // console.log("durationBetweenDates: ", durationBetweenDates);
  // console.log("days: ", days);
  // console.log("hours: ", hours);
  // console.log("minutes: ", minutes);

  if (
    moment(date).year() === moment().year() &&
    !showDaysAgo &&
    !showHoursAgo &&
    !showMinutesAgo &&
    !showNow
  ) {
    dateToshow = moment(date).format('MM-DD hh:mm A');
  }

  if (
    moment(date).year() !== moment().year() &&
    !showDaysAgo &&
    !showHoursAgo &&
    !showMinutesAgo &&
    !showNow
  ) {
    dateToshow = moment(date).format('MM-DD-YYYY hh:mm A');
  }

  if (showDaysAgo) {
    // dateToshow = i18n.t('POSTS.daysAgo')
    dateToshow = <Trans i18nKey="POSTS.daysAgo">bla {{days}} bla</Trans>
  }

  if (showHoursAgo) {
    // dateToshow = i18n.t('POSTS.hoursAgo')(hours);
    dateToshow = <Trans i18nKey="POSTS.hoursAgo">bla {{hours}} bla</Trans>
  }

  if (showMinutesAgo) {
    // dateToshow = i18n.t('POSTS.minutesAgo')(minutes);
    dateToshow = <Trans i18nKey="POSTS.minutesAgo">bla {{minutes}} bla</Trans>
  }

  if (showNow) {
    dateToshow = i18n.t('POSTS.justNow');
  }

  // console.log("dateToshow: ", dateToshow);

  return dateToshow;
};
