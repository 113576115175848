import React from 'react';
import { Title, ParsedParagraph, Banner, Divider, Paragraph, ListElement } from '../utils/utils';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../utils/styles.module.css';
import PositionsBanner from '../../../assets/whitepaper/positions.svg';
import PresidentImg from '../../../assets/characters/president-hand-up.svg';
import VicepresidentImg from '../../../assets/characters/vicepresident-hand-up.svg';
import * as colors from '../../../utils/color-palatte';
import Alert from '../../../utils/alert/Alert';

const Positions = (props) => {
  const { darkMode, outer, presSalary, vicePresSalary, presidentSalary, vicepresidentSalary } = props;
  const { t } = useTranslation();
  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 1000px)').matches);
  const [secondMatches, setSecondMatches] = React.useState(window.matchMedia('(max-width: 450px)').matches);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  const location = useLocation();

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const secondHandler = (e) => setSecondMatches(e.matches);
    window.matchMedia('(max-width: 1000px)').addEventListener('change', handler);
    window.matchMedia('(max-width: 450px)').addEventListener('change', secondHandler);
    return () => {
      window.matchMedia('(max-width: 1000px)').removeEventListener('change', handler);
      window.matchMedia('(max-width: 450px)').removeEventListener('change', secondHandler);
    };
  }, []);

  const copyUrl = (hash) => {
    if (hash) {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}#${hash}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    } else {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    }
  };

  // const sendToHash = (hash) => {
  //   if (hash) {
  //     history.push(`${location.pathname}#${hash}`);
  //   } else {
  //     history.push(location.pathname);
  //   }
  // };

  const showAlert = (isOpen, type, content) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  const values = [
    {
      keyword: `${presSalary ? presSalary : '...'}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${vicePresSalary ? vicePresSalary : '...'}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${presidentSalary ? presidentSalary : '...'}$`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${vicepresidentSalary ? vicepresidentSalary : '...'}$`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
  ];

  return (
    <div className={styles.Container}>
      <div
        style={{
          width: matches ? '100%' : 'calc(100% - 300px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: outer ? 0 : '70px',
        }}
      >
        <div className={styles.Content}>
          <Title text={t('WHITEPAPER.POSITIONS.title')} darkMode={darkMode} onClick={() => copyUrl()} />
          <Divider darkMode={darkMode} />
          <Banner src={PositionsBanner} />
          <Divider darkMode={darkMode} />
          <ParsedParagraph
            style={{ whiteSpace: 'pre-line' }}
            string={t('WHITEPAPER.POSITIONS.content')(presSalary, vicePresSalary)}
            darkMode={darkMode}
            hasWiki
            values={values}
          />
          <ListElement
            style={{ marginTop: '40px' }}
            img={PresidentImg}
            content={
              <>
                <Paragraph
                  text={t('WHITEPAPER.POSITIONS.POWERS.PRESIDENT.title')}
                  style={{ fontWeight: 'bold' }}
                  darkMode={darkMode}
                />
                <ParsedParagraph
                  style={{ whiteSpace: 'pre-line', boxSizing: 'border-box', paddingLeft: secondMatches ? '5px' : '20px' }}
                  darkMode={darkMode}
                  hasWiki
                  string={t('WHITEPAPER.POSITIONS.POWERS.PRESIDENT.content')(presSalary, presidentSalary)}
                  values={values}
                />
              </>
            }
          />
          <ListElement
            style={{ margin: '40px 0' }}
            img={VicepresidentImg}
            content={
              <>
                <Paragraph
                  text={t('WHITEPAPER.POSITIONS.POWERS.VICEPRESIDENT.title')}
                  style={{ fontWeight: 'bold' }}
                  darkMode={darkMode}
                />
                <ParsedParagraph
                  style={{ whiteSpace: 'pre-line', boxSizing: 'border-box', paddingLeft: secondMatches ? '5px' : '20px' }}
                  darkMode={darkMode}
                  hasWiki
                  string={t('WHITEPAPER.POSITIONS.POWERS.VICEPRESIDENT.content')(vicePresSalary, vicepresidentSalary)}
                  values={values}
                />
              </>
            }
          />
        </div>
      </div>
      <div className={styles.Wrapper} style={{ display: matches ? 'none' : 'flex', paddingTop: outer ? 0 : '70px' }}>
        {/* <ContentTitle text={t('WHITEPAPER.content')} darkMode={darkMode} />
        <Paragraph onClick={() => sendToHash()} darkMode={darkMode} style={{ margin: 0, cursor: 'pointer' }}>
          {t('WHITEPAPER.ECONOMY.title')}
        </Paragraph>
        <Paragraph onClick={() => sendToHash('contributions')} darkMode={darkMode} style={{ cursor: 'pointer' }}>
          {t('WHITEPAPER.ECONOMY.contributions')}
        </Paragraph> */}
      </div>
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ ...modal, isOpen: false, type: null, content: undefined })}
      />
    </div>
  );
};

export default Positions;
