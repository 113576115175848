export const ROOT_ROUTE = '/';
export const HOME_ROUTE = '/home';

// header Routes

export const FAQ_ROUTE = '/faq';
export const LOGIN_ROUTE = '/login';
export const SIGN_UP_ROUTE = '/sign-up';

// navbar Routes

export const DASHBOAR_ROUTE = '/dashboard';
export const FLOW_ROUTE = '/flow';
export const SEARCH_ROUTE = '/search';
export const VOTINGS_ROUTE = '/votings';
export const SERVICES_ROUTE = '/services';
export const COMMUNITIES_ROUTE = '/communities';
export const PROFILE_ROUTE = '/profile';
export const USER_PAYMENT_DETAILS = '/payment';
export const SETTINGS_ROUTE = '/settings';
export const PRYVACY_ROUTE = '/privacy';
export const SECURITY_ROUTE = '/security';
export const CREATE_POST_ROUTE = '/create-post';
export const MESSAGES_ROUTE = '/messages';
export const COMMUNITY_CREATE_ROUTE = '/create-community';

// no user Routes

export const POST_ROUTE = '/post';
export const WELCOME_ROUTE = '/welcome';
export const CHANGE_PASSWORD_ROUTE = '/change-password';
export const RESTORE_PASSWORD_ROUTE = '/restore-password';
export const PASSWORD_DONE_ROUTE = '/password-done';
export const SUCCES_MAIL_ROUTE = '/succes-mail';
export const MAIL_ERROR_ROUTE = '/mail-error';

// other Routes

export const INCOMES_ROUTE = '/incomes';
export const EXPENSES_ROUTE = '/expenses';
export const LAWS_LIST_ROUTE = '/laws';
export const LAWS_DETAILS_ROUTE = '/law';
export const DEALS_LIST_ROUTE = '/deals';
export const DEALS_DETAILS_ROUTE = '/deal';
export const DOWNLOAD_APP_ROUTE = '/download-app';
export const CREATE_VOTE_ROUTE = '/create-vote';
export const COMMENTS_ROUTE = '/comments';
export const COMMUNITY_POSTS_ROUTE = '/community-posts';
export const USER_FOLLOWERS_ROUTE = '/followers';
export const USER_FOLLOWING_ROUTE = '/following';
export const GLOBAL_GOVERNMENT_ROUTE = '/global-government';
export const NOTICE_OF_PRIVACY_ROUTE = '/privacy-policy';
export const TERMS_AND_CONDITIONS_ROUTE = '/terms-and-conditions';
export const USER_CHAT = '/chat';
export const VOTING_HISTORY = '/voting-history';
export const VOTING_LIST_ROUTE = '/voting-list';
export const VOTING_DETAILS_ROUTE = '/voting';
export const USER_PATMENTS_ROUTE = '/payments';
export const USER_FINANCE_ROUTE = '/user-finance';
export const GG_HIERARCHY_ROUTE = '/hierarchy';
export const GG_FINANCE_ROUTE = '/finance';
export const GG_INCOMES_LIST_ROUTE = '/incomes';
export const GG_INCOMES_DETAILS_ROUTE = '/income';
export const GG_EXPENSES_LIST_ROUTE = '/expenses';
export const GG_EXPENSES_DETAILS_ROUTE = '/expense';
export const COMMUNITY_PROFILE_ROUTE = '/community';
export const COMMUNITY_MEMBERS_ROUTE = '/community-members';
export const USER_NOTIFICATIONS_ROUTE = '/notifications';
export const USER_ACHIEVEMENTS_ROUTE = '/achievements';
export const GG_POSITIONS_HISTORY = '/position-history';
export const DELETE_YOU_ACCOUNT = '/delete-account';
export const SELF_XSS_ROUTE = '/self-xss';

// whitepaper
export const WHITEPAPER_ROUTE = '/whitepaper';
