import getUser from './getUser';

export const updateLoggedUser = (saveUser) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem('userId');
      const user = await getUser(userId);
      // console.log("updateLoggedUser / user: ", user);
      saveUser(user);
      resolve();
    } catch (e) {
      reject(e);
    }
  })
}