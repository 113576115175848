import React, { useState } from 'react';
import { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
// import * as routes from '../../routes/routes';
import { UserName, UserProfile } from '../ornament-profile/profileUtils';
import {
  FEDERAL_WHITE,
  FEDERAL_BLUE,
  FEDERAL_GREEN,
  GRAY_MAIN,
  BRIGHT_BLUE,
  FEDERAL_BLUE_STRONG,
  WHITE_MAIN,
} from '../../utils/color-palatte';
// import { Trans } from 'react-i18next';
import { thousandSeparator } from '../../utils/thousandSeparator';
import moment from 'moment';
import './chatItem.css';
import OptionModal from '../options-modal/optionsModal';
import MenuButton from '../menu-button/MenuButton';
import { useTranslation } from 'react-i18next';
// import { normalDecrypt } from '../../utils/encrypt';

const ChatItem = (props) => {
  const { 
    item, 
    style, 
    goUserChat, 
    userId, 
    darkMode, 
    positions, 
    removeChatMessages, 
    removeChat 
  } = props;

  const { t } = useTranslation()

  // eslint-disable-next-line
  const [unreadMessage, setUnreadMessage] = useState(null);
  // eslint-disable-next-line
  const [actionsLoader, setActionsLoader] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  useEffect(() => async () => {
    const unreadMessage = localStorage.getItem(String(item._id));
    setUnreadMessage(unreadMessage);
  });

  // const goUserProfile = async (message) => {
  //   const currentUserId = localStorage.getItem('userId');
  //   if (currentUserId === message.user._id) {
  //     history.push(routes.PROFILE_ROUTE);
  //   } else {
  //     history.push(`${routes.PROFILE_ROUTE}/${message.user._id}`);
  //   }
  // };
  // console.log("item: ", item)
  // console.log("positions: ", positions)
  const chatUser =
    item.members && item.members.length > 0
      ? String(item.members[0]._id) === String(item.members[1]._id)
        ? item.members[0]
        : item.members && item.members.length > 0
        ? item.members.filter((member) => String(member._id) !== String(userId))[0]
        : item.user
      : item.user;

  const position =
    positions?.filter((position) => position.user._id === chatUser?._id).length > 0
      ? positions?.filter((position) => position.user._id === chatUser?._id)[0].position
      : null;

  const removeChatMessagesFunc = async () => {
    setActionsLoader(true);
    try {
      await removeChatMessages(item._id);
      setActionsLoader(false);
      setIsOptionsOpen(false)
    } catch (e) {
      // console.log(e)
      setActionsLoader(false);
    }
  };

  const removeChatFunc = async () => {
    setActionsLoader(true);
    try {
      await removeChat(item._id);
      setActionsLoader(false);
      setIsOptionsOpen(false)
    } catch (e) {
      // console.log(e)
      setActionsLoader(false);
    }
  };

  const handleClick = (e, click) => {
    e.stopPropagation();
    if (typeof click === 'function') {
      click();
    } else {
      return null;
    }
  };

  return (
    <div
      className='ChatItem__Container'
      style={Object.assign({ backgroundColor: darkMode ? FEDERAL_BLUE : FEDERAL_WHITE }, style)}
      onClick={() => goUserChat(chatUser._id, item ? item : null)}
    >
      <UserProfile
        uri={chatUser?.picture ?? null}
        position={position}
        showActive
        dotColor={chatUser?.active ? FEDERAL_GREEN : GRAY_MAIN}
        borderColor={darkMode ? BRIGHT_BLUE : FEDERAL_BLUE_STRONG}
      />
      <div className='ChatItem__Content'>
        <div
          className='ChatItem__Header'
          // style={{
          //   borderBottom: `1px solid ${darkMode ? '#FFF5' : '#04458F55'}`,
          // }}
        >
          <UserName
            darkMode={darkMode}
            username={chatUser?.username}
            isVerified={chatUser.isVerifiedUser}
            position={position}
            onClick={() => null}
            titleStyles={{
              cursor: 'pointer',
              color: darkMode ? WHITE_MAIN : '#000',
              fontSize: chatUser?.username.length > 9 ? '1.4rem' : '1.7rem',
            }}
          />
          {item.lastUpdateDate ? (
            <p className='ChatItem__Date' style={{ color: darkMode ? FEDERAL_GREEN : '#000' }}>
              {moment(item.lastUpdateDate).format('MM-DD hh:mm A')}
            </p>
          ) : null}
        </div>
        <div className='ChatItem__Footer'>{item?.lastMessage && <p className='LastMessage'>{item?.lastMessage}</p>}</div>
      </div>
      <MenuButton withBorder className='ChatItem__Menu__Btn' onClick={(e) => handleClick(e, setIsOptionsOpen(true))} />
      <OptionModal
        isOpen={isOptionsOpen}
        onRequestClose={() => setIsOptionsOpen(false)}
        show={{
          removeAllMsg: {
            display: removeChatMessages ? true : false,
            title: t('MESSAGES.removeChatMessages'),
            onClick: () => removeChatMessagesFunc(),
          },
          delete: {
            display: removeChat ? true : false,
            title: t('MESSAGES.removeChat'),
            onClick: () => removeChatFunc(),
          },
        }}
      />
      {item.unreadMessagesCount > 0 ? (
          <p
            className='ChatItem__MsgCounter'
            style={{
              color: darkMode ? WHITE_MAIN : '#000',
            }}
          >
            {thousandSeparator(item.unreadMessagesCount)}
          </p>
        ) : null}
    </div>
  );
};

export default ChatItem;
