import React from 'react';
import AlertModal from '../../../utils/modal/Modal';
import { ListItem } from '../listItem';
import i18n from '../../../i18n/i18n';
import { Translation, Trans } from 'react-i18next';
import InfoModal from '../../info-modals/info-modals';
import { BSpan } from '../../../utils/text-utils/text-utils';
import { WARNING_YELLOW } from '../../../utils/color-palatte';
import './paymentReceivedModal.css';

const PaymentRenovDoneModal = (props) => {
  const { isOpen, onRequestClose, firstPaid } = props;

  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
  });

  const openModal = (isOpen, type) => {
    setModal({
      isOpen: isOpen,
      type: type,
    });
  };

  const Item = [
    {
      text: i18n.t('BENEFITS.li1'),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li2'>
          bla
          <BSpan style={{ color: WARNING_YELLOW }} onClick={() => openModal(true, 'voting')}>bla</BSpan>
          bla
          <BSpan style={{ color: WARNING_YELLOW }} onClick={() => openModal(true, 'law')}>bla</BSpan>
          bla
          <BSpan style={{ color: WARNING_YELLOW }} onClick={() => openModal(true, 'deal')}>bla</BSpan>
          bla
        </Trans>
      ),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li3'>
          bla
          <BSpan style={{ color: WARNING_YELLOW }} onClick={() => openModal(true, 'citizen')}>bla</BSpan>
          bla
        </Trans>
      ),
    },
    {
      text: i18n.t('BENEFITS.li4'),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li5'>
          bla
          <BSpan style={{ color: WARNING_YELLOW }} onClick={() => openModal(true, 'services')}>bla</BSpan>
          bla
          <BSpan style={{ color: WARNING_YELLOW }} onClick={() => openModal(true, 'deals')}>bla</BSpan>
          bla
        </Trans>
      ),
    },
  ];

  return (
    <Translation>
      {(t) => (
        <AlertModal
          isOpen={isOpen}
          modalClassName='PaymentRenovDone__Container'
          overlayClassName='PaymentRenovDone__Overlay'
          outsideClick={onRequestClose}
        >
          <div className='PaymentRenovDone__Header'>
            <h1>
              {firstPaid
                ? t('PAYMENTS_MODALS.PAYMENT_RECEIVED.firstTitle')
                : t('PAYMENTS_MODALS.PAYMENT_RECEIVED.title')}
            </h1>
            <h3>{t('PAYMENTS_MODALS.PAYMENT_RECEIVED.subTitle')}</h3>
          </div>
          <div className='PaymentRenovDone__Content'>
            <h3>
              {firstPaid
                ? t('PAYMENTS_MODALS.PAYMENT_RECEIVED.firstListTitle')
                : t('PAYMENTS_MODALS.PAYMENT_RECEIVED.listTitle')}
              :
            </h3>
            {Item.map((item, i) => (
              <ListItem {...item} />
            ))}
          </div>

          <div className='PaymentRenovDone__btnCont'>
            <button onClick={onRequestClose}>
              {t('PAYMENTS_MODALS.continue')}
            </button>
          </div>
          <InfoModal
            isOpen={modal.isOpen}
            type={modal.type}
            onRequestClose={() => setModal({ ...modal, isOpen: false })}
          />
        </AlertModal>
      )}
    </Translation>
  );
};

export default PaymentRenovDoneModal;
