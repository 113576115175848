import React from 'react';
import { withRouter } from 'react-router-dom';
import * as routes from '../../routes/routes';
// import recaptcha from 'react-google-recaptcha/lib/recaptcha';
import './votingItem.css';
import moment from 'moment';
import {
  FEDERAL_BLUE,
  FEDERAL_BLUE_SOFT,
  FEDERAL_LIGTH_BLUE,
  UNSATURATED_BLACK_BLUE,
  WHITE_MAIN,
} from '../../utils/color-palatte';
import { LikeButton, UnlikeButton, UserName, UserProfile } from '../ornament-profile/profileUtils';
import handlePositions from '../../utils/handlePositionName';
import handleDealTypeName from '../../utils/handleDealTypeName';
import handleVotingTypeNames from '../../utils/handleVotingTypeNames';
import { thousandSeparator } from '../../utils/thousandSeparator';
import MenuButton from '../menu-button/MenuButton';
import OptionsModal from '../options-modal/optionsModal';
import Countdown from 'react-countdown';
import { ReadMoreLinesTxt, ReadMoreTxt, TranslatableText } from '../../utils/text-utils/text-utils';
import ReCaptchaModal from '../re-captcha-modal/reCaptchaModal';
import { AppLoader } from '../../utils/animations/animations';
// import { CURRENT_DOMAIN } from '../../config';
import i18n from '../../i18n/i18n';
import Alert from '../../utils/alert/Alert';
import VotingApprovedIcon from '../../assets/voting/voting-approved-icon.svg';
import VotingRejectedIcon from '../../assets/voting/icon-denied.svg';
import PendingIcon from '../../assets/voting/pending-voting-icon.svg';
import { handlePostDate } from '../../utils/handlePostDate';

const LikeButtonCursed = (props) => {
  const { darkMode, style, onClick, counts } = props;
  const [hover, setHover] = React.useState(false);
  return (
    <LikeButton
      style={Object.assign(
        {
          padding: '8px 15px',
          backgroundColor: hover ? '#6cc7e7' : darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
          borderRadius: '10px',
          cursor: 'pointer',
          transition: 'all .2s',
        },
        style
      )}
      darkMode
      liked
      counts={counts}
      counterStyle={{
        fontSize: '1.3rem',
        fontWeight: 'bold',
        margin: '0',
        marginLeft: '5px',
      }}
      onClick={onClick}
      otherProps={{
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
        onTouchStart: () => setHover(true),
        onTouchEnd: () => setHover(false),
      }}
    />
  );
};
const UnlikeButtonCursed = (props) => {
  const { darkMode, style, onClick, counts } = props;
  const [hover, setHover] = React.useState(false);
  return (
    <UnlikeButton
      style={Object.assign(
        {
          padding: '8px 15px',
          backgroundColor: hover ? '#6cc7e7' : darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
          borderRadius: '10px',
          cursor: 'pointer',
          transition: 'all .2s',
        },
        style
      )}
      darkMode
      unliked
      counts={counts}
      counterStyle={{
        fontSize: '1.3rem',
        fontWeight: 'bold',
        margin: '0',
        marginLeft: '5px',
      }}
      onClick={onClick}
      otherProps={{
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
        onTouchStart: () => setHover(true),
        onTouchEnd: () => setHover(false),
      }}
    />
  );
};

class VotingItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.approveCaptchaRef = React.createRef();
    this.disapproveCaptchaRef = React.createRef();
    this.state = {
      activeSlide: 0,
      voteLoading: false,
      terminateLoading: false,
      isVotingMenuOpen: false,
      isSecondVotingMenuOpen: false,
      isMessageModalOpen: false,
      reason: '',
      isSmall: false,
      captchaSuccess: false,
    };
  }

  rlvRef = React.createRef();
  observer = null;

  componentDidMount = () => {
    this.observer = new ResizeObserver((entries) => {
      // console.log(entries);
      const boxElem = entries[0];
      const small = boxElem.contentRect.width < 450;
      if (small) {
        this.setState({
          isSmall: true,
        });
      } else {
        this.setState({
          isSmall: false,
        });
      }
    });
    this.observer.observe(this.rlvRef.current);
  };

  componentWillUnmount = () => {
    if (this.observer) {
      this.observer.disconnect();
    }
  };

  vote = (votingId, boolean, postId) => {
    const { voteFunction } = this.props;
    if (voteFunction) {
      this.setState({ voteLoading: true, isCaptchaOpen: false }, async () => {
        try {
          await voteFunction(votingId, boolean, postId);
          this.setState({ voteLoading: false, isCaptchaOpen: false });
        } catch (e) {
          this.setState({ voteLoading: false, isCaptchaOpen: false });
        }
      });
    }
  };

  terminateVotingFunc = () => {
    const { terminateVoting, item } = this.props;
    this.setState({ terminateLoading: true }, async () => {
      try {
        await terminateVoting(item._id);
        this.setState({ terminateLoading: false });
      } catch (e) {
        this.setState({ terminateLoading: false });
      }
    });
  };

  blockVoting = () => {
    const { reason } = this.state;
    const { blockVoting, item } = this.props;
    blockVoting(item._id, reason);
  };

  goUserProfile = (post) => {
    const userId = post.user._id || post.user;
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId === userId) {
      this.props.history.push(routes.PROFILE_ROUTE);
    } else {
      this.props.history.push(`${routes.PROFILE_ROUTE}/${userId}`);
    }
  };

  onCallApprove = (item, postId) => {
    this.callCaptcha(true, (value) => {
      if (value) {
        this.setState({ captchaSuccess: true }, () => this.vote(item._id, true, postId));
      } else {
        this.setState({ captchaSuccess: false });
      }
    });
  };

  onCallReject = (item, postId) => {
    this.callCaptcha(true, (value) => {
      if (value) {
        this.setState({ captchaSuccess: true }, () => this.vote(item._id, false, postId));
      } else {
        this.setState({ captchaSuccess: false });
      }
    });
  };

  callCaptcha = (isOpen, onChange) => {
    this.setState({
      isCaptchaOpen: isOpen,
      onCaptchaChange: onChange,
    });
  };

  shareUrl = (id) => {
    const { isDeal, isLaw, isHistory } = this.props;
    this.setState({ isOptionsOpen: false });
    navigator.clipboard.writeText(
      `${window.location.origin}${
        isHistory
          ? routes.VOTING_HISTORY
          : isDeal
          ? routes.DEALS_DETAILS_ROUTE
          : isLaw
          ? routes.LAWS_DETAILS_ROUTE
          : routes.VOTING_DETAILS_ROUTE
      }/${id}`
    );
    this.showAlert(
      true,
      'success',
      'Done',
      i18n.t('DASHBOARD.shareUrl'),
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      'Ok',
      () => this.setState({ isModalOpen: false }),
      false
    );
  };

  shareAsMsg = (id) => {
    const { isDeal, isLaw, isHistory } = this.props;
    this.props.history.push(
      `${routes.MESSAGES_ROUTE}/?${isHistory ? 'voting-history' : isDeal ? 'deal' : isLaw ? 'law' : 'voting'}=${id}`
    );
  };

  onShare = (id) => {
    const { isDeal, isLaw, isHistory } = this.props;
    this.props.history.push(
      `${routes.CREATE_POST_ROUTE}/?${isHistory ? 'voting-history' : isDeal ? 'deal' : isLaw ? 'law' : 'voting'}=${id}`
    );
  };

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  handleClick = (e, click) => {
    e.stopPropagation();
    if (typeof click === 'function') {
      click();
    } else {
      return null;
    }
  };

  render() {
    const {
      style,
      item,
      user,
      voting,
      darkMode,
      postId,
      positionsPeriodDays,
      votingExpiresPeriodDays,
      isHistory,
      isDone,
      isDeal,
      isLaw,
      onClick,
      hideMenu,
      select,
      saveField,
    } = this.props;

    // const shareUrl = isDeal ? `/?deal=${item._id}` : isLaw ? `/?law=${item._id}` : `/?voting=${item._id}`;

    const { voteLoading, isSmall } = this.state;

    // console.log(item);

    let currentPosition = '';
    if (voting && voting.positions?.length > 0 && item.user) {
      const havePosition = voting.positions.filter((position) => position.user._id === item.user._id);
      if (havePosition?.length > 0) {
        currentPosition = havePosition[0].position;
      }
    }

    const a = moment();
    const b = moment(item.createdAt).add(votingExpiresPeriodDays, 'days');
    var Seconds_Between_Dates = Math.abs((+a - +b) / 1000);

    const isPostApproved = user && item.approves ? item.approves.filter((userId) => userId === user._id).length > 0 : false;

    const isPostRejected = user && item.rejects ? item.rejects.filter((userId) => userId === user._id).length > 0 : false;

    const votingIsRejected = a.diff(b, 'days') >= 0;

    return (
      <div
        className='VotingCard__Container'
        ref={this.rlvRef}
        style={Object.assign(
          {
            background: darkMode ? UNSATURATED_BLACK_BLUE : FEDERAL_LIGTH_BLUE,
            cursor: isHistory ? 'pointer' : 'default',
            margin: 'auto',
            marginTop: hideMenu ? 0 : 10,
            marginBottom: hideMenu ? 0 : 10,
            border: `1px solid ${hideMenu ? FEDERAL_BLUE : 'transparent'}`,
          },
          style
        )}
        onClick={() =>
          onClick
            ? onClick()
            : isHistory
            ? this.props.history.push(
                `${routes.VOTING_DETAILS_ROUTE}/${item.votingRef && item.votingRef._id ? item.votingRef._id : item.votingRef}`
              )
            : null
        }
      >
        <div
          className='VotingCard__Header'
          style={{
            borderBottom: `2px solid ${darkMode ? WHITE_MAIN : FEDERAL_BLUE}`,
            marginBottom: 5,
          }}
        >
          <div className='VotingCard__UserPicture-container'>
            <UserProfile
              onClick={(e) => this.handleClick(e, this.goUserProfile(item))}
              uri={item?.user?.picture}
              position={currentPosition}
              containerStyle={{
                width: isSmall && 45,
                height: isSmall && 45,
              }}
            />
          </div>
          <div className='VotingCard__Header-data'>
            <p
              style={{
                margin: 0,
                color: darkMode ? WHITE_MAIN : '#000',
                fontSize: '.85rem',
              }}
            >
              {i18n.t('VOTING_GENERAL.publish-by')}
            </p>
            <UserName
              username={item.user && item.user.username ? item.user.username : item.userUsername}
              darkMode={darkMode}
              style={{ height: 30 }}
              isVerified={item && item.user && item.user.isVerifiedUser ? item.user.isVerified : false}
              checkIconStyles={{ width: item.user && item.user.username && item.user.username.length > 10 ? 18 : 20 }}
              titleStyles={{
                color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                margin: 0,
                fontSize: `${item.user && item.user.username && item.user.username.length > 10 ? 0.85 : 1}rem`,
                // textTransform: 'uppercase',
              }}
              // alias={item.user.username}
              aliasStyles={{
                color: darkMode ? '#CCC' : '#333',
                fontSize: '.9rem',
                margin: 0,
              }}
              position={currentPosition}
              crownStyle={{ width: 18 }}
              ornamentStyle={{ width: 18 }}
              onClick={(e) => this.handleClick(e, this.goUserProfile(item))}
            />
            <p
              className='VotingCard__UserPosition-text'
              style={{
                color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
                background: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                textTransform: 'uppercase',
              }}
            >
              {handlePositions(currentPosition)}
            </p>
          </div>
        </div>
        <div className='VotingCard__Content'>
          {votingExpiresPeriodDays && !isDone ? (
            <div
              className='VotingCard__CountDown-container'
              style={{
                color: darkMode ? WHITE_MAIN : '#000',
                transform: isSmall ? 'scale(.8,.8)' : 'unset',
                transformOrigin: 'left',
              }}
            >
              {item.dealType ? (
                <p>
                  {i18n.t('VOTING_GENERAL.dealType')}&nbsp;
                  <span
                    style={{
                      color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                      transform: isSmall ? 'scale(.8,.8)' : 'unset',
                    }}
                  >
                    {handleDealTypeName(item.dealType)}
                  </span>
                </p>
              ) : null}
              {i18n.t('VOTING_GENERAL.expire-in')}{' '}
              <Countdown
                date={Date.now() + Seconds_Between_Dates * 1000}
                renderer={({ days, hours, minutes, seconds }) => {
                  return (
                    <span
                      style={{
                        color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                        fontSize: isSmall ? '.95rem' : '1rem',
                      }}
                    >
                      {days} {days === 1 ? i18n.t('VOTING_GENERAL.days') : i18n.t('VOTING_GENERAL.day')} | {hours}{' '}
                      {hours === 1 ? i18n.t('VOTING_GENERAL.hour') : i18n.t('VOTING_GENERAL.hours')} | {minutes}{' '}
                      {minutes === 1 ? i18n.t('VOTING_GENERAL.minute') : i18n.t('VOTING_GENERAL.minutes')} | {seconds}{' '}
                      {seconds === 1 ? i18n.t('VOTING_GENERAL.second') : i18n.t('VOTING_GENERAL.seconds')}{' '}
                    </span>
                  );
                }}
              />
            </div>
          ) : null}
          {item.type && isDeal ? (
            <div className='VotingCard__CountDown-container'>
              <p style={{ color: WHITE_MAIN }}>
                {i18n.t('VOTING_GENERAL.dealType')}&nbsp;
                <span
                  style={{
                    color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                  }}
                >
                  {handleDealTypeName(item.type)}
                </span>
              </p>
            </div>
          ) : null}
        </div>
        <div>
          {user ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexFlow: 'row',
                }}
              >
                {item.position ? (
                  <p style={{ color: darkMode ? WHITE_MAIN : '#000', margin: 0 }}>
                    {i18n.t('VOTING_GENERAL.position')}{' '}
                    <span
                      style={{
                        color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                      }}
                    >
                      {item.position}
                    </span>
                  </p>
                ) : null}
                {item.position ? (
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : '#000',
                      margin: 0,
                      marginLeft: '10px',
                    }}
                  >
                    {i18n.t('VOTING_GENERAL.period')}{' '}
                    <span
                      style={{
                        color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                      }}
                    >
                      {positionsPeriodDays} {i18n.t('VOTING_GENERAL.days')}
                    </span>
                  </p>
                ) : null}
              </div>
              {item.postulate && item.position ? (
                <div>
                  <p style={{ color: darkMode ? WHITE_MAIN : '#000' }}>
                    {i18n.t('VOTING_GENERAL.postulate')}{' '}
                    <span
                      style={{
                        color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                        cursor: 'pointer',
                      }}
                      onClick={() => this.goUserProfile(item)}
                    >
                      {item.postulate}
                    </span>
                  </p>
                </div>
              ) : null}
              {item.lawToRemove && item.type === 'removeLaw' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <button
                    className='VotingCard__SeeItem'
                    style={{
                      backgroundColor: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
                    }}
                    onClick={() => this.props.history.push(`${routes.LAWS_DETAILS_ROUTE}/${item.lawToRemove._id}`)}
                  >
                    {i18n.t('VOTING_GENERAL.law-to-remove')}
                  </button>
                </div>
              ) : null}
              {item.dealToRemove && item.type === 'removeDeal' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <button
                    className='VotingCard__SeeItem'
                    style={{
                      backgroundColor: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
                    }}
                    onClick={() => this.props.history.push(`${routes.DEALS_DETAILS_ROUTE}/${item.dealToRemove._id}`)}
                  >
                    {i18n.t('VOTING_GENERAL.deal-to-remove')}
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div>
          {item?.title?.length > 40 ? (
            <ReadMoreTxt
              darkMode={darkMode}
              isHeading
              maxLength={40}
              style={{
                wordBreak: 'break-all',
                fontSize: '1.5rem',
                color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                margin: 0,
              }}
              readMoreStyle={{
                fontSize: isSmall ? '.95rem' : '1rem',
                color: darkMode ? '#CCC' : '#000',
              }}
              showLessStyle={{
                fontSize: '1rem',
                color: darkMode ? '#AAA' : '#444',
              }}
              text={item.title}
            />
          ) : item?.title?.split(/\r\n|\r|\n/)?.length > 1 ? (
            <ReadMoreLinesTxt
              isHeading
              maxLines={1}
              darkMode={darkMode}
              style={{
                wordBreak: 'break-all',
                fontSize: isSmall ? '.95rem' : '1rem',
                color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                margin: 0,
              }}
              readMoreStyle={{
                fontSize: '1rem',
                color: darkMode ? '#CCC' : '#000',
              }}
              showLessStyle={{
                fontSize: '1rem',
                color: darkMode ? '#AAA' : '#444',
              }}
              text={item.title}
            />
          ) : (
            <>
              <h1
                style={{
                  fontSize: isSmall ? '1.2rem' : '1.5rem',
                  color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
                  margin: 0,
                }}
              >
                {item.title}
              </h1>
              <TranslatableText text={item.title} style={{ color: darkMode ? WHITE_MAIN : '#000' }} />
            </>
          )}
          {item?.description?.length > 80 ? (
            <ReadMoreTxt
              maxLength={80}
              darkMode={darkMode}
              showLessColor={darkMode ? '#888' : '#555'}
              readMoreColor={darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE}
              style={{
                wordBreak: 'break-all',
                color: darkMode ? WHITE_MAIN : '#000',
                margin: '5px 0',
              }}
              text={item.description}
            />
          ) : item?.description?.split(/\r\n|\r|\n/)?.length > 2 ? (
            <ReadMoreLinesTxt
              maxLines={2}
              darkMode={darkMode}
              showLessColor={darkMode ? '#888' : '#555'}
              readMoreColor={darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE}
              style={{
                wordBreak: 'break-all',
                color: darkMode ? WHITE_MAIN : '#000',
                margin: '5px 0',
              }}
              text={item.description}
            />
          ) : (
            <>
              <p
                style={{
                  color: darkMode ? WHITE_MAIN : '#000',
                  margin: '5px 0',
                }}
              >
                {item.description}
              </p>
              <TranslatableText text={item.description} style={{ color: darkMode ? WHITE_MAIN : '#000' }} />
            </>
          )}
        </div>
        {item.type ? (
          <p
            className='VotingCard__VoteType'
            style={{
              color: darkMode ? '#CCC' : '#333',
              fontSize: `${isSmall ? 0.7 : 1}rem`,
              marginTop: 10,
              marginRight: hideMenu ? 10 : 50,
            }}
          >
            {i18n.t('VOTING_GENERAL.type')}{' '}
            <span style={{ color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE }}>&nbsp;{handleVotingTypeNames(item.type)}</span>
          </p>
        ) : null}
        {item.status === 'voting-request-approved' && !votingIsRejected ? (
          <div className='approvedButtonContainer'>
            <p className='approvedButtonText'>
              {isPostRejected ? i18n.t('VOTING_LIST.votingRequestApprovedRejected') : i18n.t('VOTING_LIST.votingRequestApproved')}
            </p>
            <img alt='' src={PendingIcon} style={{ width: 20, height: 20, marginLeft: 10 }} />
          </div>
        ) : null}
        {item.status === 'pending' && !votingIsRejected ? (
          <div className='approvedButtonContainer'>
            <p className='approvedButtonText'>
              {isPostRejected ? i18n.t('VOTING_LIST.pendingStatusRejected') : i18n.t('VOTING_LIST.pendingStatus')}
            </p>
            <img alt='' src={PendingIcon} style={{ width: 20, height: 20, marginLeft: 10 }} />
          </div>
        ) : null}
        {select && saveField && (isLaw || isDeal) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <button
              className='VotingCard__SeeItem'
              style={{
                backgroundColor: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
              }}
              onClick={() => {
                this.props.history.push(`${routes.CREATE_VOTE_ROUTE}`);
                saveField(isLaw ? 'lawToRemove' : 'dealToRemove', item._id);
              }}
            >
              {i18n.t('VOTING_GENERAL.select')}
            </button>
          </div>
        )}
        {isHistory ? null : (isPostApproved || isPostRejected) && !isDone ? (
          <div style={{ width: '100%', height: '90px', position: 'relative' }}>
            <p
              style={{
                margin: 0,
                marginTop: 10,
                textAlign: 'right',
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: `${isSmall ? 0.85 : 0.9}rem`,
                color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE,
              }}
            >
              {handlePostDate(item.creationDate)}
            </p>
            <div
              className='VotingCard__Voted-area'
              style={{
                backgroundColor: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE,
              }}
            >
              <LikeButton
                svged
                iconColor={darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN}
                iconStyle={{ width: '30px' }}
                liked
                counts={thousandSeparator(item.approvesCount) || 0}
                counterStyle={{
                  color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
                  marginLeft: '10px',
                  fontWeight: 'bold',
                }}
              />
              <UnlikeButton
                svged
                iconColor={darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN}
                iconStyle={{ width: '30px' }}
                unliked
                counts={thousandSeparator(item.rejectsCount) || 0}
                counterStyle={{
                  color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
                  marginLeft: '10px',
                  fontWeight: 'bold',
                }}
              />
              {votingIsRejected ? (
                <div className='approvedTextContainer'>
                  <img alt='' src={VotingRejectedIcon} style={{ width: 12, height: 12 }} />
                  <p className='approvedText'>{i18n.t('VOTING_LIST.rejected')}</p>
                </div>
              ) : null}
              {item.status === 'approved' ? (
                <div className='approvedTextContainer'>
                  <img alt='' src={VotingApprovedIcon} style={{ width: 12, height: 12 }} />
                  <p className='approvedText'>{i18n.t('VOTING_LIST.approved')}</p>
                </div>
              ) : null}
            </div>
            {/* {item.status === "approved"
              ? <View style={styles.approvedTextContainer}>
                <VotingApprovedIcon width={12} height={12} />
                <Text style={styles.approvedText}>{i18n.t('VOTING_LIST.approved')}</Text>
              </View>
              : votingIsRejected ? <View style={styles.approvedTextContainer}>
                <VotingRejectedIcon width={12} height={12} />
                <Text style={styles.rejectedText}>{i18n.t('VOTING_LIST.rejected')}</Text>
              </View>
                : null} */}
          </div>
        ) : voteLoading ? (
          <div
            style={{
              width: '100%',
              height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AppLoader size='45px' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
          </div>
        ) : !isDone ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexFlow: 'row nowrap',
                marginTop: '20px',
              }}
            >
              <LikeButtonCursed
                style={{
                  transformOrigin: 'center',
                  transform: `scale(${isSmall ? '.85' : '1'})`,
                  margin: isSmall ? '0 -5px ' : 'unset',
                }}
                darkMode={darkMode}
                counts='Approve'
                onClick={() => this.onCallApprove(item, postId)}
              />
              <UnlikeButtonCursed
                style={{
                  transformOrigin: 'center',
                  transform: `scale(${isSmall ? '.85' : '1'})`,
                  margin: isSmall ? '0 -5px' : 'unset',
                }}
                darkMode={darkMode}
                counts='Reject'
                onClick={() => this.onCallReject(item, postId)}
              />
            </div>
            <p
              style={{
                margin: 0,
                marginTop: 10,
                width: '100%',
                textAlign: 'right',
                fontSize: `${isSmall ? 0.85 : 0.9}rem`,
                color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE,
              }}
            >
              {handlePostDate(item.creationDate)}
            </p>
          </>
        ) : null}
        {isHistory ? (
          <div style={{ width: '100%', height: '90px', position: 'relative' }}>
            <p
              style={{
                margin: 0,
                marginTop: 10,
                textAlign: 'right',
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: `${isSmall ? 0.85 : 0.9}rem`,
                color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE,
              }}
            >
              {handlePostDate(item.creationDate)}
            </p>
            <div
              className='VotingCard__Voted-area'
              style={{
                backgroundColor: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE,
              }}
            >
              <LikeButton
                svged
                iconColor={darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN}
                iconStyle={{ width: '30px' }}
                liked
                counts={thousandSeparator(item.approvesCount) || 0}
                counterStyle={{
                  color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
                  marginLeft: '10px',
                  fontWeight: 'bold',
                }}
              />
              <UnlikeButton
                svged
                iconColor={darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN}
                iconStyle={{ width: '30px' }}
                unliked
                counts={thousandSeparator(item.rejectsCount) || 0}
                counterStyle={{
                  color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
                  marginLeft: '10px',
                  fontWeight: 'bold',
                }}
              />
              {item.status === 'approved' ? (
                <div className='approvedTextContainer'>
                  <img alt='' src={VotingApprovedIcon} style={{ width: 12, height: 12 }} />
                  <p className='approvedText'>{i18n.t('VOTING_LIST.approved')}</p>
                </div>
              ) : votingIsRejected ? (
                <div className='approvedTextContainer'>
                  <img alt='' src={VotingRejectedIcon} style={{ width: 12, height: 12 }} />
                  <p className='approvedText'>{i18n.t('VOTING_LIST.rejected')}</p>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {isLaw || isDeal ? (
          <div style={{ width: '100%', height: '90px', position: 'relative' }}>
            <p
              style={{
                margin: 0,
                marginTop: 10,
                textAlign: 'right',
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: `${isSmall ? 0.85 : 0.9}rem`,
                color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE,
              }}
            >
              {handlePostDate(item.creationDate)}
            </p>
            <div
              className='VotingCard__Voted-area'
              style={{
                backgroundColor: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE,
              }}
            >
              <LikeButton
                svged
                iconColor={darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN}
                iconStyle={{ width: '30px' }}
                liked
                counts={thousandSeparator(item.approvesCount) || 0}
                counterStyle={{
                  color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
                  marginLeft: '10px',
                  fontWeight: 'bold',
                }}
              />
              <UnlikeButton
                svged
                iconColor={darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN}
                iconStyle={{ width: '30px' }}
                unliked
                counts={thousandSeparator(item.rejectsCount) || 0}
                counterStyle={{
                  color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
                  marginLeft: '10px',
                  fontWeight: 'bold',
                }}
              />
              <div className='approvedTextContainer'>
                <img alt='' src={VotingApprovedIcon} style={{ width: 12, height: 12 }} />
                <p className='approvedText'>{i18n.t('VOTING_LIST.approved')}</p>
              </div>
            </div>
          </div>
        ) : null}
        <OptionsModal
          isOpen={this.state.isOptionsOpen}
          show={{
            share: {
              display: true,
              onClick: () => this.onShare(item._id),
            },
            shareUrl: {
              display: true,
              onClick: () => this.shareUrl(item._id),
            },
            shareAsMsg: {
              display: true,
              onClick: () => this.shareAsMsg(item._id),
            },
          }}
          onRequestClose={() =>
            this.setState({
              isOptionsOpen: false,
            })
          }
        />
        <ReCaptchaModal
          isOpen={this.state.isCaptchaOpen}
          onChange={this.state.onCaptchaChange}
          darkMode={darkMode}
          onRequestClose={() => this.setState({ isCaptchaOpen: false })}
        />
        {!hideMenu && (
          <MenuButton
            style={{
              position: 'absolute',
              right: '0',
              top: '0',
              marginTop: 10,
              marginRight: 10,
            }}
            isBlue={!darkMode}
            onClick={(e) =>
              this.handleClick(
                e,
                this.setState({
                  isOptionsOpen: true,
                })
              )
            }
            withBorder
          />
        )}
        <Alert
          isOpen={this.state.isModalOpen}
          type={this.state.modalType}
          title={this.state.modalTitle}
          content={this.state.modalContent}
          choose={this.state.modalChoose}
          NOClick={this.state.noAction}
          noButtonText={this.state.noText}
          YESClick={this.state.yesAction}
          yesButtonText={this.state.yesText}
          singleButtonText={this.state.btnTxt}
          onRequestClose={this.state.btnAction}
          loading={this.state.actionsLoader}
        />
      </div>
    );
  }
}

export default withRouter(VotingItem);
