import { postData, getData } from '../../fetch';

export const createMessage = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/user-messages/create-message-web', data);
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};

export const createChat = (members) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem('userId')
      const response = await postData('/user-messages/create-chat-web', {
        members,
        user: userId
      });
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};

export const removeChatMessagesAction = chatId => new Promise(async(resolve, reject) => {
  try {
    const userId = localStorage.getItem('userId');
    const response = await postData('/user-messages/remove-chat-messages-web', { chatId, userId });
    resolve(response)
  } catch (e) {
    // console.log('removeChatAction / e: ', e)
    reject (e);
  }
})

export const removeChatAction = chatId => new Promise(async(resolve, reject) => {
  try {
    const userId = localStorage.getItem('userId');
    const response = await postData('/user-messages/remove-chat-web', { chatId, userId });
    resolve(response)
  } catch (e) {
    // console.log('removeChatAction / e: ', e)
    reject (e);
  }
})
export const makeChatVisibleAction = chatId => new Promise(async(resolve, reject) => {
  try {
    const userId = localStorage.getItem('userId');
    const response = await postData('/user-messages/make-chat-visible-web', { chatId, userId });
    resolve(response)
  } catch (e) {
    // console.log('removeChatAction / e: ', e)
    reject (e);
  }
})

export const getChats = (userIds, skipItems, limitItems, userId) => {
  return new Promise(async (resolve, reject) => {
    const data = {
      userIds,
      skipItems,
      limitItems,
      userId
    };
    try {
      const response = await postData(`/user-messages/get-user-chats-web`, data);
      // console.log("getComments / response: ", response);
      const comments = response.body;
      return resolve(comments);
    } catch (e) {
      // console.log("getComments / error: ", e);
      return reject(e);
    }
  });
};

export const getChat = (chatId) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log('chatId', chatId)
      const response = await getData(`/user-messages/get-chat-web/?chatId=${chatId}`);
      // console.log("getChats / response: ", response);
      const chat = response.body;
      resolve(chat);
    } catch (e) {
      // console.log("getChats / error: ", e);
      reject(e);
    }
  });
};

export const getMessagesFromChat = (chatId, skipItems, limitItems) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/user-messages/get-chat-messages-web/?chatId=${chatId}&skipItems=${skipItems}&limitItems=${limitItems}`
      );
      // console.log("getComments / response: ", response);
      const comments = response.body;
      return resolve(comments);
    } catch (e) {
      // console.log("getComments / error: ", e);
      return reject(e);
    }
  });
};

export const fetchUsersToChating = (userId, skipItems, limitItems, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/user/get-user-for-chating-web/?userId=${userId}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}`
      );
      // console.log("getPosts / response: ", response);
      const posts = response.body;
      return resolve(posts);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  });
};
