import React from 'react';
import PostCard from '../../components/post/postcard';
import { withRouter } from 'react-router';
import { getPosts, like, unlike, getPost, deletePost, reportPost, confirmEmail } from './dashboard-actions';
import i18n from '../../i18n/i18n';
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import moment from 'moment';
import getStat from '../../utils/getStat';
import { isUserValid } from '../../utils/isUserValid';

// styles
import './dashboard.css';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import { AppLoader } from '../../utils/animations/animations';
import { FEDERAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import Alert from '../../utils/alert/Alert';
import editUser from '../../utils/editUser';
import { getToken } from '../../firebase/config';
import * as routes from '../../routes/routes';
import SearchFilter from '../../components/search-filter/SearchFilter';
// import { NotFoundItem } from '../../components/not-found-item/notFoundItem';

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      fetching: false,
      skipItems: 0,
      limitItems: 5,
      plusPostNumber: 10,
      keepIncreasingCounter: false,
      isThereUnreadMessages: '',
      isMenuOpen: false,
      adminCodeModal: false,
      updateLoading: false,
      toDate: '',
      sinceDate: '',
      searchBy: '',
      search: '',
      publicUserPosts: [],
      height: 0,
      // isShowPasswordButtonShow: false,
    };
  }

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    this.unlisten = this.props.history.listen((location, action) => {
      this.onInit();
    });
    if (this._isMounted) {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const token = url.searchParams.get('token');
      if (token) {
        await this.confirmEmail();
      }
      this.onInit(true);
    }
  };

  confirmEmail = async () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token = url.searchParams.get('token');
    this.showAlert(true, i18n.t('verifyingMessage'), 'email', true);
    try {
      const response = await confirmEmail({token})
      // console.log('confirmEmail / response: ', response);
      // alert(response.body);
      this.showAlert(true, 'success', response.body);
    } catch (e) {
      // console.log("confirmEmail / error: ", e);
      const error = e && e.data.error && e.data.error.message ? e.data.error.message : i18n.t('connectionErrorMessage');
      this.showAlert(true, 'error', error);
      // alert(error);
    }
  };

  onInit = async (clean) => {
    if (this._isMounted) this.getPushNotificationToken();
    if (this._isMounted) await this.getStats();
    isUserValid(false, this._isMounted);
    if (this._isMounted) this.onRefreshPosts(clean);
  };

  // componentWillMount() {
  // unsafe!
  //   this.unlisten = this.props.history.listen((location, action) => {
  //     this.onInit();
  //   });
  // }
  
  componentWillUnmount() {
    this._isMounted = false;
    this.unlisten();
  }

  getPushNotificationToken = () => {
    return new Promise(async (resolve, _) => {
      try {
        const token = await getToken();
        // console.log("token: ", token)
        await editUser({ prop: 'pushToken', value: token });
        resolve();
      } catch (e) {
        // console.log(e)
        resolve();
      }
    });
  };

  getStats = () => {
    return new Promise((resolve, _) => {
      // console.log('getStats');
      this.setState({ loading: true }, async () => {
        try {
          const users = await getStat('users');
          const positions = await getStat('positions');
          const postReportsLimit = await getStat('post-reports-limit');
          const positionsPeriodDays = await getStat('position-expires-period-days');
          const votingExpiresPeriodDays = await getStat('voting-expires-period-days');
          // console.log("officialVotings: ", officialVotings);
          this.setState({
            positions,
            users,
            postReportsLimit,
            positionsPeriodDays,
            votingExpiresPeriodDays,
          });
          resolve();
        } catch (e) {
          resolve();
          // console.log("getStats / error: ", e);
        }
      });
    });
  };

  onRefreshPosts = (clean) => {
    const { plusPostNumber } = this.state;
    const { saveUserPosts, saveSearchPosts } = this.props;
    // console.log('onRefreshPosts / clean: ');
    if (clean) {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const publicUser = url.searchParams.get('user');
      const { pathname } = this.props.location;
      const currentRoute = pathname.replace('/', '');
      if (publicUser) {
        this.setState({ publicUserPosts: [] });
      }

      if (currentRoute === 'search') {
        saveSearchPosts([]);
      } else {
        saveUserPosts([]);
      }
    }

    this.setState({ skipItems: 0, limitItems: plusPostNumber }, () => {
      this.fetchPosts();
    });
  };

  fetchPosts = () => {
    // console.log('fetchPosts / time:', moment().format('MM-DD hh:mm A'));
    return new Promise(async (resolve, reject) => {
      const { pathname } = this.props.location;
      const currentRoute = pathname.replace('/', '');
      // console.log("fetchPosts / currentRoute: ", currentRoute)
      const {
        skipItems,
        limitItems,
        fetching,
        search = '',
        searchBy = '',
        sinceDate = '',
        toDate = '',
        publicUserPosts,
      } = this.state;
      const { saveUserPosts, saveSearchPosts, userPosts = [], searchPosts = [], user } = this.props;
      const userId = localStorage.getItem('userId');
      const url_string = window.location.href;
      const url = new URL(url_string);
      const publicUser = url.searchParams.get('user');
      const data = publicUser ? publicUserPosts : currentRoute === 'search' ? searchPosts : userPosts;
      // console.log("fetchPosts / publicUser: ", publicUser)
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const endpointData = {
              userId: currentRoute === 'search' ? '' : publicUser || userId,
              skipItems,
              limitItems,
              admin: user.admin,
              searchBy: searchBy,
              search: search,
              sinceDate: sinceDate,
              toDate: toDate,
              showPositionsPosts: searchBy === 'positions',
              userPosts: publicUser || '',
              blockList: user.blockList
            };
            // console.log("fetchPosts / endpointData: ", endpointData)
            const newPosts = await getPosts(endpointData);
            // console.log('Dashboard / getPosts / newPosts: ', newPosts);
            const finalposts = newPosts
              .concat(data)
              .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id));
            // console.log("Dashboard / finalposts: ", finalposts)
            if (publicUser) {
              this.setState({ publicUserPosts: finalposts });
            } else {
              if (currentRoute === 'search') {
                saveSearchPosts(finalposts.sort((a, b) => moment(b.creationDate) - moment(a.creationDate)));
              } else {
                saveUserPosts(finalposts.sort((a, b) => moment(b.creationDate) - moment(a.creationDate)));
              }
            }
            // console.log('finalPost:', finalposts);
            this.setState({
              fetching: false,
              keepIncreasingCounter: newPosts.length > 0 ? true : false,
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log('fetchPosts / error: ', e);
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            // console.log(error);
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  removePost = (postId, userId, files) => {
    const { removeSearchPostsItem, removeUserPostsItem } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await deletePost(postId, userId, files);
        this.showAlert(true, 'success', response.body);
        // console.log('removePost:', response);
        removeSearchPostsItem(postId);
        removeUserPostsItem(postId);
        this.onRefreshPosts();
        resolve();
      } catch (e) {
        // console.log('removePost / error: ', e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  reportPost = (postId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await reportPost(user._id, postId);
        this.showAlert(true, 'success', response.body);
        resolve();
      } catch (e) {
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  likeFunction = (postId) => {
    const {
      // updateUserPostsItem,
      // updateSearchPostsItem,
      user,
    } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        await like(user._id, postId);
        // const response = await like(user._id, postId);
        // console.log('likeFunction / response: ', response);
        await this.updatePostItem(postId);
        resolve();
      } catch (e) {
        // console.log('likeFunction / error: ', e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          reject();
          return;
        }
        this.showAlert(true, 'error', error);
        reject();
      }
    });
  };

  unlikeFunction = (postId) => {
    const {
      // updateUserPostsItem,
      // updateSearchPostsItem,
      user,
    } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        await unlike(user._id, postId);
        // const response = await unlike(user._id, postId);
        // console.log('unlikeFunction / response: ', response);
        await this.updatePostItem(postId);
        resolve();
      } catch (e) {
        // console.log('unlikeFunction / error: ', e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          reject();
          return;
        }
        this.showAlert(true, 'error', error);
        reject();
      }
    });
  };

  updatePostItem = async (postId) => {
    // eslint-disable-next-line
    const { updateUserPostsItem, updateSearchPostsItem } = this.props;
    // console.log("updatePostItem / postId: ", postId)
    const postUdated = await getPost(postId);
    // console.log("postUdated: ", postUdated);
    updateUserPostsItem(postUdated[0]);
    updateSearchPostsItem(postUdated[0]);
    // updateSearchPostsItem(postUdated[0]);
    this.fetchPosts();
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchPosts();
        }
      );
    } else {
      this.fetchPosts();
    }
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  setValue = (name, value) => {
    // console.log("setValue / name: ", name)
    // console.log("setValue / value: ", value)
    this.setState({ [name]: value });
  };
  render() {
    const { pathname } = this.props.location;
    const currentRoute = pathname.replace('/', '');
    // console.log(
    //   'limits',
    //   this.state.keepIncreasingCounter,
    //   this.state.limitItems,
    //   this.state.skipItems
    // );
    const { userPosts, searchPosts, darkMode, user } = this.props;
    const { 
      // fetching,
       positions, users, publicUserPosts, postReportsLimit, positionsPeriodDays, votingExpiresPeriodDays } =
      this.state;
    const path = this.props.location.pathname;

    // console.log('state:', this.state);
    // console.log('userPost:', userPosts);
    // console.log('currentRoute:', currentRoute);
    // console.log('searchPosts:', searchPosts);
    const url_string = window.location.href;
    const url = new URL(url_string);
    const publicUser = url.searchParams.get('user');
    const data = publicUser ? publicUserPosts : currentRoute === 'search' ? searchPosts : userPosts;
    // const data = []
    // console.log(" window.location.href: ", window.location.href)

    return (
      <ScrollableDiv onEndReach={() => this.onEndReached()} className='Dashboard__Container'>
        {path === routes.ROOT_ROUTE || path === routes.HOME_ROUTE || path === routes.DASHBOAR_ROUTE ? null : (
          <SearchFilter
            inputPlaceholder={i18n.t('DASHBOARD.searchposts')}
            onRefresh={this.onRefreshPosts}
            setValue={this.setValue}
            showPostsFilters
          />
        )}
        {data && data.length > 0
          ? data.map((post, i) => (
              <PostCard
                item={post}
                positions={positions}
                removePost={this.removePost}
                reportPost={this.reportPost}
                likeFunction={this.likeFunction}
                unlikeFunction={this.unlikeFunction}
                darkMode={darkMode}
                user={user}
                key={i}
                postReportsLimit={postReportsLimit}
                stats={{ positions, users }}
                positionsPeriodDays={positionsPeriodDays}
                votingExpiresPeriodDays={votingExpiresPeriodDays}
              />
            ))
          : null}
        {data?.length > 0 && (
          <div
            style={{
              width: '100%',
              height: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: darkMode ? WHITE_MAIN : '#000',
            }}
          >
            {this.state.fetching ? <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} /> : i18n.t('DASHBOARD.noPostToShow')}
          </div>
        )}
        {!data || data.length === 0 ? (
          <div
            style={{
              width: '100%',
              height:
                path === routes.ROOT_ROUTE || path === routes.HOME_ROUTE || path === routes.DASHBOAR_ROUTE
                  ? 'calc(100vh - 120px)'
                  : 450,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* {fetching ? ( */}
              <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} size='big' />
            {/* // ) : (
            //   <NotFoundItem type='post' isBlue={darkMode} />
            // )} */}
          </div>
        ) : null}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() =>
            this.setState({
              isAlertOpen: false,
            })
          }
        />
      </ScrollableDiv>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveUserPosts: bindActionCreators(actions.saveUserPosts, dispatch),
    saveSearchPosts: bindActionCreators(actions.saveSearchPosts, dispatch),
    updateUserPostsItem: bindActionCreators(actions.updateUserPostsItem, dispatch),
    updateSearchPostsItem: bindActionCreators(actions.updateSearchPostsItem, dispatch),
    removeSearchPostsItem: bindActionCreators(actions.removeSearchPostsItem, dispatch),
    removeUserPostsItem: bindActionCreators(actions.removeUserPostsItem, dispatch),
    openVerifyModal: bindActionCreators(actions.openVerifyModal, dispatch),
  };
}

const DashboardWithRouter = withRouter(Dashboard);

function mapStateToProps(state) {
  const { userPosts, user, darkMode, searchPosts } = state.user;
  return {
    userPosts,
    user,
    darkMode,
    searchPosts,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWithRouter);
