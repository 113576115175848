import React from 'react';
import { throttle } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { getExpenses } from './ggExpensesActions';
import { isUserValid } from '../../utils/isUserValid';
import SearchFilter from '../../components/search-filter/SearchFilter';
import i18n from '../../i18n/i18n';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import { AppLoader } from '../../utils/animations/animations';
import { FEDERAL_BLUE } from '../../utils/color-palatte';
import IncomesItem from '../../components/incomes-list/incomesItem';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';
import { Translation } from 'react-i18next';
import Alert from '../../utils/alert/Alert';

class GGExpensesList extends React.Component {
  constructor() {
    super();
    this.state = {
      fetching: false,
      skipItems: 0,
      limitItems: 5,
      plusPostNumber: 5,
      keepIncreasingCounter: false,
      search: '',
    };
  }

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    
    isUserValid(false, this._isMounted);
    if (this._isMounted) this.onRefreshExpensesList();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  fetchExpensesList = () => {
    return new Promise(async (resolve, reject) => {
      const { skipItems, limitItems, fetching, search, expenses = [], toDate = '', sinceDate = '' } = this.state;
      const { user } = this.props;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const newExpenses = await getExpenses({
              skipItems,
              limitItems,
              search,
              admin: user.admin,
              toDate,
              sinceDate,
            });

            let finalExpensesList = [];
            finalExpensesList = expenses
              .concat(newExpenses)
              .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
              .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
            this.setState({
              expenses: finalExpensesList,
              fetching: false,
              keepIncreasingCounter: newExpenses.length > 0,
            });
            resolve();
          } catch (e) {
            if (e.status === 404) {
              this.setState({
                fetching: false,
              });
              reject(e);
            } else {
              this.setState({
                fetching: false,
              });
              const error = e?.data?.error?.message ?? e?.data?.error ?? i18n.t('GENERAL_ERRORS.serverError');
              this.showAlert(true, 'error', error);
            }
          }
        });
      }
    });
  };

  onRefreshExpensesList = () => {
    const { plusPostNumber } = this.state;
    this.setState(
      {
        skipItems: 0,
        limitItems: plusPostNumber,
        expenses: [],
        showModal: false,
      },
      () => {
        this.fetchExpensesList();
      }
    );
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchExpensesList();
        }
      );
    } else {
      this.fetchExpensesList();
    }
  };

  throtled = throttle(async () => {
    this.onRefreshExpensesList();
  }, 1000);

  setValue = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const {
      fetching,
      search,
      expenses,
      // showModal
    } = this.state;
    const { user, darkMode } = this.props;
    // console.log('expenses', expenses);

    return (
      <Translation>
        {(t) => (
          <ScrollableDiv
            style={{
              display: 'flex',
              justifyContent: fetching ? 'center' : 'flex-start',
              alignItems: 'center',
              width: '100%',
              maxWidth: '800px',
              minHeight: fetching ? 'calc(100vh - 100px)' : 'unset',
              margin: '0 auto',
              flexFlow: 'column nowrap',
              padding: 10,
              boxSizing: 'border-box',
            }}
            onEndReach={this.onEndReached}
          >
            <SearchFilter inputPlaceholder={t('EXPENSES.searchPlaceholder')} onRefresh={this.onRefreshExpensesList} setValue={this.setValue} />
            {(!expenses || expenses?.length === 0) && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'calc(100vh - 180px)' }}>
                {fetching ? (
                  <AppLoader color={darkMode ? '#fff' : FEDERAL_BLUE} size='big' />
                ) : (
                  <NotFoundItem isBlue={darkMode} type='income' text={search ? t('EXPENSES.notFound') : t('EXPENSES.noYet')} />
                )}
              </div>
            )}
            {expenses && expenses.length > 0
              ? expenses.map((item, i) => <IncomesItem isExpense key={i} item={item} user={user} darkMode={darkMode} />)
              : null}
            {expenses &&
              expenses?.length > 0 &&
              (fetching ? (
                <div style={{ width: '100%', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <AppLoader size='40px' color={darkMode ? '#fff' : FEDERAL_BLUE} />
                </div>
              ) : (
                <div style={{ width: '100%', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <p style={{ color: darkMode ? '#FFF' : '#000' }}>{t('EXPENSES.noMoreItems')}</p>
                </div>
              ))}
            <Alert
              isOpen={this.state.isAlertOpen}
              type={this.state.alertType}
              content={this.state.alertContent}
              onRequestClose={() =>
                this.setState({
                  isAlertOpen: false,
                })
              }
            />
          </ScrollableDiv>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GGExpensesList);
