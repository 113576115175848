import React from 'react';
import i18n from '../../i18n/i18n';
import './style.css';
import { connect } from 'react-redux';
import { getVoting } from './voting-list-actions';
import { FEDERAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import getStat from '../../utils/getStat';
import { isUserValid } from '../../utils/isUserValid';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';
import Alert from '../../utils/alert/Alert';
import { throttle } from 'lodash';
import VotingItem from '../../components/voting/votingItem';
import SearchFilter from '../../components/search-filter/SearchFilter';
import { AppLoader } from '../../utils/animations/animations';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';

class VotingHistory extends React.Component {
  constructor() {
    super();
    this.state = {
      fetching: false,
      skipItems: 0,
      limitItems: 5,
      plusPostNumber: 5,
      keepIncreasingCounter: false,
      search: '',
      voting: null,
      votings: [],
      isOfficialUser: false,
      rangeDatesFileringOpen: false,
      toDate: '',
      sinceDate: '',
    };
  }

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(false, this._isMounted);
    if (this._isMounted) await this.getStats();
    if (this._isMounted) this.onRefreshVotingList();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const positions = await getStat('positions');
        const users = await getStat('users');
        const positionsPeriodDays = await getStat('position-expires-period-days');
        const votingExpiresPeriodDays = await getStat('voting-expires-period-days');
        const votingMinVotingAssistance = await getStat('min-voting-assistance');
        // console.log("getStats / response: ", response);
        this.setState({ voting: { positions, users, votingMinVotingAssistance }, positionsPeriodDays, votingExpiresPeriodDays });
        resolve();
      } catch (e) {
        resolve();
        // console.log("getStats / error: ", e);
      }
    });
  };

  fetchVotingHistory = () => {
    // console.log('fetchVotingHistory');
    return new Promise(async (resolve, reject) => {
      // const { votingsUser } = this.props.route.params;
      const { skipItems, limitItems, search, votings, sinceDate, toDate } = this.state;
      const { state } = this.props.location;
      const { user } = this.props;
      const routeUserId = this.props.match.params.userId;
      const userId = routeUserId ? routeUserId : user._id;
      // console.log("fetchVotingHistory / userId: ", userId)
      this.setState({ fetching: true }, async () => {
        try {
          const newVoting = await getVoting(
            userId !== undefined && state?.user && userId ? userId : routeUserId ? userId : "",
            skipItems,
            limitItems,
            search,
            user.admin,
            sinceDate,
            toDate
          );
          // console.log("fetchVotingHistory / getVoting / newVoting: ", newVoting);
          let finalVotingList = [];
          finalVotingList = newVoting
            .concat(votings)
            .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id));
          // .sort((a, b) => b.approvesCount - a.approvesCount);

          // console.log("fetchVotingHistory / finalVotingList: ", finalVotingList)
          // console.log("isOfficial: ", isOfficial)
          this.setState({
            votings: finalVotingList,
            fetching: false,
            keepIncreasingCounter: newVoting.length > 0,
          });
          resolve();
        } catch (e) {
          // console.log("fetchVotingHistory / error: ", e)
          if (e.status === 404) {
            this.setState({ fetching: false });
            resolve();
          } else {
            reject(e);
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
            this.showAlert(true, 'wrong', '', error);
          }
        }
      });
    });
  };

  onRefreshVotingList = () => {
    // const { saveVotings, saveVotingRequests } = this.props;
    const { plusPostNumber } = this.state;
    // saveVotings([]);
    // saveVotingRequests([]);
    this.setState({ skipItems: 0, limitItems: plusPostNumber, votings: [] }, () => {
      this.fetchVotingHistory();
    });
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchVotingHistory();
        }
      );
    } else {
      this.fetchVotingHistory();
    }
  };

  throtled = throttle(async () => {
    this.onRefreshVotingList();
  }, 1000);

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  setValue = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      fetching,
      voting,
      votings,
      positionsPeriodDays,
      votingExpiresPeriodDays,
      // isOfficialUser
    } = this.state;
    // const { isOfficial, votingsUser } = this.props.route.params;
    const { user, darkMode } = this.props;
    // console.log("VotingHistory / votings: ", votings);

    // console.clear()
    // console.log(votings)
    return (
      <ScrollableDiv
        style={{
          width: 'calc(100% - 20px)',
          maxWidth: '800px',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexFlow: 'column nowrap',
        }}
        onEndReach={this.onEndReached}
      >
        <SearchFilter
          inputPlaceholder={i18n.t('VOTING_HISTORY.searchInputPlaceholder')}
          onRefresh={this.onRefreshVotingList}
          setValue={this.setValue}
          showPostsFilters={false}
        />
        {votings && votings.length > 0
          ? votings.map((item, idx) => (
              <VotingItem
                item={item}
                // hideMenu
                key={idx}
                darkMode={darkMode}
                isHistory
                voting={voting}
                positionsPeriodDays={positionsPeriodDays}
                votingExpiresPeriodDays={votingExpiresPeriodDays}
                user={user}
                voteFunction={this.voteFunction}
              />
            ))
          : null}
        {fetching && votings?.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 'calc(100vh - 250px)',
            }}
          >
            <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} size='big' />
          </div>
        ) : null}
        {fetching && votings?.length > 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '80px',
            }}
          >
            <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} size='50px' />
          </div>
        ) : null}
        {!fetching && votings.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              minHeight: 'calc(100vh - 250px)',
            }}
          >
            <NotFoundItem text={i18n.t('VOTING_GENERAL.notFound')} type='votes' isBlue={darkMode} />
          </div>
        ) : null}
        {/* </View> */}
        <Alert
          isOpen={this.state.isModalOpen}
          type={this.state.modalType}
          title={this.state.modalTitle}
          content={this.state.modalContent}
          choose={this.state.modalChoose}
          NOClick={this.state.noAction}
          noButtonText={this.state.noText}
          YESClick={this.state.yesAction}
          yesButtonText={this.state.yesText}
          singleButtonText={this.state.btnTxt}
          onRequestClose={() => this.showAlert(false)}
          loading={this.state.actionsLoader}
        />
      </ScrollableDiv>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VotingHistory);
