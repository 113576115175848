import {
  getData,
  postData,
  putData,
  // deleteData
} from '../../fetch';
import { store } from '../../redux/configureStore';
import deleteFiles from '../../utils/delete-files';
import * as types from '../../redux/types/user/types';
import * as ChievTypes from '../../redux/types/achievements/types';
import { validateNewAchievements } from '../../utils/validateNewAchievements';

export const createPost = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/post/create-post-web', data);
      // console.log(response)
      resolve(response);
    } catch (e) {
      console.log(e)
      reject(e);
    }
  });
};

/* export const validatePayment = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/payments/validate-user-payment', data);
      // console.log("validatePayment / response: ", response)
      resolve(response);
      resolve();
    } catch (e) {
      // console.log("validatePayment / error: ", e)
      reject(e);
    }
  })
}; */

export const getPosts = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(
        `/post/get-posts-web/`,
        data
      );
      // console.log("getPosts / response: ", response);
      const posts = response.body;
      return resolve(posts);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  });
};

export const getPost = (postId, postType = 'normal') => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/post/get-post-web/?postId=${postId}&postType=${postType}`);
      // console.log("getPost / response: ", response);
      const post = response.body;
      // console.log("getPost / post: ", post);
      return resolve(post);
    } catch (e) {
      // console.log("getPost / error: ", e);
      return reject(e);
    }
  });
};

export const confirmEmail = (token) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/user/confirm-email', token);
      resolve(response)
    } catch (e) {
      reject(e)
    }
  })

export const deletePost = (postId, postUserId, files) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const adminId = localStorage.getItem("adminId")
      // const adminCode = localStorage.getItem("adminCode")
      const userId = localStorage.getItem('userId');
      if (files && files.length > 0) {
        await deleteFiles(files.map((file) => file.uri));
      }
      const data = {
        // adminId,
        // adminCode,
        userId,
        postId,
        postUserId,
      };
      const response = await postData(`/post/remove-post-web`, data);
      // console.log("deletePost / response: ", response);
      return resolve(response);
    } catch (e) {
      // console.log("deletePost / error: ", e);
      return reject(e);
    }
  });
};

export const like = (userId, postId) => {
  return new Promise(async (resolve, reject) => {
    const data = { user: userId, post: postId };
    try {
      const response = await putData(`/post/like-post-web`, data);
      // console.log("like / response: ", response);
      return resolve(response);
    } catch (e) {
      // console.log("like / error: ", e);
      return reject(e);
    }
  });
};

export const unlike = (userId, postId) => {
  return new Promise(async (resolve, reject) => {
    const data = { user: userId, post: postId };
    try {
      const response = await putData(`/post/unlike-post-web`, data);
      // console.log("unlike / response: ", response);
      return resolve(response);
    } catch (e) {
      // console.log("unlike / error: ", e);
      return reject(e);
    }
  });
};

export const reportPost = (userId, postId) => {
  return new Promise(async (resolve, reject) => {
    const data = { user: userId, post: postId };
    try {
      const response = await putData(`/post/report-post-web`, data);
      // console.log("reportPost / response: ", response);
      return resolve(response);
    } catch (e) {
      // console.log("reportPost / error: ", e);
      return reject(e);
    }
  });
};

export const getUserAchievements = (id, user) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await getData(`/user/get-user-achievements-web/?userId=${id ? id : userId}`);
      // console.log("getUserAchievements / response: ", response);
      const achievements = response.body;
      // saveUser({ ...user, achievements: achievements })
      store.dispatch({ type: types.SAVE_USER_ACHIEVEMENTS, data: achievements });
      validateNewAchievements(achievements);
      return resolve(achievements);
    } catch (e) {
      // console.log("getUserAchievements / error: ", e);
      return reject(e);
    }
  });
};

export const makeAchievementSeen = (achievementId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem('userId');
      store.dispatch({ type: ChievTypes.CLOSE_NEW_ACHIEVEMENT_MODAL });
      const response = await postData(`/user/make-achievement-seen-web/?userId=${userId}&achievementId=${achievementId}`);
      // console.log("makeAchievementSeen / response: ", response);
      const posts = response.body;
      return resolve(posts);
    } catch (e) {
      // console.log("makeAchievementSeen / error: ", e);
      return reject(e);
    }
  });
};
