import React from 'react';
import { withRouter } from 'react-router-dom';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
// import { isUserValid } from '../../utils/isUserValid';
import i18n from '../../i18n/i18n';
import PostCard from '../../components/post/postcard';
import getStat from '../../utils/getStat';
import { deletePost, getPost, like, reportPost, unlike } from '../dashboard/dashboard-actions';
import { DASHBOAR_ROUTE } from '../../routes/routes';

import './postDetails.css';
import Alert from '../../utils/alert/Alert';
import { AppLoader } from '../../utils/animations/animations';
import { FEDERAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import { isUserValid } from '../../utils/isUserValid';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';

class PostDetails extends React.Component {
  state = {
    fetching: true,
    skipItems: 0,
    limitItems: 10,
    plusPostNumber: 3,
    keepIncreasingCounter: false,
    comments: [],
    post: null,
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      await this.getStats();
    }
    isUserValid(false, this._isMounted);
    if (this._isMounted) {
      await this.fetchPost();
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise((resolve, _) => {
      this.setState({ loading: true }, async () => {
        try {
          const users = await getStat('users');
          const positions = await getStat('positions');
          const positionsPeriodDays = await getStat('position-expires-period-days');
          const votingExpiresPeriodDays = await getStat('voting-expires-period-days');
          this.setState({
            positions,
            users,
            positionsPeriodDays,
            votingExpiresPeriodDays,
          });
          resolve();
        } catch (e) {
          // console.log('postComments / getStats:', e);
          resolve();
        }
      });
    });
  };

  fetchPost = () => {
    return new Promise(async (resolve, reject) => {
      const { post } = this.props.match.params;
      // const { fetching } = this.state;
      try {
        const response = await getPost(post);
        this.setState({
          fetching: false,
          post: response[0],
        });
        resolve();
      } catch (e) {
        // console.log('postComments / fetchPost:', e);
        // const error =
        //   e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        // console.log('fetchPost / postComment / error:', error);
        this.setState({ fetching: false });
        reject(e);
      }
    });
  };

  likeFunction = (postId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        await like(user._id, postId);
        await this.updatePostItem(postId);
        resolve();
      } catch (e) {
        const error = e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          reject(e);
          return;
        }
        this.showAlert(true, 'error', error);
        reject();
      }
    });
  };

  unlikeFunction = (postId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        await unlike(user._id, postId);
        await this.updatePostItem(postId);
        resolve();
      } catch (e) {
        const error = e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          reject(e);
          return;
        }
        this.showAlert(true, 'error', error);
        reject();
      }
    });
  };

  updatePostItem = async (postId) => {
    // eslint-disable-next-line
    const { updateUserPostItem, updateSearchPostItem } = this.props;
    const postUpdated = await getPost(postId);

    updateUserPostItem(postUpdated[0]);
    // updateSearchPostItem(postUpdated[0]);
    this.fetchPost();
  };

  removePost = (postId, userId, files) => {
    return new Promise(async (resolve, reject) => {
      const { removeSearchPostsItem, removeUserPostsItem, removeProfilePostsItem } = this.props;
      try {
        const response = await deletePost(postId, userId, files);
        this.showAlert(true, 'succes', response.body);
        removeSearchPostsItem(postId);
        removeUserPostsItem(postId);
        removeProfilePostsItem(postId);
        // console.log('response', response);
        this.props.history.push(DASHBOAR_ROUTE);
      } catch (e) {
        const error = e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        // console.log('%cError:', 'color: #F00, font-size: 20px', error);
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  reportPost = (postId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await reportPost(user._id, postId);
        // console.log('reportPost / response:', response);
        this.showAlert(true, 'success', response);
        this.fetchPost();
        resolve();
      } catch (e) {
        const error = e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        // console.log('%cError:', 'color: #F00, font-size: 20px', error);
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    // eslint-disable-next-line
    const { fetching, post, positions, users, positionsPeriodDays, votingExpiresPeriodDays } = this.state;

    const { user, darkMode } = this.props;

    return (
      <ScrollableDiv
        className='PostDetails__Container'
        // onEndReach={() => this.onEndReached()}
      >
        {fetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 100px)',
            }}
          >
            <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} size='big' />
          </div>
        ) : null}
        {!fetching && post ? (
          <PostCard
            item={post}
            positions={positions}
            removePost={this.removePost}
            reportPost={this.reportPost}
            likeFunction={this.likeFunction}
            unlikeFunction={this.unlikeFunction}
            darkMode={darkMode}
            user={user}
            positionsPeriodDays={positionsPeriodDays}
            votingExpiresPeriodDays={votingExpiresPeriodDays}
            stats={{ positions, users }}
          />
        ) : null}
        {!fetching && !post ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 100px)',
            }}
          >
            <NotFoundItem type='post' isBlue={darkMode} />
          </div>
        ) : null}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() =>
            this.setState({
              isAlertOpen: false,
            })
          }
        />
      </ScrollableDiv>
    );
  }
}

const PostDetailsWithRouter = withRouter(PostDetails);

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserPostItem: bindActionCreators(actions.updateUserPostsItem, dispatch),
    removeSearchPostsItem: bindActionCreators(actions.removeSearchPostsItem, dispatch),
    removeUserPostsItem: bindActionCreators(actions.removeUserPostsItem, dispatch),
    removeProfilePostsItem: bindActionCreators(actions.removeProfilePostsItem, dispatch),
    openVerifyModal: bindActionCreators(actions.openVerifyModal, dispatch)
  };
};

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostDetailsWithRouter);
