import { getData } from "../../fetch";

export const getVoting = (userId, skipItems, limitItems, search, admin, sinceDate, toDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/voting-history/get-voting-web/?userId=${userId}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&admin=${admin}&sinceDate=${sinceDate}&toDate=${toDate}`);
      // console.log("getPosts / response: ", response);
      const posts = response.body;
      return resolve(posts);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  })
};