import React from 'react';
import './createVote.css';
import SelectInput from '../../utils/app-inputs/select-input/select-input';
import { Trans } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { DEALS_LIST_ROUTE, LAWS_LIST_ROUTE, VOTING_DETAILS_ROUTE } from '../../routes/routes';
import { createVotingRequest } from './create-voting-actions';
import handleVotingTypesNames from '../../utils/handleVotingTypeNames';
import { Warning } from '../../components/warning/Warning';
import getStat from '../../utils/getStat';
import moment from 'moment';
import Alert from '../../utils/alert/Alert';
import { AppLoader } from '../../utils/animations/animations';
import { isUserValid } from '../../utils/isUserValid';
import { BSpan, ParsedText } from '../../utils/text-utils/text-utils';
import { FEDERAL_GREEN, FEDERAL_RED, WHITE_MAIN } from '../../utils/color-palatte';
import InfoModal from '../../components/info-modals/info-modals';
import CustomTextInput from '../../utils/app-inputs/custom-text-input/CustomTextInput';
import handlePositions from '../../utils/handlePositionName';
import warningIcon from '../../assets/votes/warning.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/create-voting/actions';
import { actionCreators as userActions } from '../../redux/actions/user/actions';
import handleDealTypeName from '../../utils/handleDealTypeName';
import { getLawDetails } from '../../components/laws-list/lawsListActions';
import VotingItem from '../../components/voting/votingItem';
import { getDealDetails } from '../../components/deals-list/dealsListActions';

class CreateVote extends React.Component {
  state = {
    loading: true,
    positions: null,
    law: null,
    positionsPeriodDays: null,
    fetching: false,
    modal: {
      isOpen: false,
      type: null,
      data: {},
    },
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this.props.match.params) {
      const { type, lawToRemove, dealToRemove } = this.props.match.params;

      if (type && (lawToRemove || dealToRemove)) {
        this.setState({ type, lawToRemove, dealToRemove });
      }
    }

    const { lawToRemove, dealToRemove } = this.props;

    if (lawToRemove) {
      if (!this._isMounted) return;
      this.fetchLaw();
    }
    if (dealToRemove) {
      if (!this._isMounted) return;
      this.fetchDeal();
    }
    isUserValid(false, this._isMounted);
    if (this._isMounted) {
      this.getStats();
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  fetchDeal = () => {
    return new Promise(async (resolve, reject) => {
      const { fetching } = this.state;
      const { dealToRemove } = this.props;
      if (!fetching) {
        try {
          const response = await getDealDetails(dealToRemove);
          // console.log("fetchDeal / response: ", response);
          this.setState({
            deal: response[0],
          });
          this.setState({ fetching: false });
          resolve();
        } catch (e) {
          if (e.status === 404) {
            this.setState({ fetching: false });
            resolve();
          } else {
            if (e.status === 404) {
              this.setState({ fetching: false });
              resolve();
            } else {
              reject(e);
              // console.log("fetchDeal / error: ", e)
              this.setState({ fetching: false });
              const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
              this.setState({
                isMessageModalOpen: true,
                messageModalMessage: error,
                messageModalType: 'error',
              });
            }
          }
        }
      }
    });
  };

  fetchLaw = () => {
    return new Promise(async (resolve, reject) => {
      const { lawToRemove } = this.props;
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getLawDetails(lawToRemove);
            // console.log("fetchLaw / post: ", post);
            this.setState({
              law: response[0],
              fetching: false,
            });
            resolve();
          } catch (e) {
            if (e.status === 404) {
              this.setState({ fetching: false });
              resolve();
            } else {
              reject(e);
              // console.log("fetchLaw / error: ", e)
              this.setState({ fetching: false });
              const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
              this.setState({
                isMessageModalOpen: true,
                messageModalMessage: error,
                messageModalType: 'error',
              });
            }
          }
        });
      }
    });
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const positions = await getStat('positions');
        const positionsPeriodDays = await getStat('position-expires-period-days');
        const votingExpiresPeriodDays = await getStat('voting-expires-period-days');
        const timeToApproveDealDays = await getStat('time-to-approve-deal-days');
        const citizenGainFromDeal = await getStat('citizen-gain-from-deal');
        const maintenanceTaxes = await getStat('maintenance-taxes');
        const presidentSalaryPorcentage = await getStat('president-salary-porcentage');
        const vicepresidentSalaryPorcentage = await getStat('vicepresident-salary-porcentage');
        // console.log("getStats / response: ", response);
        this.setState({
          positions,
          positionsPeriodDays,
          votingExpiresPeriodDays,
          maintenanceTaxes,
          presidentSalaryPorcentage,
          vicepresidentSalaryPorcentage,
          timeToApproveDealDays,
          citizenGainFromDeal,
          loading: false,
        });
        resolve();
      } catch (e) {
        resolve();
        // console.log("getStats / error: ", e);
      }
    });
  };

  createVotingRequest = async () => {
    const { title, description, type, position, postulate, lawToRemove, dealToRemove, dealType, clearFields } = this.props;
    if (
      title &&
      title.trim() &&
      description &&
      description.trim() &&
      type &&
      (type === 'position' ? position && postulate : true) &&
      (type === 'deal' ? dealType : true) &&
      (type === 'removeLaw' ? lawToRemove : true) &&
      (type === 'removeDeal' ? dealToRemove : true)
    ) {
      this.setState({ loading: true }, async () => {
        try {
          const userId = localStorage.getItem('userId');
          const data = {
            title,
            description,
            user: userId,
            type,
            position,
            postulate: postulate.replace('@', ''),
            dealType: dealType,
            lawToRemove: lawToRemove ? lawToRemove : '',
            dealToRemove: dealToRemove ? dealToRemove : '',
          };
          // console.log("data: ", data)
          const response = await createVotingRequest(data);
          // console.log("createVotingRequest / voting: ", response);
          this.setState({ loading: false, title: '', description: '', type: '', postulate: '' });
          this.setState({
            isMessageModalOpen: true,
            messageModalMessage: i18n.t('VOTING_REQUEST.votingRequestCreated'),
            messageModalType: 'success',
          });
          clearFields();
          this.props.history.replace(`${VOTING_DETAILS_ROUTE}/${response.body._id}`);
        } catch (e) {
          // console.log('createVotingRequest / error: ', e);
          this.setState({ loading: false });
          const error =
            e && e.data && e.data.error
              ? e.data.error.message
                ? e.data.error.message
                : e.data.error
              : i18n.t('ERRORS.serverError');
          if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
            this.props.openVerifyModal();
            return;
          }
          this.setState({
            isMessageModalOpen: true,
            messageModalMessage: error,
            messageModalType: 'error',
          });
        }
      });
    } else {
      if (!type) {
        return this.setState({
          isMessageModalOpen: true,
          messageModalMessage: i18n.t('VOTING_REQUEST.missingType'),
          messageModalType: 'wrong',
        });
      }
      if (!title) {
        return this.setState({
          isMessageModalOpen: true,
          messageModalMessage: i18n.t('VOTING_REQUEST.missingTitle'),
          messageModalType: 'wrong',
        });
      }
      if (!description) {
        return this.setState({
          isMessageModalOpen: true,
          messageModalMessage: i18n.t('VOTING_REQUEST.missingDescription'),
          messageModalType: 'wrong',
        });
      }
      if (type === 'position' && !position) {
        return this.setState({
          isMessageModalOpen: true,
          messageModalMessage: i18n.t('VOTING_REQUEST.missingPosition'),
          messageModalType: 'wrong',
        });
      }
      if (type === 'deal' && !dealType) {
        return this.setState({
          isMessageModalOpen: true,
          messageModalMessage: i18n.t('VOTING_REQUEST.missingDealType'),
          messageModalType: 'wrong',
        });
      }
      if (type === 'position' && !postulate) {
        return this.setState({
          isMessageModalOpen: true,
          messageModalMessage: i18n.t('VOTING_REQUEST.missingPostulate'),
          messageModalType: 'wrong',
        });
      }
      if (type === 'removeLaw' && !lawToRemove) {
        return this.setState({
          isMessageModalOpen: true,
          messageModalMessage: i18n.t('VOTING_REQUEST.missingLawToRemove'),
          messageModalType: 'wrong',
        });
      }
      if (type === 'removeDeal' && !dealToRemove) {
        return this.setState({
          isMessageModalOpen: true,
          messageModalMessage: i18n.t('VOTING_REQUEST.missingDealToRemove'),
          messageModalType: 'wrong',
        });
      }
    }
  };

  openModal = (isOpen, type) => {
    this.setState({
      modal: {
        isOpen: isOpen,
        type: type,
      },
    });
  };

  render() {
    const {
      loading,
      positions,
      positionsPeriodDays,
      votingExpiresPeriodDays,
      timeToApproveDealDays,
      maintenanceTaxes,
      citizenGainFromDeal,
      presidentSalaryPorcentage,
      vicepresidentSalaryPorcentage,
      modal,
      law,
      deal,
      fetching,
    } = this.state;
    const { title, description, type, position, postulate, lawToRemove, dealToRemove, saveField, clearFields, user, dealType } =
      this.props;

    const { darkMode } = this.props;
    let blockPosition = false;
    let currentPosition = null;
    // console.log('positions: ', positions);
    // console.log('position: ', position);
    if (position && positions) {
      currentPosition = positions.filter((item) => String(item.position) === String(position))[0];
      if (currentPosition) {
        blockPosition = true;
      }
    }

    // const GSpan = ({ onClick, children }) => (
    //   <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={onClick}>
    //     {children}
    //   </BSpan>
    // );

    const values = [
      {
        keyword: `${
          maintenanceTaxes && presidentSalaryPorcentage && vicepresidentSalaryPorcentage
            ? 100 - Number(maintenanceTaxes) - (Number(presidentSalaryPorcentage) + Number(vicepresidentSalaryPorcentage))
            : '...'
        }%`,
        style: { fontWeight: 'bold', color: '#fff', cursor: 'normal' },
      },
      {
        keyword: `${maintenanceTaxes && citizenGainFromDeal ? Number(maintenanceTaxes) - Number(citizenGainFromDeal) : '...'}%`,
        style: { fontWeight: 'bold', color: '#fff', cursor: 'normal' },
      },
      {
        keyword: `${presidentSalaryPorcentage ? presidentSalaryPorcentage : '...'}%`,
        style: { fontWeight: 'bold', color: '#fff', cursor: 'normal' },
      },
      {
        keyword: `${vicepresidentSalaryPorcentage ? vicepresidentSalaryPorcentage : '...'}%`,
        style: { fontWeight: 'bold', color: '#fff', cursor: 'normal' },
      },
      {
        keyword: `${citizenGainFromDeal ? citizenGainFromDeal : '...'}%`,
        style: { fontWeight: 'bold', color: '#fff', cursor: 'normal' },
      },
      {
        keyword: `${timeToApproveDealDays ? timeToApproveDealDays : '...'}`,
        style: { fontWeight: 'bold', color: '#fff', cursor: 'normal' },
      },
    ];

    // console.log('postulate: ', postulate);
    return (
      <div className='CreateVote__Container'>
        {!loading ? (
          <form action='' className='CreateVote__Form'>
            <h1 className='CreateVote__H1'>{i18n.t('VOTING_REQUEST.formulate')}</h1>
            <SelectInput
              isWhite={darkMode}
              value={type}
              onChange={(item) => {
                clearFields();
                saveField('type', item.target.value);
                saveField('dealToRemove', '');
                saveField('lawToRemove', '');
                this.setState({ law: '', deal: '' });
              }}
            >
              <option value='' disabled selected>
                {i18n.t('VOTING_REQUEST.type')}
              </option>
              <option value='position'>{handleVotingTypesNames('position')}</option>
              <option value='law'>{handleVotingTypesNames('law')}</option>
              <option value='deal'>{handleVotingTypesNames('deal')}</option>
              <option value='removeLaw'>{handleVotingTypesNames('removeLaw')}</option>
              <option value='removePosition'>{handleVotingTypesNames('removePosition')}</option>
              <option value='removeDeal'>{handleVotingTypesNames('removeDeal')}</option>
            </SelectInput>
            {type === 'deal' ? (
              <Warning
                darkMode={darkMode}
                title={i18n.t('VOTING_REQUEST.warning')}
                content={
                  <ParsedText
                    string={i18n.t('VOTING_REQUEST.warningInfoDeals')(
                      maintenanceTaxes,
                      citizenGainFromDeal,
                      presidentSalaryPorcentage,
                      vicepresidentSalaryPorcentage,
                      timeToApproveDealDays
                    )}
                    values={values}
                    hasWiki
                    markStyle={{ color: FEDERAL_GREEN, fontWeight: 'bold' }}
                  />
                }
              />
            ) : null}
            {type === 'law' ? (
              <Warning darkMode={darkMode} title={i18n.t('VOTING_REQUEST.warning')} content={i18n.t('LAWS.warningInfo')} />
            ) : null}
            {type === 'position' ? (
              <Warning
                darkMode={darkMode}
                title={i18n.t('VOTING_REQUEST.warning')}
                content={
                  <Trans i18nKey='VOTING_REQUEST.warningInfoPositions'>
                    bla
                    <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'president')}>
                      bla
                    </BSpan>
                    bla
                    <BSpan
                      style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }}
                      onClick={() => this.openModal(true, 'vicepresident')}
                    >
                      bla
                    </BSpan>
                    bla
                    <b>{{ positionsPeriodDays }}</b>
                    bla
                    <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'citizen')}>
                      bla
                    </BSpan>
                    bla
                    <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'citizen')}>
                      bla
                    </BSpan>
                    bla
                  </Trans>
                }
              />
            ) : null}
            {type === 'position' ? (
              <SelectInput
                isWhite={darkMode}
                value={position}
                onChange={(item) => {
                  saveField('position', item.target.value);
                }}
              >
                <option value='' disabled selected>
                  {i18n.t('VOTING_REQUEST.selectPosition')}
                </option>
                <option value='president'>{handlePositions('president')}</option>
                <option value='vicepresident'>{handlePositions('vicepresident')}</option>
              </SelectInput>
            ) : null}
            {type === 'deal' ? (
              <SelectInput
                value={dealType}
                isWhite={darkMode}
                onChange={(item) => {
                  saveField('dealType', item.target.value);
                }}
              >
                <option value='' disabled selected>
                  {i18n.t('VOTING_REQUEST.selectDealsType')}
                </option>
                <option value='capital-gain'>{handleDealTypeName('capital-gain')}</option>
                <option value='service'>{handleDealTypeName('service')}</option>
                <option value='both'>{handleDealTypeName('both')}</option>
              </SelectInput>
            ) : null}
            {blockPosition ? (
              <div
                style={{
                  width: '80%',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={warningIcon}
                  style={{
                    height: 30,
                    width: 30,
                  }}
                  alt=''
                />
                <p
                  style={{
                    color: FEDERAL_RED,
                    fontSize: 20,
                    margin: 20,
                    fontFamily: `Source Sans Pro', sans-serif`,
                  }}
                >
                  {`${i18n.t('VOTING_REQUEST.positionNotAvailableTill')} ${moment(currentPosition.createdAt)
                    .add(positionsPeriodDays, 'days')
                    .format('MM-DD-YYYY hh:mm A')}`}
                </p>
              </div>
            ) : null}
            {type === 'position' && !blockPosition && position ? (
              <CustomTextInput
                value={postulate}
                isWhite={darkMode}
                onChange={(e) => saveField('postulate', e.target.value)}
                placeholder={i18n.t('VOTING_REQUEST.postulateHere')}
              />
            ) : null}
            <CustomTextInput
              positions={positions}
              value={title}
              onChange={(e) => saveField('title', e.target.value)}
              placeholder={i18n.t('VOTING_REQUEST.titleHere')}
              isWhite={darkMode}
            />
            {/* <RegularInput className='CreateVote__Description' placeholder='Description'/> */}
            <CustomTextInput
              value={description}
              isWhite={darkMode}
              onChange={(e) => saveField('description', e.target.value)}
              placeholder={i18n.t('VOTING_REQUEST.decriptionHere')}
              isTextArea
              rows='10'
              style={{
                minHeight: '200px',
              }}
            />
            {type === 'removeLaw' && !lawToRemove ? (
              <button
                className='CreateVote__Button'
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(`${LAWS_LIST_ROUTE}/?action=pick`);
                }}
              >
                {i18n.t('VOTING_REQUEST.selectLaw')}
              </button>
            ) : null}
            {fetching ? <AppLoader color={darkMode && WHITE_MAIN} /> : null}
            {law ? <VotingItem isDone item={law} darkMode={darkMode} user={user} /> : null}
            {deal ? <VotingItem isDone item={deal} darkMode={darkMode} user={user} /> : null}
            {type === 'removeLaw' && law ? (
              <button
                className='CreateVote__Button'
                onClick={(e) => {
                  e.preventDefault();
                  saveField('lawToRemove', '');
                  this.setState({ law: '' });
                }}
              >
                {i18n.t('VOTING_REQUEST.removeSelectedLaw')}
              </button>
            ) : null}
            {type === 'removeDeal' && deal ? (
              <button
                className='CreateVote__Button'
                onClick={(e) => {
                  e.preventDefault();
                  saveField('dealToRemove', '');
                  this.setState({ deal: '' });
                }}
              >
                {i18n.t('VOTING_REQUEST.removeSelectedDeal')}
              </button>
            ) : null}
            {type === 'removeDeal' && !dealToRemove ? (
              <button
                className='CreateVote__Button'
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(`${DEALS_LIST_ROUTE}/?action=pick`);
                }}
              >
                {i18n.t('VOTING_REQUEST.selectDeal')}
              </button>
            ) : null}
            {/* {lawToRemove
                      ? <>
                        <Text style={[styles.lawToRemoveTitle, { color: darkMode ? WHITE : BLACK }]}>
                          {i18n.t('VOTING_REQUEST.lawToRemoveTitle')}:
                        </Text>
                        <VotingItem
                          item={lawToRemove}
                          navigation={this.props.navigation}
                          voteFunction={null}
                          terminateVoting={null}
                          positions={positions}
                          user={user}
                          lawMenu
                        />
                        <Button
                          style={styles.createButton2}
                          onPress={() => {
                            this.setState({ lawToRemove: "" })
                          }}
                        >
                          <Text style={styles.createButtonText2}>{i18n.t('VOTING_REQUEST.removeSelectedLaw')}</Text>
                        </Button>
                      </>
                      : null}
                    {dealToRemove
                      ? <>
                        <Text style={styles.lawToRemoveTitle}>
                          {i18n.t('VOTING_REQUEST.dealToRemoveTitle')}:
                        </Text>
                        <VotingItem
                          item={dealToRemove}
                          navigation={this.props.navigation}
                          voteFunction={null}
                          terminateVoting={null}
                          positions={positions}
                          user={user}
                          dealMenu
                        />
                        <Button
                          style={styles.createButton2}
                          onPress={() => {
                            this.setState({ dealToRemove: "" })
                          }}
                        >
                          <Text style={styles.createButtonText2}>{i18n.t('VOTING_REQUEST.removeSelectedDeal')}</Text>
                        </Button>
                      </>
                      : null} */}
            <button
              className='CreateVote__Button'
              onClick={(e) => {
                e.preventDefault();
                if (blockPosition) {
                  this.setState({
                    isMessageModalOpen: true,
                    messageModalMessage: `${i18n.t('VOTING_REQUEST.positionNotAvailableTill')} ${moment(currentPosition.createdAt)
                      .add(positionsPeriodDays, 'days')
                      .format('MM-DD-YYYY hh:mm A')}`,
                    messageModalType: 'error',
                  });
                } else {
                  this.createVotingRequest();
                }
              }}
            >
              {i18n.t('VOTING_REQUEST.create')}
            </button>
            <Warning
              darkMode={darkMode}
              title={i18n.t('VOTING_REQUEST.warning')}
              content={
                <Trans i18nKey='VOTING_REQUEST.warningInfo'>
                  bla
                  <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'voting')}>
                    bla
                  </BSpan>
                  bla
                  <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'voting')}>
                    bla
                  </BSpan>
                  bla
                  <b>{{ votingExpiresPeriodDays }}</b>
                  bla
                  <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'voting')}>
                    bla
                  </BSpan>
                  bla
                </Trans>
              }
            />
          </form>
        ) : (
          <AppLoader color={darkMode && WHITE_MAIN} />
        )}
        <InfoModal
          isOpen={modal.isOpen}
          type={modal.type}
          capitalData={modal.data}
          onRequestClose={() => this.setState({ modal: { isOpen: false, type: null } })}
        />
        <Alert
          isOpen={this.state.isMessageModalOpen}
          type={this.state.messageModalType}
          content={this.state.messageModalMessage}
          onRequestClose={() =>
            this.setState({
              isMessageModalOpen: false,
            })
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { darkMode, user } = state.user;
  const { title, description, type, position, postulate, lawToRemove, dealToRemove, dealType } = state.createVoting;
  return {
    darkMode,
    title,
    description,
    type,
    position,
    postulate,
    lawToRemove,
    dealToRemove,
    dealType,
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveField: bindActionCreators(actions.saveField, dispatch),
    clearFields: bindActionCreators(actions.clearFields, dispatch),
    openVerifyModal: bindActionCreators(userActions.openVerifyModal, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateVote);
