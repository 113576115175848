import { getData } from "../../fetch";;

export const getUserFollowing = (userId, skipItems, limitItems, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/user/get-user-following-web/?userId=${userId}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}`);
      // console.log("getPosts / response: ", response);
      const posts = response.body;
      return resolve(posts);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  })
};