import React from 'react';
import { withRouter } from 'react-router-dom';
import { getComments, createComment, reportComment, deleteComment } from './postCommentsActions';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import i18n from '../../i18n/i18n';
import PostCard from '../../components/post/postcard';
import getStat from '../../utils/getStat';
import { deletePost, getPost, like, reportPost, unlike } from '../dashboard/dashboard-actions';
import { DASHBOAR_ROUTE } from '../../routes/routes';
import CommentItem from '../../components/comments/commentItem';

import './postComments.css';
import Alert from '../../utils/alert/Alert';
import { AppLoader } from '../../utils/animations/animations';
import { FEDERAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import MessageInput from '../../components/message-input/messageInput';
import { isUserValid } from '../../utils/isUserValid';

class PostComments extends React.Component {
  state = {
    fetching: false,
    skipItems: 0,
    limitItems: 10,
    plusPostNumber: 3,
    keepIncreasingCounter: false,
    comments: [],
    post: null,
  };

  rvlRef = React.createRef();

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) await this.fetchPost();
    if (this._isMounted) await this.fetchComments();
    if (this._isMounted) await this.getStats();
    isUserValid(false, this._isMounted);
    if (this._isMounted) {
      if (this.rvlRef) {
        this.rvlRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true }, async () => {
        try {
          const users = await getStat('users');
          const positions = await getStat('positions');
          this.setState({
            positions,
            users,
          });
          resolve();
        } catch (e) {
          // console.log('postComments / getStats:', e);
          reject(e);
        }
      });
    });
  };

  fetchPost = () => {
    return new Promise(async (resolve, reject) => {
      const { post } = this.props.match.params;
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getPost(post);
            // console.log("fetchPost / post: ", post);
            this.setState({
              fetching: false,
              post: response[0],
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  likeFunction = (postId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        await like(user._id, postId);
        await this.updatePostItem(postId);
        resolve();
      } catch (e) {
        const error = e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        console.log(e)
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          reject()
          return;
        }
        this.showAlert(true, 'error', error);
        reject();
      }
    });
  };

  unlikeFunction = (postId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        await unlike(user._id, postId);
        await this.updatePostItem(postId);
        resolve();
      } catch (e) {
        const error = e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          return;
        }
        this.showAlert(true, 'error', error);
        reject();
      }
    });
  };

  updatePostItem = async (postId) => {
    // eslint-disable-next-line
    const { updateUserPostItem, updateSearchPostItem } = this.props;
    const postUpdated = await getPost(postId);

    updateUserPostItem(postUpdated[0]);
    // updateSearchPostItem(postUpdated[0]);
    this.fetchPost();
  };

  removePost = (postId, userId, files) => {
    return new Promise(async (_, reject) => {
      const { removeSearchPostsItem, removeUserPostsItem, removeProfilePostsItem } = this.props;
      try {
        const response = await deletePost(postId, userId, files);
        this.showAlert(true, 'succes', response.body);
        removeSearchPostsItem(postId);
        removeUserPostsItem(postId);
        removeProfilePostsItem(postId);
        // console.log('response', response);
        this.props.history.push(DASHBOAR_ROUTE);
      } catch (e) {
        const error = e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        // console.log('%cError:', 'color: #F00, font-size: 20px', error);
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  reportPost = (postId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await reportPost(user._id, postId);
        // console.log('reportPost / response:', response);
        this.showAlert(true, 'success', response);
        this.fetchPost();
        resolve();
      } catch (e) {
        const error = e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        // console.log('%cError:', 'color: #F00, font-size: 20px', error);
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  fetchComments = async () => {
    const { skipItems, limitItems, fetching, comments } = this.state;
    // const url_string = window.location.href;
    // const url = new URL(url_string);
    // const post = url.searchParams.get('post');
    const { post } = this.props.match.params;
    if (!fetching) {
      this.setState({ fetching: true }, async () => {
        try {
          const newPosts = await getComments(post, skipItems, limitItems, this.props.user.admin);
          // console.log('getComments / newPosts: ', newPosts);
          const finalComments = newPosts
            .concat(comments)
            .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id));
          // .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
          this.setState({
            fetching: false,
            keepIncreasingCounter: newPosts.length > 0,
            comments: finalComments,
          });
        } catch (e) {
          // console.log('postComments / fetchComments:', e);
          if (e.status === 404) {
            this.setState({ fetching: false });
          } else {
            // console.log('fetchPosts / error: ', e);
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error?.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        }
      });
    }
  };

  addComment = (search) => {
    return new Promise((resolve, reject) => {
      const { user } = this.props;
      const { post } = this.props.match.params;
      // console.log('post:', post);
      const data = {
        content: search,
        user,
        post: post,
      };
      // console.log('addComment / data: ', data);
      this.setState({ createCommentLoader: true }, async () => {
        try {
          const response = await createComment(data);
          this.setState({ createCommentLoader: false });
          // console.log('addComment / response: ', response);
          if (response.status === 201) {
            this.onRefreshComments();
            resolve();
          }
        } catch (e) {
          this.setState({ createCommentLoader: false });
          // console.log('addComment / error: ', e);
          const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
          if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
            this.props.openVerifyModal();
            reject();
            return;
          }
          this.showAlert(true, 'error', error);
          reject();
        }
      });
    });
  };

  onRefreshComments = () => {
    // const { plusPostNumber } = this.state;
    this.setState({ skipItems: 0, comments: [], limitItems: 10 }, () => {
      this.fetchComments();
    });
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchComments();
        }
      );
    } else {
      this.fetchComments();
    }
  };

  reportComment = (commentId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await reportComment(user._id, commentId);
        // console.log('reportComment / response:', response);
        this.showAlert(true, 'success', response.body);
        resolve();
      } catch (e) {
        // console.log("removePost / error: ", e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  removeComment = (commentId, userId, postId) => {
    const { comments } = this.state;
    // const { removeSearchPostsItem, removeUserPostsItem } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await deleteComment(commentId, userId, postId);
        this.showAlert(true, 'success', response.body);
        // removeSearchPostsItem(commentId);
        // removeUserPostsItem(commentId);
        this.setState({
          comments: comments.filter((comment) => comment._id !== commentId),
        });
        // this.onRefreshComments();
        resolve();
      } catch (e) {
        // console.log("removePost / error: ", e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const { fetching, post, positions, users, comments, createCommentLoader } = this.state;

    const { user, darkMode } = this.props;

    // console.log('%ccomments:', 'color: #00F, font-size: 22px', comments);

    return (
      <ScrollableDiv className='PostComments__Container' onEndReach={() => this.onEndReached()}>
        {post ? (
          <PostCard
            ref={this.rvlRef}
            item={post}
            positions={positions}
            removePost={this.removePost}
            reportPost={this.reportPost}
            likeFunction={this.likeFunction}
            unlikeFunction={this.unlikeFunction}
            darkMode={darkMode}
            user={user}
            stats={{ positions, users }}
            showComments={
              <>
                {comments && comments.length > 0
                  ? comments.map((comment, i) => (
                      <CommentItem
                        {...comment}
                        loggedUser={user}
                        darkMode={darkMode}
                        positions={positions}
                        removeComment={this.removeComment}
                        reportComment={this.reportComment}
                        openVerifyModal={this.props.openVerifyModal}
                        key={i}
                      />
                    ))
                  : null}
                <div
                  style={{
                    width: '100%',
                    height: '70px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: darkMode ? WHITE_MAIN : '#000',
                  }}
                >
                  {fetching && <AppLoader size='60px' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />}
                  {!fetching && comments?.length > 0 && i18n.t('COMMENTS.noCommentsToShow')}
                  {!fetching && comments?.length <= 0 && i18n.t('COMMENTS.noCommentsFound')}
                </div>
                <MessageInput
                  darkMode={darkMode}
                  addMessage={this.addComment}
                  fetching={createCommentLoader}
                  placeholder={i18n.t('COMMENTS.commentInput')}
                  user={user}
                  showErrors={false}
                />
              </>
            }
          />
        ) : (
          'post unavilable'
        )}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() =>
            this.setState({
              isAlertOpen: false,
            })
          }
        />
      </ScrollableDiv>
    );
  }
}

const PostCommentsWithrouter = withRouter(PostComments);

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserPostItem: bindActionCreators(actions.updateUserPostsItem, dispatch),
    removeSearchPostsItem: bindActionCreators(actions.removeSearchPostsItem, dispatch),
    removeUserPostsItem: bindActionCreators(actions.removeUserPostsItem, dispatch),
    removeProfilePostsItem: bindActionCreators(actions.removeProfilePostsItem, dispatch),
    openVerifyModal: bindActionCreators(actions.openVerifyModal, dispatch)
  };
};

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCommentsWithrouter);
