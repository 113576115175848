import { putData } from "../../fetch";

export const like = (userId, commentId) => {
  return new Promise(async (resolve, reject) => {
    const data = { user: userId, comment: commentId }
    try {
      const response = await putData(`/comment/like-comment-web`, data);
      // console.log("like / response: ", response);
      return resolve(response);
    } catch (e) {
      // console.log("like / error: ", e);
      return reject(e);
    }
  })
};

export const unlike = (userId, commentId) => {
  return new Promise(async (resolve, reject) => {
    const data = { user: userId, comment: commentId }
    try {
      const response = await putData(`/comment/unlike-comment-web`, data);
      // console.log("unlike / response: ", response);
      return resolve(response);
    } catch (e) {
      // console.log("unlike / error: ", e);
      return reject(e);
    }
  })
};