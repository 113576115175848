import React from 'react';
import { Translation } from 'react-i18next';
import { COMPANY_NAME } from '../../config';
import './mini-footer.css';
import { TERMS_AND_CONDITIONS_ROUTE, NOTICE_OF_PRIVACY_ROUTE } from '../../routes/routes';
import SafeLINK from '../../utils/safe-link';
import ReactFlagsSelect from 'react-flags-select';

const MiniFooter = () => {
  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 650px)').matches);

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 650px)').addEventListener('change', handler);
    return () => window.matchMedia('(max-width: 650px)').removeEventListener('change', handler);
  }, []);

  return (
    <Translation>
      {(t) => (
        <div className='App-footer'>
          <div className='app-footer-first-container'>
            <p>
              {COMPANY_NAME} | {t('GENERAL_TEXT.footer-text')}
            </p>
            <SafeLINK
              dangerousLINK={TERMS_AND_CONDITIONS_ROUTE}
              className='terms-and-conditions-text'
              text={t('GENERAL_TEXT.terms-and-conditions')}
              // style={path === routes.FAQ_ROUTE ? { display: 'none' } : undefined}
            />
            <SafeLINK
              dangerousLINK={NOTICE_OF_PRIVACY_ROUTE}
              className='terms-and-conditions-text'
              text={t('GENERAL_TEXT.privacy-policy')}
              // style={path === routes.FAQ_ROUTE ? { display: 'none' } : undefined}
            />
          </div>
          <div
            style={{
              marginRight: matches ? 0 : 10,
              cursor: 'pointer',
              boxSizing: 'border-box',
              width: matches ? '100%' : 'unset',
              display: matches ? 'flex' : 'block',
              justifyContent: matches ? 'flex-end' : 'unset',
              paddingRight: matches ? 10 : 0,
            }}
          >
            <ReactFlagsSelect
              className='Footer__Flag'
              // ref={this.userFlag}
              defaultCountry={String(localStorage.getItem('language') || 'US').toUpperCase()}
              countries={['US', 'ES']}
              showSelectedLabel={false}
              showOptionLabel={false}
              onSelect={(selected) => {
                localStorage.setItem('language', String(selected).toLowerCase());
                window.location.reload(false);
              }}
            />
          </div>
        </div>
      )}
    </Translation>
  );
};

export default MiniFooter;
