import { getData, postData } from '../../fetch';

export const getUserNotifications = (userId, skipItems, limitItems, search) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/user/get-user-notifications-web/?userId=${userId}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}`
      );
      // console.log("getUserNotifications / response: ", response)
      const user = response.body;
      return resolve(user);
    } catch (e) {
      return reject(e);
      // console.log("getUserNotifications / reject: ", e)
    }
  });
};

export const removeNotification = (notificationId) =>
  new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem('userId');
      const data = {
        notificationId,
        userId,
      };
      const response = await postData('/user/remove-notification-web', data);
      resolve(response);
    } catch (e) {
      console.log('removeNotification / e:', e);
      reject(e);
    }
  });

export const removeAllNotifications = () =>
  new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await postData('/user/remove-all-notification-web', { userId });
      resolve(response);
    } catch (e) {
      console.log('removeAllNotification / e:', e);
      reject(e);
    }
  });
