import React, { useState } from 'react';
import { FEDERAL_BLUE, FEDERAL_BLUE_SOFT, WHITE_MAIN } from '../color-palatte';
import SafeLINK from '../safe-link';
import { AppLoader } from '../animations/animations';
import TranslateBlueStrongIcon from '../../assets/post/translate-blue-strong.svg';
import TranslateDarkModeIcon from '../../assets/post/translate-darkmode.svg';
import translateText from '../translateText';
import InfoModal from '../../components/info-modals/info-modals';

export const BSpan = ({ children, onClick, style }) => (
  <span style={Object.assign({ color: FEDERAL_BLUE_SOFT, cursor: 'pointer' }, style)} onClick={onClick}>
    {children}
  </span>
);

export const BLink = ({ children, route, style, hoverable }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <SafeLINK
      style={Object.assign(
        {
          color: FEDERAL_BLUE_SOFT,
          cursor: 'pointer',
          textDecoration: hoverable && isHover ? 'underline' : undefined,
        },
        style
      )}
      dangerousLINK={route}
      text={children}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  );
};

export const TranslatableText = ({ className, style, text, darkMode, noTraslate, traslateButtonColor }) => {
  const [contentTranslated, setContentTranslated] = useState('');
  const [translatorLoading, setTranslatorLoading] = useState(false);
  const translateTx = async (text) => {
    try {
      setTranslatorLoading(true);
      const lang = localStorage.getItem('language') || 'en';
      // console.log("lang: ", lang)

      const result = await translateText(text, lang);
      // console.log("result: ", result)
      setContentTranslated(result[0]);
      setTranslatorLoading(false);
      // console.log("translateText / result: ", result);
    } catch (e) {
      // console.log("translateText / error: ", e);
      setTranslatorLoading(false);
    }
  };

  return (
    <>
      {!contentTranslated && !noTraslate && (
        <span
          style={{
            width: 30,
            height: 30,
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '90%',
            // alignSelf: 'flex-end',
            // position: 'absolute',
            // marginLeft: 5,
            // borderColor: 'red',
            // borderWidth: 1
          }}
          onClick={() => translateTx(text)}
        >
          {translatorLoading ? (
            <AppLoader style={{ marginLeft: '90%' }} size='small' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
          ) : traslateButtonColor ? (
            <img src={TranslateBlueStrongIcon} style={{ width: 12, height: 12, cursor: 'pointer' }} alt='' />
          ) : (
            <img src={TranslateDarkModeIcon} style={{ width: 12, height: 12, cursor: 'pointer' }} alt='' />
          )}
        </span>
      )}
      {contentTranslated && (
        <p
          className={className}
          style={Object.assign(
            { whiteSpace: 'pre-wrap' },
            {
              border: `solid 0.5px ${WHITE_MAIN}`,
              borderRadius: 10,
              padding: 10,
              margin: 10,
            },
            style
          )}
        >
          {contentTranslated ? contentTranslated : null}
        </p>
      )}
    </>
  );
};

export const ReadMoreTxt = ({
  className,
  maxLength,
  style,
  text,
  darkMode,
  readMoreColor,
  showLessColor,
  isHeading,
  readMoreStyle,
  showLessStyle,
}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (isHeading) {
    return (
      <>
        <h1 className={className} style={Object.assign({ whiteSpace: 'pre-wrap' }, style)}>
          {isReadMore ? `${text.slice(0, maxLength || 250)}...\n` : text}
          {text.length > (maxLength || 250) && isReadMore ? (
            <span
              onClick={toggleReadMore}
              style={Object.assign(
                {
                  fontWeight: 'bold',
                  cursor: 'pointer',
                },
                readMoreStyle
              )}
            >
              read more
            </span>
          ) : (
            <div
              onClick={toggleReadMore}
              style={Object.assign(
                {
                  fontWeight: 'bold',
                  cursor: 'pointer',
                },
                showLessStyle
              )}
            >
              show less...
            </div>
          )}
        </h1>
        <TranslatableText text={text} style={{ color: darkMode ? WHITE_MAIN : '#000' }} />
      </>
    );
  }
  return (
    <>
      <p className={className} style={Object.assign({ whiteSpace: 'pre-wrap' }, style)}>
        {isReadMore ? `${text.slice(0, maxLength || 250)}...\n` : text}
        {text.length > (maxLength || 250) && isReadMore ? (
          <span
            onClick={toggleReadMore}
            style={Object.assign({
              fontWeight: 'bold',
              color: readMoreColor ?? FEDERAL_BLUE,
              cursor: 'pointer',
            })}
          >
            read more
          </span>
        ) : (
          <div
            onClick={toggleReadMore}
            style={{
              fontWeight: 'bold',
              color: showLessColor ? showLessColor : darkMode ? '#AAA' : '#555',
              cursor: 'pointer',
            }}
          >
            show less...
          </div>
        )}
      </p>
      <TranslatableText text={text} style={{ color: darkMode ? WHITE_MAIN : '#000' }} />
    </>
  );
};

export const ReadMoreLinesTxt = ({
  className,
  maxLines,
  style,
  text,
  darkMode,
  readMoreColor,
  showLessColor,
  isHeading,
  readMoreStyle,
  showLessStyle,
}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (isHeading) {
    return (
      <>
        <h1 className={className} style={Object.assign({ whiteSpace: 'pre-wrap' }, style)}>
          {isReadMore
            ? `${text
                .split(/\r\n|\r|\n/)
                .slice(0, maxLines || 2)
                .join('\n')}...\n`
            : text}
          {text.split(/\r\n|\r|\n/).length > (maxLines || 2) && isReadMore ? (
            <span
              onClick={toggleReadMore}
              style={Object.assign(
                {
                  fontWeight: 'bold',
                  cursor: 'pointer',
                },
                readMoreStyle
              )}
            >
              read more
            </span>
          ) : (
            <div
              onClick={toggleReadMore}
              style={Object.assign(
                {
                  fontWeight: 'bold',
                  cursor: 'pointer',
                },
                showLessStyle
              )}
            >
              show less...
            </div>
          )}
        </h1>
        <TranslatableText text={text} style={{ color: darkMode ? WHITE_MAIN : '#000' }} />
      </>
    );
  }
  return (
    <>
      <p className={className} style={Object.assign({ whiteSpace: 'pre-wrap' }, style)}>
        {isReadMore
          ? `${text
              .split(/\r\n|\r|\n/)
              .slice(0, maxLines || 2)
              .join('\n')}...\n`
          : text}
        {text.split(/\r\n|\r|\n/).length > (maxLines || 2) && isReadMore ? (
          <span
            onClick={toggleReadMore}
            style={{
              fontWeight: 'bold',
              color: readMoreColor ?? FEDERAL_BLUE,
              cursor: 'pointer',
            }}
          >
            read more
          </span>
        ) : (
          <div
            onClick={toggleReadMore}
            style={{
              fontWeight: 'bold',
              color: showLessColor ? showLessColor : darkMode ? '#AAA' : '#555',
              cursor: 'pointer',
            }}
          >
            show less...
          </div>
        )}
      </p>
      <TranslatableText text={text} style={{ color: darkMode ? WHITE_MAIN : '#000' }} />
    </>
  );
};

export const BigText = ({ style, className, text, darkMode }) => {
  return (
    <>
      <p style={Object.assign({ fontSize: '1.6em' }, style)} className={className}>
        {text}
      </p>
      <TranslatableText text={text} style={{ color: darkMode ? WHITE_MAIN : '#000' }} />
    </>
  );
};

/**
 * @param {String} string     string to transform
 * @param {{keyword: String, onCkick: Function, style: Object}[]} values
 * @param {Object} style     styles to general string
 * @param {Object} markStyle     styles to highlight text
 * @param {Boolean} hasWiki     show wiki
 * @returns {JSXElement}
 */
 export const ParsedText = (props) => {
  const { string, values, style, className, markStyle, hasWiki } = props;

  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
  });

  const showModal = (isOpen, type) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
    });
  };

  const nestedValues = [
    { keyword: 'voting', onClick: () => showModal(true, 'voting') },
    { keyword: 'votings', onClick: () => showModal(true, 'voting') },
    { keyword: 'vote', onClick: () => showModal(true, 'voting') },
    { keyword: 'votes', onClick: () => showModal(true, 'voting') },
    { keyword: 'votaciones', onClick: () => showModal(true, 'voting') },
    { keyword: 'voto', onClick: () => showModal(true, 'voting') },
    { keyword: 'votos', onClick: () => showModal(true, 'voting') },
    { keyword: 'votacion', onClick: () => showModal(true, 'voting') },
    { keyword: 'Voting', onClick: () => showModal(true, 'voting') },
    { keyword: 'Votings', onClick: () => showModal(true, 'voting') },
    { keyword: 'Vote', onClick: () => showModal(true, 'voting') },
    { keyword: 'Votes', onClick: () => showModal(true, 'voting') },
    { keyword: 'Votaciones', onClick: () => showModal(true, 'voting') },
    { keyword: 'Voto', onClick: () => showModal(true, 'voting') },
    { keyword: 'Votos', onClick: () => showModal(true, 'voting') },
    { keyword: 'Votacion', onClick: () => showModal(true, 'voting') },
    { keyword: 'law', onClick: () => showModal(true, 'law') },
    { keyword: 'Law', onClick: () => showModal(true, 'law') },
    { keyword: 'laws', onClick: () => showModal(true, 'law') },
    { keyword: 'Laws', onClick: () => showModal(true, 'law') },
    { keyword: 'leyes', onClick: () => showModal(true, 'law') },
    { keyword: 'Leyes', onClick: () => showModal(true, 'law') },
    { keyword: 'ley', onClick: () => showModal(true, 'law') },
    { keyword: 'Ley', onClick: () => showModal(true, 'law') },
    { keyword: 'deal', onClick: () => showModal(true, 'deal') },
    { keyword: 'deals', onClick: () => showModal(true, 'deal') },
    { keyword: 'Deal', onClick: () => showModal(true, 'deal') },
    { keyword: 'Deals', onClick: () => showModal(true, 'deal') },
    { keyword: 'trato', onClick: () => showModal(true, 'deal') },
    { keyword: 'tratos', onClick: () => showModal(true, 'deal') },
    // { keyword: 'acuerdo', onClick: () => showModal(true, 'deal') },
    // { keyword: 'acuerdos', onClick: () => showModal(true, 'deal') },
    { keyword: 'Trato', onClick: () => showModal(true, 'deal') },
    { keyword: 'Tratos', onClick: () => showModal(true, 'deal') },
    // { keyword: 'Acuerdo', onClick: () => showModal(true, 'deal') },
    // { keyword: 'Acuerdos', onClick: () => showModal(true, 'deal') },
    { keyword: 'service', onClick: () => showModal(true, 'service') },
    { keyword: 'services', onClick: () => showModal(true, 'service') },
    { keyword: 'servicio', onClick: () => showModal(true, 'service') },
    { keyword: 'servicios', onClick: () => showModal(true, 'service') },
    { keyword: 'Service', onClick: () => showModal(true, 'service') },
    { keyword: 'Services', onClick: () => showModal(true, 'service') },
    { keyword: 'Servicio', onClick: () => showModal(true, 'service') },
    { keyword: 'Servicios', onClick: () => showModal(true, 'service') },
    { keyword: 'finances', onClick: () => showModal(true, 'finances') },
    { keyword: 'finance', onClick: () => showModal(true, 'finances') },
    { keyword: 'finanza', onClick: () => showModal(true, 'finances') },
    { keyword: 'finanzas', onClick: () => showModal(true, 'finances') },
    { keyword: 'Finances', onClick: () => showModal(true, 'finances') },
    { keyword: 'Finance', onClick: () => showModal(true, 'finances') },
    { keyword: 'Finanza', onClick: () => showModal(true, 'finances') },
    { keyword: 'Finanzas', onClick: () => showModal(true, 'finances') },
    // { keyword: 'income', onClick: () => showModal(true, 'income') },
    // { keyword: 'incomes', onClick: () => showModal(true, 'income') },
    // { keyword: 'ingreso', onClick: () => showModal(true, 'income') },
    // { keyword: 'ingresos', onClick: () => showModal(true, 'income') },
    // { keyword: 'ganancia', onClick: () => showModal(true, 'income') },
    // { keyword: 'ganancias', onClick: () => showModal(true, 'income') },
    // { keyword: 'Income', onClick: () => showModal(true, 'income') },
    // { keyword: 'Incomes', onClick: () => showModal(true, 'income') },
    // { keyword: 'Ingreso', onClick: () => showModal(true, 'income') },
    // { keyword: 'Ingresos', onClick: () => showModal(true, 'income') },
    // { keyword: 'Ganancia', onClick: () => showModal(true, 'income') },
    // { keyword: 'Ganancias', onClick: () => showModal(true, 'income') },
    // { keyword: 'expense', onClick: () => showModal(true, 'expense') },
    // { keyword: 'expenses', onClick: () => showModal(true, 'expense') },
    // { keyword: 'gastos', onClick: () => showModal(true, 'expense') },
    // { keyword: 'gasto', onClick: () => showModal(true, 'expense') },
    // { keyword: 'pasive', onClick: () => showModal(true, 'expense') },
    // { keyword: 'pasives', onClick: () => showModal(true, 'expense') },
    // { keyword: 'pasivo', onClick: () => showModal(true, 'expense') },
    // { keyword: 'pasivos', onClick: () => showModal(true, 'expense') },
    // { keyword: 'Expense', onClick: () => showModal(true, 'expense') },
    // { keyword: 'Expenses', onClick: () => showModal(true, 'expense') },
    // { keyword: 'Gastos', onClick: () => showModal(true, 'expense') },
    // { keyword: 'Gasto', onClick: () => showModal(true, 'expense') },
    // { keyword: 'Pasive', onClick: () => showModal(true, 'expense') },
    // { keyword: 'Pasives', onClick: () => showModal(true, 'expense') },
    // { keyword: 'Pasivo', onClick: () => showModal(true, 'expense') },
    // { keyword: 'Pasivos', onClick: () => showModal(true, 'expense') },
    { keyword: 'community', onClick: () => showModal(true, 'community') },
    { keyword: 'communities', onClick: () => showModal(true, 'community') },
    { keyword: 'comunidad', onClick: () => showModal(true, 'community') },
    { keyword: 'comunidades', onClick: () => showModal(true, 'community') },
    { keyword: 'Community', onClick: () => showModal(true, 'community') },
    { keyword: 'Communities', onClick: () => showModal(true, 'community') },
    { keyword: 'Comunidad', onClick: () => showModal(true, 'community') },
    { keyword: 'Comunidades', onClick: () => showModal(true, 'community') },
    { keyword: 'citizens', onClick: () => showModal(true, 'citizen') },
    { keyword: 'citizen', onClick: () => showModal(true, 'citizen') },
    { keyword: 'ciudadano', onClick: () => showModal(true, 'citizen') },
    { keyword: 'ciudadanos', onClick: () => showModal(true, 'citizen') },
    { keyword: 'Citizens', onClick: () => showModal(true, 'citizen') },
    { keyword: 'Citizen', onClick: () => showModal(true, 'citizen') },
    { keyword: 'Ciudadano', onClick: () => showModal(true, 'citizen') },
    { keyword: 'Ciudadanos', onClick: () => showModal(true, 'citizen') },
    { keyword: 'vicepresident', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'vicepresidente', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'vice-presidente', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'vice-president', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'Vicepresident', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'Vicepresidente', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'Vice-presidente', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'Vice-president', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'Vice-President', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'Vice-Presidente', onClick: () => showModal(true, 'vicepresident') },
    { keyword: 'presidente', onClick: () => showModal(true, 'president') },
    { keyword: 'president', onClick: () => showModal(true, 'president') },
    { keyword: 'Presidente', onClick: () => showModal(true, 'president') },
    { keyword: 'President', onClick: () => showModal(true, 'president') },
    { keyword: 'capital', onClick: () => showModal(true, 'capital') },
    { keyword: 'Capital', onClick: () => showModal(true, 'capital') },
  ];

  let val = hasWiki ? nestedValues : []

  const vals = () => {
    if (values) {
      return val.push(...values)
    }
  }
  vals()
  // console.log(val)

  const mutatedText = string
    .split(' ')
    .map((word) =>
      word.includes(',')
        ? val.filter((value) => value.keyword === word.replace(',', '')).length > 0
          ? `[[${word.replace(',', '')}]]${word.includes(',') ? ',' : ''}`
          : word
        : word.includes(';')
        ? val.filter((value) => value.keyword === word.replace(';', '')).length > 0
          ? `[[${word.replace(';', '')}]]${word.includes(';') ? ';' : ''}`
          : word
        : word.slice(-1).includes('.')
        ? val.filter((value) => value.keyword === word.replace('.', '')).length > 0
          ? `[[${word.replace('.', '')}]]${word.includes('.') ? '.' : ''}`
          : word
        : word.includes(':')
        ? val.filter((value) => value.keyword === word.replace(':', '')).length > 0
          ? `[[${word.replace(':', '')}]]${word.includes(':') ? ':' : ''}`
          : word
        : word.includes('!')
        ? val.filter((value) => value.keyword === word.replace('!', '')).length > 0
          ? `[[${word.replace('!', '')}]]${word.includes('!') ? '!' : ''}`
          : word
        : word.includes('¡')
        ? val.filter((value) => value.keyword === word.replace('¡', '')).length > 0
          ? `[[${word.replace('¡', '')}]]${word.includes('¡') ? '¡' : ''}`
          : word
        : word.includes('?')
        ? val.filter((value) => value.keyword === word.replace('?', '')).length > 0
          ? `[[${word.replace('?', '')}]]${word.includes('?') ? '?' : ''}`
          : word
        : word.includes('¿')
        ? val.filter((value) => value.keyword === word.replace('¿', '')).length > 0
          ? `[[${word.replace('¿', '')}]]${word.includes('¿') ? '¿' : ''}`
          : word
        : word.includes("'")
        ? val.filter((value) => value.keyword === word.replace("'", '')).length > 0
          ? `[[${word.replace("'", '')}]]${word.includes("'") ? "'" : ''}`
          : word
        : word.includes('"')
        ? val.filter((value) => value.keyword === word.replace('"', '')).length > 0
          ? `[[${word.replace('"', '')}]]${word.includes('"') ? '"' : ''}`
          : word
        : word.includes('|')
        ? val.filter((value) => value.keyword === word.replace('|', '')).length > 0
          ? `[[${word.replace('|', '')}]]${word.includes('|') ? '|' : ''}`
          : word
        : word.includes('[')
        ? val.filter((value) => value.keyword === word.replace('[', '')).length > 0
          ? `[[${word.replace('[', '')}]]${word.includes('[') ? '[' : ''}`
          : word
        : word.includes(']')
        ? val.filter((value) => value.keyword === word.replace(']', '')).length > 0
          ? `[[${word.replace(']', '')}]]${word.includes(']') ? ']' : ''}`
          : word
        : word.includes('{')
        ? val.filter((value) => value.keyword === word.replace('{', '')).length > 0
          ? `[[${word.replace('{', '')}]]${word.includes('{') ? '{' : ''}`
          : word
        : word.slice(-1).includes('(')
        ? val.filter((value) => value.keyword === word.replace('(', '')).length > 0
          ? `[[${word.replace('(', '')}]]${word.includes('(') ? '(' : ''}`
          : word
        : word.slice(-1).includes(')')
        ? val.filter((value) => value.keyword === word.replace(')', '')).length > 0
          ? `[[${word.replace(')', '')}]]${word.includes(')') ? ')' : ''}`
          : word
        : word.includes('<')
        ? val.filter((value) => value.keyword === word.replace('<', '')).length > 0
          ? `[[${word.replace('<', '')}]]${word.includes('<') ? '<' : ''}`
          : word
        : word.includes('>')
        ? val.filter((value) => value.keyword === word.replace('>', '')).length > 0
          ? `[[${word.replace('>', '')}]]${word.includes('>') ? '>' : ''}`
          : word
        : word.includes('/')
        ? val.filter((value) => value.keyword === word.replace('/', '')).length > 0
          ? `[[${word.replace('/', '')}]]${word.includes('/') ? '/' : ''}`
          : word
        : val.filter((value) => value.keyword === word).length > 0
        ? `[[${word}]]`
        : word
    )
    .join(' ');
  // console.log('mutatedText', mutatedText);

  if (!val.length) return string;

  const regex = new RegExp(/\[\[(.*?)\]\]/);

  return (
    <p style={style} className={className}>
      {mutatedText.split(regex).reduce((prev, current, i) => {
        if (!i) return [current];
        // console.log('prev', prev, 'current', current);

        return prev.concat(
          val.filter((value) => value.keyword?.includes(current)).length > 0 ? (
            <BSpan
              key={i + current}
              style={val.filter(
                (value) => value.keyword === current)[0]?.style || markStyle}
              onClick={() => {
                const onClick = val.filter((value) => value.keyword === current)[0].onClick;
                if (onClick) onClick();
              }}
            >
              {current}
            </BSpan>
          ) : (
            current
          )
        );
      }, [])}
      <InfoModal isOpen={modal.isOpen} type={modal.type} onRequestClose={() => setModal({ isOpen: false, type: null })} />
    </p>
  );
};