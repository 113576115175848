import React from 'react';
import { Translation } from 'react-i18next';
//import { MaterialIdCount } from 'three';
import BrokenMail from '../../assets/mail-error/IconEmailCanceled.svg';
import './mail-error.css';

class MailError extends React.Component {
  render() {
    return (
      <Translation>
        {t =>
          <div className="ErrorMailBox">
            <div className="ErrorContainer">
              <img src={BrokenMail} alt="BrokenMail" />
              <div className="ErrorText">
                <h1>{t("mail-error")}...</h1>
                <p>{t("mail-error-msg")}</p>
              </div>
            </div>
            <svg viewBox="0 0 100 100" preserveAspectRatio="none">
              <polygon className="diagonal1"
                points="0,40 100,0 100,100 0,100"
              />
            </svg>
          </div>
        }
      </Translation>
    );
  }
}

export default MailError;