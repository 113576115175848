import React from 'react';
import AlertModal from '../../../utils/modal/Modal';
import CloseBtnBlack from '../../../assets/payment-modals/close_btn_black.svg';
import { Translation } from 'react-i18next';
import './contribution-payment-method-modal.css';

const ContributionPaymentMethodModal = (props) => {
  const { isOpen, onRequestClose } = props;

  return (
    <Translation>
      {(t) => (
        <AlertModal
          isOpen={isOpen}
          modalClassName='PaymentMethod__Container'
          overlayClassName='PaymentMethod__Overlay'
          outsideClick={onRequestClose}
        >
          <div className='PaymentMethod__Header'>
            <img
              src={CloseBtnBlack}
              alt='close'
              className='PaymentMethod__Close-btn-black'
              onClick={onRequestClose}
            />
            <h1>{t('PAYMENTS_MODALS.PAYMENTS_METHOD.title')}</h1>
          </div>

          <div className='PaymentMethod__btns'>
            {/* <button className='PaymentMethod__btn1'>PayPal</button> */}
            <button onClick={() => props.onCreditCardClick()} className='PaymentMethod__btn2'>{t('PAYMENTS_MODALS.PAYMENTS_METHOD.btnCreditcard')}</button>
          </div>

          <div>
            <p className='PaymentMethod__Text'>
            {t('CONTRIBUTION.beforePayText')}
            </p>
          </div>

          {/* <div className='PaymentMethod__ImportantText'>
            <p>
            {t('PAYMENTS_MODALS.PAYMENTS_METHOD.importantText')}
            </p>
          </div> */}
        </AlertModal>
      )}
    </Translation>
  );
};

export default ContributionPaymentMethodModal;
