import React from 'react';
import { FEDERAL_BLUE, FEDERAL_GREEN, WHITE_MAIN } from '../../utils/color-palatte';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { thousandSeparator } from '../../utils/thousandSeparator';
import getStat from '../../utils/getStat';
import { getCommunity, joinCommunity, leaveCommunity } from './communityProfileActions';
import { updateLoggedUser } from '../../utils/updateLoggedUser';
import { isUserValid } from '../../utils/isUserValid';
import { reportCommunity } from '../communities/communitiesActions';
import * as routes from '../../routes/routes';
import Alert from '../../utils/alert/Alert';
import i18n from '../../i18n/i18n';
import CommunityBG from '../../assets/communities/community-bg.png';
import CommunityProfilePictureDark from '../../assets/communities/community_profile_dark.svg';
import CommunityProfilePicture from '../../assets/communities/community_profile_light.svg';
import { UserProfile } from '../../components/ornament-profile/profileUtils';
import { AppLoader } from '../../utils/animations/animations';
import MenuButton from '../../components/menu-button/MenuButton';
import OptionModal from '../../components/options-modal/optionsModal';
import './communityProfile.css';
import { Translation } from 'react-i18next';
import EditCommunity from '../../components/community-profile/edit-community/editCommunity';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';

class CommunityProfile extends React.Component {
  state = {
    loading: false,
    refresh: false,
    community: null,
    isSideMenuOpen: false,
    actionsLoader: false,
    positions: null,
    officialVotings: null,
    noOfficialVotings: null,
    isSecondVotingMenuOpen: false,
    reason: '',
    isSmall: false,
  };

  observer = null;
  rvlRef = React.createRef();
  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      this.observer = new ResizeObserver((entries) => {
        // console.log(entries)
        const boxElem = entries[0];
        if (boxElem.contentRect.width < 500) {
          this.setState({
            isSmall: true,
          });
        } else {
          this.setState({
            isSmall: false,
          });
        }
      });
      this.observer.observe(this.rvlRef.current);
    }
    const { saveUser } = this.props;
    isUserValid(false, this._isMounted);
    // await this.getStats();
    if (this._isMounted) await this.fetchCommunity();
    if (this._isMounted) updateLoggedUser(saveUser);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.observer.disconnect();
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { userId } = this.props.route.params;
        const positions = await getStat('positions');
        const officialVotings = await getStat('official-votings', true, userId);
        const noOfficialVotings = await getStat('no-official-votings', true, userId);
        this.setState({ positions, officialVotings, noOfficialVotings });
        resolve();
      } catch (e) {
        resolve();
        // console.log("getStats / error: ", e);
      }
    });
  };

  fetchCommunity = () => {
    return new Promise(async (resolve, reject) => {
      this.setState({ loading: true }, async () => {
        try {
          // const { user } = this.props;
          const { communityId } = this.props.match.params;
          const community = await getCommunity(communityId);
          // console.log("fetchCommunity / community: ", community);

          if (!community || community === undefined) {
            // this.props.navigation.navigate(COMMUNITIES)
            this.props.navigation.goBack();
          } else {
            this.setState({
              community: community,
              loading: false,
              refresh: false,
            });
            resolve();
          }
        } catch (e) {
          // console.log(e);
          this.setState({ loading: false });
          const error =
            e?.data?.error?.message?.length > 0
              ? e.data.error.message.toString()
              : e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject();
        }
      });
    });
  };

  joinCommunityFunc = () => {
    const { community } = this.state;
    const { user, saveUser } = this.props;
    this.setState({ joinLoading: true }, async () => {
      try {
        await joinCommunity(user._id, community._id);
        await this.fetchCommunity();
        await updateLoggedUser(saveUser);
        this.setState({ leaveLoading: false });
      } catch (e) {
        // console.log("joinCommunityFunc / error: ", e)
        this.setState({ joinLoading: false });
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          return;
        }

        const error =
          e?.data?.error?.message?.length > 0
            ? e.data.error.message.toString()
            : e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
      }
    });
  };

  leaveCommunityFunc = () => {
    const { community } = this.state;
    const { user, saveUser, saveCommunityPosts } = this.props;
    this.setState({ leaveLoading: true }, async () => {
      try {
        await leaveCommunity(user._id, community._id);
        await this.fetchCommunity();
        await updateLoggedUser(saveUser);
        saveCommunityPosts([]);
        this.setState({ joinLoading: false }, () => {
          this.props.history.push(routes.COMMUNITIES_ROUTE);
        });
      } catch (e) {
        // console.log("leaveCommunityFunc / error: ", e)
        const error =
          e?.data?.error?.message?.length > 0
            ? e.data.error.message.toString()
            : e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
        this.setState({ leaveLoading: false });
      }
    });
  };

  report = () => {
    const { user } = this.props;
    const { community } = this.state;
    this.setState({ actionsLoader: true }, async () => {
      // const { item } = this.props;
      try {
        const response = await reportCommunity(community._id, user._id);
        this.showAlert(true, 'success', response);
        this.setState({
          actionsLoader: false,
          isSideMenuOpen: false,
          isSecondVotingMenuOpen: false,
        });
      } catch (e) {
        // console.log("report / error: ", e);
        this.setState({ actionsLoader: false });
        const error =
          e?.data?.error?.message?.length > 0
            ? e.data.error.message[0]
            : e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
      }
    });
  };

  goUserProfile = async (userId) => {
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId === userId) {
      this.props.history.push(routes.PROFILE_ROUTE);
    } else {
      this.props.history.push(`${routes.PROFILE_ROUTE}/${userId}`);
    }
  };

  shareUrl = (id) => {
    this.setState({ isOptionsOpen: false }, () => {
      navigator.clipboard.writeText(`${window.location.origin}${routes.COMMUNITY_PROFILE_ROUTE}/${id}`);
      this.showAlert(true, 'success', i18n.t('DASHBOARD.shareUrl'));
    });
  };

  shareAsMsg = (id) => {
    this.setState({ isOptionsOpen: false }, () => {
      this.props.history.push(`${routes.MESSAGES_ROUTE}/?community=${id}`);
    });
  };

  onShare = (id) => {
    this.setState({ isOptionsOpen: false }, () => {
      this.props.history.push(`${routes.CREATE_POST_ROUTE}/?community=${id}`);
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const {
      loading,
      community,
      // isSideMenuOpen,
      // actionsLoader,
      joinLoading,
      // positions,
      // refresh,
      // isSecondVotingMenuOpen,
      // reason,
      leaveLoading,
      // officialVotings,
      // noOfficialVotings
    } = this.state;
    const { user, darkMode } = this.props;
    // let currentPosition = null
    // if (positions && positions.length > 0 && publicUser) {
    //   const havePosition = positions.filter(item => item.user._id === publicUser._id)
    //   if (havePosition.length > 0) {
    //     currentPosition = havePosition[0].position
    //   }
    // }
    // console.log("community: ", community);
    // console.log("user: ", user);

    const CommunitiImg = (props) => {
      const { uri, darkMode, className, name, description, nameStyles, descriptionStyles, ...otherProps } = props;
      const [src, setSrc] = React.useState(uri);
      const [errored, setErrored] = React.useState(false);

      const fallBackSrc = darkMode ? CommunityProfilePictureDark : CommunityProfilePicture;

      React.useEffect(() => {
        setSrc(uri);
      }, [uri]);

      const onError = React.useCallback(() => {
        if (!errored) {
          setSrc(fallBackSrc);
          setErrored(true);
        }
      }, [fallBackSrc, errored]);
      return (
        <div className={className}>
          <img src={src || fallBackSrc} onError={src && onError} alt='' {...otherProps} />
          {name && (
            <h1
              style={Object.assign(
                {
                  color: darkMode ? '#FFF' : FEDERAL_BLUE,
                  margin: 0,
                  marginTop: 5,
                  marginBottom: 5,
                  textAlign: 'center',
                  fontSize: `${name?.length >= 12 ? 1.4 : 1.8}rem`,
                },
                nameStyles
              )}
            >
              {name}
            </h1>
          )}
          {description && (
            <p style={Object.assign({ color: darkMode ? '#FFF' : FEDERAL_BLUE, margin: '5px 0' }, descriptionStyles)}>
              {description}
            </p>
          )}
        </div>
      );
    };

    return (
      <Translation>
        {(t) => (
          <div ref={this.rvlRef} className='CommunityProfile__Container'>
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: 'calc(100vh - 100px)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AppLoader size='big' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
              </div>
            ) : community ? (
              <>
                <div
                  className='CommunityProfile__Banner'
                  style={{
                    backgroundImage: `url(${community?.bgPicture ? community.bgPicture : CommunityBG}), url(${CommunityBG})`,
                  }}
                >
                  <MenuButton
                    onClick={() => this.setState({ isOptionsOpen: true })}
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      marginTop: 10,
                      marginRight: 10,
                      boxShadow: '0px 0px 3px 1px #0004, inset 0px 0px 15px 3px #0004',
                    }}
                    withBorder
                  />
                  <CommunitiImg
                    uri={community?.picture ? community.picture : null}
                    darkMode={darkMode}
                    className='CommunityProfile__Picture'
                    style={{
                      border: `3px solid ${darkMode ? '#fff' : FEDERAL_BLUE}`,
                      width: 140,
                      height: 140,
                      borderRadius: 25,
                    }}
                    name={community.name ?? null}
                    description={community.description ?? null}
                  />
                </div>
                <div className='CommunityProfile__Content'>
                  <div className='CommunityProfile__LeaderPosts'>
                    <div
                      className='CommunityProfile__LP CommunityProfile__Leader'
                      onClick={() => this.goUserProfile(community.leader._id)}
                    >
                      <p
                        style={{
                          color: darkMode ? '#fff' : '#000',
                          fontSize: '1.3rem',
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        {t('COMMUNITY_PROFILE.leader')}
                      </p>
                      <UserProfile
                        containerStyle={{
                          width: 40,
                          height: 40,
                          pointerEvents: 'none',
                        }}
                        uri={community.leader.picture ?? null}
                      />
                    </div>
                    <div
                      className='CommunityProfile__LP CommunityProfile__Posts'
                      onClick={() =>
                        user?.community?._id === community._id
                          ? this.props.history.push(`${routes.COMMUNITY_POSTS_ROUTE}/${community._id}`)
                          : void 0
                      }
                    >
                      <p
                        style={{
                          whiteSpace: 'pre-line',
                          textAlign: 'right',
                          color: darkMode ? '#fff' : '#000',
                          fontSize: '1.3rem',
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        {t('COMMUNITY_PROFILE.posts')}
                        {'\n'}
                        <span
                          style={{
                            color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE,
                            fontWeight: '400',
                            fontSize: '1.6rem',
                          }}
                        >
                          {community?.postsCount || 0}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='CommunityProfile__Members'>
                    <p
                      style={{
                        color: FEDERAL_BLUE,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        this.props.history.push(
                          `${routes.COMMUNITY_MEMBERS_ROUTE}/${community._id}/?communityLeader=${community.leader._id}`
                        )
                      }
                    >
                      {thousandSeparator(community.membersCount)} {t('COMMUNITY_PROFILE.members')}
                    </p>
                  </div>
                  {/* <div className="CommunityProfile__Text">
                <p>
                  Ecological group destined to eco-development programs and the
                  collective conscience on the protection of the environment.
                </p>
              </div> */}
                  {community &&
                  !community.block &&
                  String(user._id) !== String(community.leader._id) &&
                  String(user._id) !== String(community.leader._id) &&
                  !user.community ? (
                    <>
                      <button className='CommunityProfile__Btn' onClick={() => this.joinCommunityFunc()}>
                        {joinLoading || community === undefined ? (
                          <AppLoader
                            // style={{ marginTop: '50%', marginBottom: 20 }}
                            size='small'
                            color='#FFF'
                          />
                        ) : (
                          t('COMMUNITY_PROFILE.join')
                        )}
                      </button>
                      {community && user && String(user._id) === String(community.leader._id) && !community.block ? (
                        <div
                          style={{
                            width: '100%',
                            borderBottom: `2px solid ${darkMode ? '#fff' : FEDERAL_BLUE}`,
                          }}
                        />
                      ) : null}
                    </>
                  ) : null}
                  {community &&
                  user.community &&
                  user.community._id === community._id &&
                  (String(user._id) === String(community.leader._id) && !community.block ? false : true) ? (
                    <>
                      <button className='CommunityProfile__Btn' onClick={() => this.leaveCommunityFunc()}>
                        {leaveLoading || community === undefined ? (
                          <AppLoader
                            // style={{ marginTop: '50%', marginBottom: 20 }}
                            size='small'
                            color='#FFF'
                          />
                        ) : (
                          t('COMMUNITY_PROFILE.leave')
                        )}
                      </button>
                      {community && user && String(user._id) === String(community.leader._id) && !community.block ? (
                        <div
                          style={{
                            width: '100%',
                            borderBottom: `2px solid ${darkMode ? '#fff' : FEDERAL_BLUE}`,
                          }}
                        />
                      ) : null}
                    </>
                  ) : null}
                  {community && user && String(user._id) === String(community.leader._id) && !community.block ? (
                    <button className='CommunityProfile__Btn' onClick={() => this.setState({ isEditOpen: true })}>
                      {t('COMMUNITIES.edit')}
                    </button>
                  ) : null}
                </div>
              </>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: 'calc(100vh - 100px)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NotFoundItem text={t('COMMUNITIES.communityNotFound')} isBlue={darkMode} />
              </div>
            )}
            <Alert
              isOpen={this.state.isAlertOpen}
              type={this.state.alertType}
              content={this.state.alertContent}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
            />
            <OptionModal
              isOpen={this.state.isOptionsOpen}
              onRequestClose={() =>
                this.setState({
                  isOptionsOpen: false,
                })
              }
              show={{
                share: {
                  display: true,
                  onClick: () => this.onShare(community._id),
                },
                shareUrl: {
                  display: true,
                  onClick: () => this.shareUrl(community._id),
                },
                shareAsMsg: {
                  display: true,
                  onClick: () => this.shareAsMsg(community._id),
                },
              }}
            />
            <EditCommunity
              isOpen={this.state.isEditOpen}
              onRequestClose={() => this.setState({ isEditOpen: false }, async () => await this.fetchCommunity())}
            />
          </div>
        )}
      </Translation>
    );
  }
}

const CPWR = withRouter(CommunityProfile);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    saveCommunityPosts: bindActionCreators(actions.saveCommunityPosts, dispatch),
    openVerifyModal: bindActionCreators(actions.openVerifyModal, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CPWR);
