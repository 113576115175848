import React, { useState, useEffect } from 'react';
import { Switch, useRouteMatch, Route, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../components/whitepaper/layout/Layout';
import WhatsGG from '../../components/whitepaper/whats-gg/whatsGG';
import getStatPublic from '../../utils/getStatsPublic';
import Economy from '../../components/whitepaper/economy/economy';
import SocialExperiment from '../../components/whitepaper/social-experiment/socialExperiment';
import Deals from '../../components/whitepaper/deals/deals';
import Laws from '../../components/whitepaper/laws/laws';
import Positions from '../../components/whitepaper/positions/positions';
import RoadMap from '../../components/whitepaper/roadmap/roadmap';

const Whitepaper = (props) => {
  const { path } = useRouteMatch();
  const { darkMode, outer } = props;

  return (
    <Layout darkMode={darkMode} outer={outer}>
      <Switch>
        <Route exact path={`${path}/:topicId`} children={<Topic darkMode={darkMode} outer={outer} />} />
        <Route exact path={path} children={<Topic darkMode={darkMode} outer={outer} />} />
      </Switch>
    </Layout>
  );
};

const Topic = (props) => {
  const { darkMode, outer } = props;
  const { topicId } = useParams();

  // eslint-disable-next-line
  const [fetching, setFetching] = useState(false);

  const [maintenanceTaxes, setMaintenanceTaxes] = useState(null);
  const [citizenGainForDeal, setCitizenGainForDeal] = useState(null);
  const [presidentSalaryPorcentage, setPresidentSalaryPorcentage] = useState(null);
  const [vicepresidentSalaryPorcentage, setVicepresidentSalaryPorcentage] = useState(null);
  const [presidentSalary, setPresidentSalary] = useState("");
  const [vicepresidentSalary, setVicepresidentSalary] = useState("");
  // eslint-disable-next-line
  const [minVotingAssistance, setMinVotingAssistance] = useState(null);
  const [contributionEveryPaymentTime, setContributionEveryPaymentTime] = useState(null);
  const [timeToApproveDealDays, setTimeToApproveDealDays] = useState(null);
  const [contributionAmount, setContributionAmount] = useState(null);
  const [subscriptionBillingTaxes, setSubscriptionBillingTaxes] = useState(null);
  const [subscriptionBillingAmount, setSubscriptionBillingAmount] = useState(null);

  let _isMounted = false;

  useEffect(() => {
    // eslint-disable-next-line
    _isMounted = true;
    if (_isMounted) getStats();
    return () => {
      _isMounted = false;
    };
  }, []);

  const getStats = () =>
    new Promise(async (resolve, reject) => {
      setFetching(true);
      try {
        const maintenanceTaxes = await getStatPublic('maintenance-taxes');
        const citizenGainFromDeal = await getStatPublic('citizen-gain-from-deal');
        const presidentSalaryPercentage = await getStatPublic('president-salary-porcentage');
        const presidentSalary = await getStatPublic('president-salary');
        const vicepresidentSalary = await getStatPublic('vicepresident-salary');
        const vicepresidentSalaryPercentage = await getStatPublic('vicepresident-salary-porcentage');
        const minVotingAssistance = await getStatPublic('min-voting-assistance');
        const contributionEveryPaymentTime = await getStatPublic('contribution-every-payment-time');
        const timeToApproveDealDays = await getStatPublic('time-to-approve-deal-days');
        const contributionAmount = await getStatPublic('contribution-amount');
        const subscriptionBillingTaxes = await getStatPublic('subscription-billing-taxes');
        const subscriptionBillingAmount = await getStatPublic('subscription-billing-amount');
        setMaintenanceTaxes(maintenanceTaxes);
        setCitizenGainForDeal(citizenGainFromDeal);
        setPresidentSalaryPorcentage(presidentSalaryPercentage);
        setVicepresidentSalaryPorcentage(vicepresidentSalaryPercentage);
        setPresidentSalary(presidentSalary);
        setVicepresidentSalary(vicepresidentSalary);
        setMinVotingAssistance(minVotingAssistance);
        setContributionEveryPaymentTime(contributionEveryPaymentTime);
        setTimeToApproveDealDays(timeToApproveDealDays);
        setContributionAmount(contributionAmount);
        setSubscriptionBillingTaxes(subscriptionBillingTaxes);
        setSubscriptionBillingAmount(subscriptionBillingAmount);
        setFetching(false);
        resolve();
      } catch (e) {
        // console.log(e);
        reject(e);
        setFetching(false);
      }
    });

  switch (topicId) {
    case '':
      return <WhatsGG darkMode={darkMode} outer={outer} />;
    case 'whats-global-government':
      return <WhatsGG darkMode={darkMode} outer={outer} />;
    case 'economy':
      return (
        <Economy
          darkMode={darkMode}
          outer={outer}
          mainTaxes={maintenanceTaxes}
          presidentSalary={presidentSalary}
          vicepresidentSalary={vicepresidentSalary}
          presSalary={presidentSalaryPorcentage}
          vicePresSalary={vicepresidentSalaryPorcentage}
          contribAmount={contributionAmount}
          contribPayEveryTime={contributionEveryPaymentTime}
          subscriptionTaxes={subscriptionBillingTaxes}
          subscriptionAmount={subscriptionBillingAmount}
          citizenGainForDeal={citizenGainForDeal}
          presidentSalaryAmount={presidentSalary}
          vicepresidentSalaryAmount={vicepresidentSalary}
        />
      );
    case 'social-experiment':
      return <SocialExperiment darkMode={darkMode} outer={outer} />;
    case 'deals':
      return (
        <Deals
          darkMode={darkMode}
          outer={outer}
          mainTaxes={maintenanceTaxes}
          citizenGainDeal={citizenGainForDeal}
          presSalary={presidentSalaryPorcentage}
          vicePresSalary={vicepresidentSalaryPorcentage}
          timeToApprove={timeToApproveDealDays}
        />
      );
    case 'laws':
      return <Laws darkMode={darkMode} outer={outer} />;
    case 'positions':
      return (
        <Positions
          darkMode={darkMode}
          outer={outer}
          presSalary={presidentSalaryPorcentage}
          vicePresSalary={vicepresidentSalaryPorcentage}
          presidentSalary={presidentSalary}
          vicepresidentSalary={vicepresidentSalary}
        />
      );
    case 'roadmap':
      return <RoadMap darkMode={darkMode} outer={outer} />;
    default:
      return <WhatsGG darkMode={darkMode} outer={outer} />;
  }
};

const mapStateToProps = (state) => {
  const { darkMode } = state.user;
  return { darkMode };
};

export default connect(mapStateToProps, null)(Whitepaper);
