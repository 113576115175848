import { getData, postData } from "../../fetch";

export const getGlobalGovernmentData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/globalgovernment/get-data-web/`);
      return resolve(response.body);
    } catch (e) {
      // console.log("getVotingDetails / error: ", e);
      return reject(e);
    }
  })
};

export const validateContribution = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      await postData(`/payments/validate-contribution-web/`, { userId });
      return resolve();
    } catch (e) {
      // console.log("getVotingDetails / error: ", e);
      return reject(e);
    }
  })
};