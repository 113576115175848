import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './profile.css';
import profileBg from '../../assets/profile/profile-top-background.png';
// import ProfilePictureOrnament from '../../components/profile-picture-ornament/ProfilePictureOrnament';
// import { FEDERAL_BLUE } from '../../utils/color-palatte';
import MenuButton from '../../components/menu-button/MenuButton';
import followingButtonPicture from '../../assets/profile/following-button-picture.svg';
import followersButtonPicture from '../../assets/profile/followers-button-picture.svg';
import votingsButtonPicture from '../../assets/profile/votings-button-picture.svg';
import dealsButtonPicture from '../../assets/profile/deals-button-picture.svg';
import lawsButtonPicture from '../../assets/profile/laws-button-picture.svg';
import achievementsButtonPicture from '../../assets/profile/achievements-button-picture.svg';
import SafeLINK from '../../utils/safe-link';
import * as routes from '../../routes/routes';
import i18n from '../../i18n/i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { actionCreators as utilActions } from '../../redux/actions/utils/actions';
import { blockGeneralFunc, followUser, getUser, getUserTotalContribution, reportUser } from './profileActions';
import { isUserValid } from '../../utils/isUserValid';
import getStat from '../../utils/getStat';
import OrnamentProfile from '../../components/ornament-profile/ornament-profile';
import IdModal from '../../components/profile/id-modal/idModal';
import EditProfile from '../../components/profile/edit-profile/editProfile';
import ChangeEmailModal from '../../components/profile/edit-profile/changeEmailModal';
import ChangePasswordModal from '../../components/profile/edit-profile/changePasswordModal';
import { FEDERAL_BLUE, WHITE_MAIN, FEDERAL_GREEN, UNSATURATED_BLACK_BLUE } from '../../utils/color-palatte';
import { AppLoader } from '../../utils/animations/animations';
import { CURRENT_DOMAIN } from '../../config';
import OptionModal from '../../components/options-modal/optionsModal';
import Alert from '../../utils/alert/Alert';
import thousandSeparator from '../../utils/thousandSeparator';
import CapitalContributionIcon from '../../assets/contribution/capital-contribution-icon.svg';
import handlePositions from '../../utils/handlePositionName';
import BlockCitizenIcon from '../../assets/options-modal/icon_block_citizen.svg';
import UnblockCitizenIcon from '../../assets/options-modal/icon_unlock_citizen.svg';
import VerificationIcon from '../../assets/ornament-profile/verification_icon.svg';

export const ProfileButton = ({ className, number, titleStyle, title, picture, onClick, linked, navigation, darkMode }) => {
  const [hover, setHover] = useState(false);

  if (linked) {
    return (
      <SafeLINK
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        dangerousLINK={navigation}
        className={`Profile__Action__Button ${className}`}
        style={{ background: hover ? '#257eb44f' : undefined }}
        text={
          <>
            <img src={picture} className='Profile__Action__Button__Picture' alt={`Go to ${title} button`} />
            <h1 className='Profile__Action__Button__Number' style={{ color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE }}>
              {number}
            </h1>
            <p
              className='Profile__Action__Button__Name'
              style={Object.assign({ color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE }, titleStyle)}
            >
              {title}
            </p>
          </>
        }
      />
    );
  }
  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ background: hover ? '#257eb44f' : undefined }}
      className={`Profile__Action__Button ${className}`}
    >
      <img src={picture} className='Profile__Action__Button__Picture' alt={`Go to ${title} button`} />
      <h1 className='Profile__Action__Button__Number' style={{ color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE }}>
        {number}
      </h1>
      <p
        className='Profile__Action__Button__Name'
        style={Object.assign({ color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE }, titleStyle)}
      >
        {title}
      </p>
    </button>
  );
};

const CommunityImg = (props) => {
  const { uri } = props;
  const [src, setSrc] = useState(uri);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    setSrc(uri);
  }, [uri]);

  const onError = useCallback(() => {
    if (errored) {
      setSrc(profileBg);
      setErrored(true);
    }
  }, [errored]);

  return <img src={src || profileBg} onError={src && onError} className='Profile__Community__Picture' alt='User community' />;
};

class Profile extends React.Component {
  state = {
    loading: true,
    isSmall: false,
    publicUser: null,
  };

  rvlRef = React.createRef();
  observer = null;

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    const { user } = this.props;
    const { userId } = this.props.match.params;
    const routeUserId = user._id;
    if (routeUserId === userId) {
      this.props.history.replace(routes.PROFILE_ROUTE);
    }
    if (this._isMounted) {
      this.observer = new ResizeObserver((entries) => {
        const boxElem = entries[0];
        if (boxElem.contentRect.width <= 450) {
          this.setState({
            isSmall: true,
          });
        } else {
          this.setState({
            isSmall: false,
          });
        }
      });

      this.observer.observe(this.rvlRef.current);
    }

    isUserValid(false, this._isMounted);
    if (this._isMounted) await this.getStats();
    if (this._isMounted) this.fetchUser();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.observer.disconnect();
  };

  getStats = () => {
    return new Promise(async (resolve, _) => {
      try {
        const routeUserId = this.props.match?.params?.userId;
        const isPublicUser = routeUserId && routeUserId !== this.props.user._id;
        const userId = isPublicUser ? routeUserId : this.props.user._id;
        // const userId = localStorage.getItem("userId");
        const positions = await getStat('positions');
        const totalContribution = await getUserTotalContribution(userId);
        const officialVotings = await getStat('official-votings', true, userId);
        const noOfficialVotings = await getStat('no-official-votings', true, userId);
        this.setState({
          positions,
          officialVotings,
          noOfficialVotings,
          totalContribution: isNaN(totalContribution) ? null : Number(totalContribution),
        });
        resolve();
      } catch (e) {
        // console.log("getStats / error: ", e);
        resolve();
      }
    });
  };

  fetchUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const routeUserId = this.props.match?.params?.userId;
        const isPublicUser = routeUserId && routeUserId !== this.props.user._id;
        const userId = routeUserId || this.props.user._id;
        const user = await getUser(userId);
        if (routeUserId) {
          /**
           * We do not store the entire user object to avoid security leaks,
           * such as access to passwords or other personal data possibly visible
           * from the localStorage
           */
          this.props.saveForeignUser({
            username: user.username,
            name: user.name,
            picture: user.picture ?? null,
            _id: user._id,
          });
        }

        this.setState({ user: user, publicUser: isPublicUser ? user : null });
        if (!isPublicUser) {
          this.props.saveUser(user);
        }

        this.setState({ loading: false });
        resolve();
      } catch (e) {
        const error =
          e && e.data && e.data.error
            ? e.data.error.message
              ? e.data.error.message
              : e.data.error
            : i18n.t('ERRORS.serverError');
        this.setState({ loading: false });
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          reject();
        }
        this.showAlert(true, 'error', undefined, error, false, null, null, null, null, undefined, () =>
          this.setState({ isModalOpen: false })
        );
        reject();
      }
    });
  };

  showId = (isOpen, user) => {
    this.setState({
      isIdOpen: isOpen,
      idUser: user,
    });
  };

  editUser = (isOpen) => {
    this.setState({
      isEditUserModalOpen: isOpen,
    });
  };

  report = (userId) => {
    const { user } = this.props;
    this.setState({ fetching: true }, async () => {
      try {
        const response = await reportUser(userId, user._id);
        // console.log("report / response: ", response);
        this.showAlert(true, 'success', '', response);
        this.setState({ fetching: false, isPostMenuOpen: false });
      } catch (e) {
        // console.log("report / error: ", e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', undefined, error, false, null, null, null, null, undefined, () =>
          this.setState({ isModalOpen: false })
        );
        this.setState({
          fetching: false,
        });
      }
    });
  };

  blockGeneral = () => {
    const { user } = this.props;
    const publicUser = this.state.user;
    this.setState({ actionsLoader: true }, async () => {
      try {
        const response = await blockGeneralFunc(user._id, publicUser._id);
        const userUpdated = await getUser(user._id);
        this.props.saveUser(userUpdated);
        // this.setState({
        //   isMessageModalOpen: true,
        //   messageModalMessage: response,
        //   messageModalType: 'success',
        // });
        this.showAlert(true, 'success', undefined, response, false, null, null, null, null, undefined, () =>
          this.setState({ isModalOpen: false })
        );
        this.setState({ actionsLoader: false, isPostMenuOpen: false });
      } catch (e) {
        // console.log("blockGeneral / error: ", e);
        this.setState({ actionsLoader: false });
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
        this.showAlert(true, 'error', undefined, error, false, null, null, null, null, undefined, () =>
          this.setState({ isModalOpen: false })
        );
      }
    });
  };

  follow = (publicUser) => {
    const { user } = this.props;
    this.setState({ followLoading: true }, async () => {
      try {
        await followUser(user._id, publicUser._id);
        await this.fetchUser();
        this.setState({ followLoading: false });
      } catch (e) {
        // console.log("follow / error: ", e)
        const error = e?.data?.error?.message ?? e?.data?.error ?? i18n.t('GENERAL_ERRORS.serverError');
        this.setState({ followLoading: false });
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          return;
        }
        this.showAlert(true, 'error', '', error);
        this.showAlert(true, 'error', undefined, error, false, null, null, null, null, undefined, () =>
          this.setState({ isModalOpen: false })
        );
      }
    });
  };

  shareUrl = (id) => {
    this.showOptions(false);
    navigator.clipboard.writeText(`${CURRENT_DOMAIN}${routes.PROFILE_ROUTE}/${id}`);
    this.showAlert(
      true,
      'success',
      '',
      i18n.t('DASHBOARD.shareUrl'),
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      'Ok',
      () => this.setState({ isModalOpen: false }),
      false
    );
  };

  onBlockCall = () => {
    const { user } = this.props;
    // console.log('user', user)
    const { publicUser } = this.state;
    this.showOptions(false);
    this.showAlert(
      true,
      'warning',
      undefined,
      user.blockList && publicUser && user.blockList.filter((userId) => userId === publicUser._id).length > 0
        ? i18n.t('DASHBOARD.sureTounblockUsergeneral')
        : i18n.t('DASHBOARD.sureToblockUsergeneral'),
      true,
      () => this.setState({ isModalOpen: false }),
      i18n.t('GENERAL_TEXT.cancel'),
      () => this.blockGeneral(),
      user.blockList && publicUser && user.blockList.filter((userId) => userId === publicUser._id).length > 0
        ? i18n.t('DASHBOARD.unblockText')
        : i18n.t('DASHBOARD.blockText'),
      undefined,
      undefined,
      false
    );
  };

  showOptions = (isOpen) => {
    this.setState({
      isOptionsOpen: isOpen,
    });
  };

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  shareAsMsg = (id) => {
    this.props.history.push(`${routes.MESSAGES_ROUTE}/?userRef=${id}`);
  };

  render() {
    const { loading, followLoading, totalContribution, officialVotings, noOfficialVotings, positions, publicUser } = this.state;
    const { darkMode } = this.props;
    const routeUserId = this.props.match?.params?.userId;
    const isPublicUser = routeUserId && routeUserId !== this.props.user._id;
    const user = isPublicUser ? this.state.user : this.props.user;
    // console.log("routeUserId: ", routeUserId)
    // console.log("isPublicUser: ", isPublicUser)
    // console.log("render / user: ", user)

    let currentPosition = null;
    if (positions && positions.length > 0 && user) {
      const havePosition = positions.filter((item) => item.user._id === user._id);
      if (havePosition.length > 0) {
        currentPosition = havePosition[0].position;
      }
    }

    const votingsCount = thousandSeparator(thousandSeparator(Number(officialVotings) || 0 + Number(noOfficialVotings) || 0) || 0);

    const profileItems = [
      {
        onClick: () =>
          routeUserId
            ? this.props.history.push(`${routes.USER_FOLLOWING_ROUTE}/${user._id}`)
            : this.props.history.push(routes.USER_FOLLOWING_ROUTE),
        number: user?.following?.length || 0,
        title: i18n.t('PROFILE.following'),
        picture: followingButtonPicture,
      },
      {
        onClick: () =>
          routeUserId
            ? this.props.history.push(`${routes.USER_FOLLOWERS_ROUTE}/${user._id}`)
            : this.props.history.push(routes.USER_FOLLOWERS_ROUTE),
        number: user?.followers?.length || 0,
        title: i18n.t('PROFILE.followers'),
        picture: followersButtonPicture,
      },
      {
        onClick: () =>
          routeUserId
            ? this.props.history.push(`${routes.VOTING_HISTORY}/${user._id}`)
            : this.props.history.push({ pathname: routes.VOTING_HISTORY, state: { user } }),
        number: votingsCount,
        title: i18n.t('PROFILE.votings'),
        picture: votingsButtonPicture,
      },
      {
        onClick: () =>
          routeUserId
            ? this.props.history.push(`${routes.LAWS_LIST_ROUTE}/${user._id}`)
            : this.props.history.push({ pathname: routes.LAWS_LIST_ROUTE, state: { user } }),
        number: user?.lawsCount || 0,
        title: i18n.t('PROFILE.laws'),
        picture: lawsButtonPicture,
      },
      {
        onClick: () =>
          routeUserId
            ? this.props.history.push(`${routes.DEALS_LIST_ROUTE}/${user._id}`)
            : this.props.history.push({ pathname: routes.DEALS_LIST_ROUTE, state: { user } }),
        number: user?.dealsCount || 0,
        title: i18n.t('PROFILE.deals'),
        picture: dealsButtonPicture,
      },
      {
        onClick: () =>
          routeUserId
            ? this.props.history.push(`${routes.USER_ACHIEVEMENTS_ROUTE}/${user._id}`)
            : this.props.history.push(routes.USER_ACHIEVEMENTS_ROUTE),
        number:
          thousandSeparator(user?.achievements ? user.achievements.filter((achievement) => achievement.active).length : 0) || 0,
        title: i18n.t('PROFILE.achievements'),
        picture: achievementsButtonPicture,
      },
    ];

    return (
      <div ref={this.rvlRef} className='Profile__Container'>
        {loading && isPublicUser ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
            <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
          </div>
        ) : (
          <>
            <div className='Profile__Background__Conatiner'>
              {/* <img
            src={user.bgPicture || profileBg}
            className='Profile__Background__Image'
            alt='profile background'
          /> */}
              <div
                className='Profile__Background__Image'
                style={{ backgroundImage: `url(${user?.bgPicture || profileBg})` }}
              ></div>
              <OrnamentProfile
                userPicture={user?.picture}
                userRank={currentPosition}
                className='Profile__Background__Ornament'
                noText
                style={{
                  transformOrigin: 'bottom',
                  transform: `scale(${this.state.isSmall ? 0.6 : 1})`,
                  bottom: `-${this.state.isSmall ? 30 : 45}%`,
                }}
              />
              {!isPublicUser ? (
                <SafeLINK
                  // className='Profile__Route__Button'
                  dangerousLINK={routes.SETTINGS_ROUTE}
                  text={
                    <>
                      <MenuButton
                        onClick={() => null}
                        className='Profile__Menu__Button'
                        withBorder
                        style={{
                          boxShadow: '0px 0px 3px 1px #0004, inset 0px 0px 15px 3px #0004',
                        }}
                      />
                    </>
                  }
                  onClick={() => null}
                />
              ) : (
                <MenuButton
                  onClick={() => this.showOptions(true)}
                  className='Profile__Menu__Button'
                  withBorder
                  style={{
                    boxShadow: '0px 0px 3px 1px #0004, inset 0px 0px 15px 3px #0004',
                  }}
                />
              )}
            </div>
            <div className='Profile__Content__Container'>
              {user.name && user.showName ? (
                <h1 className='Profile__Content__User_Name' style={{ color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE }}>
                  {user.name}
                </h1>
              ) : null}
              <h3 className='Profile__Content__UserName' style={{ color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE }}>
                @{user.username}
                {user.isVerifiedUser ? (
                  <img
                    src={VerificationIcon}
                    alt=''
                    title={i18n.t('GENERAL_TEXT.verifiedUser')}
                    style={{ width: 25, marginLeft: 5 }}
                  />
                ) : null}
              </h3>
              <button
                className='Profile__Position__Button__Container'
                onClick={() => this.props.history.push(routes.GG_HIERARCHY_ROUTE)}
              >
                <h3
                  className='Profile__Position__Button__Title'
                  style={{ color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE }}
                >
                  {i18n.t('PROFILE.position')}
                </h3>
                <h2 className='Profile__Position__Button__Position' style={{ color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE }}>
                  {currentPosition ? handlePositions(currentPosition) : i18n.t('GENERAL_TEXT.citizen')}
                </h2>
              </button>
              <button
                onClick={() => this.props.history.push(`${routes.DASHBOAR_ROUTE}/?user=${user._id}`)}
                className='Profile__Activities__Button__Container'
              >
                <h3
                  className='Profile__Activities__Button__Title'
                  style={{ color: darkMode ? WHITE_MAIN : UNSATURATED_BLACK_BLUE }}
                >
                  {i18n.t('PROFILE.activities')}
                </h3>
                <h2
                  className='Profile__Activities__Button__Activities'
                  style={{ color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE }}
                >
                  {user.activities}
                </h2>
              </button>
              {user.resume && user.showResume ? (
                <div className='Profile__About__Container'>
                  <h3 className='Profile__About__Title' style={{ color: darkMode && WHITE_MAIN }}>
                    {i18n.t('PROFILE.about')}
                  </h3>
                  <p className='Profile__About__Content' style={{ color: darkMode && WHITE_MAIN }}>
                    {user.resume}
                  </p>
                </div>
              ) : null}
              {isPublicUser ? (
                followLoading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
                    <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
                  </div>
                ) : (
                  <button
                    className='Profile__Route__Button'
                    onClick={() => this.follow(user)}
                    disabled={loading}
                    style={{ marginBottom: 20 }}
                  >
                    {/* <img src={createPost} alt='' /> */}
                    <p>
                      {user.followers.filter((follower) => String(follower._id) === String(this.props.user._id)).length > 0
                        ? i18n.t('PROFILE.unFollow')
                        : i18n.t('PROFILE.follow')}
                    </p>
                  </button>
                )
              ) : null}
              {user.community && user.community !== undefined && user.community._id && (
                <div
                  className='Profile__Community__Container'
                  onClick={() => this.props.history.push(`${routes.COMMUNITIES_ROUTE}`)}
                >
                  <div className='Profile__Community__Header__Line__Container'>
                    <h4 className='Profile__Community__Header__Title'>{i18n.t('PROFILE.community')}</h4>
                    <div className='Profile__Community__Header__Line' />
                  </div>
                  <div className='Profile__Community__Content__Container'>
                    <div className='Profile__Community__Picture__Container'>
                      {/* <img
                        src={user.community?.picture ?? profileBg}
                        className='Profile__Community__Picture'
                        alt='User community'
                      /> */}
                      <CommunityImg src={user.community?.picture} />
                      <div className='Profile__Community__Name__Container'>
                        <h2 className='Profile__Community__Name'>{user.community.name}</h2>
                        <p className='Profile__Community__Rool'>{i18n.t('PROFILE.member')}</p>
                      </div>
                    </div>
                    <div>
                      <p className='Profile__Community__Members'>{`${user.community.membersCount} ${i18n.t(
                        'PROFILE.members'
                      )}`}</p>
                    </div>
                  </div>
                </div>
              )}
              {totalContribution && (
                <div className='Profile__Contribution__Container'>
                  <div className='Profile__Community__Header__Line__Container'>
                    <h5 className='Profile__Community__Header__Title'>{i18n.t('CONTRIBUTION.totalContribution')}</h5>
                    <div className='Profile__Contribution__Header__Line' />
                  </div>
                  <div className='Profile__Community__Content__Container'>
                    <div className='Profile__Community__Picture__Container'>
                      <img src={CapitalContributionIcon} className='Profile__Contribution__Picture' alt='' />
                      <div className='Profile__Community__Name__Container'>
                        <h2 className='Profile__Community__Name'>{thousandSeparator(totalContribution)}$</h2>
                        <p className='Profile__Community__Rool'>{i18n.t('CONTRIBUTION.capitalContribution')}</p>
                      </div>
                    </div>
                    {/* <div>
                        <p className="Profile__Community__Members">{`${user.community.membersCount
                          } ${i18n.t("PROFILE.members")}`}</p>
                      </div> */}
                  </div>
                </div>
              )}
              <div className='Profile__Buttons__Container'>
                {/* <ProfileButton
                  onClick={() => null}
                  number={user?.following?.length || 0}
                  title={i18n.t('PROFILE.following')}
                  picture={followingButtonPicture}
                />
                <ProfileButton
                  onClick={() => null}
                  number={user?.followers?.length || 0}
                  title={i18n.t('PROFILE.followers')}
                  picture={followersButtonPicture}
                  linked
                  navigation={routes.USER_FOLLOWERS_ROUTE}
                />
                <ProfileButton
                  onClick={() => null}
                  number={user.votingCount}
                  title={i18n.t('PROFILE.votings')}
                  picture={votingsButtonPicture}
                />
                <ProfileButton
                  onClick={() => null}
                  number={user.lawsCount}
                  title={i18n.t('PROFILE.laws')}
                  picture={lawsButtonPicture}
                />
                <ProfileButton
                  onClick={() => null}
                  number={user.dealsCount}
                  title={i18n.t('PROFILE.deals')}
                  picture={dealsButtonPicture}
                /> */}
                {profileItems.map((item, i) => (
                  <ProfileButton {...item} key={i} darkMode={darkMode} />
                ))}
              </div>
              {!isPublicUser && (
                <button className='Profile__Route__Button' onClick={() => this.editUser(true)}>
                  {/* <img src={createPost} alt='' /> */}
                  <p>{i18n.t('PROFILE.editProfile')}</p>
                </button>
              )}
              {!isPublicUser && (
                <button className='Profile__Route__Button__Yellow' onClick={() => this.showId(true, user)}>
                  <>
                    {/* <img src={createPost} alt='' /> */}
                    <p>{i18n.t('PROFILE.idCard')}</p>
                  </>
                </button>
              )}
            </div>
          </>
        )}
        <IdModal
          user={this.state.idUser}
          isOpen={this.state.isIdOpen}
          onRequestClose={() => this.setState({ isIdOpen: false })}
        />
        <EditProfile
          isOpen={this.state.isEditUserModalOpen}
          changeEmailClick={() =>
            this.setState({
              isEditUserModalOpen: false,
              changeEmailOpen: true,
            })
          }
          changePasswordClick={() =>
            this.setState({
              isEditUserModalOpen: false,
              changePassOpen: true,
            })
          }
          changePass
          onRequestClose={() =>
            this.setState({
              isEditUserModalOpen: false,
            })
          }
        />
        <ChangeEmailModal isOpen={this.state.changeEmailOpen} onRequestClose={() => this.setState({ changeEmailOpen: false })} />
        <ChangePasswordModal
          isOpen={this.state.changePassOpen}
          darkMode={darkMode}
          onRequestClose={() => this.setState({ changePassOpen: false })}
        />
        <OptionModal
          isOpen={this.state.isOptionsOpen}
          onRequestClose={() => this.setState({ isOptionsOpen: false })}
          show={{
            share: {
              display: true,
              onClick: () => this.props.history.push(`${routes.CREATE_POST_ROUTE}/?userRef=${user._id}`),
            },
            shareUrl: {
              display: true,
              onClick: () => this.shareUrl(user?._id),
            },
            shareAsMsg: {
              display: true,
              onClick: () => this.shareAsMsg(user?._id),
            },
            report: {
              display: user?._id === this.props.user._id ? false : true,
              onClick: () => this.report(user?._id),
            },
            block: {
              display: user?._id !== this.props.user._id ? true : false,
              title:
                this.props.user &&
                this.props.user.blockList &&
                publicUser &&
                this.props.user.blockList.filter((userId) => userId === publicUser._id).length > 0
                  ? i18n.t('DASHBOARD.unblockText')
                  : i18n.t('DASHBOARD.blockText'),
              onClick: () => this.onBlockCall(),
              image:
                this.props.user &&
                this.props.user.blockList &&
                publicUser &&
                this.props.user.blockList.filter((userId) => userId === publicUser._id).length > 0
                  ? UnblockCitizenIcon
                  : BlockCitizenIcon,
            },
          }}
        />
        <Alert
          isOpen={this.state.isModalOpen}
          type={this.state.modalType}
          title={this.state.modalTitle}
          content={this.state.modalContent}
          choose={this.state.modalChoose}
          NOClick={this.state.noAction}
          noButtonText={this.state.noText}
          YESClick={this.state.yesAction}
          yesButtonText={this.state.yesText}
          singleButtonText={this.state.btnTxt}
          onRequestClose={() => this.showAlert(false)}
          loading={this.state.actionsLoader}
        />
      </div>
    );
  }
}

const ProfileWithRouter = withRouter(Profile);

function mapDispatchToProps(dispatch) {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    openVerifyModal: bindActionCreators(actions.openVerifyModal, dispatch),
    saveForeignUser: bindActionCreators(utilActions.saveForeignUser, dispatch),
  };
}

function mapStateToProps(state) {
  const { user, darkMode } = state.user;
  const { foreignUser } = state.utils;
  return {
    user,
    darkMode,
    foreignUser,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWithRouter);
