import React from 'react';
import { withRouter } from 'react-router-dom';
import * as routes from '../../routes/routes';
import { UserProfile } from '../../components/ornament-profile/profileUtils';
import {
  FEDERAL_BLUE,
  FEDERAL_BLUE_SOFT,
  // UNSATURATED_BLACK_BLUE,
  WHITE_MAIN,
} from '../../utils/color-palatte';
import BG from '../../assets/communities/community-bg.png';
import i18n from '../../i18n/i18n';
import './communityItem.css';
import { Translation } from 'react-i18next';
import moment from 'moment';
import OptionModal from '../options-modal/optionsModal';
import MenuButton from '../menu-button/MenuButton';
import Alert from '../../utils/alert/Alert';

class CommunityItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      small: false,
      fetching: false,
      skipItems: 0,
      limitItems: 5,
      plusPostNumber: 5,
      keepIncreasingCounter: false,
      search: '',
      members: [],
    };
  }

  rlvRef = React.createRef();
  observer = null;

  componentDidMount = async () => {
    this.observer = new ResizeObserver((entries) => {
      // console.log(entries);
      const boxElem = entries[0];
      const isSmall = boxElem.contentRect.width < 600;
      if (isSmall) {
        this.setState({
          small: true,
        });
      } else {
        this.setState({
          small: false,
        });
      }
    });
    this.observer.observe(this.rlvRef.current);
  };

  componentWillUnmount = () => {
    this.observer.disconnect();
  };

  report = () => {
    this.setState({ actionsLoader: true }, async () => {
      const { item, report } = this.props;
      try {
        await report(item._id);
        this.setState({ actionsLoader: false, isVotingMenuOpen: false, isSecondVotingMenuOpen: false });
      } catch (e) {
        this.setState({ actionsLoader: false });
      }
    });
  };

  shareUrl = (id) => {
    this.setState({ isOptionsOpen: false });
    navigator.clipboard.writeText(`${window.location.origin}${routes.COMMUNITY_PROFILE_ROUTE}/${id}`);
    this.showAlert(
      true,
      'success',
      i18n.t('DASHBOARD.done'),
      i18n.t('DASHBOARD.shareUrl'),
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      () => this.setState({ isModalOpen: false }),
      false
    );
  };

  shareAsMsg = (id) => {
    this.setState({ isOptionsOpen: false });
    this.props.history.push(`${routes.MESSAGES_ROUTE}/?community=${id}`);
  };

  sharePost = (id) => {
    this.setState({ isOptionsOpen: false });
    this.props.history.push(`${routes.CREATE_POST_ROUTE}/?community=${id}`);
  };

  onReportCall = () => {
    this.setState({ isOptionsOpen: false });
    this.showAlert(
      true,
      'warning',
      i18n.t('DASHBOARD.MODAL.title'),
      i18n.t('COMMUNITIES.report.title'),
      true,
      () => this.setState({ isModalOpen: false }),
      i18n.t('COMMUNITIES.report.cancel'),
      () => this.report(),
      i18n.t('COMMUNITIES.report.report'),
      undefined,
      undefined,
      false
    );
  };

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  render() {
    const { small } = this.state;
    const {
      item,
      user,
      inner,
      onClick,
      isMsg,
      //report
    } = this.props;

    // console.log('CommunityItem: ', item);
    // console.log('CommunityItem / user: ', user);

    // let followingUsersInCommunity = null;
    // if (!inner) {
    //   followingUsersInCommunity = user.following.filter(
    //     (user) => item.members.filter((member) => user._id === member).length > 0
    //   );
    // }

    const followingUsersInCommunity = user?.following?.filter(
      (user) => item.members?.filter((member) => user._id === member).length > 0
    );

    return (
      <Translation>
        {(t) => (
          <div
            className='CommunityItem__Groups'
            ref={this.rlvRef}
            onClick={inner ? onClick : void 0}
            style={{
              background: isMsg ? FEDERAL_BLUE_SOFT : `linear-gradient(180deg, ${FEDERAL_BLUE_SOFT}, ${FEDERAL_BLUE})`,
            }}
          >
            <div className='CommunityItem__Header'>
              <div style={{ width: '100%', display: 'flex' }}>
                <img src={item?.picture ? item.picture : BG} className='CommunityItem__ImgGroup' alt='' />
                <div className='CommunityItem__Content'>
                  <h1>{item.name}</h1>
                  <p
                    style={{
                      color: WHITE_MAIN,
                      position: small ? 'relative' : 'absolute',
                      top: '0',
                      right: '0',
                      textAlign: 'right',
                      marginRight: inner ? 15 : 55,
                    }}
                  >
                    {t('COMMUNITY_PROFILE.creationDate')}: {moment(item.creationDate).format('MM/DD/YYYY')}
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  textAlign: 'left',
                  boxSizing: 'border-box',
                  padding: 5,
                }}
              >
                <p style={{ color: WHITE_MAIN, margin: '5px 0' }}>{item.description}</p>
              </div>
              <div className='CommunityItem__footer'>
                {!inner && (
                  <button
                    className='CommunityItem__JoinBtn'
                    onClick={() => this.props.history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${item._id}`)}
                  >
                    {item?._id !== user?.community?._id ? t('COMMUNITY_PROFILE.join') : t('COMMUNITY_PROFILE.joined')}
                  </button>
                )}
                <div className='CommunityItem__members'>
                  <p style={{ margin: '5px', color: WHITE_MAIN }}>
                    {`${item?.members?.length} ${t('COMMUNITY_PROFILE.members')}`}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexFlor: 'row no-wrap',
                      paddingLeft: '12px',
                      boxSizing: 'border-box',
                    }}
                  >
                    {followingUsersInCommunity && followingUsersInCommunity.length > 0
                      ? followingUsersInCommunity.slice(0, 5).map((itm, i) => (
                          <UserProfile
                            containerStyle={{
                              width: '40px',
                              height: '40px',
                              marginLeft: '-12px',
                            }}
                            key={i}
                            uri={itm?.picture ?? null}
                            onClick={() => this.props.history.push(`${routes.PROFILE_ROUTE}/${itm._id}`)}
                          />
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <Alert
              isOpen={this.state.isModalOpen}
              type={this.state.modalType}
              title={this.state.modalTitle}
              content={this.state.modalContent}
              choose={this.state.modalChoose}
              NOClick={this.state.noAction}
              noButtonText={this.state.noText}
              YESClick={this.state.yesAction}
              yesButtonText={this.state.yesText}
              singleButtonText={this.state.btnTxt}
              onRequestClose={this.state.btnAction}
              loading={this.state.actionsLoader}
            />
            {!inner && (
              <MenuButton
                withBorder
                onClick={() =>
                  this.setState({
                    isOptionsOpen: true,
                  })
                }
              />
            )}
            {!inner && (
              <OptionModal
                isOpen={this.state.isOptionsOpen}
                onRequestClose={() => this.setState({ isOptionsOpen: false })}
                show={{
                  share: {
                    display: true,
                    onClick: () => this.sharePost(item._id),
                  },
                  shareAsMsg: {
                    display: true,
                    onClick: () => this.shareAsMsg(item._id),
                  },
                  shareUrl: { display: true, onClick: () => this.shareUrl(item._id) },
                  report: { display: user?.community?._id !== item._id, onClick: () => this.onReportCall() },
                }}
              />
            )}
          </div>
        )}
      </Translation>
    );
  }
}

export default withRouter(CommunityItem);
