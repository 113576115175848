import React from 'react';
import { Translation } from 'react-i18next';
import Logo from '../../assets/id-card/ID_icon.png';
import LogoDark from '../../assets/id-card/ID_icon_dark.png';
import {
  FEDERAL_BLUE,
  FEDERAL_BLUE_STRONG,
  FEDERAL_GREEN,
  FEDERAL_LIGTH_BLUE,
  FEDERAL_RED,
} from '../../utils/color-palatte';
import OrnamentProfile from '../ornament-profile/ornament-profile';
import moment from 'moment'
import './id-card.css';

const IDCard = React.forwardRef((props, ref) => {
  const {
    style,
    isBlue,
    user,
    capturable,
  } = props;
  return (
    <Translation>
      {(t) => (
        <div
          ref={ref}
          className='IDCard__Container'
          style={Object.assign(
            {
              border: `3px solid ${isBlue ? FEDERAL_LIGTH_BLUE : FEDERAL_BLUE}`,
              userSelect: 'none',
            },
            style
          )}
        >
          <div
            className='IDCard__Header'
            style={{
              backgroundColor: isBlue ? FEDERAL_LIGTH_BLUE : FEDERAL_BLUE,
            }}
          >
            <img src={isBlue ? LogoDark : Logo} alt='' draggable='false' />
            <h1 style={{ color: isBlue ? FEDERAL_BLUE_STRONG : 'white' }}>
              Global Government
            </h1>
          </div>
          <div className='IDCard__Content'>
            <div className='IDCard__Profile'>
              <OrnamentProfile
                ref={ref}
                capturable={capturable}
                userPicture={user?.picture ?? undefined}
                onlyRank
                isWhite
                userRank={'citizen'}
              />
            </div>
            <div className='IDCard__Data-Container'>
              <div className='IDCard__Data'>
                <p>
                  <b>{t('ID_CARD.name')}</b> <br />
                  {user.name}
                </p>
                <p>
                  <b>{t('ID_CARD.status')}</b> <br />
                  <span
                    style={{
                      color: user.block ? FEDERAL_RED : FEDERAL_GREEN,
                    }}
                  >
                    {!user.block ? t('ID_CARD.active') : t('ID_CARD.inactive')}
                  </span>
                </p>
              </div>
              <div className='IDCard__Data'>
                {/* <p>
                  <b>{t('ID_CARD.bornDate')}</b> <br />
                  {moment(user.bornDate).format('MM-DD-YYYY')}
                </p> */}
                <p>
                  <b>{t('ID_CARD.since')}</b> <br />
                  {moment(user.createDate).format('MM-DD-YYYY')}
                </p>
              </div>
              <div className='IDCard__UserName'>
                <p>{t('ID_CARD.username')}</p>
                <h1>{user.username}</h1>
              </div>
              <div className='IDCard__NumberID'>
                <p>ID</p>
                <div className='IDCard__ID' style={{ userSelect: 'text' }}>
                  {user.number}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
});

export default IDCard;
