import React, { useState } from 'react';
import { useEffect } from 'react';
import getStat from '../../utils/getStat';
import { isUserValid } from '../../utils/isUserValid';
import * as routes from '../../routes/routes';
import './hierarchy.css';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import OrnamentProfile from '../../components/ornament-profile/ornament-profile';
import Countdown from 'react-countdown';
import secondsBetweenDates from '../../utils/secondsBetweenDates';
import { AppLoader } from '../../utils/animations/animations';
import { FEDERAL_BLUE, FEDERAL_LIGTH_BLUE, UNSATURATED_BLACK_BLUE, FEDERAL_GREEN } from '../../utils/color-palatte';
import { Trans, Translation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { BSpan } from '../../utils/text-utils/text-utils';
import InfoModal from '../../components/info-modals/info-modals';

const PositionInfo = (props) => {
  const { darkMode, salary, period, style, uri, position, onClick, title, content } = props;

  const [isSmall, setIsSmall] = useState(false);

  const nthRef = React.useRef();
  let observer = null;

  useEffect(() => {
    // eslint-disable-next-line
    observer = new ResizeObserver((entries) => {
      const boxy = entries[0];
      if (boxy.contentRect.width <= 500) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    });
    if (observer) {
      observer.observe(nthRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isSmall]);

  return (
    <div
      ref={nthRef}
      className='Hierarchy__PositionInfo-Container'
      style={Object.assign(
        {
          backgroundColor: darkMode ? UNSATURATED_BLACK_BLUE : FEDERAL_LIGTH_BLUE,
          flexFlow: isSmall ? 'column nowrap' : 'row nowrap',
          alignItems: isSmall ? 'center' : 'flex-start',
        },
        style
      )}
    >
      <div className='Hierarchy__PositionInfo-Ornament'>
        <OrnamentProfile
          userPicture={uri}
          userRank={position}
          userRankStyles={{ display: 'none' }}
          onClick={onClick}
          style={{ transform: 'scale(.5,.5)', transformOrigin: 'top left', height: '100px', cursor: onClick ? 'pointer' : 'default' }}
        />
      </div>
      <div className='Hierarchy__PositionInfo-content' style={{ alignItems: isSmall ? 'center' : 'flex-start', marginTop: isSmall ? 10 : 0 }}>
        <h1 className='Hierarchy__PositionInfo-title' style={{ color: darkMode ? '#CCC' : UNSATURATED_BLACK_BLUE }}>
          {title}
        </h1>
        <p className='Hierarchy__PositionInfo-text' style={{ textAlign: isSmall ? 'center' : 'left', color: darkMode ? '#FFF' : '#000' }}>
          {content}
        </p>
        {salary && period ? (
          <div className='Hierarchy__PositionInfo-data' style={{ color: darkMode ? '#FFF' : '#000' }}>
            <p>
              <b>{i18n.t('GG_HIERARCHY.salary')}</b> &#8776; {salary}$
            </p>
            <p>
              <b>{i18n.t('GG_HIERARCHY.period')}:</b> {period}{' '}
              {Number(period) > 1 ? i18n.t('GG_HIERARCHY.DAYS.plural') : i18n.t('GG_HIERARCHY.DAYS.singular')}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const Counter = (props) => {
  const { createdAt, days, style, darkMode } = props;
  if (!createdAt || !days) {
    return null;
  }
  return (
    <Translation>
      {(t) => (
        <Countdown
          date={Date.now() + secondsBetweenDates(createdAt, days) * 1000}
          renderer={({ days, hours, minutes, seconds }) => {
            const n = (num) => (num > 9 ? '' + num : '0' + num);
            return (
              <p
                className='Hierarchy__Countdown'
                style={Object.assign(
                  { color: darkMode ? UNSATURATED_BLACK_BLUE : '#FFF', backgroundColor: darkMode ? '#FFF' : FEDERAL_BLUE },
                  style
                )}
              >
                {/* Aqui relajado corrigiendo una verga bastante intuitiva por que a mi jefe se le metio la mariquera a lo fuerte... */}
                {t('GG_HIERARCHY.expire')}: {n(days)} : {n(hours)} : {n(minutes)} : {n(seconds)}
              </p>
            );
          }}
        />
      )}
    </Translation>
  );
};

const Hierarchy = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [positions, setPositions] = useState(null);
  const [positionsPeriodDays, setPositionsPeriodDays] = useState(null);
  const [presidentSalaryPorcetage, setPresidentSalaryPorcetage] = useState(null);
  const [presidentSalary, setPresidentSalary] = useState(null);
  const [vicepresidentSalaryPorcentage, setVicepresidentSalaryPorcentage] = useState(null);
  const [vicepresidentSalary, setVicepresidentSalary] = useState(null);

  const [isSmall, setIsSmall] = useState(false);

  const [modal, setModal] = useState({
    isOpen: false,
    type: null,
  });

  const history = useHistory();

  const rvlRef = React.createRef();
  let observer = null;

  let _isMounted = false;

  useEffect(() => {
    // eslint-disable-next-line
    observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      if (boxElem.contentRect.width <= 500) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    });
    if (observer) {
      observer.observe(rvlRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isSmall]);

  useEffect(() => {
    // eslint-disable-next-line
    _isMounted = true;
    (async () => {
      isUserValid(false, _isMounted);
      if (_isMounted) await getStats();
    })();
    return () => {
      _isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const getStats = () =>
    new Promise(async (resolve, reject) => {
      setIsLoading(true);
      if (isLoading) {
        try {
          const positions = await getStat('positions');
          const positionsPeriodDays = await getStat('position-expires-period-days');
          const presidentSalaryPorcetage = await getStat('president-salary-porcentage');
          const presidentSalary = await getStat('president-salary');
          const vicepresidentSalaryPorcentage = await getStat('vicepresident-salary-porcentage');
          const vicepresidentSalary = await getStat('vicepresident-salary');
          setPositions(positions);
          setPositionsPeriodDays(positionsPeriodDays);
          setPresidentSalaryPorcetage(presidentSalaryPorcetage);
          setPresidentSalary(presidentSalary);
          setVicepresidentSalaryPorcentage(vicepresidentSalaryPorcentage);
          setVicepresidentSalary(vicepresidentSalary);
          setIsLoading(false);
          resolve();
        } catch (e) {
          reject();
        }
      }
    });

  const goUserProfile = async (userId) => {
    if (userId) {
      const currentUserId = localStorage.getItem('userId');
      if (currentUserId === userId) {
        history.push(routes.PROFILE_ROUTE);
      } else {
        history.push(`${routes.PROFILE_ROUTE}/${userId}`);
      }
    }
  };

  const showInfo = (isOpen, type) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
    });
  };

  let president = null;
  let vicepresident = null;

  if (positions && positions.length > 0) {
    if (positions.filter((item) => item.position === 'president').length > 0) {
      president = positions.filter((item) => item.position === 'president')[0];
    }
    if (positions.filter((item) => item.position === 'vicepresident').length > 0) {
      vicepresident = positions.filter((item) => item.position === 'vicepresident')[0];
    }
  }

  const { darkMode } = props;

  const PSalary = presidentSalaryPorcetage ? presidentSalaryPorcetage : '...';
  const VPSalary = vicepresidentSalaryPorcentage ? vicepresidentSalaryPorcentage : '...';

  const GSpan = ({ children, type }) => (
    <BSpan style={{ color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE }} onClick={() => showInfo(true, type)}>
      {children}
    </BSpan>
  );

  const PositionItems = [
    {
      uri: president?.user?.picture ?? null,
      position: 'president',
      onClick: president?.user?._id ? () => goUserProfile(president.user._id) : null,
      title: i18n.t('GENERAL_TEXT.president'),
      content: (
        <Trans i18nKey='GG_HIERARCHY.INFO.presidentInfo'>
          bla <GSpan type='laws'> bla </GSpan> bla <GSpan type='deals'> bla </GSpan> bla <GSpan type='citizen'> bla </GSpan> bla
          <GSpan type='president'> bla </GSpan> {{ PSalary }} bla
        </Trans>
      ),
      salary: presidentSalary ?? '...',
      period: positionsPeriodDays ?? '...',
    },
    {
      uri: vicepresident?.user?.picture ?? null,
      position: 'vicepresident',
      onClick: vicepresident?.user?._id ? () => goUserProfile(vicepresident.user._id) : null,
      title: i18n.t('GENERAL_TEXT.vicepresident'),
      content: (
        <Trans i18nKey='GG_HIERARCHY.INFO.vicePresidentInfo'>
          bla <GSpan type='president'> bla </GSpan> bla <GSpan type='citizen'> bla </GSpan> bla <GSpan type='president'> bla </GSpan> bla
          <GSpan type='vicepresident'> bla </GSpan> {{ VPSalary }} bla
        </Trans>
      ),
      salary: vicepresidentSalary ?? '...',
      period: positionsPeriodDays ?? '...',
    },
    {
      position: 'citizen',
      title: i18n.t('GENERAL_TEXT.citizen'),
      content: (
        <Trans i18nKey='GG_HIERARCHY.INFO.citizenInfo'>
          bla <GSpan type='citizen'> bla </GSpan> bla
        </Trans>
      ),
    },
  ];

  return (
    <Translation>
      {(t) => (
        <div
          ref={rvlRef}
          className='Hierarchy__Container'
          style={{ height: isLoading ? 'calc(100vh - 100px)' : 'unset', justifyContent: isLoading ? 'center' : 'flex-start' }}
        >
          {isLoading ? (
            <AppLoader size='big' color={darkMode ? '#FFF' : FEDERAL_BLUE} />
          ) : (
            <>
              <div className='Hierarchy__Banner' style={{ paddingTop: isSmall ? '38%' : '33%' }}>
                <div className='Hierarchy__Ornament-container' style={isSmall ? { transform: 'translate(-50%, 0) scale(.8)' } : undefined}>
                  <OrnamentProfile
                    onClick={president && president?.user?._id ? () => goUserProfile(president.user._id) : null}
                    userPicture={president?.user?.picture ?? null}
                    style={{ cursor: president && president?.user?._id ? 'pointer' : 'default' }}
                    userName={president?.user?.username ?? t('GG_HIERARCHY.avilable')}
                    userRank={'President'}
                    userRankStyles={{ color: darkMode ? '#FFF' : FEDERAL_BLUE, textAlign: 'center', fontSize: '1.5rem' }}
                    userNameStyles={{ color: darkMode ? '#fff' : FEDERAL_BLUE, textAlign: 'center', fontSize: '1.1rem', textTransform: 'capitalize' }}
                  />
                  <Counter createdAt={president?.createdAt ?? null} days={positionsPeriodDays} darkMode={darkMode} />
                </div>
              </div>
              <div className='Hierarchy__Ornaments-content'>
                <div className='Hierarchy__Ornament-VP' style={{ transform: isSmall ? 'scale(.8)' : undefined }}>
                  <OrnamentProfile
                    onClick={vicepresident && vicepresident?.user?._id ? () => goUserProfile(vicepresident.user._id) : null}
                    userPicture={vicepresident?.user?.picture ?? null}
                    userName={vicepresident?.user?.username ?? t('GG_HIERARCHY.avilable')}
                    userRank={'vicepresident'}
                    style={{
                      transform: 'scale(.8,.8)',
                      transformOrigin: 'top center',
                      cursor: vicepresident && vicepresident?.user?._id ? 'pointer' : 'default',
                    }}
                    userRankStyles={{ color: darkMode ? '#FFF' : FEDERAL_BLUE, textAlign: 'center', fontSize: '1.5rem' }}
                    userNameStyles={{ color: darkMode ? '#fff' : FEDERAL_BLUE, textAlign: 'center', fontSize: '1.1rem', textTransform: 'capitalize' }}
                  />
                  <Counter createdAt={vicepresident?.createdAt ?? null} days={positionsPeriodDays} style={{ marginTop: '-50px' }} darkMode={darkMode} />
                </div>
                <div className='Hierarchy__Ornament-Cz' style={{ transform: isSmall ? 'scale(.8)' : undefined }}>
                  <OrnamentProfile
                    style={{ transform: 'scale(.65,.65)', transformOrigin: 'top center' }}
                    onlyRank
                    userRankStyles={{
                      color: darkMode ? '#FFF' : FEDERAL_BLUE,
                      textAlign: 'center',
                      fontSize: '1.5rem',
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                    }}
                  />
                </div>
                <div className='Hierarchy__Info-Container'>
                  {PositionItems.map((item) => (
                    <PositionInfo key={Math.random()} {...item} darkMode={darkMode} />
                  ))}
                </div>
              </div>
            </>
          )}
          <InfoModal isOpen={modal.isOpen} type={modal.type} onRequestClose={() => setModal({ ...modal, isOpen: false, type: null })} />
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => {
  const { darkMode } = state.user;
  return {
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Hierarchy);
