import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import {
  COMPANY_EMAIL,
  COMPANY_IG,
  COMPANY_IG_USERNAME,
  COMPANY_INFO_EMAIL,
  COMPANY_TWITTER,
  COMPANY_TWITTER_USERNAME,
  COMPANY_FACEBOOK,
  COMPANY_FACEBOOK_USERNAME
} from '../../config';
import SafeUrl from '../../utils/safe-url';
import IG_LOGO from '../../assets/footer/Instagram.svg';
import FB_LOGO from '../../assets/footer/facebook-icon.svg';
import TWITTER_LOGO from '../../assets/footer/Twitter.svg';
import MAIL_LOGO from '../../assets/footer/Mail.svg';
import President from '../../assets/characters/president-approve.svg';
import VicePresident from '../../assets/characters/vicepresident-approve.svg';
import Citizen from '../../assets/characters/citizen-approve.svg';
import CitizenLogo from '../../assets/footer/citizen_icon.svg';
import CitizenSelected from '../../assets/footer/citizen_icon_selected.svg';
import PresidentLogo from '../../assets/footer/president_icon.svg';
import PresidentSelected from '../../assets/footer/president_icon_selected.svg';
import VicepresidentLogo from '../../assets/footer/vice-president_icon.svg';
import VicepresidentSelected from '../../assets/footer/vice-president_icon_selected.svg';
import './footer.css';
import SafeLINK from '../../utils/safe-link';
// import ReactFlagsSelect from 'react-flags-select';

const Footer = () => {
  const [president, setPresident] = useState(false);
  const [vicepresident, setVicepresident] = useState(false);
  const [citizen, setCitizen] = useState(true);

  const selectPresident = () => {
    setPresident(true);
    setVicepresident(false);
    setCitizen(false);
  };

  const selectVicepresident = () => {
    setPresident(false);
    setVicepresident(true);
    setCitizen(false);
  };

  const selectCitizen = () => {
    setPresident(false);
    setVicepresident(false);
    setCitizen(true);
  };

  return (
    <Translation>
      {(t) => (
        <div className='footer'>
          <div className='footer__Citizen'>
            <div className='footer__Img-Menu'>
              <img
                src={citizen ? CitizenSelected : CitizenLogo}
                alt=''
                onClick={selectCitizen}
                className='footer__Img3'
                draggable='false'
              />
              <img
                src={vicepresident ? VicepresidentSelected : VicepresidentLogo}
                alt=''
                onClick={selectVicepresident}
                className='footer__Img2'
                draggable='false'
              />
              <img
                src={president ? PresidentSelected : PresidentLogo}
                alt=''
                onClick={selectPresident}
                className='footer__Img1'
                draggable='false'
              />
            </div>
            <img
              src={
                president ? President : vicepresident ? VicePresident : Citizen
              }
              alt=''
              className='footer__BGImg'
              draggable='false'
            />
          </div>
          <div className='footer__content1'>
            <h1>{t('FOOTER.menu')}</h1>
            <SafeLINK
              dangerousLINK='/faq'
              text={t('FOOTER.helpCenter')}
              className='footer__url'
            />
            {/* <div className="flags-select-container-footer">
              <ReactFlagsSelect
                className='Footer__Flag'
                // ref={this.userFlag}
                defaultCountry={String(localStorage.getItem("language") || "US").toUpperCase()}
                countries={["US", "ES"]}
                showSelectedLabel={false}
                showOptionLabel={false}
                onSelect={(selected) => {
                  localStorage.setItem("language", String(selected).toLowerCase())
                  window.location.reload(false);
                }}
              />
            </div> */}
          </div>
          <div className='footer__content1'>
            <h1>{t('FOOTER.getSupport')}</h1>
            <SafeUrl
              dangerousURL={`mailto:${COMPANY_EMAIL}`}
              text={COMPANY_EMAIL}
              className='footer__url'
            />

          </div>
          <div className='footer__content1'>
            <h1>{t('FOOTER.findUs')}</h1>
            {COMPANY_TWITTER && <SafeUrl
              newTab
              dangerousURL={COMPANY_TWITTER}
              text={
                <>
                  <img src={TWITTER_LOGO} alt='' />{' '}
                  <p>{COMPANY_TWITTER_USERNAME}</p>
                </>
              }
              className='footer__url'
            />}
            {COMPANY_IG && <SafeUrl
              newTab
              dangerousURL={COMPANY_IG}
              text={
                <>
                  <img src={IG_LOGO} alt='' />
                  <p>{COMPANY_IG_USERNAME}</p>
                </>
              }
              className='footer__url'
            />}
            {COMPANY_FACEBOOK && <SafeUrl
              newTab
              dangerousURL={COMPANY_FACEBOOK}
              text={
                <>
                  <img src={FB_LOGO} alt='' />
                  <p>{COMPANY_FACEBOOK_USERNAME}</p>
                </>
              }
              className='footer__url'
            />}
            <SafeUrl
              dangerousURL={COMPANY_INFO_EMAIL}
              text={
                <>
                  <img src={MAIL_LOGO} alt='' /> <p>{COMPANY_INFO_EMAIL}</p>
                </>
              }
              className='footer__url'
            />
          </div>
        </div>
      )}
    </Translation>
  );
};

export default Footer;
