import { getData } from '../fetch';
import {
  CLOSE_PAYMENT_MODAL_AGREEMENT,
  // OPEN_MESSAGE_MODAL,
  LOGOUT,
  // SAVE_USER,
} from '../redux/types/user/types';
// import { validateAppVersion } from './validateAppVersion';
import validateUserPayment from './validateUserPayment';
import { store } from '../redux/configureStore';
import { throttle } from 'lodash';
import * as routes from '../routes/routes';
// import i18n from '../i18n/i18n';
import history from '../routes/history';
import getUser from './getUser';
import { getUserAchievements } from '../pages/dashboard/dashboard-actions';
import hasUserNewNotifications from './hasUserNewNotifications';

export const isUserValid = throttle((avoidPaymentValidation = false, isMounted) => {
  // console.log("avoidPaymentValidation: ", avoidPaymentValidation)
  if (!isMounted) return;
  return new Promise(async (resolve, _) => {
    // console.log("isUserValid")
    const state = store.getState().user;
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (userId && token && state.user) {
      // await validateAppVersion()
      const user = await getUser(userId);
      // await store.dispatch({ type: SAVE_USER, data: user });
      if (!avoidPaymentValidation) {
        await validateUserPayment();
      } else {
        await store.dispatch({ type: CLOSE_PAYMENT_MODAL_AGREEMENT, data: null });
      }

      try {
        await getData(`/user/is-user-valid-web/${userId}`);
        getUserAchievements(user._id, user);
        hasUserNewNotifications();
        resolve();
      } catch (e) {
        // alert(`isUserValid / catch error: ${JSON.stringify(e)}`)
        // console.log("isUserValid / catch error: ", e)
        if (e && e.data && e.data.error && e.data.error.code !== 'internal-error' && e.status !== 429) {
          // const errorMessage =
          //   e && e.data && e.data.error
          //     ? e.data.error.message
          //     : i18n.t('ERRORS.serverError');
          await store.dispatch({ type: LOGOUT, data: null });
          // store.dispatch({ type: OPEN_MESSAGE_MODAL, data: { message: errorMessage, type: "error" } })
          // console.log('errorMessage:', errorMessage);
          history.push(routes.HOME_ROUTE);
        }
        resolve(e);
      }
    } else {
      // alert(`isUserValid / else error: userId: ${userId}, token: ${token}, props.user: ${props.user}`,)
      await store.dispatch({ type: LOGOUT, data: null });
      // store.dispatch({ type: OPEN_MESSAGE_MODAL, data: { message: i18n.t('ERRORS.serverError'), type: "error" } })
      // console.log(`isUserValid / else error: userId: ${userId}, token: ${token}, props.user: ${state.user}`)
      history.push(routes.HOME_ROUTE);
      resolve();
    }
  });
}, 10000);
