import { getData, putData } from "../../fetch";

export const getVoting = ({
  userId, 
  skipItems, 
  limitItems, 
  search, 
  admin, 
  official, 
  toDate, 
  sinceDate,
  votingId
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/voting/get-voting-web/?userId=${userId}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&admin=${admin}&official=${official}&sinceDate=${sinceDate}&toDate=${toDate}&votingId=${votingId}`);
      // console.log("getPosts / response: ", response);
      const posts = response.body;
      return resolve(posts);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  })
};

export const getVotingDetails = (votingId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/voting/get-voting-details-web/?votingId=${votingId}`);
      // console.log("getVotingDetails / response: ", response);
      const voting = response.body;
      return resolve(voting);
    } catch (e) {
      // console.log("getVotingDetails / error: ", e);
      return reject(e);
    }
  })
};

export const getVotingHistoryDetails = (votingId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/voting-history/get-voting-details-web/?votingId=${votingId}`);
      // console.log("getVotingDetails / response: ", response);
      const voting = response.body;
      return resolve(voting);
    } catch (e) {
      // console.log("getVotingDetails / error: ", e);
      return reject(e);
    }
  })
};

export const vote = (userId, votingId, desicion) => {
  return new Promise(async (resolve, reject) => {
    const data = { user: userId, voting: votingId, desicion }
    // console.log("vote / data: ", data);
    try {
      const response = await putData(`/voting/vote-voting-web`, data);
      //   console.log("like / response: ", response);
      return resolve(response);
    } catch (e) {
      //   console.log("like / error: ", e);
      return reject(e);
    }
  })
};