import React from "react";
import { withRouter } from "react-router-dom";
import * as routes from "../../routes/routes";
import {
  FEDERAL_LIGTH_BLUE,
  UNSATURATED_BLACK_BLUE,
  WHITE_MAIN,
  FEDERAL_BLUE_SOFT,
} from "../../utils/color-palatte";
import MenuButton from "../menu-button/MenuButton";
import OptionModal from "../options-modal/optionsModal";
import { UserName, UserProfile } from "../ornament-profile/profileUtils";
// import { CURRENT_DOMAIN } from '../../config';
import i18n from "../../i18n/i18n";
import Alert from "../../utils/alert/Alert";
import "./userItem.css";

class UserItem extends React.PureComponent {
  state = {
    isPostMenuOpen: false,
    isSecondMenuOpen: false,
    reason: "",
    firstMatches: window.matchMedia("(max-width: 600px)").matches,
  };

  componentDidMount = () => {
    window.matchMedia("(max-width: 600px)").addEventListener("change", (e) =>
      this.setState({
        firstMatches: e.matches,
      })
    );
  };

  componentWillUnmount = () => {
    window.matchMedia("(max-width: 600px)").removeEventListener("change", (e) =>
      this.setState({
        firstMatches: e.matches,
      })
    );
  };

  goUserProfile = (id) => {
    const currentUserId = localStorage.getItem("userId");
    if (currentUserId === id) {
      this.props.history.push(routes.PROFILE_ROUTE);
    } else {
      this.props.history.push(`${routes.PROFILE_ROUTE}/${id}`);
    }
  };

  report = () => {
    this.setState(
      {
        isModalOpen: true,
        modalType: "warning",
        modalTitle: "please wait",
        actionsLoader: true,
      },
      async () => {
        const { item, report } = this.props;
        try {
          await report(item._id);
          this.setState({
            isModalOpen: false,
            actionsLoader: false,
          });
        } catch (e) {
          this.setState({ isModalOpen: false, actionsLoader: false });
        }
      }
    );
  };

  giveLeadershipFunc = () => {
    this.setState({ actionsLoader: true }, async () => {
      const { item, giveLeadership, communityId } = this.props;
      try {
        await giveLeadership(item._id);
        this.setState({ actionsLoader: false, isModalOpen: false });
        this.props.history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${communityId}`);
      } catch (e) {
        this.setState({ actionsLoader: false });
      }
    });
  };

  quitMemberFunc = () => {
    this.setState({ actionsLoader: true }, async () => {
      const { item, quitMember } = this.props;
      try {
        await quitMember(item._id);
        this.setState({ actionsLoader: false, isModalOpen: false });
        // this.props.history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${communityId}`);
      } catch (e) {
        this.setState({ actionsLoader: false });
      }
    });
  };

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  onReportCall = () => {
    this.showOptions(false);
    this.showAlert(
      true,
      "warning",
      i18n.t("DASHBOARD.MODAL.title"),
      i18n.t("USER_ITEM.MODAL.report"),
      true,
      () => this.setState({ isModalOpen: false }),
      "NO",
      () => this.report(),
      i18n.t("DASHBOARD.MODAL.yes"),
      undefined,
      undefined,
      false
    );
  };

  shareUrl = (id) => {
    this.showOptions(false);
    navigator.clipboard.writeText(
      `${window.location.origin}${routes.PROFILE_ROUTE}/${id}`
    );
    this.showAlert(
      true,
      "success",
      "Done",
      i18n.t("DASHBOARD.shareUrl"),
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      "Ok",
      () => this.setState({ isModalOpen: false }),
      false
    );
  };

  onGiveLeadershipCall = () => {
    this.showOptions(false);
    this.showAlert(
      true,
      "warning",
      i18n.t("DASHBOARD.MODAL.title"),
      i18n.t("COMMUNITIES.sureToGiveLeadership"),
      true,
      () => this.setState({ isModalOpen: false }),
      "NO",
      () => this.giveLeadershipFunc(),
      i18n.t("DASHBOARD.MODAL.yes"),
      undefined,
      undefined,
      false
    );
  }

  onQuitMemberCall = () => {
    this.showOptions(false);
    this.showAlert(
      true,
      "warning",
      i18n.t("DASHBOARD.MODAL.title"),
      i18n.t("COMMUNITIES.sureToQuitMember"),
      true,
      () => this.setState({ isModalOpen: false }),
      "NO",
      () => this.quitMemberFunc(),
      i18n.t("DASHBOARD.MODAL.yes"),
      undefined,
      undefined,
      false
    );
  }

  showOptions = (isOpen) => {
    this.setState({
      isOptionsOpen: isOpen,
    });
  };

  render() {
    const {
      item,
      // user,
      userId,
      positions,
      darkMode,
      isCommunity,
    } = this.props;

    const postUserId = item && item._id ? item._id : "";

    const position =
      positions &&
      positions.filter((position) => position.user._id === postUserId).length >
        0
        ? positions.filter((position) => position.user._id === postUserId)[0]
            .position
        : null;

    const { showOptions, goUserProfile, shareUrl, onReportCall } = this;

    // console.log('item', item);
    // console.log('positions', position)
    // console.log(item);

    return (
      <div
        className="UserItem__Container"
        style={{
          backgroundColor: darkMode
            ? UNSATURATED_BLACK_BLUE
            : FEDERAL_LIGTH_BLUE,
        }}
      >
        <UserProfile
          uri={item?.picture}
          containerStyle={{
            width: this.state.firstMatches ? "50px" : "60px",
            height: this.state.firstMatches ? "50px" : "60px",
          }}
          onClick={() => goUserProfile(item?._id)}
        />
        <UserName
          username={item?.username}
          position={position}
          darkMode={darkMode}
          style={{
            marginLeft: this.state.firstMatches ? "10px" : "16px",
          }}
          titleStyles={{
            color: darkMode ? WHITE_MAIN : "#000",
            fontSize:
              item?.username?.length > 9
                ? this.state.firstMatches
                  ? "1.1rem"
                  : "1.3rem"
                : this.state.firstMatches
                ? "1.3rem"
                : "1.5rem",
          }}
          crownStyle={{
            width: "25px",
          }}
          ornamentStyle={{
            width: "25px",
          }}
          onClick={() => goUserProfile(item?._id)}
        />
        {isCommunity && this.props.communityLeader === item._id ? (
          <h2
            style={{
              color: darkMode ? FEDERAL_BLUE_SOFT : UNSATURATED_BLACK_BLUE,
              fontSize: '1.4rem',
              marginLeft: 7
            }}
          >
            ({i18n.t("COMMUNITIES.leader")})
          </h2>
        ) : null}
        <MenuButton
          isBlue={!darkMode}
          withBorder
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(0, -50%)",
            marginRight: "20px",
          }}
          onClick={() => showOptions(true)}
        />
        <OptionModal
          isOpen={this.state.isOptionsOpen}
          onRequestClose={() => this.setState({ isOptionsOpen: false })}
          show={{
            shareUrl: {
              display: true,
              onClick: () => shareUrl(item?._id),
            },
            report: {
              display: item?._id === userId ? false : true,
              onClick: () => onReportCall(),
            },
            quitMember: {
              display:
                isCommunity &&
                this.props.communityLeader === userId &&
                userId !== item._id,
              onClick: () => this.onQuitMemberCall(),
            },
            giveLeadership: {
              display:
                isCommunity &&
                this.props.communityLeader === userId &&
                userId !== item._id,
              onClick: () => this.onGiveLeadershipCall(),
            },
          }}
        />
        <Alert
          isOpen={this.state.isModalOpen}
          type={this.state.modalType}
          title={this.state.modalTitle}
          content={this.state.modalContent}
          choose={this.state.modalChoose}
          NOClick={this.state.noAction}
          noButtonText={this.state.noText}
          YESClick={this.state.yesAction}
          yesButtonText={this.state.yesText}
          singleButtonText={this.state.btnTxt}
          onRequestClose={this.state.btnAction}
          loading={this.state.actionsLoader}
        />
      </div>
    );
  }
}

export default withRouter(UserItem);
