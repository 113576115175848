import React from 'react';
import i18n from '../../i18n/i18n';
import NotFoundCitizen from '../../assets/not-found-item/not_found_citizen_icon_gray.svg';
import NotFoundCitizenBlue from '../../assets/not-found-item/not_found_citizen_icon.svg';
import NotFoundDeals from '../../assets/not-found-item/not_found_deals_icon_gray.svg';
import NotFoundDealsBlue from '../../assets/not-found-item/not_found_deals_icon.svg';
import NotFoundLaws from '../../assets/not-found-item/not_found_laws_icon_gray.svg';
import NotFoundLawsBlue from '../../assets/not-found-item/not_found_laws_icon.svg';
import NotFoundServices from '../../assets/not-found-item/not_found_services_icon_gray.svg';
import NotFoundServicesBlue from '../../assets/not-found-item/not_found_services_icon.svg';
import NotFoundVotes from '../../assets/not-found-item/not_found_votes_icon_gray.svg';
import NotFoundVotesBlue from '../../assets/not-found-item/not_found_votes_icon.svg';
import NotFoundIncomes from '../../assets/not-found-item/not_found_incomes_icon_gray.svg';
import NotFoundIncomesBlue from '../../assets/not-found-item/not_found_incomes_icon.svg';
import NotFoundExpenses from '../../assets/not-found-item/not_found_expenses_icon_gray.svg';
import NotFoundExpensesBlue from '../../assets/not-found-item/not_found_expenses_icon.svg';
import NotFoundInvoicesBlue from '../../assets/not-found-item/not_found_invoices_icon.svg';
import NotFoundInvoices from '../../assets/not-found-item/not_found_invoices_icon_gray.svg';
import NotFoundNotificationsBlue from '../../assets/not-found-item/not_found_notifications_icon.svg';
import NotFoundNotifications from '../../assets/not-found-item/not_found_notifications_icon_gray.svg';
import NotFoundPositionHistoryBlue from '../../assets/not-found-item/not_found_position_history_icon.svg';
import NotFoundPositionHistory from '../../assets/not-found-item/not_found_position_history_icon_gray.svg';
import NotFoundPostIcon from '../../assets/not-found-item/not_found_post_icon_gray.svg';
import NotFoundPostIconBlue from '../../assets/not-found-item/not_found_post_icon.svg';
import './notFoundItem.css';
import { WHITE_MAIN } from '../../utils/color-palatte';

export const NotFoundItem = (props) => {
  const { type, isBlue, text, textStyle } = props;

  const ImageType =
    type?.toLowerCase() === 'services'
      ? isBlue
        ? NotFoundServicesBlue
        : NotFoundServices
      : type?.toLowerCase() === 'votes'
      ? isBlue
        ? NotFoundVotesBlue
        : NotFoundVotes
      : type?.toLowerCase() === 'laws'
      ? isBlue
        ? NotFoundLawsBlue
        : NotFoundLaws
      : type?.toLowerCase() === 'deals'
      ? isBlue
        ? NotFoundDealsBlue
        : NotFoundDeals
      : type?.toLowerCase() === 'income'
      ? isBlue
        ? NotFoundIncomesBlue
        : NotFoundIncomes
      : type?.toLowerCase() === 'expense'
      ? isBlue
        ? NotFoundExpensesBlue
        : NotFoundExpenses
      : type?.toLowerCase() === 'invoice' || type?.toLowerCase() === 'payment'
      ? isBlue
        ? NotFoundInvoicesBlue
        : NotFoundInvoices
      : type?.toLowerCase() === 'notification'
      ? isBlue
        ? NotFoundNotificationsBlue
        : NotFoundNotifications
      : type?.toLowerCase() === 'position-history'
      ? isBlue
        ? NotFoundPositionHistoryBlue
        : NotFoundPositionHistory
      : type?.toLowerCase() === 'post'
      ? isBlue
        ? NotFoundPostIconBlue
        : NotFoundPostIcon
      : isBlue
      ? NotFoundCitizenBlue
      : NotFoundCitizen;

  return (
    <div className='NotFoundItem__Container'>
      <img src={ImageType} alt='' style={{ opacity: isBlue ? '.5' : '1' }} draggable='false' />
      <p style={Object.assign({ color: isBlue ? WHITE_MAIN : '#000', textAlign: 'center' }, textStyle)}>
        {text ? text : type?.toLowerCase() === 'services'
          ? i18n.t('SERVICES.notServicesFound')
          : type?.toLowerCase() === 'votes'
          ? i18n.t('VOTING_GENERAL.notFound')
          : type?.toLowerCase() === 'laws'
          ? i18n.t('LAWS.notFound')
          : type?.toLowerCase() === 'deals'
          ? i18n.t('DEALS.notFound')
          : type?.toLowerCase() === 'income'
          ? i18n.t('INCOMES.notFound')
          : type?.toLowerCase() === 'expense'
          ? i18n.t('EXPENSES.notFound')
          : type?.toLowerCase() === 'invoice' || type?.toLowerCase() === 'payment'
          ? i18n.t('PAYMENTS_LIST.noSearchResults')
          : type?.toLowerCase() === 'notification'
          ? i18n.t('NOTIFICATIONS.notFound')
          : type?.toLowerCase() === 'position-history'
          ? i18n.t('POSITION_HISTORY.noSearchResults')
          : type?.toLowerCase() === 'post'
          ? i18n.t('DASHBOARD.noPostFound')
          : i18n.t('FOLLOWERS.citizenNotFound')}
      </p>
    </div>
  );
};
