import React from 'react';
import { Title, ParsedParagraph, Banner, Divider } from '../utils/utils';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../utils/styles.module.css';
import DealsBanner from '../../../assets/whitepaper/deals.svg';
import * as colors from '../../../utils/color-palatte';
import Alert from '../../../utils/alert/Alert';

const Deals = (props) => {
  const { darkMode, outer, mainTaxes, citizenGainDeal, presSalary, vicePresSalary, timeToApprove } = props;
  const { t } = useTranslation();
  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 1000px)').matches);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  const location = useLocation();

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 1000px)').addEventListener('change', handler);
    return () => window.matchMedia('(max-width: 1000px)').removeEventListener('change', handler);
  }, []);

  const copyUrl = (hash) => {
    if (hash) {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}#${hash}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    } else {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    }
  };

  // const sendToHash = (hash) => {
  //   if (hash) {
  //     history.push(`${location.pathname}#${hash}`);
  //   } else {
  //     history.push(location.pathname);
  //   }
  // };

  const showAlert = (isOpen, type, content) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  const maintenance = mainTaxes && citizenGainDeal ? mainTaxes - citizenGainDeal : '...';
  const capital =
    mainTaxes && presSalary && vicePresSalary ? 100 - (Number(mainTaxes) + Number(presSalary) + Number(vicePresSalary)) : '...';
  const presSalaryPercent = presSalary ? presSalary : '...';
  const vicePresSalaryPercent = vicePresSalary ? vicePresSalary : '...';
  const citizenGainForDeal = citizenGainDeal ? citizenGainDeal : '...';
  const timeApprove = timeToApprove ? timeToApprove : '...';
  const politicalMaintenance = presSalary && vicePresSalary ? Number(presSalary) + Number(vicePresSalary) : '...';

  const values = [
    {
      keyword: `${maintenance}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${presSalaryPercent}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${vicePresSalaryPercent}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${politicalMaintenance}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${capital}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${citizenGainForDeal}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${timeApprove}`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
  ];

  return (
    <div className={styles.Container}>
      <div
        style={{
          width: matches ? '100%' : 'calc(100% - 300px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: outer ? 0 : '70px',
        }}
      >
        <div className={styles.Content}>
          <Title text={t('WHITEPAPER.DEALS.title')} darkMode={darkMode} onClick={() => copyUrl()} />
          <Divider darkMode={darkMode} />
          <Banner src={DealsBanner} />
          <Divider darkMode={darkMode} />
          <ParsedParagraph
            style={{ whiteSpace: 'pre-line' }}
            string={t('WHITEPAPER.DEALS.content')(
              maintenance,
              capital,
              citizenGainForDeal,
              presSalaryPercent,
              vicePresSalaryPercent,
              timeApprove,
              politicalMaintenance
            )}
            darkMode={darkMode}
            hasWiki
            values={values}
          />
        </div>
      </div>
      <div className={styles.Wrapper} style={{ display: matches ? 'none' : 'flex', paddingTop: outer ? 0 : '70px' }}></div>
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ ...modal, isOpen: false, type: null, content: undefined })}
      />
    </div>
  );
};

export default Deals;
