import React from 'react';
import Confetti from 'react-confetti';
import ReactDOM from 'react-dom';
import './Modal.css';

const AlertModal = React.forwardRef((props, ref) => {
  const handleClick = (e) => {
    e.stopPropagation();
  };

  const width = window.innerWidth;
  const height = window.innerHeight;

  const [isRunning, setIsRunning] = React.useState(false);

  React.useEffect(() => {
    if (props.useConffeti) {
      setIsRunning(true);
    }
    return () => {
      if (props.useConffeti) {
        setIsRunning(false);
      }
    };
  }, [props.useConffeti]);

  if (!props.isOpen) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className='Modal'>
      <div className={`overlay ${props.overlayClassName}`} onClick={props.outsideClick}>
      {props.useConffeti ? (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={props.numberOfPieces}
          friction={1}
          gravity={0.4}
          run={isRunning}
          recycle={false}
          onConfettiComplete={() => setIsRunning(false)}
        />
      ) : null}
        <div className={`Modal__container ${props.modalClassName}`} style={props.style} onClick={handleClick} ref={ref}>
          {props.children}
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
});

export default AlertModal;
