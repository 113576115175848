import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./laws.css";
import VotingItem from "../../components/voting/votingItem";
import { isUserValid } from "../../utils/isUserValid";
import getStat from "../../utils/getStat";
import { getLaws } from "../../components/laws-list/lawsListActions";
import i18n from "../../i18n/i18n";
import moment from "moment";
import ScrollableDiv from "../../components/scrollable-div/scrollableDiv";
import { AppLoader } from "../../utils/animations/animations";
import { FEDERAL_BLUE } from "../../utils/color-palatte";
import SearchFilter from "../../components/search-filter/SearchFilter";
import { NotFoundItem } from "../../components/not-found-item/notFoundItem";
import Alert from "../../utils/alert/Alert";
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/create-voting/actions';

class Laws extends React.Component {
  constructor() {
    super();
    this.state = {
      fetching: false,
      skipItems: 0,
      limitItems: 5,
      plusPostNumber: 5,
      keepIncreasingCounter: false,
      search: "",
      positions: null,
      laws: [],
      isVisibleModal: false,
    };
  }

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(false, this._isMounted);
    if (this._isMounted) await this.getStats();
    if (this._isMounted) this.onRefreshLawsList();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const positions = await getStat("positions");
        this.setState({ positions });
        resolve();
      } catch (e) {
        reject();
        // console.log("getStats / error: ", e);
      }
    });
  };

  fetchLawsList = () => {
    return new Promise(async (resolve, reject) => {
      const {
        skipItems,
        limitItems,
        fetching,
        search,
        laws,
        sinceDate = "",
        toDate = "",
      } = this.state;
      const { state } = this.props.location
      const routeUserId = this.props.match.params.userId
      const { user } = this.props;
      const userId = routeUserId ? routeUserId : user._id
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const newLaws = await getLaws({
              userId: state?.user && userId ? userId : routeUserId ? userId : "",
              skipItems,
              limitItems,
              search,
              admin: user.admin,
              sinceDate,
              toDate,
            });
            // console.log("fetchLawsList / getVoting / newLaws: ", newLaws);
            let finalLawsList = [];
            finalLawsList = laws
              .concat(newLaws)
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t._id === item._id)
              )
              .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
            this.setState({
              laws: finalLawsList,
              fetching: false,
              keepIncreasingCounter: newLaws.length > 0,
            });
            resolve();
          } catch (e) {
            if (e.status === 404) {
              this.setState({ fetching: false });
              reject();
            } else {
              reject(e);
              // console.log("fetchLawsList / error: ", e)
              this.setState({ fetching: false });
              const error =
                e && e.data && e.data.error
                  ? e.data.error.message
                  : i18n.t("ERRORS.serverError");
              this.showAlert(true, "error", error);
            }
          }
        });
      }
    });
  };

  onRefreshLawsList = () => {
    const { plusPostNumber } = this.state;
    this.setState(
      { skipItems: 0, limitItems: plusPostNumber, laws: [] },
      () => {
        this.fetchLawsList();
      }
    );
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } =
      this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchLawsList();
        }
      );
    } else {
      this.fetchLawsList();
    }
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  setValue = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      fetching,
      // search,
      laws,
      positions,
    } = this.state;
    const { user, darkMode, saveField } = this.props;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const action = url.searchParams.get('action');
    const select = action === "pick"
    return (
      <ScrollableDiv
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexFlow: "column nowrap",
          width: "100%",
          maxWidth: "800px",
          margin: "0 auto",
        }}
        onEndReach={this.onEndReached}
      >
        <SearchFilter
          inputPlaceholder={i18n.t("LAWS.placeholder")}
          onRefresh={this.onRefreshLawsList}
          setValue={this.setValue}
        />
        {fetching && laws?.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "calc(100vh - 120px)",
            }}
          >
            <AppLoader color={darkMode ? "#FFF" : FEDERAL_BLUE} size="big" />
          </div>
        ) : null}
        {laws?.length > 0
          ? laws.map((item, i) => (
              <VotingItem
                isLaw
                item={item}
                key={i}
                positions={positions}
                select={select}
                usert={user}
                darkMode={darkMode}
                isDone
                saveField={saveField}
              />
            ))
          : null}
        {fetching && laws.length > 0 ? (
          <div
            style={{
              width: "100%",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppLoader color={darkMode ? "#FFF" : FEDERAL_BLUE} size="60px" />
          </div>
        ) : null}
        {!fetching && laws.length > 0 ? (
          <div
            style={{
              width: "100%",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: darkMode ? "#FFF" : FEDERAL_BLUE }}>
              {i18n.t("LAWS.noMoreLaws")}
            </p>
          </div>
        ) : null}
        {!fetching && laws.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "calc(100vh - 120px)",
            }}
          >
            <NotFoundItem
              isBlue={darkMode}
              type="laws"
              text={i18n.t("LAWS.notFound")}
            />
          </div>
        ) : null}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        />
      </ScrollableDiv>
    );
  }
}

const LawsWithRouter = withRouter(Laws);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveField: bindActionCreators(actions.saveField, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LawsWithRouter);
