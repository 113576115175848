import { getData, postData } from "../../fetch";

export const getCommunities = ({
  skipItems,
  limitItems,
  search,
  sinceDate,
  toDate,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const adminId = localStorage.getItem("adminId");
      const adminCode = localStorage.getItem("adminCode");
      const userId = localStorage.getItem("userId");
      const response = await getData(
        `/communities/get-communities-web/?skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&userId=${userId}&adminId=${adminId}&adminCode=${adminCode}&sinceDate=${sinceDate}&toDate=${toDate}`
      );
      // console.log("getPosts / response: ", response);
      const communities = response.body;
      return resolve(communities);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  });
};

export const reportCommunity = (communityId, reporter) => {
  const data = {
    communityId,
    reporter,
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(`/communities/report-community-web`, data);
      resolve(response.body);
    } catch (e) {
      reject(e);
    }
  });
};