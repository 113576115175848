import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styles from '../utils/styles.module.css';
import {
  Title,
  Paragraph,
  ParsedParagraph,
  Divider,
  Banner,
  SubTitle,
  LastTitle,
  ContentTitle,
  ListElement,
} from '../utils/utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import WhatsGGBanner from '../../../assets/whitepaper/whats_gg.svg';
import Alert from '../../../utils/alert/Alert';
import Flow from '../../home/flow/flow';
import ainstainImg from '../../../assets/characters/ainstain-shadow.png';

const WhatsGG = (props) => {
  const { darkMode, outer } = props;
  const { t } = useTranslation();
  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 1000px)').matches);
  const [secondMatches, setSecondMatches] = React.useState(window.matchMedia('(max-width: 450px)').matches);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    const secondHandler = (e) => setSecondMatches(e.matches);
    window.matchMedia('(max-width: 1000px)').addEventListener('change', handler);
    window.matchMedia('(max-width: 450px)').addEventListener('change', secondHandler);
    return () => {
      window.matchMedia('(max-width: 1000px)').removeEventListener('change', handler);
      window.matchMedia('(max-width: 450px)').removeEventListener('change', secondHandler);
    };
  }, []);

  const copyUrl = (hash) => {
    if (hash) {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}#${hash}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    } else {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    }
  };

  const sendToHash = (hash) => {
    if (hash) {
      history.push(`${location.pathname}#${hash}`);
    } else {
      history.push(location.pathname);
    }
  };

  const showAlert = (isOpen, type, content) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  return (
    <div className={styles.Container}>
      <div
        style={{
          width: matches ? '100%' : 'calc(100% - 300px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: outer ? 0 : '70px',
        }}
      >
        <div className={styles.Content}>
          <Title text={t('WHITEPAPER.title')} darkMode={darkMode} onClick={() => copyUrl()} />
          <Paragraph darkMode={darkMode}>{t('WHITEPAPER.subTitle')(moment(Date.now()).format('MM/DD/YYYY'))}</Paragraph>
          <Divider darkMode={darkMode} />
          <Banner src={WhatsGGBanner} />
          <Divider darkMode={darkMode} />
          <SubTitle
            text={t('WHITEPAPER.WHATS_GG.title')}
            darkMode={darkMode}
            hash='whats-gg'
            location={location}
            onClick={() => copyUrl('whats-gg')}
          />
          <ParsedParagraph string={t('WHITEPAPER.WHATS_GG.content')} hasWiki darkMode={darkMode} />
          <Divider darkMode={darkMode} half />
          <SubTitle text={t('FLOW.title')} darkMode={darkMode} hash='flow' location={location} onClick={() => copyUrl('flow')} />
          <Flow isScreen darkMode={darkMode} withoutTitle withoutWarning />
          <Divider darkMode={darkMode} half style={{ marginTop: '-50px' }} />
          <SubTitle
            text={t('FLOW.warningTitle')}
            darkMode={darkMode}
            hash='how-gg-works'
            location={location}
            onClick={() => copyUrl('how-gg-works')}
          />
          <ListElement
            style={{ marginTop: '40px', alignItems: 'flex-start' }}
            img={ainstainImg}
            imgStyles={{ width: '70px', objectFit: 'contain' }}
            content={
              <ParsedParagraph
                style={{ whiteSpace: 'pre-line', boxSizing: 'border-box', paddingLeft: secondMatches ? '5px' : '20px' }}
                darkMode={darkMode}
                hasWiki
                string={t('FLOW.warningInfo')}
              />
            }
          />
          <Divider darkMode={darkMode} half />
          <LastTitle
            text={t('WHITEPAPER.WHATS_GG.mission')}
            darkMode={darkMode}
            hash='mission'
            location={location}
            onClick={() => copyUrl('mission')}
          />
          <ParsedParagraph string={t('WHITEPAPER.WHATS_GG.missionContent')} hasWiki darkMode={darkMode} />
        </div>
      </div>
      <div className={styles.Wrapper} style={{ display: matches ? 'none' : 'flex', paddingTop: outer ? 0 : '70px' }}>
        <ContentTitle text={t('WHITEPAPER.content')} darkMode={darkMode} />
        <Paragraph onClick={() => sendToHash('whats-gg')} darkMode={darkMode} style={{ margin: 0, cursor: 'pointer' }}>
          {t('WHITEPAPER.WHATS_GG.title')}
        </Paragraph>
        <Paragraph onClick={() => sendToHash('flow')} darkMode={darkMode} style={{ margin: '15px 0 0 0', cursor: 'pointer' }}>
          {t('FLOW.title')}
        </Paragraph>
        <Paragraph
          onClick={() => sendToHash('how-gg-works')}
          darkMode={darkMode}
          style={{ margin: '15px 0 0 0', cursor: 'pointer' }}
        >
          {t('FLOW.warningTitle')}
        </Paragraph>
        <Paragraph onClick={() => sendToHash('mission')} darkMode={darkMode} style={{ cursor: 'pointer' }}>
          {t('WHITEPAPER.WHATS_GG.mission')}
        </Paragraph>
      </div>
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ ...modal, isOpen: false, type: null, content: undefined })}
      />
    </div>
  );
};

export default WhatsGG;
