export const API_URL = process.env.REACT_APP_API_URL
export const TIME_TO_RESPONSE = process.env.REACT_APP_TIME_TO_RESPONSE //time to response from server before show timeout error
export const CRYPTO_JS_SECRET_KEY = process.env.REACT_APP_CRYPTO_JS_SECRET_KEY

export const COMPANY_EMAIL = process.env.REACT_APP_COMPANY_EMAIL
export const COMPANY_IG = process.env.REACT_APP_COMPANY_IG
export const COMPANY_FACEBOOK = process.env.REACT_APP_COMPANY_FACEBOOK
export const COMPANY_FACEBOOK_USERNAME = process.env.REACT_APP_COMPANY_FACEBOOK_USERNAME
export const COMPANY_TWITTER = process.env.REACT_APP_COMPANY_TWITTER
export const COMPANY_INFO_EMAIL = process.env.REACT_APP_COMPANY_INFO_EMAIL
export const COMPANY_IG_USERNAME = process.env.REACT_APP_COMPANY_IG_USERNAME
export const COMPANY_TWITTER_USERNAME = process.env.REACT_APP_COMPANY_TWITTER_USERNAME
export const NOTICE_OF_PRIVACY_URL = process.env.REACT_APP_NOTICE_OF_PRIVACY_URL
export const CURRENT_SITE = process.env.REACT_APP_CURRENT_SITE
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
export const MAX_FILES_POST = process.env.REACT_APP_MAX_FILES_POST
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const TERM_AND_CONDITIONS_URL = process.env.REACT_APP_TERM_AND_CONDITIONS_URL
export const COMPANY_CONTACT_MAIL = process.env.REACT_APP_COMPANY_CONTACT_MAIL
export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME
export const HOME_VIDEO = process.env.REACT_APP_HOME_VIDEO

export const CURRENT_DOMAIN = process.env.REACT_APP_CURRENT_DOMAIN
// export const CURRENT_DOMAIN = 'http://localhost:3000'

export const STORE_LINK_ANDROID = process.env.REACT_APP_STORE_LINK_ANDROID
export const STORE_LINK_IOS = process.env.REACT_APP_STORE_LINK_IOS
export const NODE_ENV = "production"