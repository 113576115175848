import React from 'react';
import { FEDERAL_BLUE } from '../../color-palatte';
import './regular-input.css';

const RegularInput = ({
  className,
  type,
  isWhite,
  inputClassName,
  style,
  placeholder,
  name,
  onChange,
  value,
  id,
  disabled,
  autoComplete,
  maxLength,
  onKeyPress
}) => {
  return (
    <div className={`${className} Regular__Input-Container1`}>
      <input
        type={type}
        id={id}
        className={`${
          isWhite ? 'Regular__Input-white1' : 'Regular__Input1'
        } ${inputClassName}`}
        style={Object.assign(
          {
            border: `2px solid ${isWhite ? 'white' : FEDERAL_BLUE}`,
            opacity: disabled ? '0.5' : 'unset',
          },
          style
        )}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
      />
    </div>
  );
};

export default RegularInput;
