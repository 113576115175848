import React from 'react';
import { AppLoader } from '../../../../utils/animations/animations';
import { FEDERAL_BLUE } from '../../../../utils/color-palatte';
import thousandSeparator from '../../../../utils/thousandSeparator';
import './stats-item.css';

const StatsItems = ({ Logo, Txt, Num, mask = "", ...rest }) => (
  <section className='StatsCards' {...rest}>
    <img src={Logo} alt='Citizens'></img>
    <p>{Txt}</p>
    {Num === null ? (
      <AppLoader color={FEDERAL_BLUE} size='40px' />
    ) : (
      <h2>{thousandSeparator(Num)}{mask}</h2>
    )}
  </section>
);

export default StatsItems;
