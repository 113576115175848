import React from 'react';
import { useHistory } from 'react-router-dom';
import * as routes from '../../routes/routes';
import * as colors from '../../utils/color-palatte';
import IncomesIcon from '../../assets/finances/incomes/income_icon.svg';
import ExpensesIcon from '../../assets/finances/expenses/expense_icon.svg';
import { ReadMoreTxt, ReadMoreLinesTxt } from '../../utils/text-utils/text-utils';
import OptionModal from '../options-modal/optionsModal';
import MenuButton from '../menu-button/MenuButton';
import Alert from '../../utils/alert/Alert';
import { thousandSeparator } from '../../utils/thousandSeparator';
import './incomesItem.css';
import moment from 'moment';
import i18n from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const IncomesItem = (props) => {
  // const [activeSlide, setActiveSlide] = React.useState(0);

  const [isSmall, setIsSmall] = React.useState(false);
  const [options, setOptions] = React.useState(false);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: null,
  });

  const {
    item,
    user,
    // voting,
    darkMode,
    isExpense,
    hideMenu,
    onClick,
  } = props;

  // console.log('Item', item);

  const history = useHistory();

  const rvlRef = React.useRef();
  let observer = null;

  React.useEffect(() => {
    // eslint-disable-next-line
    observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      if (boxElem.contentRect.width <= 550) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    });
    if (observer) {
      observer.observe(rvlRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isSmall]);

  // const goToProfile = (id) => {
  //   const currentUserId = localStorage.getItem('userId');
  //   if (currentUserId === id) {
  //     history.push(routes.PROFILE_ROUTE);
  //   } else {
  //     history.push(`${routes.PROFILE_ROUTE}/${id}`);
  //   }
  // };

  // let currentPosition = '';
  // if (voting && voting.length > 0 && item.user) {
  //   const havePosition = voting.positions.filter((position) => position.user._id === item.user._id);
  //   if (havePosition.length > 0) {
  //     currentPosition = havePosition[0].position;
  //   }
  // }

  const shareUrl = (id) => {
    if (isExpense) {
      navigator.clipboard.writeText(`${window.location.origin}${routes.GG_EXPENSES_DETAILS_ROUTE}/${id}`);
    } else {
      navigator.clipboard.writeText(`${window.location.origin}${routes.GG_INCOMES_DETAILS_ROUTE}/${id}`);
    }
    setOptions(false);
    showAlert(true, 'success', i18n.t('DASHBOARD.shareUrl'));
  };

  const sharePost = (id) => {
    if (isExpense) {
      history.push(`${routes.CREATE_POST_ROUTE}/?expense=${id}`);
    } else {
      history.push(`${routes.CREATE_POST_ROUTE}/?income=${id}`);
    }
    setOptions(false);
  };

  const shareAsMsg = (id) => {
    if (isExpense) {
      history.push(`${routes.MESSAGES_ROUTE}/?expense=${id}`);
    } else {
      history.push(`${routes.MESSAGES_ROUTE}/?income=${id}`);
    }
    setOptions(false);
  };

  const showAlert = (isOpen, type, content) => {
    setModal({
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  const { t } = useTranslation();

  return (
    <div
      ref={rvlRef}
      className='IncomesItem__Container'
      style={{
        backgroundColor: darkMode ? colors.UNSATURATED_BLACK_BLUE : colors.FEDERAL_LIGTH_BLUE,
        border: `1px solid ${hideMenu ? colors.FEDERAL_BLUE : 'transparent'}`,
        cursor: hideMenu ? 'pointer' : 'unset',
      }}
      onClick={hideMenu ? onClick : void 0}
    >
      <div
        className='IncomesItem__ImageBorder'
        style={{
          width: isSmall ? 50 : 70,
          height: isSmall ? 50 : 70,
          border: `${isSmall ? 2 : 3}px solid ${darkMode ? colors.FEDERAL_BLUE_SOFT : colors.FEDERAL_BLUE}`,
          backgroundColor: colors.FEDERAL_LIGTH_BLUE,
        }}
      >
        <img
          src={isExpense ? ExpensesIcon : IncomesIcon}
          style={{ marginRight: isExpense ? '10%' : 'unset', marginLeft: isExpense ? 'unset' : '10%' }}
          alt=''
          draggable='false'
        />
      </div>
      <div className='IncomesItem__Content' style={{ width: hideMenu ? '100%' : 'calc(100% - 95px)'}}>
        <div className='IncomesItem__Header'>
          {user && item && item.description ? (
            item.description.split(/\r\n|\r|\n/).length > 2 ? (
              <p style={{ color: darkMode ? '#FFF' : '#000', margin: 0, width: hideMenu ? '100%' : 'calc(100% - 30px)', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                <b>{t('INCOMES.ITEM.description')}:</b>
                <br />
                <ReadMoreLinesTxt
                  style={{ margin: 0 }}
                  text={item.description}
                  readMoreColor={darkMode ? colors.FEDERAL_BLUE_SOFT : colors.FEDERAL_BLUE}
                  showLessColor={darkMode ? '#aaa' : '#333'}
                />
              </p>
            ) : item.description.length > 50 ? (
              <p style={{ color: darkMode ? '#fff' : '#000', margin: 0, width: hideMenu ? '100%' :  'calc(100% - 30px)', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                <b>{t('INCOMES.ITEM.description')}:</b>
                <br />
                <ReadMoreTxt
                  style={{ margin: 0 }}
                  maxLength={50}
                  text={item.description}
                  readMoreColor={darkMode ? colors.FEDERAL_BLUE_SOFT : colors.FEDERAL_BLUE}
                  showLessColor={darkMode ? '#aaa' : '#333'}
                />
              </p>
            ) : (
              <p style={{ color: darkMode ? '#fff' : '#000', margin: 0, width: hideMenu ? '100%' : 'calc(100% - 30px)', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                <b>{t('INCOMES.ITEM.description')}:</b>&nbsp;{item.description}
              </p>
            )
          ) : null}
          <p
            style={{
              color: darkMode ? colors.FEDERAL_LIGTH_BLUE : colors.UNSATURATED_BLACK_BLUE,
              fontSize: isSmall ? '.9rem' : '1rem',
            }}
          >
            {moment(item.creationDate).format('MM-DD hh:mm A')}
          </p>
          {item.deal ? (
            <button
              className='IncomesItem__Deal-btn'
              style={{
                fontSize: `${isSmall ? 0.8 : 1.1}rem`,
                backgroundColor: darkMode ? '#FFF' : colors.UNSATURATED_BLACK_BLUE,
                color: darkMode ? colors.UNSATURATED_BLACK_BLUE : '#FFF',
              }}
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${routes.DEALS_DETAILS_ROUTE}/${item.deal}`);
              }}
            >
              See deal
            </button>
          ) : null}
        </div>
        <div className='IncomesItem__Amount'>
          <p style={{ color: darkMode ? '#FFF' : '#000', fontSize: `${isSmall ? 0.8 : 1.1}rem`, margin: 0, textAlign: 'right' }}>
            {t('INCOMES.ITEM.amount')}:
            <br />
            <span
              style={{
                color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.FEDERAL_BLUE,
                fontSize: `${isSmall ? 1.2 : 1.5}rem`,
                fontWeight: 'bold',
              }}
            >
              {isExpense && '-'}
              {thousandSeparator(item.amount)}$
            </span>
          </p>
        </div>
      </div>
      {!hideMenu && (
        <MenuButton
          onClick={() => setOptions(true)}
          withBorder
          isBlue={!darkMode}
          style={{ position: 'absolute', right: 0, top: 0, marginTop: 10, marginRight: 10 }}
        />
      )}
      <OptionModal
        isOpen={options}
        onRequestClose={() => setOptions(false)}
        show={{
          share: {
            display: true,
            onClick: () => sharePost(item._id),
          },
          shareAsMsg: {
            display: true,
            onClick: () => shareAsMsg(item._id),
          },
          shareUrl: {
            display: true,
            onClick: () => shareUrl(item._id),
          },
        }}
      />
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ ...modal, isOpen: false, type: null, content: null })}
      />
    </div>
  );
};

export default IncomesItem;
