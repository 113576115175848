import React from 'react';
import i18n from '../../../i18n/i18n';
import editUser from '../../../utils/editUser';
import getStat from '../../../utils/getStat';
import { isUserValid } from '../../../utils/isUserValid';
import './editProfile.css';
import { getUsernameAvailability } from './editUserActions';
import { throttle } from 'lodash';
import deleteFiles from '../../../utils/delete-files';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../../redux/actions/user/actions';
import AlertModal from '../../../utils/modal/Modal';
import { UserProfile } from '../../ornament-profile/profileUtils';
import {
  FEDERAL_BLUE,
  FEDERAL_BLUE_SOFT,
  UNSATURATED_BLACK_BLUE,
  GRAY_SECOND_TRANSPARENCY_FULL,
  WHITE_MAIN,
} from '../../../utils/color-palatte';
import AddIcon from '../../../assets/edit-profile/add_photo_icon.svg';
import SaveIcon from '../../../assets/edit-profile/save_icon.svg';
import SaveIconWhite from '../../../assets/edit-profile/save_icon_white.svg';
import CloseIcon from '../../../assets/terms-modal/close-btn.svg';
import CloseIconWhite from '../../../assets/terms-modal/close-btn-white.svg';
import RightComponentItem from '../../../utils/app-inputs/right-component-input/rightCompoentInput';
import UnwatchIcon from '../../../assets/app-inputs/password-input/icon_unwatch_password.svg';
import UnwatchIconWhite from '../../../assets/app-inputs/password-input/icon_unwatch_password_white.svg';
import WatchIcon from '../../../assets/app-inputs/password-input/icon_watch_password.svg';
import WatchIconWhite from '../../../assets/app-inputs/password-input/icon_watch_password_white.svg';
import { AppLoader } from '../../../utils/animations/animations';
import { getUser } from '../../../pages/profile/profileActions';
import OptionModal from '../../options-modal/optionsModal';
import Resizer from 'react-image-file-resizer';
import CropModal from '../crop-modal/cropModal';
import { readerFile } from './readerFile';
import { getCroppedImg } from '../crop-modal/canvasUtils';
import { saveFile } from '../../../utils/saveFilesToCloud';
import { getBase64Size } from '../../../utils/getBase64File';
import { Translation } from 'react-i18next';
import profileBg from '../../../assets/profile/profile-top-background.png';
import Alert from '../../../utils/alert/Alert';

export const EditProfileButton = (props) => {
  const { mobile, onClick, disabled, darkMode, style, text } = props;
  const [hover, setHover] = React.useState(false);

  return (
    <button
      className='EditProfile__Button'
      onMouseEnter={mobile ? null : () => setHover(true)}
      onMouseLeave={mobile ? null : () => setHover(false)}
      onTouchStart={mobile ? () => setHover(true) : null}
      onTouchEnd={mobile ? () => setHover(false) : null}
      onClick={onClick}
      disabled={disabled}
      style={Object.assign(
        {
          width: '100%',
          height: '40px',
          display: 'flex',
          border: 'none',
          borderRadius: '10px',
          fontFamily: `'Source Sans Pro', sans-serif`,
          fontSize: '1.1rem',
          transition: 'all .3s',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          backgroundColor: darkMode ? (hover ? '#CCC' : WHITE_MAIN) : hover ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
          color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
        },
        style
      )}
    >
      {text}
    </button>
  );
};

class EditProfile extends React.Component {
  state = {
    loading: false,
    name: this.props.user.name,
    username: this.props.user.username,
    bornDate: this.props.user.bornDate,
    resume: this.props.user.resume || '',
    searchingUsername: false,
    pickerVisible: false,
    acceptTerms: false,
    isShowPasswordButtonShow: false,
    isUsernameAvailable: true,
    editingProp: false,
    savingName: false,
    savingResume: false,
    savingBornDate: false,
    showModal: false,
    pictureImage: null,
    croppedAreaPixels: null,
    croppedImage: null,
    rotation: 0,
    zoom: 1,
    crop: { x: 0, y: 0 },
    matches: window.matchMedia('(max-width: 420px)').matches,
    currentEditImage: '',
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      window.matchMedia('(max-width: 420px)').addEventListener('change', (e) => this.setState({ matches: e.matches }));
    }
    isUserValid(false, this._isMounted);
    window.addEventListener('keydown', e => this.handleKeyPress(e));
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    window.matchMedia('(max-width: 420px)').removeEventListener('change', (e) => this.setState({ matches: e.matches }));
    window.removeEventListener('keydown', e => this.handleKeyPress(e));
  };

  handleKeyPress = (e) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.props.onRequestClose();
    }
  };

  fetchUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const userId = localStorage.getItem('userId');
        const user = await getUser(userId);
        const positions = await getStat('positions');
        this.setState({
          positions,
        });
        this.props.saveUser(user);
        resolve();
      } catch (e) {
        this.showAlert(true, 'error', e.data.error ?? i18n.t('GENERAL_ERRORS.serverError'));
        reject();
      }
    });
  };

  isUsernameAvilable = () => {
    this.setState({ searchingUsername: true }, async () => {
      try {
        const { username } = this.state;
        const response = await getUsernameAvailability(username);
        this.setState({
          isUsernameAvilable: response === 'Avilable',
          searchingUsername: false,
        });
      } catch (e) {
        this.setState({ searchingUsername: false });
        const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
      }
    });
  };

  editProp = (prop, value) => {
    this.setState({ editingProp: true }, async () => {
      try {
        await editUser({ prop, value });
        await this.fetchUser();
        this.setState({
          editingProp: false,
          searchingUsername: false,
          savingName: false,
          savingResume: false,
          savingBornDate: false,
        });
      } catch (e) {
        this.setState({
          editingProp: false,
          searchingUsername: false,
          savingName: false,
          savingResume: false,
          savingBornDate: false,
        });
        const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
      }
    });
  };

  fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const fileType = file.type.split('/')[0].toLowerCase();
      const extension = file.type.split('/')[1].toLowerCase();
      if (fileType === 'image') {
        Resizer.imageFileResizer(
          file,
          730,
          500,
          extension === 'png' ? 'PNG' : extension === 'webp' ? 'WEBP' : 'JPEG',
          80,
          0,
          (file) => {
            // console.log("imageFileResizer / file: ", file);
            const reader = new FileReader();
            reader.onload = (event) => {
              const uri = event.target.result;
              const sizeInMb = getBase64Size(uri);
              // console.log('uri', uri);
              resolve({
                name: file.name,
                uri: uri,
                type: file.type,
                folder: 'posts',
                sizeInMb: sizeInMb,
              });
            };
            reader.readAsDataURL(file);
          },
          'file'
        );
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const uri = event.target.result;
          const sizeInMb = getBase64Size(uri);
          // console.log('uri', uri);
          const finalUri = `data:video/mp4;base64,${uri.split(',')[1]}`;
          resolve({
            name: file.name,
            uri: finalUri,
            type: 'video',
            folder: 'posts',
            sizeInMb: sizeInMb,
          });
        };
        reader.readAsDataURL(file);
      }
    });

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onRotationChange = (rotation) => {
    this.setState({ rotation });
  };

  handleFileInput = async (e, aspect) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readerFile(file);
      // console.log('imageDataUrl:', imageDataUrl);

      this.setState({ pictureImage: imageDataUrl }, () => this.showCropper(true, imageDataUrl, aspect));
    }
  };

  uploadFile = (aspect) => {
    const file = document.createElement('input');
    file.type = 'file';
    file.style.display = 'none';
    file.accept = 'image/png, image/jpg, image/jpeg, image/heif, image/heic, image/webp, image/gif';
    file.onchange = (e) => this.handleFileInput(e, aspect);
    file.click();
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  saveUserProfileImage = async (file, name) => {
    try {
      const userId = localStorage.getItem('userId');
      const item = await saveFile(Math.random(), file.uri, 'image', 'profiles');
      await this.editProp(name, item.uri);
      const user = await getUser(userId);
      this.props.saveUser(user);
      this.setState({ isOptionsOpen: false });
    } catch (e) {
      this.setState({
        editingProp: false,
        searchingUsername: false,
        savingName: false,
        savingResume: false,
        savingBornDate: false,
      });
      const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
      this.setState({
        isMessageModalOpen: true,
        messageModalMessage: error,
        messageModalType: 'error',
      });
    }
  };

  showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(this.state.pictureImage, this.state.croppedAreaPixels, this.state.rotation);
      this.setState(
        {
          croppedImage: croppedImage,
        },
        async () => {
          const res = await this.blobToBase64(croppedImage);
          const file = {
            uri: res,
            name: `IMG${String(Math.random()).replace('0.', '')}`,
            type: 'image/png',
            // extension: 'jpeg'
            folder: 'profiles',
            // sizeInMb: sizeInMb,
          };
          // console.log('showCroppedImage / file: ', file);
          this.setState(
            {
              isCropperOpen: false,
            },
            () => {
              this.saveUserProfileImage(file, this.state.selectedImage);
            }
          );
        }
      );
    } catch (e) {
      // console.log(e);
      this.showAlert(true, 'error', e);
    }
  };

  blobToBase64 = (blob) => {
    return new Promise(async (resolve, _) => {
      let blobUrl = await fetch(blob).then((r) => r.blob());
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blobUrl);
    });
  };

  throtled = throttle(async () => {
    this.isUsernameAvilable();
  }, 1000);

  removeImage = () => {
    const { selectedImage } = this.state;
    const userId = localStorage.getItem('userId');
    const { user } = this.props;
    const currentPicture = selectedImage === 'picture' ? user?.picture : user?.bgPicture;
    this.setState({ editModalLoading: true }, async () => {
      if (currentPicture) {
        await deleteFiles([currentPicture]);
      }

      await editUser({ prop: selectedImage, value: '' });
      const user = await getUser(userId);
      this.props.saveUser(user);
      setTimeout(() => {
        this.setState({ isOptionsOpen: false, editModalLoading: false });
      }, 500);
    });
  };

  hideProp = (isShow, name) => {
    this.setState({ [`${name.trim()}Loader`]: true }, async () => {
      await editUser({ prop: name, value: !isShow ? true : false });
      await this.fetchUser();
      this.setState({ [`${name.trim()}Loader`]: false });
    });
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  changePicture = (uri) => {
    this.showOptions(false);
    this.showCropper(true, uri);
  };

  showOptions = (isOpen) => {
    this.setState({
      isOptionsOpen: isOpen,
    });
  };

  showCropper = (isOpen, uri, aspect) => {
    this.setState({
      isCropperOpen: isOpen,
      cropperImage: uri,
      cropperAspect: aspect,
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const {
      loading,
      name,
      // bornDate,
      // username,
      // isUsernameAvailable,
      // searchingUsername,
      savingName,
      savingResume,
      // savingBornDate,
      editingProp,
      // showModal,
      // isEditPictureModalOpen,
      // savingResume,
      resume,
      showResumeLoader,
      showNameLoader,
      positions,
      // selectedImage
    } = this.state;

    const { user, darkMode, onRequestClose, isOpen, changeEmailClick, changePasswordClick } = this.props;

    // console.log("user: ", user);
    // console.log("selectedImage: ", selectedImage);

    const postUserId = user && user._id ? user._id : '';

    const position =
      positions && positions.filter((position) => position.user._id === postUserId).length > 0
        ? positions.filter((position) => position.user._id === postUserId)[0].position
        : null;

    return (
      <Translation>
        {(t) => (
          <AlertModal
            overlayClassName='EditProfile__Overlay'
            modalClassName='EditProfile__Container'
            style={{
              backgroundColor: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
            }}
            outsideClick={onRequestClose}
            isOpen={isOpen}
          >
            <h1
              className='EditProfile__Title'
              style={{
                borderBottom: `1px solid ${darkMode ? '#FFF5' : GRAY_SECOND_TRANSPARENCY_FULL}`,
                color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
              }}
            >
              {t('EDIT_PROFILE.title')}
            </h1>
            <img
              src={darkMode ? CloseIconWhite : CloseIcon}
              alt='Close'
              onClick={onRequestClose}
              className='EditProfile__CloseImg'
            />
            <div className='EditProfile__Content'>
              <div style={{ display: 'flex' }}>
                <div className='EditProfile__PictureContainer'>
                  {/* <label
              htmlFor='EditProfile__ChangePicture'
              className='EditProfile__ChangePicture'
              >
              <input type='file' hidden id='EditProfile__ChangePicture' accept='image/*'/>
            </label> */}
                  <img
                    src={AddIcon}
                    className='EditProfile__Icon'
                    alt=''
                    onClick={() => this.setState({ selectedImage: 'picture' }, () => this.showOptions(true))}
                  />
                  <UserProfile
                    uri={user?.picture}
                    position={position}
                    containerStyle={{
                      width: '100px',
                      height: '100px',
                      borderRadius: 'calc(100px / 2)',
                    }}
                  />
                  <p
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                    }}
                  >
                    {t('EDIT_PROFILE.changePicture')}
                  </p>
                </div>
                <div className='EditProfile__PictureContainer'>
                  {/* <label
              htmlFor='EditProfile__ChangePicture'
              className='EditProfile__ChangePicture'
              >
              <input type='file' hidden id='EditProfile__ChangePicture' accept='image/*'/>
            </label> */}
                  <img
                    src={AddIcon}
                    className='EditProfile__Icon'
                    alt=''
                    onClick={() => this.setState({ selectedImage: 'bgPicture' }, () => this.showOptions(true))}
                  />
                  <UserProfile
                    uri={user?.bgPicture || profileBg}
                    position={position}
                    containerStyle={{
                      width: '100px',
                      height: '100px',
                      borderRadius: 'calc(100px / 2)',
                    }}
                    imgStyle={{ objectFit: 'cover' }}
                  />
                  <p
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                    }}
                  >
                    {t('EDIT_PROFILE.changeCover')}
                  </p>
                </div>
              </div>
              <div className='EditProfile__Data'>
                <div
                  className='EditProfile__InputContainer'
                  style={{
                    width: '100%',
                    position: 'relative',
                    marginBottom: '10px',
                  }}
                >
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      marginBottom: '5px',
                    }}
                  >
                    {t('EDIT_PROFILE.changeName')}
                  </p>
                  <p
                    style={{
                      position: 'absolute',
                      right: '0',
                      top: '0',
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                    }}
                  >
                    {user.showName ? t('EDIT_PROFILE.STATUS.visible') : t('EDIT_PROFILE.STATUS.notVisible')}
                  </p>
                  <RightComponentItem
                    isWhite={darkMode}
                    name='name'
                    value={name}
                    disabled={editingProp || loading}
                    onChange={this.handleInputChange}
                    autoComplete='off'
                    rightComponent={
                      <>
                        {user.name === name && name ? (
                          showNameLoader ? (
                            <AppLoader size='30px' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
                          ) : (
                            <img
                              src={
                                user.showName
                                  ? darkMode
                                    ? UnwatchIconWhite
                                    : UnwatchIcon
                                  : darkMode
                                  ? WatchIconWhite
                                  : WatchIcon
                              }
                              style={{ width: '32px' }}
                              onClick={() => this.hideProp(user.showName, 'showName')}
                              alt=''
                            />
                          )
                        ) : null}
                        {savingName ? (
                          <AppLoader size='30px' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
                        ) : user.name !== name && name ? (
                          <img
                            src={darkMode ? SaveIconWhite : SaveIcon}
                            style={{ width: '25px' }}
                            onClick={() =>
                              this.setState(
                                {
                                  savingName: true,
                                },
                                () => {
                                  this.editProp('name', name);
                                }
                              )
                            }
                            alt=''
                          />
                        ) : null}
                      </>
                    }
                  />
                </div>
                <div className='EditProfile__InputContainer' style={{ width: '100%', position: 'relative' }}>
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      marginBottom: '5px',
                    }}
                  >
                    {t('EDIT_PROFILE.changeDescription')}
                  </p>
                  <p
                    style={{
                      position: 'absolute',
                      right: '0',
                      top: '0',
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                    }}
                  >
                    {user.showResume ? t('EDIT_PROFILE.STATUS.visible') : t('EDIT_PROFILE.STATUS.notVisible')}
                  </p>
                  <RightComponentItem
                    textArea
                    isWhite={darkMode}
                    name='resume'
                    value={resume}
                    disabled={editingProp || loading}
                    onChange={this.handleInputChange}
                    row={4}
                    autoComplete='off'
                    rightComponent={
                      <>
                        {user.resume === resume && resume ? (
                          showResumeLoader ? (
                            <AppLoader size='30px' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
                          ) : (
                            <img
                              src={
                                user.showResume
                                  ? darkMode
                                    ? UnwatchIconWhite
                                    : UnwatchIcon
                                  : darkMode
                                  ? WatchIconWhite
                                  : WatchIcon
                              }
                              style={{ width: '32px' }}
                              onClick={() => this.hideProp(user.showResume, 'showResume')}
                              alt=''
                            />
                          )
                        ) : null}
                        {savingResume ? (
                          <AppLoader size='30px' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
                        ) : user.resume !== resume && resume ? (
                          <img
                            src={darkMode ? SaveIconWhite : SaveIcon}
                            style={{ width: '25px' }}
                            onClick={() =>
                              this.setState(
                                {
                                  savingResume: true,
                                },
                                () => {
                                  this.editProp('resume', resume);
                                }
                              )
                            }
                            alt=''
                          />
                        ) : null}
                      </>
                    }
                  />
                </div>
                {/* <EditProfileButton
                  text={t('EDIT_PROFILE.BUTTONS.continue')}
                  darkMode={darkMode}
                  style={{ margin: '10px 0' }}
                  onClick={onRequestClose}
                /> */}
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                  }}
                />
                <EditProfileButton
                  text={t('EDIT_PROFILE.BUTTONS.changeEmail')}
                  darkMode={darkMode}
                  style={{ marginTop: '10px' }}
                  onClick={changeEmailClick}
                />
                <EditProfileButton
                  text={t('EDIT_PROFILE.BUTTONS.changePassword')}
                  darkMode={darkMode}
                  style={{ marginTop: '10px' }}
                  onClick={changePasswordClick}
                />
              </div>
            </div>
            <OptionModal
              isOpen={this.state.isOptionsOpen}
              onRequestClose={() => this.setState({ isOptionsOpen: false })}
              show={
                this.state.selectedImage === 'picture'
                  ? {
                      edit: {
                        display: true,
                        title: t('EDIT_PROFILE.BUTTONS.editProfilePicture'),
                        onClick: () => this.uploadFile(),
                      },
                      delete: {
                        display: user?.picture,
                        title: t('EDIT_PROFILE.BUTTONS.removeProfilePicture'),
                        onClick: () => this.removeImage(),
                      },
                    }
                  : {
                      edit: {
                        display: true,
                        title: t('EDIT_PROFILE.BUTTONS.editBgPicture'),
                        onClick: () => this.uploadFile(5 / 2),
                      },
                      delete: {
                        display: user?.bgPicture,
                        title: t('EDIT_PROFILE.BUTTONS.removeBgPicture'),
                        onClick: () => this.removeImage(),
                      },
                    }
              }
            />
            <CropModal
              mobile={this.state.matches}
              isOpen={this.state.isCropperOpen}
              uri={this.state.cropperImage}
              darkMode={darkMode}
              onRequestClose={() => this.setState({ isCropperOpen: false, imageToCropper: null })}
              aspect={this.state.cropperAspect || 1}
              action={() => this.showCroppedImage()}
              onCropComplete={this.onCropComplete}
              zoom={this.state.zoom}
              crop={this.state.crop}
              rotation={this.state.rotation}
              onZoomChange={this.onZoomChange}
              onCropChange={this.onCropChange}
              onRotationChange={this.onRotationChange}
              firstInputProps={{
                type: 'range',
                min: 1,
                max: 4,
                step: 0.1,
                value: this.state.zoom,
                name: 'zoom',
                onChange: this.handleInputChange,
              }}
              secondInputProps={{
                type: 'range',
                max: '360',
                min: 0,
                step: 1,
                value: this.state.rotation,
                name: 'rotation',
                onChange: this.handleInputChange,
              }}
            />
            <Alert
              isOpen={this.state.isAlertOpen}
              type={this.state.alertType}
              content={this.state.alertContent}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
            />
          </AlertModal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
