import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import CheckBox from '../../utils/app-inputs/checkbox/checkbox';
import { FEDERAL_BLACK_LIGHT, FEDERAL_BLUE } from '../../utils/color-palatte';
import AlertModal from '../../utils/modal/Modal';
import SendButton from '../../utils/send-button/send-button';
import CloseIcon from '../../assets/terms-modal/close-btn.svg';
import { Translation } from 'react-i18next';
import './terms-modal.css';

const TermsModal = (props) => {
  const [position, setPosition] = useState();

  const [isDisabled, setIsDisabled] = useState(true);

  const Elem = useRef(null);

  useEffect(() => {
    if (Elem && Elem.current)
    Elem.current.addEventListener('scroll', listenToScroll);
  });

  useLayoutEffect(() => {
    if (Elem && Elem.current)
    Elem.current.removeEventListener('scroll', listenToScroll);
  });
  
  const listenToScroll = () => {
    // const elem = document.getElementById('TermsModal__Scroll-area');
    if (isOpen) {
      const winScroll = Elem.current.scrollTop;
      const height = Elem.current.scrollHeight - Elem.current.clientHeight;
      const scrolled = winScroll / height;

      if (scrolled > 0.95) {
        setIsDisabled(false);
      }
      setPosition(scrolled);
      // console.log(
      //   `Calc position: ${position}
      //   winScroll: ${winScroll}
      //   height: ${height}`
      // );
    }
    return null
  };

  const {
    isOpen,
    title,
    onRequestClose,
    checkName,
    checkValue,
    checked,
    chekOnChange,
    checkDisabled,
    children,
    checkboxText,
    checkClick,
    buttonClick,
    buttonDisabled,
    renderDisabled,
    checkable,
  } = props;
  return (
    <Translation>
      {(t) => (
        <AlertModal
          overlayClassName='TermsModal__Overlay'
          modalClassName='TermsModal__Container'
          isOpen={isOpen}
        >
          <div className='TermsModal__Content'>
            <div className='TermsModal__Header'>
              <h1>{title ?? t('TERMS_MODAL.Title')}</h1>
              <img
                src={CloseIcon}
                alt=''
                className='TermsModal__Close-btn'
                onClick={onRequestClose}
              />
            </div>
            <div className='Terms__Scroll-Container'>
              <div
                className='TermsModal__Vanish TermsModal__VSH1'
                style={{ height: position > 0 ? undefined : '0' }}
              />
              <div
                className='TermsModal__Scroll-area'
                onScroll={listenToScroll}
                ref={Elem}
              >
                {children}
              </div>
              <div
                className='TermsModal__Vanish TermsModal__VSH2'
                style={{
                  height: position >= 0.95 ? '0px' : undefined,
                }}
              />
            </div>
            {checkable ? <div className='TermsModal__Footer'>
              {isDisabled ? (
                <p
                  style={{
                    width: '100% - 40px',
                    textAlign: 'center',
                    marginTop: '40px',
                  }}
                >
                  {t('TERMS_MODAL.ScrollDown')}
                </p>
              ) : (
                <>
                  <div className='TermsModal__Check-container'>
                    <CheckBox
                      name={checkName}
                      value={checkValue}
                      checked={checked}
                      onChange={chekOnChange}
                      disabled={isDisabled ? isDisabled : checkDisabled}
                      onClick={checkClick}
                      className='TermsModal__CheckBox'
                    />
                    <p
                      style={{
                        color: !isDisabled ? FEDERAL_BLUE : FEDERAL_BLACK_LIGHT,
                      }}
                    >
                      {checkboxText ?? t('TERMS_MODAL.CheckBoxText')}
                    </p>
                  </div>
                  <SendButton
                    textContent={t('TERMS_MODAL.Button')}
                    onClick={buttonClick}
                    style={{
                      backgroundColor:
                        buttonDisabled || isDisabled ? '#E6E4E4' : FEDERAL_BLUE,
                      color:
                        buttonDisabled || isDisabled
                          ? FEDERAL_BLACK_LIGHT
                          : 'white',
                      cursor:
                        buttonDisabled || isDisabled ? undefined : 'pointer',
                    }}
                    renderDisabled={
                      !checked && (buttonDisabled || isDisabled)
                        ? t('TERMS_MODAL.Button')
                        : renderDisabled
                    }
                    disabled={isDisabled ? isDisabled : buttonDisabled}
                    className='TermsModal__Button'
                  />
                </>
              )}
            </div> : <div style={{ width: '100%', height: '40px'}} />}
          </div>
        </AlertModal>
      )}
    </Translation>
  );
};

export default TermsModal;
