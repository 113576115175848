import i18n from "../i18n/i18n"

const handlePaymentTypeDescription = (type) => {
  if (type === "subscription") {
    return i18n.t('PAYMENTS_LIST.subscriptionDescription')
  }
  if (type === "capital-contribution") {
    return i18n.t('PAYMENTS_LIST.capitalContributionDescription')
  }
  return ""
}

export default handlePaymentTypeDescription;