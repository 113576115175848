import { getData } from "../fetch";

const getUser = (userId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await getData(`/user/get-user-web/${userId}`);
            const user = response.body[0];
            // console.log("getUser / user: ", user)
            return resolve(user);
        } catch (e) {
            return reject(e);
        }
    })
};

export default getUser;