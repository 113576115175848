import React from 'react';
import './sign-up.css';
import { WARNING_YELLOW, WHITE_MAIN } from '../../utils/color-palatte';
import RegularInput from '../../utils/app-inputs/regular-input/regular-input';
import { Trans, Translation } from 'react-i18next';
// import DateInput from '../../utils/app-inputs/date-input/date-input';
// import SelectInput from '../../utils/app-inputs/select-input/select-input';
import PasswordInput from '../../utils/app-inputs/password-input/password-input';
import TermsModal from '../../components/terms-modal/terms-modal';
import {
  TermsEs,
  TermsEn,
} from '../../components/terms-modal/terms-content/terms-content';
import { signUp } from './signup-actions';
import { withRouter } from 'react-router-dom';
import i18n from '../../i18n/i18n';
// import validateBornDate from '../../utils/validateBornDate';
import { emailsRegex, usernameRegex } from '../../utils/regex';
import Alert from '../../utils/alert/Alert';
import WarningIcon from '../../assets/general/warning-icon.svg';
import CheckBox from '../../utils/app-inputs/checkbox/checkbox';
import SendButton from '../../utils/send-button/send-button';
import { AppLoader } from '../../utils/animations/animations';
import ReCAPTCHA from 'react-google-recaptcha';
import SafeLINK from '../../utils/safe-link';
import { LOGIN_ROUTE } from '../../routes/routes';
import { RECAPTCHA_SITE_KEY } from '../../config';
import bgMobile from "../../assets/log-in/login-bg.png";
import { login } from '../log-in/login-actions';
import { store } from '../../redux/configureStore';
import * as types from '../../redux/types/user/types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions'

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      fullname: '',
      username: '',
      email: '',
      password: '',
      repeatPassword: '',
      picture: '',
      // bornDate: new Date(),
      acceptTerms: false,
      legalAge: false,
      imgLoaded: true,
      captchaSuccess: false,
      matches: window.matchMedia("(max-width: 750px)").matches
    };
  }

  // componentDidMount = () => {
  //   validateAppVersion()
  // } 

  // stopLoader = () => this.setState({ loading: false });

  resetState = () =>
    this.setState({
      fullname: '',
      email: '',
      password: '',
      repeatPassword: '',
      username: '',
      // bornDate: new Date(),
      acceptTerms: false,
    });
    

  _isMounted = false

  componentDidMount = () => {
    this._isMounted = true;
    if (this._isMounted) {
      this.setState({ imgLoaded: false });
      const handler = e => this.setState({ matches: e.matches });
      window.matchMedia("(max-width: 750px)").addEventListener('change', handler);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    window.matchMedia("(max-width: 750px)").removeEventListener('change', this.handler);
  };

  signUpFuction = async (event) => {
    if (event) {
      event.preventDefault();
    }

    const {
      fullname,
      email,
      password,
      repeatPassword,
      legalAge,
      // bornDate,
      acceptTerms,
      username,
      // picture,
    } = this.state;

    const {
      darkMode,
      toggleDarkMode
    } = this.props
    // Keyboard.dismiss()
    let data = {
      username: username.trim(),
      name: fullname.trim(),
      email: email.trim(),
      password: password.trim(),
      // bornDate,
    };
    if (
      fullname &&
      email &&
      password &&
      password.length >= 6 &&
      repeatPassword &&
      repeatPassword === password &&
      legalAge &&
      // bornDate &&
      // validateBornDate(bornDate) &&
      email.match(emailsRegex) &&
      acceptTerms &&
      username &&
      username.match(usernameRegex) &&
      username.length <= 12 &&
      this.state.captchaSuccess
    ) {
      this.setState({ loading: true }, async () => {
        try {
          /* if (picture) {
            const item = await saveFile(
              Math.random(),
              picture,
              'image',
              'profiles'
            );
            data.picture = item.uri;
          } */

          const response = await signUp(data);
          // console.log('signUp() response: ', response);
          const createdUser = response.body.product;

          if (response.status === 200) {
            // this.props.navigation.navigate(SIGNUP_SUCCESS_ROUTE, {
            const res = await login({email: data.email, password: data.password});
            this.onLoginSuccess(res);
            if (!darkMode || darkMode === false) {
              toggleDarkMode();
            }
            this.props.history.push({
              pathname: '/welcome',
              state: {
                user: {
                  name: createdUser.name,
                  // bornDate: createdUser.bornDate,
                  block: false,
                  createDate: createdUser.createDate,
                  number: createdUser.number,
                  picture: createdUser.picture,
                  active: true,
                  username: createdUser.username,
                  position: createdUser.position,
                },
                message: response.body.message,
              },
            });

            // this.stopLoader();
            this.setState({ loading: false });
            this.resetState();
          }
          this.showModal(false);
        } catch (e) {
          console.log('signUpFuction error: ', e);
          const error = e && e.data && e.data.error ? e.data.error : null;
          const errorMessage =
            error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : error && error.message
                ? error.message
                : i18n.t('SIGN_UP.ERRORS.serverError');
          // this.toggleMessageModal('error', true, errorMessage);
          this.setState({
            loading: false,
            isModalOpen: true,
            modalType: 'wrong',
            modalContent: errorMessage,
            modalChoose: false,
            btnAction: () => this.setState({ isModalOpen: false }),
          });
        }
      });
    } else {
      // this.stopLoader();
      if (username && !username.match(usernameRegex)) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.usernameInfo'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      if (username && username.length > 12) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.usernameTooLong'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      if (!username) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.invalidUsername'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      if (username && username.length > 20) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.invalidUsernameLength'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      if (!fullname) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.invalidName'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      if (fullname && fullname.length > 20) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.invalidNameLength'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      if (!email) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.missingEmail'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      if (email && !email.match(emailsRegex)) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.invalidEmail'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      if (!password) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.missingPassword'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      if (password && password.length < 6) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.invalidPassword'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
      // if (!repeatPassword) {
      //   return this.setState({
      //     loading: false,
      //     isModalOpen: true,
      //     modalType: 'wrong',
      //     modalContent: i18n.t('SIGN_UP.ERRORS.confirmPassword'),
      //     modalChoose: false,
      //     btnAction: () => this.setState({ isModalOpen: false }),
      //   });
      // }
      if (!repeatPassword || repeatPassword !== password) {
        return this.setState(
          {
            loading: false,
            isModalOpen: true,
            modalType: 'wrong',
            modalContent: i18n.t('SIGN_UP.ERRORS.verifyPassword'),
            modalChoose: false,
            btnAction: () => this.setState({ isModalOpen: false }),
          }
          // console.log('false')
        );
      }
      if (!legalAge) {
        return this.setState(
          {
            loading: false,
            isModalOpen: true,
            modalType: 'wrong',
            modalContent: i18n.t('SIGN_UP.ERRORS.invalidBornDate'),
            modalChoose: false,
            btnAction: () => this.setState({ isModalOpen: false }),
          }
          // console.log('false')
        );
      }
      // if (!bornDate) {
      //   return this.setState({
      //     loading: false,
      //     isModalOpen: true,
      //     modalType: 'wrong',
      //     modalContent: i18n.t('SIGN_UP.ERRORS.missingBornDate'),
      //     modalChoose: false,
      //     btnAction: () => this.setState({ isModalOpen: false }),
      //   });
      // }
      // if (!validateBornDate(bornDate)) {
      //   return this.setState({
      //     loading: false,
      //     isModalOpen: true,
      //     modalType: 'wrong',
      //     modalContent: i18n.t('SIGN_UP.ERRORS.invalidBornDate'),
      //     modalChoose: false,
      //     btnAction: () => this.setState({ isModalOpen: false }),
      //   });
      // }
      if (
        !acceptTerms &&
        fullname &&
        email &&
        password &&
        repeatPassword &&
        // bornDate &&
        username &&
        legalAge
      ) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.acceptTerms'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }

      if (!this.state.captchaSuccess) {
        return this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: i18n.t('SIGN_UP.ERRORS.pleaseFinishCaptcha'),
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }

      this.setState({
        loading: false,
        isModalOpen: true,
        modalType: 'wrong',
        modalContent: i18n.t('SIGN_UP.ERRORS.missingFields'),
        modalChoose: false,
        btnAction: () => this.setState({ isModalOpen: false }),
      });
    }
  };

  onLoginSuccess = (data) => {
    store.dispatch({ type: types.SAVE_USER_HAS_ENTERED, data: true });
    localStorage.setItem('token', data.body.token);
    localStorage.setItem('userId', data.body.user._id);
    this.props.saveUser(data.body.user);
    // this.stopLoader();
    // this.resetState();
    this.setState({ loading: false });
  }

  showModal = (
    isOpen,
    type,
    title,
    content,
    progress,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose
  ) => {
    const that = this;
    that.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title ?? i18n.t('SIGN_UP.greetings'),
      modalContent: content,
      loading: progress,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
    });
  };

  showTermsModal = (isOpen) => {
    this.setState({
      isTermsOpen: isOpen,
    });
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  render() {
    const {
      loading,
      acceptTerms,
      username,
      fullname,
      email,
      // bornDate,
      password,
      repeatPassword,
      matches,
      legalAge
    } = this.state;

    const that = this;

    const language =
    localStorage.getItem('language')?.toLowerCase() === 'us'
        ? 'en'
        : localStorage.getItem('language')?.toLowerCase() || 'es';

    return (
      <Translation>
        {(t) => (
          <div
            className='SignUp'
            style={that.state.imgLoaded ? { display: 'none' } : undefined}
          >
            {matches && <img 
            alt=""
              src={bgMobile}
              className="bgMobile"
            />}
            <form
              // autoComplete='off'
              className='SignUp__Container'
              onSubmit={(event) => that.signUpFuction(event)}
            >
              <h1 className='SignUp__H1'>{i18n.t('SIGN_UP.title')}</h1>
              <RegularInput
                isWhite
                type='text'
                placeholder={t('SIGN_UP.username')}
                name='username'
                value={this.state.username}
                onChange={that.handleInputChange}
                disabled={loading}
                maxLength='12'
              />
              <div className='SignUp__Info-container'>
                {username &&
                  (!usernameRegex.test(username) ||
                    username.length < 5 ||
                    username.length > 12) ? (
                  <>
                    <img
                      src={WarningIcon}
                      className='SignUp__Warning'
                      alt=''
                      onLoad={() => that.setState({ imgLoaded: false })}
                    />
                    <p className='SignUp__Info'>{t('SIGN_UP.usernameInfo')}</p>
                  </>
                ) : (
                  <p className='SignUp__Info'>
                    {t('SIGN_UP.usernameIsPermanent')}
                  </p>
                )}
              </div>
              <RegularInput
                isWhite
                type='text'
                placeholder={t('SIGN_UP.name')}
                name='fullname'
                value={fullname}
                onChange={that.handleInputChange}
                disabled={loading}
                maxLength='20'
              />
              {/* <RegularInput
                type='text'
                placeholder={t('SIGN_UP.phoneNumber')}
              /> */}
              <RegularInput
                isWhite
                type='email'
                placeholder='E-mail'
                name='email'
                value={email}
                onChange={that.handleInputChange}
                disabled={loading}
              />
              {/* <DateInput
                isWhite
                name='bornDate'
                value={bornDate}
                onChange={that.handleInputChange}
                placeholder='BornDate'
                disabled={loading}
                title={t('SIGN_UP.bornDate')}
              /> */}
              {/* <SelectInput placeholder={t('SIGN_UP.country')}>
                <option value='' disabled selected>
                  {t('SIGN_UP.country')}
                </option>
                <option value='US'>USA</option>
                <option value='GE'>Germany</option>
                <option value='IT'>Italy</option>
                <option value='UK'>United Kingdom</option>
                <option value='VY'>Venezuela</option>
              </SelectInput> */}
              <div
                style={{
                  width: '100%',
                  height: '0',
                  borderTop: `1px solid ${WHITE_MAIN}`,
                  margin: '15px 0',
                }}
              />
              <PasswordInput
                isWhite
                placeholder={t('SIGN_UP.password')}
                name='password'
                value={password}
                onChange={that.handleInputChange}
                disabled={loading}
              />
              <PasswordInput
                isWhite
                placeholder={t('SIGN_UP.repeatPassword')}
                name='repeatPassword'
                value={repeatPassword}
                onChange={this.handleInputChange}
                disabled={loading}
              />
              <div className='SignUp__Terms-Area'>
                <CheckBox
                  isWhite
                  onClick={() => this.setState({ legalAge: !legalAge })}
                  checked={legalAge}
                  disabled={loading}
                />
                <p style={{ color: WHITE_MAIN}}>
                  {t('SIGN_UP.areYouLegalAge')}
                </p>
              </div>
              <div className='SignUp__Terms-Area'>
                <CheckBox
                  isWhite
                  onClick={() =>
                    !acceptTerms
                      ? this.setState({ isTermsOpen: true })
                      : this.setState({ acceptTerms: false })
                  }
                  checked={acceptTerms}
                  disabled={loading}
                />
                <p style={{ color: WHITE_MAIN}}>
                  <Trans i18nKey='SIGN_UP.termsCheck'>
                    bla
                    <span
                      onClick={() => that.setState({ isTermsOpen: true })}
                      style={
                        loading
                          ? { opacity: '.5', pointerEvents: 'none', color: WARNING_YELLOW }
                          : { color: WARNING_YELLOW }
                      }
                    >
                      bla
                    </span>
                  </Trans>
                </p>
              </div>
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={(value) => {
                  // console.log('Captcha value:', value);
                  if (value) {
                    this.setState({ captchaSuccess: true });
                  } else {
                    this.setState({ captchaSuccess: false });
                  }
                }}
              />
              <SendButton
                className='Create__button'
                textContent={t('SIGN_UP.send')}
                disabled={loading}
                renderDisabled={<AppLoader size='40px' color={WHITE_MAIN} />}
              />
              <div
                style={{
                  borderBottom: `1px solid ${WHITE_MAIN}`,
                  width: '100%',
                  height: '1px',
                  marginTop: '15px',
                }}
              />
              <p
                style={{
                  color: WHITE_MAIN,
                  fontSize: '1.1rem',
                  margin: '10px',
                }}
              >
                <Trans i18nKey='SIGN_UP.loginTxt'>
                  bla
                  <SafeLINK
                    style={{ color: WARNING_YELLOW }}
                    dangerousLINK={LOGIN_ROUTE}
                  >
                    bla
                  </SafeLINK>
                  bla
                </Trans>
              </p>
            </form>
            <TermsModal
              isOpen={that.state.isTermsOpen}
              checkable
              onRequestClose={() =>
                this.setState({
                  isTermsOpen: false,
                })
              }
              checkClick={() =>
                that.setState({
                  acceptTerms: !acceptTerms,
                })
              }
              buttonDisabled={!acceptTerms}
              buttonClick={() =>
                that.setState({
                  isTermsOpen: false,
                })
              }
            >
              {language === 'es' ? <TermsEs /> : <TermsEn />}
            </TermsModal>
            <Alert
              isOpen={that.state.isModalOpen}
              type={that.state.modalType}
              title={that.state.modalTitle}
              content={that.state.modalContent}
              progress={that.state.loading}
              choose={that.state.modalChoose}
              NOClick={that.state.noAction}
              noButtonText={that.state.noText}
              YESClick={that.state.yesAction}
              yesButtonText={that.state.yesText}
              singleButtonText={that.state.btnTxt}
              onRequestClose={that.state.btnAction}
            />
          </div>
        )}
      </Translation>
    );
  }
}

const SignUpWithRouter = withRouter(SignUp);

const mapDispatch = dispatch => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    toggleDarkMode: bindActionCreators(actions.toggleDarkMode, dispatch)
  }
}

const mapState = state => {
  const { darkMode } = state.user;
  return {
    darkMode
  }
}
export default connect(mapState, mapDispatch)(SignUpWithRouter);
