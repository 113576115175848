import React, { useState, useEffect } from 'react';
import {
  CURRENT_SITE, // NOTICE_OF_PRIVACY_URL,
  COMPANY_NAME,
} from '../../../config';
import { NOTICE_OF_PRIVACY_ROUTE } from '../../../routes/routes';
import { FEDERAL_BLACK_LIGHT } from '../../../utils/color-palatte';
import { BLink } from '../../../utils/text-utils/text-utils';
import { romanize as rom } from '../../../utils/romanizer';

const Tabulation = () => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

const BTitle = ({ children }) => <b style={{ textTransform: 'uppercase' }}>{children}</b>;

export const TermsEn = ({ style, textStyle }) => (
  <div
    className='TermsEN__Container'
    style={Object.assign(
      {
        color: FEDERAL_BLACK_LIGHT,
        boxSizing: 'border-box',
        padding: '0 5px',
      },
      style
    )}
  >
    {/* <h1>TERMS AND CONDITIONS</h1> */}
    <p style={textStyle}>
      This contract describes the terms and conditions applicable to the use of the content, products and/or services of the
      mobile or web application, <b>Global Government</b>, which is owned by the entity <b>{COMPANY_NAME}</b> (company
      incorporated in the <b>United States</b>). To make use of the content, products and/or services of the mobile or web
      application, the user must abide by these terms and conditions.
      <br />
      <br />
      <Tabulation />
      <BTitle>{`${rom(1)}. OBJECT`}</BTitle>
      <br />
      <br />
      The purpose is to regulate the access and use of the content, products and/or services available to the general public in
      the <b>Global Government</b> application.
      <br />
      <br />
      <b>Global G LLC</b> reserves the right to make any type of modification and in the conditions of access and use in the
      application, at any time and without prior notice, the user accepts said modifications.
      <br />
      <br /> Access to the application by the user is free. Likewise, access to the content and services of the mobile or web
      application will be subject to prior subscription or user registration.
      <br />
      <br /> The owner reserves the right and the power to modify the mobile or web application at any time and without prior
      notice, that implies the content, products and services, or some type of configuration contained in it. Likewise, the user
      acknowledges and accepts that <b>Global G LLC</b> deactivates, cancels or interrupts any of the elements or accesses that
      make up the mobile or web application, in addition to not being obliged to pay any type of compensation. <br />
      <br /> The mobile or web application is directed exclusively to people of legal age (over 18 years of age) and is not
      responsible for the content displayed susceptible to these or the breach of this requirement. <br />
      <br /> The web or mobile application is aimed at users of any person from any country and complies with the legislation
      established in the United States of America, if the user resides in another country and decides to access and subscribe to
      the web or mobile application, he will do so under your responsibility. <br />
      <br /> The administration of the mobile or web application can be exercised by third parties, that is, people other than the
      owner, without affecting these terms and conditions.
      <br />
      <br />
      <Tabulation />
      <BTitle>{`${rom(2)}. USER`}</BTitle>
      <br />
      <br />
      The user's activity in the mobile or web application such as publications or comments, will be subject to these terms and
      conditions. The user agrees to use the content, products and/or services lawfully, without breaching morality or public
      order, refraining from performing any act that affects the rights of third parties or the operation of the mobile or web
      application. Likewise, you agree to comply with the laws of your respective country, you also agree not to use automated
      software for downloading information, publishing information or collecting information.
      <br />
      <br />
      <b>Global G LLC</b> reserves the right to remove, delete and cancel any multimedia content or comment provided by users that
      violates in any way the Law, the rights of third parties, promotes discrimination, alters public order, or, for its judgment
      not suitable for publication.
      <br />
      <br />
      Global G LLC is not responsible for comments or multimedia content provided by users or the opinions of such content.
      <br />
      <br />
      The user agrees to provide true information in the forms of the mobile or web application.
      <br />
      <br />
      Failure to comply with this rule will result in blocking, suspension or deletion of your account in the mobile or web
      application, in addition <b>Global G LLC</b> is not responsible for the loss or deletion of information, multimedia files
      provided by the user to the mobile or web application.
      <br />
      <br />
      Access to the mobile or web application does not imply a relationship between the user and the owner of the mobile or web
      application.
      <br />
      <br />
      The user declares to have the legal capacity to abide by these terms and conditions.
      <br />
      <br />
      <Tabulation />
      <BTitle>{`${rom(3)}. ACCESS AND NAVIGATION IN THE MOBILE OR WEB APPLICATION`}</BTitle>
      <br />
      <br />
      The owner does not guarantee the continuity and availability of the content, products and/or services in the mobile or web
      application, it will carry out actions that promote the proper functioning of the mobile or web application without any
      responsibility.
      <br />
      <br />
      The owner is not responsible for the software being free of errors that may cause damage to the software and/or hardware of
      the equipment from which the user accesses the mobile or web application. Similarly, he is not responsible for damages
      caused by access and / or use of mobile or web application.
      <br />
      <br />
      <Tabulation />
      <BTitle>{`${rom(4)}. PRIVACY POLICY AND DATA PROTECTION`}</BTitle>
      <br />
      <br />
      In accordance with the provisions of the Legislation of the <b>United States of America</b>, in the <b>State of Texas</b>,
      the owner undertakes to take the necessary measures that are within his reach to guarantee the security of the user,
      preventing the improper use of the data. information that the user provides in the mobile or web application.
      <br />
      <br />
      The owner will verify that the personal data contained in the databases are correct, true and current, as well as that they
      are used only for the purpose for which they were collected.
      <br />
      <br />
      The use of personal data will be limited to the provisions of the Privacy Policy available at:{' '}
      <BLink route={NOTICE_OF_PRIVACY_ROUTE} hoverable>
        {`${window.location.origin}${NOTICE_OF_PRIVACY_ROUTE}`}
      </BLink>
      .
      <br />
      <br />
      <b>Global G LLC</b> reserves the right to make any type of modification to the Privacy Policy at any time and without prior
      notice, in accordance with its needs or changes in the applicable legislation, the user accepts said modifications.
      <br />
      <br />
      <Tabulation />
      <BTitle>{`${rom(5)}. LINKS POLICY`}</BTitle>
      <br />
      <br />
      The mobile or web application may contain links or hyperlinks to Internet sites belonging to third parties for which{' '}
      <b>Global G LLC</b>
      is not responsible. Likewise, it is not responsible for the data provided by users to websites other than{' '}
      <b>Global Government</b>. Each non-Global Government website or mobile or web application will have its own personal data
      processing. Likewise, it is not responsible for damages caused by third-party links or another website or application of
      mobile devices.
      <br />
      <br />
      The veracity, availability, legality or handling of personal data from other internet sites, mobile applications or web
      pages that can be accessed from the <b>Global Government</b> mobile or web application is not guaranteed.
      <br />
      <br />
      <Tabulation />
      <BTitle>{`${rom(6)}. INTELLECTUAL AND INDUSTRIAL PROPERTY POLICY`}</BTitle>
      <br />
      <br />
      The owner declares to have the intellectual and industrial property rights of the mobile application including images, audio
      or video files, logos, trademarks, colors, structures, fonts, designs and other elements that distinguish it, protected by
      US and international legislation in matter of intellectual and industrial property.
      <br />
      <br />
      The user undertakes to respect the industrial and intellectual property rights of the owner, being able to view the elements
      of the mobile or web application, store them, copy them and print them exclusively for personal use.
      <br />
      <br />
      <Tabulation />
      <BTitle>{`${rom(7)}. APPLICABLE LEGISLATION AND JURISDICTION`}</BTitle>
      <br />
      <br />
      The relationship between the user and the owner will be governed by the applicable laws in the{' '}
      <b>United States of America</b>.
      <br />
      <br />
      <b>Global G LLC</b> is not responsible for the improper use of the content, products and/or services of the mobile or web
      application and for the breach of these terms and conditions.
      <br />
      <br />
      <Tabulation />
      <BTitle>{`${rom(8)}. COOKIES`}</BTitle>
      <br />
      <br />A cookie is a data packet that a web browser automatically stores on a user's computer when the user visits a web
      page. The cookie is sent from the server to the web page visitor. Subsequently, each time the user visits the same web page
      or any other from the same domain, the cookie will be read by the web browser, without being modified, and returned to the
      web server. <b>{CURRENT_SITE}</b> does not use cookies, however, any link to third parties outside the website could use
      them. The owner entity is not responsible for the use of cookies by affiliated pages, services used on the website or third
      parties.
    </p>
  </div>
);

export const TermsEs = ({ style, textStyle }) => {
  const [firstMatches, setFirstMatches] = useState(window.matchMedia('(max-width: 640px)').matches);

  const [secondMatches, setSecondMatches] = useState(window.matchMedia('(max-width: 480px)').matches);

  useEffect(() => {
    window.matchMedia('(max-width: 640px)').addEventListener('change', (e) => setFirstMatches(e.matches));
    window.matchMedia('(max-width: 480px)').addEventListener('change', (e) => setSecondMatches(e.matches));
  });

  return (
    <div
      className='TermsES__Container'
      style={Object.assign(
        {
          color: FEDERAL_BLACK_LIGHT,
          boxSizing: 'border-box',
          padding: '0 5px',
          fontSize: firstMatches ? (secondMatches ? '.8em' : '.9em') : '1em',
        },
        style
      )}
    >
      {/* <h1>TÉRMINOS Y CONDICIONES</h1> */}
      <p style={textStyle}>
        El presente contrato describe los términos y condiciones aplicables al uso del contenido, productos y/o servicios de la
        aplicación móvil o web, <b>Global Government</b> del cual es titular la entidad <b>{COMPANY_NAME}</b> (empresa conformada
        en los Estados Unidos). Para hacer uso del contenido, productos y/o servicios de la aplicación móvil o web el usuario
        deberá sujetarse a los presentes términos y condiciones.
        <br />
        <br />
        <Tabulation />
        <BTitle>{`${rom(1)}. OBJETO`}</BTitle>
        <br />
        <br />
        El objeto es regular el acceso y utilización del contenido, productos y/o servicios a disposición del público en general
        en la aplicación <b>Global Government</b>.
        <br />
        <br />
        <b>Global G LLC</b> se reserva el derecho de realizar cualquier tipo de modificación y en las condiciones de acceso y
        utilización en la aplicación, en cualquier momento y sin previo aviso, el usuario acepta dichas modificaciones.
        <br />
        <br />
        El acceso a la aplicación por parte del usuario es libre y gratuito. Así mismo el acceso al contenido y servicios de la
        aplicación móvil o web estará sujeta previa suscripción o registro del usuario.
        <br />
        <br />
        El titular se reserva el derecho y la facultad de modificar la aplicación móvil o web en cualquier momento y sin previo
        aviso, eso implica el contenido, productos y servicios, o algún tipo de configuración contenido en esta. Así mismo el
        usuario reconoce y acepta que <b>Global G LLC</b> desactive, cancele o interrumpa cualquiera de los elementos o accesos
        que conforman la aplicación móvil o web, además de no estar obligado a pagar ningún tipo de indemnización.
        <br />
        <br />
        La aplicación móvil o web está dirigida exclusivamente a personas mayores de edad (mayores de 18 años) y no se hace
        responsable por el contenido mostrado susceptible a estos o el incumplimiento de este requisito.
        <br />
        <br />
        La aplicación web o movil está dirigida a usuarios a cualquier persona de cualquier país y cumple con la legislación
        establecida en Los Estados Unidos de América, si el usuario reside en otro país y decide acceder y suscribirte a la
        aplicación web o movil, lo hará bajo su responsabilidad.
        <br />
        <br />
        La administración de la aplicación móvil o web puede ejercerse por terceros, es decir, personas distintas al titular, sin
        afectar esto los presentes términos y condiciones.
        <br />
        <br />
        <Tabulation />
        <BTitle>{`${rom(2)}. USUARIO`}</BTitle>
        <br />
        <br />
        La actividad del usuario en la aplicación móvil o web como publicaciones o comentarios, estarán sujetos a los presentes
        términos y condiciones. El usuario se compromete a utilizar el contenido, productos y/o servicios de forma lícita, sin
        faltar a la moral o al orden público, absteniéndose de realizar cualquier acto que afecte los derechos de terceros o el
        funcionamiento de la aplicación móvil o web. Igualmente se compromete a cumplir con las leyes de su respectivo país,
        también se compromete a no utilizar softwares automatizados para la descarga de información, publicación de información o
        recaudo de información.
        <br />
        <br />
        <b>Global G LLC</b> se reserva el derecho a eliminar, suprimir y cancelar cualquier contenido multimedia o comentario
        suministrados por los usuarios que vulneren de alguna manera la Ley, los derechos de terceros, fomente la discriminación,
        alteren el orden público, o bien, para su juicio no sea adecuada para su publicación.
        <br />
        <br />
        <b>Global G LLC</b> no se hace responsable por comentarios o contenidos multimedia suministrados por los usuarios o las
        opiniones de dicho contenido.
        <br />
        <br />
        El usuario se compromete a proporcionar información verídica en los formularios de la aplicación móvil o web. El
        incumplimiento de esta norma supondrá, bloqueo, suspensión o eliminación de su cuenta en la aplicación móvil o web, además{' '}
        <b>Global G LLC</b> no se hace responsable por la pérdida o eliminación de la información, archivos multimedia
        suministrados por el usuario a la aplicación móvil o web.
        <br />
        <br />
        El acceso a la aplicación móvil o web no supone una relación entre el usuario y el titular de la aplicación móvil o web.
        <br />
        <br />
        El usuario manifiesta contar con la capacidad jurídica de acatar los presentes términos y condiciones.
        <br />
        <br />
        <Tabulation />
        <BTitle>{`${rom(3)}. ACCESO Y NAVEGACIÓN EN LA APLICACIÓN MÓVIL O WEB`}</BTitle>
        <br />
        <br />
        El titular no garantiza la continuidad y disponibilidad del contenido, productos y/o servicios en la aplicación móvil o
        web, realizará acciones que fomenten el buen funcionamiento de la aplicación móvil o web sin responsabilidad alguna.
        <br />
        <br />
        El titular no se responsabiliza de que el software esté libre de errores que puedan causar un daño al software y/o
        hardware del equipo del cual el usuario accede a la aplicación móvil o web. De igual forma, no se responsabiliza por los
        daños causados por el acceso y/o utilización de aplicación móvil o web.
        <br />
        <br />
        <Tabulation />
        <BTitle>{`${rom(4)}. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS`}</BTitle>
        <br />
        <br />
        Conforme a lo establecido en la Legislación de <b>Los Estados Unidos De América</b>, en el <b>Estado De Texas</b>, el
        titular se compromete a tomar las medidas necesarias que estén a su alcance para garantizar la seguridad del usuario,
        evitando que se haga uso indebido de los datos personales que el usuario proporcione en la aplicación móvil o web.
        <br />
        <br />
        El titular corroborará que los datos personales contenidos en las bases de datos sean correctos, verídicos y actuales, así
        como que se utilicen únicamente con el fin con el que fueron recabados.
        <br />
        <br />
        El uso de datos personales se limitará a lo previsto en las Políticas de Privacidad disponible en:{' '}
        <BLink route={NOTICE_OF_PRIVACY_ROUTE} hoverable>
          {/* {NOTICE_OF_PRIVACY_URL} */}
          {`${window.location.origin}${NOTICE_OF_PRIVACY_ROUTE}`}
        </BLink>
        .
        <br />
        <br />
        <b>Global G LLC</b> se reserva el derecho de realizar cualquier tipo de modificación en las Políticas de Privacidad en
        cualquier momento y sin previo aviso, de acuerdo con sus necesidades o cambios en la legislación aplicable, el usuario
        acepta dichas modificaciones.
        <br />
        <br />
        <Tabulation />
        <BTitle>{`${rom(5)}. POLÍTICA DE ENLACES`}</BTitle>
        <br />
        <br />
        La aplicación móvil o web puede contener enlaces o hipervínculos a sitios de internet pertenecientes a terceros de los
        cuales <b>Global G LLC</b> no se hace responsable. Así mismo no se hace responsable por los datos facilitados por los
        usuarios a sitios web ajenos a <b>Global Government</b>. Cada sitio web o aplicación móvil o web ajenos a{' '}
        <b>Global Government</b> tendrá su propio tratamiento de datos personales. Así mismo no se hace responsable de daños y
        perjuicios ocasionados por enlaces de terceros u otro sitio web o aplicación de dispositivos móviles.
        <br />
        <br />
        No se garantiza la veracidad, disponibilidad, legalidad o manejo de datos personales de otros sitios de internet,
        aplicaciones móviles o páginas web que se puedan acceder desde la aplicación móvil o web de <b>Global Government</b>.
        <br />
        <br />
        <Tabulation />
        <BTitle>{`${rom(6)}. POLÍTICA DE PROPIEDAD INTELECTUAL E INDUSTRIAL`}</BTitle>
        <br />
        <br />
        El titular manifiesta tener los derechos de propiedad intelectual e industrial de la aplicación móvil incluyendo imágenes,
        archivos de audio o video, logotipos, marcas, colores, estructuras, tipografías, diseños y demás elementos que lo
        distinguen, protegidos por la legislación estadounidense e internacional en materia de propiedad intelectual e industrial.
        <br />
        <br />
        El usuario se compromete a respetar los derechos de propiedad industrial e intelectual del titular pudiendo visualizar los
        elementos de la aplicación móvil o web, almacenarlos, copiarlos e imprimirlos exclusivamente para uso personal.
        <br />
        <br />
        <Tabulation />
        <BTitle>{`${rom(7)}. LEGISLACIÓN Y JURISDICCIÓN APLICABLE`}</BTitle>
        <br />
        <br />
        La relación entre el usuario y el titular se regirá por las legislaciones aplicables en{' '}
        <b>Los Estados Unidos De América</b>.
        <br />
        <br />
        <b>Global G LLC</b> no se responsabiliza por la indebida utilización del contenido, productos y/o servicios de la
        aplicación móvil o web y del incumplimiento de los presentes términos y condiciones.
        <br />
        <br />
        <Tabulation />
        <BTitle>{`${rom(8)}. COOKIES`}</BTitle>
        <br />
        <br />
        Una cookie es un paquete de datos que un navegador web almacena de forma automática en el ordenador de un usuario cuando
        este visita una página web. La cookie es enviada desde el servidor al visitante de la página web. Posteriormente, cada vez
        que el usuario visite esa misma página web o alguna otra del mismo dominio, la cookie será leída por el navegador web, sin
        ser modificada, y devuelta al servidor web. <b>{CURRENT_SITE}</b> no utiliza cookies, no obstante, cualquier link a
        terceros ajenos al sitio web podría utilizarlos. La entidad titular no se hace responsable por el uso de cookies por parte
        de páginas afiliadas, servicios utilizados en el sitio web o terceros.
      </p>
    </div>
  );
};

export const PrivacyEn = ({ style, textStyle }) => (
  <div className='PrivacyEn__Container' style={style}>
    <p style={textStyle}>
      <b>Global Government</b> with address at (24929 Katy Ranch Rd TX 7749), in accordance with the provisions of the{' '}
      <b>Legislation of the United States of America</b>, specifically of the <b>State of Texas</b>, makes available to our
      customers, suppliers, employees and the general public, our Privacy Policies.
      <br />
      <br />
      The personal data you provide us is used strictly in the performance of our company's own functions, however, they are
      handled by third parties as a paid and secure information management service. Any misuse of information stored by these
      third parties is subject to their own privacy policies and <b>Global Government</b> is not responsible for the handling of
      personal information by these third parties.
      <br />
      <br />
      We request the following personal information from our <b>clients</b>:
      <br />
      <br />
      <ol type='I'>
        <li>Name and email.</li>
        <li>
          Financial data such as debit or credit card number, expiration date and security code to make the corresponding contract
          and/or purchase payments, these financial data will not be stored in our database and the user will have to enter the
          data each time a payment is to be made.
        </li>
      </ol>
      <br />
      <br />
      We request the following personal data from our <b>suppliers</b>:
      <ol type='I'>
        <li>Name, telephone, email and address to facilitate the process of contracting services and/or purchasing products.</li>
      </ol>
      We request the following personal data from our <b>employees</b>:
      <ol type='I'>
        <li>Name, telephone, email, address, date and place of birth.</li>
        <li>Work history, position, salary and reason for termination of employment in the last two jobs.</li>
      </ol>
      In case of making changes to these Privacy Policies, we will inform you by email, our official website, print media and our
      telephone operators.
    </p>
  </div>
);

export const PrivacyEs = ({ style, textStyle }) => (
  <div className='PrivacyEn__Container' style={style}>
    <p style={textStyle}>
      <b>Global Government</b> con domicilio en (24929 Katy Ranch Rd TX 7749), conforme a lo establecido en la{' '}
      <b>Legislación de los Estados Unidos de América</b>, específicamente del <b>Estado de Texas</b>, pone a disposición de
      nuestros clientes, proveedores, empleados y público en general, nuestro Aviso de Privacidad.
      <br />
      <br />
      Los datos personales que nos proporciona son utilizados estrictamente en la realización de funciones propias de nuestra
      empresa, no obstante son manejados por terceros como un servicio pago y seguro del manejo de la información. Cualquier uso
      indebido de la información almacenada por estos terceros están sujetos a sus propias políticas de privacidad y{' '}
      <b>Global Government</b> no se responsabiliza del manejo de la información personal por estos terceros.
      <br />
      <br />A nuestros <b>clientes</b> les solicitamos los siguientes datos personales:
      <ol type='I'>
        <li>Nombre y correo electrónico.</li>
        <li>
          Datos financieros como número de tarjeta de débito o crédito, fecha de vencimiento y código de seguridad para efectuar
          los pagos de contratación y/o compra correspondientes, estos datos financieros no se guardarán en nuestra base de datos
          y el usuario tendra que ingresar los datos cada vez que se vaya a hacer un pago.
        </li>
      </ol>
      <br />
      <br />A nuestros <b>proveedores</b> les solicitamos los siguientes datos personales:
      <ol type='I'>
        <li>
          Nombre, teléfono, correo electrónico y domicilio para facilitar el proceso de contratación de servicio y/o compra de
          producto
        </li>
      </ol>
      A nuestros <b>empleados</b> solicitamos los siguientes datos personales:
      <ol type='I'>
        <li>Nombre, teléfono, correo electrónico, domicilio, fecha y lugar de nacimiento.</li>
        <li>Antecedentes laborales, puesto, sueldo y motivo de terminación laboral en los últimos dos empleos.</li>
      </ol>
      En caso de realizar modificaciones a las presentes Políticas de Privacidad, le informaremos mediante correo electrónico,
      nuestro sitio web oficial, medios impresos y nuestros operadores telefónicos.
    </p>
  </div>
);
