import * as types from '../../types/utils/types';

const saveChatUser = (chatUser) => ({
  type: types.SAVE_USER_CHAT,
  data: chatUser,
});

const deleteChatUser = () => ({
  type: types.REMOVE_USER_CHAT,
});

const saveForeignUser = (foreignUser) => ({
  type: types.SAVE_FOREIGN_USER,
  data: foreignUser,
})

const removeForeignUser = () => ({
  type: types.REMOVE_FOREIGN_USER
})

const actionCreators = {
  saveChatUser,
  deleteChatUser,
  saveForeignUser,
  removeForeignUser
};

export { actionCreators };