import React from 'react';
import ReactPlayer from 'react-player';
import { HOME_VIDEO } from '../../../config';
import "./home-video.css";
import fbImg from "../../../assets/home/banner/Facebook-Header-Picture.jpg";

const HomeVideo = () => {
  return (
    <div className="Video-container">
      <section className="mediaPlayer">
        <ReactPlayer light={fbImg} width='100%' height='calc(100% - 10px)' url={HOME_VIDEO} />
      </section>
    </div>
  )
}

export default HomeVideo;