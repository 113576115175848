import React, { useState } from 'react';
// import InfoModal from '../../info-modals/info-modals';
// import { BSpan } from '../../../utils/text-utils/text-utils';
import { BLACK, FEDERAL_BLUE, FEDERAL_GREEN, WHITE_MAIN } from '../../../utils/color-palatte';
import './questions.css';
import TermsModal from '../../terms-modal/terms-modal';
import { TermsEs, TermsEn } from '../../terms-modal/terms-content/terms-content';
import i18n from '../../../i18n/i18n';
import { ParsedText } from '../../../utils/text-utils/text-utils';

// const QuestionWindow = (props) => {
//   return (
//     <div className='Question__Window-Container'>
//       <div className='Question__Window-Header'>
//         <p>{props.text}</p>
//       </div>
//       <div className='Question__Window-content'>{props.children}</div>
//     </div>
//   );
// };

// export const Question1 = () => (
//   <div className='Question__Container'>
//     <h1 className='Question__h1'>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper:</h1>
//     <p className='Question__Paragraph'>
//       Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo{' '}
//       <span>consequat</span>.
//       <br />
//       <br />
//       Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
//       consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat,{' '}
//       <span>vel illum dolore</span> eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit
//       praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
//     </p>
//     <QuestionWindow text='Ullamcorper suscript loborotis:'>
//       <p className='Question__Window-Paragraph'>
//         1. Molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan.
//       </p>
//       <p className='Question__Window-Paragraph'>2. Veniam, quis nostrud exerci tation ullamcorper.</p>
//       <p className='Question__Window-Paragraph'>3. Duis autem vel eum iriure dolor:</p>
//       <p className='Question__Window-Paragraph'>4. Vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan:</p>
//       <p className='Question__Window-Paragraph'>5. Qui blandit praesent luptatum zzril delenit augue duis dolore te feugait.</p>
//     </QuestionWindow>
//     <h1 className='Question__h1'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit:</h1>
//     <p className='Question__Paragraph'>
//       Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
//       aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper <span>suscipit</span> lobortis
//       nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor.
//       <br />
//       <br />
//       Ut wisi enim ad <span>minim veniam</span>, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
//       commodo consequat. Duis autem vel eum iriure dolor.
//       <br />
//       <br />
//       Lorem ipsum dolor sit amet, <span>consectetuer adipiscing elit</span>, sed diam nonummy nibh euismod tincidunt ut laoreet
//       dolore magna aliquam erat volutpat:
//     </p>
//   </div>
// );

export const Questions = {
  Question1: function Q1({ darkMode }) {
    return (
      <ParsedText
        string={i18n.t('FAQ.ITEMS.Item1.content')}
        hasWiki
        style={{
          fontSize: '1.1rem',
          whiteSpace: 'pre-line',
          boxSizing: 'border-box',
          padding: '20px 40px',
        }}
        markStyle={{ fontWeight: 'bold', color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE }}
      />
    );
  },
  Question2: function Q2({
    darkMode,
    maintenanceTaxes,
    presidentSalaryPercentage,
    vicepresidentSalaryPercentage,
    citizenGainFromDeal,
  }) {
    const maintenance = maintenanceTaxes && citizenGainFromDeal ? Number(maintenanceTaxes) - Number(citizenGainFromDeal) : '...';
    const presSalaryPercent = presidentSalaryPercentage ? presidentSalaryPercentage : '...';
    const vicePresSalaryPercent = vicepresidentSalaryPercentage ? vicepresidentSalaryPercentage : '...';
    const citizenGainDeal = citizenGainFromDeal ? citizenGainFromDeal : '...';
    const capital =
      maintenanceTaxes && presidentSalaryPercentage && vicepresidentSalaryPercentage
        ? 100 - (Number(maintenanceTaxes) + Number(presidentSalaryPercentage) + Number(vicepresidentSalaryPercentage))
        : '...';
    const politicalMaintenance =
      presidentSalaryPercentage && vicepresidentSalaryPercentage
        ? Number(presidentSalaryPercentage) + Number(vicepresidentSalaryPercentage)
        : '...';

    const values = [
      {
        keyword: `${maintenance}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${politicalMaintenance}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${presSalaryPercent}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${vicePresSalaryPercent}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${capital}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${citizenGainDeal}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
    ];

    return (
      <ParsedText
        string={i18n.t('FAQ.ITEMS.Item2.content')(
          maintenance,
          presSalaryPercent,
          vicePresSalaryPercent,
          capital,
          citizenGainDeal,
          politicalMaintenance
        )}
        style={{
          fontSize: '1.1rem',
          whiteSpace: 'pre-line',
          boxSizing: 'border-box',
          padding: '20px 40px',
        }}
        values={values}
        markStyle={{ fontWeight: 'bold', color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE }}
        hasWiki
      />
    );
  },
  Question3: function Q3({ minVotingAssistance, darkMode }) {
    const [terms, setTerms] = useState(false);

    const language = i18n.language;

    const minAssistance = minVotingAssistance ? minVotingAssistance : '...';

    const values = [
      { keyword: 'Terms\u00A0and\u00A0conditions', onClick: () => setTerms(true) },
      { keyword: 'Términos\u00A0y\u00A0condiciones', onClick: () => setTerms(true) },
      { keyword: `${minAssistance}%`, style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK } },
    ];

    return (
      <>
        <ParsedText
          string={i18n.t('FAQ.ITEMS.Item3.content')(minAssistance)}
          values={values}
          style={{ fontSize: '1.1rem', whiteSpace: 'pre-line', boxSizing: 'border-box', padding: '20px 40px' }}
          hasWiki
          markStyle={{ color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE, fontWeight: 'bold' }}
        />
        <TermsModal isOpen={terms} onRequestClose={() => setTerms(!terms)}>
          {language === 'es' ? <TermsEs /> : <TermsEn />}
        </TermsModal>
      </>
    );
  },
  Question4: function Q4({ timeToApproveDealDays, darkMode }) {
    const timeToApprove = timeToApproveDealDays ? timeToApproveDealDays : '...';

    return (
      <ParsedText
        string={i18n.t('FAQ.ITEMS.Item4.content')(timeToApprove)}
        markStyle={{
          color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE,
          fontWeight: 'bold',
        }}
        style={{
          fontSize: '1.1rem',
          whiteSpace: 'pre-line',
          boxSizing: 'border-box',
          padding: '20px 40px',
        }}
        hasWiki
        values={[
          {
            keyword: timeToApprove,
            style: { fontWeight: 'bold', color: '#fff' },
          },
        ]}
      />
    );
  },
  Question5: function Q5({ darkMode }) {
    return (
      <ParsedText
        string={i18n.t('FAQ.ITEMS.Item5.content')}
        hasWiki
        style={{
          fontSize: '1.1rem',
          whiteSpace: 'pre-line',
          boxSizing: 'border-box',
          padding: '20px 40px',
        }}
        markStyle={{
          color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE,
          fontWeight: 'bold',
        }}
      />
    );
  },
  Question6: function Q6({ PSalary, VPSalary, darkMode }) {
    const presSalaryPercent = PSalary ? PSalary : '...';
    const vicePresSalaryPercent = VPSalary ? VPSalary : '...';
    const values = [
      { keyword: `${presSalaryPercent}%`, style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' } },
      {
        keyword: `${vicePresSalaryPercent}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
    ];
    return (
      <ParsedText
        string={i18n.t('FAQ.ITEMS.Item6.content')(presSalaryPercent, vicePresSalaryPercent)}
        values={values}
        hasWiki
        style={{
          fontSize: '1.1rem',
          whiteSpace: 'pre-line',
          boxSizing: 'border-box',
          padding: '20px 40px',
        }}
        markStyle={{
          color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE,
          fontWeight: 'bold',
        }}
      />
    );
  },
  Question7: function Q7({ darkMode }) {
    return (
      <ParsedText
        string={i18n.t('FAQ.ITEMS.Item7.content')}
        hasWiki
        style={{
          fontSize: '1.1rem',
          whiteSpace: 'pre-line',
          boxSizing: 'border-box',
          padding: '20px 40px',
        }}
        markStyle={{
          color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE,
          fontWeight: 'bold',
        }}
      />
    );
  },
  Question8: function Q8({ maintenance, PSalary, VPSalary, citizenGainDeal, darkMode }) {

    const dealMaintenance = maintenance && citizenGainDeal ? Number(maintenance) - Number(citizenGainDeal) : '...';
    const presSalaryPercent = PSalary ? PSalary : '...';
    const vicePresSalaryPercent = VPSalary ? VPSalary : '...';
    const capital = maintenance && PSalary && VPSalary ? 100 - (Number(maintenance) + Number(PSalary) + Number(VPSalary)) : '...';
    const politicalMaintenance = PSalary && VPSalary ? Number(PSalary) + Number(VPSalary) : '...';

    const values = [
      { keyword: `${dealMaintenance}%`, style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' } },
      { keyword: `${presSalaryPercent}%`, style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' } },
      {
        keyword: `${vicePresSalaryPercent}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${capital}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${politicalMaintenance}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${citizenGainDeal}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
    ];
    return (
      <ParsedText
        string={i18n.t('FAQ.ITEMS.Item8.content')(
          dealMaintenance,
          capital,
          presSalaryPercent,
          vicePresSalaryPercent,
          politicalMaintenance,
          citizenGainDeal,
        )}
        values={values}
        style={{
          fontSize: '1.1rem',
          whiteSpace: 'pre-line',
          boxSizing: 'border-box',
          padding: '20px 40px',
        }}
        hasWiki
        markStyle={{
          color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE,
          fontWeight: 'bold',
        }}
      />
    );
  },
  Question9: function Q9({ contributionAmount, contributionEveryPaymentTime, darkMode }) {
    const contAmount = contributionAmount ? contributionAmount : '...';
    const contributionTime = contributionEveryPaymentTime ? contributionEveryPaymentTime : '...';

    const values = [
      {
        keyword: `${contAmount}$`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `$${contAmount}`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${contributionTime}`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
    ];

    return (
      <ParsedText
        string={i18n.t('FAQ.ITEMS.Item9.content')(contributionTime, contAmount)}
        hasWiki
        style={{
          fontSize: '1.1rem',
          whiteSpace: 'pre-line',
          boxSizing: 'border-box',
          padding: '20px 40px',
        }}
        values={values}
        markStyle={{
          color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE,
          fontWeight: 'bold',
        }}
      />
    );
  },
  Question10: function Q10({
    maintenanceTaxes,
    PSalary,
    VPSalary,
    contributionAmount,
    subscriptionBillingTaxes,
    subscriptionBillingAmount,
    citizenGainFromDeal,
    darkMode,
  }) {
    const dealMaintenance = maintenanceTaxes && citizenGainFromDeal ? Number(maintenanceTaxes) - Number(citizenGainFromDeal) : '...';
    const fullMaintenance = maintenanceTaxes ? maintenanceTaxes : '...';
    const presSalaryPercent = PSalary ? PSalary : '...';
    const vicePresSalaryPercent = VPSalary ? VPSalary : '...';
    const capital = maintenanceTaxes && PSalary && VPSalary ? 100 - (Number(maintenanceTaxes) + Number(PSalary) + Number(VPSalary)) : '...';
    const contribAmount = contributionAmount ? contributionAmount : '...';
    const monthPayment =
      subscriptionBillingAmount && subscriptionBillingTaxes
        ? Number(subscriptionBillingAmount) + Number(subscriptionBillingTaxes)
        : '...';
    const politicalMaintenance =
      PSalary && VPSalary ? Number(PSalary) + Number(VPSalary) : '...';
    const citizenGainDeal = citizenGainFromDeal ? citizenGainFromDeal : '...';

    const values = [
      {
        keyword: `${dealMaintenance}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${fullMaintenance}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${presSalaryPercent}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${vicePresSalaryPercent}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${capital}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${citizenGainDeal}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${contribAmount}$`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `$${contribAmount}`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${monthPayment}$`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
      {
        keyword: `${politicalMaintenance}%`,
        style: { fontWeight: 'bold', color: darkMode ? WHITE_MAIN : BLACK, cursor: 'normal' },
      },
    ];

    return (
      <ParsedText
        string={i18n.t('FAQ.ITEMS.Item10.content')(
          dealMaintenance,
          fullMaintenance,
          presSalaryPercent,
          vicePresSalaryPercent,
          capital,
          citizenGainDeal,
          contribAmount,
          monthPayment,
          politicalMaintenance
        )}
        hasWiki
        style={{
          fontSize: '1.1rem',
          whiteSpace: 'pre-line',
          boxSizing: 'border-box',
          padding: '20px 40px',
        }}
        values={values}
        markStyle={{
          color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE,
          fontWeight: 'bold',
        }}
      />
    );
  },
};
