import React from 'react';
import { isUserValid } from '../../utils/isUserValid';
import { getGlobalGovernmentData, validateContribution } from './financeActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as routes from '../../routes/routes';
import i18n from '../../i18n/i18n';
import moment from 'moment';
import thousandSeparator from '../../utils/thousandSeparator';
import { AppLoader } from '../../utils/animations/animations';
import { FEDERAL_BLUE } from '../../utils/color-palatte';
import OrnamentProfile from '../../components/ornament-profile/ornament-profile';
import ProfilePicture from '../../assets/global-government/gg_profile_img.png';
import IncomesIcon from '../../assets/finances/income_icon.svg';
import ExpensesIcon from '../../assets/finances/expense_icon.svg';
import QuestionIcon from '../../assets/general/question_icon.svg';
import './finances.css';
import Alert from '../../utils/alert/Alert';
import InfoModal from '../../components/info-modals/info-modals';
import { Warning } from '../../components/warning/Warning';
import ContributionSuccess from '../../assets/contribution/button-success.svg';
import NewContributionModal from '../../components/contribution/new-contribution-modal/NewContributionModal';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';

class Finances extends React.Component {
  state = {
    fetching: false,
    refresh: false,
    showIncomesInfo: false,
    isContributionModalOpen: false,
    modalHeight: 320,
    showPassivesInfo: false,
    //date range inputs
    startDate: moment().startOf('month').format('MM-DD-YYYY hh:mm'),
    endDate: moment().endOf('month').format('MM-DD-YYYY hh:mm'),
    displayedDate: moment(),
    minDate: moment().startOf('year'),
    maxDate: moment(),
    isVisibleModal: false,
    // modalIcon: FinanceIcon,
    modalTitle: i18n.t('GG_FINANCE.name'),
    modalContent: i18n.t('HELP_MODAL.finances'),
    isSmall: false,
  };

  rvlRef = React.createRef();
  observer = null;

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      this.observer = new ResizeObserver((entries) => {
        const boxElem = entries[0];
        if (boxElem.contentRect.width <= 500) {
          this.setState({
            isSmall: true,
          });
        } else {
          this.setState({
            isSmall: false,
          });
        }
      });
      this.observer.observe(this.rvlRef.current);
    }

    isUserValid(false, this._isMounted);
    if (this._isMounted) this.fetchData();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.observer.disconnect();
  };

  fetchData = () => {
    return new Promise(async (resolve, reject) => {
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const data = await getGlobalGovernmentData();
            // console.log("fetchPost / post: ", post);
            this.setState({
              fetching: false,
              refresh: false,
              data: data,
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false, refresh: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  setDates = (dates) => {
    this.setState({
      ...dates,
    });
  };

  resetFilters = () => {
    this.setState({
      startDate: moment().startOf('month').format('MM-DD-YYYY hh:mm'),
      endDate: moment().endOf('month').format('MM-DD-YYYY hh:mm'),
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  showInfo = (isOpen, type) => {
    this.setState({
      isInfoOpen: isOpen,
      infoType: type,
    });
  };

  validateContributionFunc = () => {
    return new Promise(async (resolve, reject) => {
      const { user } = this.props;
      this.setState({ contributionLoader: true }, async () => {
        try {
          await validateContribution(user._id);
          // console.log("fetchPost / post: ", post);
          this.setState({
            contributionLoader: false,
            isContributionModalOpen: true,
          });
          resolve();

          // this.props.navigation.navigate(CONTRIBUTION_ADVICE)
        } catch (e) {
          // console.log("validateContributionFunc / error: ", e)
          this.setState({ contributionLoader: false });
          const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
          if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
            this.props.openVerifyModal();
            reject(e)
            return;
          }
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  render() {
    const {
      fetching,
      data,
      isSmall,
      contributionLoader,
      isContributionModalOpen,
      //date range input
      // startDate,
      // endDate,
      // displayedDate,
      // minDate,
      // maxDate
    } = this.state;
    const { darkMode } = this.props;
    // const isTotalIncomeLarge = data ? `${thousandSeparator(data.totalIncomes.total)}$`.length > 7 : false;

    const FinanceItem = (props) => {
      const { style, type, cypher, onClick } = props;
      return (
        <div className='FinanceItem__Container' style={style}>
          <div className='FinanceItemTitle__Container'>
            <h2>{type === 'income' ? i18n.t('FINANCES.ITEMS.INCOMES.title') : i18n.t('FINANCES.ITEMS.EXPENSES.title')}</h2>
            <img
              style={{ position: 'absolute' }}
              src={QuestionIcon}
              alt=''
              onClick={() => this.showInfo(true, type === 'income' ? 'income' : 'expense')}
            />
          </div>
          <div className='FinanceItem__Content'>
            <div>
              <img src={type === 'income' ? IncomesIcon : ExpensesIcon} alt='' />
              <p>{cypher}$</p>
            </div>
            <button onClick={onClick}>
              {type === 'income' ? i18n.t('FINANCES.ITEMS.INCOMES.button') : i18n.t('FINANCES.ITEMS.EXPENSES.button')}
            </button>
          </div>
        </div>
      );
    };

    return (
      <div
        ref={this.rvlRef}
        className='Finance__Container'
        style={{
          minHeight: fetching ? 'calc(100vh - 120px)' : 'unset',
          justifyContent: fetching ? 'center' : 'flex-start',
        }}
      >
        {fetching ? (
          <AppLoader size='big' color={darkMode ? '#FFF' : FEDERAL_BLUE} />
        ) : (
          <>
            <div className='Finance__Banner' style={{ paddingTop: isSmall ? '41%' : '33%' }}>
              <div
                className='Finances__Ornament-container'
                style={{
                  transform: isSmall ? 'translate(-50%, 0) scale(.8)' : 'translate(-50%, 0)',
                }}
              >
                <OrnamentProfile
                  userPicture={ProfilePicture}
                  onlyRank
                  userRank='Global Government'
                  userRankStyles={{
                    color: darkMode ? '#fff' : FEDERAL_BLUE,
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                />
              </div>
            </div>
            <div
              className='Finance__Content'
              style={{
                borderBottom: `1px solid ${darkMode ? '#FFF' : FEDERAL_BLUE}`,
              }}
            >
              <div className='Finance__Bubble1'>
                <div className='Finance__Total-Item'>
                  {data?.globalgovernment?.capital ? (
                    <p>
                      Capital: <b>{thousandSeparator(data.globalgovernment.capital)}$</b>
                    </p>
                  ) : data?.globalgovernment?.capital === undefined || data?.globalgovernment?.capital === null ? (
                    <AppLoader size='small' color='#FFF' />
                  ) : (
                    `0$`
                  )}
                  <img src={QuestionIcon} alt='' onClick={() => this.showInfo(true, 'capital')} />
                </div>
                <div className='Finance__Balance-container' style={{ flexFlow: isSmall ? 'column nowrap' : 'row nowrap' }}>
                  {data?.totalIncomes && (
                    <FinanceItem
                      type='income'
                      cypher={thousandSeparator(data.totalIncomes.total)}
                      onClick={() => this.props.history.push(routes.GG_INCOMES_LIST_ROUTE)}
                    />
                  )}
                  {data?.totalExpenses && (
                    <FinanceItem
                      style={{
                        marginLeft: isSmall ? 0 : 10,
                        marginTop: isSmall ? 10 : 0,
                      }}
                      cypher={thousandSeparator(data.totalExpenses.total)}
                      onClick={() => this.props.history.push(routes.GG_EXPENSES_LIST_ROUTE)}
                    />
                  )}
                </div>
                <div onClick={() => this.validateContributionFunc()} className='Finance__Total-Item2'>
                  {!contributionLoader ? <p>{i18n.t('FINANCES.makeContribution')}</p> : <AppLoader size='small' color='#FFF' />}
                  <img src={ContributionSuccess} alt='' style={{ width: 330, height: '100%' }} />
                </div>
              </div>
            </div>
            <Warning title={i18n.t('FINANCES.important')} content={i18n.t('FINANCES.info')} />
          </>
        )}
        {/* <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        /> */}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() =>
            this.setState({
              isAlertOpen: false,
            })
          }
        />
        <NewContributionModal
          isOpen={isContributionModalOpen}
          onRequestClose={() => this.setState({ isContributionModalOpen: false })}
        />
        <InfoModal
          isOpen={this.state.isInfoOpen}
          type={this.state.infoType}
          capitalData={this.state.capitalData}
          onRequestClose={() =>
            this.setState({
              isInfoOpen: false,
              infoType: null,
              capitalData: {},
            })
          }
        />
      </div>
    );
  }
}

const FinancesWithRouter = withRouter(Finances);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openVerifyModal: bindActionCreators(actions.openVerifyModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancesWithRouter);
