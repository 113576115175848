import { getData } from '../fetch';
import { store } from '../redux/configureStore';
import { SAVE_HAS_NEW_NOTIFICATIONS } from '../redux/types/user/types';

const hasUserNewNotifications = () => {
  return new Promise(async (resolve, _) => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await getData(`/user/has-user-new-notifications-web/?userId=${userId}`);
      // console.log("hasUserNewNotifications / response: ", response)
      store.dispatch({ type: SAVE_HAS_NEW_NOTIFICATIONS, data: Number(response.body) > 0 });
      resolve();
    } catch (e) {
      resolve(e);
    }
  });
};

export default hasUserNewNotifications;