import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { 
  throttle, 
  // debounce 
} from 'lodash'
import './scrollableDiv.css';
// import debounce from 'lodash.debounce';
// import throttle from 'lodash.throttle';

const ScrollableDiv = ({ className, style, children, onEndReach }) => {

  // const [executed, setExecuted] = useState(false)

  const eventHandler = () => {
    onEndReach()
  };
  // const debouncedEventHandler = useMemo(
  //   () => debounce(eventHandler, 300)
  // , []);
  const throttledEventHandler = useMemo(
    () => throttle(eventHandler, 2000)
    // eslint-disable-next-line
  , []);

  useEffect(() => {
    document.addEventListener('scroll', listenToScroll);
    // preventExcetutedMoreTimes()
  });

  useLayoutEffect(() => {
    document.removeEventListener('scroll', listenToScroll);
  });

  // const preventExcetutedMoreTimes = () => {
  //   if (executed) {
  //     setTimeout(() => {
  //       setExecuted(false)
  //       console.log('Prevented')
  //     }, 5000)
  //   }
  // }

  const listenToScroll = () => {

    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  
    const scrolled = winScroll / height
    // console.log("scrolled: ", scrolled);
    if (scrolled >= .98) {
      // if (!executed) {
        if (typeof onEndReach === 'function') {
          // setTimeout(() => {
            throttledEventHandler()
            // setExecuted(true)
          // }, 3000)
        }
      // }
    }
  }

  return (
    <div
      onScroll={listenToScroll}
      className={`Scrollable__Div-Container ${className}`}
      style={Object.assign({}, style)}
    >
      {children}
    </div>
  );
};

export default ScrollableDiv;
