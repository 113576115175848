import { getData } from "../fetch";

const getUserBySearch = (search) => {
    // console.log("llamo getUserBySearch: ", search);
    return new Promise(async (resolve, reject) => {
        try {
            const response = await getData(`/user/get-user-by-search-web/?search=${search}`);
            const users = response.body;
            return resolve(users);
        } catch (e) {
            return reject(e);
        }
    })
};

export default getUserBySearch;