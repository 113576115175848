import i18n from "../i18n/i18n"

const handlePaymentType = (type) => {
  if (type === "subscription") {
    return i18n.t('PAYMENTS_LIST.subscription')
  }
  if (type === "capital-contribution") {
    return i18n.t('PAYMENTS_LIST.capitalContribution')
  }
  return ""
}

export default handlePaymentType;