import { postData } from "../../fetch";

export const createContact = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/contact/create-contact-web', data);
      resolve(response);
      resolve();
    } catch (e) {
      reject(e);
    }
  })
};