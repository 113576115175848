import React from 'react';
import WarningIcon from '../../assets/not-loaded-rss/icon_report.svg';
import i18n from '../../i18n/i18n';
import './notLoadedResources.css';

const NotLoadedResources = (props) => {
  const { text, type, style, textStyle, onClick } = props;
  return (
    <div onClick={onClick} className='NotLoadedRss__Container' style={style}>
      <img src={WarningIcon} alt='' />
      <p style={textStyle}>
        {text
          ? text
          : type === 'stream'
          ? i18n.t('NOT_LOADED_RSS.stream')
          : type === 'video'
          ? i18n.t('NOT_LOADED_RSS.video')
          : i18n.t('NOT_LOADED_RSS.image')}
      </p>
    </div>
  );
};

export default NotLoadedResources;
