import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import i18n from '../../i18n/i18n';
import { throttle } from 'lodash';
import * as colors from '../../utils/color-palatte';
import Alert from '../../utils/alert/Alert';
import { isUserValid } from '../../utils/isUserValid';
import getStat from '../../utils/getStat';
import { getUserNotifications, removeAllNotifications, removeNotification } from './getUserNotifications';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import NotificationItem from '../../components/notifications/notificationItem';
import { AppLoader } from '../../utils/animations/animations';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';
import styles from './userNotifications.module.css';
import removeIcon from '../../assets/notifications/remove_icon.svg';
import removeIconWhite from '../../assets/notifications/remove_icon_white.svg';
import { withDialog } from '../../utils/dialog-modal/dialogModal';

const DeleteBtn = (props) => {
  const { darkMode, style, ...rest } = props;
  const [isHover, setIsHover] = useState(false);
  return (
    <button
      className={styles.RemoveAll__Btn}
      style={Object.assign({ backgroundColor: isHover ? (darkMode ? '#fff5' : `${colors.GRAY_SECOND}55`) : '#0000' }, style)}
      {...rest}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img src={darkMode ? removeIconWhite : removeIcon} alt='remove' />
    </button>
  );
};

class UserNotifications extends React.Component {
  state = {
    fetching: true,
    skipItems: 0,
    limitItems: 20,
    plusPostNumber: 20,
    keepIncreasingCounter: false,
    search: '',
    notifications: [],
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;

    isUserValid(false, this._isMounted);
    if (this._isMounted) await this.getStats();
    if (this._isMounted) this.onRefreshUser();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const positions = await getStat('positions');
        // console.log("positionsPeriodDays: ", positionsPeriodDays);
        this.setState({
          positions,
        });
        resolve();
      } catch (e) {
        // console.log("getStats / error: ", e);
        reject();
      }
    });
  };

  fetchNotifications = async () => {
    const { user } = this.props;
    const { skipItems, limitItems, search, notifications = [] } = this.state;
    this.setState({ fetching: true }, async () => {
      try {
        const newNotifications = await getUserNotifications(user._id, skipItems, limitItems, search);
        // console.log("getUserNotifications / newNotifications: ", newNotifications);
        if ((newNotifications && newNotifications.length > 0) || (notifications && notifications.length > 0)) {
          const finalNotifications = notifications
            .concat(newNotifications)
            .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
            .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));

          this.setState({
            notifications: finalNotifications,
            fetching: false,
          });
        } else {
          this.setState({
            fetching: false,
          });
        }
      } catch (e) {
        // console.log("fetchNotifications / error: ", e)
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
        this.setState({
          fetching: false,
        });
      }
    });
  };

  removeNotification = async (notificationId) => {
    this.setState({ fetching: true }, async () => {
      try {
        await removeNotification(notificationId);
        this.setState({
          notifications: this.state.notifications.filter((item) => item._id !== notificationId),
          fetching: false
        });
      } catch (e) {
        const error = e?.data?.error?.details?.message ?? e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
        this.setState({ fetching: false });
        this.showAlert(true, 'error', error?.toString());
      }
    });
  };

  removeAllNotificationsFunc = async () => {
    this.setState({ fetching: true });
    try {
      await removeAllNotifications();
      this.setState(
        {
          notifications: [],
          fetching: false,
        },
        () => this.onRefreshUser()
      );
    } catch (e) {
      const error = e?.data?.error?.details?.message ?? e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
      this.setState({ fetching: false });
      this.showAlert(true, 'error', error?.toString());
    }
  };

  onRefreshUser = () => {
    const { plusPostNumber } = this.state;
    this.setState({ skipItems: 0, limitItems: plusPostNumber, notifications: [] }, () => {
      this.fetchNotifications();
    });
  };

  onEndReached = () => {
    if (!this._isMounted) return;
    // console.log("onEndReached");
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchNotifications();
        }
      );
    } else {
      this.fetchNotifications();
    }
  };

  throtled = throttle(async () => {
    this.onRefreshUser();
  }, 1000);

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const { fetching, notifications, positions } = this.state;

    const {
      // user,
      darkMode,
    } = this.props;
    // console.log('notifications: ', notifications);
    return (
      <ScrollableDiv
        style={{
          width: '100%',
          maxWidth: 800,
          margin: '0 auto',
          boxSizing: 'border-box',
          padding: '1px 0',
          position: 'relative',
        }}
        onEndReach={this.onEndReached}
      >
        {notifications && notifications.length > 0 && <div className={styles.NotificationHeader}>
          <DeleteBtn
            darkMode={darkMode}
            style={{ opacity: notifications && notifications.length > 0 ? '1' : '0.5', pointerEvents: notifications && notifications.length > 0 ? 'auto' : 'none' }}
            onClick={() =>
              this.props.dialogModal(
                true,
                'warning',
                i18n.t('NOTIFICATIONS.sureToRemoveAll'),
                fetching,
                { action: () => this.removeAllNotificationsFunc() },
                true
              )
            }
          />
        </div>}
        {fetching && (!notifications || notifications?.length === 0) ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 100px)',
            }}
          >
            <AppLoader size='big' color={darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE} />
          </div>
        ) : null}
        {!fetching && (!notifications || notifications?.length === 0) ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 100px)',
            }}
          >
            <NotFoundItem type='notification' isBlue={darkMode} text={i18n.t('NOTIFICATIONS.notFound')} />
          </div>
        ) : null}
        {notifications?.length > 0
          ? notifications.map((item, i) => (
              <NotificationItem
                item={item}
                key={i}
                darkMode={darkMode}
                positions={positions}
                removeNotification={this.removeNotification}
              />
            ))
          : null}
        {notifications?.length > 0 && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 60 }}>
            {fetching ? (
              <AppLoader size='40px' color={darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE} />
            ) : (
              <p style={{ color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE }}>{i18n.t('NOTIFICATIONS.noMore')}</p>
            )}
          </div>
        )}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        />
      </ScrollableDiv>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
};

const UserNotificationsWithDialog = withDialog(UserNotifications);

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationsWithDialog);
