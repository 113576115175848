import React from 'react';
import { FEDERAL_BLUE } from '../../color-palatte';
import './rightComponentInput.css';

const RightComponentItem = ({
  className,
  type,
  isWhite,
  inputClassName,
  textareaClassName,
  autoComplete,
  style,
  placeholder,
  name,
  onChange,
  value,
  id,
  disabled,
  onKeyPress,
  rightComponent,
  textArea,
  maxLength,
  row,
  cols,
}) => {
  return (
    <div
      className={`${className} RightComponentItem__Container`}
      style={{
        border: `2px solid ${isWhite ? 'white' : FEDERAL_BLUE}`,
        opacity: disabled ? '0.5' : 'unset',
      }}
    >
      {!textArea ? (
        <input
          type={type || 'text'}
          id={id}
          className={`${
            isWhite ? 'RightComponentInput-white' : 'RightComponentInput'
          } ${inputClassName}`}
          style={Object.assign(
            {
              width: rightComponent ? 'calc(100% - 60px)' : '100%',
              opacity: disabled ? '0.5' : 'unset',
            },
            style
          )}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          onKeyPress={onKeyPress}
          autoComplete={autoComplete}
        />
      ) : (
        <textarea
          id={id}
          className={`${
            isWhite ? 'RightComponentTextArea-white' : 'RightComponentTextArea'
          } ${textareaClassName}`}
          style={Object.assign(
            {
              width: rightComponent ? 'calc(100% - 60px)' : '100%',
              opacity: disabled ? '0.5' : 'unset',
            },
            style
          )}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          autoComplete={autoComplete}
          maxLength={maxLength}
          row={row}
          cols={cols}
        />
      )}
      {rightComponent && (
        <div className='RightComponentItem__ItemContainer'>
          {rightComponent}
        </div>
      )}
    </div>
  );
};

export default RightComponentItem;
