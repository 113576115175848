import { postData } from '../../fetch';

export const signUp = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/user/signup-web', data, false);
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};
