import React from 'react';
import AlertModal from '../modal/Modal';
import WrongIcon from '../../assets/contact-us/alert/wrong_icon.svg';
import WarningIcon from '../../assets/contact-us/alert/warning_icon.svg';
import SuccessIcon from '../../assets/contact-us/alert/success_icon.svg';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { AppLoader } from '../animations/animations';
import { store } from '../../redux/configureStore';
import { OPEN_DIALOG_MODAL } from '../../redux/types/user/types';

const DialogModal = (props) => {
  const { isOpen, onRequestClose, title, yes, no, loading, content, type } = props;
  const { t } = useTranslation();

  const onYesClick = () => {
    if (yes.action && typeof yes.action === 'function') {
      yes.action();
      onRequestClose();
    } else {
      void 0;
    }
  };

  const onNoClick = () => {
    if (no.action && typeof no.action === 'function') {
      no.action();
      onRequestClose();
    } else {
      onRequestClose();
    }
  };
  return (
    <AlertModal isOpen={isOpen} modalClassName={styles.Modal} overlayClassName={styles.Overlay}>
      <div className={styles.Title}>
        {loading
          ? null
          : type && (
              <img
                src={
                  type === 'success'
                    ? SuccessIcon
                    : type === 'warning'
                    ? WarningIcon
                    : type === 'wrong'
                    ? WrongIcon
                    : type === 'error'
                    ? WrongIcon
                    : ''
                }
                alt=''
              />
            )}
        <h1>
          {loading
            ? t('GENERAL_TEXT.pleaseWait')
            : title
            ? title
            : type === 'success'
            ? t('MESSAGE_MODAL.success')
            : type === 'warning'
            ? t('MESSAGE_MODAL.warning')
            : type === 'wrong'
            ? t('MESSAGE_MODAL.error')
            : type === 'email'
            ? t('verifyingEmail')
            : type === 'error'
            ? t('MESSAGE_MODAL.error')
            : t('MESSAGE_MODAL.error')}
        </h1>
      </div>
      {loading ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 80 }}>
          <AppLoader size='60px' />
        </div>
      ) : (
        <>
          <p>{content}</p>
          <div className={styles.Button__Container}>
            {yes && (
              <button className={styles.Button} onClick={onYesClick}>
                {yes.title || t('GENERAL_TEXT.yes')}
              </button>
            )}
            {no && (
              <button className={styles.Button} onClick={onNoClick}>
                {no.title || t('GENERAL_TEXT.no')}
              </button>
            )}
            {!yes && !no && (
              <button className={styles.Button} onClick={onRequestClose}>
                {t('GENERAL_TEXT.continue')}
              </button>
            )}
          </div>
        </>
      )}
    </AlertModal>
  );
};

export const useDialogModal = () => {
  return (isOpen, type, content, loading, yes, no) =>
    store.dispatch({
      type: OPEN_DIALOG_MODAL,
      data: {
        isDialogOpen: isOpen,
        dialogType: type,
        dialogMessage: content,
        dialogLoading: loading,
        dialogYes: yes,
        dialogNo: no,
      },
    });
};

export const withDialog = (Component) => {
  return (props) => {
    const dialogModal = useDialogModal();

    return <Component dialogModal={dialogModal} {...props} />;
  };
};

export default DialogModal;
