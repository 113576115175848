import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertModal from '../../utils/modal/Modal';
import AinstainSad from '../../assets/privacy/sad_ainstain.svg';
import styles from './styles.module.css';
import { DeleteModalButton } from './deleteWarnModal';
import * as colors from '../../utils/color-palatte';

const DeleteSuccessModal = (props) => {
  const { isOpen, onRequestClose } = props;

  const [isSmall, setIsSmall] = React.useState(false);

  const { t } = useTranslation();

  const rvlRef = React.useRef();
  let observer = null;

  React.useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line
      observer = new ResizeObserver((entries) => {
        const boxElem = entries[0];
        if (boxElem.contentRect.width < 450) {
          setIsSmall(true);
        } else {
          setIsSmall(false);
        }
      });

      if (observer) {
        observer.observe(rvlRef.current);
      }
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isOpen, rvlRef, isSmall]);

  return (
    <AlertModal
      ref={rvlRef}
      isOpen={isOpen}
      overlayClassName={styles.overlay}
      modalClassName={styles.modal__3}
      outsideClick={onRequestClose}
    >
      <h1>{t('DELETE_ACCOUNT.MODALS.DELETE_SUCCESS.title')}</h1>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexFlow: 'column',
          marginBottom: 80
        }}
      >
        <p style={{ textAlign: 'center' }}>{t('DELETE_ACCOUNT.MODALS.DELETE_SUCCESS.content')}</p>
        <img src={AinstainSad} className={styles.AinstainSad} alt='' />
      </div>
      <div style={{ position: 'absolute', bottom: '0', height: 80 }}>
        <DeleteModalButton
          text={t('GENERAL_TEXT.continue')}
          onClick={onRequestClose}
          style={{ bacgroundColor: colors.WHITE_MAIN, color: colors.FEDERAL_BLUE }}
          hoverStyle={{ bacgroundColor: colors.FEDERAL_LOW_BLUE, color: colors.FEDERAL_BLUE }}
        />
      </div>
    </AlertModal>
  );
};

export default DeleteSuccessModal;
