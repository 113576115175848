import React from 'react';
import i18n from '../../i18n/i18n';
import { withRouter } from 'react-router-dom';
import * as routes from '../../routes/routes';
import * as colors from '../../utils/color-palatte';
import { handlePostDate } from '../../utils/handlePostDate';
import { UserName, UserProfile } from '../ornament-profile/profileUtils';
// import CitizenIcon from '../../assets/notifications/citizen_icon.svg';
import CommentIcon from '../../assets/notifications/comment_icon.svg';
import LikeIcon from '../../assets/notifications/like_icon.svg';
import VotingIcon from '../../assets/notifications/voting_icon.svg';
import * as mobile from '../../utils/mobileRegex';
import './notificationItem.css';
import { decrypt } from '../../utils/encrypt';
import Alert from '../../utils/alert/Alert';
import MenuButton from '../menu-button/MenuButton';
import OptionModal from '../options-modal/optionsModal';

class NotificationItem extends React.PureComponent {
  state = {
    isHover: false,
    isSmall: false,
    isMobile: false,
    content: '',
  };

  observer = null;
  rvlRef = React.createRef();

  componentDidMount = () => {
    this.decryptMessage();
    this.observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      if (boxElem.contentRect.width <= 600) {
        this.setState({ isSmall: true });
      } else {
        this.setState({ isSmall: false });
      }
    });

    if (mobile.mobileRegex1.test(navigator.userAgent) || mobile.mobileRegex2.test(navigator.userAgent.substr(0, 4))) {
      this.setState({
        isMobile: true,
      });
    }

    this.observer.observe(this.rvlRef.current);
  };

  componentWillUnmount = () => {
    this.observer.disconnect();
  };

  goUserProfile = (user) => {
    const { history } = this.props;
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId === user) {
      history.push(routes.PROFILE_ROUTE);
    } else {
      history.push(`${routes.PROFILE_ROUTE}/${user}`);
    }
  };

  onNotificationClick = () => {
    const { item, history } = this.props;
    if (item.type === 'receive-message') {
      if (item?.chat?._id) {
        history.push(`${routes.USER_CHAT}/${item.chat._id}`);
      } else {
        this.showAlert(true, 'error', i18n.t('NOTIFICATIONS.ERRORS.noChatFound'));
      }
    }
    if (item.type === 'post-liked') {
      history.push(`${routes.POST_ROUTE}/${item?.post?._id ?? item.post}`);
    }
    if (item.type === 'mention' || item.type === 'post-comment') {
      history.push(`${routes.POST_ROUTE}/${item?.post?._id ?? item.post}`);
    }
    if (item.type === 'new-follower') {
      this.goUserProfile(item?.user?._id ?? item?.user);
    }
    if (item.type === 'voting-is-official' || item.type === 'voting-is-blocked') {
      if (item?.voting?._id ?? item?.voting) {
        history.push(`${routes.VOTING_DETAILS_ROUTE}/${item?.voting?._id ?? item?.voting}`);
      } else {
        history.push(routes.VOTING_LIST_ROUTE);
      }
    }
    if (item.type === 'new-position') {
      history.push(routes.GG_HIERARCHY_ROUTE);
    }
    if (item.type === 'law-approved') {
      if (item?.law?._id ?? item?.law) {
        history.push(`${routes.LAWS_DETAILS_ROUTE}/${item?.law?._id ?? item?.law}`);
      } else {
        history.push(routes.LAWS_LIST_ROUTE);
      }
    }
    if (item.type === 'deal-approved') {
      if (item?.deal?._id ?? item?.deal) {
        history.push(`${routes.DEALS_DETAILS_ROUTE}/${item?.deal?._id ?? item?.deal}`);
      } else {
        history.push(routes.DEALS_LIST_ROUTE);
      }
    }
    if (item.type === 'deal-removed') {
      history.push(routes.DEALS_LIST_ROUTE);
    }
    if (item.type === 'law-removed') {
      history.push(routes.LAWS_LIST_ROUTE);
    }
  };

  decryptMessage = async () => {
    const { item } = this.props;
    if (item.type === 'receive-message') {
      const decrypted = await decrypt(item.content);
      this.setState({ content: decrypted });
      console.log(item);
    } else {
      this.setState({ content: item.content });
    }
  };

  removeNotification = async () => {
    const { removeNotification, item } = this.props;
    try {
      this.setState({ actionsLoader: true });
      await removeNotification(item._id);
      this.setState({ isOptionsOpen: false, actionsLoader: false });
    } catch (e) {
      this.setState({ actionsLoader: false, isOptionsOpen: false });
    }
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  handleClick = (e, click) => {
    e.stopPropagation();
    if (typeof click === 'function') {
      click();
    } else {
      return null;
    }
  };

  render() {
    const { item, darkMode, positions } = this.props;
    const { isSmall, isHover, isMobile } = this.state;

    let currentPosition = '';

    if (positions && positions.length > 0 && item.user) {
      const havePosition = positions.filter((position) => position.user._id === item.user._id);
      if (havePosition.length > 0) {
        currentPosition = havePosition[0].position;
        // console.log('%cPositions', currentPosition);
      }
    }

    const notificationContent =
      item.type === 'receive-message' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexFlow: 'row wrap',
            color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLACK,
            fontSize: `${isSmall ? 0.8 : 1.1}rem`,
          }}
        >
          <UserName
            username={item.user.username}
            position={currentPosition}
            titleStyles={{
              fontWeight: '400',
              fontSize: `${isSmall ? 0.8 : 1.1}rem`,
              color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLACK,
              cursor: 'pointer',
            }}
            darkMode={darkMode}
            crownStyle={{ width: isSmall ? 15 : 20 }}
            ornamentStyle={{ width: isSmall ? 15 : 20 }}
          />
          &nbsp;{`${i18n.t('NOTIFICATIONS.sendedYouAmessage')} ${'\n'} ${this.state.content}`}
        </div>
      ) : item.type === 'post-liked' || item.type === 'new-follower' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexFlow: 'row wrap',
            color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLACK,
            fontSize: `${isSmall ? 0.8 : 1.1}rem`,
          }}
        >
          <UserName
            username={this.state.content}
            position={currentPosition}
            titleStyles={{
              fontWeight: '400',
              fontSize: `${isSmall ? 0.8 : 1.1}rem`,
              color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLACK,
              cursor: 'pointer',
            }}
            darkMode={darkMode}
            crownStyle={{ width: isSmall ? 15 : 20 }}
            ornamentStyle={{ width: isSmall ? 15 : 20 }}
          />
        </div>
      ) : (
        <p
          style={{ color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLACK, margin: 0, fontSize: `${isSmall ? 0.8 : 1.1}rem` }}
        >
          {this.state.content}
        </p>
      );

    return (
      <div
        onTouchMove={() => (isMobile ? this.setState({ isHover: true }) : void 0)}
        onTouchEnd={() => (isMobile ? this.setState({ isHover: false }) : void 0)}
        onMouseEnter={() => (isMobile ? void 0 : this.setState({ isHover: true }))}
        onMouseLeave={() => (isMobile ? void 0 : this.setState({ isHover: false }))}
        onContextMenu={isMobile ? (e) => this.handleClick(e, this.setState({ isOptionsOpen: true})) : void 0}
        ref={this.rvlRef}
        style={{
          backgroundColor: isHover ? (darkMode ? '#FFF4' : '#0004') : '#0000',
        }}
        className='Notification__Container'
        onClick={() => this.onNotificationClick()}
      >
        <div className='NotificationItem__Content'>
          <div className='NotificationItem__ImageContainer' style={{ width: isSmall ? 40 : 60 }}>
            {item.type === 'receive-message' ||
            item.type === 'mention' ||
            item.type === 'new-follower' ||
            item.type === 'post-liked' ? (
              <UserProfile
                position={currentPosition}
                uri={item.user.picture}
                containerStyle={{ width: isSmall ? 40 : 60, height: isSmall ? 40 : 60 }}
              />
            ) : item?.type ? (
              <img
                style={{ width: isSmall ? 40 : 60 }}
                className='NotificationItem__Icon'
                src={
                  item.type === 'post-liked'
                    ? LikeIcon
                    : item.type === 'post-comment'
                    ? CommentIcon
                    : // : item.type === 'mention' || item.type === 'new-follower'
                    // ? CitizenIcon
                    item.type === 'voting-is-official' ||
                      item.type === 'voting-is-blocked' ||
                      item.type === 'new-position' ||
                      item.type === 'law-approved' ||
                      item.type === 'deal-approved' ||
                      item.type === 'deal-removed' ||
                      item.type === 'law-removed'
                    ? VotingIcon
                    : null
                }
                alt=''
              />
            ) : null}
          </div>
          <div className='NotificationItem__Title' style={{ width: `calc(100% - ${isSmall ? 50 : 70}px)` }}>
            {notificationContent}
          </div>
        </div>
        <p
          style={{
            width: '100%',
            textAlign: 'right',
            color: darkMode ? colors.FEDERAL_GREEN : colors.FEDERAL_BLACK,
            margin: 0,
            fontSize: `${isSmall ? 0.8 : 1}rem`,
          }}
        >
          {handlePostDate(item.creationDate)}
        </p>
        {isHover && !isMobile ? (
          <MenuButton
            isBlue={!darkMode}
            withBorder
            className='NotificationItem__Menu'
            onClick={(e) => this.handleClick(e, this.setState({ isOptionsOpen: true }))}
          />
        ) : null}
        <OptionModal
          isOpen={this.state.isOptionsOpen}
          show={{
            delete: {
              display: this.props.removeNotification,
              onClick: () => this.removeNotification(),
            },
          }}
          onRequestClose={() => this.setState({ isOptionsOpen: false })}
        />
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        />
      </div>
    );
  }
}

export default withRouter(NotificationItem);
