import * as types from '../redux/types/achievements/types';
import { store } from "../redux/configureStore";
import { throttle } from "lodash";

export const validateNewAchievements = throttle((achievements) => {
  // console.log("test: ", achievements)
  return new Promise(async (resolve, reject) => {
    // console.log("isUserValid")
    // const state = store.getState()
    
    if (achievements && achievements.filter(achievement => achievement.new && achievement.active).length > 0) {
      let firstItem = {}
      achievements.filter(achievement => achievement.new && achievement.active).forEach((element, i) => {
        if(i === 0){
          firstItem = element
        }
      }); 
      // console.log("firstItem: ", firstItem)
      if(firstItem){
        store.dispatch({ type: types.OPEN_NEW_ACHIEVEMENT_MODAL, data: { newAchievement: firstItem } })
      }
      
    } else {
      store.dispatch({ type: types.CLOSE_NEW_ACHIEVEMENT_MODAL })
      resolve()
    }
  })
}, 1000)

