import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../routes/routes'

class SafeLINK extends Component {
  isSafe(dangerousLINK) {
    const url = dangerousLINK;
    if (url === routes.ROOT_ROUTE) return true;
    if (url === routes.HOME_ROUTE ) return true;
    if (url === routes.FAQ_ROUTE) return true;
    if (url === routes.LOGIN_ROUTE) return true;
    if (url === routes.SIGN_UP_ROUTE) return true;
    if (url === routes.DASHBOAR_ROUTE) return true;
    if (url === routes.SETTINGS_ROUTE) return true;
    if (url === routes.SEARCH_ROUTE) return true;
    if (url === routes.VOTINGS_ROUTE) return true;
    if (url === routes.SERVICES_ROUTE) return true;
    if (url === routes.USER_PAYMENT_DETAILS) return true;
    if (url === routes.USER_PATMENTS_ROUTE) return true;
    if (url === routes.USER_FINANCE_ROUTE) return true;
    if (url === routes.COMMUNITIES_ROUTE) return true;
    if (url === routes.PROFILE_ROUTE) return true;
    if (url === routes.CREATE_POST_ROUTE) return true;
    if (url === routes.MESSAGES_ROUTE) return true;
    if (url === routes.POST_ROUTE) return true;
    if (url === routes.WELCOME_ROUTE) return true;
    if (url === routes.CHANGE_PASSWORD_ROUTE) return true;
    if (url === routes.RESTORE_PASSWORD_ROUTE) return true;
    if (url === routes.PASSWORD_DONE_ROUTE) return true;
    if (url === routes.SUCCES_MAIL_ROUTE) return true;
    if (url === routes.MAIL_ERROR_ROUTE) return true;
    if (url === routes.INCOMES_ROUTE) return true;
    if (url === routes.EXPENSES_ROUTE) return true;
    if (url === routes.LAWS_LIST_ROUTE) return true;
    if (url === routes.DEALS_LIST_ROUTE) return true;
    if (url === routes.DOWNLOAD_APP_ROUTE) return true;
    if (url === routes.CREATE_VOTE_ROUTE) return true;
    if (url === routes.USER_FOLLOWERS_ROUTE) return true;
    if (url === routes.GLOBAL_GOVERNMENT_ROUTE) return true;
    if (url === routes.NOTICE_OF_PRIVACY_ROUTE) return true;
    if (url === routes.TERMS_AND_CONDITIONS_ROUTE) return true;
    if (url === routes.USER_NOTIFICATIONS_ROUTE) return true;
    if (url === routes.COMMUNITY_CREATE_ROUTE) return true;
    if (url === routes.WHITEPAPER_ROUTE) return true;
    return false;
  }
  render() {
    const dangerousLINK = this.props.dangerousLINK;
    const safeLINK = this.isSafe(dangerousLINK) ? dangerousLINK : '';
    return (
      <Link
        to={safeLINK}
        style={this.props.style}
        className={this.props.className}
        onClick={this.props.onClick}
        target={this.props.newTab ? "_blank" : ""}
        rel={this.props.newTab ? "noopener norefer" : ""}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
      >
        {this.props.text ?? this.props.children}
      </Link>
    );
  }
}

export default SafeLINK;
