import React from 'react';
import { useHistory } from 'react-router-dom';
import * as routes from '../../routes/routes';
import { UserName, UserProfile } from '../ornament-profile/profileUtils';
import {
  FEDERAL_BLUE,
  FEDERAL_WHITE,
  UNSATURATED_BLACK_BLUE,
  EASY_BLUE,
  FEDERAL_BLUE_STRONG,
  WHITE_MAIN,
  FEDERAL_BLUE_SOFT,
  FEDERAL_LIGTH_BLUE,
} from '../../utils/color-palatte';
import {
  // ReadMoreLinesTxt,
  ReadMoreTxt,
  TranslatableText,
} from '../../utils/text-utils/text-utils';
import './messageItem.css';
import moment from 'moment';
import PostCard from '../post/postcard';
import VotingItem from '../voting/votingItem';
import UserRef from '../user-ref/UserRef';
import i18n from '../../i18n/i18n';
import CommunityItem from '../community/communityItem';
import IncomesItem from '../incomes-list/incomesItem';
import { decrypt } from '../../utils/encrypt';
import checkIcon from '../../assets/messages/check-solid.svg';
import doubleCheckIcon from '../../assets/messages/check-double-solid.svg';
import { useStateCallback } from '../../hooks/AppHooks';
import OptionModal from '../options-modal/optionsModal';
import Alert from '../../utils/alert/Alert';

const MessageItem = (props) => {
  const [isSmall, setIsSmall] = React.useState(false);
  const [decryptedContent, setContent] = React.useState('');
  // eslint-disable-next-line
  const [actionsLoader, setActionsLoader] = useStateCallback(false);
  const [menu, setMenu] = React.useState(false);
  const [selected, setSelected] = React.useState(false);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  const history = useHistory();

  const goUserProfile = async (message) => {
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId === message.user._id) {
      history.push(routes.PROFILE_ROUTE);
    } else {
      history.push(`${routes.PROFILE_ROUTE}/${message.user._id}`);
    }
  };

  const rvlRef = React.useRef();
  let observer = null;

  React.useEffect(() => {
    decryptMessage();
    // eslint-disable-next-line
    observer = new ResizeObserver((entries) => {
      const msgElem = entries[0];
      if (msgElem.contentRect.width <= 520) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    });

    if (observer) {
      observer.observe(rvlRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  });

  const decryptMessage = async () => {
    const decrypted = await decrypt(props.item.content);
    setContent(decrypted);
  };
  const {
    item,
    creationDate = true,
    // style,
    // navigation,
    // textStyle,
    userId,
    // active,
    // userUsername,
    // userImageStyle,
    // circleStyle,
    darkMode,
    positions,
    user,
    stats,
    remove,
    removeForAll,
    positionsPeriodDays,
    votingExpiresPeriodDays,
  } = props;

  const position =
    positions?.filter((position) => position.user._id === item.user._id).length > 0
      ? positions.filter((position) => position.user._id === item.user._id)[0].position
      : null;

  const inner = userId === item?.user?._id ? true : false;

  const users = stats?.users;

  const handleClick = (e) => {
    e.preventDefault();
    if (e.type === 'click' || e.nativeEvent.button === 0) {
      return false;
    }
    setMenu(true);
  };

  const removeMsg = () => {
    setActionsLoader(true, async () => {
      try {
        await remove(item._id);
        setActionsLoader(false);
        setMenu(false);
      } catch (e) {
        setActionsLoader(false);
        setMenu(false);
      }
    });
  };

  const removeMsgForAll = () => {
    setActionsLoader(true, async () => {
      try {
        await removeForAll(item._id, item.recipentUserId);
        setActionsLoader(false);
        setMenu(false);
      } catch (e) {
        setActionsLoader(false);
        setMenu(false);
      }
    });
  };

  const onCopy = () => {
    setMenu(false);
    let mainTxt = decryptedContent;
    if (item && item.post && positions && !item.post?.block) {
      mainTxt += `:\u00A0\n${window.location.origin}${routes.POST_ROUTE}/${item.post._id}`;
    }
    if (item && item.deal && positions && !item.deal.block) {
      mainTxt += `:\u00A0\n${window.location.origin}${routes.DEALS_DETAILS_ROUTE}/${
        item.deal && item.deal._id ? item.deal._id : item.deal
      }`;
    }
    if (item && item.law && positions && !item.law.block) {
      mainTxt += `:\u00A0\n${window.location.origin}${routes.LAWS_DETAILS_ROUTE}/${
        item.law && item.law._id ? item.law._id : item.law
      }`;
    }
    if (item && item.voting && positions && !item.voting.block) {
      mainTxt += `:\u00A0\n${window.location.origin}${routes.VOTING_DETAILS_ROUTE}/${
        item.voting && item.voting._id ? item.voting._id : item.voting
      }`;
    }
    if (item && item.income && positions && !item.income._id) {
      mainTxt += `:\u00A0\n${window.location.origin}${routes.GG_INCOMES_DETAILS_ROUTE}/${item.income._id}`;
    }
    if (item && item.expense && positions && !item.expense._id) {
      mainTxt += `:\u00A0\n${window.location.origin}${routes.GG_EXPENSES_DETAILS_ROUTE}/${item.expense._id}`;
    }
    if (item && item.community && !item.community.block) {
      mainTxt += `:\u00A0\n${window.location.origin}${routes.COMMUNITY_PROFILE_ROUTE}/${item.community._id}`;
    }
    if (item && item.votingHistory && positions && !item.votingHistory.block) {
      mainTxt += `:\u00A0\n${window.location.origin}${routes.VOTING_HISTORY}/${item.votingHistory._id}`;
    }
    if (item.userRef && positions && !item.userRef.block) {
      mainTxt += `:\u00A0\n${window.location.origin}${routes.PROFILE_ROUTE}/${item.userRef._id}`;
    }
    navigator.clipboard.writeText(mainTxt);
    showAlert(true, 'success', i18n.t('MESSAGES.copySuccess'));
  };
  // console.log("item: ", item)
  // const decryptedContent = decrypt(item.content);
  // console.log("decryptedContent: ", decryptedContent)

  const showAlert = (isOpen, type, content) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  return (
    <div
      ref={rvlRef}
      className='MessageItem__Container'
      style={{
        display: 'flex',
        justifyContent: inner ? 'flex-end' : 'flex-start',
        width: '100%',
      }}
      onClick={handleClick}
      onContextMenu={handleClick}
    >
      <div
        className='MessageItem__Item'
        style={{
          backgroundColor: inner
            ? darkMode
              ? selected
                ? FEDERAL_BLUE_SOFT
                : FEDERAL_BLUE
              : selected
              ? FEDERAL_LIGTH_BLUE
              : FEDERAL_WHITE
            : darkMode
            ? selected
              ? '#357'
              : UNSATURATED_BLACK_BLUE
            : selected
            ? 'rgb(180,200,250)'
            : EASY_BLUE,
          width:
            isSmall &&
            (item.post ||
              item.deal ||
              item.law ||
              item.voting ||
              item.income ||
              item.expense ||
              item.community ||
              item.votingHistory)
              ? '100%'
              : 'calc(100% - 100px)',
        }}
        onMouseDown={(e) => {
          if (e.nativeEvent.button === 2 || e.type === 'rightclick') {
            setSelected(true);
          } else {
            setSelected(false);
          }
        }}
        onMouseUp={() => setSelected(false)}
      >
        <div className='MessageItem__Header'>
          <div className='MessageItem__Title'>
            <div className='MessageItem__UserImage-container'>
              <UserProfile
                borderColor={'#0000'}
                containerStyle={{ width: '45px', height: '45px' }}
                uri={item?.user?.picture}
                position={position}
                onClick={() => goUserProfile(item)}
              />
              <UserName
                onClick={() => goUserProfile(item)}
                username={item.user.username}
                titleStyles={{
                  fontSize: item.user.username.length > 8 ? '1.4rem' : '1.6rem',
                  color: darkMode ? WHITE_MAIN : FEDERAL_BLUE_STRONG,
                }}
                isVerified={item.user.isVerifiedUser}
                style={{ marginLeft: 8 }}
                darkMode={darkMode}
                position={position}
              />
            </div>
            {decryptedContent ? (
              decryptedContent.length > 2000 ? (
                <ReadMoreTxt
                  text={decryptedContent}
                  style={{
                    color: darkMode ? WHITE_MAIN : FEDERAL_BLUE_STRONG,
                    margin: '0',
                    marginTop: '5px',
                  }}
                  darkMode={darkMode}
                  maxLength={2000}
                  readMoreColor={inner ? (darkMode ? '#CCC' : FEDERAL_BLUE) : darkMode ? WHITE_MAIN : FEDERAL_BLUE_STRONG}
                />
              ) : (
                // decryptedContent.split(/\r\n|\r|\n/).length > 50 ? (
                //   <ReadMoreLinesTxt
                //     text={decryptedContent}
                //     style={{
                //       color: darkMode ? WHITE_MAIN : FEDERAL_BLUE_STRONG,
                //       margin: '0',
                //       marginTop: '5px',
                //     }}
                //     darkMode={darkMode}
                //     readMoreColor={inner ? (darkMode ? '#CCC' : FEDERAL_BLUE) : darkMode ? WHITE_MAIN : FEDERAL_BLUE_STRONG}
                //   />
                // ) :
                <>
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE_STRONG,
                      margin: '0',
                      marginTop: '5px',
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-all',
                    }}
                  >
                    {decryptedContent}
                  </p>
                  <TranslatableText text={decryptedContent} style={{ color: darkMode ? WHITE_MAIN : '#000' }} />
                </>
              )
            ) : null}
            {item.post ? (
              item && item.post && positions && !item.post?.block ? (
                <PostCard
                  removeRefers
                  item={item.post}
                  onClick={() => history.push(`${routes.POST_ROUTE}/${item.post._id}`)}
                  inner
                  style={{
                    width: '100%',
                    margin: '0',
                    border: `1px solid ${inner ? 'transparent' : FEDERAL_BLUE}`,
                  }}
                  user={user}
                  darkMode={darkMode}
                  stats={{ positions, users }}
                />
              ) : (
                <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.postNotFound')}
                </p>
              )
            ) : item.post === null ? (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.postNotFound')}
              </p>
            ) : null}
            {item.deal ? (
              item.deal && positions && !item.deal.block ? (
                <div style={{ width: '100%' }}>
                  <VotingItem
                    item={item.deal}
                    removeRefers
                    positions={positions}
                    darkMode={darkMode}
                    user={user}
                    hideMenu
                    isDeal
                    isDone
                    positionsPeriodDays={positionsPeriodDays}
                    votingExpiresPeriodDays={votingExpiresPeriodDays}
                    onClick={() =>
                      history.push(`${routes.DEALS_DETAILS_ROUTE}/${item.deal && item.deal._id ? item.deal._id : item.deal}`)
                    }
                  />
                </div>
              ) : (
                <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.dealNotFound')}
                </p>
              )
            ) : item.deal === null ? (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.dealNotFound')}
              </p>
            ) : null}
            {item.law ? (
              item.law && positions && !item.law.block ? (
                <div style={{ width: '100%' }}>
                  <VotingItem
                    item={item.law}
                    inner
                    removeRefers
                    positions={positions}
                    darkMode={darkMode}
                    user={user}
                    hideMenu
                    isLaw
                    isDone
                    onClick={() =>
                      history.push(`${routes.LAWS_DETAILS_ROUTE}/${item.law && item.law._id ? item.law._id : item.law}`)
                    }
                    positionsPeriodDays={positionsPeriodDays}
                    votingExpiresPeriodDays={votingExpiresPeriodDays}
                  />
                </div>
              ) : (
                <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.lawNotFound')}
                </p>
              )
            ) : item.law === null ? (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.lawNotFound')}
              </p>
            ) : null}
            {item.voting ? (
              item.voting && positions && !item.voting.block ? (
                // <div style={{ width: '100%' }}>
                <VotingItem
                  item={item.voting}
                  inner
                  removeRefers
                  positions={positions}
                  darkMode={darkMode}
                  user={user}
                  hideMenu
                  onClick={() =>
                    history.push(
                      `${routes.VOTING_DETAILS_ROUTE}/${item.voting && item.voting._id ? item.voting._id : item.voting}`
                    )
                  }
                  positionsPeriodDays={positionsPeriodDays}
                  votingExpiresPeriodDays={votingExpiresPeriodDays}
                />
              ) : (
                <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.votingIsover')}
                </p>
              )
            ) : item.voting === null ? (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.votingNotFound')}
              </p>
            ) : null}
            {item.income ? (
              item.income && item.income._id ? (
                <IncomesItem
                  hideMenu
                  item={item.income}
                  darkMode={darkMode}
                  onClick={() => history.push(`${routes.GG_INCOMES_DETAILS_ROUTE}/${item.income._id}`)}
                  user={user}
                />
              ) : (
                <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.incomeNotFound')}
                </p>
              )
            ) : item.income === null ? (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.incomeNotFound')}
              </p>
            ) : null}
            {item.expense ? (
              item.expense && item.expense._id ? (
                <IncomesItem
                  hideMenu
                  isExpense
                  item={item.expense}
                  darkMode={darkMode}
                  onClick={() => history.push(`${routes.GG_EXPENSES_DETAILS_ROUTE}/${item.expense._id}`)}
                  user={user}
                />
              ) : (
                <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.expenseNotFound')}
                </p>
              )
            ) : item.expense === null ? (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.expenseNotFound')}
              </p>
            ) : null}
            {item.community ? (
              item.community && item.community._id ? (
                <CommunityItem
                  isMsg
                  inner
                  item={item.community}
                  darkMode={darkMode}
                  onClick={() => history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${item.community._id}`)}
                  user={user}
                />
              ) : (
                <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.communityNotFound')}
                </p>
              )
            ) : item.community === null ? (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.communityNotFound')}
              </p>
            ) : null}
            {item.votingHistory ? (
              item.votingHistory && positions && !item.votingHistory.block ? (
                <VotingItem
                  isHistory
                  hideMenu
                  darkMode={darkMode}
                  item={item.votingHistory}
                  voting={stats}
                  user={user}
                  positionsPeriodDays={positionsPeriodDays}
                  votingExpiresPeriodDays={votingExpiresPeriodDays}
                />
              ) : (
                <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.votingIsover')}
                </p>
              )
            ) : item.votingHistory === null ? (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.votingIsover')}
              </p>
            ) : null}
            {item.userRef ? (
              item.userRef && positions && !item.userRef.block ? (
                <div style={{ width: '100%' }}>
                  <UserRef item={item.userRef} history={props.history} darkMode={darkMode} positions={positions} />
                </div>
              ) : (
                <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.userNotFound')}
                </p>
              )
            ) : item.userRef === null ? (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.userNotFound')}
              </p>
            ) : null}
          </div>
        </div>
        {creationDate ? (
          <p
            style={Object.assign(
              {
                width: '100%',
                marginBottom: '0',
                textAlign: 'right',
                fontSize: '.95rem',
                color: darkMode ? '#FFFA' : '#000A',
              },
              String(item.user?._id) === String(user?._id) ? { width: '90%' } : {}
            )}
          >
            {moment(item.creationDate).format('MM-DD hh:mm A')}
          </p>
        ) : null}
        {String(item.user?._id) === String(user?._id) ? (
          item.recipentUserSeen ? (
            <img src={doubleCheckIcon} style={{ width: 15, height: 15, position: 'absolute', right: 10, bottom: 10 }} alt='' />
          ) : (
            <img src={checkIcon} style={{ width: 15, height: 15, position: 'absolute', right: 10, bottom: 10 }} alt='' />
          )
        ) : null}
      </div>
      <OptionModal
        isOpen={menu}
        onRequestClose={() => setMenu(false)}
        show={{
          removeMsg: {
            display: remove ? true : false,
            onClick: remove ? () => removeMsg() : null,
          },
          removeAllMsg: {
            display: item.user._id === user._id && !item.recipentUserSeen,
            onClick: item.user._id === user._id && !item.recipentUserSeen ? () => removeMsgForAll() : null,
          },
          copyMsg: {
            display: true,
            onClick: () => onCopy(),
          },
        }}
      />
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ isOpen: false, type: null, content: undefined })}
      />
    </div>
  );
};

export default MessageItem;
