import React from 'react';
import { withRouter } from 'react-router-dom';
import { like, unlike } from './commentsActions';
import thousandSeparator from '../../utils/thousandSeparator';
import i18n from '../../i18n/i18n';
import * as routes from '../../routes/routes';
import {
  LikeButton,
  UnlikeButton,
  UserName,
  UserProfile,
} from '../ornament-profile/profileUtils';
import {
  ReadMoreLinesTxt,
  ReadMoreTxt,
} from '../../utils/text-utils/text-utils';
import Alert from '../../utils/alert/Alert';

import './commentItem.css';
import MenuButton from '../menu-button/MenuButton';
import OptionModal from '../options-modal/optionsModal';
import { deleteComment } from '../../pages/post-comments/postCommentsActions';
import { FEDERAL_BLUE, FEDERAL_GREEN, UNSATURATED_BLACK_BLUE, WHITE_DARK, WHITE_MAIN } from '../../utils/color-palatte';
import { AppLoader } from '../../utils/animations/animations';
import { handlePostDate } from '../../utils/handlePostDate';

class CommentItem extends React.Component {
  state = {
    likeLoading: false,
    liked: false,
    unliked: false,
    actionsLoader: false,
  };

  goUserProfile = (user) => {
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId === user._id) {
      this.props.history.push(routes.PROFILE_ROUTE);
    } else {
      this.props.history.push(
        `${routes.PROFILE_ROUTE}/${user._id}`
      );
    }
  };

  remove = () => {
    this.setState(
      {
        isModalOpen: true,
        modalType: 'warning',
        modalTitle: 'please wait',
        actionsLoader: true,
      },
      async () => {
        const { _id, user, postId, removeComment } = this.props;
        try {
          await removeComment(_id, user._id, postId);
          this.setState({
            isModalOpen: false,
            actionsLoader: false,
          });
        } catch (e) {
          // console.log(e);
          this.setState({ isModalOpen: false, actionsLoader: false });
        }
      }
    );
  };

  report = () => {
    this.setState(
      {
        isModalOpen: true,
        modalType: 'warning',
        modalTitle: 'please wait',
        actionsLoader: true,
      },
      async () => {
        const { _id, reportComment } = this.props;
        try {
          await reportComment(_id);
          this.setState({
            isModalOpen: false,
            actionsLoader: false,
          });
        } catch (e) {
          // console.log(e);
          this.setState({ isModalOpen: false, actionsLoader: false });
        }
      }
    );
  };

  like = (postId) => {
    const { loggedUser } = this.props;
    this.setState({ likeLoading: true }, async () => {
      try {
        await like(loggedUser._id, postId);
        this.setState({ likeLoading: false, liked: true });
      } catch (e) {
        // console.log(e)
        const error =
          e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        this.setState({
          likeLoading: false,
        });
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          return;
        }
        this.showAlert(true, 'error', undefined, error, false);
      }
    });
  };

  unlike = (postId) => {
    const { loggedUser } = this.props;
    this.setState({ likeLoading: true }, async () => {
      try {
        await unlike(loggedUser._id, postId);
        this.setState({ likeLoading: false, unliked: true });
      } catch (e) {
        // console.log(e)
        const error =
          e?.data?.error?.message || i18n.t('GENERAL_ERRORS.serverError');
        this.setState({
          likeLoading: false,
        });
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          return;
        }
        this.showAlert(true, 'error', undefined, error, false);
      }
    });
  };

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  showOptions = (isOpen) => {
    this.setState({
      optionsOpen: isOpen,
    });
  };

  onDeleteCall = () => {
    this.showOptions(false);
    this.showAlert(
      true,
      'warning',
      i18n.t('COMMENTS.MODAL.title'),
      i18n.t('COMMENTS.MODAL.deleteComment.message'),
      true,
      () => this.setState({ isModalOpen: false }),
      'NO',
      () => this.setState({ isModalOpen: false }, () => this.remove()),
      i18n.t('COMMENTS.MODAL.yes'),
      undefined,
      undefined,
      false
    );
  };

  onReportCall = () => {
    this.showOptions(false);
    this.showAlert(
      true,
      'warning',
      i18n.t('COMMENTS.MODAL.title'),
      i18n.t('COMMENTS.MODAL.reportComment.message'),
      true,
      () => this.setState({ isModalOpen: false }),
      'NO',
      () => this.setState({ isModalOpen: false }, () => this.report()),
      i18n.t('COMMENTS.MODAL.yes'),
      undefined,
      undefined,
      false
    );
  };

  render() {
    const {
      _id,
      // block,
      content,
      // creationDate,
      likeCount,
      likes,
      // post,
      // postId,
      // reports,
      unlikeCount,
      unlikes,
      user,
      // hideMenu,
      reportComment,
      // removeComment,
      darkMode,
      positions,
      loggedUser,
      creationDate,
      isCommentable = true,
    } = this.props;

    // console.log(this.props.positions);

    const { likeLoading, liked, unliked } = this.state;

    const isPostLiked =
      loggedUser && likes
        ? likes.filter(
          (userId) => userId.toString() === loggedUser._id.toString()
        ).length > 0
        : false;

    const isPostunLiked =
      loggedUser && unlikes
        ? unlikes.filter(
          (userId) => userId.toString() === loggedUser._id.toString()
        ).length > 0
        : false;

    const postUserId = user && user._id ? user._id : '';

    const position =
      positions &&
        positions.filter((position) => position.user._id === postUserId).length >
        0
        ? positions.filter((position) => position.user._id === postUserId)[0]
          .position
        : null;

    return (
      <div
        className='CommentItem__Container'
        style={{
          backgroundColor: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_DARK,
        }}
      >
        {reportComment && (
          <MenuButton
            isBlue={!darkMode}
            style={{
              position: 'absolute',
              right: '0',
              top: '0',
              marginTop: '10px',
              marginRight: '10px',
            }}
            onClick={() => this.showOptions(true)}
          />
        )}
        <div className='CommentItem__Header'>
          <UserProfile
            uri={user?.picture}
            position={position}
            containerStyle={{ width: '40px', height: '40px' }}
            onClick={() => this.goUserProfile(user)}
          />
          <div className='CommentItem__Content'>
            <UserName
              username={user?.username}
              position={position}
              darkMode={darkMode}
              titleStyles={{
                fontSize: '1.3em',
                color: darkMode ? WHITE_MAIN : '#000',
              }}
              onClick={() => this.goUserProfile(user)}
            />
            <div className='CommentItem__Content-text'>
              {content?.length > 300 ? (
                <ReadMoreTxt
                  text={content}
                  className='CommentItem__Text'
                  darkMode={darkMode}
                  style={{ color: darkMode ? WHITE_MAIN : '#000' }}
                />
              ) : content.split(/\r\n|\r|\n/).length > 2 ? (
                <ReadMoreLinesTxt
                  text={content}
                  darkMode={darkMode}
                  className='CommentItem__Text'
                  style={{ color: darkMode ? WHITE_MAIN : '#000' }}
                />
              ) : (
                <p
                  className='CommentItem__Text'
                  style={{ color: darkMode ? WHITE_MAIN : '#000' }}
                >
                  {content}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className='CommentItem__Like-area'>
          {isCommentable ? (
            <div className='CommentItem__Like-Button'>
              {isPostLiked || isPostunLiked || liked || unliked ? (
                <>
                  <LikeButton
                    liked={
                      likes.filter(
                        (currentUser) => currentUser === loggedUser._id
                      ).length > 0 || liked
                        ? true
                        : false
                    }
                    counts={thousandSeparator(
                      liked ? Number(likeCount || 0) + 1 : likeCount || 0
                    )}
                    style={{ marginRight: '25px' }}
                    counterStyle={{ margin: '0' }}
                    imgStyle={{ width: '20px' }}
                    darkMode={darkMode}
                  />
                  <UnlikeButton
                    unliked={
                      unlikes.filter(
                        (currentUser) => currentUser === loggedUser._id
                      ).length > 0 || unliked
                        ? true
                        : false
                    }
                    counts={thousandSeparator(
                      unliked ? Number(unlikeCount || 0) + 1 : unlikeCount || 0
                    )}
                    counterStyle={{ margin: '0' }}
                    imgStyle={{ width: '20px' }}
                    darkMode={darkMode}
                  />
                </>
              ) : !isPostLiked && !isPostunLiked ? (
                likeLoading ? (
                  <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} size='small' />
                ) : (
                  <>
                    <LikeButton
                      counterStyle={{ margin: '0' }}
                      onClick={() => this.like(_id)}
                      style={{ cursor: 'pointer', marginRight: '30px' }}
                      imgStyle={{ width: '20px' }}
                      darkMode={darkMode}
                    />
                    <UnlikeButton
                      counterStyle={{ margin: '0' }}
                      onClick={() => this.unlike(_id)}
                      style={{ cursor: 'pointer ' }}
                      imgStyle={{ width: '20px' }}
                      darkMode={darkMode}
                    />
                  </>
                )
              ) : null}
              <p
                className='PostCard__User-PostDate'
                style={{ color: darkMode && FEDERAL_GREEN, position: 'absolute', bottom: 10, right: 10, fontSize: 12 }}
              >
                {handlePostDate(creationDate)}
              </p>
            </div>
          ) : null}
        </div>
        <Alert
          isOpen={this.state.isModalOpen}
          type={this.state.modalType}
          title={this.state.modalTitle}
          content={this.state.modalContent}
          choose={this.state.modalChoose}
          NOClick={this.state.noAction}
          noButtonText={this.state.noText}
          YESClick={this.state.yesAction}
          yesButtonText={this.state.yesText}
          singleButtonText={this.state.btnTxt}
          onRequestClose={this.state.btnAction}
          loading={this.state.actionsLoader}
        />
        {loggedUser && reportComment && deleteComment ? (
          <OptionModal
            isOpen={this.state.optionsOpen}
            onRequestClose={() => this.setState({ optionsOpen: false })}
            show={{
              delete: {
                display:
                  loggedUser && user._id === loggedUser._id ? true : false,
                title: i18n.t('COMMENTS.MODAL.deleteComment.title'),
                onClick: () => this.onDeleteCall(),
              },
              report: {
                display:
                  loggedUser && user._id !== loggedUser._id ? true : false,
                title: i18n.t('COMMENTS.MODAL.reportComment.title'),
                onClick: () => this.onReportCall(),
              },
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(CommentItem);
