import { 
    postData,
  } from '../../fetch';

  export const createVotingRequest = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await postData('/voting/create-voting-web', data);
        resolve(response);
        resolve();
      } catch (e) {
        reject(e);
      }
    })
  };