import React, { useEffect, useRef, useState } from 'react';
import { useVideoPlayer } from '../../hooks/AppHooks';
import PlayBG from '../../assets/app-video/play_button_main.svg';
import PlayBGBlue from '../../assets/app-video/play_button_main_hover.svg';
import PlayBtn from '../../assets/app-video/btn_play.svg';
import PlayBtnBlue from '../../assets/app-video/btn_play_hover.svg';
import PauseBtn from '../../assets/app-video/btn_paused.svg';
import PauseBtnBlue from '../../assets/app-video/btn_paused_hover.svg';
import SoundBtn from '../../assets/app-video/btn_sound.svg';
import SoundBtnBlue from '../../assets/app-video/btn_sound_hover.svg';
import MuteBtn from '../../assets/app-video/btn_muted.svg';
import MuteBtnBlue from '../../assets/app-video/btn_muted_hover.svg';
import LoopBtn from '../../assets/app-video/btn_loop.svg';
import LoopBtnBlue from '../../assets/app-video/btn_loop_hover.svg';
import UnLoopBtn from '../../assets/app-video/btn_loop_disabled.svg';
import UnLoopBtnBlue from '../../assets/app-video/btn_loop_disabled_hover.svg';
import FullScreenButtonBlue from '../../assets/app-video/btn_full_screen_hover.svg';
import FullScreenButton from '../../assets/app-video/btn_full_screen.svg';
import MinimizeButtonBlue from '../../assets/app-video/btn_normal_screen_hover.svg';
import MinimizeButton from '../../assets/app-video/btn_normal_screen.svg';
import useFullscreenStatus from '../../hooks/useFullScreen';
import './AppVideo.css';

const VideoBtn = ({ hoverImg, defaultImg, style, className, onClick }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <img
      src={isHover ? hoverImg ?? defaultImg : defaultImg}
      alt=''
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={style}
      className={className}
      onClick={onClick}
    />
  );
};

const AppVideo = ({ source, className, style, fullScreenEnable, onError, videoStyle }) => {
  const videoElement = useRef(null);
  const videoContainer = useRef(null);
  const inputEl = React.createRef()
  let isFullScreen, setIsFullScreen;
  let errorMsg;

  try {
    [isFullScreen, setIsFullScreen] = useFullscreenStatus(videoContainer);
  } catch (e) {
    errorMsg = 'Fullscreen not supported';
    isFullScreen = false;
    setIsFullScreen = undefined;
  }

  const handleExitFullScreen = () => document.exitFullscreen();

  const [opacity, setOpacity] = useState(1);

  const [tabletMatches, setTabletMatches] = useState(
    window.matchMedia('(max-width: 600px)').matches
  );

  const [mobileMatches, setMobileMatches] = useState(
    window.matchMedia('(max-width: 450px)').matches
  );

  useEffect(() => {
    window
      .matchMedia('(max-width: 600px)')
      .addEventListener('change', (e) => setTabletMatches(e.matches));
    window
      .matchMedia('(max-width: 450px)')
      .addEventListener('change', (e) => setMobileMatches(e.matches));
    if (inputEl) {
      const setAttr = (e) => {
        e.style.setProperty('--value', e.value)
        e.style.setProperty('--min', e.min === '' ? '0' : e.min)
        e.style.setProperty('--max', e.max === '' ? '100' : e.max)
        e.addEventListener('input', () => e.style.setProperty('--value', e.value))
      }
      setAttr(inputEl.current)
    }
    // console.log(playerState.progress)
  });

  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    toggleLoop,
  } = useVideoPlayer(videoElement);

  return (
    <div
      ref={videoContainer}
      className={`AppVideo__Container ${className}`}
      style={Object.assign({}, style)}
      onMouseEnter={() =>
        setTimeout(() => {
          setOpacity(1);
        }, 100)
      }
      onMouseMove={() =>
        setTimeout(() => {
          setOpacity(1);
        }, 100)
      }
      onMouseLeave={() =>
        setTimeout(() => {
          setOpacity(0);
        }, 2000)
      }
    >
      <video
        className='AppVideo__Media'
        src={source}
        ref={videoElement}
        onTimeUpdate={handleOnTimeUpdate}
        onEnded={togglePlay}
        onClick={togglePlay}
        onError={onError}
        style={Object.assign({maxHeight: isFullScreen ? 'unset' : 500}, videoStyle)}
      />
      {!playerState.isPlaying && (
        <div className='AppVideo__PauseOverlay'>
          <VideoBtn
            defaultImg={PlayBG}
            hoverImg={PlayBGBlue}
            onClick={togglePlay}
            style={{
              width: tabletMatches
                ? mobileMatches
                  ? '50px'
                  : '60px'
                : undefined,
            }}
          />
        </div>
      )}
      <div
        className='AppVideo__Controls'
        style={{
          opacity:
            playerState.progress === 0 || playerState.progress === 100
              ? '1'
              : String(opacity),
        }}
        onMouseEnter={() => setOpacity(1)}
        onMouseLeave={() =>
          setTimeout(() => {
            setOpacity(0);
          }, 2000)
        }
      >
        <div className='AppVideo__Actions'>
          <button
            onClick={togglePlay}
            className='AppVideo__Play-control'
            title={playerState.isPlaying ? 'Pause' : 'Play'}
          >
            <VideoBtn
              defaultImg={playerState.isPlaying ? PauseBtn : PlayBtn}
              hoverImg={playerState.isPlaying ? PauseBtnBlue : PlayBtnBlue}
            />
          </button>
        </div>
        <input
          ref={inputEl}
          type='range'
          min='0'
          max='100'
          value={playerState.progress}
          onChange={(e) => handleVideoProgress(e)}
          className='Appvideo__ProgressBar Appvideo__SliderProgress'
          step={0.01}
        />
        <select
          className='AppVideo__Velocity-menu'
          value={playerState.speed}
          onChange={(e) => handleVideoSpeed(e)}
          title='velocity'
        >
          <option value='0.50'>0.50x</option>
          <option value='1'>1x</option>
          <option value='1.25'>1.25x</option>
          <option value='2'>2x</option>
        </select>
        {fullScreenEnable ? (
          errorMsg ? (
            <button onClick={() => alert('FullScreenNotSupported')}>
              {errorMsg}
            </button>
          ) : (
            <button
              onClick={isFullScreen ? handleExitFullScreen : setIsFullScreen}
              className='AppVideo__FullScreen'
              title={isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
            >
              <VideoBtn
                defaultImg={isFullScreen ? MinimizeButton : FullScreenButton}
                hoverImg={
                  isFullScreen ? MinimizeButtonBlue : FullScreenButtonBlue
                }
              />
            </button>
          )
        ) : null}
        <button
          className='AppVideo__Loop'
          onClick={toggleLoop}
          title={playerState.isLooped ? 'Stop Loop' : 'Loop'}
        >
          <VideoBtn
            defaultImg={playerState.isLooped ? LoopBtn : UnLoopBtn}
            hoverImg={playerState.isLooped ? LoopBtnBlue : UnLoopBtnBlue}
          />
        </button>
        <button
          className='AppVideo__Mute'
          onClick={toggleMute}
          title={playerState.isMuted ? 'Unmute' : 'mute'}
        >
          <VideoBtn
            defaultImg={playerState.isMuted ? MuteBtn : SoundBtn}
            hoverImg={playerState.isMuted ? MuteBtnBlue : SoundBtnBlue}
          />
        </button>
      </div>
    </div>
  );
};

export default AppVideo;
