import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as routes from '../../routes/routes';
import './globalGovernment.css';
import getStat from '../../utils/getStat';
import { isUserValid } from '../../utils/isUserValid';
import OrnamentProfile from '../../components/ornament-profile/ornament-profile';
import { ProfileButton } from '../profile/Profile';
import BkIconCitizen from '../../assets/global-government/icon_citizen.svg';
import BkIconAggrements from '../../assets/global-government/bk_icon_aggrements.svg';
import BkIconFlow from '../../assets/global-government/bk_icon_flow.svg';
import BkIconLaws from '../../assets/global-government/bk_icon_laws.svg';
import BkIconVotes from '../../assets/global-government/bk_icon_votes.svg';
import BkIconMoney from '../../assets/global-government/icon_money_increase_colored.svg';
import BkIconServices from '../../assets/global-government/icon_services.svg';
import BkIconFinances from '../../assets/global-government/icon_finances.svg';
import BkIconFinancesWhite from '../../assets/global-government/icon_finances_white.svg';
import BkIconHierarchy from '../../assets/global-government/icon_hierarchy.svg';
import BkIconHierarchyWhite from '../../assets/global-government/icon_hierarchy_white.svg';
import ProfilePicture from '../../assets/global-government/gg_profile_img.png';
import { thousandSeparator } from '../../utils/thousandSeparator';
import { FEDERAL_BLUE, FEDERAL_GREEN } from '../../utils/color-palatte';
import { AppLoader } from '../../utils/animations/animations';
import { Translation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { Warning } from '../../components/warning/Warning';
import InfoModal from '../../components/info-modals/info-modals';
import { ParsedText } from '../../utils/text-utils/text-utils';

// const GSpan = ({ children, onClick }) => (
//   <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={onClick}>
//     {children}
//   </BSpan>
// );

class GlobalGovernment extends React.Component {
  state = {
    loading: true,
    refresh: false,
    isSideMenuOpen: false,
    laws: null,
    users: null,
    capital: null,
    positions: null,
    deals: null,
    officialVotings: null,
    noOfficialVotings: null,
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(false, this._isMounted);
    if (this._isMounted) await this.getStats();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true }, async () => {
        try {
          const positions = await getStat('positions');
          const laws = await getStat('laws');
          const users = await getStat('users');
          const deals = await getStat('deals');
          const capital = await getStat('capital');
          const officialVotings = await getStat('official-votings');
          const noOfficialVotings = await getStat('no-official-votings');
          const maintenanceTaxes = await getStat('maintenance-taxes');
          const presidentSalaryPorcentage = await getStat('president-salary-porcentage');
          const vicepresidentSalaryPorcentage = await getStat('vicepresident-salary-porcentage');
          // console.log("officialVotings: ", officialVotings);
          this.setState({
            officialVotings,
            noOfficialVotings,
            users,
            laws,
            deals,
            positions,
            loading: false,
            refresh: false,
            capital,
            maintenanceTaxes,
            presidentSalaryPorcentage,
            vicepresidentSalaryPorcentage,
          });
          resolve();
        } catch (e) {
          reject(e);
          // console.log("getStats / error: ", e);
        }
      });
    });
  };

  goUserProfile = (userId) => {
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId === userId) {
      this.props.history.push(routes.PROFILE_ROUTE);
    } else {
      this.props.history.push(`${routes.PROFILE_ROUTE}/${userId}`);
    }
  };

  showInfo = (isOpen, type) => {
    this.setState({
      isInfoOpen: isOpen,
      infoType: type,
    });
  };

  render() {
    const {
      loading,
      users,
      officialVotings,
      noOfficialVotings,
      laws,
      deals,
      capital,
      positions,
      maintenanceTaxes,
      // presidentPicture,
      // vicepresidentPicture,
      presidentSalaryPorcentage,
      vicepresidentSalaryPorcentage,
    } = this.state;
    const { darkMode } = this.props;

    let president = null;
    let vicepresident = null;

    if (positions && positions.length > 0) {
      if (positions.filter((item) => item.position === 'president').length > 0) {
        president = positions.filter((item) => item.position === 'president')[0];
      }
      if (positions.filter((item) => item.position === 'vicepresident').length > 0) {
        vicepresident = positions.filter((item) => item.position === 'vicepresident')[0];
      }
    }

    // console.log("%cData:", "color: #0f0; font-size: 1.1rem;", this.state);

    const ProfileItems = [
      {
        number: loading ? (
          <AppLoader size='40px' color={darkMode ? '#FFF' : FEDERAL_BLUE} />
        ) : (
          thousandSeparator(Number(users) || 0)
        ),
        title: i18n.t('GENERAL_TEXT.citizens'),
        picture: BkIconCitizen,
        onClick: () => null,
      },
      {
        number: loading ? (
          <AppLoader size='40px' color={darkMode ? '#FFF' : FEDERAL_BLUE} />
        ) : (
          thousandSeparator(Number(officialVotings) || 0 + Number(noOfficialVotings) || 0)
        ),
        title: i18n.t('GENERAL_TEXT.votings'),
        picture: BkIconVotes,
        onClick: () => this.props.history.push(routes.VOTING_LIST_ROUTE),
      },
      {
        number: loading ? (
          <AppLoader size='40px' color={darkMode ? '#FFF' : FEDERAL_BLUE} />
        ) : (
          thousandSeparator(Number(laws) || 0)
        ),
        title: i18n.t('GENERAL_TEXT.laws'),
        picture: BkIconLaws,
        onClick: () => this.props.history.push(routes.LAWS_LIST_ROUTE),
      },
      {
        number: loading ? (
          <AppLoader size='40px' color={darkMode ? '#FFF' : FEDERAL_BLUE} />
        ) : (
          thousandSeparator(Number(deals) || 0)
        ),
        title: i18n.t('GENERAL_TEXT.deals'),
        picture: BkIconAggrements,
        onClick: () => this.props.history.push(routes.DEALS_LIST_ROUTE),
      },
      {
        number: loading ? <AppLoader size='40px' color={darkMode ? '#FFF' : FEDERAL_BLUE} /> : thousandSeparator(Number(0)),
        title: i18n.t('GENERAL_TEXT.services'),
        picture: BkIconServices,
        onClick: () => this.props.history.push(routes.SERVICES_ROUTE),
      },
      {
        number: loading ? (
          <AppLoader size='40px' color={darkMode ? '#FFF' : FEDERAL_BLUE} />
        ) : (
          `${thousandSeparator(Number(capital) || 0)}$`
        ),
        title: i18n.t('GENERAL_TEXT.balance'),
        picture: BkIconMoney,
        onClick: () => this.props.history.push(routes.GG_FINANCE_ROUTE),
      },
      {
        titleStyle: { marginTop: 30, fontSize: '1.4rem' },
        title: i18n.t('GENERAL_TEXT.flow'),
        picture: BkIconFlow,
        onClick: () => this.props.history.push(routes.FLOW_ROUTE),
      },
    ];

    const values = [
      {
        keyword: `${
          maintenanceTaxes && presidentSalaryPorcentage && vicepresidentSalaryPorcentage
            ? 100 - (Number(maintenanceTaxes) + Number(vicepresidentSalaryPorcentage) + Number(presidentSalaryPorcentage))
            : '...'
        }%`,
        style: { color: '#fff', cursor: 'normal', fontWeight: 'bold' },
      },
      {
        keyword: `${maintenanceTaxes ? maintenanceTaxes : '...'}%`,
        style: { color: '#fff', cursor: 'normal', fontWeight: 'bold' },
      },
      {
        keyword: `${presidentSalaryPorcentage ? presidentSalaryPorcentage : '...'}%`,
        style: { color: '#fff', cursor: 'normal', fontWeight: 'bold' },
      },
      {
        keyword: `${vicepresidentSalaryPorcentage ? vicepresidentSalaryPorcentage : '...'}%`,
        style: { color: '#fff', cursor: 'normal', fontWeight: 'bold' },
      },
    ];

    return (
      <Translation>
        {(t) => (
          <div className='GG__Container'>
            <div className='GG__Banner'>
              <OrnamentProfile
                userPicture={ProfilePicture}
                className='GG__BKOrnament'
                style={{
                  width: 'unset',
                  bottom: 0,
                  marginBottom: '-140px',
                  textAlign: 'center',
                }}
                userName='Global Government'
                userRank=' '
                userNameStyles={{
                  color: darkMode ? '#FFF' : '#000',
                  fontWeight: 'bold',
                  fontSize: '1.8rem',
                }}
                userRankStyles={{
                  color: darkMode ? '#FFF' : '#000',
                  fontWeight: 'unset',
                  textTransform: 'unset',
                  fontSize: '1.5rem',
                }}
              />
            </div>
            <div className='GG__Content'>
              <div
                className='GG__Data-Container'
                style={{
                  borderBottom: `1px solid ${darkMode ? '#FFF' : FEDERAL_BLUE}`,
                }}
              >
                <div className='GG__Data-buttons'>
                  <button className='GG__SideBtn GG__SideBtn1' onClick={() => this.props.history.push(routes.GG_HIERARCHY_ROUTE)}>
                    <p style={{ color: darkMode ? '#FFF' : FEDERAL_BLUE }}>{t('GLOBAL_GOVERNMENT.BUTTONS.herarchy')}</p>
                    <img src={darkMode ? BkIconHierarchyWhite : BkIconHierarchy} alt='' />
                  </button>
                  <button className='GG__SideBtn GG__SideBtn2' onClick={() => this.props.history.push(routes.GG_FINANCE_ROUTE)}>
                    <p style={{ color: darkMode ? '#FFF' : FEDERAL_BLUE }}>{t('GLOBAL_GOVERNMENT.BUTTONS.finances')}</p>
                    <img src={darkMode ? BkIconFinancesWhite : BkIconFinances} alt='' />
                  </button>
                </div>
                <p
                  style={{
                    color: darkMode ? FEDERAL_GREEN : FEDERAL_BLUE,
                    marginTop: '40px',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  {`${thousandSeparator(Number(users) || "...")} ${t('GENERAL_TEXT.citizens')}`}
                </p>
              </div>
              <div className='GG__Positions'>
                <OrnamentProfile
                  className='GG__PosotionOrnament'
                  style={{ width: 'unset', minWidth: '200px', cursor: president ? 'pointer' : 'default' }}
                  onClick={() =>
                    president?.user?._id ? this.props.history.push(`${routes.PROFILE_ROUTE}/${president?.user?._id}`) : null
                  }
                  userPicture={president?.user?.picture}
                  userRank={president?.position ?? 'president'}
                  userRankStyles={{
                    color: darkMode ? '#FFF' : '#000',
                    fontWeight: 'bold',
                    textTransform: 'unset',
                    fontSize: '1.5rem',
                  }}
                />
                <OrnamentProfile
                  className='GG__PosotionOrnament'
                  onClick={() =>
                    vicepresident?.user?._id
                      ? this.props.history.push(`${routes.PROFILE_ROUTE}/${vicepresident?.user?._id}`)
                      : null
                  }
                  style={{ width: 'unset', minWidth: '200px', cursor: vicepresident ? 'pointer' : 'default' }}
                  userPicture={vicepresident?.user.picture}
                  userRank={vicepresident?.position ?? 'vicepresident'}
                  userRankStyles={{
                    color: darkMode ? '#FFF' : '#000',
                    fontWeight: 'bold',
                    textTransform: 'unset',
                    fontSize: '1.5rem',
                  }}
                />
              </div>
              <div className='GG__ProfBtn'>
                {ProfileItems.map((item, i) => (
                  <ProfileButton {...item} key={i} darkMode={darkMode} />
                ))}
              </div>
              <div />
            </div>
            <Warning
              darkMode={darkMode}
              title={t('GLOBAL_GOVERNMENT.IMPORTANT.title')}
              textCotent={
                <ParsedText
                  string={t('GLOBAL_GOVERNMENT.IMPORTANT.content')(
                    maintenanceTaxes,
                    presidentSalaryPorcentage,
                    vicepresidentSalaryPorcentage
                  )}
                  style={{ whiteSpace: 'pre-line', textAlign: 'center', color: '#fff' }}
                  values={values}
                  hasWiki
                  markStyle={{ fontWeight: 'bold', color: FEDERAL_GREEN }}
                />
              }
            />
            {/* <div className="GG__ImportantText">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: 3,
                  backgroundColor: "#fff",
                  margin: "10px 0",
                  borderRadius: "3px",
                }}
              >
                <h3>{t('GLOBAL_GOVERNMENT.IMPORTANT.title')}</h3>
              </div>
              <p>
              {t('GLOBAL_GOVERNMENT.IMPORTANT.content')}
              </p>
            </div> */}
            <InfoModal
              isOpen={this.state.isInfoOpen}
              type={this.state.infoType}
              onRequestClose={() =>
                this.setState({
                  isInfoOpen: false,
                })
              }
            />
          </div>
        )}
      </Translation>
    );
  }
}

const GGWithRouter = withRouter(GlobalGovernment);

const mapStateToProps = (state) => {
  const { darkMode } = state.user;
  return {
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GGWithRouter);
