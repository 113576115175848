import { getData } from '../fetch';

export const updateDashboardPosts = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem('userId');
      const data = {
        userId: userId,
        skipItems: 0,
        limitItems: 5
      }
      const response = await getData(
        `/post/get-posts-web/`,
        data
      );
      // console.log("getPosts / response: ", response);
      const posts = response.body;
      return resolve(posts);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  });
};

export default updateDashboardPosts;
