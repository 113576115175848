import React, { useState } from 'react';
import { Trans, Translation } from 'react-i18next';
import i18n from '../../../i18n/i18n';
import BenefitsImg from '../../../assets/home/benefits/benefits.png';
import CheckList from '../../../assets/home/benefits/check.png';
import { BSpan } from '../../../utils/text-utils/text-utils';
import './benefits.css';
import InfoModal from '../../info-modals/info-modals';
import { FEDERAL_BLUE } from '../../../utils/color-palatte';

const Benefits = () => {
  const [modal, setModal] = useState({
    isOpen: false,
    type: null,
  });

  const openModal = (isOpen, type) => {
    setModal({
      isOpen: isOpen,
      type: type,
    });
  };

  const CheckListItems = ({ text }) => (
    <div className='itemList'>
      <img src={CheckList} alt='check' />
      <p>{text}</p>
    </div>
  );

  const ListItems = [
    {
      text: i18n.t('BENEFITS.li1'),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li2'>
          bla
          <BSpan
            onClick={() => openModal(true, 'voting')}
            style={{ color: FEDERAL_BLUE }}
          >
            bla
          </BSpan>
          bla
          <BSpan
            onClick={() => openModal(true, 'law')}
            style={{ color: FEDERAL_BLUE }}
          >
            bla
          </BSpan>
          bla
          <BSpan
            onClick={() => openModal(true, 'deal')}
            style={{ color: FEDERAL_BLUE }}
          >
            bla
          </BSpan>
          bla
        </Trans>
      ),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li3'>
          bla
          <BSpan
            onClick={() => openModal(true, 'citizen')}
            style={{ color: FEDERAL_BLUE }}
          >
            bla
          </BSpan>
          bla
        </Trans>
      ),
    },
    {
      text: i18n.t('BENEFITS.li4'),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li5'>
          bla
          <BSpan
            onClick={() => openModal(true, 'services')}
            style={{ color: FEDERAL_BLUE }}
          >
            bla
          </BSpan>
          bla
          <BSpan
            onClick={() => openModal(true, 'deals')}
            style={{ color: FEDERAL_BLUE }}
          >
            bla
          </BSpan>
          bla
        </Trans>
      ),
    },
  ];

  return (
    <Translation>
      {(t) => (
        <section className='Home-benefits'>
          <div className='Card-Benefit'>
            <div className='Benefits-ImgContainer'>
              <img src={BenefitsImg} alt='Benefits Img' />
            </div>
            <div className='Benefits-List'>
              <h1>{t('BENEFITS.title')}</h1>
              {ListItems.map((item, i) => (
                <CheckListItems {...item} key={i} />
              ))}
            </div>
          </div>
          <InfoModal
            isOpen={modal.isOpen}
            type={modal.type}
            onRequestClose={() => setModal({ ...modal, isOpen: false })}
          />
        </section>
      )}
    </Translation>
  );
};

export default Benefits;
