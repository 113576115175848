import CryptoJS from 'crypto-js';
import { CRYPTO_JS_SECRET_KEY } from '../config';

const decrypt = (object) => {
  return new Promise((resolve, reject) => {
    var bytes = CryptoJS.AES.decrypt(object, CRYPTO_JS_SECRET_KEY);
    const response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    resolve(response);
  });
};

const normalDecrypt = (object) => {
    var bytes = CryptoJS.AES.decrypt(object, CRYPTO_JS_SECRET_KEY);
    const response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return response;
};

const encrypt = (data) => {
  // console.log("encrypt / data: ", data);
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), String(CRYPTO_JS_SECRET_KEY)).toString();
  // console.log("encrypt / ciphertext: ", ciphertext);
  return ciphertext;
};

export { encrypt, decrypt, normalDecrypt };
