import React from 'react';
import './select-input.css';
import { FEDERAL_BLUE } from '../../color-palatte';

const SelectInput = ({
  className,
  value,
  onChange,
  isWhite,
  styles,
  selectClassName,
  name,
  disabled,
  children,
}) => {
  return (
    <div className={`SelectInput__Container ${className}`}>
      <select
        value={value}
        onChange={onChange}
        style={Object.assign(
          {
            border: `2px solid ${isWhite ? 'white' : FEDERAL_BLUE}`,
            opacity: disabled ? '0.5' : 'unset',
          },
          styles
        )}
        className={`${selectClassName} ${
          isWhite ? 'SelectInput-white' : 'SelectInput'
        }`}
        id={`${isWhite ? 'SelectInput-white' : 'SelectInput'}`}
        name={name}
        disabled={disabled}
      >
        {children}
      </select>
    </div>
  );
};

export default SelectInput;
