import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/messaging';

// Set the configuration for your app
// TODO: Replace with your app's config object
const firebaseConfig = {
  apiKey: 'AIzaSyBJd-EoQ0H1nMxK5Kf4BeZXHlmYoZOnNrc',
  authDomain: 'global-government.firebaseapp.com',
  databaseURL: 'https://global-government.firebaseio.com',
  projectId: 'global-government',
  storageBucket: 'global-government.appspot.com',
  messagingSenderId: '1055143065939',
  appId: '1:1055143065939:web:443454e5b3cc88f92849a6',
  measurementId: 'G-R2Q56JXRBS',
};
firebase.initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
var storage = firebase.storage();
// console.log("firebase.messaging.isSupported(): ", firebase.messaging.isSupported())

export const getToken = () => new Promise (async (resolve, reject) => {

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.getToken({ vapidKey: 'GENERATED_MESSAGING_KEY' }).then((currentToken) => {
      // console.log("getToken / currentToken: ", currentToken)
      resolve(currentToken)
    })
    .catch(e => {
      // console.log("getToken / error: ", e)
      reject(e)
    })
  } else {
    reject()
  }
})


export const onMessageListener = () =>
  new Promise((resolve) => {
    if (firebase.messaging.isSupported()) {
      // firebase.notifications().onNotification((notification) => {
      //   firebase.notifications().displayNotification(notification);
      //   resolve(notification)
      // });
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });

export { storage, firebase };
