import React from "react";
import SearchFilter from "../../components/search-filter/SearchFilter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as actions } from "../../redux/actions/user/actions";
import i18n from "../../i18n/i18n";
import "./communities.css";
import CommunityItem from "../../components/community/communityItem";
import { reportCommunity, getCommunities } from "./communitiesActions";
import { isUserValid } from "../../utils/isUserValid";
import { updateLoggedUser } from "../../utils/updateLoggedUser";
import ScrollableDiv from "../../components/scrollable-div/scrollableDiv";
import { AppLoader } from "../../utils/animations/animations";
import { WHITE_MAIN } from "../../utils/color-palatte";
import Alert from "../../utils/alert/Alert";
import { NotFoundItem } from "../../components/not-found-item/notFoundItem";

class Communities extends React.Component {
  state = {
    fetching: false,
    skipItems: 0,
    limitItems: 5,
    plusPostNumber: 5,
    keepIncreasingCounter: false,
    search: "",
    communities: [],
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(false, this._isMounted);
    // await updateLoggedUser(saveUser)
    if (this._isMounted) this.onRefreshCommunities();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  fetchCommunities = async () => {
    const {
      skipItems,
      limitItems,
      fetching,
      search,
      communities,
      sinceDate = "",
      toDate = "",
    } = this.state;
    if (!fetching) {
      this.setState({ fetching: true }, async () => {
        try {
          const newCommunities = await getCommunities({
            skipItems,
            limitItems,
            search,
            sinceDate,
            toDate,
          });
          // console.log("getUsersForAdmin / newCommunities: ", newCommunities);
          const finalCommunities = communities
            .concat(newCommunities)
            .filter(
              (item, index, self) =>
                index === self.findIndex((t) => t._id === item._id)
            )
            .sort((a, b) => b.reportsCount - a.reportsCount);
          this.setState({
            communities: finalCommunities,
            fetching: false,
            keepIncreasingCounter: newCommunities.length > 0,
          });
        } catch (e) {
          // console.log("fetchCommunities / error: ", e)
          if (e.status === 404) {
            this.setState({ fetching: false });
          } else {
            this.setState({ fetching: false });
            const error =
              e && e.data && e.data.error
                ? e.data.error.message
                : i18n.t("GENERAL_ERRORS.serverError");
            this.showAlert(true, "error", error);
          }
        }
      });
    }
  };

  onRefreshCommunities = () => {
    const { plusPostNumber } = this.state;
    const { saveUser } = this.props;
    this.setState(
      { skipItems: 0, communities: [], limitItems: plusPostNumber },
      async () => {
        updateLoggedUser(saveUser);
        this.fetchCommunities();
      }
    );
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } =
      this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchCommunities();
        }
      );
    } else {
      this.fetchCommunities();
    }
  };

  report = (userId) => {
    const { user } = this.props;
    this.setState({ fetching: true }, async () => {
      try {
        const response = await reportCommunity(userId, user._id);
        this.showAlert(true, "success", response);
        this.setState({ fetching: false });
        this.onRefreshCommunities();
      } catch (e) {
        // console.log("report / error: ", e);
        const error =
          e && e.data && e.data.error
            ? e.data.error.message
            : i18n.t("GENERAL_ERRORS.serverError");
        this.showAlert(true, "error", error);
        this.setState({ fetching: false });
      }
    });
  };

  setValue = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const {
      fetching,
      communities,
      // search,
      // sinceDate,
      // toDate,
    } = this.state;
    const { user, darkMode } = this.props;

    // console.log("communities: ", communities);
    // console.log("communities / user: ", user);

    return (
      <ScrollableDiv onEndReach={() => this.onEndReached()} className="Communities__Container">
        <div className="Communities__Search-bar">
          <SearchFilter
            setValue={this.setValue}
            onRefresh={this.onRefreshCommunities}
            darkMode={darkMode}
            inputPlaceholder={i18n.t("COMMUNITIES.inputPlaceholder")}
          />
        </div>
        {communities
          ? communities.map((item, i) => (
              <CommunityItem
                item={item}
                key={i}
                user={user}
                report={this.report}
              />
            ))
          : null}
        {!communities || fetching ? (
          <AppLoader color={darkMode && WHITE_MAIN} />
        ) : null}
        {/* {(search || (sinceDate && toDate)) &&
        communities.length === 0 &&
        !fetching ? (
          <NotFoundText content={i18n.t("COMMUNITIES.notFound")} />
        ) : null} */}
        {!fetching && (!communities || communities?.length === 0) ? (
          <NotFoundItem
            isBlue={darkMode}
            text={i18n.t("COMMUNITIES.notFound")}
          />
        ) : null}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        />
      </ScrollableDiv>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Communities);
