import i18n from "../i18n/i18n"

const handleVotingTypesNames = (type) => {
  if (type === "law") {
    return i18n.t('VOTING.TYPES.law')
  }
  if (type === "deal") {
    return i18n.t('VOTING.TYPES.deal')
  }
  if (type === "position") {
    return i18n.t('VOTING.TYPES.position')
  }
  if (type === "removeLaw") {
    return i18n.t('VOTING.TYPES.removeLaw')
  }
  if (type === "removePosition") {
    return i18n.t('VOTING.TYPES.removePosition')
  }
  if (type === "removeDeal") {
    return i18n.t('VOTING.TYPES.removeDeal')
  }
  if (type.toLowerCase() === "both") {
    return i18n.t('VOTING.TYPES.deal')
  }
  return type
}

export default handleVotingTypesNames;