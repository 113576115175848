import React from 'react';
import i18n from '../../i18n/i18n';
import { throttle } from 'lodash';
// import { getUserAchievements } from '../dashboard/dashboard-actions';
import { connect } from 'react-redux';
import * as colors from '../../utils/color-palatte';
// import getStat from '../../utils/getStat';
import { isUserValid } from '../../utils/isUserValid';
import ToggleIconWhite from '../../assets/user-achievements/toggle_icon_white.svg';
import ToggleIconBlue from '../../assets/user-achievements/toggle_icon_blue.svg';
import Alert from '../../utils/alert/Alert';
import { withRouter } from 'react-router-dom';
import { AppLoader } from '../../utils/animations/animations';
import AchievementItem from '../../components/achievemen-item/achievementItem';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { getData } from '../../fetch';

const CategoryButton = (props) => {
  const { title, count, darkMode, children, fetching } = props;
  const [isDropped, setIsDropped] = React.useState(false);
  const [height, setHeight] = React.useState(null);

  const rvlRef = React.createRef();

  React.useEffect(() => {
    if (rvlRef && rvlRef.current) {
      setHeight(rvlRef.current.scrollHeight);
      // console.log(height)
    }
  }, [rvlRef]);

  return (
    <div
      className='CategoryButton__Container'
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexFlow: 'column nowrap',
        width: '100%',
      }}
    >
      <div
        className='CategoryButton__Header'
        onClick={() => setIsDropped(!isDropped)}
        style={{
          cursor: 'pointer',
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '5px 15px',
          height: 45,
        }}
      >
        <div
          style={{ display: 'flex', justifyContent: 'fles-start', alignItems: 'center', marginRight: 15, width: 'max-content' }}
        >
          <p
            style={{
              color: darkMode ? colors.WHITE_MAIN : colors.UNSATURATED_BLACK_BLUE,
              margin: 0,
              textTransform: 'capitalize',
              fontSize: '1.1rem',
              alignItems: 'center',
              width: 'max-content',
              whiteSpace: 'nowrap',
              display: 'flex',
            }}
          >
            {title}{' '}
            {!fetching && (
              <span
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 'calc(40px / 2)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: `2px solid ${darkMode ? colors.WHITE_MAIN : colors.UNSATURATED_BLACK_BLUE}`,
                  marginLeft: 10,
                  fontSize: '.9rem',
                }}
              >
                {count || 0}
              </span>
            )}
          </p>
          {fetching && (
            <div
              style={{
                width: 20,
                height: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 10,
                overflow: 'hidden',
              }}
            >
              <AppLoader svgStyle={{ width: 20 }} color={darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE} />
            </div>
          )}
        </div>
        <div
          style={{ width: '100%', borderBottom: `2px solid ${darkMode ? colors.WHITE_MAIN : colors.UNSATURATED_BLACK_BLUE}` }}
        />
        <img
          src={darkMode ? ToggleIconWhite : ToggleIconBlue}
          style={{
            transform: `rotateZ(${isDropped ? 180 : 0}deg)`,
            transition: 'all .2s',
            width: 25,
            height: 25,
            marginLeft: 15,
          }}
          alt=''
        />
      </div>
      <div
        ref={rvlRef}
        className='CategoryButton__Content'
        style={{ overflow: 'hidden', height: isDropped ? height : 0, width: '100%', transition: 'all .4s' }}
      >
        {children}
      </div>
    </div>
  );
};

class UserAchievements extends React.Component {
  state = {
    fetching: false,
    skipItems: 0,
    limitItems: 5,
    plusPostNumber: 5,
    keepIncreasingCounter: false,
    search: '',
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(false, this._isMounted);
    // await this.getStats()
    if (this._isMounted) this.fetchAchievements();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  // getStats = () => {
  //   return new Promise((resolve, reject) => {
  //     this.setState({ loading: true }, async () => {
  //       try {
  //         const positions = await getStat('positions');
  //         const users = await getStat('users');
  //         // console.log("officialVotings: ", officialVotings);
  //         this.setState({
  //           positions,
  //           users,
  //         });
  //         resolve();
  //       } catch (e) {
  //         reject();
  //         // console.log("getStats / error: ", e);
  //       }
  //     });
  //   });
  // };

  fetchAchievements = () => {
    return new Promise(async (resolve, reject) => {
      const { fetching } = this.state;
      const { user } = this.props;
      const routeUserId = this.props.match.params.userId;
      const userId = routeUserId ? routeUserId : user._id;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getData(`/user/get-user-achievements-web/?userId=${userId}`);
            // console.log("getUserAchievements / response: ", response);
            const achievements = response.body;
            // const achievements = await getUserAchievements(userId, user);
            this.setState({
              achievements,
              fetching: false,
              refresh: false,
            });
            resolve();
          } catch (e) {
            if (e.status === 404) {
              this.setState({ fetching: false });
              reject();
            } else {
              // console.log("fetchAchievements / error: ", e)
              this.setState({ fetching: false });
              const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
              this.showAlert(true, 'error', error);
              this.setState({
                refresh: false,
              });
              reject();
            }
          }
        });
      }
    });
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchAchievements();
        }
      );
    } else {
      this.fetchAchievements();
    }
  };

  throtled = throttle(async () => {
    this.onRefreshPosts();
  }, 1000);

  showList = (data) => {
    const { darkMode } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          flexFlow: 'column nowrap',
          padding: '0 20px',
          boxSizing: 'border-box',
        }}
      >
        {data && data.length > 0 ? data.map((item, i) => <AchievementItem item={item} key={i} darkMode={darkMode} />) : null}
      </div>
    );
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const {
      achievements,
      fetching,
      // refresh,
      // completedActive,
      // postsApprovementsActive,
      // followersActive,
      // votingsApprovementsActive,
      // userCommunityMembers,
      // votingsAchievementsActive,
    } = this.state;
    const {
      // user,
      darkMode,
    } = this.props;
    // const { username } = this.props.match.params;
    // const bg = darkMode ? colors.DARK_OFFICIAL_BLUE : colors.FEDERAL_BLUE;
    // console.log("profilePosts: ", profilePosts);

    // console.log("user: ", user)
    const CategoryArrayData = [
      {
        title: i18n.t('ACHIEVEMENTS.completed'),
        count: achievements ? achievements.filter((achievement) => achievement.active)?.length : null,
        children: this.showList(achievements ? achievements.filter((achievement) => achievement.active) : []),
      },
      {
        title: i18n.t('ACHIEVEMENTS.followers'),
        count: achievements ? achievements.filter((achievement) => achievement.category === 'followers').length : null,
        children: this.showList(
          achievements
            ? achievements.filter((achievement) => achievement.category === 'followers').sort((a, b) => b.active - a.active)
            : []
        ),
      },
      {
        title: i18n.t('ACHIEVEMENTS.postsApprovements'),
        count: achievements ? achievements.filter((achievement) => achievement.category === 'posts-approvements').length : null,
        children: this.showList(
          achievements
            ? achievements
                .filter((achievement) => achievement.category === 'posts-approvements')
                .sort((a, b) => b.active - a.active)
            : []
        ),
      },
      {
        title: i18n.t('ACHIEVEMENTS.votingsApprovements'),
        count: achievements
          ? achievements.filter((achievement) => achievement.category === 'user-votings-approvements').length
          : null,
        children: this.showList(
          achievements
            ? achievements
                .filter((achievement) => achievement.category === 'user-votings-approvements')
                .sort((a, b) => b.active - a.active)
            : []
        ),
      },
      {
        title: i18n.t('ACHIEVEMENTS.communityMembers'),
        count: achievements
          ? achievements.filter((achievement) => achievement.category === 'user-community-members').length
          : null,
        children: this.showList(
          achievements
            ? achievements
                .filter((achievement) => achievement.category === 'user-community-members')
                .sort((a, b) => b.active - a.active)
            : []
        ),
      },
      {
        title: i18n.t('ACHIEVEMENTS.votingAchievement'),
        count: achievements ? achievements.filter((achievement) => achievement.category === 'voting-achievement').length : null,
        children: this.showList(
          achievements
            ? achievements
                .filter((achievement) => achievement.category === 'voting-achievement')
                .sort((a, b) => b.active - a.active)
            : []
        ),
      },
      {
        title: i18n.t('ACHIEVEMENTS.contributionAchievement'),
        count: achievements
          ? achievements.filter((achievement) => achievement.category === 'user-contribution-amount').length
          : null,
        children: this.showList(
          achievements
            ? achievements
                .filter((achievement) => achievement.category === 'user-contribution-amount')
                .sort((a, b) => b.active - a.active)
            : []
        ),
      },
    ];
    return (
      <div
        className='UserAchievement__Container'
        style={{
          width: '100%',
          maxWidth: 800,
          margin: '0 auto',
        }}
      >
        {CategoryArrayData.map((item, i) => (
          <CategoryButton fetching={fetching} {...item} key={i} darkMode={darkMode} />
        ))}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() =>
            this.setState({
              isAlertOpen: false,
            })
          }
        />
      </div>
    );
  }
}

const ChievosWithRouter = withRouter(UserAchievements);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChievosWithRouter);
