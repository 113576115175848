import React, { useState } from 'react';
import AlertModal from '../../../utils/modal/Modal';
import CloseBtn from '../../../assets/payment-modals/close_btn.svg';
import { ListItem } from '../listItem';
import { Trans } from 'react-i18next';
import i18n from '../../../i18n/i18n';
import { BSpan } from '../../../utils/text-utils/text-utils';
import './paymentWarningModal.css';
import { Translation } from 'react-i18next';
import InfoModal from '../../info-modals/info-modals';

const PaymentWarnnigModal = (props) => {
  const { isOpen, onRequestClose } = props;

  const [modal, setModal] = useState({
    isOpen: false,
    type: null,
  });

  const openModal = (isOpen, type) => {
    setModal({
      isOpen: isOpen,
      type: type,
    });
  };

  const Item = [
    {
      text: i18n.t('BENEFITS.li1'),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li2'>
          bla
          <BSpan onClick={() => openModal(true, 'voting')}>bla</BSpan>
          bla
          <BSpan onClick={() => openModal(true, 'law')}>bla</BSpan>
          bla
          <BSpan onClick={() => openModal(true, 'deal')}>bla</BSpan>
          bla
        </Trans>
      ),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li3'>
          bla
          <BSpan onClick={() => openModal(true, 'citizen')}>bla</BSpan>
          bla
        </Trans>
      ),
    },
    {
      text: i18n.t('BENEFITS.li4'),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li5'>
          bla
          <BSpan onClick={() => openModal(true, 'services')}>bla</BSpan>
          bla
          <BSpan onClick={() => openModal(true, 'deals')}>bla</BSpan>
          bla
        </Trans>
      ),
    },
  ];

  return (
    <Translation>
      {(t) => (
        <AlertModal
          isOpen={isOpen}
          modalClassName='PaymentWarnnig__Container'
          overlayClassName='PaymentWarnnig__Overlay'
          // outsideClick={onRequestClose}
        >
          <div className='PaymentWarning__Header'>
            <img
              src={CloseBtn}
              alt='close'
              className='PaymentWarning__Close-btn'
              onClick={onRequestClose}
            />
            <h1>{t('PAYMENTS_MODALS.PAYMENTS_WARNING.title')}</h1>
          </div>
          <div className='PaymentWarnnig__Content'>
            <h3>{t('PAYMENTS_MODALS.PAYMENTS_WARNING.subTitle')}</h3>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexFlow: 'column nowrap',
                width: '100%',
              }}
            >
              {Item.map((item, i) => (
                <ListItem textClassName='PaymentWarning__Li' {...item} />
              ))}
            </div>
            <button onClick={() => props.onPayClick()} className='PaymentWarning__btn1'>{t('PAYMENTS_MODALS.PAYMENTS_WARNING.btnPaynow')}</button>
            {/* <button className='PaymentWarning__btn2'>
            {t('PAYMENTS_MODALS.PAYMENTS_WARNING.btnCpm')}
            </button> */}
          </div>
          <InfoModal
            isOpen={modal.isOpen}
            type={modal.type}
            onRequestClose={() => setModal({ ...modal, isOpen: false })}
          />
        </AlertModal>
      )}
    </Translation>
  );
};

export default PaymentWarnnigModal;
