import {
  SAVE_USER,
  LOGOUT,
  SAVE_USER_POSTS,
  TOGGLE_DARK_MODE,
  UPDATE_USER_POSTS_ITEM,
  REMOVE_SEARCH_POSTS_ITEM,
  REMOVE_USER_POSTS_ITEM,
  REMOVE_PROFILE_POSTS_ITEM,
  SHOW_PAYMENT_MODAL_AGREEMENT,
  CLOSE_PAYMENT_MODAL_AGREEMENT,
  //
  OPEN_MESSAGE_MODAL,
  CLOSE_MESSAGE_MODAL,
  SAVE_VOTING_REQUEST,
  SAVE_VOTINGS,
  UPDATE_VOTING_REQUEST,
  UPDATE_VOTING,
  SAVE_MENTIONS,
  UPDATE_SEARCH_POSTS_ITEM,
  SAVE_SEARCH_POSTS,
  SAVE_COMMUNITY_POSTS,
  UPDATE_COMMUNITY_POSTS_ITEM,
  REMOVE_COMMUNITY_POSTS_ITEM,
  SAVE_PROFILE_POSTS,
  SAVE_HAS_NEW_NOTIFICATIONS,
  SAVE_USER_ACHIEVEMENTS,
  SAVE_USER_HAS_ENTERED,
  SAVE_USER_CHATS,
  OPEN_DELETE_SUCCESS_MODAL,
  CLOSE_DELETE_SUCCESS_MODAL,
  OPEN_VERIFY_MODAL,
  CLOSE_VERIFY_MODAL,
  OPEN_DIALOG_MODAL,
  CLOSE_DIALOG_MODAL,
} from "../../types/user/types";

const initialState = {
  user: null,
  userPosts: [],
  searchPosts: [],
  darkMode: false,
  isMessageModalOpen: false,
  messageModalMessage: "",
  messageModalType: "error",
  isPaymentModalAgreementOpen: false,
  votingRequests: [],
  votings: [],
  mentions: [],
  communityPosts: [],
  hasNewNotifications: false,
  achievements: [],
  userHasEntered: false,
  chats: [],
  isDeleteSuccessModalOpen: false,
  isVerificationModalOpen: false,
  isDialogOpen: false,
  dialogType: null,
  dialogMessage: '',
  dialogLoading: false,
  dialogYes: null,
  dialogNo: null,
};

const saveUser = (state, payload) => {
  let mentions = state.mentions || [];
  if (payload.data && (payload.data.followers || payload.data.following)) {
    mentions = [
      ...mentions
        .concat(payload.data.followers)
        .concat(payload.data.following)
        .map((item) => {
          return { display: item.username, id: item._id, ...item };
        }),
    ].filter(
      (item, index, self) => index === self.findIndex((t) => t._id === item._id)
    );
  }

  return {
    ...state,
    user: payload.data,
    mentions: mentions,
  };
};

const saveMentions = (state, payload) => {
  const lastMentions = state.mentions
    .concat(payload.data)
    .filter(
      (item, index, self) => index === self.findIndex((t) => t._id === item._id)
    );
  return {
    ...state,
    mentions: lastMentions,
  };
};

const saveUserAchievements = (state, payload) => {

  return {
    ...state,
    achievements: payload.data
  }
};

const saveUserChats = (state, payload) => {

  return {
    ...state,
    chats: payload.data
  }
};

const showPaymentAgreementModal = (state, payload) => {
  return {
    ...state,
    isPaymentModalAgreementOpen: true,
  };
};

const closePaymentAgreementModal = (state, payload) => {
  return {
    ...state,
    isPaymentModalAgreementOpen: false,
  };
};

const logOut = (state, payload) => {
  return {
    ...state,
    user: null,
    userHasEntered: false,
    userPosts: [],
    searchPosts: [],
    // darkMode: false,
    isMessageModalOpen: false,
    messageModalMessage: "",
    messageModalType: "error",
    isPaymentModalAgreementOpen: false,
    votingRequests: [],
    votings: [],
    mentions: [],
    communityPosts: [],
    hasNewNotifications: false,
    achievements: []
  };
};

const saveUserPosts = (state, payload) => {
  return {
    ...state,
    userPosts: payload.data,
  };
};

const saveUserHasEntered = (state, payload) => {
  return {
    ...state,
    userHasEntered: true,
  };
};

const saveVotingRequest = (state, payload) => {
  return {
    ...state,
    votingRequests: payload.data,
  };
};

const saveVotings = (state, payload) => {
  return {
    ...state,
    votings: payload.data,
  };
};

const updateVotingRequest = (state, payload) => {
  let votingUpdated = payload.data;
  let votingRequestCopy = state.votingRequests.map((voting) => {
    if (votingUpdated && voting && voting._id === votingUpdated._id) {
      return votingUpdated;
    } else {
      return voting;
    }
  });
  return {
    ...state,
    votingRequests: votingRequestCopy,
  };
};

const updateVoting = (state, payload) => {
  let votingUpdated = payload.data;
  let votingsCopy = state.votings.map((voting) => {
    if (votingUpdated && voting && voting._id === votingUpdated._id) {
      return votingUpdated;
    } else {
      return voting;
    }
  });
  return {
    ...state,
    votings: votingsCopy,
  };
};

const toggleDarkMode = (state) => {
  return {
    ...state,
    darkMode: !state.darkMode,
  };
};

const updateUserPostsItem = (state, payload) => {
  let postUpdated = payload.data;
  let userPostsCopy = state.userPosts.map((post) => {
    if (post && postUpdated && post._id === postUpdated._id) {
      return postUpdated;
    } else {
      return post;
    }
  });
  return {
    ...state,
    userPosts: userPostsCopy,
  };
};

const removeProfilePostsItem = (state, payload) => {
  let postId = payload.postId;
  let profilePostsCopy = state.profilePosts;
  return {
    ...state,
    profilePosts: profilePostsCopy
      ? profilePostsCopy.filter((post) => post && post._id !== postId)
      : [],
  };
};

const removeUserPostsItem = (state, payload) => {
  const postId = payload.postId;
  let userPostsCopy = state.userPosts;
  return {
    ...state,
    userPosts: userPostsCopy
      ? userPostsCopy.filter((post) => post && post._id !== postId)
      : [],
  };
};

const removeSearchPostsItem = (state, payload) => {
  const postId = payload.postId;
  let searchPostsCopy = state.searchPosts;
  return {
    ...state,
    searchPosts: searchPostsCopy
      ? searchPostsCopy.filter((post) => post && post._id !== postId)
      : [],
  };
};

const openMessageModal = (state, payload) => {
  return {
    ...state,
    isMessageModalOpen: true,
    messageModalMessage: payload.data.message,
    messageModalType: payload.data.type,
  };
};

const closeMessageModal = (state, payload) => {
  return {
    ...state,
    isMessageModalOpen: false,
  };
};

const updateSearchPostsItem = (state, payload) => {
  let postUpdated = payload.data;
  let searchPostsCopy = state.searchPosts.map((post) => {
    if (postUpdated && post && post._id === postUpdated._id) {
      return postUpdated;
    } else {
      return post;
    }
  });
  return {
    ...state,
    searchPosts: searchPostsCopy,
  };
};

const saveSearchPosts = (state, payload) => {
  return {
    ...state,
    searchPosts: payload.data,
  };
};

const saveCommunityPosts = (state, payload) => {
  return {
    ...state,
    communityPosts: payload.data,
  };
};

const updateCommunityPostsItem = (state, payload) => {
  let postUpdated = payload.data;
  let communityPostsCopy = state.communityPosts.map((post) => {
    if (postUpdated && post && post._id === postUpdated._id) {
      return postUpdated;
    } else {
      return post;
    }
  });
  return {
    ...state,
    communityPosts: communityPostsCopy,
  };
};

const removeCommunityPostsItem = (state, payload) => {
  const postId = payload.postId;
  let communityPostsCopy = state.communityPosts;
  return {
    ...state,
    communityPosts: communityPostsCopy
      ? communityPostsCopy.filter((post) => post._id !== postId)
      : [],
  };
};

const saveProfilePosts = (state, payload) => ({
  ...state,
  profilePosts: payload.data,
});

const saveHasNewNotifications = (state, payload) => ({
  ...state,
  hasNewNotifications: payload.data
})

const openDeleteSuccesModal = (state, payload) => ({
  ...state,
  isDeleteSuccessModalOpen: true
})

const closeDeleteSuccesModal = (state, payload) => ({
  ...state,
  isDeleteSuccessModalOpen: false
})

const openVerifyModal = (state, payload) => ({
  ...state,
  isVerificationModalOpen: true
})

const closeVerifyModal = (state, payload) => ({
  ...state,
  isVerificationModalOpen: false
})

const openDialogModal = (state, payload) => ({
  ...state,
  isDialogOpen: payload.data.isDialogOpen,
  dialogType: payload.data.dialogType,
  dialogMessage: payload.data.dialogMessage,
  dialogLoading: payload.data.dialogLoading,
  dialogYes: payload.data.dialogYes,
  dialogNo: payload.data.dialogNo
})

const closeDialogModal = (state, payload) => ({
  ...state,
  isDialogOpen: false,
  dialogType: null,
  dialogMessage: '',
  dialogLoading: false,
  dialogYes: null,
  dialogNo: null,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER:
      return saveUser(state, action);
    case LOGOUT:
      return logOut(state, action);
    case SAVE_USER_POSTS:
      return saveUserPosts(state, action);
    case SAVE_USER_ACHIEVEMENTS:
      return saveUserAchievements(state, action);
    case SAVE_USER_HAS_ENTERED:
      return saveUserHasEntered(state, action);
    case SHOW_PAYMENT_MODAL_AGREEMENT:
      return showPaymentAgreementModal(state, action);
    case CLOSE_PAYMENT_MODAL_AGREEMENT:
      return closePaymentAgreementModal(state, action);
    case TOGGLE_DARK_MODE:
      return toggleDarkMode(state, action);
    case SAVE_MENTIONS:
      return saveMentions(state, action);
    case UPDATE_USER_POSTS_ITEM:
      return updateUserPostsItem(state, action);
    case REMOVE_SEARCH_POSTS_ITEM:
      return removeSearchPostsItem(state, action);
    case REMOVE_USER_POSTS_ITEM:
      return removeUserPostsItem(state, action);
    case REMOVE_PROFILE_POSTS_ITEM:
      return removeProfilePostsItem(state, action);
    case OPEN_MESSAGE_MODAL:
      return openMessageModal(state, action);
    case CLOSE_MESSAGE_MODAL:
      return closeMessageModal(state, action);
    case SAVE_VOTING_REQUEST:
      return saveVotingRequest(state, action);
    case SAVE_VOTINGS:
      return saveVotings(state, action);
    case UPDATE_VOTING_REQUEST:
      return updateVotingRequest(state, action);
    case UPDATE_VOTING:
      return updateVoting(state, action);
    case UPDATE_SEARCH_POSTS_ITEM:
      return updateSearchPostsItem(state, action);
    case SAVE_SEARCH_POSTS:
      return saveSearchPosts(state, action);
    case SAVE_COMMUNITY_POSTS:
      return saveCommunityPosts(state, action);
    case UPDATE_COMMUNITY_POSTS_ITEM:
      return updateCommunityPostsItem(state, action);
    case REMOVE_COMMUNITY_POSTS_ITEM:
      return removeCommunityPostsItem(state, action);
    case SAVE_PROFILE_POSTS:
      return saveProfilePosts(state, action);
    case SAVE_HAS_NEW_NOTIFICATIONS:
      return saveHasNewNotifications(state, action);
    case SAVE_USER_CHATS:
      return saveUserChats(state, action);
    case OPEN_DELETE_SUCCESS_MODAL:
      return openDeleteSuccesModal(state, action);
    case CLOSE_DELETE_SUCCESS_MODAL:
      return closeDeleteSuccesModal(state, action);
    case OPEN_VERIFY_MODAL:
      return openVerifyModal(state, action);
    case CLOSE_VERIFY_MODAL:
      return closeVerifyModal(state, action);
    case OPEN_DIALOG_MODAL:
      return openDialogModal(state, action);
    case CLOSE_DIALOG_MODAL:
      return closeDialogModal(state, action);
    default:
      return state;
  }
};

export default reducer;
