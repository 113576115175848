import React from 'react';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import {
  getVoting,
  getVotingDetails,
  // terminateVoting,
  vote,
} from '../../pages/voting-list/votingListActions';
import * as routes from '../../routes/routes';
// import SearchInput from '../../utils/app-inputs/search-input/searchInput';
import getStat from '../../utils/getStat';
import { isUserValid } from '../../utils/isUserValid';
import './votingList.css';
import { withRouter } from 'react-router-dom';
import { throttle } from 'lodash';
import i18n from '../../i18n/i18n';
import VotingItem from '../../components/voting/votingItem';
import { FEDERAL_BLUE, FEDERAL_LIGTH_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import VotingIconWhite from '../../assets/voting/icon_voting_white.svg';
import VotingIcon from '../../assets/voting/icon_voting.svg';
import OfficialVotingIconWhite from '../../assets/voting/icon_official_vote_white.svg';
import OfficialVotingIcon from '../../assets/voting/icon_official_vote.svg';
import { AppLoader } from '../../utils/animations/animations';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';
import SearchFilter from '../../components/search-filter/SearchFilter';
import votinghistoryIcon from '../../assets/voting/icon_history.svg';

const SwitchButton = ({ style, className, darkMode, onClick, selected, icon, text }) => (
  <button
    style={Object.assign(
      {
        background: selected ? FEDERAL_BLUE : darkMode ? WHITE_MAIN : FEDERAL_LIGTH_BLUE,
      },
      style
    )}
    className={className}
    onClick={onClick}
  >
    <img src={icon} alt='' />
    <p style={{ color: selected ? WHITE_MAIN : FEDERAL_BLUE }}>{text}</p>
  </button>
);

class VotingList extends React.Component {
  constructor() {
    super();
    this.state = {
      fetching: false,
      skipItems: 0,
      limitItems: 5,
      plusPostNumber: 5,
      keepIncreasingCounter: false,
      search: '',
      voting: null,
    };
  }

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(false, this._isMounted);
    if (this._isMounted) await this.getStats();

    if (this._isMounted) this.onRefreshVotingList();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const positions = await getStat('positions');
        const users = await getStat('users');
        const positionsPeriodDays = await getStat('position-expires-period-days');
        const votingExpiresPeriodDays = await getStat('voting-expires-period-days');
        const votingMinVotingAssistance = await getStat('min-voting-assistance');
        this.setState({
          voting: { positions, users, votingMinVotingAssistance },
          positionsPeriodDays,
          votingExpiresPeriodDays,
        });
        resolve();
      } catch (e) {
        // console.log('getStats / error: ', e);
        reject(e);
      }
    });
  };

  fetchVotingList = () => {
    return new Promise(async (resolve, reject) => {
      const query = new URLSearchParams(this.props.location.search);
      const type = query.get('type');
      const isOfficial = type === 'official';
      const { skipItems, limitItems, fetching, search, toDate = '', sinceDate = '' } = this.state;
      const { user, saveVotingRequests, votingRequests = [], votings = [], saveVotings = [] } = this.props;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const { votingId } = this.props.match.params;
            const data = {
              userId: '',
              skipItems,
              limitItems,
              search,
              admin: user.admin,
              official: isOfficial,
              toDate,
              sinceDate,
              votingId: votingId || '',
            };
            const newVoting = await getVoting(data);
            let finalVotingList = [];
            if (isOfficial) {
              finalVotingList = newVoting
                .concat(votings)
                .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id));
              // .sort((a, b) => b.approvesCount - a.approvesCount);
            } else {
              finalVotingList = votingRequests
                .concat(newVoting)
                .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id));
              // .sort((a, b) => b.approvesCount - a.approvesCount);
            }
            // console.log("fetchVotingList / finalVotingList: ", finalVotingList)
            // console.log("isOfficial: ", isOfficial)
            if (isOfficial) {
              saveVotings(finalVotingList);
            } else {
              saveVotingRequests(finalVotingList);
            }
            this.setState({
              fetching: false,
              keepIncreasingCounter: newVoting.length > 0,
            });
            resolve();
          } catch (e) {
            if (e.status === 404) {
              this.setState({ fetching: false });
              resolve();
            } else {
              reject(e);
              // console.log("fetchVotingList / error: ", e)
              this.setState({ fetching: false });
              const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
              this.showAlert(true, 'error', error);
            }
          }
        });
      }
    });
  };

  onRefreshVotingList = (hardRefresh = true) => {
    // console.log("entrose")
    const { saveVotings, saveVotingRequests } = this.props;
    const { plusPostNumber } = this.state;
    if (hardRefresh) {
      saveVotings([]);
      saveVotingRequests([]);
    }

    this.setState({ skipItems: 0, limitItems: plusPostNumber }, () => {
      this.fetchVotingList();
    });
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchVotingList();
        }
      );
    } else {
      this.fetchVotingList();
    }
  };

  voteFunction = (votingId, boolean) => {
    const { user, updateVotingRequest, updateVoting } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        // const { isOfficial } = this.props.route.params;
        const query = new URLSearchParams(this.props.location.search);
        const type = query.get('type');
        const isOfficial = type === 'official';
        await vote(user._id, votingId, boolean);
        const votingRequestUpdated = await getVotingDetails(votingId);
        // console.log("votingRequestUpdated: ", votingRequestUpdated)
        if (isOfficial) {
          updateVoting(votingRequestUpdated[0]);
        } else {
          updateVotingRequest(votingRequestUpdated[0]);
        }

        this.fetchVotingList();
        resolve();
      } catch (e) {
        // console.log("voteFunction / error: ", e);
        const error = e?.data?.error?.message ?? i18n.t('ERRORS.serverError');
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  throtled = throttle(async () => {
    this.onRefreshVotingList(true);
  }, 1000);

  switchToVotingRequest = () => {
    this.props.history.replace(`${routes.VOTING_LIST_ROUTE}/?type=voting-request`);
    this.onRefreshVotingList();
  };

  switchToOfficialVotes = () => {
    this.props.history.replace(`${routes.VOTING_LIST_ROUTE}/?type=official`);
    this.onRefreshVotingList();
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  setValue = (name, value) => {
    // console.log("setValue / name: ", name)
    // console.log("setValue / value: ", value)
    this.setState({ [name]: value });
  };

  render() {
    const { fetching, voting, positionsPeriodDays, votingExpiresPeriodDays } = this.state;
    const { votingRequests, votings, user, darkMode } = this.props;
    const { votingId } = this.props.match.params;
    const query = new URLSearchParams(this.props.location.search);
    const type = query.get('type');
    const isOfficial = type === 'official';

    // console.log(votingRequests);
    // console.log(votings);

    return (
      <ScrollableDiv
        style={{
          width: 'calc(100% - 20px)',
          maxWidth: '800px',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexFlow: 'column nowrap',
        }}
        onEndReach={this.onEndReached}
      >
        {!votingId && (
          <SearchFilter
            inputPlaceholder={i18n.t('VOTING_GENERAL.searchInputPlaceholder')}
            onRefresh={() => this.throtled()}
            setValue={(name, value) => {
              this.setState({ [name]: value });
            }}
            showPostsFilters={false}
            rightComponent={
              <img
                alt=''
                onClick={() => this.props.history.push(`${routes.VOTING_HISTORY}`)}
                src={votinghistoryIcon}
                style={{ width: 25, height: 25, cursor: 'pointer' }}
              />
            }
          />
        )}
        {!votingId && (
          <div className='VotingList__Switch'>
            <SwitchButton
              className='VotingList__Btn'
              text={i18n.t('VOTING_GENERAL.votingRequest')}
              style={{ cursor: !isOfficial ? 'default' : 'pointer' }}
              darkMode={darkMode}
              selected={!isOfficial}
              icon={!isOfficial ? VotingIconWhite : VotingIcon}
              onClick={isOfficial ? () => this.switchToVotingRequest() : null}
            />
            <SwitchButton
              className='VotingList__Btn'
              text={i18n.t('VOTING_GENERAL.voting')}
              style={{ cursor: isOfficial ? 'default' : 'pointer' }}
              darkMode={darkMode}
              selected={isOfficial}
              icon={isOfficial ? OfficialVotingIconWhite : OfficialVotingIcon}
              onClick={isOfficial ? null : () => this.switchToOfficialVotes()}
            />
          </div>
        )}
        {isOfficial && votings?.length > 0
          ? votings.map((item, idx) => (
              <VotingItem
                item={item}
                key={idx}
                darkMode={darkMode}
                voting={voting}
                positionsPeriodDays={positionsPeriodDays}
                votingExpiresPeriodDays={votingExpiresPeriodDays}
                user={user}
                voteFunction={this.voteFunction}
              />
            ))
          : null}
        {!isOfficial && votingRequests?.length > 0
          ? votingRequests.map((item, idx) => (
              <VotingItem
                item={item}
                key={idx}
                darkMode={darkMode}
                voting={voting}
                positionsPeriodDays={positionsPeriodDays}
                votingExpiresPeriodDays={votingExpiresPeriodDays}
                user={user}
                voteFunction={this.voteFunction}
              />
            ))
          : null}
        {isOfficial && fetching && votings?.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 'calc(100vh - 250px)',
            }}
          >
            <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} size='big' />
          </div>
        ) : null}
        {!isOfficial && fetching && votingRequests?.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 'calc(100vh - 250px)',
            }}
          >
            <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} size='big' />
          </div>
        ) : null}
        {fetching && (votingRequests?.length > 0 || votings?.length > 0) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '80px',
            }}
          >
            <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} size='50px' />
          </div>
        ) : null}
        {!fetching && isOfficial && votings && votings?.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              minHeight: 'calc(100vh - 250px)',
            }}
          >
            <NotFoundItem text={i18n.t('VOTING_GENERAL.notFound')} type='votes' isBlue={darkMode} />
          </div>
        ) : null}
        {!fetching && !isOfficial && votingRequests && votingRequests.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              minHeight: 'calc(100vh - 250px)',
            }}
          >
            <NotFoundItem text={i18n.t('VOTING_GENERAL.notFound')} type='votes' isBlue={darkMode} />
          </div>
        ) : null}
      </ScrollableDiv>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, votingRequests, votings, darkMode } = state.user;
  return {
    user,
    votingRequests,
    votings,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveVotingRequests: bindActionCreators(actions.saveVotingRequests, dispatch),
    updateVotingRequest: bindActionCreators(actions.updateVotingRequest, dispatch),
    saveVotings: bindActionCreators(actions.saveVotings, dispatch),
    updateVoting: bindActionCreators(actions.updateVoting, dispatch),
  };
};

const VotingListWithRouter = withRouter(VotingList);

export default connect(mapStateToProps, mapDispatchToProps)(VotingListWithRouter);
