import React, { useState, useEffect } from 'react';
import AlertModal from '../../utils/modal/Modal';
import CitizenImg from '../../assets/info-modals/citizen.png';
import PresidentImg from '../../assets/info-modals/president.png';
import VicePresidentImg from '../../assets/info-modals/vicepresident.png';
import getStat from '../../utils/getStat';

import Arrow from '../../assets/info-modals/globe_arrow.svg';

import CitizenLogo from '../../assets/info-modals/icon_members.png';
import PresidentLogo from '../../assets/info-modals/icon_president.png';
import VicePresidentLogo from '../../assets/info-modals/icon_vicepresident.png';
import VotingLogo from '../../assets/info-modals/icon_votes.png';
import LawsLogo from '../../assets/info-modals/icon_law.png';
import DealsLogo from '../../assets/info-modals/icon_deal.png';
import FinancesLogo from '../../assets/info-modals/icon_finances.png';
import IncomesLogo from '../../assets/info-modals/icon_incomes.png';
import ExpensesLogo from '../../assets/info-modals/icon_expenses.png';
import ServicesLogo from '../../assets/info-modals/icon_services.png';
import i18n from '../../i18n/i18n';
import './info-modals.css';
import { ParsedText } from '../../utils/text-utils/text-utils';

const InfoModal = (props) => {
  const {
    isOpen,
    // title,
    onRequestClose,
    type,
  } = props;

  // eslint-disable-next-line
  const [positionsPeriodDays, setPositionsPeriodDays] = useState(null);
  const [presidentSalaryPorcentage, setPresidentSalaryPorcentage] = useState(null);
  const [vicepresidentSalaryPorcentage, setVicepresidentSalaryPorcentage] = useState(null);
  // eslint-disable-next-line
  const [presidentSalary, setPresidentSalary] = useState(null);
  // eslint-disable-next-line
  const [vicepresidentSalary, setVicepresidentSalary] = useState(null);
  const [maintenanceTaxes, setMaintenanceTaxes] = useState(null);
  const [citizenGainFromDeal, setCitizenGainFromDeal] = useState(null);
  const [timeToApproveDealDays, setTimeToApproveDealDays] = useState(null);

  let _isMounted = false;

  useEffect(() => {
    // eslint-disable-next-line
    _isMounted = true;
    if (_isMounted) getStats();
    return () => {
      _isMounted = false;
    };
  }, []);

  const getStats = () => {
    return new Promise(async (resolve, _) => {
      // setLoading(true)
      try {
        const positionsPeriodDays = await getStat('position-expires-period-days');
        const presidentSalaryPorcentage = await getStat('president-salary-porcentage');
        const vicepresidentSalaryPorcentage = await getStat('vicepresident-salary-porcentage');
        const presidentSalary = await getStat('president-salary');
        const vicepresidentSalary = await getStat('vicepresident-salary');
        const maintenanceTaxes = await getStat('maintenance-taxes');

        const timeToApproveDealDays = await getStat('time-to-approve-deal-days');
        const citizenGainFromDeal = await getStat('citizen-gain-from-deal');
        setTimeToApproveDealDays(timeToApproveDealDays);
        setCitizenGainFromDeal(citizenGainFromDeal);
        setPositionsPeriodDays(positionsPeriodDays);
        setPresidentSalaryPorcentage(presidentSalaryPorcentage);
        setVicepresidentSalaryPorcentage(vicepresidentSalaryPorcentage);
        setPresidentSalary(presidentSalary);
        setVicepresidentSalary(vicepresidentSalary);
        setMaintenanceTaxes(maintenanceTaxes);
        resolve();
      } catch (e) {
        resolve();
      }
    });
  };

  const dealsValues = [
    {
      keyword: `${
        maintenanceTaxes && presidentSalaryPorcentage && vicepresidentSalaryPorcentage
          ? 100 - Number(maintenanceTaxes) - (Number(presidentSalaryPorcentage) + Number(vicepresidentSalaryPorcentage))
          : '...'
      }%`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
    {
      keyword: `${maintenanceTaxes && citizenGainFromDeal ? Number(maintenanceTaxes) - Number(citizenGainFromDeal) : '...'}%`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
    {
      keyword: `${presidentSalaryPorcentage ? presidentSalaryPorcentage : '...'}%`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
    {
      keyword: `${vicepresidentSalaryPorcentage ? vicepresidentSalaryPorcentage : '...'}%`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
    {
      keyword: `${citizenGainFromDeal ? citizenGainFromDeal : '...'}%`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
    {
      keyword: `${timeToApproveDealDays ? timeToApproveDealDays : '...'}`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
  ];

  const capitalValues = [
    {
      keyword: `${maintenanceTaxes ? maintenanceTaxes : '...'}%`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
    {
      keyword: `${presidentSalaryPorcentage ? presidentSalaryPorcentage : '...'}%`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
    {
      keyword: `${vicepresidentSalaryPorcentage ? vicepresidentSalaryPorcentage : '...'}%`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
    {
      keyword: `${
        maintenanceTaxes
          ? 100 - (Number(maintenanceTaxes) + Number(vicepresidentSalaryPorcentage) + Number(presidentSalaryPorcentage))
          : '...'
      }%`,
      style: { fontWeight: 'bold', cursor: 'normal', color: '#000' },
    },
  ];

  return (
    <AlertModal
      overlayClassName='InfoModal__Overlay'
      modalClassName='InfoModal__Container'
      isOpen={isOpen}
      outsideClick={onRequestClose}
    >
      <div className='InfoModal__Content'>
        <div className='infoModal__ImgContainer'>
          <img
            src={
              type && (type.toLowerCase() === 'president' || type.toLowerCase() === 'presidente')
                ? PresidentImg
                : type &&
                  (type.toLowerCase() === 'vicepresident' ||
                    type.toLowerCase() === 'vicepresidente' ||
                    type.toLowerCase() === 'vice-presidente' ||
                    type.toLowerCase() === 'vice-president' ||
                    type.toLowerCase() === 'vice presidente' ||
                    type.toLowerCase() === 'vice president' ||
                    type.toLowerCase() === 'capital')
                ? VicePresidentImg
                : CitizenImg
            }
            alt=''
          />
          <img src={Arrow} alt='' />
        </div>
        <div className='InfoModal__DescriptionContainer'>
          <div className='InfoModal__Description'>
            <div className='InfoModal__Header'>
              <img
                src={
                  type &&
                  (type.toLowerCase() === 'voting' ||
                    type.toLowerCase() === 'votings' ||
                    type.toLowerCase() === 'vote' ||
                    type.toLowerCase() === 'votes' ||
                    type.toLowerCase() === 'votaciones' ||
                    type.toLowerCase() === 'voto' ||
                    type.toLowerCase() === 'votos' ||
                    type.toLowerCase() === 'votacion')
                    ? VotingLogo
                    : type &&
                      (type.toLowerCase() === 'law' ||
                        type.toLowerCase() === 'laws' ||
                        type.toLowerCase() === 'leyes' ||
                        type.toLowerCase() === 'ley')
                    ? LawsLogo
                    : type &&
                      (type.toLowerCase() === 'deal' ||
                        type.toLowerCase() === 'deals' ||
                        type.toLowerCase() === 'trato' ||
                        type.toLowerCase() === 'tratos')
                    ? DealsLogo
                    : type &&
                      (type.toLowerCase() === 'service' ||
                        type.toLowerCase() === 'services' ||
                        type.toLowerCase() === 'servicio' ||
                        type.toLowerCase() === 'servicios')
                    ? ServicesLogo
                    : type &&
                      (type.toLowerCase() === 'finances' ||
                        type.toLowerCase() === 'fincance' ||
                        type.toLowerCase() === 'finanza' ||
                        type.toLowerCase() === 'finanzas')
                    ? FinancesLogo
                    : type &&
                      (type.toLowerCase() === 'income' ||
                        type.toLowerCase() === 'incomes' ||
                        type.toLowerCase() === 'ingreso' ||
                        type.toLowerCase() === 'ingresos')
                    ? IncomesLogo
                    : type &&
                      (type.toLowerCase() === 'expense' ||
                        type.toLowerCase() === 'expenses' ||
                        type.toLowerCase() === 'gastos' ||
                        type.toLowerCase() === 'gasto')
                    ? ExpensesLogo
                    : type &&
                      (type.toLowerCase() === 'community' ||
                        type.toLowerCase() === 'communities' ||
                        type.toLowerCase() === 'comunidad' ||
                        type.toLowerCase() === 'comunidades')
                    ? CitizenLogo
                    : type &&
                      (type.toLowerCase() === 'citizens' ||
                        type.toLowerCase() === 'citizen' ||
                        type.toLowerCase() === 'ciudadano' ||
                        type.toLowerCase() === 'ciudadanos')
                    ? CitizenLogo
                    : type && (type.toLowerCase() === 'president' || type.toLowerCase() === 'presidente')
                    ? PresidentLogo
                    : type &&
                      (type.toLowerCase() === 'vicepresident' ||
                        type.toLowerCase() === 'vicepresidente' ||
                        type.toLowerCase() === 'vice-presidente' ||
                        type.toLowerCase() === 'vice-president' ||
                        type.toLowerCase() === 'vice presidente' ||
                        type.toLowerCase() === 'vice president')
                    ? VicePresidentLogo
                    : type && type.toLowerCase() === 'capital'
                    ? FinancesLogo
                    : CitizenLogo
                }
                alt=''
              />
              <h2>
                {type &&
                (type.toLowerCase() === 'voting' ||
                  type.toLowerCase() === 'votings' ||
                  type.toLowerCase() === 'vote' ||
                  type.toLowerCase() === 'votes' ||
                  type.toLowerCase() === 'votaciones' ||
                  type.toLowerCase() === 'voto' ||
                  type.toLowerCase() === 'votos' ||
                  type.toLowerCase() === 'votacion')
                  ? i18n.t('HELP_MODAL.VOTINGS.title')
                  : type &&
                    (type.toLowerCase() === 'law' ||
                      type.toLowerCase() === 'laws' ||
                      type.toLowerCase() === 'leyes' ||
                      type.toLowerCase() === 'ley')
                  ? i18n.t('HELP_MODAL.LAWS.title')
                  : type &&
                    (type.toLowerCase() === 'deal' ||
                      type.toLowerCase() === 'deals' ||
                      type.toLowerCase() === 'trato' ||
                      type.toLowerCase() === 'tratos')
                  ? i18n.t('HELP_MODAL.DEALS.title')
                  : type &&
                    (type.toLowerCase() === 'service' ||
                      type.toLowerCase() === 'services' ||
                      type.toLowerCase() === 'servicio' ||
                      type.toLowerCase() === 'servicios')
                  ? i18n.t('HELP_MODAL.SERVICES.title')
                  : type &&
                    (type.toLowerCase() === 'finances' ||
                      type.toLowerCase() === 'fincance' ||
                      type.toLowerCase() === 'finanza' ||
                      type.toLowerCase() === 'finanzas')
                  ? i18n.t('HELP_MODAL.FINANCES.title')
                  : type &&
                    (type.toLowerCase() === 'income' ||
                      type.toLowerCase() === 'incomes' ||
                      type.toLowerCase() === 'ingreso' ||
                      type.toLowerCase() === 'ingresos')
                  ? i18n.t('HELP_MODAL.INCOMES.title')
                  : type &&
                    (type.toLowerCase() === 'expense' ||
                      type.toLowerCase() === 'expenses' ||
                      type.toLowerCase() === 'gastos' ||
                      type.toLowerCase() === 'gasto')
                  ? i18n.t('HELP_MODAL.EXPENSES.title')
                  : type &&
                    (type.toLowerCase() === 'community' ||
                      type.toLowerCase() === 'communities' ||
                      type.toLowerCase() === 'comunidad' ||
                      type.toLowerCase() === 'comunidades')
                  ? i18n.t('HELP_MODAL.COMMUNITY.title')
                  : type &&
                    (type.toLowerCase() === 'citizens' ||
                      type.toLowerCase() === 'citizen' ||
                      type.toLowerCase() === 'ciudadano' ||
                      type.toLowerCase() === 'ciudadanos')
                  ? i18n.t('HELP_MODAL.CITIZEN.title')
                  : type && (type.toLowerCase() === 'president' || type.toLowerCase() === 'presidente')
                  ? i18n.t('HELP_MODAL.PRESIDENT.title')
                  : type &&
                    (type.toLowerCase() === 'vicepresident' ||
                      type.toLowerCase() === 'vicepresidente' ||
                      type.toLowerCase() === 'vice-presidente' ||
                      type.toLowerCase() === 'vice-president' ||
                      type.toLowerCase() === 'vice presidente' ||
                      type.toLowerCase() === 'vice president')
                  ? i18n.t('HELP_MODAL.VICE_PRESIDENT.title')
                  : type && type.toLowerCase() === 'capital'
                  ? i18n.t('HELP_MODAL.CAPITAL.title')
                  : i18n.t('HELP_MODAL.CITIZEN.title')}
              </h2>
            </div>
            <p style={{ whiteSpace: 'pre-line', maxHeight: 500 }}>
              {type &&
              (type.toLowerCase() === 'voting' ||
                type.toLowerCase() === 'votings' ||
                type.toLowerCase() === 'vote' ||
                type.toLowerCase() === 'votes' ||
                type.toLowerCase() === 'votaciones' ||
                type.toLowerCase() === 'voto' ||
                type.toLowerCase() === 'votos' ||
                type.toLowerCase() === 'votacion') ? (
                i18n.t('HELP_MODAL.VOTINGS.content')
              ) : type &&
                (type.toLowerCase() === 'law' ||
                  type.toLowerCase() === 'laws' ||
                  type.toLowerCase() === 'leyes' ||
                  type.toLowerCase() === 'ley') ? (
                i18n.t('HELP_MODAL.LAWS.content')
              ) : type &&
                (type.toLowerCase() === 'deal' ||
                  type.toLowerCase() === 'deals' ||
                  type.toLowerCase() === 'trato' ||
                  type.toLowerCase() === 'tratos') ? (
                <ParsedText
                  string={i18n.t('HELP_MODAL.DEALS.content')(
                    maintenanceTaxes,
                    citizenGainFromDeal,
                    presidentSalaryPorcentage,
                    vicepresidentSalaryPorcentage,
                    timeToApproveDealDays
                  )}
                  values={dealsValues}
                />
              ) : type &&
                (type.toLowerCase() === 'service' ||
                  type.toLowerCase() === 'services' ||
                  type.toLowerCase() === 'servicio' ||
                  type.toLowerCase() === 'servicios') ? (
                i18n.t('HELP_MODAL.SERVICES.content')
              ) : type &&
                (type.toLowerCase() === 'finances' ||
                  type.toLowerCase() === 'finance' ||
                  type.toLowerCase() === 'finanza' ||
                  type.toLowerCase() === 'finanzas') ? (
                i18n.t('HELP_MODAL.FINANCES.content')
              ) : type &&
                (type.toLowerCase() === 'income' ||
                  type.toLowerCase() === 'incomes' ||
                  type.toLowerCase() === 'ingreso' ||
                  type.toLowerCase() === 'ingresos') ? (
                i18n.t('HELP_MODAL.INCOMES.content')
              ) : type &&
                (type.toLowerCase() === 'expense' ||
                  type.toLowerCase() === 'expenses' ||
                  type.toLowerCase() === 'gastos' ||
                  type.toLowerCase() === 'gasto') ? (
                i18n.t('HELP_MODAL.EXPENSES.content')
              ) : type &&
                (type.toLowerCase() === 'community' ||
                  type.toLowerCase() === 'communities' ||
                  type.toLowerCase() === 'comunidad' ||
                  type.toLowerCase() === 'comunidades') ? (
                i18n.t('HELP_MODAL.COMMUNITY.content')
              ) : type && (type.toLowerCase() === 'president' || type.toLowerCase() === 'presidente') ? (
                i18n.t('HELP_MODAL.PRESIDENT.content')
              ) : type &&
                (type.toLowerCase() === 'vicepresident' ||
                  type.toLowerCase() === 'vicepresidente' ||
                  type.toLowerCase() === 'vice-presidente' ||
                  type.toLowerCase() === 'vice-president' ||
                  type.toLowerCase() === 'vice presidente' ||
                  type.toLowerCase() === 'vice president') ? (
                i18n.t('HELP_MODAL.VICE_PRESIDENT.content')
              ) : type && type.toLowerCase() === 'capital' ? (
                <ParsedText
                  string={i18n.t('HELP_MODAL.CAPITAL.content')(
                    maintenanceTaxes,
                    presidentSalaryPorcentage,
                    vicepresidentSalaryPorcentage
                  )}
                  values={capitalValues}
                />
              ) : type &&
                (type.toLowerCase() === 'citizens' ||
                  type.toLowerCase() === 'citizen' ||
                  type.toLowerCase() === 'ciudadano' ||
                  type.toLowerCase() === 'ciudadanos') ? (
                i18n.t('HELP_MODAL.CITIZEN.content')
              ) : (
                i18n.t('HELP_MODAL.CITIZEN.content')
              )}
            </p>
          </div>
        </div>
      </div>
      <div
        className='InfoModal__ButtonContainer'
        style={{
          height: 50,
        }}
      >
        <button type='button' onClick={onRequestClose}>
          Continue
        </button>
      </div>
    </AlertModal>
  );
};

export default InfoModal;
