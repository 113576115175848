import React from 'react';
import './settings.css';
import i18n from '../../i18n/i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { withRouter } from 'react-router';
import { isUserValid } from '../../utils/isUserValid';
import SelectButton from '../../assets/settings/select-button-icon.svg';
import FinanceIcon from '../../assets/settings/finance-icon.svg';
import FinanceIconWhite from '../../assets/settings/finance-icon-white.svg';
import HelpIcon from '../../assets/settings/help-icon.svg';
import HelpIconWhite from '../../assets/settings/help-icon-white.svg';
import LogOutIcon from '../../assets/settings/logout-icon.svg';
import LogOutIconWhite from '../../assets/settings/logout-icon-white.svg';
import DarkModeIcon from '../../assets/settings/darkmode-icon.svg';
import DarkModeIconWhite from '../../assets/settings/darkmode-icon-white.svg';
import TranslateIcon from '../../assets/settings/translate_icon.svg';
import TranslateIconWhite from '../../assets/settings/translate_icon_white.svg';
import PrivacyIcon from '../../assets/settings/privacy_icon.svg';
import PrivacyIconWhite from '../../assets/settings/privacy_icon_white.svg';
import SecurityIcon from '../../assets/settings/security_icon.svg';
import SecurityIconWhite from '../../assets/settings/security_icon_white.svg';
import ToggleButton from 'react-toggle-button';
import { FEDERAL_BLUE, GRAY_MAIN, GRAY_SECOND_TRANSPARENCY_FULL, WHITE_MAIN } from '../../utils/color-palatte';
import { FAQ_ROUTE, PRYVACY_ROUTE, SECURITY_ROUTE, USER_FINANCE_ROUTE } from '../../routes/routes';
import SafeLINK from '../../utils/safe-link';
import ReactFlagsSelect from 'react-flags-select';
import './flags-select.css';
import signOutEndpoint from '../../utils/signOutEndpoint';
import Alert from '../../utils/alert/Alert';

export const SettingsButton = ({ title, icon, iconStyle, onClick, rightComponent, darkMode }) => (
  <button
    onClick={onClick}
    className='Settings__Button__Container'
    style={{
      borderBottom: `1px solid ${darkMode ? '#FFF8' : GRAY_SECOND_TRANSPARENCY_FULL}`,
    }}
  >
    <div className='Settings__Button__Text__Container'>
      <p className='Settings__Button__Text' style={{ color: darkMode ? WHITE_MAIN : GRAY_MAIN }}>
        {title}
      </p>
      <img src={icon} style={iconStyle} className='Settings__Select__Button__Image' alt={`${title} button`} />
    </div>
    {rightComponent ? (
      rightComponent
    ) : (
      <img src={SelectButton} style={iconStyle} className='Settings__Select__Button' alt={`${title} select`} />
    )}
  </button>
);

class Settings extends React.Component {
  state = {
    loading: false,
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(false, this._isMounted);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  logoutFunction = async () => {
    const { logOut } = this.props;
    // this.props.history.push(HOME_ROUTE);
    await signOutEndpoint();
    logOut();
    // localStorage.clear()
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  render() {
    const { darkMode, toggleDarkMode } = this.props;
    const { isOpen, type, content } = this.state;
    // console.log('user: ', user);
    // console.log("darkMode: ", darkMode)

    return (
      <div className='Settings__Container'>
        <div className='Settings__Title__Container' style={{ borderBottom: `1px solid ${darkMode ? WHITE_MAIN : GRAY_MAIN}` }}>
          <h1 className='Settings__Title' style={{ color: darkMode ? WHITE_MAIN : GRAY_MAIN }}>
            {i18n.t('SETTINGS.settings')}
          </h1>
        </div>
        <SettingsButton
          onClick={() => this.props.history.push(USER_FINANCE_ROUTE)}
          title={i18n.t('SETTINGS.finance')}
          icon={darkMode ? FinanceIconWhite : FinanceIcon}
          darkMode={darkMode}
        />
        <SafeLINK
          // className='Profile__Route__Button'
          dangerousLINK={FAQ_ROUTE}
          text={
            <SettingsButton
              onClick={() => null}
              title={i18n.t('SETTINGS.help')}
              icon={darkMode ? HelpIconWhite : HelpIcon}
              darkMode={darkMode}
            />
          }
          onClick={() => null}
        />
        <SettingsButton
          onClick={() => null}
          title={i18n.t('language')}
          rightComponent={
            <div className='Setings__Flag-container'>
              <ReactFlagsSelect
                className='Setings__Flag'
                // ref={this.userFlag}
                defaultCountry={String(localStorage.getItem('language') === 'es' ? 'ES' : 'US').toUpperCase()}
                countries={['US', 'ES']}
                showSelectedLabel={false}
                showOptionLabel={false}
                onSelect={(selected) => {
                  localStorage.setItem('language', String(selected).toLowerCase());
                  window.location.reload(false);
                }}
              />
            </div>
          }
          iconStyle={{ width: '25px' }}
          icon={darkMode ? TranslateIconWhite : TranslateIcon}
          darkMode={darkMode}
        />
        <SettingsButton
          onClick={() => this.props.history.push(PRYVACY_ROUTE)}
          title={i18n.t('SETTINGS.privacy')}
          icon={darkMode ? PrivacyIconWhite : PrivacyIcon}
          darkMode={darkMode}
        />
        <SettingsButton
          onClick={() => this.props.history.push(SECURITY_ROUTE)}
          icon={darkMode ? SecurityIconWhite : SecurityIcon}
          title={i18n.t('SETTINGS.security')}
          darkMode={darkMode}
        />
        <SettingsButton
          onClick={() => {
            toggleDarkMode();
          }}
          title={i18n.t('SETTINGS.darkmode')}
          icon={darkMode ? DarkModeIconWhite : DarkModeIcon}
          darkMode={darkMode}
          rightComponent={
            <ToggleButton
              value={darkMode}
              inactiveLabel={''}
              activeLabel={''}
              colors={{
                // activeThumb: {
                //   base: GRAY_MAIN,
                // },
                // inactiveThumb: {
                //   base: GRAY_MAIN,
                // },
                active: {
                  base: FEDERAL_BLUE,
                  // hover: 'rgb(177, 191, 215)',
                },
                inactive: {
                  base: GRAY_MAIN,
                  // hover: 'rgb(95,96,98)',
                },
              }}
              trackStyle={{ height: 15, width: 50 }}
              thumbStyle={{ height: 25, width: 25 }}
              // thumbAnimateRange={[-10, 36]}
              // thumbIcon={<ThumbIcon />}
              onToggle={() => {
                const { toggleDarkMode } = this.props;
                toggleDarkMode();
              }}
            />
          }
        />
        <SettingsButton
          onClick={() => this.logoutFunction()}
          title={i18n.t('SETTINGS.logout')}
          icon={darkMode ? LogOutIconWhite : LogOutIcon}
          darkMode={darkMode}
        />
        <Alert isOpen={isOpen} type={type} content={content} onRequestClose={() => this.setState({ isOpen: false })} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleDarkMode: bindActionCreators(actions.toggleDarkMode, dispatch),
    logOut: bindActionCreators(actions.logOut, dispatch),
  };
}

const SettingsWithRouter = withRouter(Settings);

function mapStateToProps(state) {
  const { darkMode } = state.user;
  return {
    darkMode,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsWithRouter);
