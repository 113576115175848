import { 
  combineReducers, 
  createStore, 
  // applyMiddleware
 } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import user from './reducers/user/reducers';
import utils from './reducers/utils/reducers';
import createVoting from './reducers/create-voting/reducers';
import achievements from './reducers/achievements/recucers'
// import { createLogger } from 'redux-logger'

const persistConfig = {
  key: "root",
  storage: storage,
};

const rootReducer = combineReducers({
  user,
  createVoting,
  utils,
  achievements,
});

// const logger = createLogger();

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(
  persistedReducer
  // applyMiddleware(logger)
);

let persistor = persistStore(store);

export { store, persistor };
