import React from 'react';
import './send-button.css';

const SendButton = ({
  type,
  onClick,
  disabled,
  renderDisabled,
  textContent,
  className,
  style
}) => {
  return (
    <button
      type={type || 'submit'}
      onClick={onClick}
      disabled={disabled}
      style={style}
      className={className ?? 'SendButton'}
    >
      {disabled ? renderDisabled : textContent || 'Send'}
    </button>
  );
};

export default SendButton;
