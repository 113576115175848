import React from "react";
import i18n from "../../i18n/i18n";
import "./communityMembers.css";
import * as routes from "../../routes/routes";
import { withRouter } from "react-router-dom";
import {
  getCommunityMembers,
  giveLeadership,
  quitCommunityMember,
} from "./communityMembersActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as actions } from "../../redux/actions/user/actions";
import * as colors from "../../utils/color-palatte";
import { throttle } from "lodash";
import { reportUser } from "../profile/profileActions";
import { updateLoggedUser } from "../../utils/updateLoggedUser";
import { isUserValid } from "../../utils/isUserValid";
import UserItem from "../../components/user-item/userItem";
import { AppLoader } from "../../utils/animations/animations";
import SearchFilter from "../../components/search-filter/SearchFilter";
import Alert from "../../utils/alert/Alert";
import ScrollableDiv from "../../components/scrollable-div/scrollableDiv";
import { NotFoundItem } from "../../components/not-found-item/notFoundItem";
import getStat from "../../utils/getStat";

class CommunityMembers extends React.Component {
  state = {
    fetching: false,
    skipItems: 0,
    limitItems: 5,
    plusPostNumber: 5,
    keepIncreasingCounter: false,
    search: "",
    members: [],
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;

    isUserValid(false, this._isMounted);
    if (this._isMounted) this.getStats()
    if (this._isMounted) this.onRefreshMembers();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true }, async () => {
        try {
          // const users = await getStat('users');
          const positions = await getStat('positions');
          this.setState({
            positions,
            // users,
          });
          resolve();
        } catch (e) {
          // console.log('postComments / getStats:', e);
          reject(e);
        }
      });
    });
  };

  fetchMembers = async () => {
    const { skipItems, limitItems, fetching, search, members } = this.state;
    if (!fetching) {
      this.setState({ fetching: true }, async () => {
        try {
          const { communityId } = this.props.match.params;
          // console.log("fetchMembers / communityId: ", communityId);
          const newMembers = await getCommunityMembers({
            communityId,
            skipItems,
            limitItems,
            search,
          });
          // console.log("getmembers / newMembers: ", newMembers);
          const finalUsers = members
            .concat(newMembers)
            .filter(
              (item, index, self) =>
                index === self.findIndex((t) => t._id === item._id)
            );
          // .sort((a, b) => b.reportsCount - a.reportsCount);
          this.setState({
            members: finalUsers,
            fetching: false,
            keepIncreasingCounter: newMembers.length > 0,
          });
        } catch (e) {
          // console.log("fetchMembers / error: ", e)
          this.setState({ fetching: false });
          const error =
            e && e.data && e.data.error
              ? e.data.error.message
              : i18n.t("GENERAL_ERRORS.serverError");
          this.setState({
            isMessageModalOpen: true,
            messageModalMessage: error,
            messageModalType: "error",
          });
        }
      });
    }
  };

  onRefreshMembers = () => {
    const { plusPostNumber } = this.state;
    this.setState(
      { skipItems: 0, limitItems: plusPostNumber, members: [] },
      () => {
        this.fetchMembers();
      }
    );
  };

  onEndReached = () => {
    if (!this._isMounted) return;
    // console.log("onEndReached");
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } =
      this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchMembers();
        }
      );
    } else {
      this.fetchMembers();
    }
  };

  throtled = throttle(async () => {
    this.onRefreshMembers();
  }, 1000);

  report = (userId) => {
    const { user } = this.props;
    this.setState({ fetching: true }, async () => {
      try {
        const response = await reportUser(userId, user._id);
        this.showAlert(true, "success", response);
        this.setState({ fetching: false });
        this.onRefreshMembers();
      } catch (e) {
        // console.log("report / error: ", e);
        const error =
          e && e.data && e.data.error
            ? e.data.error.message
            : i18n.t("GENERAL_ERRORS.serverError");
        this.showAlert(true, "error", error);
        this.setState({ fetching: false });
      }
    });
  };

  giveLeadership = (userId) => {
    this.setState({ fetching: true }, async () => {
      try {
        const { communityId } = this.props.match.params;
        const { saveUser, history } = this.props;
        await giveLeadership(communityId, userId);
        await updateLoggedUser(saveUser);
        this.showAlert(
          true,
          "success",
          i18n.t("COMMUNITIES_MEMBERS.leadershipDelivered")
        );
        this.setState({ fetching: false });
        history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${communityId}`);
      } catch (e) {
        // console.log("unblock / error: ", e);
        const error =
          e && e.data && e.data.error
            ? e.data.error.message
            : i18n.t("GENERAL_ERRORS.serverError");
        this.showAlert(true, "error", error);
        this.setState({ fetching: false });
      }
    });
  };

  quitMember = (userId) => {
    this.setState({ fetching: true }, async () => {
      try {
        const { communityId } = this.props.match.params;
        const { saveUser } = this.props;
        await quitCommunityMember(userId, communityId);
        await updateLoggedUser(saveUser);
        this.showAlert(
          true,
          "success",
          i18n.t("COMMUNITIES.userQuited")
        );
        this.setState({ fetching: false }, () => this.onRefreshMembers());
        // history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${communityId}`);
      } catch (e) {
        // console.log("unblock / error: ", e);
        const error = e?.data?.error?.message
          ? e.data.error.message
          : i18n.t("GENERAL_ERRORS.serverError");
        // console.log("error", error);
        this.showAlert(true, "error", error);
        this.setState({ fetching: false });
      }
    });
  };

  setValue = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const { fetching, members } = this.state;
    const query = new URLSearchParams(this.props.location.search);
    const communityLeader = query.get("communityLeader");
    const { user, darkMode } = this.props;

    const { communityId } = this.props.match.params;

    // console.log("members: ", members);
    return (
      <ScrollableDiv
        style={{
          color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE,
          width: "100%",
          maxWidth: 800,
          margin: "0 auto",
        }}
        onEndReach={this.onEndReached}
      >
        <SearchFilter
          hideDateFilter
          inputPlaceholder={i18n.t("COMMUNITY_PROFILE.searchMembers")}
          setValue={this.setValue}
          onRefresh={this.onRefreshMembers}
        />
        {fetching && (members?.length === 0 || !members) ? (
          <div
            style={{
              width: "100%",
              height: "calc(100vh - 200px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppLoader
              color={darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE}
              size="big"
            />
          </div>
        ) : null}
        {members && members?.length !== 0
          ? members.map((item, i) => (
              <UserItem
                isCommunity
                item={item}
                key={i}
                darkMode={darkMode}
                user={user}
                positions={this.state.positions}
                userId={user._id}
                report={this.report}
                quitMember={this.quitMember}
                giveLeadership={this.giveLeadership}
                communityLeader={communityLeader}
                communityId={communityId}
              />
            ))
          : null}
        {members && members?.length !== 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 40,
              width: "100%",
              color: darkMode ? colors.WHITE_MAIN : "#000",
            }}
          >
            {fetching ? (
              <AppLoader
                size="40px"
                color={darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE}
              />
            ) : (
              i18n.t("COMMUNITY_PROFILE.noMoreMembers")
            )}
          </div>
        )}
        {!fetching && (members?.length === 0 || !members) ? (
          <div
            style={{
              width: "100%",
              height: "calc(100vh - 200px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NotFoundItem
              isBlue={darkMode}
              text={
                this.state.search
                  ? i18n.t("COMMUNITY_PROFILE.notMembersMatches")
                  : i18n.t("COMMUNITY_PROFILE.notMembersFound")
              }
            />
          </div>
        ) : null}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        />
      </ScrollableDiv>
    );
  }
}

const CMWR = withRouter(CommunityMembers);

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
};

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CMWR);
