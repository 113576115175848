import { 
  SAVE_FIELD,
  CLEAR_FIELDS
} from "../../types/create-voting/types";

const saveField = (field, value) => {
  return {
    type: SAVE_FIELD,
    data: {
      field: field,
      value: value,
    }
  }
}

const clearFields = () => {
  return {
    type: CLEAR_FIELDS,
  }
}

const actionCreators = {
  clearFields,
  saveField
}

export { actionCreators }