import React, { useState } from 'react';
import '../settings/settings.css';
import VerificationModal from '../../components/verification-modal/verificationModal';
import { connect } from 'react-redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import * as colors from '../../utils/color-palatte';
import { SettingsButton } from '../settings/Settings';
import TwoStepsIcon from '../../assets/security/two_step_icon.svg';
import TwoStepsIconWhite from '../../assets/security/two_step_icon_white.svg';
import ToggleButton from 'react-toggle-button';
import Alert from '../../utils/alert/Alert';
import { updateLoggedUser } from '../../utils/updateLoggedUser';
import editUser from '../../utils/editUser';

const Security = (props) => {
  const { darkMode, user, saveUser } = props;

  const [verification, setVerifcation] = useState({
    isOpen: false,
    onValidateFunc: undefined,
  });

  const [modal, setModal] = useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  const { t } = useTranslation();

  const toggleTwoStepVerification = async () => {
    try {
      if (user.hasTwoStepVerification) {
        setVerifcation({
          ...verification,
          isOpen: true,
          onValidateFunc: async () => {
            await editUser({ prop: 'hasTwoStepVerification', value: false });
            await updateLoggedUser(saveUser);
            setVerifcation({
              ...verification,
              isOpen: false
            })
          }
        })
      } else {
        await editUser({ prop: 'hasTwoStepVerification', value: true });
      }
      await updateLoggedUser(saveUser);
    } catch (e) {
      const error = e?.data?.error?.message?.toString() ?? t('GENERAL_ERRORS.serverError');
      showAlert(true, 'error', error);
    }
  };

  const showAlert = (isOpen, type, content) => {
    setModal({
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  return (
    <div className='Settings__Container'>
      <div
        className='Settings__Title__Container'
        style={{ borderBottom: `1px solid ${darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN}` }}
      >
        <h1 className='Settings__Title' style={{ color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN }}>
          {t('SECURITY.title')}
        </h1>
      </div>
      <SettingsButton
        onClick={() => toggleTwoStepVerification()}
        title={t('SECURITY.twoSteps')}
        icon={darkMode ? TwoStepsIconWhite : TwoStepsIcon}
        darkMode={darkMode}
        rightComponent={
          <ToggleButton
            value={user.hasTwoStepVerification}
            inactiveLabel={''}
            activeLabel={''}
            colors={{
              // activeThumb: {
              //   base: GRAY_MAIN,
              // },
              // inactiveThumb: {
              //   base: GRAY_MAIN,
              // },
              active: {
                base: colors.FEDERAL_BLUE,
                // hover: 'rgb(177, 191, 215)',
              },
              inactive: {
                base: colors.GRAY_MAIN,
                // hover: 'rgb(95,96,98)',
              },
            }}
            trackStyle={{ height: 15, width: 50 }}
            thumbStyle={{ height: 25, width: 25 }}
            // thumbAnimateRange={[-10, 36]}
            // thumbIcon={<ThumbIcon />}
            // onToggle={() => {
            //   this.toggleTwoStepVerification();
            // }}
          />
        }
      />
      <VerificationModal
        isOpen={verification.isOpen}
        user={user}
        onValidateFunc={verification.onValidateFunc}
        onRequestClose={() => setVerifcation({ ...verification, isOpen: false, onValidateFunc: undefined })}
      />
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ ...modal, isOpen: false, type: null, content: undefined })}
      />
    </div>
  );
};

const mapState = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatch = (dispatch) => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
};

export default connect(mapState, mapDispatch)(Security);
