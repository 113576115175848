import React from 'react';
import i18n from '../../i18n/i18n';
import * as colors from '../../utils/color-palatte';
import * as routes from '../../routes/routes';
import { withRouter } from 'react-router-dom';
import { createNewCommunity } from './createCommunityActions.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { saveFile } from '../../utils/saveFilesToCloud';
import { updateLoggedUser } from '../../utils/updateLoggedUser';
import { isUserValid } from '../../utils/isUserValid';
import { UserProfile } from '../../components/ornament-profile/profileUtils';
import AddIcon from '../../assets/edit-profile/add_photo_icon.svg';
import CommunityBG from '../../assets/communities/community-bg.png';
import CommunityProfilePictureDark from '../../assets/communities/community_profile_dark.svg';
import CommunityProfilePicture from '../../assets/communities/community_profile_light.svg';
import { Translation } from 'react-i18next';
import './createCommunity.css';
import RegularInput from '../../utils/app-inputs/regular-input/regular-input';
import RegularTextArea from '../../utils/app-inputs/regular-text-area/regular-text-area';
import CropModal from '../../components/profile/crop-modal/cropModal';
import Resizer from 'react-image-file-resizer';
import { getBase64Size } from '../../utils/getBase64File';
import { getCroppedImg } from '../../components/profile/crop-modal/canvasUtils';
import { readerFile } from '../../components/profile/edit-profile/readerFile';
import OptionModal from '../../components/options-modal/optionsModal';
import { AppLoader } from '../../utils/animations/animations';
import Alert from '../../utils/alert/Alert';

class CreateCommunity extends React.Component {
  state = {
    fetching: false,
    picture: '',
    bgPicture: '',
    name: '',
    description: '',
    isSmall: false,
    matches: false,
    pictureImage: null,
    croppedAreaPixels: null,
    croppedImage: null,
    rotation: 0,
    zoom: 1,
    crop: { x: 0, y: 0 },
    currentEditImage: '',
  };

  rvlRef = React.createRef();
  observer = null;
  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      this.observer = new ResizeObserver((entries) => {
        const boxElem = entries[0];
        if (boxElem.contentRect.width <= 600) {
          if (boxElem.contentRect.width <= 420) {
            this.setState({
              matches: true,
            });
          } else {
            this.setState({
              isSmall: true,
              matches: false,
            });
          }
        } else {
          this.setState({
            isSmall: false,
          });
        }
      });

      this.observer.observe(this.rvlRef.current);
    }

    isUserValid(false, this._isMounted);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.observer.disconnect();
  };

  createCommunity = () => {
    const { name, description, picture, bgPicture } = this.state;
    const { user, saveUser } = this.props;
    if (name && description) {
      this.setState({ fetching: true }, async () => {
        try {
          let pictureUri = picture ? await saveFile(Math.random(), picture, 'image', 'communities') : '';
          let bgPictureUri = bgPicture ? await saveFile(Math.random(), bgPicture, 'image', 'communities') : '';

          // console.log("pictureUri: ", pictureUri);
          // console.log("bgPictureUri: ", bgPictureUri);

          const data = {
            name,
            description,
            leader: user._id,
            bgPicture: bgPictureUri ? bgPictureUri.uri : '',
            picture: pictureUri ? pictureUri.uri : '',
          };

          await createNewCommunity(data);

          await updateLoggedUser(saveUser);

          this.setState(
            {
              fetching: false,
              picture: '',
              bgPicture: '',
              name: '',
              description: '',
            },
            () => {
              this.props.history.push(routes.COMMUNITIES_ROUTE);
            }
          );
        } catch (e) {
          // console.log("createCommunity / error: ", e);
          this.setState({
            fetching: false,
          });
          if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
            this.props.openVerifyModal();
            return;
          }
          
          const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
        }
      });
    } else {
      if (!name) {
        this.showAlert(true, 'warning', i18n.t('COMMUNITIES.missingName'));
        return false;
      }
      if (!description) {
        this.showAlert(true, 'warning', i18n.t('COMMUNITIES.missingDescription'));
        return false;
      }
    }
  };

  // removeImage = (currentPicture) => {
  //   const { selectedImage } = this.state;
  //   this.setState({ editModalLoading: true }, async () => {
  //     if (currentPicture) {
  //       await deleteFiles([currentPicture]);
  //     }

  //     await editUser({ prop: selectedImage, value: "" });
  //     await this.fetchUser();
  //     this.setState({ editModalLoading: false, isEditPictureModalOpen: false });
  //   });
  // };

  editImage = (currentPicture) => {
    const { selectedImage } = this.state;
    this.setState({ editModalLoading: true }, async () => {
      // if(currentPicture){
      //   await deleteFiles([currentPicture])
      //  }

      await this.editUserImage(selectedImage);
      this.setState({ refresh: true });
      this.setState({
        editModalLoading: false,
        isEditPictureModalOpen: false,
        refresh: false,
      });
      await this.fetchUser();
    });
  };

  fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const fileType = file.type.split('/')[0].toLowerCase();
      const extension = file.type.split('/')[1].toLowerCase();
      if (fileType === 'image') {
        Resizer.imageFileResizer(
          file,
          730,
          500,
          extension === 'png' ? 'PNG' : extension === 'webp' ? 'WEBP' : 'JPEG',
          80,
          0,
          (file) => {
            // console.log("imageFileResizer / file: ", file);
            const reader = new FileReader();
            reader.onload = (event) => {
              const uri = event.target.result;
              const sizeInMb = getBase64Size(uri);
              // console.log('uri', uri);
              resolve({
                name: file.name,
                uri: uri,
                type: file.type,
                folder: 'posts',
                sizeInMb: sizeInMb,
              });
            };
            reader.readAsDataURL(file);
          },
          'file'
        );
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const uri = event.target.result;
          const sizeInMb = getBase64Size(uri);
          // console.log('uri', uri);
          const finalUri = `data:video/mp4;base64,${uri.split(',')[1]}`;
          resolve({
            name: file.name,
            uri: finalUri,
            type: 'video',
            folder: 'posts',
            sizeInMb: sizeInMb,
          });
        };
        reader.readAsDataURL(file);
      }
    });

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onRotationChange = (rotation) => {
    this.setState({ rotation });
  };

  handleFileInput = async (e, aspect) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readerFile(file);
      // console.log('imageDataUrl:', imageDataUrl);

      this.setState({ pictureImage: imageDataUrl }, () => this.showCropper(true, imageDataUrl, aspect));
    }
  };

  uploadFile = (aspect) => {
    const file = document.createElement('input');
    file.type = 'file';
    file.style.display = 'none';
    file.accept = 'image/png, image/jpg, image/jpeg, image/heif, image/heic, image/webp, image/gif';
    file.onchange = (e) => this.handleFileInput(e, aspect);
    file.click();
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(this.state.pictureImage, this.state.croppedAreaPixels, this.state.rotation);
      this.setState(
        {
          croppedImage: croppedImage,
        },
        async () => {
          const res = await this.blobToBase64(croppedImage);
          // const file = {
          //   uri: res,
          //   name: `IMG${String(Math.random()).replace("0.", "")}`,
          //   type: "image/png",
          //   // extension: 'jpeg'
          //   folder: "profiles",
          //   // sizeInMb: sizeInMb,
          // };
          // console.log("showCroppedImage / file: ", file);
          this.setState(
            {
              isCropperOpen: false,
            },
            async () => {
              this.showOptions(false);
              if (this.state.selectedImage === 'picture') {
                this.setState({
                  picture: res,
                });
              } else {
                this.setState({ bgPicture: res });
              }
            }
          );
        }
      );
    } catch (e) {
      // console.log("showCroppedImage", e);
      this.showAlert(true, 'error', e);
    }
  };

  blobToBase64 = (blob) => {
    return new Promise(async (resolve, _) => {
      let blobUrl = await fetch(blob).then((r) => r.blob());
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blobUrl);
    });
  };

  changePicture = (uri) => {
    this.showOptions(false);
    this.showCropper(true, uri);
  };

  showOptions = (isOpen) => {
    this.setState({
      isOptionsOpen: isOpen,
    });
  };

  showCropper = (isOpen, uri, aspect) => {
    this.setState({
      isCropperOpen: isOpen,
      cropperImage: uri,
      cropperAspect: aspect,
    });
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const { fetching, name, picture, bgPicture, description } = this.state;
    const {
      //user,
      darkMode,
    } = this.props;

    const profilePic = darkMode ? CommunityProfilePictureDark : CommunityProfilePicture;
    // console.log("user: ", user);
    return (
      <Translation>
        {(t) => (
          <div
            ref={this.rvlRef}
            style={{
              width: '100%',
              maxWidth: 800,
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexFlow: 'column nowrap',
            }}
          >
            <h1
              style={{
                color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Create community
            </h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
                width: '100%',
                maxWidth: 350,
              }}
            >
              <div className='CreateCommunity__PictureContainer'>
                <img
                  src={AddIcon}
                  className='CreateCommunity__Icon'
                  alt=''
                  onClick={() => this.setState({ selectedImage: 'picture' }, () => this.showOptions(true))}
                />
                <UserProfile
                  uri={picture || profilePic}
                  containerStyle={{
                    width: '110px',
                    height: '110px',
                    borderRadius: 'calc(110px / 2)',
                  }}
                />
                <p
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE,
                  }}
                >
                  {picture ? t('COMMUNITIES.CREATE.PROFILE.picture') : t('COMMUNITIES.CREATE.PROFILE.addPicture')}
                </p>
              </div>
              <div className='CreateCommunity__PictureContainer'>
                <img
                  src={AddIcon}
                  className='CreateCommunity__Icon'
                  alt=''
                  onClick={() => this.setState({ selectedImage: 'bgPicture' }, () => this.showOptions(true))}
                />
                <UserProfile
                  uri={bgPicture || CommunityBG}
                  containerStyle={{
                    width: '110px',
                    height: '110px',
                    borderRadius: 'calc(110px / 2)',
                  }}
                  imgStyle={{ objectFit: 'cover' }}
                />
                <p
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE,
                  }}
                >
                  {bgPicture ? t('COMMUNITIES.CREATE.PROFILE.bg') : t('COMMUNITIES.CREATE.PROFILE.addBg')}
                </p>
              </div>
            </div>
            <div className='CreateCommunity__Form'>
              <div className='CreateCommunity__InputContainer'>
                <p
                  style={{
                    color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE,
                    margin: 5,
                  }}
                >
                  {t('COMMUNITIES.CREATE.name')}
                </p>
                <RegularInput isWhite={darkMode} name='name' autoComplete='off' value={name} onChange={this.handleChange} />
              </div>
              <div className='CreateCommunity__InputContainer' style={{ marginTop: 10 }}>
                <p
                  style={{
                    color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE,
                    margin: 5,
                  }}
                >
                  {t('COMMUNITIES.CREATE.description')}
                </p>
                <RegularTextArea isWhite={darkMode} name='description' value={description} onChange={this.handleChange} />
              </div>
              <button onClick={() => this.createCommunity()}>
                {fetching ? <AppLoader size='small' color={colors.WHITE_MAIN} /> : t('COMMUNITIES.CREATE.create')}
              </button>
            </div>
            <OptionModal
              isOpen={this.state.isOptionsOpen}
              onRequestClose={() => this.setState({ isOptionsOpen: false })}
              show={
                this.state.selectedImage === 'picture'
                  ? {
                      edit: {
                        display: true,
                        title: t('EDIT_COMMUNITY.BUTTONS.editCommunityPicture'),
                        onClick: () => this.uploadFile(),
                      },
                      delete: {
                        display: picture ? true : false,
                        title: t('EDIT_COMMUNITY.BUTTONS.deletePicture'),
                        onClick: () => this.setState({ picture: '' }),
                      },
                    }
                  : {
                      edit: {
                        display: true,
                        title: t('EDIT_PROFILE.BUTTONS.editBgPicture'),
                        onClick: () => this.uploadFile(5 / 2),
                      },
                      delete: {
                        display: bgPicture ? true : false,
                        title: t('EDIT_COMMUNITY.BUTTONS.deletePicture'),
                        onClick: () => this.setState({ bgPicture: '' }),
                      },
                    }
              }
            />
            <CropModal
              mobile={this.state.matches}
              isOpen={this.state.isCropperOpen}
              uri={this.state.cropperImage}
              darkMode={darkMode}
              onRequestClose={() => this.setState({ isCropperOpen: false, imageToCropper: null })}
              aspect={this.state.cropperAspect || 1}
              action={() => this.showCroppedImage()}
              onCropComplete={this.onCropComplete}
              zoom={this.state.zoom}
              crop={this.state.crop}
              rotation={this.state.rotation}
              onZoomChange={this.onZoomChange}
              onCropChange={this.onCropChange}
              onRotationChange={this.onRotationChange}
              firstInputProps={{
                type: 'range',
                min: 1,
                max: 4,
                step: 0.1,
                value: this.state.zoom,
                name: 'zoom',
                onChange: this.handleChange,
              }}
              secondInputProps={{
                type: 'range',
                max: '360',
                min: 0,
                step: 1,
                value: this.state.rotation,
                name: 'rotation',
                onChange: this.handleChange,
              }}
            />
            <Alert
              isOpen={this.state.isAlertOpen}
              type={this.state.alertType}
              content={this.state.alertContent}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
            />
          </div>
        )}
      </Translation>
    );
  }
}

const CCWR = withRouter(CreateCommunity);

function mapDispatchToProps(dispatch) {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    openVerifyModal: bindActionCreators(actions.openVerifyModal, dispatch)
  };
}

function mapStateToProps(state) {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CCWR);
