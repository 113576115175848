import React from 'react';
import { withRouter } from 'react-router-dom';
import './change-password.css';
import { postData } from '../../fetch';
import { Translation } from 'react-i18next';
import PasswordIcon from '../../assets/change-password/iconPassword.svg';
import PasswordInput from '../../utils/app-inputs/password-input/password-input';
import SendButton from '../../utils/send-button/send-button';
import { AppLoader } from '../../utils/animations/animations';
import * as routes from '../../routes/routes';
import Alert from '../../utils/alert/Alert';
import i18n from '../../i18n/i18n';

class ChangePassword extends React.Component {
  state = {
    password: '',
    newPassword: '',
    confirmPassword: '',
    isFetching: false,
  };

  changePassword = (event) => {
    event.preventDefault();
    const { confirmPassword, newPassword } = this.state;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token = url.searchParams.get('token');
    // console.log("token: ", token);
    // console.log("newPassword: ", newPassword);
    // console.log("confirmPassword: ", confirmPassword);
    if (
      // password &&
      // password.length >= 6 &&
      token &&
      newPassword &&
      newPassword.length >= 6 &&
      confirmPassword === newPassword
    ) {
      this.setState({ isFetching: true }, async () => {
        const data = { 
          token, 
          password: newPassword,
         };
        // console.log("changePassword / data: ", data)
        // console.log('Token', token);
        try {
          const response = await postData('/user/change-password', data, false);
          // console.log("changePassword / response: ", response);
          // alert(response.body);
          this.showAlert(true, 'success', response.body);
          this.setState({ isFetching: false });
          this.props.history.replace(routes.PASSWORD_DONE_ROUTE);
        } catch (e) {
          this.setState({ isFetching: false });
          // console.log('changePassword / error: ', e);
          const errorCode = e && e.data.error && e.data.error.code ? e.data.error.code : null
          const error =
            e && e.data.error && e.data.error.message
              ? e.data.error.message
              : i18n.t('GENERAL_ERRORS.serverError');
          // alert(error);
          this.showAlert(true, 'error', error, errorCode && errorCode === "no-valid-token" ? i18n.t('CHANGE_PASSWORD.ERRORS.tokenExpiredTitle') : null );
        }
      });
    } else {
      if (!token) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASSWORD.ERRORS.missngToken')
        );
      }
      if (!newPassword) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASSWORD.ERRORS.missingPassword')
        );
      }
      if (confirmPassword !== newPassword) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASSWORD.ERRORS.verifyPassword')
        );
      }
      if (newPassword && newPassword.length < 6) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASSWORD.ERRORS.shortPassword')
        );
      }
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.changePassword(event)
    }
  };

  showAlert = (isOpen, type, message, title) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertMessage: message,
      alertTitle: title,
    });
  };

  render() {
    const { isFetching, newPassword, confirmPassword } = this.state;
    // console.log("isFetching: ", isFetching);
    return (
      <Translation>
        {(t) => (
          <div className='ChangePassword__Container'>
            <div className='ChangePassword__Form-Container'>
              <img
                src={PasswordIcon}
                className='ChangePassword__Image'
                alt=''
              />
              <form
                className='ChangePassword__Form'
                onSubmit={(event) => this.changePassword(event)}
              >
                {/* <h1 className='ChangePassword__Title'>
                  {t('CHANGE_PASSWORD.title')}
                </h1>
                <PasswordInput
                  isWhite
                  placeholder={t('CHANGE_PASSWORD.current')}
                  disabled={isFetching}
                  onChange={this.handleInputChange}
                  onKeyPress={this.handleKeyPress}
                  value={password}
                  name='password'
                /> */}
                <PasswordInput
                  isWhite
                  placeholder={t('CHANGE_PASSWORD.newPassword')}
                  disabled={isFetching}
                  onChange={this.handleInputChange}
                  onKeyPress={this.handleKeyPress}
                  value={newPassword}
                  name='newPassword'
                />
                <PasswordInput
                  isWhite
                  placeholder={t('CHANGE_PASSWORD.repeat')}
                  disabled={isFetching}
                  onChange={this.handleInputChange}
                  onKeyPress={this.handleKeyPress}
                  value={confirmPassword}
                  name='confirmPassword'
                />
                <div
                  style={{
                    width: '100%',
                    borderBottom: '1px solid white',
                    marginTop: '10px',
                  }}
                />
                <p className='ChangePassword__Dialogue'>
                  {t('CHANGE_PASSWORD.dialogue')}
                </p>
                <SendButton
                  className='ChangePassword__SendButton'
                  textContent={t('CHANGE_PASSWORD.continue')}
                  disabled={isFetching}
                  style={{ pointerEvents: isFetching ? 'none' : undefined }}
                  renderDisabled={
                    <AppLoader size='small' />
                  }
                />
              </form>
            </div>
            <Alert
              isOpen={this.state.isAlertOpen}
              content={this.state.alertMessage}
              type={this.state.alertType}
              title={this.state.alertTitle}
              onRequestClose={() =>
                this.setState({
                  isAlertOpen: false,
                })
              }
            />
          </div>
        )}
      </Translation>
    );
  }
}

export default withRouter(ChangePassword);
