import { postData } from '../../fetch';

export const sendRemoveUserCode = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/user/send-remove-user-code-web', data);
      // console.log('[sendRemoveUserCode Response]', response);
      resolve(response);
    } catch (e) {
      // console.log('[sendRemoveUserCode reject]:', e);
      reject(e);
    }
  });

export const removeUser = (data) => 
  new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/user/remove-user-web', data);
      // console.log('[removeUser] response:', response);
      resolve(response)
    } catch (e) {
      // console.log('[removeUser] reject:', e);
      reject(e)
    }
  })
