// import * as SecureStore from 'expo-secure-store';
import { putData } from '../fetch';

export const signOutEndpoint = async (id) => {
  const userId = localStorage.getItem('userId');
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('token');
      // console.log('userId: ', userId)
      // console.log('token: ', token)
      if (token && (id || userId )) {
        const response = await putData(`/user/sign-out-web/${id || userId}`);
        // localStorage.clear()
        // console.log('signOutEndpoint/Response:', response)
        return resolve(response);
      } else {
        return resolve();
      }
    } catch (e) {
      // console.log('signOutEndpoint/Error:', e)
      return reject(e);
    }
  });
};

export default signOutEndpoint;