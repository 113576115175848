import React, { useState, useEffect } from 'react';
import AlertModal from '../../../utils/modal/Modal';
import CloseIcon from '../../../assets/terms-modal/close-btn.svg';
import IDCard from '../../id-card/id-card';
import { Translation } from 'react-i18next';
import './idModal.css';

const IdModal = (props) => {
  const { isOpen, onRequestClose, user } = props;

  const [first, setFirst] = useState(
    window.matchMedia('(max-width: 700px)').matches
  );
  const [second, setSecond] = useState(
    window.matchMedia('(max-width: 590px)').matches
  );
  const [third, setThird] = useState(
    window.matchMedia('(max-width: 440px)').matches
  );

  useEffect(() => {
    window
      .matchMedia('(max-width: 700px)')
      .addEventListener('change', (e) => setFirst(e.matches));
    window
      .matchMedia('(max-width: 590px)')
      .addEventListener('change', (e) => setSecond(e.matches));
    window
      .matchMedia('(max-width: 440px)')
      .addEventListener('change', (e) => setThird(e.matches));
    // eslint-disable-next-line
  }, []);

  return (
    <Translation>
      {(t) => (
        <AlertModal
          isOpen={isOpen}
          modalClassName='IdModal__Container'
          overlayClassName='IdModal__Overlay'
          outsideClick={onRequestClose}
        >
          <div className='IdModal__Header'>
            <h1
              style={{
                fontSize: first
                  ? second
                    ? third
                      ? '1.2rem'
                      : '1.7rem'
                    : '1.9rem'
                  : '2.1rem',
              }}
            >
              {t('PROFILE.IDModal.title')}
            </h1>
            <img src={CloseIcon} alt='close' onClick={onRequestClose} />
          </div>
          <p className='IdModal__SubTitle'>{t('PROFILE.IDModal.subTitle')}</p>
          <IDCard
            style={{
              transform: `scale(${
                first
                  ? second
                    ? third
                      ? '.465, .465'
                      : '.6, .6'
                    : '.8, .8'
                  : '1, 1'
              })`,
              transformOrigin: 'top',
              marginBottom: first
                ? second
                  ? third
                    ? '-200px'
                    : '-140px'
                  : '-70px'
                : 'unset',
            }}
            user={user}
          />
          <button className='IdModal__Continue' onClick={onRequestClose}>
            {t('PROFILE.IDModal.continue')}
          </button>
        </AlertModal>
      )}
    </Translation>
  );
};

export default IdModal;
