import React from 'react';
import './download-app.css';
//import Button from "../../utils/Button";
//import { FEDERAL_WHITE } from '../../utils/color-palatte';
//import { Translation } from 'react-i18next';
import HomeDownload from '../home/home-download/home-download';

class DownloadApp extends React.Component {

  render() {
    /*return (
      <Translation>
        {t =>
          <div className="container">
            <div style={{ width: '100%' }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                autoComplete="off"
                noValidate
                className="form-container"
              >
                <h3 style={{ color: FEDERAL_WHITE }}>{t("download-app")}</h3>
                <Button
                  loading={false}
                  width="auto"
                  height="43px"
                  type="submit"
                  content={t("download-app-button")}
                />
              </form>
            </div>
          </div>
        }
      </Translation>
      );*/
    return (
      <div className="container">
        <HomeDownload />
      </div>
    );
  }
}

export default DownloadApp;
