import React, { useState } from 'react';
import AlertModal from '../../../utils/modal/Modal';
import Flag from '../../../assets/payment-modals/flag_citizen.png';
import { Translation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as routes from '../../../routes/routes';
import './NPAModal.css';
import PaymentWarnnigModal from '../payment-warning/paymentWarningModal';
import PaymentMethodModal from '../payment-method-modal/paymentMethodModal';
import AddCreditCardModal from '../add-credit-card-modal/addCreditCardModal';
import PaymentRenovDoneModal from '../payment-received/paymentReceivedModal';
import SubAdviceModal from '../sub-advice/subAdviceModal';
import LogOutIcon from '../../../assets/settings/logout-icon.svg';
import PaymentDetailsIcon from '../../../assets/payment-modals/payment_history_icon.svg';
import { store } from '../../../redux/configureStore';
import { CLOSE_PAYMENT_MODAL_AGREEMENT, LOGOUT } from '../../../redux/types/user/types';
import signOutEndpoint from '../../../utils/signOutEndpoint';

const NewPaymentModal = ({ isOpen, logout }) => {
  const [isOpenAdvise, setIsOpenAdvise] = useState(false);
  const [isPatmentMethodModal, setIsPatmentMethodModal] = useState(false);
  const [isAddCreditCardModal, setIsAddCreditCardModal] = useState(false);
  const [isPaymentReceivedModal, setPaymentReceivedModal] = useState(false);
  const [isNewWarningModal, setIsNewWarningModal] = useState(false);

  // console.log("store.getState().user: ", store.getState().user)
  const goToWarningModal = () => {
    const { user } = store.getState().user;
    // console.log("goToWarningModal / user: ", user)
    const paymentDate = user && user.paymentDate;
    const isNew = !paymentDate;
    store.dispatch({ type: CLOSE_PAYMENT_MODAL_AGREEMENT, data: null });
    if (isNew) {
      setIsNewWarningModal(true);
    } else {
      setIsOpenAdvise(true);
    }
  };

  const history = useHistory();

  const logoutFunc = async () => {
    await signOutEndpoint();
    store.dispatch({ type: LOGOUT, data: null });
    store.dispatch({ type: CLOSE_PAYMENT_MODAL_AGREEMENT, data: null });
  };

  const goToPayments = () => {
    store.dispatch({ type: CLOSE_PAYMENT_MODAL_AGREEMENT, data: null });
    history.push(routes.USER_PATMENTS_ROUTE)
  }

  return (
    <Translation>
      {(t) => (
        <>
          <AlertModal
            isOpen={isOpen}
            modalClassName='NewPayment__Container'
            overlayClassName='NewPayment__Overlay'
            // outsideClick={setIsOpenAgreement}
          >
            <img src={Flag} alt='' className='NewPayment__img' />
            <div className='NewPayment__Content'>
              <h1>{t('PAYMENTS_MODALS.NEW_PAYMENTS_AG.title')}</h1>
              <p>{t('PAYMENTS_MODALS.NEW_PAYMENTS_AG.text')}</p>
              <button
                onClick={() => {
                  goToWarningModal();
                }}
              >
                {t('PAYMENTS_MODALS.continue')}
              </button>
              <button
                style={{
                  backgroundImage: `url(${PaymentDetailsIcon})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '100% 50%',
                  backgroundSize: '60px',
                  marginBottom: 50
                }}
                onClick={() => goToPayments()}
              >
                {t('USER_FINANCE.paymentsHistory')}
              </button>
              <img src={LogOutIcon} alt='logout' onClick={() => logoutFunc()} className='NewPayment__Logout' />
            </div>
          </AlertModal>
          {isOpenAdvise && (
            <PaymentWarnnigModal //service expired
              isOpen={isOpenAdvise}
              onPayClick={() => {
                setIsOpenAdvise(false);
                setIsPatmentMethodModal(true);
              }}
            />
          )}
          {isNewWarningModal && (
            <SubAdviceModal //new service
              isOpen={isNewWarningModal}
              onPayClick={() => {
                setIsNewWarningModal(false);
                setIsPatmentMethodModal(true);
              }}
            />
          )}
          {isPatmentMethodModal && (
            <PaymentMethodModal
              isOpen={isPatmentMethodModal}
              onCreditCardClick={() => {
                setIsPatmentMethodModal(false);
                setIsAddCreditCardModal(true);
              }}
            />
          )}
          {isAddCreditCardModal && (
            <AddCreditCardModal
              isOpen={isAddCreditCardModal}
              onRequestClose={() => setIsAddCreditCardModal(false)}
              onFinishPayment={() => {
                setIsAddCreditCardModal(false);
                setPaymentReceivedModal(true);
              }}
            />
          )}
          {isPaymentReceivedModal && (
            <PaymentRenovDoneModal
              isOpen={isPaymentReceivedModal}
              onFinishPayment={() => {
                setPaymentReceivedModal(false);
              }}
              onRequestClose={() => {
                setPaymentReceivedModal(false);
              }}
            />
          )}
        </>
      )}
    </Translation>
  );
};

export default NewPaymentModal;
