import * as types from '../../types/utils/types';

const initialState = {
  chatUser: null,
  foreignUser: null,
}

const saveChatUser = (state, payload) => ({
  ...state,
  chatUser: payload.data,
})

const deleteChatUser = state => ({
  ...state,
  chatUser: null,
})

const saveForeignUser = (state, payload) => ({
  ...state,
  foreignUser: payload.data,
})

const removeForeignUser = state => ({
  ...state,
  foreignUser: null,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_USER_CHAT:
      return saveChatUser(state, action);
    case types.REMOVE_USER_CHAT:
      return deleteChatUser(state);
    case types.SAVE_FOREIGN_USER:
      return saveForeignUser(state, action);
    case types.REMOVE_FOREIGN_USER:
      return removeForeignUser(state, action);
    default:
      return state;
  }
}

export default reducer;