import React from 'react';
import { SettingsButton } from '../settings/Settings';
import '../settings/settings.css';
import * as colors from '../../utils/color-palatte';
import * as routes from '../../routes/routes'
import i18n from '../../i18n/i18n';
// import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import DelIcon from '../../assets/privacy/del_icon.svg';
import DelIconWhite from '../../assets/privacy/del_icon_white.svg';
import NotificationIcon from '../../assets/settings/notification_icon.svg';
import NotificationIconWhite from '../../assets/settings/notification_icon_white.svg';
import Alert from '../../utils/alert/Alert';
import ToggleButton from 'react-toggle-button';
import editUser from '../../utils/editUser';
import { updateLoggedUser } from '../../utils/updateLoggedUser';
import { getToken } from '../../firebase/config';
import { useHistory } from 'react-router-dom';

const Privacy = (props) => {
  const { darkMode, user, saveUser } = props;

  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: '',
    onClose: undefined,
  });

  const history = useHistory()

  const toggleNotifications = async () => {
    try {
      if (user?.pushToken?.length > 8) {
        await editUser({prop: 'pushToken', value: Math.floor(10000000 + Math.random() * 90000000)})
      } else {
        const token = await getToken();
        // console.log('token', token)
        await editUser({ prop: 'pushToken', value: token })
      }
      updateLoggedUser(saveUser)
    } catch (e) {
      // console.log(e)
      const error = e?.toString()?.includes("The notification permission was not granted") ? i18n.t('GENERAL_ERRORS.notificationAllowed') : i18n.t('GENERAL_ERRORS.serverError');
      showAlert(true, 'error', error, () => setModal({ ...modal, isOpen: false }))
    }
  }

  const showAlert = (isOpen, type, content, onClose) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
      onClose: onClose,
    });
  };

  return (
    <div className='Settings__Container'>
      <div
        className='Settings__Title__Container'
        style={{ borderBottom: `1px solid ${darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN}` }}
      >
        <h1 className='Settings__Title' style={{ color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN }}>
          {i18n.t('PRIVACY.title')}
        </h1>
      </div>
      <SettingsButton
        onClick={() => {
          toggleNotifications();
        }}
        title={i18n.t('SETTINGS.notifications')}
        icon={darkMode ? NotificationIconWhite : NotificationIcon}
        darkMode={darkMode}
        rightComponent={
          <ToggleButton
            value={user?.pushToken?.length > 8}
            inactiveLabel={''}
            activeLabel={''}
            colors={{
              // activeThumb: {
              //   base: GRAY_MAIN,
              // },
              // inactiveThumb: {
              //   base: GRAY_MAIN,
              // },
              active: {
                base: colors.FEDERAL_BLUE,
                // hover: 'rgb(177, 191, 215)',
              },
              inactive: {
                base: colors.GRAY_MAIN,
                // hover: 'rgb(95,96,98)',
              },
            }}
            trackStyle={{ height: 15, width: 50 }}
            thumbStyle={{ height: 25, width: 25 }}
          />
        }
      />
      <SettingsButton
        onClick={() => history.push(routes.DELETE_YOU_ACCOUNT)}
        title={i18n.t('PRIVACY.dropOut')}
        icon={darkMode ? DelIconWhite : DelIcon}
        darkMode={darkMode}
      />
      <Alert isOpen={modal.isOpen} type={modal.type} content={modal.content} onRequestClose={modal.onClose} />
    </div>
  );
};

function mapStateToProps(state) {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
