import { getData, postData } from "../../fetch";

export const getUserPayments = (skipItems, limitItems, search, admin) => {
  return new Promise(async (resolve, reject) => {
      try {
        const userId = localStorage.getItem("userId")
        const response = await getData(`/payments/get-user-payments-web/?userId=${userId}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&admin=${admin}`);
        // console.log("getPosts / response: ", response);
        const payments = response.body;
        return resolve(payments);
      } catch (e) {
        // console.log("getPosts / error: ", e);
        return reject(e);
      }
  })
};

export const getPaymentDetails = (paymentId) => {
  return new Promise(async (resolve, reject) => {
      try {
        const userId = localStorage.getItem("userId")
        const response = await getData(`/payments/get-user-payment-web/?userId=${userId}&paymentId=${paymentId}`);
        // console.log("getVotingDetails / response: ", response);
        const deal = response.body;
        return resolve(deal);
      } catch (e) {
        // console.log("getVotingDetails / error: ", e);
        return reject(e);
      }
  })
};

export const cancelPayment = (paymentId) => {
  return new Promise(async (resolve, reject) => {
      try {
        const userId = localStorage.getItem("userId")
        const response = await postData(`/payments/cancel-payment-web/?userId=${userId}&paymentId=${paymentId}`);
        // console.log("getVotingDetails / response: ", response);
        const payment = response.body;
        return resolve(payment);
      } catch (e) {
        // console.log("getVotingDetails / error: ", e);
        return reject(e);
      }
  })
};