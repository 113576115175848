import React from 'react';
import AlertModal from '../../utils/modal/Modal';
import RegularInput from '../../utils/app-inputs/regular-input/regular-input';
import PasswordInput from '../../utils/app-inputs/password-input/password-input';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { DeleteModalButton } from './deleteWarnModal';
import * as colors from '../../utils/color-palatte';
import * as routes from '../../routes/routes';
import { removeUser } from '../../pages/privacy/privacyActions';
import { useHistory } from 'react-router-dom';
import Alert from '../../utils/alert/Alert';
// import signOutEndpoint from '../../utils/signOutEndpoint';
import { store } from '../../redux/configureStore';
import { OPEN_DELETE_SUCCESS_MODAL } from '../../redux/types/user/types';

const DeleteSecurityModal = (props) => {
  const { isOpen, onRequestClose, logOutFunc } = props;

  const { t } = useTranslation();

  const [isSmall, setIsSmall] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [values, setValues] = React.useState({
    code: '',
    password: '',
  });

  const [alert, setAlert] = React.useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  const history = useHistory();

  const rvlRef = React.useRef();
  let observer = null;

  React.useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line
      observer = new ResizeObserver((entries) => {
        const boxElem = entries[0];
        if (boxElem.contentRect.width < 400) {
          setIsSmall(true);
        } else {
          setIsSmall(false);
        }
      });
      if (observer) {
        observer.observe(rvlRef.current);
      }
    }
    return () => {
      if (observer) {
        observer.disconnect();
        setValues({
          code: '',
          password: ''
        })
      }
    };
  }, [isOpen, rvlRef, isSmall]);

  const deleteAccount = async () => {
    if (values.code && values.password) {
      setIsFetching(true);
      return new Promise(async (resolve, _) => {
        try {
          const data = {
            code: values.code,
            password: values.password,
          };
          await removeUser(data);
          // console.log(res);
          // await signOutEndpoint();
          setIsFetching(false);
          logOutFunc();
          store.dispatch({ type: OPEN_DELETE_SUCCESS_MODAL, data: null })
          onRequestClose();
          history.push(routes.HOME_ROUTE);
          resolve();
        } catch (e) {
          setIsFetching(false);
          const error = e?.data?.error?.message ?? e?.data?.error ?? t('GENERAL_ERRORS.serverError');
          // console.log(e);
          showAlert(true, 'error', String(error));
        }
      });
    } else {
      if (!values.code) {
        showAlert(true, 'warning', t('DELETE_ACCOUNT.MODALS.DELETE_SECURITY.ERRORS.missingCode'));
        return;
      }
      if (!values.password) {
        showAlert(true, 'warning', t('DELETE_ACCOUNT.MODALS.DELETE_SECURITY.ERRORS.missingPassword'));
        return;
      }
    }
  };

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
    // console.log('code', values.code);
    // console.log('password', values.password);
  };

  const showAlert = (isOpen, type, content) => {
    setAlert({
      ...alert,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  return (
    <AlertModal
      ref={rvlRef}
      isOpen={isOpen}
      modalClassName={styles.modal__2}
      overlayClassName={styles.overlay}
      outsideClick={onRequestClose}
    >
      <h1>{t('DELETE_ACCOUNT.MODALS.DELETE_SECURITY.title')}</h1>
      <p>{t('DELETE_ACCOUNT.MODALS.DELETE_SECURITY.content')}</p>
      <div
        style={{
          width: `calc(100% - ${isSmall ? 20 : 120}px)`,
          marginBottom: 150,
        }}
      >
        <RegularInput
          name='code'
          value={values.code}
          onChange={handleInputChange}
          isWhite
          placeholder={t('DELETE_ACCOUNT.MODALS.DELETE_SECURITY.INPUTS.code')}
          autoComplete='off'
        />
        <PasswordInput
          name='password'
          value={values.password}
          onChange={handleInputChange}
          isWhite
          placeholder={t('DELETE_ACCOUNT.MODALS.DELETE_SECURITY.INPUTS.password')}
          autoComplete='off'
        />
      </div>
      <div
        className={styles.buttonContainer}
        style={{
          flexFlow: isSmall ? 'column' : 'row',
          height: `${isSmall ? 140 : 80}px`,
          justifyContent: isSmall ? 'center' : 'space-around',
        }}
      >
        <DeleteModalButton
          text={t('DELETE_ACCOUNT.MODALS.DELETE_SECURITY.button')}
          fetching={isFetching}
          hasLoader
          style={{ backgroundColor: colors.WARNING_YELLOW, color: colors.FEDERAL_BLUE }}
          hoverStyle={{ backgroundColor: colors.FEDERAL_LOW_BLUE, color: colors.FEDERAL_BLUE }}
          onClick={deleteAccount}
          loaderColor={colors.WHITE_MAIN}
        />
        <DeleteModalButton
          text={t('GENERAL_TEXT.cancel')}
          fetching={isFetching}
          style={{ backgroundColor: colors.WHITE_MAIN, color: colors.FEDERAL_BLUE }}
          hoverStyle={{ backgroundColor: colors.FEDERAL_LOW_BLUE, color: colors.FEDERAL_BLUE }}
          onClick={() => onRequestClose()}
          loaderColor={colors.WHITE_MAIN}
        />
      </div>
      <Alert
        isOpen={alert.isOpen}
        type={alert.type}
        content={alert.content}
        onRequestClose={() => setAlert({ ...alert, isOpen: false, type: null, content: undefined })}
      />
    </AlertModal>
  );
};

export default DeleteSecurityModal;
