import { store } from '../redux/configureStore';
import { postData } from '../fetch';
import { SHOW_PAYMENT_MODAL_AGREEMENT } from '../redux/types/user/types';
// import { useHistory } from 'react-router';
// import * as routes from '../routes/routes'

const validateUserPayment = () => {
  return new Promise(async (resolve, reject) => {
    // const history = useHistory()
    try {
      const state = store.getState().user;
      // console.log("validateUserPayment / props: ", props)
      if (state.user && !state.user.admin) {
        const userHasPay = await validatePayment({ userId: state.user._id });
        // console.log("userHasPay: ", userHasPay);
        if (userHasPay && userHasPay.body && userHasPay.body.needToPay) {
          // history.push(routes.NEW_PAYMENT_AGG)
          await store.dispatch({ type: SHOW_PAYMENT_MODAL_AGREEMENT, data: null });
          resolve();
        }
        resolve();
      } else {
        resolve();
      }
    } catch (e) {
      resolve(e);
    }
  });
};

const validatePayment = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/payments/validate-user-payment-web', data);
      // console.log("validatePayment / response: ", response)
      resolve(response);
      resolve();
    } catch (e) {
      // console.log("validatePayment / error: ", e)
      reject(e);
    }
  })
};

export default validateUserPayment;
