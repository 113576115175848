import React from 'react';
import MailIcon from '../../../assets/edit-profile/mail_icon.svg';
import MailIconWhite from '../../../assets/edit-profile/mail_icon_white.svg';
import { getUser } from '../../../pages/profile/profileActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../../redux/actions/user/actions';
import AlertModal from '../../../utils/modal/Modal';
import Alert from '../../../utils/alert/Alert';
import CloseIcon from '../../../assets/terms-modal/close-btn.svg';
import CloseIconWhite from '../../../assets/terms-modal/close-btn-white.svg';
import i18n from '../../../i18n/i18n';
import { Translation } from 'react-i18next';
import { emailsRegex } from '../../../utils/regex';
import { changeEmail, changeEmailFinal } from './editUserActions';
import { FEDERAL_BLUE, GRAY_SECOND_TRANSPARENCY_FULL, UNSATURATED_BLACK_BLUE, WHITE_MAIN } from '../../../utils/color-palatte';
import RegularInput from '../../../utils/app-inputs/regular-input/regular-input';
import { AppLoader } from '../../../utils/animations/animations';
import { EditProfileButton } from './editProfile';
import './changeEmailModal.css';

class ChangeEmailModal extends React.Component {
  state = {
    newEmail: '',
    modalLoader: false,
    resendLoader: false,
    codeSended: false,
  };

  componentDidMount = () => {
    window.addEventListener('keydown', (e) => this.handleKeyPress(e));
  };

  componentWillUnmount = () => {
    window.removeEventListener('keydown', (e) => this.handleKeyPress(e));
  };

  fetchUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const userId = localStorage.getItem('userId');
        const user = await getUser(userId);
        this.props.saveUser(user);
        resolve();
      } catch (e) {
        const error = e?.data?.error ?? e?.data ?? e;
        this.showAlert(true, 'error', error);
        reject();
      }
    });
  };

  changeEmail = () => {
    const { newEmail } = this.state;
    if (newEmail && newEmail.match(emailsRegex)) {
      this.setState({ resendLoader: true }, async () => {
        try {
          await this.fetchUser();
          const { user } = this.props;
          const data = {
            newEmail,
            oldEmail: user.email,
          };
          await changeEmail(data);
          this.setState({
            codeSended: true,
            resendLoader: false,
            newEmailCode: '',
            oldEmailCode: '',
          });
          this.showAlert(true, 'success', i18n.t('CHANGE_EMAIL.checkEmailCodes'));
        } catch (e) {
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
        }
      });
    } else {
      if (!newEmail) {
        this.showAlert(true, 'wrong', i18n.t('CHANGE_EMAIL.missingNewEmail'));
        return false;
      }
      if (!newEmail.match(emailsRegex)) {
        this.showAlert(true, 'warning', i18n.t('CHANGE_EMAIL.newEmailInvalid'));
        return false;
      }
    }
  };

  changeEmailFinal = () => {
    const { newEmailCode, oldEmailCode, newEmail } = this.state;
    const { user } = this.props;
    if (newEmailCode && oldEmailCode) {
      this.setState({ modalLoader: true }, async () => {
        try {
          const data = {
            newEmailCode,
            oldEmailCode,
            newEmail,
            oldEmail: user.email,
          };
          await changeEmailFinal(data);
          await this.fetchUser();
          this.setState({
            modalLoader: false,
            codeSended: false,
            newEmail: '',
            newEmailCode: '',
            oldEmailCode: '',
          });
          this.showAlert(true, 'success', i18n.t('CHANGE_EMAIL.emailChanged'));
        } catch (e) {
          this.setState({ modalLoader: false });
          const error = e?.data?.error?.message ?? e?.data?.error ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
        }
      });
    } else {
      if (!newEmailCode) {
        this.showAlert(true, 'wrong', i18n.t('CHANGE_EMAIL.missingNewEmailCode'));
        return false;
      }
      if (!oldEmailCode) {
        this.showAlert(true, 'wrong', i18n.t('CHANGE_EMAIL.missingOldEmailCode'));
        return false;
      }
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  handleKeyPress = (e) => {
    // const { key } = e;
    const { codeSended, oldEmailCode, newEmailCode, newEmail } = this.state;
    if (e.key === 'Enter') {
      if (codeSended) {
        if (!oldEmailCode && !newEmailCode) return false;
        this.changeEmailFinal();
      } else {
        if (!newEmail) return false;
        this.changeEmail();
      }
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.props.onRequestClose();
    }
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const { newEmail, modalLoader, resendLoader, codeSended, newEmailCode, oldEmailCode } = this.state;

    const { /* user, */ darkMode, isOpen, onRequestClose } = this.props;

    return (
      <Translation>
        {(t) => (
          <AlertModal
            isOpen={isOpen}
            outsideClick={onRequestClose}
            overlayClassName='ChangeEmailModal__Overlay'
            modalClassName='ChangeEmailModal__Container'
            style={{
              backgroundColor: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
            }}
          >
            {resendLoader || modalLoader ? null : (
              <img
                src={darkMode ? CloseIconWhite : CloseIcon}
                alt='close'
                onClick={onRequestClose}
                className='ChangeEmailModal__CloseBtn'
              />
            )}
            <h1
              className='ChangeEmailModal__Title'
              style={{
                color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                borderBottom: `1px solid ${darkMode ? '#FFF5' : GRAY_SECOND_TRANSPARENCY_FULL}`,
              }}
            >
              {t('CHANGE_EMAIL.title')}
            </h1>
            <img src={darkMode ? MailIconWhite : MailIcon} alt='' className='ChangeEmailModal__Icon' draggable='false' />
            <div className='ChangeEmailModal__Form'>
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: '0' }}>{t('CHANGE_EMAIL.newEmail')}</p>
              <RegularInput
                name='newEmail'
                value={newEmail}
                isWhite={darkMode}
                onChange={this.handleInputChange}
                disabled={resendLoader || modalLoader}
                autoComplete='off'
              />
              {codeSended ? (
                <>
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      margin: '0',
                    }}
                  >
                    {t('CHANGE_EMAIL.newEmailCode')}
                  </p>
                  <RegularInput
                    isWhite={darkMode}
                    name='newEmailCode'
                    value={newEmailCode}
                    onChange={this.handleInputChange}
                    disabled={resendLoader || modalLoader}
                    autoComplete='off'
                  />
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      margin: '0',
                    }}
                  >
                    {t('CHANGE_EMAIL.oldEmailCode')}
                  </p>
                  <RegularInput
                    isWhite={darkMode}
                    name='oldEmailCode'
                    value={oldEmailCode}
                    onChange={this.handleInputChange}
                    disabled={resendLoader || modalLoader}
                    autoComplete='off'
                  />
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      background: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      margin: '10px 0',
                    }}
                  />
                  {modalLoader ? (
                    <div
                      style={{
                        width: '100%',
                        height: 60,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AppLoader size={40} color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
                    </div>
                  ) : (
                    <EditProfileButton
                      onClick={() => this.changeEmailFinal()}
                      style={{
                        marginBottom: '10px',
                        opacity: resendLoader || modalLoader ? '0.5' : '1',
                      }}
                      darkMode={darkMode}
                      text={t('CHANGE_EMAIL.BUTTONS.continue')}
                      disabled={resendLoader || modalLoader}
                    />
                  )}
                  {resendLoader ? (
                    <div
                      style={{
                        width: '100%',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AppLoader size={40} color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
                    </div>
                  ) : (
                    <EditProfileButton
                      onClick={() => this.changeEmail()}
                      style={{
                        marginBottom: '20px',
                        opacity: resendLoader || modalLoader ? '0.5' : '1',
                      }}
                      darkMode={darkMode}
                      text={t('CHANGE_EMAIL.BUTTONS.resend')}
                      disabled={resendLoader || modalLoader}
                    />
                  )}
                </>
              ) : null}
              {!codeSended ? (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      background: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      margin: '10px 0',
                    }}
                  />
                  {resendLoader ? (
                    <div
                      style={{
                        width: '100%',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AppLoader size={40} color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
                    </div>
                  ) : (
                    <EditProfileButton
                      darkMode={darkMode}
                      onClick={() => this.changeEmail()}
                      text={t('CHANGE_EMAIL.BUTTONS.continue')}
                      style={{ marginBottom: '20px' }}
                    />
                  )}
                </>
              ) : null}
            </div>
            <Alert
              isOpen={this.state.isAlertOpen}
              type={this.state.alertType}
              content={this.state.alertContent}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
            />
          </AlertModal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailModal);
