import React from 'react';
import i18n from '../../i18n/i18n';
import { connect } from 'react-redux';
import { isUserValid } from '../../utils/isUserValid';
import Alert from '../../utils/alert/Alert';
import SearchFilter from '../../components/search-filter/SearchFilter';
import getStat from '../../utils/getStat';
import { getPositionHistory } from './getPositionHistory';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import PositionHistoryItem from '../../components/position-history/positionHistoryItem';
import { AppLoader } from '../../utils/animations/animations';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';
import * as colors from '../../utils/color-palatte';

class PositionHistory extends React.Component {
  constructor() {
    super();
    this.state = {
      fetching: false,
      skipItems: false,
      limitItems: 5,
      plusPostNumber: 5,
      keepIncreasingCounter: false,
      search: '',
      rangeDatesFilteringOpen: false,
      toDate: '',
      sinceDate: '',
      positions: [],
    };
  }

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;

    isUserValid(false, this._isMounted);
    if (this._isMounted) await this.getStats();
    if (this._isMounted) this.onRefhreshPositions();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise(async (resolve, _) => {
      // use the character '_' when the reject function isn't called
      try {
        const positions = await getStat('positions');
        const positionsPeriodDays = await getStat('position-expires-period-days');
        // console.log('positionHistory / positions', positions);
        // console.log('positionHistory / posPeriodDays', positionsPeriodDays);
        this.setState({ positionsPeriodDays, currentPositions: positions });
        resolve();
      } catch (e) {
        resolve();
        // console.log('positionHistory / catch', e);
      }
    });
  };

  fetchPositions = () =>
    new Promise(async (resolve, reject) => {
      // console.log('fetchPositions');
      const { skipItems, limitItems, search, positions, sinceDate, toDate } = this.state;
      this.setState({ fetching: true }, async () => {
        try {
          const data = {
            skipItems,
            limitItems,
            search,
            positions,
            sinceDate,
            toDate,
          };
          // console.log('fetchPositions / data', data);
          const newPositions = await getPositionHistory(data);
          // console.log('fetchPositions / newPositions', newPositions);
          let finalPositions = newPositions
            .concat(positions)
            .filter((itm, idx, slf) => idx === slf.findIndex((t) => t._id === itm._id));
          // console.log('fetchPositions / finalPositions', finalPositions);
          this.setState({
            positions: finalPositions,
            keepIncreasingCounter: newPositions?.length > 0,
            fetching: false,
          });
          resolve();
        } catch (e) {
          if (e.status === 404) {
            this.setState({ fetching: false });
            resolve();
          } else {
            reject(e);
            this.setState({ fetching: false });
            const error = e?.data?.error?.message.toString() ?? i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        }
      });
    });

  onRefhreshPositions = () => {
    // console.log('onRefeshPositions');
    const { plusPostNumber } = this.state;
    this.setState({ skipItems: 0, limitItems: plusPostNumber, positions: [] }, () => {
      // console.log('onRefreshPositions2');
      this.fetchPositions();
    });
  };

  onEndReached = () => {
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchPositions();
        }
      );
    } else {
      this.fetchPositions();
    }
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  setValue = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      fetching,
      // search,
      positions,
      positionsPeriodDays,
      currentPositions,
    } = this.state;
    // const positions = []
    // console.log('positions', positions);

    const {
      // user,
      darkMode,
    } = this.props;

    return (
      <ScrollableDiv
        onEndReach={this.onEndReached}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexFlow: 'column nowrap',
          boxSizing: 'border-box',
          padding: 5,
        }}
      >
        <SearchFilter
          inputPlaceholder={i18n.t('POSITION_HISTORY.searchInputPlaceholder')}
          setValue={this.setValue}
          onRefresh={this.onRefhreshPositions}
        />
        {fetching && (!positions || positions?.length === 0) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 180px)',
              width: '100%',
            }}
          >
            <AppLoader color={darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE} size='big' />
          </div>
        ) : null}
        {!fetching && (!positions || positions?.length === 0) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 180px)',
              width: '100%',
            }}
          >
            <NotFoundItem isBlue={darkMode} type='position-history' />
          </div>
        ) : null}
        {positions && positions.length > 0
          ? positions.map((position, i) => (
              <PositionHistoryItem
                item={position}
                key={i}
                positions={currentPositions}
                positionsPeriodDays={positionsPeriodDays}
                darkMode={darkMode}
              />
            ))
          : null}
        {positions?.length > 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60px',
            }}
          >
            {fetching ? (
              <AppLoader size='40px' color={darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE} />
            ) : (
              <p style={{ color: darkMode ? colors.WHITE_MAIN : '#000' }}>{i18n.t('POSITION_HISTORY.noMorePositions')}</p>
            )}
          </div>
        )}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() =>
            this.setState({
              isAlertOpen: false,
            })
          }
        />
      </ScrollableDiv>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionHistory);
