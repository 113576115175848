import React from 'react';
import { Translation } from 'react-i18next';
import CheckedMail from '../../assets/sucess-mail/CheckedMail.svg';
import './sucess-mail.css';
import SafeURL from '../../utils/safe-url';

class SucessMail extends React.Component {
  render() {
    return (
      <Translation>
        {(t) => (
          <div className='SucessMailBox'>
            <div className='sucessContainer'>
              <img src={CheckedMail} alt='SucessMail' />
              <div className='SucessText'>
                <h1>¡{t('mail-done')}!</h1>
                <p>{t('mail-done-msg')}</p>
                <SafeURL style={{ cursor: 'pointer' }} text={t('continue')} />
              </div>
            </div>
            <svg viewBox='0 0 100 100' preserveAspectRatio='none'>
              <polygon
                className='diagonal1'
                points='0,40 100,0 100,100 0,100'
              />
            </svg>
          </div>
        )}
      </Translation>
    );
  }
}

export default SucessMail;
