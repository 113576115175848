import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as routes from '../../routes/routes';
// import i18n from '../../i18n/i18n';
import { UserProfile, UserName } from '../ornament-profile/profileUtils';
import handlePositions from '../../utils/handlePositionName';
import styles from './positionHistoryItem.module.css';
import * as colors from '../../utils/color-palatte';
import { Translation } from 'react-i18next';

class PositionHistoryItem extends React.PureComponent {
  state = {
    isSmall: false,
  };

  rvlRef = React.createRef();
  observer = null;

  componentDidMount = () => {
    this.observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      // console.log(entries);
      if (boxElem.contentRect.width < 450) {
        this.setState({ isSmall: true });
      } else {
        this.setState({ isSmall: false });
      }
    });

    this.observer.observe(this.rvlRef.current);
  };

  componentWillUnmount = () => {
    this.observer.disconnect();
  };

  goUserProfile = (post) => {
    const { history } = this.props;
    const userId = post.user._id || post.user;
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId === userId) {
      history.push(routes.PROFILE_ROUTE);
    } else {
      history.push(`${routes.PROFILE_ROUTE}/${userId}`);
    }
  };

  render() {
    const { isSmall } = this.state;
    const { item, positionsPeriodDays, positions, darkMode } = this.props;

    let currentPosition = '';
    if (positions && positions.length > 0 && item.user) {
      const havePosition = positions.filter((position) => position.user._id === item.user._id);
      if (havePosition.length > 0) {
        currentPosition = havePosition[0].position;
      }
    }

    let startDate = moment(item.creationDate);
    let endDate = moment(item.creationDate).add(Number(item.period), 'days');
    let date = moment();

    const isCurrentPosition = moment(date).isBetween(startDate, endDate);
    // console.log('isCurrentPosition:', isCurrentPosition)
    return (
      <Translation>
        {(t) => (
          <div
            ref={this.rvlRef}
            className={styles.PositionItem__Container}
            style={{
              backgroundColor: darkMode
                ? currentPosition === item.position && isCurrentPosition
                  ? colors.UNSATURATED_BLACK_BLUE
                  : colors.BLACK_HOVER_SOFT
                : currentPosition === item.position && isCurrentPosition
                ? colors.FEDERAL_LIGTH_BLUE
                : colors.FEDERAL_WHITE2,
            }}
          >
            <div
              className={styles.PositionItem__Header}
              style={{
                // borderBottom: `2px solid ${darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE}`,
                marginBottom: 5,
              }}
            >
              <div className={styles.PositionItem__PictureContainer}>
                <UserProfile
                  onClick={() => this.goUserProfile(item)}
                  uri={item?.user?.picture}
                  position={item.position}
                  containerStyle={{
                    width: isSmall && 45,
                    height: isSmall && 45,
                  }}
                />
              </div>
              <div className={styles.PositionItem__HeaderData}>
                <UserName
                  onClick={() => this.goUserProfile(item)}
                  username={item.user && item.user.username ? item.user.username : item.userUsername}
                  titleStyles={{
                    color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.FEDERAL_BLACK,
                    margin: 0,
                    fontSize: `${item.user && item.user.username && item.user.username.length > 10 ? 0.85 : 1}rem`,
                    // textTransform: 'uppercase',
                  }}
                  crownStyle={{ width: 18 }}
                  ornamentStyle={{ width: 18 }}
                  darkMode={darkMode}
                  position={item.position}
                />
                <p
                  className={styles.PositionItem__UserPosition}
                  style={{
                    color: darkMode ? colors.UNSATURATED_BLACK_BLUE : colors.WHITE_MAIN,
                    background: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLUE,
                    textTransform: 'uppercase',
                    fontSize: `${isSmall ? .7 : .9}rem`
                  }}
                >
                  {handlePositions(item.position)}
                </p>
                <p style={{ margin: '8px 0', color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLACK, fontSize: `${isSmall ? .8 : 1}rem` }}>
                  {t('POSITION_HISTORY.period')}:{' '}
                  <b
                    style={{ color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.UNSATURATED_BLACK_BLUE }}
                  >{`${positionsPeriodDays} ${t('POSITION_HISTORY.days')}`}</b>
                </p>
                <p style={{ margin: '8px 0', color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLACK , fontSize: `${isSmall ? .8 : 1}rem`}}>
                  {t('POSITION_HISTORY.startDate')}:{' '}
                  <b style={{ color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.UNSATURATED_BLACK_BLUE }}>
                    {moment(item.creationDate).format('MM-DD-YYYY hh:mm A')}
                  </b>
                </p>
                <p style={{ margin: '8px 0', color: darkMode ? colors.WHITE_MAIN : colors.FEDERAL_BLACK, fontSize: `${isSmall ? .8 : 1}rem` }}>
                  {t('POSITION_HISTORY.endDate')}:{' '}
                  <b style={{ color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.UNSATURATED_BLACK_BLUE }}>
                    {moment(item.creationDate).add(Number(item.period), 'days').format('MM-DD-YYYY hh:mm A')}
                  </b>
                </p>
              </div>
            </div>
            <p className={styles.PositionItem__Status} style={{ color: darkMode ? colors.FEDERAL_WHITE : colors.FEDERAL_BLACK, fontSize: `${isSmall ? .8 : 1}rem` }}>
              {t('POSITION_HISTORY.status')}: {isSmall ? <br /> : <>&nbsp;</>}
              {currentPosition === item.position && isCurrentPosition ? (
                <b style={{ color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.UNSATURATED_BLACK_BLUE }}>
                  {t('POSITION_HISTORY.active')}
                </b>
              ) : (
                <b style={{ color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.UNSATURATED_BLACK_BLUE }}>
                  {t('POSITION_HISTORY.finished')}
                </b>
              )}
            </p>
          </div>
        )}
      </Translation>
    );
  }
}

export default withRouter(PositionHistoryItem);
