import React, { useState, useEffect, useRef } from 'react';
import AlertModal from '../../../utils/modal/Modal';
import CloseIconWhite from '../../../assets/terms-modal/close-btn-white.svg';
import Cropper from 'react-easy-crop';
import './cropModal.css';
import {
  FEDERAL_BLUE,
  UNSATURATED_BLACK_BLUE,
  WHITE_MAIN,
} from '../../../utils/color-palatte';

const CropModal = (props) => {
  const {
    isOpen,
    uri,
    darkMode,
    action,
    aspect,
    crop,
    zoom,
    rotation,
    onCropChange,
    onZoomChange,
    onRotationChange,
    onCropComplete,
    firstInputProps,
    secondInputProps,
    onRequestClose,
    mobile,
  } = props;

  const rlvRef = useRef();
  let observer = null;
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line
      observer = new ResizeObserver((entries) => {
        const boxElem = entries[0]
        setWidth(boxElem.contentRect.width - 20)
      })

      observer.observe(rlvRef.current)
      return () => {
        observer.disconnect()
      }
    }
  }, [isOpen]);

  return (
    <AlertModal
      isOpen={isOpen}
      // outsideClick={onRequestClose}
      overlayClassName='CropModal__Overlay'
      modalClassName='CropModal__Container'
      style={{ backgroundColor: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN }}
    >
      <div
        // ref={(el) => {
        //   rlvRef.current = el;
        //   setRefVisible(!!el);
        //   setWidth(
        //     rlvRef?.current?.clientWidth
        //       ? rlvRef.current.clientWidth - 20
        //       : null
        //   );
        // }}
        ref={rlvRef}
        className='CropperModal__Cropper'
      >
        <Cropper
          style={{
            containerStyle: {
              background: '#000',
            },
          }}
          image={uri}
          aspect={aspect || 1}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onRotationChange={onRotationChange}
          onCropComplete={onCropComplete}
          cropSize={mobile ? { width: width, height: width } : null}
        />
        <img
          src={CloseIconWhite}
          alt='close'
          className='CropperModal__CloseImg'
          onClick={onRequestClose}
        />
      </div>
      <div className='CropModal__Controls'>
        <div
          className='CropModal__Item'
          style={{ display: mobile ? 'none' : undefined }}
        >
          <input
            className={
              darkMode
                ? 'CropModal__ControlInput-dark'
                : 'CropModal__ControlInput'
            }
            {...firstInputProps}
          />
          <p style={{ color: darkMode ? WHITE_MAIN : '#000' }}>
            {String(Math.ceil((zoom / 4) * 100))}%
          </p>
        </div>
        <div
          className='CropModal__Item'
          style={{ display: mobile ? 'none' : undefined }}
        >
          <input
            className={
              darkMode
                ? 'CropModal__ControlInput-dark'
                : 'CropModal__ControlInput'
            }
            {...secondInputProps}
          />
          <p style={{ color: darkMode ? WHITE_MAIN : '#000' }}>
            {String(rotation)}º
          </p>
        </div>
        <button
          className='CropModal__Button'
          onClick={action}
          style={{
            backgroundColor: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
            color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
            marginTop: mobile ? '15px' : undefined
          }}
        >
          Crop
        </button>
      </div>
    </AlertModal>
  );
};

export default CropModal;
