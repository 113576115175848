import React from 'react';
import { AppLoader } from '../../utils/animations/animations';
import { FEDERAL_BLUE } from '../../utils/color-palatte';
import SafeLINK from '../../utils/safe-link';
import './voteItem.css';

const VoteItem = ({
  className,
  style,
  onClick,
  navigation,
  img,
  text,
  color,
  counter,
  linked,
  loader,
}) => {

  // console.log(counter)
  
  if (linked) {
    return (
      <SafeLINK
        className={`${className} VoteItem__Container`}
        style={Object.assign({}, style)}
        onClick={onClick}
        dangerousLINK={navigation}
        text={
          <>
            {img && <img src={img} alt='' className='VoteItem__Img' />}
            <p
              className='VoteItem__Text'
              style={{ color: color, fontSize: '1.1rem' }}
            >
              {text}
            </p>
            {loader ? (
              <AppLoader color={FEDERAL_BLUE} size='small' />
            ) : counter && counter !== undefined ? (
              <p className='VoteItem__Counter'>{counter}</p>
            ) : null}
            {/* {counter ? (
              <p className='VoteItem__Counter'>{counter}</p>
            ) : loader ? (
              <AppLoader color={FEDERAL_BLUE} size='small' />
            ) : null} */}
          </>
        }
      />
    );
  }
  return (
    <button
      className={`${className} VoteItem__Container`}
      style={Object.assign({}, style)}
      onClick={onClick}
    >
      {img && <img src={img} alt='' className='VoteItem__Img' />}
      <p
        className='VoteItem__Text'
        style={{ color: color, fontSize: '1.1rem' }}
      >
        {text}
      </p>
      {loader ? (
        <AppLoader color={FEDERAL_BLUE} size='small' />
      ) : (counter !== undefined || counter === 0 || counter === '0') ? (
        <p className='VoteItem__Counter'>{counter}</p>
      ) : null}
    </button>
  );
};

export default VoteItem;
