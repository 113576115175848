import React from 'react';
import WrongIcon from '../../assets/contact-us/alert/wrong_icon.svg';
import WarningIcon from '../../assets/contact-us/alert/warning_icon.svg';
import SuccessIcon from '../../assets/contact-us/alert/success_icon.svg';
import EmailIcon from '../../assets/contact-us/alert/email_icon.svg';
import AlertModal from '../modal/Modal';
import i18n from '../../i18n/i18n';
// import { FEDERAL_BLUE } from '../color-palatte';

import './Alert.css';
import { AppLoader } from '../animations/animations';

const Alert = (props) => {
  return (
    <AlertModal
      isOpen={props.isOpen}
      overlayClassName='Alert__overlay'
      modalClassName='Alert__Modal'
    >
      {/* <img 
        src={props.success ? CloseBlue : ClosePink} 
        className='ModalCloseBtn' 
        onClick={() => props.onRequestClose()} 
        alt='Close' 
      /> */}
      <div className='Alert__Container'>
        <div className='Alert__Message-title'>
          <img
            src={
              props.type === 'success'
                ? SuccessIcon
                : props.type === 'warning'
                ? WarningIcon
                : props.type === 'wrong'
                ? WrongIcon
                : props.type === 'email'
                ? EmailIcon
                : props.type === 'error'
                ? WrongIcon
                : WrongIcon
            }
            alt=''
          />
          <h1>
            {props.title
              ? props.title
              : props.type === 'success'
              ? i18n.t('MESSAGE_MODAL.success')
              : props.type === 'warning'
              ? i18n.t('MESSAGE_MODAL.warning')
              : props.type === 'wrong'
              ? i18n.t('MESSAGE_MODAL.error')
              : props.type === 'email'
              ? i18n.t('verifyingEmail')
              : props.type === 'error'
              ? i18n.t('MESSAGE_MODAL.error')
              : i18n.t('MESSAGE_MODAL.error')}
          </h1>
        </div>
        <div
          className='Alert__Message-content'
          style={{ maxWidth: props.choose ? 'unset' : undefined }}
        >
          {props.loading ? (
            <div
              style={{
                width: '100%',
                minHeight: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AppLoader size='50px' />
            </div>
          ) : (
            <>
              <p>{props.content || i18n.t('internalError')}</p>
              {props.progress ? (
                <div className='Alert__ProgressBar-container'>
                  <div
                    className='Alert__Progressbar'
                    style={{ width: `${props.progress}%` }}
                  >
                    <div className='Alert__Progresbar-anim' />
                  </div>
                </div>
              ) : props.choose ? (
                <div className='Alert__Button-area'>
                  <button onClick={props.NOClick}>
                    {props.noButtonText || 'NO'}
                  </button>
                  <button onClick={props.YESClick}>
                    {props.yesButtonText || 'YES'}
                  </button>
                </div>
              ) : (
                <button onClick={props.onRequestClose}>
                  {props.singleButtonText || i18n.t('continue')}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </AlertModal>
  );
};

export default Alert;
