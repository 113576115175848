import React, { useState } from 'react';
import './sideMenu.css';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from "redux";
// import { actionCreators as actions } from "../../redux/actions/user/actions";
// import { getUser } from "../../pages/profile/profileActions";
import getStat from '../../utils/getStat';
import HomeLogo from '../../assets/dashboard/icon_home.svg';
import HomeLogoFilled from '../../assets/dashboard/icon_home_filled.svg';
import HomeLogoWhite from '../../assets/dashboard/icon_home_white.svg';
import HomeLogoWhiteFilled from '../../assets/dashboard/icon_home_white_filled.svg';
import SearchLogo from '../../assets/dashboard/icon_search.svg';
import SearchLogoFilled from '../../assets/dashboard/icon_search_filled.svg';
import SearchLogoWhite from '../../assets/dashboard/icon_search_white.svg';
import SearchLogoWhiteFilled from '../../assets/dashboard/icon_search_white_filled.svg';
import VotesLogo from '../../assets/dashboard/icon_votes.svg';
import VotesLogoFilled from '../../assets/dashboard/icon_votes_filled.svg';
import VotesLogoWhite from '../../assets/dashboard/icon_votes_white.svg';
import VotesLogoWhiteFilled from '../../assets/dashboard/icon_votes_white_filled.svg';
import ServicesLogo from '../../assets/dashboard/icon_services.svg';
import ServicesLogoFilled from '../../assets/dashboard/icon_services_filled.svg';
import ServicesLogoWhite from '../../assets/dashboard/icon_services_white.svg';
import ServicesLogoWhiteFilled from '../../assets/dashboard/icon_services_white_filled.svg';
import CommunitiesLogo from '../../assets/dashboard/icon_communities.svg';
import CommunitiesLogoFilled from '../../assets/dashboard/icon_communities_filled.svg';
import CommunitiesLogoWhite from '../../assets/dashboard/icon_communities_white.svg';
import CommunitiesLogoWhiteFilled from '../../assets/dashboard/icon_communities_white_filled.svg';
import ProfileLogo from '../../assets/dashboard/icon_profile.svg';
import ProfileLogoFilled from '../../assets/dashboard/icon_profile_filled.svg';
import ProfileLogoWhite from '../../assets/dashboard/icon_profile_white.svg';
import ProfileLogoWhiteFilled from '../../assets/dashboard/icon_profile_white_filled.svg';
import GGLogo from '../../assets/dashboard/icon_gg_filled.svg';
import GGLogoFilled from '../../assets/dashboard/icon_gg.svg';
import GGLogoWhite from '../../assets/dashboard/icon_gg_white_filled.svg';
import GGLogoWhiteFilled from '../../assets/dashboard/icon_gg_white.svg';
import CreatePost from '../../assets/dashboard/icon_create_post.svg';
// import CreateCommunity from '../../assets/dashboard/icon_create_community.svg';
import CloseBtn from '../../assets/dashboard/menu_close_btn.svg';
import CloseBtnWhite from '../../assets/dashboard/menu_close_btn_white.svg';
import CommunityIcon from '../../assets/dashboard/icon_members.svg';
import i18n from '../../i18n/i18n';
import * as routes from '../../routes/routes';
import { UserName, UserProfile } from '../ornament-profile/profileUtils';
import {
  FEDERAL_GREEN,
  FEDERAL_BLACK_LIGHT,
  GRAY_SECOND_TRANSPARENCY_FULL,
  FEDERAL_BLUE,
  DARK_OFFICIAL_BLUE,
  WHITE_MAIN,
} from '../../utils/color-palatte';
import SafeLINK from '../../utils/safe-link';
import { mobileRegex1, mobileRegex2 } from '../../utils/mobileRegex';
import VerifiedIcon from '../../assets/ornament-profile/verification_icon.svg';

const SideMenuButton = ({ onClick, title, logo, route, darkMode, containerStyle, imgStyle, textStyle }) => {
  const [hover, setHover] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (mobileRegex1.test(navigator.userAgent) || mobileRegex2.test(navigator.userAgent.substr(0, 4))) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <SafeLINK
      className='SideMenu__button'
      style={containerStyle}
      onClick={onClick}
      onMouseEnter={() => (isMobile ? void 0 : setHover(true))}
      onMouseLeave={() => (isMobile ? void 0 : setHover(false))}
      onTouchStart={() => (isMobile ? setHover(true) : void 0)}
      onTouchEnd={() => (isMobile ? setHover(false) : void 0)}
      text={
        <>
          <img src={logo} alt='' style={imgStyle} />
          <p style={Object.assign({ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE }, textStyle)}>{title}</p>
          <span
            style={{
              borderBottom: `2px solid ${darkMode ? WHITE_MAIN : FEDERAL_BLUE}`,
              width: hover ? '100%' : '0',
              maxWidth: '100%',
            }}
          />
        </>
      }
      dangerousLINK={route}
    />
  );
};

const SideMenu = (props) => {
  const { user, darkMode, mobile, isOpen, onRequestClose } = props;

  const [positions, setPositions] = useState(null);

  const history = useHistory();

  let _isMounted = false;

  useEffect(() => {
    // eslint-disable-next-line
    _isMounted = true;
    (async () => {
      if (_isMounted) await getStats();
    })();
    return () => {
      _isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        // const userId = localStorage.getItem('userId');
        const positions = await getStat('positions');
        setPositions(positions);
        resolve();
      } catch (e) {
        // console.log('sideMenu / getStats / e:', e);
        reject(e);
      }
    });
  };

  // const fetchUser = () => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const userId = localStorage.getItem("userId");
  //       const user = await getUser(userId);
  //       saveUser(user);
  //       resolve();
  //     } catch (e) {
  //       // console.log('sideMenu / fetchUser / e:', e);
  //       reject(e);
  //     }
  //   });
  // };

  const location = useLocation();

  const path = location.pathname;

  const MenuItem = [
    {
      logo:
        path === routes.ROOT_ROUTE || path === routes.DASHBOAR_ROUTE || path === routes.HOME_ROUTE
          ? darkMode
            ? HomeLogoWhiteFilled
            : HomeLogoFilled
          : darkMode
          ? HomeLogoWhite
          : HomeLogo,
      title: i18n.t('DASHBOARD.home'),
      route: routes.DASHBOAR_ROUTE,
    },
    {
      logo:
        path === routes.SEARCH_ROUTE
          ? darkMode
            ? SearchLogoWhiteFilled
            : SearchLogoFilled
          : darkMode
          ? SearchLogoWhite
          : SearchLogo,
      title: i18n.t('DASHBOARD.search'),
      route: routes.SEARCH_ROUTE,
    },
    {
      logo:
        path === routes.VOTINGS_ROUTE
          ? darkMode
            ? VotesLogoWhiteFilled
            : VotesLogoFilled
          : darkMode
          ? VotesLogoWhite
          : VotesLogo,
      title: i18n.t('DASHBOARD.votes'),
      route: routes.VOTINGS_ROUTE,
    },
    {
      logo:
        path === routes.SERVICES_ROUTE
          ? darkMode
            ? ServicesLogoWhiteFilled
            : ServicesLogoFilled
          : darkMode
          ? ServicesLogoWhite
          : ServicesLogo,
      title: i18n.t('DASHBOARD.services'),
      route: routes.SERVICES_ROUTE,
    },
    {
      logo:
        path === routes.GLOBAL_GOVERNMENT_ROUTE ? (darkMode ? GGLogoWhiteFilled : GGLogoFilled) : darkMode ? GGLogoWhite : GGLogo,
      title: 'Global Government',
      route: routes.GLOBAL_GOVERNMENT_ROUTE,
    },
    {
      logo:
        path === routes.COMMUNITIES_ROUTE
          ? darkMode
            ? CommunitiesLogoWhiteFilled
            : CommunitiesLogoFilled
          : darkMode
          ? CommunitiesLogoWhite
          : CommunitiesLogo,
      title: i18n.t('DASHBOARD.communities'),
      route: routes.COMMUNITIES_ROUTE,
    },
    {
      logo:
        path === routes.PROFILE_ROUTE
          ? darkMode
            ? ProfileLogoWhiteFilled
            : ProfileLogoFilled
          : darkMode
          ? ProfileLogoWhite
          : ProfileLogo,
      title: i18n.t('DASHBOARD.profile'),
      route: routes.PROFILE_ROUTE,
    },
  ];

  const postUserId = user && user._id ? user._id : '';

  const position =
    positions && positions.filter((position) => position.user._id === postUserId).length > 0
      ? positions.filter((position) => position.user._id === postUserId)[0].position
      : null;

  let communityId = null;
  if (path.split('/').slice(0, -1).join('/') === routes.COMMUNITY_POSTS_ROUTE) {
    communityId = path?.split('/')?.slice(2, 3).toString();
    // console.log(communityId);
  }

  if (mobile) {
    return (
      <div
        className='SideMenu__Mobile-Overlay'
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100vh',
          background: isOpen ? '#0008' : '#0000',
          pointerEvents: isOpen ? 'unset' : 'none',
          transition: 'all .3s',
        }}
        onClick={onRequestClose}
      >
        <div
          className='SideMenu__Mobile-Container'
          style={{
            transition: 'all .3s',
            backgroundColor: darkMode ? DARK_OFFICIAL_BLUE : WHITE_MAIN,
            marginLeft: isOpen ? '0' : '-300px',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className='SideMenu__Mobile-User-data'>
            <img
              src={darkMode ? CloseBtnWhite : CloseBtn}
              alt='close'
              onClick={onRequestClose}
              className='SideMenu__Mobile-Close-btn'
            />
            <UserProfile
              className='SideMenu__User-profile'
              uri={user?.picture}
              position={position}
              onClick={() => {
                history.push(routes.PROFILE_ROUTE);
                onRequestClose();
              }}
            />
            <UserName
              username={user?.name?.toUpperCase()}
              position={position}
              darkMode={darkMode}
              // isVerified={user.isVerifiedUser}
              titleStyles={{
                color: darkMode ? WHITE_MAIN : '#000',
                fontSize: user?.name?.length > 10 ? '1.2em' : '2em',
                margin: '0',
              }}
              onClick={() => {
                history.push(routes.PROFILE_ROUTE);
                onRequestClose();
              }}
            />
            <p
              style={{
                color: darkMode ? FEDERAL_GREEN : FEDERAL_BLACK_LIGHT,
                margin: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              @{user?.username}
              {user?.isVerifiedUser && (
                <img src={VerifiedIcon} alt='' style={{ width: 20, marginLeft: 5 }} title={i18n.t('GENERAL_TEXT.verifiedUser')} />
              )}
            </p>
            <div className='SideMenu__Mobile-UserFollower-data'>
              <p
                style={{
                  color: darkMode ? WHITE_MAIN : FEDERAL_BLACK_LIGHT,
                  margin: '0',
                }}
              >
                <b>{user?.followers?.length}</b> {i18n.t('DASHBOARD.followers')}{' '}
              </p>
              <p
                style={{
                  color: darkMode ? WHITE_MAIN : FEDERAL_BLACK_LIGHT,
                  margin: '0',
                  marginLeft: 10,
                }}
              >
                <b>{user?.following?.length}</b> {i18n.t('DASHBOARD.following')}
              </p>
            </div>
          </div>
          <div
            className='SideMenu__Menu'
            style={{
              borderTop: `1px solid ${darkMode ? WHITE_MAIN : GRAY_SECOND_TRANSPARENCY_FULL}`,
              borderBottom: `1px solid ${darkMode ? WHITE_MAIN : GRAY_SECOND_TRANSPARENCY_FULL}`,
              marginBottom: '20px',
              boxSizing: 'border-box',
              padding: '15px',
              paddingTop: '20px',
            }}
          >
            {MenuItem.map((item, i) => (
              <SideMenuButton
                {...item}
                key={i}
                mobile={mobile}
                onClick={onRequestClose}
                darkMode={darkMode}
                textStyle={{ fontSize: '1.2rem' }}
                imgStyle={{ width: '27px' }}
                containerStyle={{ height: '35px' }}
              />
            ))}
          </div>
          <button
            className='SideMenu__CreatePost'
            style={{ margin: '10px 20px' }}
            onClick={() => {
              path === routes.COMMUNITIES_ROUTE
                ? history.push(routes.COMMUNITY_CREATE_ROUTE)
                : path.split('/').slice(0, -1).join('/') === routes.COMMUNITY_POSTS_ROUTE && user?.community?._id === communityId
                ? history.push(`${routes.CREATE_POST_ROUTE}/?communityPost=${user.community._id}`)
                : history.push(routes.CREATE_POST_ROUTE);
              onRequestClose();
            }}
          >
            <img src={path === routes.COMMUNITIES_ROUTE ? CommunityIcon : CreatePost} alt='' />
            <p>{path === routes.COMMUNITIES_ROUTE ? i18n.t('DASHBOARD.createCommunity') : i18n.t('DASHBOARD.createPost')}</p>
          </button>
          {/* <SafeLINK
            className='SideMenu__CreateCommunity'
            style={{ margin: '20px' }}
          >
            <img src={CreateCommunity} alt='' />
            <p>{i18n.t('DASHBOARD.createCommunity')}</p>
          </SafeLINK> */}
        </div>
      </div>
    );
  }
  return (
    <div className='SideMenu__Container'>
      <div className='SideMenu__User-data'>
        <UserProfile
          className='SideMenu__User-profile'
          uri={user?.picture}
          position={position}
          onClick={() => history.push(routes.PROFILE_ROUTE)}
        />
        <UserName
          username={user?.name?.toUpperCase()}
          position={position}
          darkMode={darkMode}
          // isVerified={user.isVerifiedUser}
          titleStyles={{
            color: darkMode ? WHITE_MAIN : '#000',
            fontSize: user?.name?.length > 10 ? '1.2em' : '2em',
            margin: '0',
          }}
          onClick={() => history.push(routes.PROFILE_ROUTE)}
        />
        <p
          style={{
            color: darkMode ? FEDERAL_GREEN : FEDERAL_BLACK_LIGHT,
            margin: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          @{user?.username}
          {user?.isVerifiedUser && (
            <img src={VerifiedIcon} alt='' style={{ width: 20, marginLeft: 5 }} title={i18n.t('GENERAL_TEXT.verifiedUser')} />
          )}
        </p>
        <div
          className='SideMenu__UserFollower-data'
          style={{
            borderTop: `1px solid ${darkMode ? '#FFF5' : GRAY_SECOND_TRANSPARENCY_FULL}`,
            borderBottom: `1px solid ${darkMode ? '#FFF5' : GRAY_SECOND_TRANSPARENCY_FULL}`,
          }}
        >
          <p
            style={{
              color: darkMode ? WHITE_MAIN : FEDERAL_BLACK_LIGHT,
              margin: '8px 0',
            }}
          >
            <b>{user?.followers?.length}</b> followers
          </p>
          <p
            style={{
              color: darkMode ? WHITE_MAIN : FEDERAL_BLACK_LIGHT,
              margin: '8px 0',
            }}
          >
            <b>{user?.following?.length}</b> following
          </p>
        </div>
      </div>
      <div
        className='SideMenu__Menu'
        style={{
          borderBottom: `1px solid ${darkMode ? '#FFF5' : GRAY_SECOND_TRANSPARENCY_FULL}`,
          marginBottom: '20px',
        }}
      >
        {MenuItem.map((item, i) => (
          <SideMenuButton {...item} key={i} darkMode={darkMode} />
        ))}
      </div>
      <button
        className='SideMenu__CreatePost'
        onClick={() =>
          path === routes.COMMUNITIES_ROUTE
            ? history.push(routes.COMMUNITY_CREATE_ROUTE)
            : path.split('/').slice(0, -1).join('/') === routes.COMMUNITY_POSTS_ROUTE && user?.community?._id === communityId
            ? history.push(`${routes.CREATE_POST_ROUTE}/?communityPost=${user.community._id}`)
            : history.push(routes.CREATE_POST_ROUTE)
        }
      >
        <img src={path === routes.COMMUNITIES_ROUTE ? CommunityIcon : CreatePost} alt='' />
        <p>{path === routes.COMMUNITIES_ROUTE ? i18n.t('DASHBOARD.createCommunity') : i18n.t('DASHBOARD.createPost')}</p>
      </button>
      {/* <SafeLINK className='SideMenu__CreateCommunity'>
        <img src={CreateCommunity} alt='' />
        <p>{i18n.t('DASHBOARD.createCommunity')}</p>
      </SafeLINK> */}
    </div>
  );
};

// const SideMenuWithRouter = withRouter(SideMenu)

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
