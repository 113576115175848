import React from 'react';
// import FlowImg from '../../../assets/home/flow/Flow.svg';
// import FlowImgES from '../../../assets/home/flow/FlowES.svg';
// import FlowImgM from '../../../assets/home/flow/Flow-m.svg';
// import FlowImgMES from '../../../assets/home/flow/Flow-mES.svg';
import './flow.css';
import { Translation } from 'react-i18next';
import { Warning } from '../../warning/Warning';
import { FEDERAL_BLUE_STRONG, FEDERAL_GREEN, FEDERAL_BLUE, DARK_OFFICIAL_BLUE } from '../../../utils/color-palatte';
import { ParsedText } from '../../../utils/text-utils/text-utils';
import i18n from '../../../i18n/i18n';
import InfoModal from '../../info-modals/info-modals';
import getStat from '../../../utils/getStat';
import { FlowAsset } from './flow-assets/flowAssets';
import thousandSeparator from '../../../utils/thousandSeparator';

class Flow extends React.Component {
  state = {
    modal: {
      isOpen: false,
      type: null,
      data: {},
    },
    isSmall: false,
  };

  observer = null;
  rvlRef = React.createRef();

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.observer = new ResizeObserver((entries) => {
        const boxElem = entries[0];
        if (boxElem.contentRect.width <= 700) {
          this.setState({ isSmall: true });
        } else {
          this.setState({
            isSmall: false,
          });
        }
      });

      this.observer.observe(this.rvlRef.current);
    }

    if (this._isMounted) this.getStats();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.observer.disconnect();
  };

  getStats = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true }, async () => {
        try {
          const laws = await getStat('laws');
          const users = await getStat('users');
          const deals = await getStat('deals');
          const totalIncomes = await getStat('total-incomes');
          const mantainanceTaxes = await getStat('maintenance-taxes');
          const presidentSalaryPorcentage = await getStat('president-salary-porcentage');
          const vicepresidentSalaryPorcentage = await getStat('vicepresident-salary-porcentage');
          // console.log("officialVotings: ", officialVotings);
          this.setState({
            laws,
            users,
            deals,
            totalIncomes: totalIncomes.total,
            mantainanceTaxes,
            presidentSalaryPorcentage,
            vicepresidentSalaryPorcentage,
          });
          resolve();
        } catch (e) {
          reject();
          // console.log("getStats / error: ", e);
        }
      });
    });
  };

  openModal = (isOpen, type, data) => {
    this.setState({
      modal: {
        isOpen: isOpen,
        type: type,
        data: data,
      },
    });
  };

  render() {
    // const language = localStorage.getItem('language') === 'us' ? 'en' : localStorage.getItem('language') || 'en';
    const { modal } = this.state;
    const { isScreen, darkMode, withoutTitle, withoutWarning } = this.props;
    const {
      users,
      laws,
      deals,
      totalIncomes,
      // mantainanceTaxes,
      // presidentSalaryPorcentage,
      // vicepresidentSalaryPorcentage
    } = this.state;
    // console.log("language: ", language)
    return (
      <Translation>
        {(t) => (
          <div
            ref={this.rvlRef}
            className='Flow-container'
            style={{
              backgroundColor: isScreen ? (darkMode ? DARK_OFFICIAL_BLUE : '#0000') : FEDERAL_BLUE,
            }}
          >
            {withoutTitle ? null : (
              <h1
                style={{
                  color: isScreen ? (darkMode ? '#FFF' : FEDERAL_BLUE) : '#FFF',
                }}
              >
                {t('FLOW.title')}
              </h1>
            )}
            {/* <img src={language === 'es' ? FlowImgES : FlowImg} alt='Flow' className='ImgBG' draggable='false' />
            <img src={language === 'es' ? FlowImgMES : FlowImgM} alt='FlowM' className='ImgMini' draggable='false' /> */}
            <div style={{ width: '100%', overflow: 'hidden' }}>
              <FlowAsset
                isSmall={this.state.isSmall}
                className='Flow__SVG'
                darkMode={darkMode}
                isScreen={isScreen}
                citizens={users && !isNaN(users) ? thousandSeparator(Number(users)) : 0}
                laws={laws && !isNaN(laws) ? thousandSeparator(Number(laws)) : 0}
                deals={deals && !isNaN(deals) ? thousandSeparator(Number(deals)) : 0}
                incomes={totalIncomes && !isNaN(totalIncomes) ? thousandSeparator(Number(totalIncomes)) : 0}
              />
            </div>
            {withoutWarning ? null : (
              <Warning
                isAinstain
                darkMode={true}
                style={{ backgroundColor: FEDERAL_BLUE_STRONG }}
                title={i18n.t('FLOW.warningTitle')}
                textCotent={
                  <ParsedText
                    string={i18n.t('FLOW.warningInfo')}
                    style={{ color: '#FFF', whiteSpace: 'pre-line' }}
                    markStyle={{ color: FEDERAL_GREEN, fontWeight: 'bold' }}
                    hasWiki
                  />
                }
              />
            )}
            <InfoModal
              isOpen={modal.isOpen}
              type={modal.type}
              capitalData={modal.data}
              onRequestClose={() =>
                this.setState({
                  modal: { isOpen: false, type: null, data: {} },
                })
              }
            />
          </div>
        )}
      </Translation>
    );
  }
}

export default Flow;
