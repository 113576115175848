import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import './styles.css';
import WhiteIcon from '../../assets/profile/white_button.svg';
import BlueIcon from '../../assets/post/button_options.svg';
import { FEDERAL_BLUE } from '../../utils/color-palatte';

const MenuButton = (props) => {
  const { onClick, className, isBlue, withBorder, style } = props;

  const [hover, setHover] = useState(false);

  return (
    <Translation>
      {(t) => (
        <img
          onClick={onClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          src={isBlue ? BlueIcon : WhiteIcon}
          className={`${className} Menu__Button`}
          alt='Open menu button'
          style={Object.assign(
            {
              border: withBorder
                ? `1px solid ${isBlue ? FEDERAL_BLUE : 'white'}`
                : undefined,
              borderRadius: '50%',
              transition: 'all .2s',
              background: hover
                ? isBlue
                  ? '#2573b45f'
                  : '#fff5'
                : 'transparent',
            },
            style
          )}
        />
      )}
    </Translation>
  );
};

export default MenuButton;
