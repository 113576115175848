import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
// import * as routes from '../../routes/routes';
import Ribbon from '../../assets/achievement/achievement_ribbon.svg';
import StarFilled from '../../assets/achievement/star_filled.svg';
import StarEmpty from '../../assets/achievement/star_empty.svg';
import { PreviewChievModal } from './modal/previewChievModal';
import './achievementItem.css';

const Stars = (props) => {
  const { value, spacing = 5, starSize = 30 } = props;

  const Star = ({ fill, spacing, starSize, ...rest }) => (
    <img
      src={fill ? StarFilled : StarEmpty}
      style={{ width: starSize, height: starSize, objectFit: 'contain', margin: `0 ${spacing}px` }}
      alt=''
      {...rest}
    />
  );

  const StarArray = [
    {
      fill: value >= 1,
    },
    {
      fill: value >= 2,
    },
    {
      fill: value >= 3,
    },
    {
      fill: value >= 4,
    },
    {
      fill: value >= 5,
    },
  ];

  return (
    <>
      {StarArray.map((star, i) => (
        <Star {...star} key={i} spacing={spacing} starSize={starSize} />
      ))}
    </>
  );
};

const AchievementItem = (props) => {
  const { item, style, darkMode } = props;
  const [isSmall, setIsSmall] = React.useState(false);
  const [isSmaller, setIsSmaller] = React.useState(false);
  const [modal, setModal] = React.useState({
    isOpen: false,
    uri: null,
  });

  // const history = useHistory();

  const rvlRef = React.useRef();
  let observer = null;

  React.useEffect(() => {
    // eslint-disable-next-line
    observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      if (boxElem.contentRect.width < 600) {
        if (boxElem.contentRect.width < 430) {
          setIsSmaller(true);
        } else {
          setIsSmaller(false);
        }
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    });
    if (observer) {
      observer.observe(rvlRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  // const goUserProfile = (id) => {
  //   const currentUserId = localStorage.getItem('userId');
  //   if (currentUserId === id) {
  //     history.push(routes.PROFILE_ROUTE);
  //   } else {
  //     history.push(`${routes.PROFILE_ROUTE}/${id}`);
  //   }
  // };

  const achievementImage = item?.active ? item.badge : item.disableBadge;

  const { t } = useTranslation();

  return (
    <div
      ref={rvlRef}
      className='AchievementItem__Container'
      style={Object.assign(
        {
          filter: item?.active ? 'unset' : 'grayscale(1)',
          paddingTop: item && item.new && item.active ? 50 : 10,
        },
        style
      )}
    >
      {item && item.new && item.active ? <div className='AchievementItem__New-chiev-header'>{t('ACHIEVEMENTS.new')}</div> : null}
      <div className='Achievement__Picture-container' style={{ width: isSmall ? 70 : 100, height: isSmall ? 70 : 100 }}>
        <img
          src={achievementImage}
          alt=''
          className='AchievementItem__Img'
          onClick={() => setModal({ ...modal, isOpen: true, uri: achievementImage })}
        />
      </div>
      <div className='Achievement__content' style={{ width: `calc(100% - ${isSmall ? 80 : 110}px)` }}>
        <div className='AchievementItem__Title'>
          <img src={Ribbon} alt='' style={{ width: isSmall ? 20 : 30 }} />
          <p style={{ fontSize: isSmaller ? '.8rem' : isSmall ? '1rem' : '1.2rem' }}>{item.name}</p>
        </div>
        <div className='Achievement__Start' style={{ height: isSmall ? 25 : 40 }}>
          <Stars value={item.stars} spacing={isSmall ? 2 : 3} starSize={isSmall ? 20 : 30} />
        </div>
        <p className='Achievement__Description' style={{ fontSize: isSmaller ? '.7rem' : isSmall ? '.8rem' : '1rem' }}>
          {item.description}
        </p>
      </div>
      <PreviewChievModal
        isOpen={modal.isOpen}
        uri={modal.uri}
        onRequestClose={() => setModal({ ...modal, isOpen: false, uri: null })}
        darkMode={darkMode}
      />
    </div>
  );
};

export default AchievementItem;
