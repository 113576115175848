import { getData } from '../../fetch';

export const getDeals = ({userId, skipItems, limitItems, search, admin, sinceDate, toDate}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/deals/get-deals-web/?userId=${userId}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&admin=${admin}&sinceDate=${sinceDate}&toDate=${toDate}`
      );
      // console.log("getPosts / response: ", response);
      const laws = response.body;
      return resolve(laws);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  });
};

export const getDealDetails = (dealId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/deals/get-deal-details-web/?dealId=${dealId}`
      );
      // console.log("getVotingDetails / response: ", response);
      const deal = response.body;
      return resolve(deal);
    } catch (e) {
      // console.log("getVotingDetails / error: ", e);
      return reject(e);
    }
  });
};
