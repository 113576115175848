import React from 'react';
import { FEDERAL_BLUE, WHITE_MAIN } from '../../color-palatte';
import './date-input.css';

const DateInput = ({
  className,
  title,
  value,
  max,
  min,
  name,
  placeholder,
  isWhite,
  styles,
  inputClassName,
  onChange,
  disabled,
}) => {
  // console.log("isWhite: ", isWhite)
  return (
    <div className={`DateInput__Container ${className}`}>
      <p style={{ color: isWhite ? WHITE_MAIN : FEDERAL_BLUE }}>{title}</p>
      <input
        type='date'
        value={value}
        name={name}
        max={max}
        min={min}
        style={Object.assign(
          {
            border: `2px solid ${isWhite ? WHITE_MAIN : FEDERAL_BLUE}`,
            opacity: disabled ? '0.5' : 'unset',
          },
          styles
        )}
        placeholder={placeholder}
        className={`${
          isWhite ? 'DateInput-white' : 'DateInput'
        } ${inputClassName} `}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default DateInput;
