//MAIN
export const FEDERAL_BLUE = "#2573b4"; //federal standard 15180
export const FEDERAL_BLUE_STRONG = "#04458F"; //federal standard 15180
export const EASY_BLUE = "rgb(214,234,250)"; //federal standard 15180
export const FEDERAL_BLUE_SOFT = '#4AA7E5';
export const FEDERAL_LOW_BLUE = "#dde5ed"; //federal standard 15180
export const FEDERAL_LIGTH_BLUE = "#d6eafa";
export const FEDERAL_BLACK = "#2d2926"; //federal standard 17038
export const FEDERAL_GREEN_WEAK = "#D6FADC"; //federal standard 17038
export const FEDERAL_GRAY = "#61819a"; //federal standard 15177
export const FEDERAL_WHITE = "#e7eae1"; //federal standard 17773
export const SERVICES_BACKGROUND_WHITE = "#f2f2f2";
export const USER_FINANCE_BACKGROUND_WHITE = "#dddddf";
export const WG_STATS_BACKGROUND_WHITE = "rgb(255, 255, 255)";
export const VOTING_BACKGROUND_WHITE = "#ffffff";
export const FEDERAL_WHITE2 = "#f1f0eb"; //federal standard 17773
export const FEDERAL_RED = "#ae2728"; //federal standard 17773
export const BLACK = "black";
export const GOLD = "#eab143";
export const WARNING_YELLOW = "#f8e84d";
export const FEDERAL_GREEN = "#5fdba7"; //14066 DoT Highway Green
export const BLUE_LINK = "#15c";
export const GRAY_WHITE = "#ddd";
export const FEDERAL_DASHBOARD_BG = '#CCCCCC';
export const WHITE_DARK = '#E6E6E6'

//EXTRAS
export const WHITE_MAIN = "#FFFFFF";
export const WHITE_SECOND = "#FFFDFD";
export const GRAY_MAIN = "#6D6868";
export const GRAY_SECOND = "#272323";
export const GRAY_SECOND_TRANSPARENCY = "rgba(39, 35, 35, 0.8)";
export const GRAY_SECOND_TRANSPARENCY_FULL = "rgba(39, 35, 35, 0.2)";
export const FEDERAL_BLACK_LIGHT = '#343434';
export const WARNING_RED = '#EB2012';
export const WARNING_RED_TRANSPARENCY = '#EB211285';

//DARK MODE
export const UNSATURATED_BLACK_BLUE = "#1D3A5B";
export const DARK_OFFICIAL_BLUE = "#011327";
export const BRIGHT_BLUE = "#4AA7E5";
export const BRIGHT_BLUE_HOVER = "rgba(74, 167, 229, 0.6)";
export const LIGHT_HOVER = "rgba(200,200,200,0.3)";
export const DARK_HOVER = "rgba(30,30,30,0.3)";
export const WHITE_HOVER = "rgba(255,255,255,0.4)";
export const BLACK_HOVER = "rgba(30,30,30,0.4)";
export const BLACK_HOVER_SOFT = "rgba(60,65,70,1)";
