import React from 'react';
import styles from './styles.module.css';
import AlertModal from '../../utils/modal/Modal';
import { useTranslation } from 'react-i18next';
// import { ParsedText } from '../../utils/text-utils/text-utils';
import * as colors from '../../utils/color-palatte';
import { AppLoader } from '../../utils/animations/animations';

export const DeleteModalButton = (props) => {
  const { text, isMobile, hoverStyle, style, fetching, hasLoader, loaderColor, ...rest } = props;
  const [isHover, setIsHover] = React.useState(false);
  return (
    <button
      onMouseEnter={() => (isMobile ? void 0 : setIsHover(true))}
      onMouseLeave={() => (isMobile ? void 0 : setIsHover(false))}
      onTouchStart={() => (isMobile ? setIsHover(true) : void 0)}
      onTouchEnd={() => (isMobile ? setIsHover(false) : void 0)}
      className={styles.privacyButton}
      style={Object.assign({opacity: fetching ? '0.5' : '1'}, isHover && hoverStyle ? hoverStyle : style)}
      {...rest}
    >
      {fetching && hasLoader ? <AppLoader color={loaderColor} size='small' /> : text}
    </button>
  );
};

const DeleteWarnModal = (props) => {
  const { isOpen, onRequestClose, user, onContinueClick, fetching } = props;

  const { username } = user;

  const { t } = useTranslation();

  const [isSmall, setIsSmall] = React.useState(false);

  const rvlRef = React.useRef();
  let observer = null;

  React.useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line
      observer = new ResizeObserver((entries) => {
        const boxElem = entries[0];
        if (boxElem.contentRect.width < 450) {
          setIsSmall(true);
        } else {
          setIsSmall(false);
        }
        // console.log(isSmall);
      });
      if (rvlRef && observer) {
        observer.observe(rvlRef.current);
      }
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isOpen, rvlRef, isSmall]);

  return (
    <AlertModal
      ref={rvlRef}
      isOpen={isOpen}
      outsideClick={onRequestClose}
      modalClassName={styles.modal__1}
      overlayClassName={styles.overlay}
    >
      <h1 style={{ fontSize: `${isSmall ? 1.5 : 2}rem` }}>{t('PRIVACY.MODALS.DELETE_WARN.title')}</h1>
      <div className={styles.warningWrapper}>
        <p className={styles.warningText}>
          <span style={{ fontWeight: 'bold' }}>{t('PRIVACY.MODALS.DELETE_WARN.subTitle')},&nbsp;</span>
          {t('PRIVACY.MODALS.DELETE_WARN.content')}:
        </p>
        {/* <ParsedText
          string={t('PRIVACY.MODALS.DELETE_WARN.contentList')(username)}
          values={[{ keyword: `${username}`, style: { fontWeight: 'bold', color: colors.GRAY_SECOND } }]}
          className={styles.ListParagraph}
          style={{ width: `calc(100% - ${isSmall ? 20 : 40}px)`}}
        /> */}
        <p className={styles.ListParagraph} dangerouslySetInnerHTML={{ __html: t('PRIVACY.MODALS.DELETE_WARN.contentList')(username) }} />
        <div
          className={styles.buttonContainer}
          style={{
            flexFlow: isSmall ? 'column' : 'row',
            height: `${isSmall ? 140 : 80}px`,
            justifyContent: isSmall ? 'center' : 'space-around',
          }}
        >
          <DeleteModalButton
            text={t('PRIVACY.MODALS.DELETE_WARN.button')}
            fetching={fetching}
            hasLoader
            style={{ backgroundColor: colors.FEDERAL_BLUE }}
            hoverStyle={{ backgroundColor: colors.FEDERAL_BLUE_SOFT }}
            onClick={() => onContinueClick()}
            loaderColor={colors.WHITE_MAIN}
          />
          <DeleteModalButton
            text={t('GENERAL_TEXT.cancel')}
            fetching={fetching}
            style={{ backgroundColor: colors.UNSATURATED_BLACK_BLUE }}
            hoverStyle={{ backgroundColor: colors.FEDERAL_BLUE }}
            onClick={() => onRequestClose()}
            loaderColor={colors.WHITE_MAIN}
          />
        </div>
      </div>
    </AlertModal>
  );
};

export default DeleteWarnModal;
