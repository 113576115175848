import React, { useState } from 'react';
import WatchIcon from '../../../assets/app-inputs/password-input/icon_watch_password.svg';
import WatchIconWhite from '../../../assets/app-inputs/password-input/icon_watch_password_white.svg';
import UnwatchIcon from '../../../assets/app-inputs/password-input/icon_unwatch_password.svg';
import UnwatchIconWhite from '../../../assets/app-inputs/password-input/icon_unwatch_password_white.svg';
import { FEDERAL_BLUE } from '../../color-palatte';
import './password-input.css';

const PasswordInput = ({
  className,
  isWhite,
  inputClassName,
  style,
  placeholder,
  name,
  onChange,
  value,
  id,
  disabled,
  onKeyPress,
}) => {
  const [visibility, setVisibility] = useState(false);

  const toggleVisibility = () => {
    setVisibility(!visibility);
  };

  return (
    <div className={`${className} PasswordInput__Container`}>
      <input
        type={disabled ? 'password' : visibility ? 'text' : 'password'}
        id={id}
        className={`${
          isWhite ? 'PasswordInput-white' : 'PasswordInput'
        } ${inputClassName}`}
        style={Object.assign(
          {
            border: `2px solid ${isWhite ? 'white' : FEDERAL_BLUE}`,
            opacity: disabled ? '0.5' : 'unset',
          },
          style
        )}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onKeyPress={onKeyPress}
      />
      {isWhite && (
        <img
          src={disabled ? WatchIconWhite : visibility ? UnwatchIconWhite : WatchIconWhite}
          alt=''
          className='PasswordInput__Image'
          onClick={disabled ? null : () => toggleVisibility()}
          style={{ opacity: disabled ? '0.5' : 'unset', cursor: disabled ? 'normal' : 'pointer' }}
        />
      )}
      {!isWhite && (
        <img
          src={disabled ? WatchIcon : visibility ? UnwatchIcon : WatchIcon}
          alt=''
          className='PasswordInput__Image'
          onClick={disabled ? null : () => toggleVisibility()}
          style={{ opacity: disabled ? '0.5' : 'unset', cursor: disabled ? 'normal' : 'pointer' }}
        />
      )}
    </div>
  );
};

export default PasswordInput;
