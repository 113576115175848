import { postData } from '../fetch';

export const translateText = async (content, target) => {
  const userId = localStorage.getItem('userId');
  // console.log("translateText / target: ", target)
  const data = {
    userId,
    content,
    target: target === "us" ? "en" : "es"
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(`/post/translate-text-web`, data);
      // console.log("translateText / response: ", response)
      return resolve(response.body);
    } catch (e) {
      // console.log("translateText / e: ", e)
      return reject(e);
    }
  });
};

export default translateText;
