import React from 'react';
import './animations.css';

export const Anim1 = ({ style, ballColor, bColor1, bColor2, bColor3 }) => {
  return (
    <div className='Anim__Container' style={style}>
      <div className='Anim__Content'>
        <div
          className='Anim1__Ball1'
          style={{ backgroundColor: bColor1 ?? ballColor }}
        />
        <div
          className='Anim1__Ball2'
          style={{ backgroundColor: bColor2 ?? ballColor }}
        />
        <div
          className='Anim1__Ball3'
          style={{ backgroundColor: bColor3 ?? ballColor }}
        />
      </div>
    </div>
  );
};

export const AppLoader = ({ size = '60px', style, svgStyle, color }) => (
  <div
    className='AppLoader'
    style={Object.assign(
      size === 'small'
        ? { width: '30px', height: '30px' }
        : size === 'big'
        ? { width: '100px', height: '100px' }
        : { width: size, height: size },
      { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      style
    )}
  >
    <svg viewBox='0 0 61 61' style={Object.assign({ width: 'calc(100% - 10px)' }, svgStyle)}>
      <g transform='translate(-263 22421)'>
        <path d='M0,0H61V61H0Z' transform='translate(263 -22421)' fill='none' />
        <g transform='translate(-57 41)'>
          <path
            d='M27.5,55.5A27.5,27.5,0,0,1,4.967,12.227L9.074,15.1A22.5,22.5,0,1,0,50,28a22.335,22.335,0,0,0-4.074-12.9l4.106-2.874A27.495,27.495,0,0,1,27.5,55.5Z'
            transform='translate(323 -22459.5)'
            fill={color ?? '#2573b4'}
          />
          <circle
            cx='27.5'
            cy='27.5'
            r='27.5'
            transform='translate(323 -22459)'
            fill='none'
          />
        </g>
      </g>
    </svg>
  </div>
);
