export const SAVE_USER = 'SAVE_USER'
export const SAVE_USER_POSTS = 'SAVE_USER_POSTS'
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE'
export const LOGOUT = 'LOGOUT'
export const UPDATE_USER_POSTS_ITEM = 'UPDATE_USER_POSTS_ITEM';
export const REMOVE_SEARCH_POSTS_ITEM = 'REMOVE_SEARCH_POSTS_ITEM';
export const REMOVE_USER_POSTS_ITEM = 'REMOVE_USER_POSTS_ITEM';
export const REMOVE_PROFILE_POSTS_ITEM = 'REMOVE_PROFILE_POSTS_ITEM';
export const OPEN_MESSAGE_MODAL = 'OPEN_MESSAGE_MODAL';
export const CLOSE_MESSAGE_MODAL = 'CLOSE_MESSAGE_MODAL';
export const SHOW_PAYMENT_MODAL_AGREEMENT = 'SHOW_PAYMENT_MODAL_AGREEMENTCUALE';
export const CLOSE_PAYMENT_MODAL_AGREEMENT = 'CLOSE_PAYMENT_MODAL_AGREEMENT';
export const SAVE_VOTING_REQUEST = 'SAVE_VOTING_REQUEST';
export const SAVE_VOTINGS = 'SAVE_VOTINGS';
export const UPDATE_VOTING_REQUEST = 'UPDATE_VOTING_REQUEST';
export const UPDATE_VOTING = 'UPDATE_VOTING';
export const SAVE_MENTIONS = 'SAVE_MENTIONS';
export const UPDATE_SEARCH_POSTS_ITEM = 'UPDATE_SEARCH_POSTS_ITEM';
export const SAVE_SEARCH_POSTS = 'SAVE_SEARCH_POSTS';
export const SAVE_COMMUNITY_POSTS = 'SAVE_COMMUNITY_POSTS';
export const UPDATE_COMMUNITY_POSTS_ITEM = 'UPDATE_COMMUNITY_POSTS_ITEM';
export const REMOVE_COMMUNITY_POSTS_ITEM = 'REMOVE_COMMUNITY_POSTS_ITEM';
export const SAVE_PROFILE_POSTS = 'SAVE_PROFILE_POSTS';
export const SAVE_HAS_NEW_NOTIFICATIONS = 'SAVE_HAS_NEW_NOTIFICATIONS';
export const SAVE_USER_ACHIEVEMENTS = 'SAVE_USER_ACHIEVEMENTS';
export const SAVE_USER_HAS_ENTERED = 'SAVE_USER_HAS_ENTERED';
export const SAVE_USER_CHATS = 'SAVE_USER_CHATS';
export const OPEN_DELETE_SUCCESS_MODAL = 'OPEN_DELETE_SUCCESS_MODAL';
export const CLOSE_DELETE_SUCCESS_MODAL = 'CLOSE_DELETE_SUCCESS_MODAL';
export const OPEN_VERIFY_MODAL = 'OPEN_VERIFY_MODAL';
export const CLOSE_VERIFY_MODAL = 'CLOSE_VERIFY_MODAL';
export const OPEN_DIALOG_MODAL = 'OPEN_DIALOG_MODAL';
export const CLOSE_DIALOG_MODAL = 'CLOSE_DIALOG_MODAL';