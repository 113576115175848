import { getData, postData } from "../../fetch";

export const getCommunity = (communityId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/communities/get-community-web/?communityId=${communityId}`);
      // console.log("getCommunity / response: ", response);
      const community = response.body;
      return resolve(community[0]);
    } catch (e) {
      // console.log("getCommunity / error: ", e);
      return reject(e);
    }
  })
};

export const joinCommunity = (userId, communityId) => {
  const data = {
    userId,
    communityId
  }
  return new Promise(async (resolve, reject) => {
    try {
      await postData(`/communities/join-community-web`, data);
      resolve();
    } catch (e) {
      reject(e);
    }
  })
}

export const leaveCommunity = (userId, communityId) => {
  const data = {
    userId,
    communityId
  }
  return new Promise(async (resolve, reject) => {
    try {
      await postData(`/communities/leave-community-web`, data);
      resolve();
    } catch (e) {
      reject(e);
    }
  })
}

export const editCommunity = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(`/communities/edit-community-web`, data);
      // console.log("changePassword / response: ", response);
      resolve(response.body);
    } catch (e) {
      reject(e);
    }
  })
}

export const deleteCommunity = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(`/communities/delete-community-web`, data);
      // console.log("changePassword / response: ", response);
      resolve(response.body);
    } catch (e) {
      reject(e);
    }
  })
}