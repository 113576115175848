import React from 'react';
// import Carousel, { consts } from 'react-elastic-carousel';
//import { defaultFocusOptions } from 'react-globe';
import { Translation } from 'react-i18next';
// import i18n from '../../../i18n/i18n';
// import LeftArrow from '../../../assets/home/home-carousel/left_arrow.svg';
// import RightArrow from '../../../assets/home/home-carousel/right_arrow.svg';
// // import ClientImg from '../../../assets/home/home-carousel/Critic.png';
// import EstebanImg from '../../../assets/home/home-carousel/esteban.png';
import './home-carousel.css';

class HomeCarousel extends React.Component {
  render() {
    // const ArrowChild = ({ img }) => (
    //   <img src={img} className='Home__Carousel-arrow' alt='' />
    // );

    // const Arrow = ({ type, onClick, isEdge }) => {
    //   const pointer =
    //     type === consts.PREV ? (
    //       <ArrowChild img={LeftArrow} />
    //     ) : (
    //       <ArrowChild img={RightArrow} />
    //     );
    //   return (
    //     <button
    //       className='Home__Carousel-btn'
    //       onClick={onClick}
    //       disabled={isEdge}
    //     >
    //       {pointer}
    //     </button>
    //   );
    // };

    // const CardsCarousel = ({ image, name, job, ocupation, opinion }) => (
    //   <div className='CardsCarousel'>
    //     <div className='ClientContainer'>
    //       <img src={image} draggable='false' alt='Client' />
    //     </div>
    //     <div className='TextCritic'>
    //       <h1>{name}</h1>
    //       {job && <h4>{job}</h4>}
    //       <h2>{ocupation || <>&nbsp;</>}</h2>
    //       <p>{opinion}</p>
    //     </div>
    //   </div>
    // );

    // const Opinion = [
    //   {
    //     image: EstebanImg,
    //     name: i18n.t('HOME_CAROUSEL.person1-name'),
    //     job: i18n.t('HOME_CAROUSEL.person1-job'),
    //     opinion: i18n.t('HOME_CAROUSEL.person1-opinion'),
    //   },
    // ];

    return (
      <Translation>
        {(t) => (
          <div className='CarouselContainer'>
            {/* <h1>{t('HOME_CAROUSEL.title')}</h1>
            <Carousel
              style={{ marginBottom: '50px' }}
              renderArrow={Arrow}
              renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <div className='Home__Carousel-flex'>
                    {pages.map((page) => {
                      const isActivePage = activePage === page;
                      return (
                        <div
                          className='Home__Carousel-pagination'
                          key={page}
                          onClick={() => onClick(page)}
                          activePage={isActivePage}
                          style={{
                            border: `1px solid ${isActivePage ? 'white' : 'transparent' }`,
                            cursor: isActivePage ? 'default' : 'pointer',
                          }}
                        >
                          <div style={{ opacity: isActivePage ? undefined : '.5'}}/>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            >
              {Opinion.map((opinion, i) => (
                <CardsCarousel {...opinion} key={i} />
              ))}
            </Carousel> */}
            <svg viewBox='0 0 100 100' preserveAspectRatio='none'>
              <polygon className='diagonal3' points='0,100 100,0 100,100' />
            </svg>
          </div>
        )}
      </Translation>
    );
  }
}

export default HomeCarousel;
