import { getData, postData } from "../../fetch";

export const getUsernameAvailability = (username) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/user/get-username-availability/${username}`);
      // console.log("getUsernameAvailability / response: ", response);
      resolve(response.body);
    } catch (e) {
      reject(e);
    }
  })
}

export const editCommunity = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(`/communities/edit-community`, data);
      // console.log("changePassword / response: ", response);
      resolve(response.body);
    } catch (e) {
      reject(e);
    }
  })
}

export const createNewCommunity = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(`/communities/create-community-web`, data);
      // console.log("createNewCommunity / response: ", response);
      resolve(response.body);
    } catch (e) {
      // console.log("createNewCommunity / e: ", e);
      reject(e);
    }
  })
}