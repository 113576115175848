import React, { Component } from 'react';
const URL = require('url-parse');

class SafeURL extends Component {
  isSafe(dangerousURL, text) {
    const url = URL(dangerousURL, {});
    if (url.protocol === 'http:') return true;
    if (url.protocol === 'https:') return true;
    if (url.protocol === 'mailto:') return true;
    if (url.protocol === 'tel:') return true;
    return false;
  }

  render() {
    const dangerousURL = this.props.dangerousURL;
    const safeURL = this.isSafe(dangerousURL) ? dangerousURL : null;
    return (
      <a
        href={safeURL}
        style={this.props.style}
        className={this.props.className}
        onClick={this.props.onClick}
        target={this.props.newTab ? "_blank" : ""}
        rel={this.props.newTab ? "noopener norefer" : ""}
      >
        {this.props.text}
      </a>
    );
  }
}

export default SafeURL;
