import { getData } from '../../fetch';

export const getPositionHistory = ({ skipItems, limitItems, search, sinceDate, toDate }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/position-history/get-positions-history-web/?skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&sinceDate=${sinceDate}&toDate=${toDate}`
      );
      // console.log("getPositionHistory / response: ", response);
      const positions = response.body;
      return resolve(positions);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  });
};
