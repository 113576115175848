import React from 'react';
import { Translation, useTranslation } from 'react-i18next';
// import RoadmapDesktop from '../../../assets/home/roadmap/roadmap-desktop.png';
// import RoadmapMobile from '../../../assets/home/roadmap/roadmap-mobile.png';
import RoadmapDesktop from '../../../assets/home/roadmap/roadmap_desktop.svg';
import RoadmapMobile from '../../../assets/home/roadmap/roadmap_mobile.svg';
import CheckIcon from '../../../assets/home/roadmap/check_icon.svg';
import RunPlayer from '../../../assets/home/roadmap/run_player.svg';
import './home-roadmap.css';
import { useHistory } from 'react-router-dom';
import * as routes from '../../../routes/routes';
import * as colors from '../../../utils/color-palatte';
import moment from 'moment';

const Item = ({ number, date, description, style, onClick }) => {
  const { i18n } = useTranslation();

  const dateString = i18n.language === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY';

  const passDate = moment(date, dateString) <= moment(Date.now());

  return (
    <div className='Roadmap-item-container' style={style} onClick={onClick}>
      <div
        className='Roadmap-item-number-container'
        style={{ border: `4px solid ${passDate ? '#00AB66' : colors.FEDERAL_BLUE}` }}
      >
        {passDate ? (
          <img src={CheckIcon} alt='' style={{ width: '22px', marginTop: 3 }} className='Roadmap__Check-Icon' />
        ) : (
          <p className='Roadmap-item-number'>{number}</p>
        )}
      </div>
      <div>
        <p className='Roadmap-item-date'>{date}</p>
      </div>
      <div>
        <p className='Roadmap-item-description'>{description}</p>
      </div>
    </div>
  );
};

const HomeRoadmap = () => {
  const [matches, setMatches] = React.useState(window.matchMedia('(min-width: 768px)').matches);

  const history = useHistory();

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(min-width: 768px)').addEventListener('change', handler);
    return () => {
      window.matchMedia('(min-width: 768px)').removeEventListener('change', handler);
    };
  }, []);

  const dateString = i18n.language === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY';

  const datePass1 = moment(t('ROADMAP.date1'), dateString) <= moment(Date.now());
  const datePass2 = moment(t('ROADMAP.date2'), dateString) <= moment(Date.now());
  const datePass3 = moment(t('ROADMAP.date3'), dateString) <= moment(Date.now());
  const datePass4 = moment(t('ROADMAP.date4'), dateString) <= moment(Date.now());
  const datePass5 = moment(t('ROADMAP.date5'), dateString) <= moment(Date.now());

  const steps = [
    {
      number: 1,
      date: t('ROADMAP.date1'),
      description: t('ROADMAP.description1'),
      style: matches ? { left: 0, top: '-20%' } : { right: 0, top: 0 },
      onClick: () =>
        history.push(`${routes.WHITEPAPER_ROUTE}/roadmap#${String(moment(t('ROADMAP.date1'), dateString).format('MM-DD-YYYY'))}`),
    },
    {
      number: 2,
      date: t('ROADMAP.date2'),
      description: t('ROADMAP.description2'),
      style: matches ? { left: '22%', bottom: '-20%' } : { top: '25%', left: 20 },
      onClick: () =>
        history.push(`${routes.WHITEPAPER_ROUTE}/roadmap#${String(moment(t('ROADMAP.date2'), dateString).format('MM-DD-YYYY'))}`),
    },
    {
      number: 3,
      date: t('ROADMAP.date3'),
      description: t('ROADMAP.description3'),
      style: matches ? { right: '24%', bottom: '-30%' } : { top: '40%', right: 0 },
      onClick: () =>
        history.push(`${routes.WHITEPAPER_ROUTE}/roadmap#${String(moment(t('ROADMAP.date3'), dateString).format('MM-DD-YYYY'))}`),
    },
    {
      number: 4,
      date: t('ROADMAP.date4'),
      description: t('ROADMAP.description4'),
      style: matches ? { right: '10%', top: '-28%' } : { top: '60%', left: 20 },
      onClick: () =>
        history.push(`${routes.WHITEPAPER_ROUTE}/roadmap#${String(moment(t('ROADMAP.date4'), dateString).format('MM-DD-YYYY'))}`),
    },
    {
      number: 5,
      date: t('ROADMAP.date5'),
      description: t('ROADMAP.description5'),
      style: matches ? { right: 0, bottom: '-40%' } : { top: '82%', right: 0 },
      onClick: () =>
        history.push(`${routes.WHITEPAPER_ROUTE}/roadmap#${String(moment(t('ROADMAP.date5'), dateString).format('MM-DD-YYYY'))}`),
    },
  ];

  return (
    <Translation>
      {(t) => (
        <div className='Roadmap-container'>
          <h2>{t('ROADMAP.title')}</h2>
          <div style={{ position: 'relative', maxWidth: 1000, margin: 'auto' }}>
            {matches ? (
              <div className='Roadmap-desktop__container'>
                <img
                  src={RunPlayer}
                  alt=''
                  className='RoadMap__desktop_run'
                  style={{
                    top: `${datePass5 ? 65 : datePass4 ? 10 : datePass3 ? 10 : datePass2 ? 75 : datePass1 ? 20 : 35}%`,
                    left: `${datePass5 ? 80 : datePass4 ? 60 : datePass3 ? 42 : datePass2 ? 45 : datePass1 ? 25 : 10}%`,
                  }}
                />
                <img alt='' className='Roadmap-desktop' src={RoadmapDesktop} />
              </div>
            ) : (
              <div className='Roadmap-mobile__container'>
                <img src={RunPlayer} alt='' className='RoadMap__mobile_run' style={{ left: '35%', top: `${datePass5 ? 90 : datePass4 ? 75 : datePass3 ? 55 : datePass2 ? 35 : datePass1 ? 12 : -4}%`}} />
                <img alt='' className='Roadmap-mobile' src={RoadmapMobile} />
              </div>
            )}
            {steps.map((item) => (
              <Item {...item} key={Math.random()} />
            ))}
          </div>
        </div>
      )}
    </Translation>
  );
};

export default HomeRoadmap;
