import React, { useState, useEffect } from 'react';
import { Trans, Translation } from 'react-i18next';
import { FEDERAL_BLUE, FEDERAL_BLUE_SOFT, WARNING_YELLOW } from '../../../utils/color-palatte';
import SafeLINK from '../../../utils/safe-link';
import { BSpan, BLink } from '../../../utils/text-utils/text-utils';
import InfoModal from '../../info-modals/info-modals';
import * as routes from '../../../routes/routes';
import { mobileRegex1, mobileRegex2 } from '../../../utils/mobileRegex';
import { DownloadButton } from '../home-download/home-download';
import './banner.css';

const Banner = () => {
  const [modal, setModal] = useState({
    isOpen: false,
    type: undefined,
  });

  const [matches, setMatches] = useState(window.matchMedia('(max-width: 400px)').matches);

  const [isMobile, setIsMobile] = useState(false);

  const openModal = (isOpen, type) => {
    setModal({
      isOpen: isOpen,
      type: type,
    });
  };

  useEffect(() => {
    if (mobileRegex1.test(navigator.userAgent) || mobileRegex2.test(navigator.userAgent.substr(0, 4))) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 400px)').addEventListener('change', handler);
    return () => window.matchMedia('(max-width: 400px)').removeEventListener('change', handler);
  }, []);

  return (
    <Translation>
      {(t) => (
        <div className='Home-Banner'>
          <h1>
            <Trans i18nKey='HOME_BANNER.H1'>
              text <span style={{ color: FEDERAL_BLUE_SOFT }}>text</span>!
            </Trans>
          </h1>
          <p style={{ fontSize: 16, lineHeight: 1.5, marginTop: 30 }}>
            <Trans i18nKey='HOME_BANNER.P'>
              bla<BLink route={routes.LOGIN_ROUTE}>bla</BLink>bla
              <BSpan onClick={() => openModal(true, 'voting')}>bla</BSpan>
              bla
            </Trans>
          </p>
          <div className='Home__Banner-buttonArea' style={{ marginBottom: isMobile ? '0px' : matches ? '45vw' : '36vw' }}>
            <SafeLINK
              style={{ cursor: 'pointer' }}
              text={t('HOME_BANNER.BUTTON')}
              className='Home__Banner-Button'
              dangerousLINK={routes.SIGN_UP_ROUTE}
            />
            <SafeLINK
              style={{ cursor: 'pointer', backgroundColor: WARNING_YELLOW, color: FEDERAL_BLUE }}
              text='Whitepaper'
              className='Home__Banner-Button'
              dangerousLINK={routes.WHITEPAPER_ROUTE}
            />
          </div>
          {isMobile && (
            <div className='Home__Banner__DownloadArea' style={{ marginBottom: matches ? '45vw' : '36vw', float: 'left' }}>
              <DownloadButton type='android' imgStyle={{ width: '255px', margin: '10px 20px' }} />
              <DownloadButton type='ios' imgStyle={{ width: '255px', margin: '10px 20px' }} />
            </div>
          )}
          <svg viewBox='0 0 100 100' preserveAspectRatio='none'>
            <polygon className='diagonal1' points='0,100 100,0 100,100' />
          </svg>
          <InfoModal isOpen={modal.isOpen} type={modal.type} onRequestClose={() => setModal({ ...modal, isOpen: false })} />
        </div>
      )}
    </Translation>
  );
};

export default Banner;
