import i18n from '../i18n/i18n';

const handlePositions = (position) => {
  if (position === 'president') {
    return i18n.t('GENERAL_TEXT.president');
  }
  if (position === 'vicepresident') {
    return i18n.t('GENERAL_TEXT.vicepresident');
  }
  return i18n.t('GENERAL_TEXT.citizen');
};

export default handlePositions
