import React from 'react';
import { FEDERAL_BLUE, WHITE_MAIN } from '../../color-palatte';
import SearchIcon from '../../../assets/app-inputs/search-input/search_icon.svg';
import SearchIconWhite from '../../../assets/app-inputs/search-input/search_icon_white.svg';
import './searchInput.css';

const SearchInput = (props) => {
  const {
    darkMode,
    style,
    className,
    type,
    placeholder,
    name,
    value,
    onChange,
    id,
    inputClassName,
    inputStyle,
    disabled,
    autoComplete,
    maxLength,
    onKeyPress,
    rightComponent,
  } = props;
  return (
    <div
      className={`SearchInput__Container ${className}`}
      style={Object.assign(
        {
          border: `2px solid ${darkMode ? WHITE_MAIN : FEDERAL_BLUE}`,
        },
        style
      )}
    >
      <img
        src={darkMode ? SearchIconWhite : SearchIcon}
        draggable='false'
        alt=''
      />
      <input
        type={type || 'text'}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        name={name}
        id={id}
        className={`${
          darkMode ? 'SearchInputDark' : 'SearchInput'
        } ${inputClassName}`}
        style={Object.assign(
          { width: `calc(${rightComponent ? '100% - 80px' : '100% - 40px'})` },
          inputStyle
        )}
        disabled={disabled}
        autoComplete={autoComplete}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
      />
      {rightComponent && rightComponent}
    </div>
  );
};

export default SearchInput;
