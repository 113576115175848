import React from 'react';
import { Translation } from 'react-i18next';
import IconPaswordChecked from '../../assets/password-done/iconPasswordChecked.svg';
import SafeLINK from '../../utils/safe-link';
import * as routes from '../../routes/routes';
import './password-done.css';

const PasswordDone = () => (
  <Translation>
    {(t) => (
      <div className='PassDoneContainer'>
        <div className='PassDoneSubContainer'>
          <div className='DoneImgContainer'>
            <img src={IconPaswordChecked} alt='IconPasswordChecked' />
          </div>
          <div className='DonePassTextContainer'>
            <h1>{t('done')}!</h1>
            <p>{t('pass-done')}!</p>
            <SafeLINK
              dangerousLINK={routes.LOGIN_ROUTE}
              className='PasswordDone__Button'
              text={t('continue')}
            />
          </div>
        </div>
      </div>
    )}
  </Translation>
);

export default PasswordDone;
