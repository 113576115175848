import React from 'react';
import AlertModal from '../../../utils/modal/Modal';
import Character from '../../../assets/contribution/albert-happy.png';
import { Translation } from 'react-i18next';
import '../new-contribution-modal/new-contribution-modal.css';

const ContributionDoneModal = ({ isOpen, onFinishPayment, onRequestClose }) => {

  // console.log("store.getState().user: ", store.getState().user)
  return (
    <Translation>
      {(t) => (
        <>
          <AlertModal
            isOpen={isOpen}
            modalClassName='NewContributionPayment__Container'
            overlayClassName='NewPayment__Overlay'
            outsideClick={onRequestClose}
          >
            <img src={Character} alt='' className='NewPayment__img' />
            <div className='NewContributionPayment__Content'>
              <h1>{t('CONTRIBUTION.contributionDoneTitle')}</h1>
              <p>
                {t('CONTRIBUTION.contributionDoneContent')}
              </p>
              <button onClick={() => {
               onFinishPayment()
              }}>
                {t('PAYMENTS_MODALS.continue')}
              </button>
            </div>
          </AlertModal>
        </>
      )}
    </Translation>
  );
};

export default ContributionDoneModal;
