import React from "react";
import './user-ref.css';
import * as routes from "../../routes/routes";
// import i18n from "../../i18n/i18n";
import {
  FEDERAL_BLUE,
  FEDERAL_BLUE_SOFT,
  UNSATURATED_BLACK_BLUE,
  WHITE_MAIN,
} from "../../utils/color-palatte";
import handlePositions from "../../utils/handlePositionName";
import { UserName, UserProfile } from "../ornament-profile/profileUtils";
import { useHistory } from "react-router-dom";

const UserRef = (props) => {

  const { item, darkMode, positions, style } = props

  const history = useHistory()

  let currentPosition = ''

  const goUserProfile = (post) => {
    const userId = post._id || post.user;
    const currentUserId = localStorage.getItem("userId");
    if (currentUserId === userId) {
      history.push(routes.PROFILE_ROUTE);
    } else {
      history.push(`${routes.PROFILE_ROUTE}/${userId}`);
    }
  };

  if (positions && positions?.length > 0 && item) {
    const havePosition = positions.filter(
      (position) => position.user._id === item._id
    );
    if (havePosition?.length > 0) {
      currentPosition = havePosition[0].position
    }
  }

  return (
    <div
      className="UserRef__Header"
      onClick={() => goUserProfile(item)}
      style={style}
    >
      <div className="VotingCard__UserPicture-container">
        <UserProfile
          uri={item?.picture}
          position={currentPosition}
        />
      </div>
      <div className="VotingCard__Header-data">
        <UserName
          username={item?.username}
          darkMode={darkMode}
          style={{ height: 30 }}
          titleStyles={{
            color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
            margin: 0,
            fontSize: item && item.username && item.username.length > 10 ? "1rem" : "1.2rem",
          }}
          aliasStyles={{
            color: darkMode ? "#CCC" : "#333",
            fontSize: ".9rem",
            margin: 0,
          }}
          position={currentPosition}
          crownStyle={{ width: 18 }}
          ornamentStyle={{ width: 18 }}
        />
        <p
          className="VotingCard__UserPosition-text"
          style={{
            color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
            background: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
            textTransform: "uppercase",
          }}
        >
          {handlePositions(currentPosition)}
        </p>
      </div>
    </div>
  )
}

export default UserRef