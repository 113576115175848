import React from 'react';
import './warning-styles.css';
import ainstainImg from '../../assets/characters/ainstain.png';
import { DARK_OFFICIAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';

export const Warning = ({ title, content, textCotent, style, isAinstain }) => {
  return (
    <div className='CreateVote__Important' style={style}>
      <div className='CreateVote__Important-lineTitle'>
        {isAinstain && <div className='smallCircle' />}
        {isAinstain && <div className='middleCircle' />}
        {isAinstain && <div className='largeCircle' />}
        <h3 style={isAinstain ? { color: DARK_OFFICIAL_BLUE, background: WHITE_MAIN, width: '90%' } : {}}>{title}</h3>
      </div>
      {isAinstain ? (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '20%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
            <img
              alt=''
              src={ainstainImg}
              style={{
                width: 60,
                height: 150,
              }}
            />
          </div>
          <div style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
            {content && (
              <p className='CreateVote__Important-P' style={{ whiteSpace: 'pre-line' }}>
                {content}
              </p>
            )}
            {textCotent}
          </div>
        </div>
      ) : (
        <>
          {content && (
            <p className='CreateVote__Important-P' style={{ whiteSpace: 'pre-line' }}>
              {content}
            </p>
          )}
          {textCotent}
        </>
      )}
    </div>
  );
};
