import React from 'react';
import AlertModal from '../../utils/modal/Modal';
import styles from './styles.module.css';
import { resendToken } from '../../pages/log-in/login-actions';
import closeIconWhite from '../../assets/terms-modal/close-btn-white.svg';
import emailIcon from '../../assets/contact-us/alert/email_icon_white.svg';
import Citizen from '../../assets/info-modals/citizen.png'
import { AppLoader } from '../../utils/animations/animations';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../hooks/useAlert';

const VerifyRequestModal = (props) => {
  const { useState, useEffect } = React;
  const { isOpen, onRequestClose, user } = props;
  const [isSendedCode, setIsSendedCode] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [responseText, setResponseText] = useState('');

  useEffect(() => {
    if (isOpen) {
      return () => {
        setIsSendedCode(false);
        setIsFetching(false);
        setResponseText('');
      };
    }
  }, [isOpen]);

  const { t } = useTranslation();

  const alert = useAlert();

  const SendCode = async () => {
    const { email } = user;
    if (!isFetching) {
      setIsFetching(true);
      try {
        const response = await resendToken(email.trim());
        setIsSendedCode(true);
        setResponseText(response?.body?.message);
        setIsFetching(false);
      } catch (e) {
        const error = e?.data?.error?.message ?? e?.data?.error ?? t('GENERAL_ERRORS.serverError');
        setIsFetching(false);
        alert('error', error.toString());
      }
    }
  };

  return (
    <AlertModal isOpen={isOpen} outsideClick={onRequestClose} modalClassName={styles.modal} overlayClassName={styles.overlay}>
      <img src={closeIconWhite} alt='close' className={styles.Close__Btn} onClick={onRequestClose} />
      {isFetching ? (
        <AppLoader size='big' color='#FFF' />
      ) : (
        <div className={styles.Info__Container}>
          <img src={Citizen} className={styles.Citizen__Img} alt='' />
          <div className={styles.Modal__Content}>
            <div className={styles.Modal__Title}>
              <img src={emailIcon} alt='' />
              <h1>{t('EMAIL_VERIFICATION.title')}</h1>
            </div>
            <p className={styles.Modal__Paragraph}>{isSendedCode ? responseText : t('EMAIL_VERIFICATION.content')}</p>
            <button className={styles.Modal__Button} onClick={() => SendCode()}>
              {isSendedCode ? t('EMAIL_VERIFICATION.BUTTON.sended') : t('EMAIL_VERIFICATION.BUTTON.unsended')}
            </button>
          </div>
        </div>
      )}
    </AlertModal>
  );
};

export default VerifyRequestModal;
