import React from 'react';
import AlertModal from '../../utils/modal/Modal';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from '../../config';
import { UNSATURATED_BLACK_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import './reCaptchaModal.css'

const ReCaptchaModal = React.forwardRef((props, ref) => {
  const { isOpen, onRequestClose, onChange, darkMode } = props;

  return (
    <AlertModal
      isOpen={isOpen}
      overlayClassName='ReCaptchaModal__Overlay'
      modalClassName='ReCaptchaModal__Container'
      style={{ backgroundColor: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN }}
      outsideClick={onRequestClose}
    >
      <ReCAPTCHA
        ref={ref}
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={onChange}
        theme={darkMode ? 'dark' : 'light'}
      />
    </AlertModal>
  );
});

export default ReCaptchaModal;
