import React from 'react';
import { Title, ContentTitle, Paragraph, ParsedParagraph, Banner, Divider, SubTitle } from '../utils/utils';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../utils/styles.module.css';
import EconomyBanner from '../../../assets/whitepaper/economy.svg';
import * as colors from '../../../utils/color-palatte';
import Alert from '../../../utils/alert/Alert';

const Economy = (props) => {
  const {
    darkMode,
    outer,
    mainTaxes,
    presSalary,
    vicePresSalary,
    contribAmount,
    contribPayEveryTime,
    subscriptionTaxes,
    subscriptionAmount,
    citizenGainForDeal,
    presidentSalaryAmount,
    vicepresidentSalaryAmount
  } = props;
  const { t } = useTranslation();
  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 1000px)').matches);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 1000px)').addEventListener('change', handler);
    return () => window.matchMedia('(max-width: 1000px)').removeEventListener('change', handler);
  }, []);

  const copyUrl = (hash) => {
    if (hash) {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}#${hash}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    } else {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    }
  };

  const sendToHash = (hash) => {
    if (hash) {
      history.push(`${location.pathname}#${hash}`);
    } else {
      history.push(location.pathname);
    }
  };

  const showAlert = (isOpen, type, content) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  const capital =
    mainTaxes && presSalary && vicePresSalary ? 100 - (Number(mainTaxes) + Number(presSalary) + Number(vicePresSalary)) : '...';
  const fullMaintenance = mainTaxes ? mainTaxes : '...';
  const dealMaintenance = mainTaxes && citizenGainForDeal ? Number(mainTaxes) - Number(citizenGainForDeal) : '...';
  const presSalaryPercent = presSalary ? presSalary : '...';
  const vicePresSalaryPercent = vicePresSalary ? vicePresSalary : '...';
  const contribution = contribAmount ? contribAmount : '...';
  const contributionTime = contribPayEveryTime ? Number(contribPayEveryTime) + 1 : '...';
  const mensualPayment = subscriptionTaxes && subscriptionAmount ? (Number(subscriptionTaxes) + Number(subscriptionAmount)).toFixed(2) : '...';
  const politicalMaintenance = presSalary && vicePresSalary ? Number(presSalary) + Number(vicePresSalary) : '...';
  const citizenGainDeal = citizenGainForDeal ? citizenGainForDeal : '...';

  const values = [
    {
      keyword: `${fullMaintenance}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${capital}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${presSalaryPercent}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${vicePresSalaryPercent}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${politicalMaintenance}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${presidentSalaryAmount}$`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${vicepresidentSalaryAmount}$`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
  ];

  const howDoesMoneyValues = [
    {
      keyword: `${dealMaintenance}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${fullMaintenance}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${capital}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${presSalaryPercent}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${vicePresSalaryPercent}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${contribAmount}$`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `$${contribAmount}`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${mensualPayment}$`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${politicalMaintenance}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${citizenGainDeal}%`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${presidentSalaryAmount}$`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${vicepresidentSalaryAmount}$`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
  ];

  const contribValues = [
    {
      keyword: `$${contribution}`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${contribution}$`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
    {
      keyword: `${contributionTime}`,
      style: { color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, fontWeight: 'bold', cursor: 'normal' },
    },
  ];

  return (
    <div className={styles.Container}>
      <div
        style={{
          width: matches ? '100%' : 'calc(100% - 300px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: outer ? 0 : '70px',
        }}
      >
        <div className={styles.Content}>
          <Title text={t('WHITEPAPER.ECONOMY.title')} darkMode={darkMode} onClick={() => copyUrl()} />
          <Divider darkMode={darkMode} />
          <Banner src={EconomyBanner} />
          <Divider darkMode={darkMode} />
          <ParsedParagraph
            string={t('WHITEPAPER.ECONOMY.content')(capital, fullMaintenance, presSalaryPercent, vicePresSalaryPercent, politicalMaintenance, presidentSalaryAmount, vicepresidentSalaryAmount)}
            darkMode={darkMode}
            hasWiki
            values={values}
          />
          <Divider darkMode={darkMode} half />
          <SubTitle
            text={t('WHITEPAPER.ECONOMY.howDoesMoney')}
            darkMode={darkMode}
            hash='how-does-money'
            location={location}
            onClick={() => copyUrl('how-does-money')}
          />
          <ParsedParagraph
            string={t('WHITEPAPER.ECONOMY.howDoesMoneyContent')(
              dealMaintenance,
              fullMaintenance,
              capital,
              presSalaryPercent,
              vicePresSalaryPercent,
              contribution,
              mensualPayment,
              politicalMaintenance,
              citizenGainDeal
            )}
            darkMode={darkMode}
            hasWiki
            values={howDoesMoneyValues}
          />
          <Divider darkMode={darkMode} half />
          <SubTitle
            text={t('WHITEPAPER.ECONOMY.contributions')}
            darkMode={darkMode}
            hash='contributions'
            location={location}
            onClick={() => copyUrl('contributions')}
          />
          <ParsedParagraph
            string={t('WHITEPAPER.ECONOMY.contributionContent')(contributionTime, contribAmount)}
            darkMode={darkMode}
            hasWiki
            values={contribValues}
          />
        </div>
      </div>
      <div className={styles.Wrapper} style={{ display: matches ? 'none' : 'flex', paddingTop: outer ? 0 : '70px' }}>
        <ContentTitle text={t('WHITEPAPER.content')} darkMode={darkMode} />
        <Paragraph onClick={() => sendToHash()} darkMode={darkMode} style={{ margin: 0, cursor: 'pointer' }}>
          {t('WHITEPAPER.ECONOMY.title')}
        </Paragraph>
        <Paragraph onClick={() => sendToHash('contributions')} darkMode={darkMode} style={{ cursor: 'pointer' }}>
          {t('WHITEPAPER.ECONOMY.contributions')}
        </Paragraph>
      </div>
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ ...modal, isOpen: false, type: null, content: undefined })}
      />
    </div>
  );
};

export default Economy;
