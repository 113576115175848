import React from 'react';
import { withRouter } from 'react-router-dom';
import { Translation } from 'react-i18next';
// import SearchButton from '../../assets/faq/search_outlined_icon.svg';
import FaqItem from '../../components/faq/faq-items/faq-items';
import { Questions } from '../../components/faq/faq-childrens-questions/questions';
import getStatPublic from '../../utils/getStatsPublic';
import i18n from '../../i18n/i18n';

import './faq.css';
import ContactUs from '../../components/conntact-us/contact-us';
import {
  // DARK_OFFICIAL_BLUE,
  FEDERAL_BLUE,
  WHITE_MAIN,
} from '../../utils/color-palatte';

class Faq extends React.Component {
  state = {
    maintenanceTaxes: '',
    presidentSalaryPercentage: '',
    vicepresidentSalaryPercentage: '',
    minVotingAssistance: '',
    refresh: false,
  };

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    if (this._isMounted) this.getStats();
  };

  getStats = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true }, async () => {
        try {
          const maintenanceTaxes = await getStatPublic('maintenance-taxes');
          const citizenGainFromDeal = await getStatPublic('citizen-gain-from-deal');
          const presidentSalaryPercentage = await getStatPublic('president-salary-porcentage');
          const vicepresidentSalaryPercentage = await getStatPublic('vicepresident-salary-porcentage');
          const minVotingAssistance = await getStatPublic('min-voting-assistance');
          const contributionEveryPaymentTime = await getStatPublic('contribution-every-payment-time');
          const timeToApproveDealDays = await getStatPublic('time-to-approve-deal-days');
          const contributionAmount = await getStatPublic('contribution-amount');
          const subscriptionBillingTaxes = await getStatPublic('subscription-billing-taxes');
          const subscriptionBillingAmount = await getStatPublic('subscription-billing-amount');
          // console.log("officialVotings: ", officialVotings);
          this.setState({
            loading: false,
            refresh: false,
            maintenanceTaxes,
            citizenGainFromDeal,
            presidentSalaryPercentage,
            vicepresidentSalaryPercentage,
            minVotingAssistance,
            contributionEveryPaymentTime: !isNaN(contributionEveryPaymentTime) ? Number(contributionEveryPaymentTime) + 1 : null,
            timeToApproveDealDays,
            contributionAmount,
            subscriptionBillingTaxes,
            subscriptionBillingAmount,
          });
          resolve();
        } catch (e) {
          reject(e);
          // console.log('getStats / error: ', e);
        }
      });
    });
  };

  render() {
    /**
     * Height (prop) is requiered to display the content inside
     * the component
     *
     * This prop was created for better animation experience for
     * the user(s)
     */

    const { location } = this.props;
    const darkMode = true;
    const {
      loading,
      maintenanceTaxes,
      presidentSalaryPercentage,
      vicepresidentSalaryPercentage,
      minVotingAssistance,
      contributionEveryPaymentTime,
      timeToApproveDealDays,
      contributionAmount,
      citizenGainFromDeal,
      subscriptionBillingTaxes,
      subscriptionBillingAmount,
    } = this.state;

    const items = [
      {
        title: i18n.t('FAQ.ITEMS.Item1.title'),
        children: <Questions.Question1 darkMode={darkMode} />,
      },
      {
        title: i18n.t('FAQ.ITEMS.Item2.title'),
        children: (
          <Questions.Question2
            darkMode={darkMode}
            citizenGainFromDeal={citizenGainFromDeal}
            maintenanceTaxes={maintenanceTaxes}
            presidentSalaryPercentage={presidentSalaryPercentage}
            vicepresidentSalaryPercentage={vicepresidentSalaryPercentage}
          />
        ),
      },
      {
        title: i18n.t('FAQ.ITEMS.Item3.title'),
        children: (
          <Questions.Question3
            darkMode={darkMode}
            minVotingAssistance={minVotingAssistance}
          />
        ),
      },
      {
        title: i18n.t('FAQ.ITEMS.Item4.title'),
        children: (
          <Questions.Question4
            timeToApproveDealDays={timeToApproveDealDays}
            darkMode={darkMode}
          />
        ),
      },
      {
        title: i18n.t('FAQ.ITEMS.Item5.title'),
        children: <Questions.Question5 darkMode={darkMode} />,
      },
      {
        title: i18n.t('FAQ.ITEMS.Item6.title'),
        children: (
          <Questions.Question6
            darkMode={darkMode}
            PSalary={loading ? '...' : presidentSalaryPercentage ? Number(presidentSalaryPercentage).toFixed(2) : '...'}
            VPSalary={loading ? '...' : vicepresidentSalaryPercentage ? Number(vicepresidentSalaryPercentage).toFixed(2) : '...'}
          />
        ),
      },
      {
        title: i18n.t('FAQ.ITEMS.Item7.title'),
        children: <Questions.Question7 darkMode={darkMode} />,
      },
      {
        title: i18n.t('FAQ.ITEMS.Item8.title'),
        children: (
          <Questions.Question8
            darkMode={darkMode}
            maintenance={maintenanceTaxes}
            citizenGainDeal={citizenGainFromDeal}
            PSalary={presidentSalaryPercentage}
            VPSalary={ vicepresidentSalaryPercentage}
          />
        ),
      },
      {
        title: i18n.t('FAQ.ITEMS.Item9.title'),
        children: (
          <Questions.Question9
            darkMode={darkMode}
            contributionAmount={contributionAmount ? Number(contributionAmount) : '...'}
            contributionEveryPaymentTime={contributionEveryPaymentTime ? Number(contributionEveryPaymentTime) : '...'}
          />
        ),
      },
      {
        title: i18n.t('FAQ.ITEMS.Item10.title'),
        children: (
          <Questions.Question10
            darkMode={darkMode}
            maintenanceTaxes={maintenanceTaxes}
            PSalary={presidentSalaryPercentage}
            VPSalary={vicepresidentSalaryPercentage}
            contributionAmount={contributionAmount}
            subscriptionBillingTaxes={subscriptionBillingTaxes}
            subscriptionBillingAmount={subscriptionBillingAmount}
            citizenGainFromDeal={citizenGainFromDeal}
          />
        ),
      },
      {
        title: i18n.t('FAQ.ITEMS.contactUs'),
        children: <ContactUs />,
        Height: '850px',
        hash: 'contact-us',
        isOpen: location.hash === '#contact-us' ? true : false,
      },
    ];

    // const Items = user
    //   ? [
    //       ...items,
    //       {
    //         title: i18n.t('FAQ.ITEMS.contactUs'),
    //         children: <ContactUs />,
    //         Height: '850px',
    //         hash: 'contact-us',
    //         isOpen: location.hash === '#contact-us' ? true : false,
    //       },
    //     ]
    //   : items;

    return (
      <Translation>
        {(t) => (
          <>
            <div className='Faq__Banner'>
              {/* <h1 className='Faq__Banner-title'>
                <Trans i18nKey='FAQ.Title'>
                  bla <span>bla </span> bla
                  <br />
                  bla <span>bla...</span>
                </Trans>
              </h1> */}
              {/* <div className='Faq__SeachBox'>
                <button type='submit'>
                  <img src={SearchButton} alt='searchBtn' />
                </button>
                <input disabled type='text' placeholder={t('FAQ.input')} />
              </div> */}
              {/* <p>{t('FAQ.subTitle')}</p> */}
              <div className='Faq__Questions'>
                <div className='Faq__Header'>
                  <h1 style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE }}>FAQ</h1>
                </div>
                {items.map((item, i) => (
                  <FaqItem {...item} darkMode={darkMode} index={i} />
                ))}
              </div>
            </div>
          </>
        )}
      </Translation>
    );
  }
}

export default withRouter(Faq);
