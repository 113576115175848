import React from 'react';
import { FEDERAL_BLUE_SOFT, FEDERAL_RED } from '../color-palatte';
import './input.css';

const Input = (props) => {
  const {
    placeholder,
    value,
    onChange,
    name,
    type,
    disabled,
    showErrors,
    onKeyPress,
    inputImg,
    className,
    style,
    borderColor,
    darkMode,
  } = props;
  return (
    <div className='GeneralInput__Container'>
      <input
        style={Object.assign(
          { border: `1px solid ${showErrors ? FEDERAL_RED : borderColor || FEDERAL_BLUE_SOFT }` },
          style
        )}
        disabled={disabled}
        placeholder={placeholder}
        type={type || 'text'}
        value={value}
        onChange={onChange}
        name={name}
        onKeyPress={onKeyPress}
        className={`${darkMode ? 'GeneralInputDark' : 'GeneralInput'} ${className}`}
      />
      <img src={inputImg} alt='' />
    </div>
  );
};

export default Input;
