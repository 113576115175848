import React from 'react';
import './serviceItem.css';
import { UserProfile } from '../ornament-profile/profileUtils';

class ServiceItem extends React.Component {
  render() {
    const {
      title, img, content, name, status
    } = this.props
    return (
      <div className='Service__Content'>
        <div className='Service__Header'>
          <div className='Service__Icon'>
            <img className='Service__Img' src={img} alt='' />
          </div>
          <div className='Service__Text'>
            <h1>{title}</h1>
            <p>
              {content}
            </p>
          </div>
        </div>
        <div className='Service__Foot'>
          <div className='Service__User'>
            <UserProfile
              containerStyle={{
                width: '60px',
                height: '60px',
              }}
            />
          </div>
          <div className='Service__UserInf'>
            <p>From:</p>
            <p>{name}</p>
            <p>{status}</p>
          </div>
          <button className='Service__Btn'>Deal</button>
        </div>
        <button className='Service__Btn2'>Use service</button>
      </div>
    );
  }
}

export default ServiceItem;
