import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styles from '../utils/styles.module.css';
import { Title, ParsedParagraph, Divider, Banner, SubTitle, ContentTitle, Paragraph, ListElement } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import RoadMapBanner from '../../../assets/whitepaper/roadmap.svg';
import * as colors from '../../../utils/color-palatte';
import Alert from '../../../utils/alert/Alert';
import moment from 'moment';
import CryptoIcon from '../../../assets/whitepaper/icon_cripto_currencies.svg';
import CryproLightIcon from '../../../assets/whitepaper/icon_cripto_currencies_light.svg';
import LaunchIcon from '../../../assets/whitepaper/icon_launch.svg';
import LaunchLightIcon from '../../../assets/whitepaper/icon_launch_light.svg';
import LiveIcon from '../../../assets/whitepaper/icon_live_auction.svg';
import LiveLightIcon from '../../../assets/whitepaper/icon_live_auction_light.svg';
import OpenSourceIcon from '../../../assets/whitepaper/icon_open_source.svg';
import OpenSourceLightIcon from '../../../assets/whitepaper/icon_open_source_light.svg';

const RoadMap = (props) => {
  const { darkMode, outer } = props;
  const { t, i18n } = useTranslation();
  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 1000px)').matches);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 1000px)').addEventListener('change', handler);
    return () => window.matchMedia('(max-width: 1000px)').removeEventListener('change', handler);
  }, []);

  const copyUrl = (hash) => {
    if (hash) {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}#${hash}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    } else {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    }
  };

  const sendToHash = (hash) => {
    if (hash) {
      history.push(`${location.pathname}#${hash}`);
    } else {
      history.push(location.pathname);
    }
  };

  const showAlert = (isOpen, type, content) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  const dateString = i18n.language === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY';

  const EventMapping = [
    {
      img: darkMode ? LaunchLightIcon : LaunchIcon,
      date: t('WHITEPAPER.ROADMAP.EVENT1.date'),
      title: t('WHITEPAPER.ROADMAP.EVENT1.title'),
      hash: String(moment(t('WHITEPAPER.ROADMAP.EVENT1.date'), dateString).format('MM-DD-YYYY')),
    },
    {
      img: darkMode ? CryproLightIcon : CryptoIcon,
      date: t('WHITEPAPER.ROADMAP.EVENT2.date'),
      title: t('WHITEPAPER.ROADMAP.EVENT2.title'),
      hash: String(moment(t('WHITEPAPER.ROADMAP.EVENT2.date'), dateString).format('MM-DD-YYYY')),
      content: t('WHITEPAPER.ROADMAP.EVENT2.content'),
    },
    {
      img: darkMode ? LiveLightIcon : LiveIcon,
      date: t('WHITEPAPER.ROADMAP.EVENT3.date'),
      title: t('WHITEPAPER.ROADMAP.EVENT3.title'),
      hash: String(moment(t('WHITEPAPER.ROADMAP.EVENT3.date'), dateString).format('MM-DD-YYYY')),
      content: t('WHITEPAPER.ROADMAP.EVENT3.content'),
    },
    {
      img: darkMode ? OpenSourceLightIcon : OpenSourceIcon,
      date: t('WHITEPAPER.ROADMAP.EVENT4.date'),
      title: t('WHITEPAPER.ROADMAP.EVENT4.title'),
      hash: String(moment(t('WHITEPAPER.ROADMAP.EVENT4.date'), dateString).format('MM-DD-YYYY')),
      content: t('WHITEPAPER.ROADMAP.EVENT4.content'),
    },
  ];

  return (
    <div className={styles.Container}>
      <div
        style={{
          width: matches ? '100%' : 'calc(100% - 300px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: outer ? 0 : '70px',
        }}
      >
        <div className={styles.Content}>
          <Title text={t('WHITEPAPER.ROADMAP.title')} darkMode={darkMode} onClick={() => copyUrl()} />
          <Divider darkMode={darkMode} />
          <Banner src={RoadMapBanner} />
          <Divider darkMode={darkMode} />
          {EventMapping.map((event, index) => (
            <ListElement
              key={index}
              img={event.img}
              style={{
                marginBottom: 20
              }}
              imgStyles={{ width: '60px', height: '60px', marginTop: 10, marginRight: 30 }}
              content={
                <>
                  <SubTitle
                    style={{ marginTop: 0 }}
                    text={event.date}
                    darkMode={darkMode}
                    color={darkMode ? colors.FEDERAL_BLUE_SOFT : colors.FEDERAL_BLUE}
                    hash={event.hash}
                    onClick={() => copyUrl(event.hash)}
                    location={location}
                    checked={moment(event.date, dateString) <= moment(Date.now())}
                  />
                  <Paragraph darkMode={darkMode} text={event.title} style={{ fontWeight: 'bold' }} />
                  {event?.content && <ParsedParagraph string={event.content} hasWiki darkMode={darkMode} />}
                </>
              }
            />
          ))}
        </div>
      </div>
      <div className={styles.Wrapper} style={{ display: matches ? 'none' : 'flex', paddingTop: outer ? 0 : '70px' }}>
        <ContentTitle text={t('WHITEPAPER.content')} darkMode={darkMode} />
        <Paragraph onClick={() => sendToHash()} darkMode={darkMode} style={{ margin: 0, cursor: 'pointer' }}>
          {t('WHITEPAPER.ROADMAP.title')}
        </Paragraph>
        <Paragraph
          onClick={() => sendToHash(String(moment(t('WHITEPAPER.ROADMAP.EVENT1.date'), dateString).format('MM-DD-YYYY')))}
          darkMode={darkMode}
          style={{ marginBottom: 0, cursor: 'pointer' }}
        >
          {t('WHITEPAPER.ROADMAP.EVENT1.date')}
        </Paragraph>
        <Paragraph
          onClick={() => sendToHash(String(moment(t('WHITEPAPER.ROADMAP.EVENT2.date'), dateString).format('MM-DD-YYYY')))}
          darkMode={darkMode}
          style={{ marginBottom: 0, cursor: 'pointer' }}
        >
          {t('WHITEPAPER.ROADMAP.EVENT2.date')}
        </Paragraph>
        <Paragraph
          onClick={() => sendToHash(String(moment(t('WHITEPAPER.ROADMAP.EVENT3.date'), dateString).format('MM-DD-YYYY')))}
          darkMode={darkMode}
          style={{ marginBottom: 0, cursor: 'pointer' }}
        >
          {t('WHITEPAPER.ROADMAP.EVENT3.date')}
        </Paragraph>
        <Paragraph
          onClick={() => sendToHash(String(moment(t('WHITEPAPER.ROADMAP.EVENT4.date'), dateString).format('MM-DD-YYYY')))}
          darkMode={darkMode}
          style={{ cursor: 'pointer' }}
        >
          {t('WHITEPAPER.ROADMAP.EVENT4.date')}
        </Paragraph>
      </div>
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ ...modal, isOpen: false, type: null, content: undefined })}
      />
    </div>
  );
};

export default RoadMap;
