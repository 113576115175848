import React, { useState, useEffect } from 'react';
import styles from './Layout.module.css';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as colors from '../../../utils/color-palatte';

const Layout = (props) => {
  const { children, darkMode, outer } = props;

  const [matches, setMatches] = useState(window.matchMedia('(max-width: 800px)').matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 800px)').addEventListener('change', handler);
  })

  return (
    <div className={styles.WhitePaper__Container} style={{backgroundColor: darkMode ? colors.DARK_OFFICIAL_BLUE : colors.WHITE_MAIN}} >
      <div
        className={styles.Menu__Container}
        style={{ backgroundColor: darkMode ? colors.UNSATURATED_BLACK_BLUE : colors.SERVICES_BACKGROUND_WHITE, display: matches ? 'none' : 'flex'}}
      >
        <WhitepaperMenu darkMode={darkMode} outer={outer} />
      </div>
      <div className={styles.WhitePaper__Content} style={{ width: matches ? '100%' : 'calc(100% - 300px)'}}>{children}</div>
    </div>
  );
};

const MenuItm = (props) => {
  const { navigate, darkMode, text, selected } = props;
  const history = useHistory();
  return (
    <div
      style={{
        backgroundColor: selected ? (darkMode ? '#fff3' : colors.WHITE_MAIN) : '#0000',
        border: `2px solid ${darkMode ? '#567' : colors.WHITE_DARK}`,
      }}
      onClick={navigate ? () => history.push(navigate) : void 0}
      className={styles.Menu__Item}
    >
      <p
        style={{
          color: darkMode
            ? selected
              ? colors.FEDERAL_BLUE_SOFT
              : colors.FEDERAL_WHITE
            : selected
            ? colors.FEDERAL_BLUE
            : colors.GRAY_MAIN,
          margin: 0,
        }}
      >
        {text}
      </p>
    </div>
  );
};

export const WhitepaperMenu = (props) => {
  const { darkMode, outer, mobile, isOpen, onRequestClose, uri } = props;
  const { url } = useRouteMatch();
  // console.log(url);
  const location = useLocation();
  const path = location.pathname;

  const { t } = useTranslation();

  const menuArr = [
    {
      selected: path === `${mobile ? uri : url}` || path === `${mobile ? uri : url}/whats-global-government`,
      text: t('WHITEPAPER.WHATS_GG.title'),
      navigate: `${mobile ? uri : url}/whats-global-government`,
    },
    {
      selected: path === `${mobile ? uri : url}/economy` || path === `${mobile ? uri : url}/economy/`,
      text: t('WHITEPAPER.ECONOMY.title'),
      navigate: `${mobile ? uri : url}/economy`,
    },
    {
      selected: path === `${mobile ? uri : url}/social-experiment`,
      text: t('WHITEPAPER.SOCIAL_EXPERIMENT.title'),
      navigate: `${mobile ? uri : url}/social-experiment`,
    },
    {
      selected: path === `${mobile ? uri : url}/deals`,
      text: t('WHITEPAPER.DEALS.title'),
      navigate: `${mobile ? uri : url}/deals`,
    },
    {
      selected: path === `${mobile ? uri : url}/laws`,
      text: t('WHITEPAPER.LAWS.title'),
      navigate: `${mobile ? uri : url}/laws`,
    },
    {
      selected: path === `${mobile ? uri : url}/positions`,
      text: t('WHITEPAPER.POSITIONS.title'),
      navigate: `${mobile ? uri : url}/positions`,
    },
    {
      selected: path === `${mobile ? uri : url}/roadmap`,
      text: t('WHITEPAPER.ROADMAP.title'),
      navigate: `${mobile ? uri : url}/roadmap`,
    },
  ];

  if (mobile) {
    return (
      <div
        className={styles.SideMenu__Overlay}
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100vh',
          background: isOpen ? '#0008' : '#0000',
          pointerEvents: isOpen ? 'unset' : 'none',
          transition: 'all .3s',
        }}
        onClick={onRequestClose}
      >
        <div
          className={styles.SideMenu__Mobile__Container}
          style={{
            transition: 'all .3s',
            backgroundColor: darkMode ? colors.UNSATURATED_BLACK_BLUE : colors.SERVICES_BACKGROUND_WHITE,
            marginLeft: isOpen ? '0' : '-300px',
          }}
        >
          <h1
            style={{
              fontSize: '1.6rem',
              color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.GRAY_MAIN,
              width: '100%',
              textAlign: 'left',
            }}
          >
            Global Government
          </h1>
          {menuArr.map((item, index) => (
            <MenuItm {...item} key={index} darkMode={darkMode} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.SideMenu__Container}>
      <h1
        style={{
          fontSize: '1.8rem',
          color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.GRAY_MAIN,
          width: '100%',
          textAlign: 'left',
          paddingTop: outer ? 0 : '70px',
        }}
      >
        Global Government
      </h1>
      {menuArr.map((item, index) => (
        <MenuItm {...item} key={index} darkMode={darkMode} />
      ))}
    </div>
  );
};

export default Layout;
