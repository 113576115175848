import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/footer';
import MiniFooter from '../mini-footer/mini-footer';
import { connect } from 'react-redux';
import NavBar from '../navbar/navbar';

const Layout = ({ user, children, style, userHasEntered }) => {
  if (user && userHasEntered) {
    return (
      <div className='Layout__Container' style={style}>
        <Header />
        <NavBar>
          {children}
        </NavBar>
      </div>
    );
  }
  return (
    <div className='Layout__Container' style={style}>
      <Header />
      {children}
      <Footer />
      <MiniFooter />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, userHasEntered } = state.user;
  return {
    user,
    userHasEntered
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
