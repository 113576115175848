import React from 'react';
import i18n from '../../../i18n/i18n';
import { AppLoader } from '../../../utils/animations/animations';
import {
  FEDERAL_BLUE,
  UNSATURATED_BLACK_BLUE,
  GRAY_SECOND_TRANSPARENCY_FULL,
  WHITE_MAIN,
} from '../../../utils/color-palatte';
import CloseIcon from '../../../assets/terms-modal/close-btn.svg';
import CloseIconWhite from '../../../assets/terms-modal/close-btn-white.svg';
import PassIcon from '../../../assets/edit-profile/change_pass_icon.svg';
import PassIconWhite from '../../../assets/edit-profile/change_pass_icon_white.svg';
import AlertModal from '../../../utils/modal/Modal';
import { changePassword } from './editUserActions';
import PasswordInput from '../../../utils/app-inputs/password-input/password-input';
import { EditProfileButton } from './editProfile';
import './changePasswordModal.css';
import { Translation } from 'react-i18next';
import Alert from '../../../utils/alert/Alert';

class ChangePasswordModal extends React.Component {
  state = {
    oldPassword: '',
    newPassword: '',
    newPasswordAgain: '',
    modalLoader: false,
  };

  componentDidMount = () => {
    window.addEventListener('keydown', e => this.handleKeyDown(e));
  };

  componentWillUnmount = () => {
    window.removeEventListener('keydown', e => this.handleKeyDown(e));
  };

  changePassword = () => {
    const { oldPassword, newPassword, newPasswordAgain } = this.state;
    if (
      oldPassword &&
      oldPassword.length >= 6 &&
      newPassword &&
      newPassword.length >= 6 &&
      newPasswordAgain &&
      newPasswordAgain.length >= 6 &&
      newPassword === newPasswordAgain
    ) {
      this.setState({ modalLoader: true }, async () => {
        try {
          const userId = localStorage.getItem('userId');
          const data = {
            userId,
            oldPassword,
            newPassword,
            newPasswordAgain,
          };
          await changePassword(data);
          this.setState({
            modalLoader: false,
            oldPassword: '',
            newPassword: '',
            newPasswordAgain: '',
          });
          this.showAlert(
            true,
            'success',
            i18n.t('CHANGE_PASS_MODAL.passChanged')
          );
        } catch (e) {
          this.setState({ modalLoader: false });
          const error =
            e?.data?.error?.message ??
            e?.data?.error ??
            i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
        }
      });
    } else {
      if (!oldPassword) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASS_MODAL.ERRORS.missingOldPassword')
        );
        return false;
      }
      if (oldPassword && oldPassword.length < 6) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASS_MODAL.ERRORS.invalidOldPassword')
        );
        return false;
      }
      if (!newPassword) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASS_MODAL.ERRORS.missingNewPassword')
        );
        return false;
      }
      if (newPassword && newPassword.length < 6) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASS_MODAL.ERRORS.invalidNewPassword')
        );
        return false;
      }
      if (!newPasswordAgain) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASS_MODAL.ERRORS.missingNewAgainPassword')
        );
        return false;
      }
      if (newPasswordAgain && newPasswordAgain.length < 6) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASS_MODAL.ERRORS.invalidNewAgainPassword')
        );
        return false;
      }
      if (newPassword !== newPasswordAgain) {
        this.showAlert(
          true,
          'warning',
          i18n.t('CHANGE_PASS_MODAL.ERRORS.diferentPasswords')
        );
        return false;
      }
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  handleKeyDown = (e) => {
    const { oldPassword, newPassword, newPasswordAgain } = this.state;
    if (e.key === 'Enter') {
      if (!oldPassword && !newPassword && !newPasswordAgain) return;
      this.changePassword();
    } if (
      e.key === 'Escape' || e.key === 'Esc'
    ) {
      this.props.onRequestClose();
    }
  }

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const { oldPassword, newPassword, newPasswordAgain, modalLoader } =
      this.state;

    const { darkMode, isOpen, onRequestClose } = this.props;

    return (
      <Translation>
        {(t) => (
          <AlertModal
            isOpen={isOpen}
            outsideClick={modalLoader ? null : onRequestClose}
            overlayClassName='ChangePasswordModal__Overlay'
            modalClassName='ChangePasswordModal__Container'
            style={{
              backgroundColor: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
              justifyContent: modalLoader ? 'center' : 'flex-start',
            }}
          >
            {modalLoader ? (
              <AppLoader size={60} color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
            ) : (
              <>
                <img
                  src={darkMode ? CloseIconWhite : CloseIcon}
                  alt='close'
                  className='ChangePasswordModal__CloseBtn'
                  onClick={onRequestClose}
                />
                <h1
                  className='ChangePasswordModal__Title'
                  style={{
                    color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                    borderBottom: `1px solid ${
                      darkMode ? '#FFF5' : GRAY_SECOND_TRANSPARENCY_FULL
                    }`,
                  }}
                >
                  {t('CHANGE_PASS_MODAL.title')}
                </h1>
                <img
                  src={darkMode ? PassIconWhite : PassIcon}
                  alt=''
                  className='ChangePasswordModal__Icon'
                  draggable='false'
                />
                <div className='ChangePasswordModal__Form'>
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      margin: '0',
                    }}
                  >
                    {t('CHANGE_PASS_MODAL.oldPass')}
                  </p>
                  <PasswordInput
                    name='oldPassword'
                    value={oldPassword}
                    onChange={this.handleInputChange}
                    isWhite={darkMode}
                  />
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      margin: '0',
                    }}
                  >
                    {t('CHANGE_PASS_MODAL.newPass')}
                  </p>
                  <PasswordInput
                    name='newPassword'
                    value={newPassword}
                    onChange={this.handleInputChange}
                    isWhite={darkMode}
                  />
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      margin: '0',
                    }}
                  >
                    {t('CHANGE_PASS_MODAL.newPassAgain')}
                  </p>
                  <PasswordInput
                    name='newPasswordAgain'
                    value={newPasswordAgain}
                    onChange={this.handleInputChange}
                    isWhite={darkMode}
                  />
                  <EditProfileButton
                    darkMode={darkMode}
                    text={t('CHANGE_PASS_MODAL.updatePassword')}
                    style={{ marginTop: '20px', marginBottom: '10px' }}
                    onClick={() => this.changePassword()}
                  />
                </div>
              </>
            )}
            <Alert
              isOpen={this.state.isAlertOpen}
              type={this.state.alertType}
              content={this.state.alertContent}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
            />
          </AlertModal>
        )}
      </Translation>
    );
  }
}

export default ChangePasswordModal;
