import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import './postcard.css';
// import PresidentIcon from '../../assets/post/president-icon.svg';
// import VicepresidentIcon from '../../assets/post/vicepresident-icon.svg';
import OptionsIcon from '../../assets/post/button_options.svg';
import LikeIcon from '../../assets/post/like_icon.svg';
import LikeIconWhite from '../../assets/post/like_icon_white.svg';
import LikeFillIcon from '../../assets/post/like_fill_icon.svg';
import LikeFillIconWhite from '../../assets/post/like_fill_icon_white.svg';
import UnlikeIcon from '../../assets/post/dislike_icon.svg';
import UnlikeIconWhite from '../../assets/post/dislike_icon_white.svg';
import UnlikeFillIcon from '../../assets/post/dislike_fill_icon.svg';
import UnlikeFillIconWhite from '../../assets/post/dislike_fill_icon_white.svg';
import CommentsIcon from '../../assets/post/icon_comments_filled.svg';
import CommentsIconWhite from '../../assets/post/icon_comments_filled_white.svg';
import PresidentImg from '../../assets/ornament-profile/default_president.png';
import VicepresidentImg from '../../assets/ornament-profile/default_vice-president.png';
import CitizenImg from '../../assets/ornament-profile/default_citizen.png';
import { BigText, ParsedText, ReadMoreLinesTxt, ReadMoreTxt, TranslatableText } from '../../utils/text-utils/text-utils';
import Carousel from 'react-elastic-carousel';
import { handlePostDate } from '../../utils/handlePostDate';
import thousandSeparator from '../../utils/thousandSeparator';
import {
  FEDERAL_BLUE,
  UNSATURATED_BLACK_BLUE,
  FEDERAL_GREEN,
  WHITE_MAIN,
  DARK_OFFICIAL_BLUE,
  FEDERAL_BLUE_SOFT,
  WARNING_YELLOW,
} from '../../utils/color-palatte';
import AppVideo from '../app-video/AppVideo';
import LockBtn from '../../assets/post/btn_lock.svg';
import UnlockBtn from '../../assets/post/btn_unlock.svg';
import { AppLoader } from '../../utils/animations/animations';
import { COMMENTS_ROUTE } from '../../routes/routes';
import * as routes from '../../routes/routes';
import OptionModal from '../options-modal/optionsModal';
import Alert from '../../utils/alert/Alert';
// import { CURRENT_DOMAIN } from '../../config';
import i18n from '../../i18n/i18n';
import NotLoadedResources from '../not-loaded-rss/notLoadedResources';
import ImgNotFound from '../../assets/not-loaded-rss/not_found_img.svg';
import VidNotFound from '../../assets/not-loaded-rss/not_found_vid.svg';
import VotingItem from '../voting/votingItem';
import UserRef from '../user-ref/UserRef';
import { UserName } from '../ornament-profile/profileUtils';
import CommunityItem from '../community/communityItem';
import IncomesItem from '../incomes-list/incomesItem';

const PostCarouselItem = ({ style, type, uri, showBtn, onClick, btnClassName, btnImg, title, onPlay, onPause }) => {
  const [src, setSrc] = useState(uri);
  const [errored, setErrored] = useState(false);
  const [alert, setAlert] = useState({
    isAlertOpen: false,
    alertType: null,
    alertContent: null,
  });

  const fallBackSrc =
    type.split('/')[0] === 'image' || type === 'image' || type.split('/')[0] === 'gif' || type === 'gif'
      ? ImgNotFound
      : VidNotFound;

  const onError = useCallback(() => {
    if (!errored) {
      setSrc(fallBackSrc);
      setErrored(true);
    }
  }, [fallBackSrc, errored]);

  const showAlert = (isOpen, type, content) => {
    setAlert({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  return (
    <div className='PostCard__CarouselItem' style={style}>
      {type.split('/')[0] === 'image' || type === 'image' ? (
        <img src={src} alt='bad' draggable='false' className='PostCard__Main-Image' onError={onError} />
      ) : type.split('/')[0] === 'gif' ? (
        <img src={uri} alt='bad' draggable='false' className='PostCard__Main-Image' />
      ) : (
        <>
          <AppVideo
            source={src}
            className='PostCard__Main-Video'
            onPlay={onPlay}
            onPause={onPause}
            onError={onError}
            videoStyle={{ display: errored ? 'none' : undefined }}
            style={{ display: errored ? 'none' : undefined }}
            fullScreenEnable
          />
          <img src={fallBackSrc} style={{ display: errored ? 'block' : 'none' }} className='PostCard__ReplaceImage' alt='' />
          {showBtn && (
            <button onClick={onClick} className={btnClassName} title={title}>
              <img src={btnImg} alt='' />
            </button>
          )}
        </>
      )}
      {errored && (
        <NotLoadedResources
          style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translate(-50%, 0)',
            marginTop: 30,
            cursor: 'pointer',
          }}
          type={type.split('/')[0] === 'image' || type === 'image' ? 'image' : 'video'}
          onClick={() => showAlert(true, i18n.t('NOT_LOADED_RSS.MODAL.title'), i18n.t('NOT_LOADED_RSS.MODAL.msg'))}
        />
      )}
      <Alert
        isOpen={alert.isAlertOpen}
        type={alert.alertType}
        content={alert.alertContent}
        onRequestClose={() => setAlert({ ...alert, isAlertOpen: false })}
      />
    </div>
  );
};

const PostButton = ({ icon, counts, onClick, style, imgStyle, counterStyle, darkMode }) => (
  <div
    className='Post__Button'
    onClick={onClick}
    style={Object.assign(
      {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'row',
      },
      style
    )}
  >
    <img src={icon} alt='' className='Post__Button-img' style={Object.assign({ marginRight: '5px' }, imgStyle)} />
    <p
      className='Post__Button-counter'
      style={Object.assign(
        {
          display: counts === undefined ? 'none' : undefined,
          color: darkMode ? FEDERAL_BLUE : FEDERAL_BLUE,
        },
        counterStyle
      )}
    >
      {counts}
    </p>
  </div>
);

class PostCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      likeLoading: false,
      // tabletMatches: window.matchMedia('(max-width: 600px)').matches,
      // mobileMatches: window.matchMedia('(max-width: 450px)').matches,
      tabletMatches: false,
      mobileMatches: false,
      isLocked: false,
      isVisible: false,
    };
  }

  rvlRef = React.createRef();
  observer = null;

  componentDidMount = () => {
    this.observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      if (boxElem.contentRect.width <= 600) {
        this.setState({ tabletMatches: true });
        if (boxElem.contentRect.width <= 450) {
          this.setState({ mobileMatches: true });
        } else {
          this.setState({ mobileMatches: false });
        }
      } else {
        this.setState({ tabletMatches: false });
      }
    });

    this.observer.observe(this.rvlRef.current);

    // window.matchMedia('(max-width: 650px)').addEventListener('change', (e) => this.setState({ tabletMatches: e.matches }));
    // window.matchMedia('(max-width: 450px)').addEventListener('change', (e) => this.setState({ mobileMatches: e.matches }));
  };

  componentWillUnmount = () => {
    this.observer.disconnect();
  };

  goUserProfile = (post) => {
    const userId = post.user._id || post.user;
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId === userId) {
      this.props.history.push(routes.PROFILE_ROUTE);
    } else {
      this.props.history.push(`${routes.PROFILE_ROUTE}/${userId}`);
    }
  };

  like = (postId) => {
    const { likeFunction } = this.props;
    this.setState({ likeLoading: true }, async () => {
      try {
        await likeFunction(postId);
        this.setState({ likeLoading: false });
      } catch (e) {
        this.setState({ likeLoading: false });
      }
    });
  };

  unlike = (postId) => {
    const { unlikeFunction } = this.props;
    this.setState({ likeLoading: true }, async () => {
      try {
        await unlikeFunction(postId);
        this.setState({ likeLoading: false });
      } catch (e) {
        this.setState({ likeLoading: false });
      }
    });
  };

  remove = () => {
    this.setState({ isModalOpen: true, actionsLoader: true }, async () => {
      const { item, removePost } = this.props;
      try {
        await removePost(item._id, item.user._id, item.files);
        this.setState({
          isModalOpen: false,
          actionsLoader: false,
        });
      } catch (e) {
        this.setState({ isModalOpen: false, actionsLoader: false });
      }
    });
  };

  report = () => {
    this.setState({ isModalOpen: true, actionsLoader: true }, async () => {
      const { item, reportPost } = this.props;
      try {
        await reportPost(item._id);
        this.setState({
          isModalOpen: false,
          actionsLoader: false,
        });
      } catch (e) {
        this.setState({ isModalOpen: false, actionsLoader: false });
      }
    });
  };

  onShare = (id) => {
    this.props.history.push(`${routes.MESSAGES_ROUTE}/?post=${id}`);
    this.setState({ optionsOpen: false });
  };

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  showOptions = (isOpen) => {
    this.setState({
      optionsOpen: isOpen,
    });
  };

  shareUrl = (id) => {
    this.showOptions(false);
    navigator.clipboard.writeText(`${window.location.origin}${routes.POST_ROUTE}/${id}`);
    this.showAlert(
      true,
      'success',
      'Done',
      i18n.t('DASHBOARD.shareUrl'),
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      'Ok',
      () => this.setState({ isModalOpen: false }),
      false
    );
  };

  onReportCall = () => {
    this.showOptions(false);
    this.showAlert(
      true,
      'warning',
      i18n.t('DASHBOARD.MODAL.title'),
      i18n.t('DASHBOARD.MODAL.reportPost.message'),
      true,
      () => this.setState({ isModalOpen: false }),
      'NO',
      () => this.report(),
      i18n.t('DASHBOARD.MODAL.yes'),
      undefined,
      undefined,
      false
    );
  };

  onDeleteCall = () => {
    this.showOptions(false);
    this.showAlert(
      true,
      'warning',
      i18n.t('DASHBOARD.MODAL.title'),
      i18n.t('DASHBOARD.MODAL.deletePost.message'),
      true,
      () => this.setState({ isModalOpen: false }),
      'NO',
      () => this.remove(),
      i18n.t('DASHBOARD.MODAL.yes'),
      undefined,
      undefined,
      false
    );
  };

  // handleClick = (e) => {
  //   e.stopPropagation();
  // };

  render() {
    const {
      item,
      user,
      darkMode,
      positions,
      stats,
      removeRefers,
      likeFunction,
      unlikeFunction,
      removePost,
      reportPost,
      // hideMenu,
      // userPost,
      inner,
      style,
      onClick,
      postReportsLimit,
      positionsPeriodDays,
      votingExpiresPeriodDays
    } = this.props;

    const { likeLoading, mobileMatches, tabletMatches, isLocked } = this.state;

    // console.log(this.props);

    const isPostLiked = user && item.likes ? item.likes.filter((userId) => userId === user._id).length > 0 : false;
    const isPostunLiked = user && item.unlikes ? item.unlikes.filter((userId) => userId === user._id).length > 0 : false;

    // const PresidentCrown = ({ darkMode, style }) => (
    //   <img src={darkMode ? PresidentIcon : PresidentIcon} alt='' className='PostCard__CrownIcon' style={style} />
    // );

    // const VicepresidentCrown = ({ darkMode, style }) => (
    //   <img src={darkMode ? VicepresidentIcon : VicepresidentIcon} alt='' className='PostCard__CrownIcon' style={style} />
    // );

    const PostMenuIcon = ({ onClick, darkMode, style, className }) => (
      <img
        src={darkMode ? OptionsIcon : OptionsIcon}
        alt=''
        className={className}
        style={Object.assign({ style })}
        onClick={onClick}
      />
    );

    const postUserId = item.user && item.user._id ? item.user._id : '';

    const position =
      positions && positions.filter((position) => position.user._id === postUserId).length > 0
        ? positions.filter((position) => position.user._id === postUserId)[0].position
        : null;

    // console.log('Position:', position);
    // console.log('Positions:', positions);

    // console.log('postUserId:', postUserId);

    const UserImage = ({ uri, position, onClick }) => {
      const [src, setSrc] = useState(uri);
      const [errored, setErrored] = useState(false);

      const positionIcon = position === 'president' ? PresidentImg : position === 'vicepresident' ? VicepresidentImg : CitizenImg;

      const onError = useCallback(() => {
        if (!errored) {
          setSrc(positionIcon);
          setErrored(true);
        }
      }, [positionIcon, errored]);

      return <img onClick={onClick} src={src || positionIcon} onError={onError} alt='' className='PostCard__UserImg' />;
    };

    return (
      <div
        ref={this.rvlRef}
        className='PostCard__Container'
        onClick={onClick}
        style={Object.assign(
          {
            border: darkMode ? `1px solid ${UNSATURATED_BLACK_BLUE}` : inner ? '1.5px solid #888' : 'none',
            // width: inner && 'calc(100% + 40px)',
            // marginLeft: inner && '-20px',
            padding: inner && '20px',
            backgroundColor: darkMode ? DARK_OFFICIAL_BLUE : WHITE_MAIN,
            boxShadow: inner ? 'none' : `1px 1px 5px 0px ${darkMode ? '#5586' : '#0006'}`,
          },
          style
        )}
      >
        <div className='PostCard__Header'>
          <div className='PostCard__User-Image-container'>
            <UserImage onClick={() => (inner ? null : this.goUserProfile(item))} uri={item?.user?.picture} position={position} />
          </div>
          <div className='PostCard__Title'>
            <div className='PostCard__User-title'>
              <div
                className='PostCard__Ornament-name'
                onClick={() => (inner ? null : this.goUserProfile(item))}
                style={{ cursor: !inner && 'pointer' }}
              >
                {/* {position && position === 'president' ? (
                  <PresidentCrown
                    style={{
                      marginRight: mobileMatches ? '5px' : '10px',
                      width: mobileMatches ? '20px' : undefined,
                    }}
                  />
                ) : null}
                {position && position === 'vicepresident' ? (
                  <VicepresidentCrown
                    style={{
                      marginRight: mobileMatches ? '5px' : '10px',
                      width: mobileMatches ? '20px' : undefined,
                    }}
                  />
                ) : null}
                <h1
                  className='PostCard__Username'
                  style={{ color: darkMode && WHITE_MAIN }}
                >
                  {item?.user?.username ?? item?.userUsername ?? ''}
                </h1> */}
                <UserName
                  darkMode={darkMode}
                  username={item?.user?.username ?? item.userUsername ?? ''}
                  position={position}
                  isVerified={item?.user?.isVerifiedUser ?? false}
                  titleStyles={{
                    color: darkMode ? WHITE_MAIN : '#000',
                    fontSize: mobileMatches ? '1rem' : tabletMatches ? '1.3rem' : '1.8rem',
                    cursor: 'pointer',
                  }}
                />
              </div>
              {removePost && reportPost && !inner ? (
                <PostMenuIcon className='PostCard__options-btn' onClick={() => this.showOptions(true)} />
              ) : null}
            </div>
            <p className='PostCard__User-PostDate' style={{ color: darkMode && FEDERAL_GREEN }}>
              {handlePostDate(item?.creationDate)}
            </p>
          </div>
        </div>
        {item.title ? (
          <div className='PostCard__Title-Container'>
            {item.title.length > 300 ? (
              <ReadMoreTxt
                className='PostCard__Title-text'
                text={item.title}
                style={{ color: darkMode && WHITE_MAIN }}
                darkMode={darkMode}
              />
            ) : item.title.split(/\r\n|\r|\n/).length > 2 ? (
              <ReadMoreLinesTxt
                className='PostCard__Title-text'
                text={item.title}
                style={{ color: darkMode && WHITE_MAIN }}
                darkMode={darkMode}
              />
            ) : item.title && item.files.length === 0 ? (
              <BigText
                style={{
                  fontSize: mobileMatches ? '1.3em' : '1.6em',
                  color: darkMode && WHITE_MAIN,
                }}
                className='PostCard__Title-text'
                text={item.title}
                darkMode={darkMode}
              />
            ) : (
              <>
                <p
                  className='PostCard__Title-text'
                  style={{
                    color: darkMode && WHITE_MAIN,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {item.title}
                </p>
                <TranslatableText text={item.title} style={{ color: darkMode ? WHITE_MAIN : '#000' }} />
              </>
            )}
          </div>
        ) : null}
        <div
          className='PostCard__Content'
        // style={files?.length === 0 ? { display: 'none' } : undefined}
        >
          {!removeRefers && item.post ? (
            item.post && positions && !item.post.block ? (
              <div style={{ width: '100%' }}>
                <PostCard
                  item={item.post}
                  inner
                  removeRefers
                  positions={positions}
                  darkMode={darkMode}
                  user={user}
                  onClick={() =>
                    this.props.history.push(`${routes.POST_ROUTE}/${item.post && item.post._id ? item.post._id : item.post}`)
                  }
                />
              </div>
            ) : (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.postNotFound')}
              </p>
            )
          ) : null}
          {!removeRefers && item.deal ? (
            item.deal && positions && !item.deal.block ? (
              <div style={{ width: '100%' }}>
                <VotingItem
                  item={item.deal}
                  inner
                  removeRefers
                  positions={positions}
                  darkMode={darkMode}
                  user={user}
                  hideMenu
                  isDeal
                  isDone
                  onClick={() =>
                    this.props.history.push(
                      `${routes.DEALS_DETAILS_ROUTE}/${item.deal && item.deal._id ? item.deal._id : item.deal}`
                    )
                  }
                />
              </div>
            ) : (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.dealNotFound')}
              </p>
            )
          ) : null}
          {!removeRefers && item.law ? (
            item.law && positions && !item.law.block ? (
              <div style={{ width: '100%' }}>
                <VotingItem
                  item={item.law}
                  inner
                  removeRefers
                  positions={positions}
                  darkMode={darkMode}
                  user={user}
                  hideMenu
                  isLaw
                  isDone
                  onClick={() =>
                    this.props.history.push(`${routes.LAWS_DETAILS_ROUTE}/${item.law && item.law._id ? item.law._id : item.law}`)
                  }
                />
              </div>
            ) : (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.lawNotFound')}
              </p>
            )
          ) : null}
          {!removeRefers && (item.voting || item.voting === null) ? (
            item.voting && positions && !item.voting.block ? (
              <div style={{ width: '100%' }}>
                <VotingItem
                  item={item.voting}
                  inner
                  removeRefers
                  positions={positions}
                  darkMode={darkMode}
                  user={user}
                  hideMenu
                  onClick={() =>
                    this.props.history.push(
                      `${routes.VOTING_DETAILS_ROUTE}/${item.voting && item.voting._id ? item.voting._id : item.voting}`
                    )
                  }
                  positionsPeriodDays={positionsPeriodDays}
                  votingExpiresPeriodDays={votingExpiresPeriodDays}
                />
              </div>
            ) : (
              item.votingHistory && item.votingHistory._id && !item.votingHistory.block
                ? <VotingItem
                  darkMode={darkMode}
                  isHistory
                  user={user}
                  postId={item._id}
                  item={item.votingHistory}
                  hideMenu
                  voting={stats}
                  positionsPeriodDays={positionsPeriodDays}
                  votingExpiresPeriodDays={votingExpiresPeriodDays}
                />
                : <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                  {i18n.t('CREATE_POST_MODAL.votingIsover')}
                </p>
            )
          ) : null}
          {item?.votingHistory && item.voting !== null && !item.voting ? (
            item.votingHistory && stats?.users && stats?.positions && !item.votingHistory.block ? (
              <VotingItem
                darkMode={darkMode}
                isHistory
                user={user}
                postId={item._id}
                item={item.votingHistory}
                hideMenu
                voting={stats}
                positionsPeriodDays={positionsPeriodDays}
                votingExpiresPeriodDays={votingExpiresPeriodDays}
              />
            ) : (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.votingIsover')}
              </p>
            )
          ) : null}
          {!removeRefers && item?.income ? (
            item.income?._id ? (
              <IncomesItem
                item={item.income}
                onClick={() => this.props.history.push(`${routes.GG_INCOMES_DETAILS_ROUTE}/${item.income._id}`)}
                darkMode={darkMode}
                hideMenu
                user={user}
              />
            ) : (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.incomeNotFound')}
              </p>
            )
          ) : null}
          {!removeRefers && item?.expense ? (
            item.expense?._id ? (
              <IncomesItem
                isExpense
                item={item.expense}
                onClick={() => this.props.history.push(`${routes.GG_EXPENSES_DETAILS_ROUTE}/${item.expense._id}`)}
                darkMode={darkMode}
                hideMenu
                user={user}
              />
            ) : (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.expenseNotFound')}
              </p>
            )
          ) : null}
          {!removeRefers && item?.community ? (
            item.community?._id ? (
              <CommunityItem
                item={item.community}
                onClick={() => this.props.history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${item.community._id}`)}
                inner
                user={user}
              />
            ) : (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.communityNotFound')}
              </p>
            )
          ) : null}
          {!removeRefers && item?.userRef ? (
            item.userRef && positions && !item.userRef.block ? (
              <div style={{ width: '100%' }}>
                <UserRef item={item.userRef} darkMode={darkMode} positions={positions} />
              </div>
            ) : (
              <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                {i18n.t('CREATE_POST_MODAL.userNotFound')}
              </p>
            )
          ) : null}
          {item.files && item.files.length > 0 && (
            <Carousel
              className={darkMode ? 'PostCard__Carousel-dark' : 'PostCard__Carousel'}
              showArrows={false}
              pagination={item.files && item.files.length > 1 ? true : false}
              enableMouseSwipe={isLocked ? false : item.files && item.files.length > 1 ? true : false}
              enableSwipe={isLocked ? false : item.files && item.files.length > 1 ? true : false}
              renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <div className='PostCard__Carousel-flex'>
                    {pages.map((page, i) => {
                      const isActivePage = activePage === page;
                      return (
                        <div
                          key={i}
                          className='PostCard__Carousel-dot'
                          onClick={() => onClick(page)}
                          style={{
                            transform: isActivePage ? 'scale(1.3, 1.3)' : undefined,
                            opacity: isActivePage ? '1' : undefined,
                          }}
                        />
                      );
                    })}
                  </div>
                );
              }}
            >
              {item.files.map((file, i) => (
                <PostCarouselItem
                  style={{
                    backgroundColor: darkMode ? '#000' : '#ccc',
                  }}
                  key={i}
                  type={file.type}
                  uri={file.uri}
                  showBtn={item.files.length > 1 && file.type !== 'image' ? true : false}
                  btnImg={isLocked ? LockBtn : UnlockBtn}
                  btnClassName='PostCard__Lock-btn'
                  onClick={() =>
                    this.setState({
                      isLocked: !isLocked,
                    })
                  }
                  title='Lock Swipe'
                />
              ))}
            </Carousel>
          )}
        </div>
        <div className='PostCard__Like-area' style={{ display: inner && 'none' }}>
          {likeFunction && unlikeFunction ? (
            <div className='PostCard__Buttons-container'>
              {isPostLiked || isPostunLiked ? (
                <>
                  <PostButton
                    icon={
                      item.likes.filter((currentUser) => currentUser === user._id).length > 0
                        ? darkMode
                          ? LikeFillIconWhite
                          : LikeFillIcon
                        : darkMode
                          ? LikeIconWhite
                          : LikeIcon
                    }
                    counts={thousandSeparator(item.likeCount || 0)}
                    counterStyle={{ color: darkMode && WHITE_MAIN }}
                  />
                  <PostButton
                    icon={
                      item.unlikes.filter((currentUser) => currentUser === user._id).length > 0
                        ? darkMode
                          ? UnlikeFillIconWhite
                          : UnlikeFillIcon
                        : darkMode
                          ? UnlikeIconWhite
                          : UnlikeIcon
                    }
                    counts={thousandSeparator(item.unlikeCount || 0)}
                    counterStyle={{ color: darkMode && WHITE_MAIN }}
                  />
                </>
              ) : !isPostLiked && !isPostunLiked ? (
                likeLoading ? (
                  <div style={{ marginTop: '15px' }}>
                    <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} size='40px' />
                  </div>
                ) : (
                  <>
                    <PostButton
                      icon={darkMode ? LikeIconWhite : LikeIcon}
                      onClick={() => this.like(item._id)}
                      style={{ cursor: 'pointer' }}
                      counterStyle={{ color: darkMode && WHITE_MAIN }}
                    />
                    <PostButton
                      icon={darkMode ? UnlikeIconWhite : UnlikeIcon}
                      onClick={() => this.unlike(item._id)}
                      style={{ cursor: 'pointer' }}
                      counterStyle={{ color: darkMode && WHITE_MAIN }}
                    />
                  </>
                )
              ) : null}
              <PostButton
                icon={darkMode ? CommentsIconWhite : CommentsIcon}
                counts={thousandSeparator(item.comments || 0)}
                style={{
                  cursor:
                    this.props.location.pathname === COMMENTS_ROUTE || this.props.location.pathname === `${COMMENTS_ROUTE}/`
                      ? 'default'
                      : 'pointer',
                }}
                onClick={() =>
                  this.props.location.pathname === COMMENTS_ROUTE || this.props.location.pathname === `${COMMENTS_ROUTE}/`
                    ? null
                    : this.props.history.push(`${COMMENTS_ROUTE}/${item._id}`)
                }
                counterStyle={{ color: darkMode && WHITE_MAIN }}
              />
            </div>
          ) : null}
        </div>
        <div
          className='PostCard__CommentArea'
          ref={this.props.innerRef}
          style={{
            borderTop: `1px solid ${darkMode ? WHITE_MAIN : FEDERAL_BLUE}`,
            display: this.props.showComments ? 'block' : 'none',
          }}
        >
          {this.props.showComments}
        </div>
        <Alert
          isOpen={this.state.isModalOpen}
          type={this.state.modalType}
          title={this.state.modalTitle}
          content={this.state.modalContent}
          choose={this.state.modalChoose}
          NOClick={this.state.noAction}
          noButtonText={this.state.noText}
          YESClick={this.state.yesAction}
          yesButtonText={this.state.yesText}
          singleButtonText={this.state.btnTxt}
          onRequestClose={this.state.btnAction}
          loading={this.state.actionsLoader}
        />
        <OptionModal
          isOpen={this.state.optionsOpen}
          show={{
            share: { display: true, onClick: () => this.props.history.push(`${routes.CREATE_POST_ROUTE}/?post=${item._id}`) },
            shareUrl: { display: true, onClick: () => this.shareUrl(item._id) },
            shareAsMsg: {
              display: true,
              onClick: () => this.onShare(item._id),
            },
            delete: {
              display: user && user?._id === item?.user?._id ? true : false,
              onClick: () => this.onDeleteCall(),
              title: i18n.t('DASHBOARD.MODAL.deletePost.title'),
            },
            report: {
              display: user && user?._id !== item?.user?._id ? true : false,
              onClick: () => this.onReportCall(),
              title: i18n.t('DASHBOARD.MODAL.reportPost.title'),
            },
          }}
          onRequestClose={() => this.setState({ optionsOpen: false })}
        />
        {Number(item.reportsCount) > Number(postReportsLimit) && !this.state.seeReportedPost ? (
          <div className='PostCard__Overlay-warning'>
            <div className='PostCard__Overlay-warning-modal'>
              <div>
                <p style={{ display: 'inline', color: WARNING_YELLOW, fontWeight: 'bold' }}>{i18n.t('DASHBOARD.warning')}:&nbsp;</p>
                <ParsedText
                  style={{ display: 'inline', color: WHITE_MAIN }}
                  string={i18n.t('DASHBOARD.postHasManyReports')}
                  values={[
                    {
                      keyword: 'Terms\u00A0and\u00A0Conditions',
                      style: { fontWeight: 'bold', color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE },
                      onClick: () => this.props.history.push(routes.TERMS_AND_CONDITIONS_ROUTE),
                    },
                    {
                      keyword: 'Términos\u00A0y\u00A0Condiciones',
                      style: { color: darkMode ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE },
                      onClick: () => this.props.history.push(routes.TERMS_AND_CONDITIONS_ROUTE),
                    },
                  ]}
                />
              </div>
              <button onClick={() => this.setState({ seeReportedPost: true })}>{i18n.t('DASHBOARD.see')}</button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const PostCardWithRouter = withRouter(PostCard);

export default React.forwardRef((props, ref) => <PostCardWithRouter {...props} innerRef={ref} />);
