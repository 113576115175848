import {
  SAVE_FIELD,
  CLEAR_FIELDS
} from '../../types/create-voting/types';

const createVoting = {
  title: '',
  description: '',
  type: '',
  position: '',
  postulate: '',
  lawToRemove: '',
  dealType: '',
  dealToRemove: '',
};

const saveFied = (state, payload) => {

  return {
    ...state,
    [payload.data.field]: payload.data.value,
  };
};

const clearFields = (state, payload) => {

  return {
    ...state,
    title: '',
    description: '',
    position: '',
    postulate: '',
    lawToRemove: '',
    dealToRemove: '',
    type: '',
    dealType: '',
  };
};

const reducer = (state = createVoting, action) => {
  switch (action.type) {
    case SAVE_FIELD:
      return saveFied(state, action);
    case CLEAR_FIELDS:
      return clearFields(state, action);
    default:
      return state;
  }
};

export default reducer;
