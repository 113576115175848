import React, { useEffect, useState, useCallback } from 'react';

// profile
import DefaultPresident from '../../assets/ornament-profile/default_president.png';
import DefaultVicepresident from '../../assets/ornament-profile/default_vice-president.png';
import DefaultCitizen from '../../assets/ornament-profile/default_citizen.png';

// username
import PresidentIcon from '../../assets/post/president-icon.svg';
import PresidentIconYellow from '../../assets/post/president-icon-yellow.svg';
import VicepresidentIcon from '../../assets/post/vicepresident-icon.svg';
import VicepresidentIconYellow from '../../assets/post/vicepresident-icon-yellow.svg';

// likebuttons
import LikeIcon from '../../assets/post/like_icon.svg';
import LikeIconWhite from '../../assets/post/like_icon_white.svg';
import LikeFillIcon from '../../assets/post/like_fill_icon.svg';
import LikeFillIconWhite from '../../assets/post/like_fill_icon_white.svg';
import UnlikeIcon from '../../assets/post/dislike_icon.svg';
import UnlikeIconWhite from '../../assets/post/dislike_icon_white.svg';
import UnlikeFillIcon from '../../assets/post/dislike_fill_icon.svg';
import UnlikeFillIconWhite from '../../assets/post/dislike_fill_icon_white.svg';
import VerificationIcon from '../../assets/ornament-profile/verification_icon.svg';

import { LikeSvg, LikeFillSvg, UnlikeSvg, UnlikeFillSvg } from './svgUtils';

import { FEDERAL_BLUE, GRAY_MAIN, WHITE_MAIN } from '../../utils/color-palatte';

import './profileUtils.css';
import { useTranslation } from 'react-i18next';

export const UserProfile = ({
  uri,
  position,
  containerStyle,
  imgStyle,
  borderColor,
  className,
  onClick,
  showActive,
  dotColor,
  dotStyle,
}) => {
  const defaultSrc =
    position === 'president' ? DefaultPresident : position === 'vicepresident' ? DefaultVicepresident : DefaultCitizen;

  const [src, setSrc] = useState(uri);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    setSrc(uri);
  }, [uri]);

  const onError = useCallback(() => {
    if (!errored) {
      setSrc(defaultSrc);
      setErrored(true);
    }
  }, [defaultSrc, errored]);

  return (
    <div
      className={`UserProfile__Container ${className} `}
      style={Object.assign({ cursor: onClick ? 'pointer' : 'default' }, containerStyle)}
      onClick={onClick}
    >
      <img
        src={src || defaultSrc}
        onError={src && onError}
        alt=''
        className='UserProfile__Img'
        style={Object.assign(
          {
            border: `3px solid ${borderColor || FEDERAL_BLUE}`,
            cursor: 'pointer',
          },
          imgStyle
        )}
        draggable='false'
      />
      {showActive && (
        <div
          className='UserProfile__ActiveDot'
          style={Object.assign(
            {
              width: '20px',
              height: '20px',
              boxSizing: 'borderBox',
              right: '0',
              bottom: '0',
              borderRadius: 'calc(25px / 2)',
              position: 'absolute',
              border: '2px solid #FFF',
              backgroundColor: dotColor ?? GRAY_MAIN,
            },
            dotStyle
          )}
        />
      )}
    </div>
  );
};

export const UserName = ({
  darkMode,
  onClick,
  crownStyle,
  ornamentStyle,
  username,
  position,
  alias,
  style,
  titleStyles,
  aliasStyles,
  isVerified,
  isOnAlias,
  checkIconStyles
}) => {
  const [mobileMatches, setMobileMatches] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (position) {
      window.matchMedia('(max-width: 450px)').addEventListener('change', (e) => setMobileMatches(e.matches));
    }
    return () => {
      window.matchMedia('(max-width: 450px)').removeEventListener('change', () => setMobileMatches(null));
    };
    // eslint-disable-next-line
  }, []);

  const PresidentCrown = ({ darkMode, style }) => (
    <img
      src={darkMode ? PresidentIconYellow : PresidentIcon}
      alt=''
      className='UserProfile__CrownIcon'
      style={style}
      draggable='false'
    />
  );

  const VicepresidentCrown = ({ darkMode, style }) => (
    <img
      src={darkMode ? VicepresidentIconYellow : VicepresidentIcon}
      alt=''
      className='UserProfile__CrownIcon'
      style={style}
      draggable='false'
    />
  );

  return (
    <div className='UserProfile__Username-container' style={Object.assign({ cursor: 'pointer' }, style)}>
      {position && position === 'president' ? (
        <PresidentCrown
          onClick={onClick}
          darkMode={darkMode}
          style={Object.assign(
            {
              marginRight: mobileMatches ? '5px' : '10px',
              width: mobileMatches ? '20px' : undefined,
            },
            crownStyle
          )}
        />
      ) : null}
      {position && position === 'vicepresident' ? (
        <VicepresidentCrown
          darkMode={darkMode}
          onClick={onClick}
          style={Object.assign(
            { cursor: onClick ? 'pointer' : 'default' },
            {
              marginRight: mobileMatches ? '5px' : '10px',
              width: mobileMatches ? '20px' : undefined,
            },
            ornamentStyle
          )}
        />
      ) : null}
      <h1 onClick={onClick} className='UserProfile__Username' style={titleStyles}>
        {username}
      </h1>
      {isVerified && !isOnAlias ? (
        <img
          src={VerificationIcon}
          style={Object.assign({ width: mobileMatches ? 20 : 30, marginLeft: 5 }, checkIconStyles)}
          className='UserProfile__Verification'
          alt='verified'
          title={t('GENERAL_TEXT.verifiedUser')}
        />
      ) : null}
      {alias && (
        <p onClick={onClick} className='UserProfile__Alias' style={aliasStyles}>
          &nbsp;-&nbsp;@{alias}{' '}
          {isVerified && isOnAlias ? (
            <img
              src={VerificationIcon}
              style={Object.assign({ width: mobileMatches ? 15 : 25, marginLeft: 5 }, checkIconStyles)}
              className='UserProfile__Verification'
              alt='verified'
              title={t('GENERAL_TEXT.verifiedUser')}
            />
          ) : null}
        </p>
      )}
    </div>
  );
};

export const LikeButton = ({
  title,
  className,
  onClick,
  darkMode,
  style,
  counts,
  imgStyle,
  liked,
  counterStyle,
  svged,
  iconColor,
  iconStyle,
  otherProps,
}) => (
  <div
    className={`${className} Like__Button`}
    onClick={onClick}
    style={Object.assign(
      {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'row',
      },
      style
    )}
    title={title}
    {...otherProps}
  >
    {!svged && (
      <img
        src={liked ? (darkMode ? LikeFillIconWhite : LikeFillIcon) : darkMode ? LikeIconWhite : LikeIcon}
        alt=''
        className='Like__Button-img'
        style={Object.assign({ marginRight: '5px' }, imgStyle)}
      />
    )}
    {svged && (liked ? <LikeFillSvg fill={iconColor} style={iconStyle} /> : <LikeSvg fill={iconColor} style={iconStyle} />)}
    <p
      className='Like__Button-counter'
      style={Object.assign(
        {
          display: counts === undefined ? 'none' : undefined,
          color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
        },
        counterStyle
      )}
    >
      {counts}
    </p>
  </div>
);

export const UnlikeButton = ({
  title,
  className,
  onClick,
  darkMode,
  style,
  counts,
  imgStyle,
  unliked,
  svged,
  iconColor,
  iconStyle,
  counterStyle,
  otherProps,
}) => (
  <div
    className={`${className} Like__Button`}
    onClick={onClick}
    style={Object.assign(
      {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'row',
      },
      style
    )}
    title={title}
    {...otherProps}
  >
    {!svged && (
      <img
        src={unliked ? (darkMode ? UnlikeFillIconWhite : UnlikeFillIcon) : darkMode ? UnlikeIconWhite : UnlikeIcon}
        alt=''
        className='Like__Button-img'
        style={Object.assign({ marginRight: '5px' }, imgStyle)}
      />
    )}
    {svged && (unliked ? <UnlikeFillSvg fill={iconColor} style={iconStyle} /> : <UnlikeSvg fill={iconColor} style={iconStyle} />)}
    <p
      className='Like__Button-counter'
      style={Object.assign(
        {
          display: counts === undefined ? 'none' : undefined,
          color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
        },
        counterStyle
      )}
    >
      {counts}
    </p>
  </div>
);
