import React from 'react';
import { FEDERAL_BLUE_SOFT, FEDERAL_RED } from '../color-palatte';
import './textArea.css';

const TextArea = (props) => {
  const {
    placeholder,
    value,
    onChange,
    name,
    type,
    disabled,
    showErrors,
    onKeyPress,
    rows,
    cols,
    styles,
    borderColor,
    darkMode,
  } = props;
  return (
    <div className='Generaltextarea__Container'>
      <textarea
        style={Object.assign(
          { border: `1px solid ${showErrors ? FEDERAL_RED : borderColor || FEDERAL_BLUE_SOFT }` },
          styles
        )}
        disabled={disabled}
        placeholder={placeholder}
        type={type || 'text'}
        value={value}
        onChange={onChange}
        name={name}
        onKeyPress={onKeyPress}
        className={darkMode ? 'GeneralTextAreaDark' : 'GeneralTextArea'}
        rows={rows}
        cols={cols}
      />
    </div>
  );
};

export default TextArea;
