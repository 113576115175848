import React from 'react';
import Show from '../../../assets/home/home-download/ShowApp.png';
import Play from '../../../assets/home/home-download/PlayStoreDownload.svg';
import Apple from '../../../assets/home/home-download/AppStoreDownload.svg';
import WebApp from '../../../assets/home/home-download/WebAppDownload.svg';
import PlayES from '../../../assets/home/home-download/PlayStoreDownloadES.svg';
import AppleES from '../../../assets/home/home-download/AppStoreDownloadES.svg';
import WebAppES from '../../../assets/home/home-download/WebAppDownloadES.svg';
import './home-download.css';
import { Trans, Translation, useTranslation } from 'react-i18next';
import SafeURL from '../../../utils/safe-url';
import SafeLINK from '../../../utils/safe-link';
import { BLink, BSpan } from '../../../utils/text-utils/text-utils';
import InfoModal from '../../info-modals/info-modals';
import { FEDERAL_BLUE } from '../../../utils/color-palatte';
import * as routes from '../../../routes/routes';
import * as config from '../../../config';

export const DownloadButton = (props) => {
  const { type, style, imgStyle } = props;
  const { i18n } = useTranslation();
  const language = i18n.language;
  return type === 'android' || type === 'ios' ? (
    <SafeURL
      style={Object.assign({ cursor: 'pointer' }, style)}
      className='DownLoadLink__Btn'
      text={
        <img
          src={
            type === 'android' ? (language === 'es' ? PlayES : Play) : type === 'ios' ? (language === 'es' ? AppleES : Apple) : ''
          }
          style={imgStyle}
          alt={type === 'android' ? 'Get on Google Play' : type === 'ios' ? 'Download in the App Store' : ''}
        />
      }
      dangerousURL={type === 'android' ? config.STORE_LINK_ANDROID : type === 'ios' ? config.STORE_LINK_IOS : ''}
    />
  ) : (
    <SafeLINK
      style={Object.assign({ cursor: 'pointer' }, style)}
      className='DownLoadLink__Btn'
      text={<img src={language === 'es' ? WebAppES : WebApp} style={imgStyle} alt='Become a Citizen' />}
      dangerousLINK={routes.LOGIN_ROUTE}
    />
  );
};

class HomeDownload extends React.Component {
  state = {};

  showInfo = (isOpen, type) => {
    this.setState({
      isInfoOpen: isOpen,
      infoType: type,
    });
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <div className='Download-Container'>
            <h1>{t('DOWNLOAD_APP.title')}!</h1>
            <div className='DownloadSubContainer'>
              <div className='DownloadImgContainer'>
                <img src={Show} alt='ShowApp' />
              </div>
              <div className='DownloadTextContainer'>
                <p>
                  <Trans i18nKey='DOWNLOAD_APP.P'>
                    <BLink style={{ color: FEDERAL_BLUE }} route={routes.LOGIN_ROUTE}>
                      bla
                    </BLink>
                    bla
                    <BSpan onClick={() => this.showInfo(true, 'vote')} style={{ color: FEDERAL_BLUE }}>
                      bla
                    </BSpan>
                    bla
                    <BLink route={routes.LOGIN_ROUTE} style={{ color: FEDERAL_BLUE }}>
                      bla
                    </BLink>
                    bla
                  </Trans>
                </p>
                <div className='DownloadAppConainer'>
                  <DownloadButton type='android' />
                  <DownloadButton type='ios' />
                  <DownloadButton />
                </div>
              </div>
            </div>
            <InfoModal
              isOpen={this.state.isInfoOpen}
              type={this.state.infoType}
              onRequestClose={() =>
                this.setState({
                  isInfoOpen: false,
                })
              }
            />
          </div>
        )}
      </Translation>
    );
  }
}

export default HomeDownload;
