import React from 'react';
import { FEDERAL_BLUE } from '../../color-palatte';
import './regular-text-area.css';

const RegularTextArea = ({
  className,
  id,
  isWhite,
  textareaClassName,
  disabled,
  placeholder,
  style,
  name,
  value,
  onChange,
  autoComplete,
  maxLength,
  row,
  cols
}) => {
  return (
    <div className={`${className} Regular__TextArea-Container`}>
      <textarea
        id={id}
        className={`${
          isWhite ? 'Regular__TextArea-white' : 'Regular__TextArea'
        } ${textareaClassName}`}
        style={Object.assign(
          {
            border: `2px solid ${isWhite ? 'white' : FEDERAL_BLUE}`,
            opacity: disabled ? '0.5' : 'unset',
          },
          style
        )}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        maxLength={maxLength}
        row={row}
        cols={cols}
      />
    </div>
  );
};

export default RegularTextArea;