import React, { useEffect, useState } from 'react';
import IDCard from '../../components/id-card/id-card';
import SafeLINK from '../../utils/safe-link';
// import { useLocation } from 'react-router';
import './welcome.css';
import { Translation } from 'react-i18next';
// import { useHistory } from 'react-router';
import { DASHBOAR_ROUTE } from '../../routes/routes';
import { connect } from 'react-redux';

const Welcome = (props) => {

  const { user } = props;
  // const location = useLocation();
  // const userData =
  //   location && location.state && location.state.user
  //     ? location.state.user
  //     : null;
  // // console.log(userData);

  const [first, setFirst] = useState(window.matchMedia('(max-width: 700px)').matches);
  const [second, setSecond] = useState(window.matchMedia('(max-width: 590px)').matches);
  const [third, setThird] = useState(window.matchMedia('(max-width: 440px)').matches);

  // const history = useHistory();

  let _isMounted = true;

  useEffect(() => {
    // eslint-disable-next-line
    _isMounted = true;
    if (_isMounted) {
    window.matchMedia('(max-width: 700px)').addEventListener('change', (e) => setFirst(e.matches));
    window.matchMedia('(max-width: 590px)').addEventListener('change', (e) => setSecond(e.matches));
    window.matchMedia('(max-width: 440px)').addEventListener('change', (e) => setThird(e.matches));
    // if (location.state === undefined) {
    //   history.push("/home");
    // }
    }
    return () => {
      _isMounted = false;
      // if (history.location.state && history.location.state.user) {
      //   let state = { ...history.location.state };
      //   delete state.user;
      //   history.replace({ ...history.location, state });
      // }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Translation>
      {(t) => (
        <div className='Welcome__Container'>
          <div className='Welcome__Content'>
            <h1 className='Welcome__title'>{t('WELCOME.title')}</h1>
            <p className='Welcome__First-P'>{t('WELCOME.subTitle')}</p>
            {user && <IDCard
              isBlue
              style={{
                transform: `scale(${
                  first
                    ? second
                      ? third
                        ? '.47, .47'
                        : '.6, .6'
                      : '.8, .8'
                    : '1, 1'
                })`,
                transformOrigin: 'top',
                marginBottom: first
                  ? second
                    ? third
                      ? '-180px'
                      : '-140px'
                    : '-70px'
                  : 'unset',
              }}
              user={user}
            />}
            <p className='Welcome__Last-P' style={{textAlign: first ? 'center' : 'left'}}>{t('WELCOME.footerText')}</p>
            <SafeLINK text='Continue' className='Welcome__Button' dangerousLINK={DASHBOAR_ROUTE} />
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapState = state => {
  const { user } = state.user;
  return {
    user
  }
}

export default connect(mapState, null)(Welcome);
