import React from "react";
// import Postcard from "../../components/post/postcard";
import SearchInput from "../../utils/app-inputs/search-input/searchInput";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { actionCreators as actions } from "../../redux/actions/user/actions";
import { withRouter } from "react-router-dom";
import DateIconBlue from "../../assets/search/calendar-blue.svg";
import DateIconWhite from "../../assets/search/calendar.svg";
import ClockIconWhite from "../../assets/search/clock.svg";
// import LikesIconBlue from "../../assets/search/icon_order_by_likes.svg";
import LikesIconWhite from "../../assets/search/icon-like-white.svg";
// import DislikesIconBlue from "../../assets/search/icon_order_by_dislikes.svg";
import DislikesIconWhite from "../../assets/search/icon-dislike-white.svg";
// import CommentsIconBlue from "../../assets/search/icon_order_by_comments.svg";
import CommentsIconWhite from "../../assets/search/comments-icon-white.svg";
// import LeaderIconBlue from "../../assets/search/icon_order_by_leader.svg";
import LeaderIconWhite from "../../assets/search/royal-crown.svg";
import {
  FEDERAL_BLUE,
  FEDERAL_BLUE_SOFT,
  // FEDERAL_LOW_BLUE,
  UNSATURATED_BLACK_BLUE,
  FEDERAL_LIGTH_BLUE,
  // DARK_OFFICIAL_BLUE,
} from "../../utils/color-palatte";
import "./search.css";
import IconCancel from "../../assets/search/icon_cancel_s2.svg";
// import * as routes from "../../routes/routes";
import DateInput from "../../utils/app-inputs/date-input/date-input";
import i18n from "../../i18n/i18n";
import moment from "moment";

const SearchButton = (props) => {
  const { img, onClick, active, darkMode } = props;
  const [hover, setHover] = React.useState(false);
  const bgColor = active ? !darkMode ? UNSATURATED_BLACK_BLUE : 'transparent' : hover
      ? FEDERAL_BLUE_SOFT
      : FEDERAL_BLUE

  return (
    <button
      className="Search__btn"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        background: bgColor
      }}
      onClick={onClick}
    >
      <img style={{ width: 30, height: 30 }} src={img} alt="" draggable="false" />
    </button>
  );
};

const FilterBtn = (props) => (
  <button className="Search__FilterButton" {...props}>
    {props.text}
  </button>
);

const FilterDateInput = (props) => (
  <div className="Search__Date-container">
    <div className="Search__DateText">
      {/* <p>{props.title}</p> */}
      <DateInput
        {...props}
      />
    </div>
    {props.value && <img onClick={props.onDelete} src={IconCancel} alt="" className="Search__DateImg" />}
  </div>
);

class SearchFilter extends React.Component {
  state = {
    isDropped: false,
    toDate: '',
    sinceDate: '',
    searchBy: "",
    search: ""
  };

  handleInputChange = ({ target }) => {

    this.props.setValue(target.name, target.value)

    this.setState({
      [target.name]: target.value,
    }, this.props.onRefresh(true));
  };

  render() {
    const { isDropped, loading, sinceDate, toDate, searchBy, activeDateFilter } = this.state;
    const { darkMode, showPostsFilters, inputPlaceholder, rightComponent, hideDateFilter } = this.props;
    const Buttons = [
      {
        img: ClockIconWhite,
        onClick: () => {
          this.setState({ searchBy: "" })
          this.props.setValue("searchBy", "")
          this.props.onRefresh(true);
        },
        active: searchBy === ""
      },
      {
        img: LikesIconWhite,
        onClick: () => {
          this.setState({ searchBy: "likes" })
          this.props.setValue("searchBy", "likes")
          this.props.onRefresh(true);
        },
        active: searchBy === "likes"
      },
      {
        img:  DislikesIconWhite,
        onClick: () => {
          this.setState({ searchBy: "dislikes" })
          this.props.setValue("searchBy", "dislikes")
          this.props.onRefresh(true);
        },
        active: searchBy === "dislikes"
      },
      {
        img:  CommentsIconWhite,
        onClick: () => {
          this.setState({ searchBy: "comments" })
          this.props.setValue("searchBy", "comments")
          this.props.onRefresh(true);
        },
        active: searchBy === "comments"
      },
      {
        img:  LeaderIconWhite,
        onClick: () => {
          this.setState({ searchBy: "positions" })
          this.props.setValue("searchBy", "positions")
          this.props.onRefresh(true);
        },
        active: searchBy === "positions"
      },
    ];

    const today = moment().format('MM-DD-YYYY')
    const tomorrow = moment().add(1, 'days').format('MM-DD-YYYY')
    const firstDateOfCurrentMonth = moment().clone().startOf('month').format('MM-DD-YYYY')
    const lastDateOfCurrentMonth = moment().clone().endOf('month').format('MM-DD-YYYY')
    const firstDateOfCurrentYear = moment().clone().startOf('year').format('MM-DD-YYYY')
    const lastDateOfCurrentYear = moment().clone().endOf('year').format('MM-DD-YYYY')


    return (
      <div className="Search__Container">
        <div className="Search__Search-bar">
          <SearchInput
            darkMode={darkMode}
            placeholder={inputPlaceholder}
            value={this.state.search}
            onChange={(e) => {
              this.setState({ search: e.target.value }, () => {
                this.props.setValue("search", this.state.search)
                this.props.onRefresh(true);
              })
            }}
          />
          {!hideDateFilter &&  <img
            src={
              darkMode
                ? isDropped
                  ? DateIconBlue
                  : DateIconWhite
                : DateIconBlue
            }
            alt=""
            className="Search__Icon"
            onClick={() => this.setState({ isDropped: !isDropped })}
            title="Add Filters"
          />}
          {rightComponent ? <div style={{ marginLeft: 20 }}>{rightComponent}</div> : null}
        </div>
        {!hideDateFilter && <div
          className="Search__Filter"
          style={{
            background: darkMode ? UNSATURATED_BLACK_BLUE : FEDERAL_LIGTH_BLUE,
            width: "100%",
            height: isDropped ? 230 : 0,
            overflow: "hidden",
            transition: "all .2s",
            padding: isDropped ? 10 : 0,
            margin: isDropped ? 10 : 0
          }}
        >
          <div
            className="Search__FiltBtn"
            style={{
              borderBottom: darkMode ? "2px solid #FFF" : "2px solid #2573b4",
            }}
          >
            <FilterBtn
              text={i18n.t('VOTING_GENERAL.today')}
              onClick={() => {
                this.props.setValue("sinceDate", today)
                this.props.setValue("toDate", tomorrow)
                this.props.onRefresh(true);
                this.setState({ activeDateFilter: "day" })
              }
              }
              style={{
                backgroundColor: activeDateFilter === "day"
                  ? darkMode
                    ? "#FFF"
                    : FEDERAL_BLUE
                  : "#0000",
                border: `2px solid ${darkMode ? "#fff" : FEDERAL_BLUE}`,
                color: activeDateFilter === "day"
                  ? darkMode
                    ? UNSATURATED_BLACK_BLUE
                    : "#FFF"
                  : darkMode
                    ? "#FFF"
                    : FEDERAL_BLUE,
              }}
            />
            <FilterBtn
              text={i18n.t('DASHBOARD.month')}
              onClick={() => {
                this.props.setValue("sinceDate", firstDateOfCurrentMonth)
                this.props.setValue("toDate", lastDateOfCurrentMonth)
                this.props.onRefresh(true);
                this.setState({ activeDateFilter: "month" })
              }
              }
              style={{
                backgroundColor: activeDateFilter === "month"
                  ? darkMode
                    ? "#FFF"
                    : FEDERAL_BLUE
                  : "#0000",
                border: `2px solid ${darkMode ? "#fff" : FEDERAL_BLUE}`,
                color: activeDateFilter === "month"
                  ? darkMode
                    ? UNSATURATED_BLACK_BLUE
                    : "#FFF"
                  : darkMode
                    ? "#FFF"
                    : FEDERAL_BLUE,
              }}
            />
            <FilterBtn
              text={i18n.t('DASHBOARD.year')}
              onClick={() => {
                this.props.setValue("sinceDate", firstDateOfCurrentYear)
                this.props.setValue("toDate", lastDateOfCurrentYear)
                this.props.onRefresh(true);
                this.setState({ activeDateFilter: "year" })
              }}
              style={{
                backgroundColor: activeDateFilter === "year"
                  ? darkMode
                    ? "#FFF"
                    : FEDERAL_BLUE
                  : "#0000",
                border: `2px solid ${darkMode ? "#fff" : FEDERAL_BLUE}`,
                color: activeDateFilter === "year"
                  ? darkMode
                    ? UNSATURATED_BLACK_BLUE
                    : "#FFF"
                  : darkMode
                    ? "#FFF"
                    : FEDERAL_BLUE,
              }}
            />
            <FilterBtn
               text={i18n.t('DASHBOARD.sinceEver')}
              onClick={() => {
                this.props.setValue("sinceDate", "")
                this.props.setValue("toDate", "")
                this.props.onRefresh(true);
                this.setState({ activeDateFilter: "" })
              }}
              style={{
                backgroundColor:
                  !activeDateFilter
                    ? darkMode
                      ? "#FFF"
                      : FEDERAL_BLUE
                    : "#0000",
                border: `2px solid ${darkMode ? "#fff" : FEDERAL_BLUE}`,
                color:
                  !activeDateFilter
                    ? darkMode
                      ? UNSATURATED_BLACK_BLUE
                      : "#FFF"
                    : darkMode
                      ? "#FFF"
                      : FEDERAL_BLUE,
              }}
            />
          </div>
          <div
            className="Search__FiltDate"
            style={{ color: darkMode ? "#FFF" : "#2573b4" }}
          >
            <FilterDateInput
              title={i18n.t('VOTING_GENERAL.from')}
              isWhite={darkMode}
              name='sinceDate'
              value={sinceDate}
              onChange={this.handleInputChange}
              placeholder='MM/DD/YY'
              disabled={loading}
              onDelete={() => this.setState({ sinceDate: "" },() => {
                this.props.setValue("sinceDate", "")
                this.props.onRefresh(true)
              })}
              />
            <FilterDateInput
              title={i18n.t('VOTING_GENERAL.to')}
              isWhite={darkMode}
              name='toDate'
              value={toDate}
              onChange={this.handleInputChange}
              placeholder='MM/DD/YY'
              disabled={loading}
              onDelete={() => this.setState({ toDate: "" }, () => {
                this.props.setValue("toDate", "")
                this.props.onRefresh(true)
              })}
            />
          </div>
        </div>}
        {showPostsFilters && <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexFlow: "row nowrap",
            marginTop: isDropped ? 10 : 0
          }}
        >
          {Buttons.map((item, i) => (
            <SearchButton {...item} key={i} darkMode={darkMode} />
          ))}
        </div>}
      </div>
    );
  }
}

const SearchWithRouter = withRouter(SearchFilter);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchWithRouter);
