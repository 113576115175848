import { store } from '../redux/configureStore';
import { OPEN_MESSAGE_MODAL, OPEN_VERIFY_MODAL } from '../redux/types/user/types';

export const useAlert = () => {
  return (type, content) => store.dispatch({
    type: OPEN_MESSAGE_MODAL,
    data: {
      type: type, 
      message: content
    }
  })
}

export const useVerificationRequest = () => {
  return () => store.dispatch({
    type: OPEN_VERIFY_MODAL
  })
}