import { putData } from '../fetch';

export const editUser = async (data, id) => {
  const userId = localStorage.getItem('userId');
  return new Promise(async (resolve, reject) => {
    try {
      const response = await putData(`/user/edit-user-web/${id || userId}`, data);
      return resolve(response);
    } catch (e) {
      // console.log("editUser / e: ", e)
      return reject(e);
    }
  });
};

export default editUser;
