import React from 'react';
import { connect } from 'react-redux';
import { getUserPayments } from './user-payments-actions';
import moment from 'moment';
import getStat from '../../utils/getStat';
import { isUserValid } from '../../utils/isUserValid';
import Alert from '../../utils/alert/Alert';
import PaymentItem from '../../components/payments/PaymentItem';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import i18n from '../../i18n/i18n';
import { AppLoader } from '../../utils/animations/animations';
import { FEDERAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import { NotFoundText } from '../../components/not-found-text/NotFoundText';
import SearchFilter from '../../components/search-filter/SearchFilter';

class UserPayments extends React.Component {
  constructor() {
    super();
    this.state = {
      fetching: false,
      skipItems: 0,
      limitItems: 5,
      plusPostNumber: 5,
      keepIncreasingCounter: false,
      search: '',
      positions: null,
      payments: [],
      isVisibleModal: false,
    };
  }

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(true, this._isMounted)
    if (this._isMounted) await this.getStats();
    if (this._isMounted) this.onRefreshPaymentsList();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  getStats = () => {
    return new Promise(async (resolve, _) => {
      try {
        const positions = await getStat('positions');
        this.setState({ positions });
        resolve();
      } catch (e) {
        resolve();
        // console.log("getStats / error: ", e);
      }
    });
  };

  fetchPayments = () => {
    return new Promise(async (resolve, reject) => {
      const {
        skipItems,
        limitItems,
        fetching,
        search,
        payments = [],
      } = this.state;
      const { user } = this.props;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const newUserPayments = await getUserPayments(
              skipItems,
              limitItems,
              search,
              user.admin
            );
            // console.log("fetchPayments / getVoting / newUserPayments: ", newUserPayments);
            let finalUserPayments = [];
            finalUserPayments = payments
              .concat(newUserPayments)
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t._id === item._id)
              )
              .sort((a, b) => moment(b.date) - moment(a.date));
            this.setState({
              payments: finalUserPayments,
              fetching: false,
              keepIncreasingCounter: newUserPayments.length > 0,
            });
            resolve();
          } catch (e) {
            if (e.status === 404) {
              this.setState({ fetching: false })
              resolve();
            } else {
              reject(e);
              // console.log("fetchPayments / error: ", e)
              this.setState({ fetching: false })
              const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
              this.showAlert(true, "wrong", "", error)
            }
          }
        });
      }
    });
  };

  onRefreshPaymentsList = () => {
    const { plusPostNumber } = this.state;
    this.setState(
      { skipItems: 0, limitItems: plusPostNumber, payments: [] },
      () => {
        this.fetchPayments();
      }
    );
  };

  onEndReached = () => {
    // console.log("onEndReached");
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } =
      this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchPayments();
        }
      );
    } else {
      this.fetchPayments();
    }
  };

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  render() {
    const { fetching, positions, payments } = this.state;
    const { user, darkMode } = this.props;
    return (
      <ScrollableDiv
        onEndReach={() => this.onEndReached()}
        className='Dashboard__Container'
      >
        <SearchFilter
          inputPlaceholder={i18n.t('PAYMENTS_LIST.searhPayments')}
          onRefresh={this.onRefreshPaymentsList}
          setValue={(name, value) => {
            this.setState({ [name]: value })
          }}
          showPostsFilters={false}
        />
        {payments.map(item =>
          <PaymentItem
            fetching={fetching}
            item={item}
            positions={positions}
            user={user}
            darkMode={darkMode} 
            historyObj={this.props.history}
            />
        )}
         <div
          style={{
            width: '100%',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: darkMode ? WHITE_MAIN : '#000',
          }}
        >
          {fetching && (
            <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
          )}
          {!fetching &&
            payments?.length > 0 &&
            <NotFoundText content={i18n.t('PAYMENTS_LIST.noPaymentsYet')} /> }
          {!fetching &&
            payments?.length <= 0 &&
            <NotFoundText content={i18n.t('PAYMENTS_LIST.noSearchResults')} />}
        </div>
        <Alert
          isOpen={this.state.isModalOpen}
          type={this.state.modalType}
          title={this.state.modalTitle}
          content={this.state.modalContent}
          choose={this.state.modalChoose}
          NOClick={this.state.noAction}
          noButtonText={this.state.noText}
          YESClick={this.state.yesAction}
          yesButtonText={this.state.yesText}
          singleButtonText={this.state.btnTxt}
          onRequestClose={() => this.showAlert(false)}
          loading={this.state.actionsLoader}
        />
      </ScrollableDiv>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

function mapStateToProps(state) {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPayments);
