import React, { useState, useEffect } from 'react';
import AlertModal from '../../../utils/modal/Modal';
import AppVideo from '../../app-video/AppVideo';
import CloseIcon from '../../../assets/options-modal/icon_close_white.svg';
import Webcam from 'react-webcam';
import FlipCam from '../../../assets/create-post/flip_camera_icon.svg';
import FilmVideoCam from '../../../assets/create-post/film_icon.svg';
import CameraIcon from '../../../assets/create-post/camera_icon.svg';
import { useStopwatch } from 'react-timer-hook';
import i18n from '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import './previewModal.css';
import { UNSATURATED_BLACK_BLUE } from '../../../utils/color-palatte';

export const WebCamModal = ({ isOpen, onRequestClose, webCamRef, mobile, action, onStart, onEnd, format, audio, darkMode }) => {
  const [backCam, setBackCam] = useState(false);
  const [recordMode, setRecordMode] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [dimention, setDimention] = useState(false);

  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  let vh = window.innerHeight * 0.01;

  let height = window.innerHeight,
    width = window.innerWidth;

  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    return () => document.documentElement.style.removeProperty('--vh', `${vh}px`);
  });

  useEffect(() => {
    if (width > height) {
      setDimention(true);
    } else {
      setDimention(false);
    }
    return () => setDimention(false);
  }, [width, height]);

  const videoConstants = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    facingMode: backCam ? { exact: 'environment' } : 'user',
  };

  const onStartPres = () => {
    setIsRecording(true);
    // console.log('start press');
    if (typeof onStart === 'function') {
      onStart();
    start();
    }
  };

  const onEndPress = () => {
    setIsRecording(false);
    reset();
    pause();
    // console.log('end press');
    if (typeof onEnd === 'function') {
      onEnd();
    }
  };

  const RECORD_RED = '#EB2012';

  const { t } = useTranslation()

  return (
    <AlertModal
      outsideClick={onRequestClose}
      overlayClassName='WebCamModal__Overlay'
      modalClassName={mobile ? (dimention ? 'WebCamModal__Container-mobile-weight' : 'WebCamModal__Container-mobile') : 'WebCamModal__Container'}
      isOpen={isOpen}
      style={{ border: `10px solid ${darkMode ? UNSATURATED_BLACK_BLUE : '#FFF'}` }}
    >
      <Webcam
        videoConstraints={videoConstants}
        audio={audio}
        ref={webCamRef}
        screenshotFormat={format}
        onUserMediaError={() => setShowError(true)}
        onUserMedia={() => setShowError(false)}
      />
      {!mobile && (
        <div className='WebCamModal__Btns'>
          <button
            className='WebCamModal__Action'
            style={showError ? { opacity: '0.5', pointerEvents: 'none' } : undefined}
            onClick={recordMode ? (isRecording ? onEndPress : onStartPres) : action}
            title={
              recordMode
                ? isRecording
                  ? t('CREATE_POST.VIDEO_MODAL.stop')
                  : t('CREATE_POST.VIDEO_MODAL.startRecording')
                : t('CREATE_POST.VIDEO_MODAL.takePicture')
            }
          >
            <div
              style={{
                border: `2px solid ${recordMode ? RECORD_RED : '#FFF'}`,
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {recordMode ? (
                isRecording ? (
                  <div className='WebCamModal-pause' />
                ) : (
                  <div className='WebCamModal__btn-active' />
                )
              ) : (
                <div className='WebCamModal__btn-active' style={{ backgroundColor: '#FFF' }} />
              )}
            </div>
          </button>
          <button
            className='WebCamModal__Switch'
            onClick={() => setRecordMode(!recordMode)}
            style={showError || isRecording ? { opacity: '0.5', pointerEvents: 'none' } : undefined}
            title={recordMode ? t('CREATE_POST.VIDEO_MODAL.switchPicture') : t('CREATE_POST.VIDEO_MODAL.switchVideo')}
          >
            <img src={recordMode ? FilmVideoCam : CameraIcon} alt='' draggable='false' />
          </button>
        </div>
      )}
      {mobile && (
        <div
          className='WebCamModal__Button-container'
          style={
            dimention
              ? {
                  position: 'absolute',
                  flexDirection: 'column',
                  right: '0',
                  top: '0',
                  height: '100%',
                  width: '100px',
                }
              : undefined
          }
        >
          <button
            className='WebCamModal__Switch-mobile'
            onClick={() => setBackCam(!backCam)}
            style={showError || isRecording ? { opacity: '0.5', pointerEvents: 'none' } : undefined}
          >
            <img src={FlipCam} alt='' draggable='false' />
          </button>
          {!recordMode && (
            <button
              className={`WebCamModal__Mobile-action ${isRecording ? 'WebCamModal__Mobile-action-recording' : ''}`}
              onClick={action}
              style={showError ? { opacity: '0.5', pointerEvents: 'none' } : undefined}
            >
              <div />
            </button>
          )}
          {recordMode ? (
            isRecording ? (
              <button
                className={`WebCamModal__Mobile-action ${isRecording ? 'WebCamModal__Mobile-action-recording' : ''}`}
                onClick={onEndPress}
                style={showError ? { opacity: '0.5', pointerEvents: 'none' } : undefined}
              >
                <div />
              </button>
            ) : (
              <button
                className={`WebCamModal__Mobile-action ${isRecording ? 'WebCamModal__Mobile-action-recording' : ''}`}
                onClick={onStartPres}
                style={showError ? { opacity: '0.5', pointerEvents: 'none' } : undefined}
              >
                <div />
              </button>
            )
          ) : null}
          <button
            className='WebCamModal__Switch-record-mobile'
            onClick={() => setRecordMode(!recordMode)}
            style={showError || isRecording ? { opacity: '0.5', pointerEvents: 'none' } : undefined}
          >
            <img src={recordMode ? CameraIcon : FilmVideoCam} alt='' draggable='false' />
          </button>
        </div>
      )}
      {showError && (
        <p className='WebCamModal__Show-error' style={dimention ? { marginTop: '0' } : undefined}>
          The device was not found or we do not have the necessary permission to access the device
        </p>
      )}
      <img
        src={CloseIcon}
        alt=''
        draggable='false'
        onClick={onRequestClose}
        className='WebCamModal__Close-btn'
        style={
          mobile && dimention
            ? {
                left: '0',
                rigth: 'unset',
                marginLeft: '20px',
                marginRight: '0',
              }
            : undefined
        }
      />
      {recordMode && (
        <p className='WebCamModal__Timer'>
          <span>{`${('0' + hours).slice(-2)}`}:</span>
          <span>{`${('0' + minutes).slice(-2)}`}:</span>
          <span>{`${('0' + seconds).slice(-2)}`}</span>
        </p>
      )}
    </AlertModal>
  );
};

export const PreviewModal = (props) => {
  const { file, isOpen, onRequestClose, imageMaxSize, videoMaxSize } = props;
  return (
    <AlertModal outsideClick={onRequestClose} overlayClassName='PreviewModal__Overlay' modalClassName='PreviewModal__Container' isOpen={isOpen}>
      {file?.type?.split('/')[0] === 'video' ? (
        <AppVideo source={file?.uri} className='PreviewModal__Media' />
      ) : (
        <img src={file?.uri} className='PreviewModal__Img' alt='' />
      )}
      <img src={CloseIcon} alt='' onClick={onRequestClose} className='PreviewModal__Close-btn' />
      {(file && file?.type?.split('/')[0] === 'image' && file?.sizeInMb > imageMaxSize) ||
      (file?.type?.split('/')[0] === 'video' && file?.sizeInMb > videoMaxSize) ? (
        <div className='PreviewModal__Warning'>
          <p>
            {`${i18n.t('CREATE_POST.tooWeight')} ${Math.round(file.sizeInMb)} MB`}
            <span>
              {`${file.type.split('/') === 'image' ? i18n.t('CREATE_POST.imageSizeInfo') : i18n.t('CREATE_POST.videoSizeInfo')} ${
                file.type.split('/') === 'image' ? imageMaxSize : videoMaxSize
              } MB`}
            </span>
          </p>
        </div>
      ) : null}
    </AlertModal>
  );
};

export default PreviewModal;
