import React from 'react';
import { UserProfile } from '../ornament-profile/profileUtils';
import SendIcon from '../../assets/message-input/send_icon.svg';
import SendIconWhite from '../../assets/message-input/send_icon_white.svg';
import Alert from '../../utils/alert/Alert';
// import i18n from '../../i18n/i18n';
import './messageInput.css';
import { AppLoader } from '../../utils/animations/animations';
import CustomTextInput from '../../utils/app-inputs/custom-text-input/CustomTextInput';
import { WHITE_MAIN } from '../../utils/color-palatte';

class MessageInput extends React.Component {
  constructor() {
    super();
    this.state = {
      fetching: false,
      search: '',
      keyword: '',
      data: [],
      value: '',
      hover: false,
    };
  }

  add = async () => {
    const { addMessage } = this.props;
    const { search } = this.state;
    // if (search) {
      // console.log('search:', search);
      try {
        await addMessage(search);
        this.setState({
          search: '',
        });
      } catch (e) {
        // console.log('add message', e)
        this.showAlert(true, 'wrong', e);
      }
    // } else {
    //   this.showAlert(
    //     true,
    //     'warning',
    //     i18n.t('GENERAL_ERRORS.missingFields')
    //   );
    // }
  };

  componentDidMount = () => {
    window.addEventListener('keydown', e => this.handleKeyPress(e));
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', e => this.handleKeyPress(e));
  }

  handleInputChange = ({ target }) => {
    this.setState({
      search: target.value,
    });
  };

  handleKeyPress = (e) => {
    // console.log('key: ', e);
    if (e.key === 'Enter' && e.shiftKey === true) {
      return;
    }
    else if (e.key === 'Enter' ) {
      this.add();
    }
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const { user, placeholder, fetching, darkMode, isRef, forwardedRef, showErrors = true } = this.props;
    const { search, hover } = this.state;

    return (
      <div ref={forwardedRef} className='MessageInput__Container'>
        <UserProfile
          uri={user?.picture}
          position={user?.position}
          containerStyle={{
            marginRight: '10px',
            width: '45px',
            height: '45px',
          }}
          imgStyle={{ border: 'none' }}
        />
        <CustomTextInput
          // name='search'
          value={search}
          isWhite={darkMode}
          onChange={(e) => this.handleInputChange(e)}
          disabled={fetching || isRef}
          placeholder={`${placeholder} ${user?.username ?? ''}`}
          autoComplete='off'
          className='MessageInput'
          // onKeyPress={this.handleKeyPress}
          isTextArea
        />
        {!fetching 
        ? <button
          className='MessageInput__Send-btn'
          style={{
            background: hover
              ? darkMode
                ? '#FFF5'
                : '#2573b450'
              : 'transparent',
            opacity: search.length <= 0 || fetching ? '0.5' : '1',
          }}
          onClick={() => this.add()}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          disabled={fetching}
        >
          <img src={darkMode ? SendIconWhite : SendIcon} alt='' />
        </button>
        : <AppLoader color={darkMode && WHITE_MAIN} />}
        {showErrors && <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        />}
      </div>
    );
  }
}

const MessageInputWithRef = React.forwardRef((props, ref) => (<MessageInput {...props} forwardedRef={ref} />));

export default MessageInputWithRef;
