import React, { useState } from 'react';
import AlertModal from '../../../utils/modal/Modal';
import CloseBtn from '../../../assets/payment-modals/close_btn.svg';
import i18n from '../../../i18n/i18n';
import { Trans, Translation } from 'react-i18next';
import { ListItem } from '../listItem';
import './subAdviceModal.css';
import InfoModal from '../../info-modals/info-modals';
import { BSpan } from '../../../utils/text-utils/text-utils';

const SubAdviceModal = (props) => {
  const { isOpen, onRequestClose } = props;

  const [modal, setModal] = useState({
    isOpen: false,
    type: null,
  });

  const openModal = (isOpen, type) => {
    setModal({
      isOpen: isOpen,
      type: type,
    });
  };

  const Item = [
    {
      text: i18n.t('BENEFITS.li1'),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li2'>
          bla
          <BSpan onClick={() => openModal(true, 'voting')}>bla</BSpan>
          bla
          <BSpan onClick={() => openModal(true, 'law')}>bla</BSpan>
          bla
          <BSpan onClick={() => openModal(true, 'deal')}>bla</BSpan>
          bla
        </Trans>
      ),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li3'>
          bla
          <BSpan onClick={() => openModal(true, 'citizen')}>bla</BSpan>
          bla
        </Trans>
      ),
    },
    {
      text: i18n.t('BENEFITS.li4'),
    },
    {
      text: (
        <Trans i18nKey='BENEFITS.li5'>
          bla
          <BSpan onClick={() => openModal(true, 'services')}>bla</BSpan>
          bla
          <BSpan onClick={() => openModal(true, 'deals')}>bla</BSpan>
          bla
        </Trans>
      ),
    },
  ];

  return (
    <Translation>
      {(t) => (
        <AlertModal
          isOpen={isOpen}
          modalClassName='SubAdvice__Container'
          overlayClassName='SubAdvice__Overlay'
          outsideClick={onRequestClose}
        >
          <div className='SubAdvice__Header'>
            <img
              src={CloseBtn}
              alt='close'
              className='SubAdvice__Close-btn'
              onClick={onRequestClose}
            />
            <h1>"{t('PAYMENTS_MODALS.SUB_ADV_MODAL.title')}"</h1>
          </div>
          <div className='SubAdvice__Content'>
            <h3>{t('BENEFITS.title')}</h3>
            {Item.map((item, i) => (
              <ListItem textClassName='SubAdvice__Li' key={i} {...item} />
            ))}
            <button onClick={() => props.onPayClick()}>{t('PAYMENTS_MODALS.subscribeNow')}</button>
          </div>
          <InfoModal
            isOpen={modal.isOpen}
            type={modal.type}
            onRequestClose={() => setModal({ ...modal, isOpen: false })}
          />
        </AlertModal>
      )}
    </Translation>
  );
};

export default SubAdviceModal;
