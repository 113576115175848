import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './user-finance.css';
import profileBg from '../../assets/user-finance/user-finance-bg.png';
import invoiceButtonIcon from '../../assets/user-finance/invoice-button-icon.svg';
import SafeLINK from '../../utils/safe-link';
import * as routes from '../../routes/routes';
import i18n from '../../i18n/i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { isUserValid } from '../../utils/isUserValid';
import getStat from '../../utils/getStat';
import OrnamentProfile from '../../components/ornament-profile/ornament-profile';
import { FEDERAL_BLUE, FEDERAL_GREEN, WHITE_MAIN } from '../../utils/color-palatte';
import { AppLoader } from '../../utils/animations/animations';
import Alert from '../../utils/alert/Alert';
import handlePositions from '../../utils/handlePositionName';
import VoteItem from '../../components/votes/voteItem';
import { Warning } from '../../components/warning/Warning';
import { Trans } from 'react-i18next';
import { BSpan } from '../../utils/text-utils/text-utils';
import InfoModal from '../../components/info-modals/info-modals';
import thousandSeparator from '../../utils/thousandSeparator';

export const ProfileButton = ({ className, number, titleStyle, title, picture, onClick, linked, navigation }) => {
  const [hover, setHover] = useState(false);

  if (linked) {
    return (
      <SafeLINK
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        dangerousLINK={navigation}
        className={`Profile__Action__Button ${className}`}
        style={{ background: hover ? '#257eb44f' : undefined }}
        text={
          <>
            <img src={picture} className='Profile__Action__Button__Picture' alt={`Go to ${title} button`} />
            <h1 className='Profile__Action__Button__Number'>{number}</h1>
            <p className='Profile__Action__Button__Name' style={titleStyle}>
              {title}
            </p>
          </>
        }
      />
    );
  }
  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ background: hover ? '#257eb44f' : undefined }}
      className={`Profile__Action__Button ${className}`}
    >
      <img src={picture} className='Profile__Action__Button__Picture' alt={`Go to ${title} button`} />
      <h1 className='Profile__Action__Button__Number'>{number}</h1>
      <p className='Profile__Action__Button__Name' style={titleStyle}>
        {title}
      </p>
    </button>
  );
};

class Profile extends React.Component {
  state = {
    loading: true,
    modal: {
      isOpen: false,
      type: null,
    },
    vicepresidentSalaryPorcentage: '',
    presidentSalaryPorcetage: '',
  };

  _isMounted = false;

  openModal = (isOpen, type) => {
    this.setState({
      modal: {
        isOpen: isOpen,
        type: type,
      },
    });
  };

  componentDidMount = async () => {
    this._isMounted = true;
    const { id } = this.props.match.params;
    if (id) {
      this.props.history.push(`${routes.PROFILE_ROUTE}/${id}`);
    }
    isUserValid(false, this._isMounted);
    if (this._isMounted)  await this.getStats();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const positions = await getStat('positions');
        const presidentSalaryPorcetage = await getStat('president-salary-porcentage');
        const vicepresidentSalaryPorcentage = await getStat('vicepresident-salary-porcentage');
        this.setState({ positions, presidentSalaryPorcetage, vicepresidentSalaryPorcentage, loading: false });
        resolve();
      } catch (e) {
        reject();
        // console.log("getStats / error: ", e);
      }
    });
  };

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  render() {
    const { darkMode, user } = this.props;
    const { positions, presidentSalaryPorcetage, vicepresidentSalaryPorcentage, modal } = this.state;
    // console.log("render / user: ", user)
    // console.log("render / presidentSalaryPorcetage: ", presidentSalaryPorcetage)
    // console.log("render / vicepresidentSalaryPorcentage: ", vicepresidentSalaryPorcentage)

    let currentPosition = null;
    if (positions && positions.length > 0) {
      const havePosition = positions.filter((item) => item.user._id === user._id);
      if (havePosition.length > 0) {
        currentPosition = havePosition[0];
      }
    }

    return (
      <div className='Profile__Container'>
        <div className='Profile__Background__Conatiner'>
          {/* <img
            src={user.bgPicture || profileBg}
            className='Profile__Background__Image'
            alt='profile background'
          /> */}
          <div className='Profile__Background__Image' style={{ backgroundImage: `url(${profileBg})` }}></div>
          <OrnamentProfile userPicture={user?.picture} className='Profile__Background__Ornament' noText />
        </div>
        <div className='Profile__Content__Container'>
          {user.name && user.showName ? <h1 className='Profile__Content__User_Name'>{user.name}</h1> : null}
          <h3 className='Profile__Content__UserName'>@{user.username}</h3>
        </div>
        <div className='cardContainer' style={{ backgroundColor: FEDERAL_BLUE }}>
          <div className='positionContainer'>
            <p className='positionTitle'>{`${i18n.t('USER_FINANCE.position')}: `}</p>
            {positions !== null ? (
              <p className='positionContent'>
                {currentPosition && currentPosition.position
                  ? handlePositions(currentPosition.position)
                  : false || i18n.t('USER_FINANCE.citizen')}
              </p>
            ) : (
              <AppLoader size='small' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
            )}
          </div>
          <div className='positionContainer'>
            <p className='positionTitle'>{`${i18n.t('USER_FINANCE.salary')}: `}</p>
            {positions !== null ? (
              <p className='positionContent'>
                {`${currentPosition && currentPosition.salary > 0 ? `≈ ` : ''} ${
                  currentPosition && currentPosition.salary ? thousandSeparator(currentPosition.salary) : 0
                }$`}
              </p>
            ) : (
              <AppLoader size='small' color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
            )}
          </div>
        </div>
        <VoteItem
          style={{
            justifyContent: 'center',
            backgroundColor: FEDERAL_BLUE,
            backgroundImage: `url(${invoiceButtonIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% center',
            width: '90%',
          }}
          text={i18n.t('USER_FINANCE.paymentsHistory')}
          linked
          navigation={routes.USER_PATMENTS_ROUTE}
          color={WHITE_MAIN}
        />
        <Warning
          darkMode={darkMode}
          title={i18n.t('VOTING_REQUEST.warning')}
          content={
            <Trans i18nKey='USER_FINANCE.warningInfo'>
              bla
              <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'president')}>
                bla
              </BSpan>
              bla
              {presidentSalaryPorcetage ? presidentSalaryPorcetage : '...'}
              bla
              <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'vicepresident')}>
                bla
              </BSpan>
              bla
              {vicepresidentSalaryPorcentage ? vicepresidentSalaryPorcentage : '...'}
              bla
            </Trans>
          }
        />
        <Alert
          isOpen={this.state.isModalOpen}
          type={this.state.modalType}
          title={this.state.modalTitle}
          content={this.state.modalContent}
          choose={this.state.modalChoose}
          NOClick={this.state.noAction}
          noButtonText={this.state.noText}
          YESClick={this.state.yesAction}
          yesButtonText={this.state.yesText}
          singleButtonText={this.state.btnTxt}
          onRequestClose={() => this.showAlert(false)}
          loading={this.state.actionsLoader}
        />
        <InfoModal
          isOpen={modal.isOpen}
          type={modal.type}
          onRequestClose={() => this.setState({ modal: { isOpen: false, type: null } })}
        />
      </div>
    );
  }
}

const ProfileWithRouter = withRouter(Profile);

function mapDispatchToProps(dispatch) {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
  };
}

function mapStateToProps(state) {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWithRouter);
