import React from 'react';
import TransparencyImg from '../../assets/thumbnails/image_icon_transparency.svg';
import TransparencyVideo from '../../assets/thumbnails/video_icon_transparency.svg';
import './thumbVideo.css';

const Transparency = ({ img }) => (
  <img
    src={img ? TransparencyImg : TransparencyVideo}
    className='Thumb__Transparency'
    alt=''
  />
);

export const ThumbVideo = ({ className, onClick, source }) => (
  <div className={`ThumbVideo__Container ${className}`} onClick={onClick}>
    <video src={source} className='ThumbVideo-video' loop autoPlay muted />
    <Transparency />
  </div>
);

export const ThumbImage = ({ className, onClick, source }) => (
  <div className={`ThumbImage__Container ${className}`} onClick={onClick}>
    <img src={source} alt='' className='ThumbImage-img' />
    <Transparency img />
  </div>
);
