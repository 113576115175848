import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './navbar.css';
import * as routes from '../../routes/routes';
import { connect } from 'react-redux';
import { DARK_OFFICIAL_BLUE, FEDERAL_WHITE, UNSATURATED_BLACK_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import VoiceBg from '../../assets/dashboard/voice_bg.svg';
import HandsUpBg from '../../assets/dashboard/hands_up_bg.svg';
import SideMenu from '../layout/sideMenu';
// import { bindActionCreators } from 'redux';
// import { actionCreators as actions } from '../../redux/actions/user/actions';

// const NavBarButton = ({ title, logo, route, darkMode }) => {
//   return (
//     <SafeLINK
//       className='NavBar__button'
//       text={
//         <>
//           <img src={logo} alt='' />
//           <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE }}>{title}</p>
//           <span style={{ borderBottom: `2px solid ${darkMode ? WHITE_MAIN : FEDERAL_BLUE}`}} />
//         </>
//       }
//       dangerousLINK={route}
//     />
//   );
// };

const NavBar = (props) => {
  const [matches, setMatches] = useState(window.matchMedia('(max-width: 800px)').matches);
  const [secondMatches, setSecondMatches] = useState(window.matchMedia('(max-width: 650px)').matches);

  useEffect(() => {
    const firstHandler = (e) => setMatches(e.matches);
    const secondHandler = (e) => setSecondMatches(e.matches);
    window.matchMedia('(max-width: 800px)').addEventListener('change', firstHandler);
    window.matchMedia('(max-width: 650px)').addEventListener('change', secondHandler);
    return () => {
      window.matchMedia('(max-width: 800px)').removeEventListener('change', firstHandler);
      window.matchMedia('(max-width: 650px)').removeEventListener('change', secondHandler);
    };
  });

  const location = useLocation();

  const { children, darkMode } = props;
  const path = location.pathname;

  return (
    <div
      className='NavBar__container'
      style={{
        backgroundColor:
          path === routes.ROOT_ROUTE ||
          path === `${routes.ROOT_ROUTE}/` ||
          path === routes.DASHBOAR_ROUTE ||
          path === `${routes.DASHBOAR_ROUTE}/` ||
          path === routes.SEARCH_ROUTE ||
          path === `${routes.SEARCH_ROUTE}/` ||
          path === routes.HOME_ROUTE ||
          path === routes.COMMENTS_ROUTE ||
          path === `${routes.COMMENTS_ROUTE}/` ||
          path === routes.POST_ROUTE ||
          path === `${routes.POST_ROUTE}/`
            ? darkMode
              ? DARK_OFFICIAL_BLUE
              : '#eee'
            : darkMode
            ? DARK_OFFICIAL_BLUE
            : WHITE_MAIN,
        minHeight: '100vh',
        backgroundImage:
          path === routes.VOTINGS_ROUTE ? `url(${HandsUpBg})` : path === routes.CREATE_VOTE_ROUTE ? `url(${VoiceBg})` : 'unset',
        backgroundPosition: path === routes.CREATE_VOTE_ROUTE ? 'center calc(100% + 40px)' : 'center bottom',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        className='NavBar__menu-container'
        style={Object.assign({
          display:
            (path === routes.FAQ_ROUTE ||
              path === routes.TERMS_AND_CONDITIONS_ROUTE ||
              path === routes.NOTICE_OF_PRIVACY_ROUTE ||
              path === routes.DELETE_YOU_ACCOUNT ||
              path === routes.WHITEPAPER_ROUTE ||
              path === routes.WELCOME_ROUTE ||
              path === routes.SELF_XSS_ROUTE ||
              path.split('/').slice(0, -1).join('/') === routes.WHITEPAPER_ROUTE ||
              matches) &&
            'none',
          backgroundColor: darkMode ? '#01132780' : '#FFF8',
          backdropFilter: 'blur(4px)',
        })}
      >
        <div
          className='NavBar__menu'
          style={{
            borderRight: `1px solid ${darkMode ? UNSATURATED_BLACK_BLUE : FEDERAL_WHITE}`,
          }}
        >
          <SideMenu />
        </div>
      </div>
      <div
        className='NavBar__content'
        style={Object.assign({
          width:
            (path === routes.FAQ_ROUTE ||
              path === routes.TERMS_AND_CONDITIONS_ROUTE ||
              path === routes.NOTICE_OF_PRIVACY_ROUTE ||
              path === routes.DELETE_YOU_ACCOUNT ||
              path === routes.WELCOME_ROUTE ||
              path === routes.SELF_XSS_ROUTE ||
              path === routes.WHITEPAPER_ROUTE ||
              path.split('/').slice(0, -1).join('/') === routes.WHITEPAPER_ROUTE ||
              matches) &&
            '100%',
          paddingTop:
            path === routes.WHITEPAPER_ROUTE ||
            path.split('/').slice(0, -1).join('/') === routes.WHITEPAPER_ROUTE ||
            path === routes.DELETE_YOU_ACCOUNT
              ? '0'
              : `${secondMatches ? 50 : 70}px`,
        })}
      >
        {children}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logOut: bindActionCreators(actions.logOut, dispatch),
  };
};

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
