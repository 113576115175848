import React from 'react';
import { FEDERAL_BLACK, FEDERAL_BLUE, FEDERAL_BLUE_STRONG } from '../../color-palatte';
import './customTextInput.css';
import { MentionsInput, Mention } from "react-mentions";
import classNames from './example.module.css'
import getUserBySearch from '../../getUserBySearch';
import PresidentImg from '../../../assets/ornament-profile/default_president.png';
import VicepresidentImg from '../../../assets/ornament-profile/default_vice-president.png';
import CitizenImg from '../../../assets/ornament-profile/default_citizen.png';
import { throttle } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../../redux/actions/user/actions';

class CustomTextInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      keyword: ""
    }
  }

  getUsers = throttle((value) => {
    const { saveMentions } = this.props;
    // console.log("this.reqTimer: ", this.reqTimer);
    // console.log("callback / keyword: ", keyword);
    const text = value.replace('@', '');
    // console.log("callback / text: ", text)
    getUserBySearch(text)
      .then(data => {
        // console.log("getUserBySearch / data: ", data)
        const mentions = [...data].map(item => { return { display: `@${item.username}`, id: item._id, ...item } })
        saveMentions(mentions)
      })
      .catch(err => {
        // console.log("getUserBySearch error: ", err);
      });
  }, 200);



  renderSuggestionsRow = (item, search, highlightedDisplay, index, focused) => {
    const { positions } = this.props;
    const position =
      positions &&
        positions.filter((position) => position.user._id === item._id).length >
        0
        ? positions.filter((position) => position.user._id === item._id)[0]
          .position
        : null;
    const positionIcon =
      position === 'president'
        ? PresidentImg
        : position === 'vicepresident'
          ? VicepresidentImg
          : CitizenImg;
    // console.log("renderSuggestionsRow / item: ", item)
    return (
      <div style={{ borderRadius: 20 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: 150,
        }}>
          <div style={{
            height: 45,
            width: 45,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <div style={{ position: 'relative', alignItems: 'center' }}>
              <img
                src={item && item.picture ? item.picture : positionIcon}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 40 / 2,
                  backgroundColor: FEDERAL_BLACK,
                  border: `solid ${FEDERAL_BLUE_STRONG}`
                }}
                alt=""
              />
            </div>
          </div>
          <div style={{
            flex: 1,
            justifyContent: 'center',
            paddingLeft: 10,
            paddingRight: 15
          }}>
            <p style={{
              fontSize: 13,
              fontWeight: '500',
              color: FEDERAL_BLUE_STRONG,
              fontFamily: `Source Sans Pro', sans-serif`,
              margin: 5
            }}>{item.name}</p>
            <p style={{
              fontSize: 12,
              fontWeight: '500',
              // color: 'rgba(0,0,0,0.6)',
              color: FEDERAL_BLUE_STRONG,
              fontFamily: `Source Sans Pro', sans-serif`,
              margin: 5
            }}>@{item.username}</p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      className,
      type,
      isWhite,
      inputClassName,
      style,
      placeholder,
      name,
      onChange,
      value,
      id,
      disabled,
      autoComplete,
      maxLength,
      onKeyPress,
      isTextArea,
      mentions,
      // user
    } = this.props;
    // const {
    //   users
    // } = this.state
    // console.log("users: ", users)
    // console.log("user: ", user)
    // console.log("mentions: ", mentions)
    return (
      <div className={`${className} ${isWhite ? 'CustomTextInput-Container-white' : 'CustomTextInput-Container'}`}>
        <MentionsInput
          type={type}
          id={id}
          className={`mentions ${isWhite ? 'CustomTextInput-white' : 'CustomTextInput'} ${inputClassName}`}
          style={Object.assign(
            {
              border: `2px solid ${isWhite ? 'white' : FEDERAL_BLUE}`,
              opacity: disabled ? '0.5' : 'unset',
              borderRadius: 12
            },
            style
          )}
          placeholder={placeholder}
          name={name}
          // className="mentions"
          classNames={classNames}
          a11ySuggestionsListLabel={"Suggested mentions"}
          value={value}
          onChange={(e) => {
            // console.log("e.target.value: ", e.target.value)
            const lastWord = e.target.value.split(" ").pop()
            // console.log("lastWord: ", lastWord)
            if (lastWord && lastWord[0] === "@") {
              this.getUsers(lastWord)
            }


            if (lastWord && lastWord.includes('@[@')) {
              var matches = lastWord.match(/\[(.*?)\]/);
              onChange({ target: { value: `${e.target.value.replace(lastWord, '')}${matches[1]}`  } })
            } else {
              onChange(e)
            }
          }}
          disabled={disabled}
          autoComplete={autoComplete}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
          singleLine={!isTextArea}
          allowSuggestionsAboveCursor
          >
          <Mention
          markup="@[__display__]"
            trigger="@"
            data={mentions}
            renderSuggestion={this.renderSuggestionsRow}
          />
        </MentionsInput>
      </div>
    );
  }
};

function mapDispatchToProps(dispatch) {
  return {
    saveMentions: bindActionCreators(actions.saveMentions, dispatch),
  };
}

function mapStateToProps(state) {
  const { user, darkMode, mentions } = state.user;
  return {
    user,
    darkMode,
    mentions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTextInput);