import moment from 'moment';
import React from 'react';
import i18n from '../../../i18n/i18n';
import getStat from '../../../utils/getStat';
import { isUserValid } from '../../../utils/isUserValid';
import { createChat, fetchUsersToChating, getChats, removeChatMessagesAction, removeChatAction, makeChatVisibleAction } from '../messagesActions';
import { throttle } from 'lodash';
import * as routes from '../../../routes/routes';
import './userChats.css';
import SearchInput from '../../../utils/app-inputs/search-input/searchInput';
import { AppLoader } from '../../../utils/animations/animations';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ChatItem from '../../../components/chats/chatItem';
import ScrollableDiv from '../../../components/scrollable-div/scrollableDiv';
import { WHITE_MAIN } from '../../../utils/color-palatte';
import { NotFoundText } from '../../../components/not-found-text/NotFoundText';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../../redux/actions/user/actions';
import Alert from '../../../utils/alert/Alert';

class UserChats extends React.Component {
  state = {
    search: '',
    // chats: [],
    fetching: false,
    skipItems: 0,
    limitItems: 5,
    plusPostNumber: 5,
    keepIncreasingCounter: false,
    usersForChating: [],
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      const isThereUnreadMessages = localStorage.getItem('isThereUnreadMessages');
      if (isThereUnreadMessages) {
        localStorage.removeItem('isThereUnreadMessages');
      }
    }

    isUserValid(false, this._isMounted);
    if (this._isMounted) await this.getStats();
    if (this._isMounted) this.fetchChats();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const positions = await getStat('positions');
        this.setState({ positions: positions });
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  fetchChats = async () => {
    const { skipItems, limitItems, fetching } = this.state;
    const { user, saveUserChats, chats = [] } = this.props;
    if (!fetching) {
      this.setState({ fetching: true }, async () => {
        try {
          const newChats = await getChats([user._id], skipItems, limitItems, user._id);
          // console.log('finalChats / newChats: ', newChats)
          const finalChats = newChats.chats
            .concat(chats)
            .filter(item => {
              // console.log("item.visibility: ", item.visibility.filter(item => item === user._id).length > 0)
              return item.visibility.filter(item => item === user._id).length > 0
            })
            .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
            .map((chat) => ({
              ...chat,
              unreadMessagesCount: newChats?.chatMessagesData?.filter((item) => item.chat === chat._id)[0]?.unreadMessages,
              lastMessage: newChats?.chatMessagesData?.filter((item) => item.chat === chat._id)[0]?.chatLastMessage,
            }))
            .sort((a, b) => moment(b.lastUpdateDate) - moment(a.lastUpdateDate));
          if (finalChats.length > 0) {
            saveUserChats(finalChats);
            this.setState({
              fetching: false,
              keepIncreasingCounter: newChats.length > 0,
              // chats: finalChats,
            });
          } else {
            this.fetchUsersForChating();
          }
        } catch (e) {
          // console.log("fetchChats / error: ", e)
          this.setState({ fetching: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
        }
      });
    }
  };

  fetchChatsHard = async (hard) => {
    if (!this._isMounted) return;
    if(hard){
      this.props.saveUserChats([]);
    }

    this.setState(
      {
        search: '',
        // chats: [],
        fetching: false,
        skipItems: 0,
        limitItems: 5,
        plusPostNumber: 5,
        keepIncreasingCounter: false,
        usersForChating: [],
      },
      async () => await this.fetchChats()
    );
  };

  onRefreshChats = () => {
    const { plusPostNumber } = this.state;
    const { saveUserChats } = this.props;
    saveUserChats([]);
    this.setState(
      {
        skipItems: 0,
        limitItems: plusPostNumber,
        usersForChating: [],
        // chats: [],
      },
      () => {
        this.getStats();
        this.fetchChats();
      }
    );
  };

  onEndReached = () => {
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchChats();
        }
      );
    } else {
      this.fetchChats();
    }
  };

  throtled = throttle(async () => {
    this.fetchUsersForChating();
  }, 0);

  fetchUsersForChating = async () => {
    const { skipItems, limitItems, /* fetching, */ search, usersForChating } = this.state;
    const { user, saveUserChats } = this.props;
    this.setState({ fetching: true }, async () => {
      try {
        const users = await fetchUsersToChating(user._id, skipItems, limitItems, search);
        // console.log("fetchUsersForChating / users: ", users)
        const finalUsers = usersForChating
          .concat(users)
          .map((item) => {
            return {
              user: {
                username: item.username,
                active: item.active,
                _id: item._id,
                isVerifiedUser: item.isVerifiedUser,
              },
              content: '',
              creationDate: '',
            };
          })
          .filter((item, index, self) => index === self.findIndex((t) => t.user._id === item.user._id) && item.user._id);
        // console.log("finalUsers: ", finalUsers)
        saveUserChats([]);
        this.setState({
          usersForChating: finalUsers,
          // chats: [],
          fetching: false,
          keepIncreasingCounter: finalUsers.length > 0,
        });
      } catch (e) {
        // console.log("fetchUsersForChating / error: ", e)
        this.setState({ fetching: false });
        const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
      }
    });
  };

  removeChatMessages = (chatId) => {
    this.setState({ fetching: true }, async () => {
      try {
        await removeChatMessagesAction(chatId);
        await this.fetchChatsHard()
        this.setState({ fetching: false });
        // this.showAlert(true, 'success', i18n.t('MESSAGES.chatRemoved'));
      } catch (e) {
        // console.log('removeChat / error: ', e)
        this.setState({ fetching: false });
        const error = e?.data?.error?.message?.toString() ?? i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
      }
    });
  };

  removeChat = (chatId) => {
    this.setState({ fetching: true }, async () => {
      try {
        await removeChatAction(chatId);
        await this.fetchChatsHard(true)
        this.setState({ fetching: false });
        // this.showAlert(true, 'success', i18n.t('MESSAGES.chatRemoved'));
      } catch (e) {
        // console.log('removeChat / error: ', e)
        this.setState({ fetching: false });
        const error = e?.data?.error?.message?.toString() ?? i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
      }
    });
  };

  goUserChat = (userId, chat) => {
    // console.log("goUserChat / chat: ", chat)
    // console.log("goUserChat / userId: ", userId)
    const query = new URLSearchParams(this.props.location.search);

    const post = query.get('post');
    const voting = query.get('voting');
    const votingHistory = query.get('voting-history');
    const law = query.get('law');
    const community = query.get('community');
    const deal = query.get('deal');
    const income = query.get('income');
    const expense = query.get('expense');
    const userRef = query.get('userRef');
    let param = ``;

    const searchParam = (name) => {
      return (
        `?${name}=` +
        {
          post: post,
          voting: voting,
          'voting-history': votingHistory,
          law: law,
          community: community,
          deal: deal,
          income: income,
          expense: expense,
          userRef: userRef,
        }[name]
      );
    };

    if (post) {
      param = searchParam('post');
    }
    if (voting) {
      param = searchParam('voting');
    }
    if (votingHistory) {
      param = searchParam('voting-history');
    }
    if (law) {
      param = searchParam('law');
    }
    if (community) {
      param = searchParam('community');
    }
    if (deal) {
      param = searchParam('deal');
    }
    if (income) {
      param = searchParam('income');
    }
    if (expense) {
      param = searchParam('expense');
    }
    if (userRef) {
      param = searchParam('userRef');
    }

    const { user } = this.props;
    if (chat && chat._id) {
      // CHAT COME FROM
      let url = `${routes.USER_CHAT}/${chat._id}/${param}`;
      // console.log("url: ", url)
      if (param)
        this.props.history.replace({
          pathname: url,
          state: chat,
        });
      else
        this.props.history.push({
          pathname: url,
          state: chat,
        });
    } else {
      this.setState({ fetching: true }, async () => {
        try {
          const userChats = await getChats([user._id, userId], 0, 5, user._id);

          // console.log("userChats: ", userChats);
          const savedMessagesExistChat =
            userChats?.chats?.length > 0
              ? userChats.chats?.filter(
                  (chat) =>
                    chat &&
                    chat.members &&
                    chat.members.length > 0 &&
                    chat.members.filter((member) => member._id === user._id).length > 1
                )
              : [];
          const existChat =
            userChats?.chats?.length > 0
              ? userChats.chats?.filter(
                  (chat) =>
                    chat &&
                    chat.members &&
                    chat.members.length > 0 &&
                    chat.members.filter((member) => member._id === userId).length > 0 &&
                    chat.members.filter((member) => member._id === user._id).length > 0
                )
              : [];
          // console.log("existChat: ", existChat)
          // if (userId === user._id && userChats.filter((chat) => chat.members.filter(member => member._id === userId).length > 1).length > 0) { //SAVED MESSAGES CHAT
          //   history.push(`${routes.USER_CHAT}/${existSavedMessagesChat[0]}/${param}`)
          // } else
          // console.log("existChat: ", existChat)
          if (userId !== user._id && existChat && existChat.length > 0 && existChat[0] && existChat[0]._id) {
            //VALIDATE IF CHAT EXIST
            this.setState({ search: '', fetching: false });
            await makeChatVisibleAction(existChat[0]._id)
            this.props.history.push(`${routes.USER_CHAT}/${existChat[0]._id}/${param}`);
          } else if (
            userId === user._id &&
            savedMessagesExistChat &&
            savedMessagesExistChat.length > 0 &&
            savedMessagesExistChat[0] &&
            savedMessagesExistChat[0]._id
          ) {
            //VALIDATE IF SAVE MESSAGES CHAT EXIST
            this.setState({ search: '', fetching: false });
            await makeChatVisibleAction(savedMessagesExistChat[0]._id)
            this.props.history.push(`${routes.USER_CHAT}/${savedMessagesExistChat[0]._id}/${param}`);
          } else {
            const members = [user._id, userId];
            const chatCreated = await createChat(members);
            // console.log("goUserChat / chatCreated: ", chatCreated.body[0]);
            this.setState({ search: '', fetching: false });
            this.props.history.push(`${routes.USER_CHAT}/${chatCreated.body[0]._id}/${param}`);
          }
        } catch (e) {
          // console.log("goUserChat / error: ", e)
          this.setState({ fetching: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
        }
      });
    }
  };

  handleInputChange = ({ target }) => {
    this.setState(
      {
        [target.name]: target.value,
      },
      () => {
        if (target.value.length > 0) {
          this.fetchUsersForChating();
        } else {
          this.setState({
            usersForChating: [],
          });
          this.onRefreshChats();
        }
      }
    );
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const { search, fetching, usersForChating, positions } = this.state;

    const { user, darkMode, chats } = this.props;

    // console.log('chats', chats);
    // console.log('usersForChating', usersForChating);

    return (
      <ScrollableDiv
        className='UserChats__Container'
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexFlow: 'column nowrap',
          maxWidth: '850px',
          width: '100%',
          margin: '0 auto',
        }}
        onEndReach={() => this.onEndReached()}
      >
        <SearchInput
          name='search'
          value={search}
          onChange={this.handleInputChange}
          placeholder={i18n.t('MESSAGES.searchPlaceholder')}
          darkMode={darkMode}
        />
        {fetching && chats?.length <= 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 140px)',
            }}
          >
            <AppLoader color={darkMode && WHITE_MAIN} />
          </div>
        ) : usersForChating.length > 0 ? (
          <>
            {usersForChating.map((item, idx) => (
              <ChatItem
                item={item}
                key={idx}
                positions={positions}
                darkMode={darkMode}
                goUserChat={this.goUserChat}
                userId={user._id}
                removeChatMessages={this.removeChatMessages}
                removeChat={this.removeChat}
              />
            ))}
            {fetching && <AppLoader color={darkMode && WHITE_MAIN} />}
          </>
        ) : chats?.length > 0 ? (
          <>
            {chats?.map((item, idx) => (
              <ChatItem
                item={item}
                key={idx}
                positions={positions}
                darkMode={darkMode}
                goUserChat={this.goUserChat}
                userId={user._id}
                removeChatMessages={this.removeChatMessages}
                removeChat={this.removeChat}
              />
            ))}
            <div style={{ height: 50 }}>{fetching && chats?.length <= 0 && <AppLoader color={darkMode && WHITE_MAIN} />}</div>
          </>
        ) : (
          <NotFoundText content={i18n.t('MESSAGES.chatsNotFound')} />
        )}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        />
      </ScrollableDiv>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, darkMode, chats } = state.user;
  return {
    user,
    darkMode,
    chats,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserChats: bindActionCreators(actions.saveUserChats, dispatch),
  };
};

const UserChatsWithRouter = withRouter(UserChats);

export default connect(mapStateToProps, mapDispatchToProps)(UserChatsWithRouter);
