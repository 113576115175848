import React from 'react';
import AlertModal from '../../../utils/modal/Modal';
import CloseIcon from '../../../assets/terms-modal/close-btn.svg';
import CloseIconWhite from '../../../assets/terms-modal/close-btn-white.svg';
import * as colors from '../../../utils/color-palatte';
import './previewChievModal.css';

export const PreviewChievModal = (props) => {
  const { isOpen, uri, onRequestClose, darkMode } = props;

  const [width, setWidth] = React.useState(0);

  const rvlRef = React.useRef();
  let observer = null;

  React.useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line
      observer = new ResizeObserver((entries) => {
        const boxElem = entries[0];
        setWidth(boxElem.contentRect.width);
      });
      if (observer) {
        observer.observe(rvlRef.current);
      }
    }
    return () => {
      if (isOpen) {
        if (observer) {
          observer.disconnect();
        }
      }
    };
  }, [isOpen, rvlRef]);

  return (
    <AlertModal
      ref={rvlRef}
      isOpen={isOpen}
      overlayClassName='PreviewChievModal__Overlay'
      modalClassName='PreviewChievModal__Conainer'
      style={{ backgroundColor: darkMode ? colors.UNSATURATED_BLACK_BLUE : colors.WHITE_MAIN, height: width }}
      outsideClick={onRequestClose}
    >
      <img
        src={darkMode ? CloseIconWhite : CloseIcon}
        alt='close'
        className='PreviewChievModal__CloseBtn'
        onClick={onRequestClose}
      />
      <img src={uri} className='PreviewChievModal__Image' alt='' />
    </AlertModal>
  );
};
