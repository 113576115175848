import React from "react";
import AlertModal from "../../../utils/modal/Modal";
import Character from "../../../assets/contribution/ainstain-comment.png";
import { Translation } from "react-i18next";
import "./new-contribution-modal.css";
import { useState } from "react";
import ContributionPaymentMethodModal from "../payment-method-modal/ContributionPaymentMethodModal";
import CreditCardPaymentModal from "../credit-card-payment-modal/CreditCardPaymentModal";
import ContributionDoneModal from "../contribution-done-modal/ContributionDoneModal";
import CloseIconWhite from "../../../assets/terms-modal/close-btn-white.svg";

const NewContributionModal = ({ isOpen, onRequestClose }) => {
  const [isPatmentMethodModal, setIsPatmentMethodModal] = useState(false);
  const [isAddCreditCardModal, setIsAddCreditCardModal] = useState(false);
  const [isPaymentReceivedModal, setPaymentReceivedModal] = useState(false);

  // console.log("store.getState().user: ", store.getState().user)
  return (
    <Translation>
      {(t) => (
        <>
          <AlertModal
            isOpen={isOpen}
            modalClassName="NewContributionPayment__Container"
            overlayClassName="NewPayment__Overlay"
            outsideClick={onRequestClose}
          >
            <img
              src={CloseIconWhite}
              onClick={onRequestClose}
              alt=""
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: 25,
                height: 25,
                margin: '10px 10px 0 0',
                cursor: 'pointer'
              }}
            />
            <img src={Character} alt="" className="NewPayment__img" />
            <div className="NewContributionPayment__Content">
              <h1>{t("CONTRIBUTION.adviceTitle")}</h1>
              <p>{t("CONTRIBUTION.adviceContent")}</p>
              <button
                onClick={() => {
                  setIsPatmentMethodModal(true);
                }}
              >
                {t("PAYMENTS_MODALS.continue")}
              </button>
            </div>
          </AlertModal>
          {isPatmentMethodModal && (
            <ContributionPaymentMethodModal
              isOpen={isPatmentMethodModal}
              onCreditCardClick={() => {
                setIsPatmentMethodModal(false);
                setIsAddCreditCardModal(true);
              }}
              onRequestClose={() => setIsPatmentMethodModal(false)}
            />
          )}
          {isAddCreditCardModal && (
            <CreditCardPaymentModal
              isOpen={isAddCreditCardModal}
              onRequestClose={() => setIsAddCreditCardModal(false)}
              onFinishPayment={() => {
                setIsAddCreditCardModal(false);
                setPaymentReceivedModal(true);
              }}
            />
          )}
          {isPaymentReceivedModal && (
            <ContributionDoneModal
              isOpen={isPaymentReceivedModal}
              onRequestClose={() => setPaymentReceivedModal(false)}
              onFinishPayment={() => {
                setPaymentReceivedModal(false);
                onRequestClose();
              }}
            />
          )}
        </>
      )}
    </Translation>
  );
};

export default NewContributionModal;
