import i18n from "../i18n/i18n";

const handleDealTypeName = type => {
  if (type === "capital-gain") {
    return i18n.t('VOTING.REQUEST.capitalProfitType')
  }
  if (type === "service") {
    return i18n.t('VOTING.REQUEST.serviceType')
  }
  if (type === "both") {
    return i18n.t('VOTING.REQUEST.bothType')
  }
  return i18n.t('VOTING.REQUEST.bothType')
}

export default handleDealTypeName