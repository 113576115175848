import React from 'react';
// import {
//   View,
//   TouchableOpacity,
//   Text,
// } from 'react-native';
// import * as SecureStore from 'expo-secure-store';
import { EASY_BLUE, FEDERAL_GREEN_WEAK, FEDERAL_BLACK, FEDERAL_BLUE } from '../../utils/color-palatte';
import "./style.css";
// import { Button } from "native-base";
import InvoiceIcon from "../../assets/user-finance/icon-invoice.svg"
import moment from "moment"
import handlePaymentType from '../../utils/handlePaymentType';
import handlePaymentStatus from '../../utils/handlePaymentStatus';
import thousandSeparator from '../../utils/thousandSeparator';
import handlePaymentTypeDescription from '../../utils/handlePaymentTypeDescription';
// import { navigate } from '../../utils/root-container';
// import Loader from '../loader/Loader';
// import RNHTMLtoPDF from 'react-native-html-to-pdf';
// import { paymentInvoice } from './paymentInvoice';
// import Share from 'react-native-share';
import { PROFILE_ROUTE, USER_PAYMENT_DETAILS } from '../../routes/routes';
import { AppLoader } from '../../utils/animations/animations';
import i18n from '../../i18n/i18n';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './paymentInvoice';

class PaymentItem extends React.PureComponent {

  state = {
    activeSlide: 0,
    isVotingMenuOpen: false,
  }

  goUserProfile = async (id) => {
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId === id) {
      this.props.historyObj.push(PROFILE_ROUTE)
    } else {
      this.props.historyObj.push(`${PROFILE_ROUTE}/${id}`)
    }
  };

  createPDF = async (item) => {

    //  try { 
    //    const { user } = this.props;
    //     let options = {
    //       html: paymentInvoice(item, user),
    //       fileName: `gg-payment-${item.paymentIntent.id}`,
    //       directory: 'Documents',
    //       base64: true
    //     };
    //     let file = await RNHTMLtoPDF.convert(options)
    //     const b64 = `data:application/pdf;base64,${file.base64}`
    //     await Share.open({ url: b64 });
    //   } catch(e){
    //     // console.log("createPDF / error: ", e)
    //   }
  }

  render() {
    const {
      item,
      user,
      isDetails,
      cancelPayment,
      cancelLoading,
      fetching,
    } = this.props;

    return (
      <div
        onClick={() => this.props.historyObj.push(`${USER_PAYMENT_DETAILS}/${item._id}`)}
        className='votingCardContainer'
        style={{ backgroundColor: item.status === "succeeded" || item.status === "requires_payment_method" ? FEDERAL_GREEN_WEAK : EASY_BLUE }}
      >
        <div className='votingContentContainer'>
          <img alt="" src={InvoiceIcon} style={{ width: 60, height: 60 }} />
          {user
            ? <div style={{ maxWidth: '70%' }}>
              <p className='paymentItemTitle' style={{ marginLeft: 10, fontSize: 13 }}>{`${i18n.t('PAYMENTS_LIST.type')}`}</p>
              <p className='votingProposalText'>{handlePaymentType(item.type)}</p>
              <p className='postCreationDate' style={{ color: FEDERAL_BLACK }}>{moment(item.date).format('MM-DD-YYYY hh:mm A')}</p>
            </div>
            : null}
        </div>
        <div className='line' />
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p className='votingProposalFrom' style={{ marginLeft: 10 }}>{i18n.t('PAYMENTS_LIST.status')}</p>
            <p className='votingProposalFrom'>{handlePaymentStatus(item.status)}</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p className='votingProposalFrom' style={{ marginLeft: 10 }}>{i18n.t('PAYMENTS_LIST.id')}</p>
            <p className='votingProposalFrom'>{item.paymentIntent.id}</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p className='votingProposalFrom' style={{ marginLeft: 10 }}>{i18n.t('PAYMENTS_LIST.description')}</p>
            <p className='votingProposalFrom'>{handlePaymentTypeDescription(item.type)}</p>
          </div>
        </div>
        <div className='line' />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <p className='paymentItemTitle' style={{ marginRight: 5 }}>{`${i18n.t('PAYMENTS_LIST.amountTitle')} `}</p>
          <p className='amountStyle'>{`${thousandSeparator(item.paymentIntent.amount / 100)}$`}</p>
        </div>
        {!fetching && isDetails && item.status === "requires_confirmation" && cancelPayment
          ? cancelLoading
            ? <AppLoader style={{ margin: "auto" }} size="small" color={FEDERAL_BLUE} />
            : <button
              className='Profile__Route__Button'
              disabled={cancelLoading}
              onClick={() => {
                cancelPayment(item._id)
              }}
            >
              <p>{i18n.t('PAYMENTS_LIST.cancelPayment')}</p>
            </button>
          : null}
        {(isDetails && !fetching && (item.status === "succeeded" || item.status === "requires_payment_method")) && <>
          <ReactToPrint
            documentTitle={`gg-payment-${item.paymentIntent.id}`}
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.
              return <button
                className='Profile__Route__Button'
              // onClick={() => this.createPDF(item)}
              >
                <p>{i18n.t('PAYMENTS_LIST.download')}</p>
              </button>;
            }}
            content={() => this.componentRef}
          />
          <div style={{ display: 'none' }}>
            <ComponentToPrint item={item} user={user} ref={el => (this.componentRef = el)} />
          </div>
        </>}
        {!isDetails && <button
          className='Profile__Route__Button'
          onClick={() => this.props.historyObj.push(`${USER_PAYMENT_DETAILS}/${item._id}`)}
        >
          <p>{i18n.t('PAYMENTS_LIST.seeDetails')}</p>
        </button>}
      </div >
    )
  }
}

export default PaymentItem;