import { postData } from '../../fetch';

export const login = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/user/login-web', data, false);
      // console.log("login ")
      resolve(response);
    } catch (e) {
      // console.log("login action error: ", e);
      reject(e);
    }
  });
};

export const resendToken = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = { email: email };
      const response = await postData('/user/resend-token-web', data, false);
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};

export const sendVerificationCode = (data) => 
  new Promise(async(resolve, reject) => {
    try {
      const response = await postData('/user/send-verification-code-web', data);
      resolve(response);
    } catch (e) {
      // console.log('sendVerificationCode / e:', e)
      reject(e)
    }
  })

export const validateVerificationCode = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/user/validate-verification-code-web', data);
      resolve(response);
    } catch (e) {
      // console.log('validateVerificationCode / e:', e);
      reject(e);
    }
  });
