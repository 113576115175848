import { getData, postData, putData } from "../../../fetch";

export const getUsernameAvailability = (username) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/user/get-username-availability-web/${username}`);
      // console.log("getUsernameAvailability / response: ", response);
      resolve(response.body);
    } catch (e) {
      reject(e);
    }
  })
}

export const changePassword = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await putData(`/user/edit-password-web`, data);
      // console.log("changePassword / response: ", response);
      resolve(response.body);
    } catch (e) {
      reject(e);
    }
  })
}

export const changeEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(`/user/edit-email-web`, data);
      // console.log("changePassword / response: ", response);
      resolve(response.body);
    } catch (e) {
      // const error = e?.data?.error?.message ?? e?.data?.error ?? e
      // console.log('changePassword / error: ', error)
      reject(e);
    }
  })
}

export const changeEmailFinal = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData(`/user/change-email-web`, data);
      // console.log("changeEmailFinal / response: ", response);
      resolve(response.body);
    } catch (e) {
      // console.log("changeEmailFinal / error: ", e);
      reject(e);
    }
  })
}