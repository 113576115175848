import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n/i18n';
import Einstein from '../../../assets/home/influencer-carousel/Einstein.png';
import Hawking from '../../../assets/home/influencer-carousel/Hawking.png';
import LeftArrow from '../../../assets/home/influencer-carousel/left_arrow.svg';
import RightArrow from '../../../assets/home/influencer-carousel/right_arrow.svg';
import './influencer-carousel.css';

const InfluencerCarousel = () => {

  const CardsInfluencer = ({ image, text, influencer }) => (
    <div className='CardsInfluencer'>
      <div className='influencerOpinion'>
        <img src={image} alt='' draggable='false' />
        <p>
          "{text}"<br />
          <span>{influencer}</span>
        </p>
      </div>
    </div>
  );

  const ArrowChild = ({ img }) => (
    <img src={img} className='InfluencerCarousel__arrow' alt='' />
  );

  const Arrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <ArrowChild img={LeftArrow} />
      ) : (
        <ArrowChild img={RightArrow} />
      );
    return (
      <button
        className='InfluencerCarousel__btn'
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };

  const Influyents = [
    {
      image: Einstein,
      text: i18n.t('albertDialog'),
      influencer: 'Albert Einstein',
    },
    {
      image: Hawking,
      text: i18n.t('hawkingDialog'),
      influencer: 'Stephen Hawking',
    },
  ];

  return (
    <Translation>
      {(t) => (
        <div className='InfluencerContainer'>
          <Carousel renderArrow={Arrow} pagination={false}>
            {Influyents.map((items, n) => (
              <CardsInfluencer {...items} key={n} />
            ))}
          </Carousel>
          <svg viewBox='0 0 100 100' preserveAspectRatio='none'>
            <polygon className='diagonal2' points='0,0 110,110 0,110' />
          </svg>
        </div>
      )}
    </Translation>
  );
};

export default InfluencerCarousel;
