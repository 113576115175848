import React from 'react';
import { PrivacyEn, PrivacyEs } from '../../components/terms-modal/terms-content/terms-content';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { FEDERAL_BLACK_LIGHT, FEDERAL_BLUE, DARK_OFFICIAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import './noticeOfPrivacy.css';

const NoticeOfPrivacy = ({ user, darkMode }) => {
  const language = i18n.language;

  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 500px)').matches);

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 500px)').addEventListener('change', handler);
    return () => window.matchMedia('(max-width: 500px)').removeEventListener('change', handler);
  });

  return (
    <Translation>
      {(t) => (
        <div
          className='NoticePrivacy__Container'
          style={{ backgroundColor: user && darkMode ? DARK_OFFICIAL_BLUE : WHITE_MAIN, padding: `0 ${matches ? 20 : 40}px` }}
        >
          <h1 style={{ color: user && darkMode ? WHITE_MAIN : FEDERAL_BLUE, fontSize: `${matches ? 1.5 : 2}rem` }}>
            {t('NOTICE_OF_PRIVACY.title')}
          </h1>
          <div
            style={{
              borderBottom: `1px solid ${user && darkMode ? WHITE_MAIN : FEDERAL_BLUE}`,
              width: '100%',
              height: '1px',
            }}
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              maxWidth: '1000px',
              margin: '0 auto',
            }}
          >
            {language === 'es' ? (
              <PrivacyEs
                style={{
                  color: user && darkMode ? WHITE_MAIN : FEDERAL_BLACK_LIGHT,
                  boxSizing: 'border-box',
                  padding: `0 ${matches ? 10 : 20}px`,
                }}
              />
            ) : (
              <PrivacyEn
                style={{
                  color: user && darkMode ? WHITE_MAIN : FEDERAL_BLACK_LIGHT,
                  boxSizing: 'border-box',
                  padding: `0 ${matches ? 10 : 20}px`,
                }}
              />
            )}
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeOfPrivacy);
