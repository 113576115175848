import React from 'react';
import styles from './selfXss.module.css';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as colors from '../../utils/color-palatte';
import { Title } from '../../components/whitepaper/utils/utils';
import { SELF_XSS_ROUTE } from '../../routes/routes';
import { useAlert } from '../../hooks/useAlert';

const SelfXss = (props) => {
  const { darkMode, userHasEntered } = props;
  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 650px)').matches);

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 650px)').addEventListener('change', handler);
    return () => window.matchMedia('(max-width: 650px)').removeEventListener('change', handler);
  });

  const { t } = useTranslation();

  const alert = useAlert();

  const onCopy = () => {
    alert('success', t('DASHBOARD.shareUrl'));
    navigator.clipboard.writeText(`${window.location.origin}${SELF_XSS_ROUTE}`);
  };

  return (
    <div
      className={styles.Container}
      style={{
        backgroundColor: userHasEntered && darkMode ? colors.DARK_OFFICIAL_BLUE : colors.WHITE_MAIN,
        minHeight: userHasEntered ? 'unset' : 'calc(100vh - 70px)',
      }}
    >
      <Title
        text={t('SELF_XSS.title')}
        style={{ margin: '20px 0', fontSize: `${matches ? 0.8 : 1}rem` }}
        onClick={onCopy}
        darkMode={userHasEntered && darkMode}
      />
      <div
        style={{
          width: '100%',
          borderBottom: `1px solid ${darkMode ? colors.FEDERAL_BLUE_SOFT : colors.FEDERAL_BLUE}`,
          height: 1,
          maxWidth: 1000,
        }}
      />
      <div className={styles.Content__Container}>
        {/* <SubTitle
          text={t('SELF_XSS.shareBtn')}
          onClick={() => onCopy()}
          color={darkMode ? colors.FEDERAL_BLUE_SOFT : colors.FEDERAL_BLUE}
        /> */}
        <p className={styles.Content} style={{ color: darkMode && userHasEntered ? colors.WHITE_MAIN : colors.GRAY_SECOND }}>
          {t('SELF_XSS.content')}
        </p>
      </div>
    </div>
  );
};

const mapState = (state) => {
  const { darkMode, userHasEntered } = state.user;
  return {
    darkMode,
    userHasEntered,
  };
};

export default connect(mapState, null)(SelfXss);
