import { getData, putData } from '../../fetch';

export const getUser = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/user/get-user-web/${userId}`);
      const user = response.body[0];
      return resolve(user);
    } catch (e) {
      return reject(e);
    }
  });
};

export const followUser = (follower, followed) => {
  const data = {
    follower,
    followed
  }
  return new Promise(async (resolve, reject) => {
    try {
      await putData(`/user/follow-user-web`, data);
      resolve();
    } catch (e) {
      reject(e);
    }
  })
}

export const reportUser = (user, reporter) => {
  const data = {
    user,
    reporter
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await putData(`/user/report-user-web`, data);
      resolve(response.body);
    } catch (e) {
      reject(e);
    }
  })
}

export const blockGeneralFunc = (user, targetUser) => {
  const data = {
    user,
    targetUser,
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await putData(`/user/block-user-general-web`, data);
      resolve(response.body);
    } catch (e) {
      reject(e);
    }
  })
}

export const getUserTotalContribution = (userId) => {
  return new Promise(async (resolve, reject) => {
      try {
          const response = await getData(`/user/get-user-contribution-web/?userId=${userId}`);
          const amount = response.body;
          return resolve(amount);
      } catch (e) {
          return reject(e);
      }
  })
};