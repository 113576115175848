import React, { useMemo } from 'react';
import AlertModal from '../../../utils/modal/Modal';
// import VisaIcon from '../../../assets/payment-modals/visa_icon.svg';
// import MasterCardIcon from '../../../assets/payment-modals/mastercard_icon.svg';
// import AmericanIcon from '../../../assets/payment-modals/american_express_icon.svg';
import CloseBtn from '../../../assets/payment-modals/close_btn.svg';
import '../../payment-modals/add-credit-card-modal/addCreditCardModal.css';
// import RegularInput from '../../../utils/app-inputs/regular-input/regular-input';
// import DateInput from '../../../utils/app-inputs/date-input/date-input';
import { 
  // FEDERAL_GREEN, 
  WHITE_MAIN } from '../../../utils/color-palatte';
import { Translation } from 'react-i18next';
import { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import {
  Elements,
  useStripe,
  useElements,
  // ElementsConsumer,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
// import getStat from '../../../utils/getStat';
import { AppLoader } from '../../../utils/animations/animations';
import { useState } from 'react';
import i18n from '../../../i18n/i18n';
import {
  createPaymentIntent,
  createPayment,
  validatePendingPayments,
} from '../../payment-modals/add-credit-card-modal/credit-card-method-actions';
import { store } from '../../../redux/configureStore';
import { STRIPE_PUBLISHABLE_KEY } from '../../../config';
import useResponsiveFontSize from '../../payment-modals/add-credit-card-modal/useResponsiveFontSize';
import Alert from '../../../utils/alert/Alert';
// import { CheckoutForm } from './checkoutForm';
import { useHistory } from 'react-router-dom';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const AddCreditCardModal = ({ onFinishPayment, onRequestClose }) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertContent, setAlertContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState('');
  const [taxes, setTaxes] = useState('');
  const history = useHistory()
  // const [maintenanceTaxes, setMaintenanceTaxes] = useState('');
  // const [presidentSalaryPorcentage, setPresidentSalaryPorcentage] =
  //   useState('');
  // const [vicepresidentSalaryPorcentage, setVicepresidentSalaryPorcentage] =
  //   useState('');
  const [totalCharge, setTotalCharge] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  // const [paymentAccessDays, setPaymentAccessDays] = useState('');
  // eslint-disable-next-line
  const [payLoading, setPayLoading] = useState(false);
  const [currentComponentToRender, setCurrentComponentToRender] =
    useState(null);

  useEffect(() => {
    initializePaymentSheet();
    // eslint-disable-next-line
  }, []);

  const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }),
      [fontSize]
    );

    return options;
  };

  const SplitForm = () => {
    const { user } = store.getState().user;
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const [loader, setLoader] = useState(false);

    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      setLoader(true);
      // console.log("clientSecret: ", clientSecret)
      const createPaymentIntentRes = await createPaymentIntent({
        userId: user._id,
        type: "capital-contribution",
        platform: "web"
      });
      // console.log(
      //   'createPaymentIntentId / createPaymentIntentRes: ',
      //   createPaymentIntentRes
      // );
      const client_secrets = createPaymentIntentRes.body.client_secret;
      // console.log('client_secrets: ', client_secrets);
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        String(client_secrets),
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            type: 'card',
          },
        }
      );

      // console.log('confirmCardPayment error:', error);
      // console.log('confirmCardPayment error:', paymentIntent);
      await onSubmit(error, paymentIntent, client_secrets);
      setLoader(false);
    };

    return (
      <form className='credit-card-form' onSubmit={handleSubmit}>
        <label className='AddCreditCard__Label'>
          {i18n.t('PAYMENTS_MODALS.ADD_CREDIT_CARD.cardNumber')}
          <CardNumberElement
            className='AddCreditCard__Input'
            options={options}
            // onReady={() => {
            //   console.log('CardNumberElement [ready]');
            // }}
            // onChange={(event) => {
            //   console.log('CardNumberElement [change]', event);
            // }}
            // onBlur={() => {
            //   console.log('CardNumberElement [blur]');
            // }}
            // onFocus={() => {
            //   console.log('CardNumberElement [focus]');
            // }}
          />
        </label>
        <label className='AddCreditCard__Label'>
          {i18n.t('PAYMENTS_MODALS.ADD_CREDIT_CARD.expirationDate')}
          <CardExpiryElement
            className='AddCreditCard__Input'
            options={options}
            // onReady={() => {
            //   console.log('CardNumberElement [ready]');
            // }}
            // onChange={(event) => {
            //   console.log('CardNumberElement [change]', event);
            // }}
            // onBlur={() => {
            //   console.log('CardNumberElement [blur]');
            // }}
            // onFocus={() => {
            //   console.log('CardNumberElement [focus]');
            // }}
          />
        </label>
        <label className='AddCreditCard__Label'>
          {i18n.t('PAYMENTS_MODALS.ADD_CREDIT_CARD.cvc')}
          <CardCvcElement
            className='AddCreditCard__Input'
            options={options}
            // onReady={() => {
            //   console.log('CardNumberElement [ready]');
            // }}
            // onChange={(event) => {
            //   console.log('CardNumberElement [change]', event);
            // }}
            // onBlur={() => {
            //   console.log('CardNumberElement [blur]');
            // }}
            // onFocus={() => {
            //   console.log('CardNumberElement [focus]');
            // }}
          />
        </label>
        <button
          className='pay-button'
          type='submit'
          disabled={!stripe || loader}
        >
          {!loader ? (
            i18n.t('PAYMENTS_MODALS.ADD_CREDIT_CARD.pay')
          ) : (
            <AppLoader color={WHITE_MAIN} size='small' />
          )}
        </button>
      </form>
    );
  };

  // const getStats = () => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       // const positions = await getStat('positions');
  //       // const laws = await getStat('laws');
  //       // const users = await getStat('users');
  //       // const deals = await getStat('deals');
  //       // const capital = await getStat('capital');
  //       // const officialVotings = await getStat('official-votings');
  //       const paymentAccessDays = await getStat('payment-access-days');
  //       const maintenanceTaxes = await getStat('maintenance-taxes');
  //       const presidentSalaryPorcentage = await getStat(
  //         'president-salary-porcentage'
  //       );
  //       const vicepresidentSalaryPorcentage = await getStat(
  //         'vicepresident-salary-porcentage'
  //       );
  //       setPresidentSalaryPorcentage(presidentSalaryPorcentage);
  //       setVicepresidentSalaryPorcentage(vicepresidentSalaryPorcentage);
  //       // console.log("officialVotings: ", officialVotings);
  //       setMaintenanceTaxes(maintenanceTaxes);
  //       setPaymentAccessDays(paymentAccessDays);
  //       resolve();
  //     } catch (e) {
  //       resolve();
  //       // console.log("getStats / error: ", e);
  //     }
  //   });
  // };

  const createPaymentIntentId = () => {
    const { user } = store.getState().user;
    return new Promise(async (resolve, reject) => {
      try {
        const createPaymentIntentRes = await createPaymentIntent({
          userId: user._id,
          type: "capital-contribution",
          platform: "web"
        });
        // console.log(
        //   'createPaymentIntentId / createPaymentIntentRes: ',
        //   createPaymentIntentRes
        // );
        const client_secret = createPaymentIntentRes.body.client_secret;
        // console.log("createPaymentIntentId / client_secret: ", client_secret)
        setClientSecret(client_secret);
        setService(createPaymentIntentRes.body.amount);
        setTaxes(createPaymentIntentRes.body.taxes);
        setTotalCharge(createPaymentIntentRes.body.total);
        resolve();
        setTimeout(() => {
          setLoading(false);
          setCurrentComponentToRender(<SplitForm />);
        }, 600);
      } catch (e) {
        reject(e);
        // console.log("createPaymentIntent / e: ", e)
      }
    });
  };

  const initializePaymentSheet = async () => {
    // await getStats();
    await validatePendingPayments(history, onRequestClose)
    await createPaymentIntentId();
    // console.log("initializePaymentSheet / clientSecret: ", client_secret);
    // const { error } = await initPaymentSheet({
    //   // customerId: customer,
    //   // customerEphemeralKeySecret: ephemeralKey,
    //   paymentIntentClientSecret: client_secret,
    // });
    // console.log("initPaymentSheet / error: ", error)
    // if (!error) {
    //   setLoading(false)
    // } else {
    //   setIsAlertOpen(true)
    //   setAlertType("error")
    //   setAlertContent(error.message && error.code ? `${error.message}, code: ${error.code}` : i18n.t('GENERAL_ERRORS.serverError'))
    // }
  };

  // const { isOpen, onRequestClose, service, taxes /* isSelected */ } = props;

  const onSubmit = (error, paymentIntent, client_secrets) => {
    return new Promise(async (resolve, reject) => {
      const { user } = store.getState().user;
      if (
        error ||
        (paymentIntent &&
          paymentIntent.status &&
          paymentIntent.status !== 'succeeded' &&
          paymentIntent.status !== 'processing') ||
        !paymentIntent
      ) {
        setIsAlertOpen(true);
        setAlertType('wrong');
        if (error.code === 'Canceled') {
          setLoading(false);
          return false;
        }

        if (error.code === 'account_country_invalid_address') {
          setAlertContent(
            i18n.t('PAYMENTS_ERRORS.account_country_invalid_address')
          );
        }
        if (error.code === 'account_number_invalid') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.account_number_invalid'));
        }
        if (error.code === 'balance_insufficient') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.balance_insufficient'));
        }
        if (error.code === 'bank_account_declined') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.bank_account_declined'));
        }
        if (error.code === 'bank_account_unusable') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.bank_account_unusable'));
        }
        if (error.code === 'card_decline_rate_limit_exceeded') {
          setAlertContent(
            i18n.t('PAYMENTS_ERRORS.card_decline_rate_limit_exceeded')
          );
        }
        if (error.code === 'card_declined') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.card_declined'));
        }
        if (error.code === 'card_declined') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.card_declined'));
        }
        if (error.code === 'country_code_invalid') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.country_code_invalid'));
        }
        if (error.code === 'expired_card') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.expired_card'));
        }
        if (error.code === 'incorrect_address') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.incorrect_address'));
        }
        if (error.code === 'incorrect_cvc') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.incorrect_cvc'));
        }
        if (error.code === 'incorrect_number') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.incorrect_number'));
        }
        if (error.code === 'incorrect_zip') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.incorrect_zip'));
        }
        if (error.code === 'invalid_characters') {
          setAlertContent(i18n.t('PAYMENTS_ERRORS.invalid_characters'));
        }
        if (error.message) {
          setAlertContent(error.message);
        } else {
          setAlertContent(i18n.t('GENERAL_ERRORS.serverError'));
        }

        // setMessageModalType("error")
        // setIsMessageModalOpen(true)
        setPayLoading(false);
        resolve();
        // initializePaymentSheet()

        // console.log(`Error code: ${error.code} ${error.message}`);
      } else {
        await createPayment({
          type: "capital-contribution",
          amount: null,
          userId: user._id,
          paymentIntent: paymentIntent,
          clientSecret: client_secrets,
          platform: "web"
        });
        setPayLoading(false);
        onFinishPayment();
        resolve();
        // props.navigation.navigate(PAYMENT_RECEIVED)
      }
    });
  };
  // eslint-disable-next-line
  // const [isSelected, setIsSelected] = React.useState(false);

  // const capitalGain =
  //   100 -
  //   (Number(maintenanceTaxes) +
  //     Number(presidentSalaryPorcentage) +
  //     Number(vicepresidentSalaryPorcentage));
  // const totalMantenance =
  //   Number(maintenanceTaxes) +
  //   Number(presidentSalaryPorcentage) +
  //   Number(vicepresidentSalaryPorcentage);

  // const appearance = {
  //   theme: 'stripe',
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };
  // console.log("client secret: ", clientSecret)
  return (
    <Translation>
      {(t) => (
        <AlertModal
          isOpen={true}
          modalClassName='AddCreditCard__Container'
          overlayClassName='AddCreditCard__Overlay'
          outsideClick={onRequestClose}
        >
          <div className='AddCreditCard__Head'>
            <div className='AddCreditCard__Header'>
              <h1>{t('PAYMENTS_MODALS.ADD_CREDIT_CARD.title')}</h1>
              <img
                src={CloseBtn}
                style={{ right: 0, top: 0, left: 'unset' }}
                alt='close'
                onClick={onRequestClose}
              />
            </div>
            <div className='AddCreditCard__Bill'>
              <div className='AddCreditCard__Service'>
                <p
                  style={{
                    borderBottom: '1px solid #FFF',
                    marginBottom: '10px',
                    paddingBottom: '5px',
                  }}
                >
                  {t(`CONTRIBUTION.amountTitle`)}
                  <br />
                  <span>{`${service || '...'}$`}</span>
                </p>
                <p>
                  {t('PAYMENTS_MODALS.ADD_CREDIT_CARD.taxes')}
                  <br />
                  <span>{taxes || '...'}$</span>
                </p>
              </div>
              <div className='AddCreditCard__Total'>
                <p>
                  {t('PAYMENTS_MODALS.ADD_CREDIT_CARD.totalCharge')}
                  <br />
                  <span>{totalCharge || '...'}$</span>
                </p>
              </div>
            </div>
            {/* <div className='AddCreditCard__Support'>
              {!isSelected && (
                <>
                  <p>{t('PAYMENTS_MODALS.ADD_CREDIT_CARD.supportedCards')}:</p>
                  <div className='AddCreditCard__Img'>
                    <img src={VisaIcon} alt='' />
                    <img src={MasterCardIcon} alt='' />
                    <img src={AmericanIcon} alt='' />
                  </div>
                </>
              )}
              {isSelected && (
                <p
                  style={{
                    color: FEDERAL_GREEN,
                    margin: '0px',
                    marginTop: '5px',
                  }}
                >
                  {t('PAYMENTS_MODALS.ADD_CREDIT_CARD.aceptedCC')}
                </p>
              )}
            </div> */}
          </div>
          <p className='text-inform'>
            {t('CONTRIBUTION.beforePayText')}
          </p>
          {/* <div className='AddCreditCard__Form'>
            {!isSelected && (
              <form action=''>
                <RegularInput
                  placeholder={t('PAYMENTS_MODALS.ADD_CREDIT_CARD.formCCN')}
                />
                <DateInput
                  title={t('PAYMENTS_MODALS.ADD_CREDIT_CARD.formET')}
                />
                <RegularInput
                  placeholder={t('PAYMENTS_MODALS.ADD_CREDIT_CARD.formANC')}
                />
                <RegularInput
                  placeholder={t('PAYMENTS_MODALS.ADD_CREDIT_CARD.formBA')}
                />
              </form>
            )}
            <button
              // onClick={!isSelected ? () => setIsSelected(true) : onRequestClose}
            >
              {!isSelected
                ? t('PAYMENTS_MODALS.ADD_CREDIT_CARD.btnACC')
                : t('PAYMENTS_MODALS.ADD_CREDIT_CARD.payNow')}
            </button>
          </div> */}
          <div className='AddCreditCard__Form'>
            {!loading && clientSecret ? (
              <Elements stripe={stripePromise}>
                {currentComponentToRender}
              </Elements>
            ) : (
              <AppLoader />
            )}
            {/* {!loading && clientSecret
              ? <Elements options={options} stripe={stripePromise}>
              <CheckoutForm clientSecret={clientSecret} onSubmit={onSubmit} loading={payLoading} setLoading={setPayLoading} />
            </Elements>
              : <AppLoader />} */}
          </div>
          <Alert
            isOpen={isAlertOpen}
            type={alertType}
            content={alertContent}
            onRequestClose={() => setIsAlertOpen(false)}
          />
        </AlertModal>
      )}
    </Translation>
  );
};

export default AddCreditCardModal;
