import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import VotingRequestsIcon from '../../assets/votes/votin_requests_icon.svg';
import VotingOfficialIcon from '../../assets/votes/votin_oficial_icon.svg';
import VoteItem from '../../components/votes/voteItem';
import BGImg from '../../assets/votes/create_vote_BG.svg';
import * as routes from '../../routes/routes';
import { isUserValid } from '../../utils/isUserValid';
import getStat from '../../utils/getStat';
import { FEDERAL_BLUE, FEDERAL_GREEN, WHITE_MAIN } from '../../utils/color-palatte';
import './voting.css';
import thousandSeparator from '../../utils/thousandSeparator';
import { Warning } from '../../components/warning/Warning';
import i18n from '../../i18n/i18n';
import { Trans } from 'react-i18next';
import { BSpan } from '../../utils/text-utils/text-utils';
import InfoModal from '../../components/info-modals/info-modals';

class Voting extends React.Component {
  state = {
    loading: false,
    officialVoting: null,
    noOfficialVoting: null,
    isVisibleModal: false,
    modal: {
      isOpen: false,
      type: null,
    }
  };

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    if (this._isMounted) localStorage.removeItem('isThereUnreadVotings');
    isUserValid(false, this._isMounted);
    if (this._isMounted) this.getStats();
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      this.setState({ loading: true }, async () => {
        try {
          const officialVoting = await getStat('official-votings');
          const noOfficialVoting = await getStat('no-official-votings');
          const minVotingAssistance = await getStat('min-voting-assistance');
          const timeToApproveVoting = await getStat('time-to-approve-voting');
          this.setState({
            officialVoting,
            noOfficialVoting,
            loading: false,
            refresh: false,
            minVotingAssistance,
            timeToApproveVoting,
          });
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.setState({ isVisibleModal: false });
  };

  openModal = (isOpen, type) => {
    this.setState({
      modal: {
        isOpen: isOpen,
        type: type,
      }
    });
  };

  render() {
    const {
      loading,
      officialVoting,
      noOfficialVoting,
      timeToApproveVoting,
      modal,
      minVotingAssistance,
      // timeToApproveVoting,
    } = this.state;

    // const { darkMode } = this.props;

    // const query = (q) => new URLSearchParams({ isOfficial: q }).toString();

    const VotesArray = [
      {
        img: VotingRequestsIcon,
        text: i18n.t('VOTING_GENERAL.votingRequest'),
        counter: noOfficialVoting
          ? thousandSeparator(Number(noOfficialVoting)) || 0
          : 0,
        onClick: () =>
          this.props.history.push(
            `${routes.VOTING_LIST_ROUTE}/?type=voting-request`
          ),
      },
      {
        img: VotingOfficialIcon,
        text: i18n.t('VOTING_GENERAL.voting'),
        counter: officialVoting
          ? thousandSeparator(Number(officialVoting)) || 0
          : 0,
        onClick: () =>
          this.props.history.push(
            `${routes.VOTING_LIST_ROUTE}/?type=official`
          ),
      },
    ];
    return (
      <div className='Votes__Container'>
        {VotesArray.map((votes, i) => (
          <VoteItem style={{ cursor: 'pointer' }} {...votes} loader={loading} key={i} />
        ))}
        <VoteItem
          style={{
            justifyContent: 'center',
            backgroundColor: FEDERAL_BLUE,
            backgroundImage: `url(${BGImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% center',
          }}
          text={i18n.t('VOTING_GENERAL.makeVoting')}
          linked
          navigation={routes.CREATE_VOTE_ROUTE}
          color={WHITE_MAIN}
        />
        <Warning
          darkMode={true}
          title={i18n.t('VOTING_GENERAL.warningTitle')}
          content={<Trans i18nKey='VOTING_GENERAL.warningInfo'>
            bla
            <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'voting')}>bla</BSpan>
            bla
            <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'voting')}>bla</BSpan>
            bla
            {timeToApproveVoting ? timeToApproveVoting : "..."}
            bla
            <BSpan style={{ color: FEDERAL_GREEN, fontWeight: 'bold' }} onClick={() => this.openModal(true, 'voting')}>bla</BSpan>
            bla
            {minVotingAssistance ? minVotingAssistance : "..."}
            bla
          </Trans>}
        />
        <InfoModal
          isOpen={modal.isOpen}
          type={modal.type}
          onRequestClose={() => this.setState({ modal: { isOpen: false, type: null, } })}
        />
      </div>
    );
  }
}

const VotingWithRouter = withRouter(Voting);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VotingWithRouter);
