import React, { useEffect, useRef, useMemo } from 'react';
import { throttle } from 'lodash'

const ScrollableDivNested = (props) => {
  const elem = useRef();

  // const [scrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (elem && elem.current) {
      elem.current.addEventListener('scroll', listenToScroll);
      // onScroll();
      // console.log(elem);
    }
    return () => {
      if (elem?.current) {
        // eslint-disable-next-line
        elem.current.removeEventListener('scroll', listenToScroll);
        // console.log(elem);
      }
    };
    // eslint-disable-next-line
  }, [elem]);

  const eventHandler = () => {
    onEndReach()
  };

  const throttledEventHandler = useMemo(
    () => throttle(eventHandler, 2000)
    // eslint-disable-next-line
  , []);

  const listenToScroll = () => {
    if (elem) {
      // setIsScrolled(true);
      const winScroll = elem.current.scrollTop;

      const height = reversed
        ? elem.current.clientHeight - elem.current.scrollHeight
        : elem.current.scrollHeight - elem.current.clientHeight;

      const scrolled = winScroll / height;

      if (scrolled >= 0.98) {
        if (typeof onEndReach === 'function') {
          throttledEventHandler();
        }
      }
    }
  };

  // const onScroll = () => {
  //   if (elem) {
  //     if (!scrolled) {
  //       elem.current.scrollTop = elem.current.scrollHeight;
  //     }
  //   }
  // };

  const { className, reversed, style, children, onEndReach } = props;

  return (
    <div
      ref={elem}
      className={`ScrollableDivNested__Container ${className}`}
      style={Object.assign(
        {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexFlow: `${reversed ? 'column-reverse' : 'column'} nowrap`,
          overflow: 'auto',
        },
        style
      )}
    >
      {children}
    </div>
  );
};

export default ScrollableDivNested;
