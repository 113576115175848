import { getData } from "../../fetch";

export const getLaws = ({userId, skipItems, limitItems, search, admin, sinceDate, toDate}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/laws/get-laws-web/?userId=${userId}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&admin=${admin}&sinceDate=${sinceDate}&toDate=${toDate}`);
      // console.log("getPosts / response: ", response);
      const laws = response.body;
      return resolve(laws);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  })
};

export const getLawDetails = (lawId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(`/laws/get-law-details-web/?lawId=${lawId}`);
      // console.log("getVotingDetails / response: ", response);
      const voting = response.body;
      return resolve(voting);
    } catch (e) {
      // console.log("getVotingDetails / error: ", e);
      return reject(e);
    }
  })
};