import { firebase } from '../firebase/config';

export const saveFile = (
  name,
  uri, // File or Blob
  type,
  folder = 'posts'
) => {
  // console.log("saveimage uri: ", uri);
  // console.log("saveimage name: ", name);
  return new Promise(async (resolve, reject) => {
    try {
      const storageRef = firebase.storage().ref();
      // Create the file metadata
      var metadata = {
        contentType: type,
      };
      const response = await fetch(uri);
      const blob = await response.blob();
      // console.log("saveImage / blob: ", blob)
      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef.child(`${folder}/${name}`);
      await uploadTask.put(blob, metadata);
      const downloadURL = await uploadTask.getDownloadURL();
      // console.log('File available at', downloadURL);
      resolve({ type, uri: downloadURL });
    } catch (e) {
      // console.log("uploadTask.put() / error: ", e);
      reject(e);
    }
  });
};

export const saveFilesToCloud = (files) => {
  // console.log("saveFilesToCloud / files: ", files);
  if (files.length > 0) {
    let promiseArray = [];

    // eslint-disable-next-line
    files.map((file) => {
      promiseArray.push(
        saveFile(Math.random(), file.uri || file.path, file.type, 'posts')
      );
    });

    return Promise.all(promiseArray);
  } else {
    return Promise.resolve(files);
  }
};

export default saveFilesToCloud;
