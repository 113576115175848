import { getData } from '../fetch';

const getStatPublic = (name, exact = true, user = '') => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/globalgovernment/get-global-government-stat-public-web/?name=${name}&exact=${exact}&user=${user}`
      );
      // console.log('getStat / response: ', response);
      const body = response.body;
      return resolve(body);
    } catch (e) {
      return reject(e);
    }
  });
};

export default getStatPublic;
