import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import getStat from '../../utils/getStat';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { bindActionCreators } from 'redux';
import { getVotingDetails, getVotingHistoryDetails } from '../../pages/voting-list/votingListActions';
import { getPost, createPost } from '../dashboard/dashboard-actions';
import { getLawDetails } from '../../components/laws-list/lawsListActions';
import { getDealDetails } from '../../components/deals-list/dealsListActions';
import { getIncomeDetails } from '../../components/incomes-list/incomesListActions';
import { getExpenseDetails } from '../../components/expenses-list/expensesListActions';
import { getUser } from '../profile/profileActions';
import { getCommunity } from '../../components/community-profile/communityProfileActions';
import { FEDERAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import updateDashboardPosts from '../../utils/refreshDashboardPost';
import { ThumbVideo, ThumbImage } from '../../utils/thumbnails/thumbVideo';
import saveFilesToCloud from '../../utils/saveFilesToCloud';
import moment from 'moment';
import i18n from '../../i18n/i18n';
import * as routes from '../../routes/routes';
import WarningIcon from '../../assets/general/warning-icon.svg';
import DeleteIcon from '../../assets/create-post/delete_icon.svg';
import CammeraIcon from '../../assets/create-post/camera_roll_icon.svg';
import VideoIcon from '../../assets/create-post/video_icon.svg';
import ImageIcon from '../../assets/create-post/image_icon.svg';
import PlusIcon from '../../assets/create-post/plus_icon.svg';
import { PreviewModal, WebCamModal } from '../../components/create-post/video-modal/previewModal';
import Alert from '../../utils/alert/Alert';
import './createPost.css';
import Resizer from 'react-image-file-resizer';
import { AppLoader } from '../../utils/animations/animations';
import { getBase64Size } from '../../utils/getBase64File';
import CustomTextInput from '../../utils/app-inputs/custom-text-input/CustomTextInput';
import Postcard from '../../components/post/postcard';
import VotingItem from '../../components/voting/votingItem';
import UserRef from '../../components/user-ref/UserRef';
import CommunityItem from '../../components/community/communityItem';
import IncomesItem from '../../components/incomes-list/incomesItem';

const TrashButton = ({ className, onClick }) => <img src={DeleteIcon} alt='' className={className} onClick={onClick} />;

const CreateButton = ({ camera, containerStyle, style, src, id, onClick, onChange, accept, plus, text, textStyle }) => {
  const [checkDevice, setCheckDevice] = useState(false);

  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    if (isMobile) setCheckDevice(true);
    else setCheckDevice(false);
  }, []);

  return (
    <div
      className='CreatePost__Button-container'
      style={Object.assign({ display: camera ? checkDevice && 'none' : undefined }, containerStyle)}
    >
      {camera ? (
        <button className='CreatePost__Button' onClick={onClick} style={style}>
          <img src={src} alt='' className='CreatePost__Img' />
          {plus && (
            <div className='CreatePost__Plus-logo'>
              <img src={PlusIcon} alt='' />
            </div>
          )}
        </button>
      ) : (
        <label
          htmlFor={id}
          onChange={onChange}
          className='CreatePost__Button'
          style={Object.assign({ position: 'relative', cursor: 'pointer' }, style)}
        >
          <img src={src} alt='' className='CreatePost__Img' />
          <input
            type='file'
            id={id}
            onClick={onClick}
            accept={accept}
            hidden
            multiple
            style={{ position: 'absolute', opacity: '0', pointerEvents: 'none' }}
          />
          {plus && (
            <div className='CreatePost__Plus-logo'>
              <img src={PlusIcon} alt='' />
            </div>
          )}
        </label>
      )}
      {text && <p style={textStyle}>{text}</p>}
    </div>
  );
};

class CreatePost extends React.Component {
  constructor(props) {
    super(props);
    this.camRef = React.createRef();
    this.mediaRecordRef = React.createRef();
    this.state = {
      title: '',
      description: '',
      files: [],
      posts: [],
      loader: true,
      showCameraModal: false,
      isThereUnreadVotings: null,
      fetching: false,
      votingHistoryItem: null,
      votingItem: null,
      postItem: null,
      lawItem: null,
      dealItem: null,
      userRefItem: null,
      communityItem: null,
      stats: null,
      matches: window.matchMedia('(max-width: 800px)').matches,
      capturing: false,
      recordedChunks: [],
      checkDevice: false,
    };
  }

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const post = url.searchParams.get('post');
    const voting = url.searchParams.get('voting');
    const law = url.searchParams.get('law');
    const income = url.searchParams.get('income');
    const expense = url.searchParams.get('expense');
    const userRef = url.searchParams.get('userRef');
    const votingHistory = url.searchParams.get('voting-history');
    const community = url.searchParams.get('community');
    const deal = url.searchParams.get('deal');
    // console.log('post: ', post);
    if (this._isMounted) {
      const isThereUnreadVotings = localStorage.getItem('isThereUnreadVotings');
      this.setState({ isThereUnreadVotings });
    }
    if (this._isMounted) await this.getStats();
    if (this._isMounted) {
      if (voting) {
        this.fetchVoting();
        return;
      }
      if (votingHistory) {
        this.fetchVotingHistory();
        return;
      }
      if (post) {
        this.fetchPost();
        return;
      }
      if (law) {
        this.fetchLaw();
        return;
      }
      if (deal) {
        this.fetchDeal();
        return;
      }
      if (income) {
        this.fetchIncome();
        return;
      }
      if (expense) {
        this.fetchExpense();
        return;
      }
      if (userRef) {
        this.fetchUserRef();
        return;
      }
      if (community) {
        this.fetchCommunity();
        return;
      }
    }
    if (this._isMounted)
      window.matchMedia('(max-width: 850px)').addEventListener('change', (e) => this.setState({ matches: e.matches }));
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    if (this.state.loader) {
      this.setState({ loader: false });
    }
  };

  onPostCreated = async (postId) => {
    const { saveUserPosts, user } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    const communityPost = query.get('communityPost');
    saveUserPosts([]);
    const posts = await updateDashboardPosts();
    const finalPosts = posts
      .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
      .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
    saveUserPosts(finalPosts);

    if (communityPost) {
      this.props.history.replace(
        `${routes.COMMUNITY_POSTS_ROUTE}/${user.community && user.community._id ? user.community._id : user.community}`
      );
    } else {
      this.props.history.replace(`${routes.POST_ROUTE}/${postId}`);
    }
  };

  createPost = () => {
    const { description, title, files, imageMaxSize, videoMaxSize, maxFilesPost } = this.state;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const communityPost = url.searchParams.get('communityPost');
    const post = url.searchParams.get('post');
    const voting = url.searchParams.get('voting');
    const law = url.searchParams.get('law');
    const income = url.searchParams.get('income');
    const expense = url.searchParams.get('expense');
    const userRef = url.searchParams.get('userRef');
    const votingHistory = url.searchParams.get('voting-history');
    const community = url.searchParams.get('community');
    const deal = url.searchParams.get('deal');
    const filesTooHeavy = files
      .slice(0, Number(maxFilesPost))
      .filter(
        (file) =>
          (file.type.split('/')[0] === 'image' && file.sizeInMb > imageMaxSize) ||
          (file.type.split('/')[0] === 'video' && file.sizeInMb > videoMaxSize)
      );
    // console.log('filesTooHeavy: ', filesTooHeavy);
    // console.log('files', this.state.files);
    if (title && title.trim() && filesTooHeavy.length === 0) {
      this.setState({ loader: true }, async () => {
        try {
          const user = localStorage.getItem('userId');
          // console.log("createPost / savedFiles: ", savedFiles);
          let data = {
            description,
            title: title.trim(),
            files: '',
            user,
            voting: voting ?? undefined,
            community: community ?? undefined,
            votingHistory: votingHistory ?? undefined,
            post: post ?? undefined,
            law: law ?? undefined,
            deal: deal ?? undefined,
            expense: expense ?? undefined,
            income: income ?? undefined,
            userRef: userRef ?? undefined,
            communityPost: communityPost ?? undefined,
          };
          if (!voting && !votingHistory && !post && !law && !deal && !income && !expense && !userRef && !community) {
            const savedFiles = await saveFilesToCloud(files.slice(0, Number(maxFilesPost)));
            data.files = savedFiles;
          }
          // console.log('createPost / data: ', data);
          const response = await createPost(data);
          // console.log("createPost / response: ", response);
          if (response.status === 201) {
            this.clearPost();
            // console.log('createPost / response.body: ', response.body);
            this.onPostCreated(response.body);
          }
        } catch (e) {
          // console.log('createPost / error: ', e);
          const error = e?.data?.error?.details?.message ?? e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.setState({ loader: false });
          if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
            this.props.openVerifyModal();
            return;
          }
          this.showAlert(true, 'error', error);
        }
      });
    } else {
      if (filesTooHeavy.length > 0) {
        this.showAlert(true, 'error', i18n.t('GENERAL_ERRORS.filesTooHeavy'));
        return false;
      }
      this.showAlert(true, 'warning', i18n.t('GENERAL_ERRORS.missingFields'));
    }
  };

  clearPost = () => {
    this.setState({
      description: '',
      title: '',
      files: [],
      selectedPhotos: null,
    });
  };

  // getLocalStream = () => {
  //   navigator.getUserMedia(
  //     { audio: true, video: true },
  //     function (stream) {
  //       stream.getTracks().forEach((x) => x.stop());
  //     },
  //     (err) => console.log(err)
  //   );
  // };

  webCamCapture = async () => {
    if (this.camRef.current !== null) {
      this.setState({ webcamformat: 'image/jpeg' });
      let item = [];
      let screenshot = [];
      let finalScreenshot = [];
      const imgSrc = this.camRef.current.getScreenshot();
      const url = this.dataURLtoFile(imgSrc, `FILE${String(Math.random()).replace('0.', '')}`);
      // console.log('url', url);
      item.push(url);
      const arr = Array.from(item);
      arr.forEach((file) => {
        screenshot.push(this.fileToDataUri(file));
      });
      const result = await Promise.all(screenshot);
      result.forEach((res) => {
        finalScreenshot.push(res);
      });
      // console.log('screenshot', finalScreenshot);
      this.setState(
        {
          files: [...this.state.files, ...finalScreenshot],
          isCamOpen: false,
        }
        // console.log('files', this.state.files)
      );
    }
  };

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  startCapturing = () => {
    this.setState({ capturing: true });
    this.mediaRecordRef.current = new MediaRecorder(this.camRef.current.stream, {
      mimeType: 'video/webm',
    });
    this.mediaRecordRef.current.addEventListener('dataavailable', this.handleDataAvilable);
    this.mediaRecordRef.current.start();
    // console.log('mediaRecordRef', this.mediaRecordRef.current);
  };

  handleDataAvilable = async ({ data }) => {
    if (data.size > 0) {
      const uri = await this.blobToBase64(data);
      const sizeInMb = getBase64Size(uri);
      // console.log("test: ", uri.split(','))
      const finalUri = `data:video/mp4;base64,${uri.split(',')[1]}`;
      const file = {
        uri: finalUri,
        name: `VID${String(Math.random()).replace('0.', '')}`,
        type: 'video',
        folder: 'posts',
        sizeInMb: sizeInMb,
      };
      // console.log('file', file);
      const resultData = [...this.state.files].concat(file);
      // console.log(`{data}, ${resultData}`);
      this.setState({
        files: resultData,
      });
    }
  };

  stopCapture = () => {
    // console.log('end capturing');
    this.mediaRecordRef.current.stop();
    this.setState({ capturing: false, isCamOpen: false });
  };

  blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      try {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          resolve(reader.result);
        };
      } catch (e) {
        reject(e);
      }
    });

  // ROUTE PARAMS

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const positions = await getStat('positions');
        const users = await getStat('users');
        const imageMaxSize = await getStat('image-max-size-mb');
        const videoMaxSize = await getStat('video-max-size-mb');
        const maxFilesPost = await getStat('max-files-post');
        const positionsPeriodDays = await getStat('position-expires-period-days');
        const votingExpiresPeriodDays = await getStat('voting-expires-period-days');
        // console.log("getStats / response: ", response);
        this.setState({
          stats: { positions, users },
          imageMaxSize,
          videoMaxSize,
          maxFilesPost,
          positionsPeriodDays,
          votingExpiresPeriodDays,
          loader: false,
        });
        resolve();
      } catch (e) {
        resolve();
        // console.log("getStats / error: ", e);
      }
    });
  };

  fetchVoting = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const voting = url.searchParams.get('voting');
      // console.log("fetchPost / votingId: ", votingId)
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getVotingDetails(voting);
            // console.log("getVotingDetails / response: ", response);
            this.setState({
              fetching: false,
              votingItem: response && response.length > 0 ? response[0] : [],
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  fetchVotingHistory = () => {
    return new Promise(async (resolve, reject) => {
      const query = new URLSearchParams(this.props.location.search);
      const votingHistory = query.get('voting-history');
      // console.log("fetchVotingHistory / votingHistory: ", votingHistory)
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getVotingHistoryDetails(votingHistory);
            // console.log("fetchVotingHistory / response: ", response);
            this.setState({
              fetching: false,
              votingHistoryItem: response && response.length > 0 ? response[0] : [],
            });

            // console.log('votingItem', this.state.votingHistoryItem)
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchVotingHistory / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  fetchPost = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const post = url.searchParams.get('post');
      // console.log("fetchPost / votingId: ", votingId)
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getPost(post);
            // console.log("getVotingDetails / response: ", response);
            this.setState({
              fetching: false,
              postItem: response && response.length > 0 ? response[0] : [],
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  fetchLaw = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const law = url.searchParams.get('law');
      // console.log("fetchPost / votingId: ", votingId)
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getLawDetails(law);
            // console.log("getVotingDetails / response: ", response);
            this.setState({
              fetching: false,
              lawItem: response && response.length > 0 ? response[0] : [],
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  fetchDeal = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const deal = url.searchParams.get('deal');
      // console.log("fetchPost / votingId: ", votingId)
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getDealDetails(deal);
            // console.log("getVotingDetails / response: ", response);
            this.setState({
              fetching: false,
              dealItem: response && response.length > 0 ? response[0] : [],
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  fetchIncome = () => {
    return new Promise(async (resolve, reject) => {
      const { fetching } = this.state;
      const url_string = window.location.href;
      const url = new URL(url_string);
      const income = url.searchParams.get('income');
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getIncomeDetails(income);
            // console.log("fetchPost / post: ", post);
            this.setState({
              fetching: false,
              incomeItem: response[0],
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  fetchExpense = () => {
    return new Promise(async (resolve, reject) => {
      const { fetching } = this.state;
      const url_string = window.location.href;
      const url = new URL(url_string);
      const expense = url.searchParams.get('expense');
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getExpenseDetails(expense);
            // console.log("fetchPost / post: ", post);
            this.setState({
              fetching: false,
              expenseItem: response[0],
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  fetchUserRef = () => {
    return new Promise(async (resolve, reject) => {
      const { fetching } = this.state;
      const url_string = window.location.href;
      const url = new URL(url_string);
      const userRef = url.searchParams.get('userRef');
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getUser(userRef);
            // console.log("fetchUserRef / user: ", response);
            this.setState({
              fetching: false,
              userRefItem: response,
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  fetchCommunity = () => {
    return new Promise(async (resolve, reject) => {
      // console.log("fetchPost / votingId: ", votingId)
      const url_string = window.location.href;
      const url = new URL(url_string);
      const community = url.searchParams.get('community');
      const { fetching } = this.state;
      if (!fetching) {
        this.setState({ fetching: true }, async () => {
          try {
            const response = await getCommunity(community);
            // console.log("getVotingDetails / response: ", response);
            this.setState({
              fetching: false,
              communityItem: response ? response : [],
            });
            resolve();
          } catch (e) {
            reject(e);
            // console.log("fetchPost / error: ", e)
            this.setState({ fetching: false });
            const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
          }
        });
      }
    });
  };

  removeFile = (uri) => {
    this.setState({
      files: this.state.files.filter((file) => file.uri !== uri),
    });
  };

  handleFileInput = async (e) => {
    let promisesArr = [];
    let finalFiles = [];
    // handle validations
    // onFileSelect(e.target.files[0])
    // e.target.files[0]
    // console.log('files', e.target.files);
    const arr = Array.from(e.target.files);
    // console.log('arr', arr);

    arr.forEach((file) => {
      promisesArr.push(this.fileToDataUri(file));
    });
    // console.log('promisesArr', promisesArr);
    const result = await Promise.all(promisesArr);
    // console.log('result', result);
    result.forEach((res) => {
      finalFiles.push(res);
    });
    // console.log('finalfiles', finalFiles, 'files', this.state.files);
    // console.log('arr', arr);
    this.setState(
      {
        files: [...this.state.files, ...finalFiles],
      }
      // async () => {
      //   const { files } = this.state
      //   const response = await saveFilesToCloud(files);
      //   console.log('response', response);
      // }
    );
  };

  fileToDataUri = (file) =>
    new Promise((resolve, _) => {
      // console.log("fileToDataUri / file: ", file)
      const fileType = file.type.split('/')[0].toLowerCase();
      const extension = file.type.split('/')[1].toLowerCase();
      // console.log('fileToDataUri / file: ', file);
      if (fileType === 'image') {
        Resizer.imageFileResizer(
          file,
          730,
          500,
          extension === 'png' ? 'PNG' : extension === 'webp' ? 'WEBP' : 'JPEG',
          80,
          0,
          (file) => {
            // console.log("imageFileResizer / file: ", file);
            const reader = new FileReader();
            reader.onload = (event) => {
              const uri = event.target.result;
              const sizeInMb = getBase64Size(uri);
              // console.log('uri', uri);
              resolve({
                name: file.name,
                uri: uri,
                type: file.type,
                folder: 'posts',
                sizeInMb: sizeInMb,
              });
            };
            reader.readAsDataURL(file);
          },
          'file'
        );
        // new Compressor(file, {
        //   quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        //   success: (compressedResult) => {
        //     // compressedResult has the compressed file.
        //     // Use the compressed file to upload the images to your server.
        //     console.log("Compressor / compressedResult: ", compressedResult)
        //     const reader = new FileReader();
        //     reader.onload = (event) => {
        //       const uri = event.target.result;
        //       const sizeInMb = this.getBase64Size(uri);
        //       console.log('uri', uri);
        //       resolve({
        //         name: compressedResult.name,
        //         uri: uri,
        //         type: compressedResult.type,
        //         folder: 'posts',
        //         sizeInMb: sizeInMb,
        //       });
        //     };
        //     reader.readAsDataURL(compressedResult);
        //   },
        // });
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const uri = event.target.result;
          const sizeInMb = getBase64Size(uri);
          // console.log('uri', uri);
          const finalUri = `data:video/mp4;base64,${uri.split(',')[1]}`;
          resolve({
            name: file.name,
            uri: finalUri,
            type: 'video',
            folder: 'posts',
            sizeInMb: sizeInMb,
          });
        };
        reader.readAsDataURL(file);
      }
    });

  handleInputChange = ({ target }) => {
    this.setState({
      title: target.value,
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const {
      title,
      // description,
      loader,
      fetching,
      // showCameraModal,
      files,
      votingItem,
      votingHistoryItem,
      // stats,
      postItem,
      lawItem,
      dealItem,
      incomeItem,
      expenseItem,
      userRefItem,
      communityItem,
      imageMaxSize,
      videoMaxSize,
      matches,
      maxFilesPost,
      stats,
      positionsPeriodDays,
      votingExpiresPeriodDays,
    } = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const communityPost = url.searchParams.get('communityPost');
    const post = url.searchParams.get('post');
    const voting = url.searchParams.get('voting');
    const law = url.searchParams.get('law');
    const income = url.searchParams.get('income');
    const expense = url.searchParams.get('expense');
    const userRef = url.searchParams.get('userRef');
    const votingHistory = url.searchParams.get('voting-history');
    const community = url.searchParams.get('community');
    const deal = url.searchParams.get('deal');

    const { darkMode, user } = this.props;

    // console.log('files / render', this.state.files);
    // console.log('title: ', this.state.title);

    return (
      <Translation>
        {(t) => (
          <div className='CreatePost__Container'>
            {loader ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '100px',
                  width: '100%',
                }}
              >
                <AppLoader color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
              </div>
            ) : (
              <div className='CreatePost__Content'>
                <h1 className='CreatePost__Title' style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE }}>
                  {communityPost ? t('CREATE_POST.communityTitle') : t('CREATE_POST.title')}
                </h1>
                <div
                  style={{
                    width: '100%',
                    borderBottom: `2px solid ${darkMode ? WHITE_MAIN : FEDERAL_BLUE}`,
                  }}
                />
                <div className='CreatePost__ModalItemContainer'>
                  {files?.length > 0 ? (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        justyfyContent: 'flex-start',
                        alignItems: 'center',
                        borderBottom: `2px solid ${darkMode ? WHITE_MAIN : FEDERAL_BLUE}`,
                      }}
                    >
                      {files &&
                        files.slice(0, Number(maxFilesPost)).map((file, idx) => {
                          const isTooHeavy =
                            (file.type.split('/')[0] === 'image' && file.sizeInMb > imageMaxSize) ||
                            (file.type.split('/')[0] === 'video' && file.sizeInMb > videoMaxSize);
                          return (
                            <div className='CreatePost__SelectImg-Container' key={idx}>
                              <TrashButton className='CreatePost__DeleteIcon' onClick={() => this.removeFile(file.uri)} />
                              {file.type.split('/')[0] === 'video' ? (
                                <ThumbVideo
                                  source={file.uri}
                                  onClick={() =>
                                    this.setState({
                                      isOpenPreview: true,
                                      modalFile: file,
                                    })
                                  }
                                />
                              ) : (
                                <ThumbImage
                                  source={file.uri}
                                  onClick={() =>
                                    this.setState({
                                      isOpenPreview: true,
                                      modalFile: file,
                                    })
                                  }
                                />
                              )}
                              {isTooHeavy && <img src={WarningIcon} className='CreatePost__SelectImg-warning' alt='' />}
                              <p className='CreatePost__Select-counter'>{idx + 1}</p>
                            </div>
                          );
                        })}
                    </div>
                  ) : null}
                  {!voting && !votingHistory && !post && !law && !deal && !income && !expense && !userRef && !community && (
                    <div className='CreatePost__Buttons-area'>
                      <p
                        style={{
                          fontSize: '1.1rem',
                          color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                          marginTop: '20px',
                          marginBottom: '10px',
                          display: files?.length < Number(maxFilesPost) ? 'block' : 'none',
                        }}
                      >
                        {files?.length > 0 ? t('CREATE_POST.moreMultimedia') : t('CREATE_POST.multimedia')}
                      </p>
                      <div
                        className='CreatePost__Buttons-content'
                        style={matches ? { justifyContent: 'center' } : { justifyContent: 'flex-start' }}
                      >
                        {files?.length === 0 || files.length < Number(maxFilesPost) ? (
                          <>
                            {!this.state.checkDevice && (
                              <CreateButton
                                camera
                                src={CammeraIcon}
                                plus={files?.length > 0}
                                text={t('CREATE_POST.camera')}
                                textStyle={{
                                  color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                                }}
                                containerStyle={{
                                  margin: '0',
                                  marginRight: '30px',
                                }}
                                onClick={() =>
                                  this.setState({
                                    isCamOpen: true,
                                  })
                                }
                              />
                            )}
                            <CreateButton
                              id='upload_Video'
                              src={VideoIcon}
                              plus={files?.length > 0}
                              text={t('CREATE_POST.video')}
                              textStyle={{
                                color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                              }}
                              accept='video/*'
                              onChange={(e) => this.handleFileInput(e)}
                            />
                            <CreateButton
                              id='upload_Image'
                              plus={files?.length > 0}
                              src={ImageIcon}
                              text={t('CREATE_POST.photo')}
                              textStyle={{
                                color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                              }}
                              containerStyle={{
                                margin: '0',
                                marginLeft: '30px',
                              }}
                              accept='image/*'
                              onChange={(e) => this.handleFileInput(e)}
                            />
                          </>
                        ) : (
                          <p style={{ color: '#C00' }}>{t('CREATE_POST.limitReached')}</p>
                        )}
                      </div>
                    </div>
                  )}
                  <CustomTextInput
                    // name='title'
                    value={title}
                    className='CreatePost__TextArea'
                    style={{ height: '150px' }}
                    placeholder={`${t('CREATE_POST.textArea')}`}
                    isWhite={darkMode}
                    onChange={(e) => this.handleInputChange(e)}
                    isTextArea
                  />
                  {post ? (
                    !fetching && postItem && stats && stats.positions ? (
                      <Postcard
                        item={postItem}
                        user={user}
                        darkMode={darkMode}
                        positions={stats.positions}
                        removeRefers
                        onClick={() => this.props.history.push(`${routes.POST_ROUTE}/${postItem._id}`)}
                      />
                    ) : postItem === [] ? (
                      <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                        {i18n.t('CREATE_POST_MODAL.postNotFound')}
                      </p>
                    ) : (
                      <AppLoader
                        style={{ margin: '20px auto' }}
                        // size="large"
                        color={darkMode ? WHITE_MAIN : FEDERAL_BLUE}
                      />
                    )
                  ) : null}
                  {deal ? (
                    !fetching && dealItem && stats ? (
                      <VotingItem
                        item={dealItem}
                        user={user}
                        voting={stats}
                        darkMode={darkMode}
                        positionsPeriodDays={positionsPeriodDays}
                        votingExpiresPeriodDays={votingExpiresPeriodDays}
                        isDone
                        isDeal
                        onClick={() => this.props.history.push(`${routes.DEALS_DETAILS_ROUTE}/${dealItem._id}`)}
                        hideMenu
                      />
                    ) : dealItem === [] ? (
                      <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                        {i18n.t('CREATE_POST_MODAL.dealNotFound')}
                      </p>
                    ) : (
                      <AppLoader
                        style={{ margin: '20px auto' }}
                        // size="large"
                        color={darkMode ? WHITE_MAIN : FEDERAL_BLUE}
                      />
                    )
                  ) : null}
                  {law ? (
                    !fetching && lawItem && stats ? (
                      <VotingItem
                        item={lawItem}
                        user={user}
                        voting={stats}
                        darkMode={darkMode}
                        positionsPeriodDays={positionsPeriodDays}
                        votingExpiresPeriodDays={votingExpiresPeriodDays}
                        isDone
                        isLaw
                        onClick={() => this.props.history.push(`${routes.LAWS_DETAILS_ROUTE}/${lawItem._id}`)}
                        hideMenu
                      />
                    ) : lawItem === [] ? (
                      <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                        {i18n.t('CREATE_POST_MODAL.lawNotFound')}
                      </p>
                    ) : (
                      <AppLoader
                        style={{ margin: '20px auto' }}
                        // size="large"
                        color={darkMode ? WHITE_MAIN : FEDERAL_BLUE}
                      />
                    )
                  ) : null}
                  {voting ? (
                    !fetching && votingItem && stats ? (
                      <VotingItem
                        style={{ marginTop: 10 }}
                        item={votingItem}
                        user={user}
                        voting={stats}
                        darkMode={darkMode}
                        positionsPeriodDays={positionsPeriodDays}
                        votingExpiresPeriodDays={votingExpiresPeriodDays}
                        onClick={() => this.props.history.push(`${routes.VOTING_DETAILS_ROUTE}/${votingItem._id}`)}
                        hideMenu
                      />
                    ) : votingItem === [] ? (
                      <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                        {i18n.t('CREATE_POST_MODAL.votingIsover')}
                      </p>
                    ) : (
                      <AppLoader
                        style={{ margin: '20px auto' }}
                        // size="large"
                        color={darkMode ? WHITE_MAIN : FEDERAL_BLUE}
                      />
                    )
                  ) : null}
                  {userRef ? (
                    !fetching && userRefItem && stats ? (
                      <UserRef item={userRefItem} history={this.props.history} darkMode={darkMode} positions={stats.positions} />
                    ) : userRefItem === {} ? (
                      <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                        {i18n.t('CREATE_POST_MODAL.userNotFound')}
                      </p>
                    ) : (
                      <AppLoader style={{ margin: '20px auto' }} color={darkMode ? WHITE_MAIN : FEDERAL_BLUE} />
                    )
                  ) : null}
                  {votingHistory ? (
                    !fetching && votingHistoryItem && stats ? (
                      <VotingItem
                        darkMode={darkMode}
                        user={user}
                        item={votingHistoryItem}
                        isHistory
                        voting={stats}
                        positionsPeriodDays={positionsPeriodDays}
                        votingExpiresPeriodDays={votingExpiresPeriodDays}
                        hideMenu
                      />
                    ) : votingItem === [] ? (
                      <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                        {i18n.t('CREATE_POST_MODAL.votingIsover')}
                      </p>
                    ) : (
                      <AppLoader
                        style={{ margin: '20px auto' }}
                        // size="large"
                        color={darkMode ? WHITE_MAIN : FEDERAL_BLUE}
                      />
                    )
                  ) : null}
                  {income ? (
                    !fetching && incomeItem && stats ? (
                      <IncomesItem
                        darkMode={darkMode}
                        item={incomeItem}
                        user={user}
                        hideMenu
                        onClick={() => this.props.history.push(`${routes.GG_INCOMES_DETAILS_ROUTE}/${incomeItem._id}`)}
                      />
                    ) : incomeItem === [] ? (
                      <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                        {i18n.t('CREATE_POST_MODAL.incomeNotFound')}
                      </p>
                    ) : (
                      <AppLoader
                        style={{ margin: '20px auto' }}
                        // size="large"
                        color={darkMode ? WHITE_MAIN : FEDERAL_BLUE}
                      />
                    )
                  ) : null}
                  {expense ? (
                    !fetching && expenseItem && stats ? (
                      <IncomesItem
                        isExpense
                        darkMode={darkMode}
                        item={expenseItem}
                        hideMenu
                        user={user}
                        onClick={() => this.props.history.push(`${routes.GG_EXPENSES_DETAILS_ROUTE}/${expenseItem._id}`)}
                      />
                    ) : expenseItem === [] ? (
                      <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                        {i18n.t('CREATE_POST_MODAL.expenseNotFound')}
                      </p>
                    ) : (
                      <AppLoader
                        style={{ margin: '20px auto' }}
                        // size="large"
                        color={darkMode ? WHITE_MAIN : FEDERAL_BLUE}
                      />
                    )
                  ) : null}
                  {community ? (
                    !fetching && communityItem && stats ? (
                      <CommunityItem
                        inner
                        item={communityItem}
                        darkMode={darkMode}
                        user={user}
                        onClick={() => this.props.history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${communityItem._id}`)}
                      />
                    ) : communityItem === {} ? (
                      <p style={{ color: darkMode ? WHITE_MAIN : FEDERAL_BLUE, margin: 'auto' }}>
                        {i18n.t('CREATE_POST_MODAL.communityNotFound')}
                      </p>
                    ) : (
                      <AppLoader
                        style={{ margin: '20px auto' }}
                        // size="large"
                        color={darkMode ? WHITE_MAIN : FEDERAL_BLUE}
                      />
                    )
                  ) : null}
                  <button className='CreatePost__Create' onClick={() => this.createPost()}>
                    {communityPost ? t('CREATE_POST.buttonCommunity') : t('CREATE_POST.button')}
                  </button>
                </div>
              </div>
            )}
            <PreviewModal
              file={this.state.modalFile}
              isOpen={this.state.isOpenPreview}
              imageMaxSize={imageMaxSize}
              videoMaxSize={videoMaxSize}
              onRequestClose={() =>
                this.setState({
                  isOpenPreview: !this.state.isOpenPreview,
                })
              }
            />
            <Alert
              isOpen={this.state.isAlertOpen}
              type={this.state.alertType}
              content={this.state.alertContent}
              onRequestClose={() =>
                this.setState({
                  isAlertOpen: false,
                })
              }
            />
            <WebCamModal
              isOpen={this.state.isCamOpen}
              mobile={this.state.matches}
              webCamRef={this.camRef}
              action={() => this.webCamCapture()}
              onRequestClose={() =>
                this.setState({
                  isCamOpen: false,
                })
              }
              onStart={() => this.startCapturing()}
              onEnd={() => this.stopCapture()}
              format={this.state.webcamformat}
              darkMode={darkMode}
            />
          </div>
        )}
      </Translation>
    );
  }
}

const CreatePostWithRouter = withRouter(CreatePost);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserPosts: bindActionCreators(actions.saveUserPosts, dispatch),
    openVerifyModal: bindActionCreators(actions.openVerifyModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostWithRouter);
