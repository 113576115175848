import React from 'react';
import Arrow from '../../../assets/faq/triengle.svg';
import ArrowDisabled from '../../../assets/faq/triengle2.svg';
import {
  BLACK,
  DARK_OFFICIAL_BLUE,
  FEDERAL_BLUE,
  //FEDERAL_WHITE,
  WHITE_MAIN,
} from '../../../utils/color-palatte';
import { withRouter } from 'react-router';

import './faq-items.css';

class FaqItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemIsOpen: this.props.isOpen === true ? this.props.isOpen : false,
      height: null,
    };
    this.rvlRef = React.createRef();
  }

  toggleItem = () => {
    if (this.state.itemIsOpen) {
      this.setState({ itemIsOpen: false });
    } else {
      this.setState({ itemIsOpen: true });
    }
  };

  componentDidMount = () => {
    if (this.rvlRef.current) {
      this.setState({
        height: this.rvlRef.current.scrollHeight,
      });
    }
    if (this.props.location.hash) {
      let elem = document.getElementById(this.props.location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  componentWillUnmount = () => {
    this.setState({
      height: null,
    });
  };

  render() {
    const { title, children, /* Height,*/ hash, isOpen, darkMode, index } = this.props;

    return (
      <>
        <div
          className='FaqItem__Header'
          style={
            this.state.itemIsOpen
              ? {
                  borderBottom: '1.5px solid #AAA',
                  borderTop: index === 0 ? '1.5px solid #AAA' : '',
                  background: darkMode ? DARK_OFFICIAL_BLUE : WHITE_MAIN,
                }
              : {
                  borderBottom: `1.5px solid ${FEDERAL_BLUE}`,
                  borderTop: index === 0 ? `1.5px solid ${FEDERAL_BLUE}` : '',
                  background: darkMode ? DARK_OFFICIAL_BLUE : WHITE_MAIN,
                }
          }
          onClick={this.toggleItem}
          id={hash}
        >
          <p
            style={this.state.itemIsOpen ? { color: '#AAA' } : { color: darkMode ? WHITE_MAIN : FEDERAL_BLUE }}
            className='FaqItem__Title'
          >
            {title}
          </p>
          <img
            src={this.state.itemIsOpen ? (isOpen ? ArrowDisabled : ArrowDisabled) : Arrow}
            alt='FaqArrow'
            className='FaqItem__Arrow'
            style={this.state.itemIsOpen ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
          />
        </div>
        <div
          ref={this.rvlRef}
          className='FaqItem__Container'
          style={{
            height: this.state.itemIsOpen ? this.state.height : 0,
            background: darkMode ? '#01132780' : '#e7eae180',
            color: darkMode ? WHITE_MAIN : BLACK,
          }}
        >
          {children}
        </div>
      </>
    );
  }
}

export default withRouter(FaqItem);
