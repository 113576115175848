import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../utils/styles.module.css';
import { Title, ParsedParagraph, Divider, Banner } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import LawsBanner from '../../../assets/whitepaper/laws.svg';
import Alert from '../../../utils/alert/Alert';

const Laws = (props) => {
  const { darkMode, outer } = props;
  const { t } = useTranslation();
  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 1000px)').matches);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: undefined,
  });

  const location = useLocation();

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 1000px)').addEventListener('change', handler);
    return () => window.matchMedia('(max-width: 1000px)').removeEventListener('change', handler);
  }, []);

  const copyUrl = (hash) => {
    if (hash) {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}#${hash}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    } else {
      navigator.clipboard.writeText(`${window.location.origin}${location.pathname}`);
      showAlert(true, 'success', t('DASHBOARD.shareUrl'));
    }
  };

  // const sendToHash = (hash) => {
  //   if (hash) {
  //     history.push(`${location.pathname}#${hash}`);
  //   } else {
  //     history.push(location.pathname);
  //   }
  // };

  const showAlert = (isOpen, type, content) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  return (
    <div className={styles.Container}>
      <div
        style={{
          width: matches ? '100%' : 'calc(100% - 300px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: outer ? 0 : '70px',
        }}
      >
        <div className={styles.Content}>
          <Title text={t('WHITEPAPER.LAWS.title')} darkMode={darkMode} onClick={() => copyUrl()} />
          <Divider darkMode={darkMode} />
          <Banner src={LawsBanner} />
          <Divider darkMode={darkMode} />
          <ParsedParagraph string={t('WHITEPAPER.LAWS.content')} hasWiki darkMode={darkMode} />
        </div>
      </div>
      <div className={styles.Wrapper} style={{ display: matches ? 'none' : 'flex', paddingTop: outer ? 0 : '70px' }}>
      </div>
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ ...modal, isOpen: false, type: null, content: undefined })}
      />
    </div>
  );
};

export default Laws;
