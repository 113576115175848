import React from 'react';
import { useTranslation } from 'react-i18next';
import HawkingScene from '../../../assets/home/reliability/hawking_scene.svg';
import HawkingAvatar from '../../../assets/home/reliability/hawking_avatar.svg';
import GlobeArrow from '../../../assets/home/reliability/globe_arrow.svg';
import { ParsedText } from '../../../utils/text-utils/text-utils';
import * as colors from '../../../utils/color-palatte';
import styles from './reliability.module.css';

// const GSpan = ({ children, onClick }) => (
//   <BSpan style={{ color: colors.FEDERAL_GREEN, fontWeight: 'bold' }} onClick={onClick}>
//     {children}
//   </BSpan>
// );

const Reliability = (props) => {
  const [isSmall, setIsSmall] = React.useState(false);

  const rvlRef = React.createRef();
  let observer = null;

  React.useEffect(() => {
    // eslint-disable-next-line
    observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      if (boxElem.contentRect.width < 1200) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    });

    if (observer) {
      observer.observe(rvlRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  });

  const { maintenanceTaxes, presidentSalaryPorcentage, vicepresidentSalaryPorcentage, citizenGainFromDeal } = props;

  const dealMaintenance = maintenanceTaxes && citizenGainFromDeal ? Number(maintenanceTaxes) - Number(citizenGainFromDeal) : '...';
  const capital = maintenanceTaxes && presidentSalaryPorcentage && vicepresidentSalaryPorcentage ? 100 - (Number(maintenanceTaxes) + Number(presidentSalaryPorcentage) + Number(vicepresidentSalaryPorcentage)) : '...';
  const presSalaryPercent = presidentSalaryPorcentage ? presidentSalaryPorcentage : '...';
  const vicePresSalaryPercent = vicepresidentSalaryPorcentage ? vicepresidentSalaryPorcentage : '...';
  const citizenGainDeal = citizenGainFromDeal ? citizenGainFromDeal : '...';


  const { t } = useTranslation();

  const values = [
    {
      keyword: `${capital}%`,
      style: { color: '#fff', cursor: 'normal', fontWeight: 'bold' },
    },
    {
      keyword: `${dealMaintenance}%`,
      style: { color: '#fff', cursor: 'normal', fontWeight: 'bold' },
    },
    {
      keyword: `${citizenGainDeal}%`,
      style: { color: '#fff', cursor: 'normal', fontWeight: 'bold' },
    },
    {
      keyword: `${presSalaryPercent}%`,
      style: { color: '#fff', cursor: 'normal', fontWeight: 'bold' },
    },
    {
      keyword: `${vicePresSalaryPercent}%`,
      style: { color: '#fff', cursor: 'normal', fontWeight: 'bold' },
    },
  ];

  return (
    <div ref={rvlRef} className={styles.Reliability__Container}>
      <h1 className={styles.Reliability__Title}>{t('RELIABILITY.title')}</h1>
      {!isSmall && (
        <div className={styles.Reliability__ArtWork} style={{ backgroundImage: `url(${HawkingScene})` }}>
          <div className={styles.Reliability__Dialog__Regular}>
            <ParsedText
              string={t('RELIABILITY.content')(
                dealMaintenance,
                capital,
                citizenGainDeal,
                presidentSalaryPorcentage,
                vicepresidentSalaryPorcentage
              )}
              values={values}
              hasWiki
              markStyle={{ fontWeight: 'bold' }}
            />
          </div>
        </div>
      )}
      {isSmall && (
        <>
          <div className={styles.Reliability__TextContent}>
            <ParsedText
              string={t('RELIABILITY.content')(
                dealMaintenance,
                capital,
                citizenGainDeal,
                presidentSalaryPorcentage,
                vicepresidentSalaryPorcentage
              )}
              values={values}
              hasWiki
              markStyle={{ fontWeight: 'bold', color: colors.FEDERAL_GREEN }}
            />
            <img src={GlobeArrow} className={styles.Reliability__Arrow} alt='' />
          </div>
          <img src={HawkingAvatar} alt='' className={styles.Reliability__Hero} />
        </>
      )}
    </div>
  );
};

export default Reliability;
