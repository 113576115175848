import React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { TermsEn, TermsEs } from '../../components/terms-modal/terms-content/terms-content';
import { FEDERAL_BLUE, DARK_OFFICIAL_BLUE, WHITE_MAIN } from '../../utils/color-palatte';
import './termsAndConditions.css';
import i18n from '../../i18n/i18n';

const TermsAndConditions = ({ user, darkMode }) => {
  const language = i18n.language;

  const [matches, setMatches] = React.useState(window.matchMedia('(max-width: 500px)').matches);

  React.useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 500px)').addEventListener('change', handler);
    return () => window.matchMedia('(max-width: 500px)').removeEventListener('change', handler);
  });

  return (
    <Translation>
      {(t) => (
        <div
          className='Terms__Container'
          style={{ backgroundColor: user && darkMode ? DARK_OFFICIAL_BLUE : WHITE_MAIN, padding: `0 ${matches ? 20 : 40}px` }}
        >
          <h1 style={{ color: user && darkMode ? WHITE_MAIN : FEDERAL_BLUE, fontSize: `${matches ? 1.5 : 2}rem` }}>
            {t('TERMS_AND_CONDITIONS.title')}
          </h1>
          <div
            style={{
              borderBottom: `1px solid ${user && darkMode ? WHITE_MAIN : FEDERAL_BLUE}`,
              width: '100%',
              height: '1px',
              maxWidth: '1000px',
            }}
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              maxWidth: '1000px',
              margin: '0 auto',
            }}
          >
            {language === 'es' ? (
              <TermsEs
                style={{
                  color: user && darkMode ? WHITE_MAIN : undefined,
                  padding: '0 20px',
                }}
              />
            ) : (
              <TermsEn
                style={{
                  color: user && darkMode ? WHITE_MAIN : undefined,
                  padding: '0 20px',
                }}
              />
            )}
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
