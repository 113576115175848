import { postData } from "../../../fetch";
import { store } from "../../../redux/configureStore";
import { OPEN_MESSAGE_MODAL, OPEN_VERIFY_MODAL } from "../../../redux/types/user/types";
import { USER_PATMENTS_ROUTE } from "../../../routes/routes";

export const createPaymentIntent = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/payments/create-payment-intent-web', data);
      // console.log("validatePayment / response: ", response)
      resolve(response);
      resolve();
    } catch (e) {
      reject(e);
    }
  })
};

export const createPayment = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/payments/create-payment-web', data);
      // console.log("createPayment / response: ", response)
      resolve(response);
      resolve();
    } catch (e) {
      reject(e);
    }
  })
};

export const validatePendingPayments = (history, onRequestClose) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { user } = store.getState().user;
      const response = await postData('/payments/validate-user-pending-payments-web', { userId: user._id });
      resolve(response);
      resolve();
    } catch (e) {
      // console.log("validatePendingPayments / error: ", e)
      reject(e);
      if (
        e?.data?.error?.code === 'user-not-verified' ||
        e?.data?.error?.details?.code === 'user-not-verified'
      ) {
        onRequestClose()
        store.dispatch({
          type: OPEN_VERIFY_MODAL
        });
        return
      }
      
      if (e && e.data && e.data.error && e.data.error.code && e.data.error.code === "pending-payments") {
        onRequestClose()
        store.dispatch({
          type: OPEN_MESSAGE_MODAL,
          data: { message: e.data.error.message, type: 'wrong' },
        });
        history.push(USER_PATMENTS_ROUTE)
      }

    }
  })
};