import React, { useState, useEffect } from 'react';
import i18n from '../../i18n/i18n';
import { isUserValid } from '../../utils/isUserValid';
import { connect } from 'react-redux';
import { getIncomeDetails } from '../incomes/ggIncomesListActions';
import IncomesItem from '../../components/incomes-list/incomesItem';
import { useParams } from 'react-router-dom';
import { AppLoader } from '../../utils/animations/animations';
import * as colors from '../../utils/color-palatte';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';
import { useTranslation } from 'react-i18next';

const GGIncomeDetail = (props) => {
  const { user, darkMode } = props;

  const [fetching, setFetching] = useState(true);
  const [income, setIncome] = useState(null);
  const [modal, setModal] = useState({
    isOpen: false,
    type: null,
    content: null,
  });

  let _isMounted = false;

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line
      _isMounted = true;
      isUserValid(false, _isMounted);
      if (_isMounted) fetchIncome();
    })();
    return () => {
      _isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const { incomeId } = useParams();

  const fetchIncome = () =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await getIncomeDetails(incomeId);
        // console.log(response);
        setIncome(response[0]);
        setFetching(false);
        resolve();
      } catch (e) {
        if (e.status === 404) {
          // console.log(e);
          reject(e);
        } else {
          // console.log(e);
          reject(e);
          setFetching(false);
          const error = e?.data?.error?.message ?? e?.data?.error ?? i18n.t('GENERAL_ERRORS.serverError');
          showAlert(true, 'error', error);
        }
      }
    });

  const showAlert = (isOpen, type, content) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  const { t } = useTranslation();

  return (
    <div
      className='IncomeDetail__Container'
      style={{
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        minHeight: fetching ? 'calc(100vh - 60px)' : 'unset',
        display: 'flex',
        justifyContent: fetching ? 'center' : 'flexStart',
        alignItems: 'center',
      }}
    >
      {fetching ? <AppLoader size='big' color={darkMode ? '#FFF' : colors.FEDERAL_BLUE} /> : null}
      {income ? <IncomesItem item={income} user={user} darkMode={darkMode} /> : null}
      {!fetching && !income ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'calc(100vh - 60px)' }}
        >
          <NotFoundItem isBlue={darkMode} type='income' text={t('INCOMES.notFound')} />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GGIncomeDetail);
