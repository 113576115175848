import { postData, getData, putData } from "../../fetch";

export const createComment = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/comment/create-comment-web', data);
      resolve(response);
    } catch (e) {
      reject(e);
    }
  })
};

export const getComments = (postId, skipItems, limitItems, admin) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log("getComments / postId: ", postId);
      const response = await getData(`/comment/get-comments-web/?postId=${postId}&skipItems=${skipItems}&limitItems=${limitItems}&admin=${admin}`);
      // console.log("getComments / response: ", response);
      const comments = response.body;
      return resolve(comments);
    } catch (e) {
      // console.log("getComments / error: ", e);
      return reject(e);
    }
  })
};

export const reportComment = (userId, postId) => {
  return new Promise(async (resolve, reject) => {
    const data = { user: userId, comment: postId }
    try {
      const response = await putData(`/comment/report-comment-web`, data);
      // console.log("reportComment / response: ", response);
      return resolve(response);
    } catch (e) {
      // console.log("reportComment / error: ", e);
      return reject(e);
    }
  })
};

// export const blockComment = (commentId, boolean, reason) => {
//   return new Promise(async (resolve, reject) => {
//     const adminId = localStorage.getItem('adminId');
//     const adminCode = localStorage.getItem('adminCode');
//     const userId = localStorage.getItem('userId')
//     const data = { commentId, value: boolean, adminId, adminCode, userId, reason }
//     try {
//       const response = await putData(`/comment/block-comment`, data);
//       // console.log("blockComment / response: ", response);
//       return resolve(response);
//     } catch (e) {
//       // console.log("blockComment / error: ", e);
//       return reject(e);
//     }
//   })
// };

export const deleteComment = (commentId, commentUserId, postId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem("userId")
      const data = {
        userId,
        commentId,
        commentUserId,
        postId
      }
      const response = await postData(`/comment/remove-comment-web`, data);
      // console.log("removeComment / response: ", response);
      return resolve(response);
    } catch (e) {
      // console.log("removeComment / error: ", e);
      return reject(e);
    }
  })
};