import { getData, postData } from "../../fetch";

export const getCommunityMembers = ({communityId, skipItems, limitItems, search}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem("userId")
      const response = await getData(`/communities/get-community-members-web/?communityId=${communityId}&skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&userId=${userId}`);
      // console.log("getPosts / response: ", response);
      const users = response.body;
      return resolve(users);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  })
};

export const giveLeadership = (communityId, selectedLeader) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const adminId = await localStorage.getItem("adminId")
      // const adminCode = await localStorage.getItem("adminCode")
      const userId = localStorage.getItem("userId")
      const data = {
        userId,
        communityId,
        selectedLeader
      }
      const response = await postData(`/communities/give-leadership-web/`, data);
      // console.log("getPosts / response: ", response);
      return resolve(response);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  })
};

export const quitCommunityMember = (userId, communityId) => {
  return new Promise(async (resolve, reject) => {
    const leader = localStorage.getItem("userId")
    const data = {
      userId,
      communityId,
      leader: leader
    }
    try {
      await postData(`/communities/quit-community-member-web`, data);
      resolve();
    } catch (e) {
      reject(e);
    }
  })
}