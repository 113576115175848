import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AinstainSad from '../../assets/privacy/sad_ainstain.svg';
import Alert from '../../utils/alert/Alert';
import styles from './styles.module.css';
import DeleteSecurityModal from '../../components/privacy/deleteSecurity';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { bindActionCreators } from 'redux';
import { sendRemoveUserCode } from '../privacy/privacyActions';
import { useHistory } from 'react-router-dom';
import * as routes from '../../routes/routes';

const DeleteAccount = (props) => {
  const { user, logOut } = props;

  const username = user ? user.username : '';

  const { t } = useTranslation();

  const history = useHistory();

  const [mobile, setMobile] = React.useState(window.matchMedia('(max-width: 650px)').matches);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: null,
    content: null,
  });

  React.useEffect(() => {
    const handler = (e) => setMobile(e.matches);
    window.matchMedia('(max-width: 650px)').addEventListener('change', handler);
    return () => {
      window.matchMedia('(max-width: 650px)').removeEventListener('change', handler);
    };
  });

  const sendRemoveCode = async () => {
    return new Promise(async (resolve, _) => {
      if (!isFetching) {
        setIsFetching(true);
        try {
          await sendRemoveUserCode({ userId: user._id });
          setIsFetching(false);
          setIsDeleteOpen(true);
          setModal({ ...modal, isOpen: false });
          resolve();
        } catch (e) {
          const error = e?.data?.error?.message ?? e?.data?.error ?? t('GENERAL_ERRORS.serverError');
          setIsFetching(false);
          showAlert(true, 'error', error, () => setModal({ ...modal, isOpen: false }));
          resolve();
        }
      }
    });
  };

  const showAlert = (isOpen, type, content) => {
    setModal({
      ...modal,
      isOpen: isOpen,
      type: type,
      content: content,
    });
  };

  return (
    <div
      className={styles.DeleteAccount__Container}
      style={{ minHeight: `calc(100vh - ${mobile ? 50 : 70}px)`, paddingTop: `${mobile ? 50 : 70}px` }}
    >
      <h1 className={styles.DeleteAccount__Title}>{t('DELETE_ACCOUNT.title')}</h1>
      <div className={styles.DeleteAccount__Content}>
        <img src={AinstainSad} alt='' />
        <div className={styles.DeleteAccount__TextContent}>
          <p style={{ fontWeight: 'bold' }}>{t('DELETE_ACCOUNT.subTitle')}:</p>
          <p
            className={styles.DeleteAccount__List}
            dangerouslySetInnerHTML={{ __html: t('DELETE_ACCOUNT.contentList')(username) }}
          />
        </div>
      </div>
      <button
        className={styles.DeleteAccount__Button}
        onClick={
          user
            ? () => {
                setIsDeleteOpen(true);
                sendRemoveCode();
              }
            : () => history.push(`${routes.LOGIN_ROUTE}?redirect=delete-account`)
        }
      >
        {t('DELETE_ACCOUNT.button')}
      </button>
      <DeleteSecurityModal isOpen={isDeleteOpen} logOutFunc={logOut} onRequestClose={() => setIsDeleteOpen(false)} />
      <Alert
        isOpen={modal.isOpen}
        type={modal.type}
        content={modal.content}
        onRequestClose={() => setModal({ ...modal, isOpen: false })}
      />
    </div>
  );
};

const mapState = (state) => {
  const { user } = state.user;
  return {
    user,
  };
};

const mapDispatch = (dispatch) => {
  return {
    logOut: bindActionCreators(actions.logOut, dispatch),
  };
};

export default connect(mapState, mapDispatch)(DeleteAccount);
