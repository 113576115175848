import { getData } from '../../fetch';

export const getIncomes = (skipItems, limitItems, search, admin) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/gg-incomes/get-incomes-web/?skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&admin=${admin}`
      );
      // console.log("getPosts / response: ", response);
      const incomes = response.body;
      return resolve(incomes);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  });
};

export const getIncomeDetails = (incomeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/gg-incomes/get-income-details-web/?incomeId=${incomeId}`
      );
      // console.log("getVotingDetails / response: ", response);
      const income = response.body;
      return resolve(income);
    } catch (e) {
      // console.log("getVotingDetails / error: ", e);
      return reject(e);
    }
  });
};