import moment from "moment";

const secondsBetweenDates = (date, positionsPeriodDays) => {
  const a = moment()
  const b = moment(date).add(positionsPeriodDays, 'days');
  // console.log('data', date, 'ppd', positionsPeriodDays)
  // console.log(Math.abs((+a - +b) / 1000))
  return Math.abs((+a - +b) / 1000)
}

export default secondsBetweenDates