import React from 'react';
import i18n from '../../../i18n/i18n';
import { editCommunity, deleteCommunity } from '../communityProfileActions';
import { isUserValid } from '../../../utils/isUserValid';
import './editCommunity.css';
import { throttle } from 'lodash';
import deleteFiles from '../../../utils/delete-files';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../../redux/actions/user/actions';
import AlertModal from '../../../utils/modal/Modal';
import {
  FEDERAL_BLUE,
  FEDERAL_BLUE_SOFT,
  UNSATURATED_BLACK_BLUE,
  GRAY_SECOND_TRANSPARENCY_FULL,
  WHITE_MAIN,
} from '../../../utils/color-palatte';
import AddIcon from '../../../assets/edit-profile/add_photo_icon.svg';
import CloseIcon from '../../../assets/terms-modal/close-btn.svg';
import CloseIconWhite from '../../../assets/terms-modal/close-btn-white.svg';
import RightComponentItem from '../../../utils/app-inputs/right-component-input/rightCompoentInput';
import { AppLoader } from '../../../utils/animations/animations';
import OptionModal from '../../options-modal/optionsModal';
import CropModal from '../../profile/crop-modal/cropModal';
import { readerFile } from '../../profile/edit-profile/readerFile';
import { getCroppedImg } from '../../profile/crop-modal/canvasUtils';
import { saveFile } from '../../../utils/saveFilesToCloud';
import { Translation } from 'react-i18next';
import Alert from '../../../utils/alert/Alert';
import { getCommunity } from '../../../pages/communities-profile/communityProfileActions';
import * as routes from '../../../routes/routes';
import { updateLoggedUser } from '../../../utils/updateLoggedUser';
import { UserProfile } from '../../ornament-profile/profileUtils';
import { withRouter } from 'react-router-dom';
import CommunityBG from '../../../assets/communities/community-bg.png';
import CommunityProfilePictureDark from '../../../assets/communities/community_profile_dark.svg';
import CommunityProfilePicture from '../../../assets/communities/community_profile_light.svg';

export const EditCommunityButton = (props) => {
  const { mobile, onClick, disabled, darkMode, style, text } = props;
  const [hover, setHover] = React.useState(false);

  return (
    <button
      className='EditCommunity__Button'
      onMouseEnter={mobile ? null : () => setHover(true)}
      onMouseLeave={mobile ? null : () => setHover(false)}
      onTouchStart={mobile ? () => setHover(true) : null}
      onTouchEnd={mobile ? () => setHover(false) : null}
      onClick={onClick}
      disabled={disabled}
      style={Object.assign(
        {
          width: '100%',
          height: '40px',
          display: 'flex',
          border: 'none',
          borderRadius: '10px',
          fontFamily: `'Source Sans Pro', sans-serif`,
          fontSize: '1.1rem',
          transition: 'all .3s',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          backgroundColor: darkMode ? (hover ? '#CCC' : WHITE_MAIN) : hover ? FEDERAL_BLUE_SOFT : FEDERAL_BLUE,
          color: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
        },
        style
      )}
    >
      {text}
    </button>
  );
};

class EditCommunity extends React.Component {
  state = {
    loading: false,
    fetching: false,
    picture: '',
    bgPicture: '',
    name: '',
    description: '',
    community: {},
    editingProp: false,
    savingName: false,
    savingBornDate: false,
    showModal: false,
    pictureImage: null,
    croppedAreaPixels: null,
    croppedImage: null,
    rotation: 0,
    zoom: 1,
    crop: { x: 0, y: 0 },
    matches: window.matchMedia('(max-width: 420px)').matches,
    currentEditImage: '',
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      window.matchMedia('(max-width: 420px)').addEventListener('change', (e) => this.setState({ matches: e.matches }));
    }
    isUserValid(false, this._isMounted);
    if (this._isMounted) this.fetchCommunity();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    window.matchMedia('(max-width: 420px)').removeEventListener('change', (e) => this.setState({ matches: e.matches }));
  };

  fetchCommunity = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { communityId } = this.props.match.params;
        const community = await getCommunity(communityId);
        // if (community) {
        this.setState({
          picture: community.picture,
          bgPicture: community.bgPicture,
          name: community.name,
          description: community.description,
          community: community,
          loading: false,
          refresh: false,
        });
        // console.log("community", community);
        resolve();
        // } else {
        //   (i18n.t('COMMUNITY_PROFILE.communityNotFound'))
        //   this.props.navigation.goBack();
        // }
      } catch (e) {
        // console.log("community", e);
        this.setState({
          isMessageModalOpen: true,
          isYesChooseModal: false,
          messageModalMessage: e?.data?.error,
          messageModalType: 'error',
        });
        reject();
      }
    });
  };

  saveCommunity = async (file) => {
    // console.log("saveCommunity / file: ", file);
    const { name, description, community, selectedImage } = this.state;
    const { user, saveUser } = this.props;
    if (name && description) {
      this.setState({ fetching: true }, async () => {
        try {
          const { communityId } = this.props.match.params;
          // console.log("saveCommunity / communityId: ", communityId);

          if (community && community.picture && selectedImage === 'picture' && file) {
            await deleteFiles([community.picture]);
          }

          if (community && community.bgPicture && selectedImage === 'bgPicture' && file) {
            await deleteFiles([community.bgPicture]);
          }

          let pictureUri = '';
          let bgPictureUri = '';

          if (selectedImage === 'picture') {
            pictureUri = file ? await saveFile(Math.random(), file.uri, 'image', 'communities') : '';
          }

          if (selectedImage === 'bgPicture') {
            bgPictureUri = file ? await saveFile(Math.random(), file.uri, 'image', 'communities') : '';
          }

          let dataobj = {
            name,
            description,
          };

          if (bgPictureUri && selectedImage === 'bgPicture') {
            dataobj.bgPicture = bgPictureUri.uri;
          }

          if (pictureUri && selectedImage === 'picture') {
            dataobj.picture = pictureUri.uri;
          }

          // console.log("pictureUri: ", pictureUri);
          // console.log("bgPictureUri: ", bgPictureUri);

          const data = {
            data: dataobj,
            communityId,
            userId: user._id,
          };

          await editCommunity(data);
          await this.fetchCommunity();
          await updateLoggedUser(saveUser);
          this.setState({ fetching: false, rotation: 0, zoom: 1, crop: { x: 0, y: 0 } });
          // this.setState(
          //   {
          //     fetching: false,
          //     picture: "",
          //     bgPicture: "",
          //     name: "",
          //     description: "",
          //   },
          //   () => {
          //     this.props.history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${communityId}`);
          //   }
          // );
        } catch (e) {
          // console.log("saveCommunity / error: ", e);
          this.setState({
            fetching: false,
          });
          const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
          this.setState({
            isMessageModalOpen: true,
            isYesChooseModal: false,
            messageModalMessage: error,
            messageModalType: 'error',
          });
        }
      });
    } else {
      if (!name) {
        this.setState({
          isMessageModalOpen: true,
          isYesChooseModal: false,
          messageModalMessage: i18n.t('COMMUNNITIES.missingName'),
          messageModalType: 'error',
        });
        return false;
      }
      if (!description) {
        this.setState({
          isMessageModalOpen: true,
          isYesChooseModal: false,
          messageModalMessage: i18n.t('COMMUNNITIES.missingDescription'),
          messageModalType: 'error',
        });
        return false;
      }
    }
  };

  deleteCommunity = () => {
    this.setState({ deleteLoading: true, isModalOpen: false }, async () => {
      try {
        const { communityId } = this.props.match.params;
        const { user, saveUser, saveCommunityPosts } = this.props;

        const data = {
          communityId,
          userId: user._id,
        };

        await deleteCommunity(data);
        // await this.fetchCommunity()
        await updateLoggedUser(saveUser);
        saveCommunityPosts([]);

        this.setState(
          {
            deleteLoading: false,
          },
          () => {
            this.props.history.push(routes.COMMUNITIES_ROUTE);
          }
        );
      } catch (e) {
        // console.log("deleteCommunity / error: ", e);
        this.setState({
          deleteLoading: false,
        });
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
        // this.setState({
        //   isMessageModalOpen: true,
        //   messageModalMessage: error,
        //   messageModalType: "error",
        //   isYesChooseModal: false,
        // });
        this.showAlert(
          true,
          'error',
          undefined,
          error,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          () => this.setState({ isModalOpen: false }),
          undefined
        );
      }
    });
  };

  // fileToDataUri = (file) =>
  //   new Promise((resolve, reject) => {
  //     const fileType = file.type.split("/")[0].toLowerCase();
  //     const extension = file.type.split("/")[1].toLowerCase();
  //     if (fileType === "image") {
  //       Resizer.imageFileResizer(
  //         file,
  //         730,
  //         500,
  //         extension === "png" ? "PNG" : extension === "webp" ? "WEBP" : "JPEG",
  //         80,
  //         0,
  //         (file) => {
  //           // console.log("imageFileResizer / file: ", file);
  //           const reader = new FileReader();
  //           reader.onload = (event) => {
  //             const uri = event.target.result;
  //             const sizeInMb = getBase64Size(uri);
  //             // console.log('uri', uri);
  //             resolve({
  //               name: file.name,
  //               uri: uri,
  //               type: file.type,
  //               folder: "communities",
  //               sizeInMb: sizeInMb,
  //             });
  //           };
  //           reader.readAsDataURL(file);
  //         },
  //         "file"
  //       );
  //     } else {
  //       const reader = new FileReader();
  //       reader.onload = (event) => {
  //         const uri = event.target.result;
  //         const sizeInMb = getBase64Size(uri);
  //         // console.log('uri', uri);
  //         const finalUri = `data:video/mp4;base64,${uri.split(",")[1]}`;
  //         resolve({
  //           name: file.name,
  //           uri: finalUri,
  //           type: "video",
  //           folder: "posts",
  //           sizeInMb: sizeInMb,
  //         });
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   });

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onRotationChange = (rotation) => {
    this.setState({ rotation });
  };

  handleFileInput = async (e, aspect) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readerFile(file);
      // console.log('imageDataUrl:', imageDataUrl);

      this.setState({ pictureImage: imageDataUrl }, () => this.showCropper(true, imageDataUrl, aspect));
    }
  };

  uploadFile = (aspect) => {
    const file = document.createElement('input');
    file.type = 'file';
    file.style.display = 'none';
    file.accept = 'image/png, image/jpg, image/jpeg, image/heif, image/heic, image/webp, image/gif';
    file.onchange = (e) => this.handleFileInput(e, aspect);
    file.click();
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(this.state.pictureImage, this.state.croppedAreaPixels, this.state.rotation);
      this.setState(
        {
          croppedImage: croppedImage,
        },
        async () => {
          const res = await this.blobToBase64(croppedImage);
          const file = {
            uri: res,
            name: `IMG${String(Math.random()).replace('0.', '')}`,
            type: 'image/png',
            // extension: 'jpeg'
            folder: 'profiles',
            // sizeInMb: sizeInMb,
          };
          // console.log("showCroppedImage / file: ", file);
          this.setState(
            {
              isCropperOpen: false,
            },
            async () => {
              this.showOptions(false);
              await this.saveCommunity(file);
            }
          );
        }
      );
    } catch (e) {
      // console.log("showCroppedImage", e);
      this.showAlert(true, 'error', undefined, e.toString(), false, null, null, null, null, undefined, () =>
        this.setState({ isModalOpen: false })
      );
    }
  };

  blobToBase64 = (blob) => {
    return new Promise(async (resolve, _) => {
      let blobUrl = await fetch(blob).then((r) => r.blob());
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blobUrl);
    });
  };

  throtled = throttle(async () => {
    this.isUsernameAvilable();
  }, 1000);

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  changePicture = (uri) => {
    this.showOptions(false);
    this.showCropper(true, uri);
  };

  showOptions = (isOpen) => {
    this.setState({
      isOptionsOpen: isOpen,
    });
  };

  showCropper = (isOpen, uri, aspect) => {
    this.setState({
      isCropperOpen: isOpen,
      cropperImage: uri,
      cropperAspect: aspect,
    });
  };

  showAlert = (
    isOpen,
    type,
    title,
    content,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose,
    loading
  ) => {
    this.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
      actionsLoader: loading,
    });
  };

  onDeleteCall = () => {
    this.showAlert(
      true,
      'warning',
      i18n.t('DASHBOARD.MODAL.title'),
      i18n.t('COMMUNITIES.sureToDelete'),
      true,
      () => this.setState({ isModalOpen: false }),
      'NO',
      () => this.deleteCommunity(),
      i18n.t('DASHBOARD.MODAL.yes'),
      undefined,
      undefined,
      false
    );
  };

  render() {
    const {
      fetching,
      name,
      editingProp,
      picture,
      bgPicture,
      description,
      deleteLoading,
      // selectedImage
    } = this.state;

    const { darkMode, onRequestClose, isOpen } = this.props;

    const profilePic = darkMode ? CommunityProfilePictureDark : CommunityProfilePicture;
    // console.log("user: ", user);
    // console.log("selectedImage: ", selectedImage);

    return (
      <Translation>
        {(t) => (
          <AlertModal
            overlayClassName='EditCommunity__Overlay'
            modalClassName='EditCommunity__Container'
            style={{
              backgroundColor: darkMode ? UNSATURATED_BLACK_BLUE : WHITE_MAIN,
            }}
            outsideClick={onRequestClose}
            isOpen={isOpen}
          >
            <h1
              className='EditCommunity__Title'
              style={{
                borderBottom: `1px solid ${darkMode ? '#FFF5' : GRAY_SECOND_TRANSPARENCY_FULL}`,
                color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
              }}
            >
              {t('EDIT_COMMUNITY.title')}
            </h1>
            <img
              src={darkMode ? CloseIconWhite : CloseIcon}
              alt='Close'
              onClick={onRequestClose}
              className='EditCommunity__CloseImg'
            />
            <div className='EditCommunity__Content'>
              <div style={{ display: 'flex' }}>
                <div className='EditCommunity__PictureContainer'>
                  <img
                    src={AddIcon}
                    className='EditCommunity__Icon'
                    alt=''
                    onClick={() => this.setState({ selectedImage: 'picture' }, () => this.showOptions(true))}
                  />
                  <UserProfile
                    uri={picture || profilePic}
                    containerStyle={{
                      width: '100px',
                      height: '100px',
                      borderRadius: 'calc(100px / 2)',
                    }}
                  />
                  <p
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                    }}
                  >
                    {t('EDIT_COMMUNITY.changePicture')}
                  </p>
                </div>
                <div className='EditCommunity__PictureContainer'>
                  <img
                    src={AddIcon}
                    className='EditCommunity__Icon'
                    alt=''
                    onClick={() => this.setState({ selectedImage: 'bgPicture' }, () => this.showOptions(true))}
                  />
                  <UserProfile
                    uri={bgPicture || CommunityBG}
                    containerStyle={{
                      width: '100px',
                      height: '100px',
                      borderRadius: 'calc(100px / 2)',
                    }}
                    imgStyle={{ objectFit: 'cover' }}
                  />
                  <p
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                    }}
                  >
                    {t('COMMUNITY_PROFILE.changePictureBg')}
                  </p>
                </div>
              </div>
              <div className='EditCommunity__Data'>
                <div
                  className='EditCommunity__InputContainer'
                  style={{
                    width: '100%',
                    position: 'relative',
                    marginBottom: '10px',
                  }}
                >
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      marginBottom: '5px',
                    }}
                  >
                    {t('EDIT_COMMUNITY.changeName')}
                  </p>
                  {/* <p
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "0",
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                    }}
                  >
                    {t("EDIT_PROFILE.STATUS.visible")}
                  </p> */}
                  <RightComponentItem
                    isWhite={darkMode}
                    name='name'
                    value={name}
                    disabled={editingProp || fetching}
                    onChange={this.handleInputChange}
                    autoComplete='off'
                  />
                </div>
                <div className='EditCommunity__InputContainer' style={{ width: '100%', position: 'relative' }}>
                  <p
                    style={{
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                      marginBottom: '5px',
                    }}
                  >
                    {t('EDIT_COMMUNITY.changeDescription')}
                  </p>
                  {/* <p
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "0",
                      color: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                    }}
                  >
                    {t("EDIT_PROFILE.STATUS.notVisible")}
                  </p> */}
                  <RightComponentItem
                    textArea
                    isWhite={darkMode}
                    name='description'
                    value={description}
                    disabled={editingProp || fetching}
                    onChange={this.handleInputChange}
                    row={4}
                    autoComplete='off'
                  />
                </div>
                <button
                  className='CommunityProfile__Btn'
                  style={{
                    pointerEvents: fetching || deleteLoading ? 'none' : 'unset',
                    opacity: fetching || deleteLoading ? '0.5' : '1',
                  }}
                  disabled={fetching || deleteLoading}
                  onClick={() => this.saveCommunity()}
                >
                  {fetching ? (
                    <AppLoader
                      // style={{ marginTop: '50%', marginBottom: 20 }}
                      size='small'
                      color='#FFF'
                    />
                  ) : (
                    t('EDIT_COMMUNITY.BUTTONS.save')
                  )}
                </button>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 10,
                  }}
                >
                  <button
                    disabled={fetching || deleteLoading}
                    style={{
                      pointerEvents: fetching || deleteLoading ? 'none' : 'unset',
                      opacity: fetching || deleteLoading ? '0.5' : '1',
                    }}
                    className='EditCommunity__Delete-btn'
                    onClick={() => this.onDeleteCall()}
                  >
                    {deleteLoading ? <AppLoader size='small' color='#FFF' /> : t('EDIT_COMMUNITY.BUTTONS.delete')}
                  </button>
                </div>
                {/* <EditProfileButton
                  text={t('EDIT_PROFILE.BUTTONS.continue')}
                  darkMode={darkMode}
                  style={{ margin: '10px 0' }}
                  onClick={onRequestClose}
                /> */}
                {/* <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: darkMode ? WHITE_MAIN : FEDERAL_BLUE,
                  }}
                />
                <EditProfileButton
                  text={t("EDIT_PROFILE.BUTTONS.changeEmail")}
                  darkMode={darkMode}
                  style={{ marginTop: "10px" }}
                  onClick={changeEmailClick}
                />
                <EditProfileButton
                  text={t("EDIT_PROFILE.BUTTONS.changePassword")}
                  darkMode={darkMode}
                  style={{ marginTop: "10px" }}
                  onClick={changePasswordClick}
                /> */}
              </div>
            </div>
            <OptionModal
              isOpen={this.state.isOptionsOpen}
              onRequestClose={() => this.setState({ isOptionsOpen: false })}
              show={
                this.state.selectedImage === 'picture'
                  ? {
                      edit: {
                        display: true,
                        title: t('EDIT_COMMUNITY.BUTTONS.editCommunityPicture'),
                        onClick: () => this.uploadFile(),
                      },
                    }
                  : {
                      edit: {
                        display: true,
                        title: t('EDIT_COMMUNITY.BUTTONS.editBgPicture'),
                        onClick: () => this.uploadFile(5 / 2),
                      },
                    }
              }
            />
            <CropModal
              mobile={this.state.matches}
              isOpen={this.state.isCropperOpen}
              uri={this.state.cropperImage}
              darkMode={darkMode}
              onRequestClose={() => this.setState({ isCropperOpen: false, imageToCropper: null })}
              aspect={this.state.cropperAspect || 1}
              action={() => this.showCroppedImage()}
              onCropComplete={this.onCropComplete}
              zoom={this.state.zoom}
              crop={this.state.crop}
              rotation={this.state.rotation}
              onZoomChange={this.onZoomChange}
              onCropChange={this.onCropChange}
              onRotationChange={this.onRotationChange}
              firstInputProps={{
                type: 'range',
                min: 1,
                max: 4,
                step: 0.1,
                value: this.state.zoom,
                name: 'zoom',
                onChange: this.handleInputChange,
              }}
              secondInputProps={{
                type: 'range',
                max: '360',
                min: 0,
                step: 1,
                value: this.state.rotation,
                name: 'rotation',
                onChange: this.handleInputChange,
              }}
            />
            <Alert
              isOpen={this.state.isModalOpen}
              type={this.state.modalType}
              title={this.state.modalTitle}
              content={this.state.modalContent}
              choose={this.state.modalChoose}
              NOClick={this.state.noAction}
              noButtonText={this.state.noText}
              YESClick={this.state.yesAction}
              yesButtonText={this.state.yesText}
              singleButtonText={this.state.btnTxt}
              onRequestClose={this.state.btnAction}
              loading={this.state.actionsLoader}
            />
          </AlertModal>
        )}
      </Translation>
    );
  }
}

const EditCommunityWithRouter = withRouter(EditCommunity);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    saveCommunityPosts: bindActionCreators(actions.saveCommunityPosts, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCommunityWithRouter);
