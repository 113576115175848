import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isUserValid } from '../../utils/isUserValid';
import './dealsDetails.css';
import { getDealDetails } from '../../components/deals-list/dealsListActions';
import i18n from '../../i18n/i18n';
import VotingItem from '../../components/voting/votingItem';
import { AppLoader } from '../../utils/animations/animations';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';
import { FEDERAL_BLUE } from '../../utils/color-palatte';
import Alert from '../../utils/alert/Alert';

class DealDetails extends React.Component {
  state = {
    fetching: true,
    deal: null,
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    isUserValid(false, this._isMounted);
    if (this._isMounted) this.fetchDeal();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  fetchDeal = () => {
    return new Promise(async (resolve, reject) => {
      const { dealId } = this.props.match.params;
      try {
        const response = await getDealDetails(dealId);
        // console.log("fetchDeal / response: ", response);
        this.setState({
          fetching: false,
          deal: response[0],
        });
        resolve();
      } catch (e) {
        if (e.status === 404) {
          this.setState({ fetching: false });
          resolve();
        } else {
          reject(e);
          // console.log("fetchDeal / error: ", e)
          this.setState({ fetching: false });
          const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
          this.showAlert(true, 'error', error);
        }
      }
    });
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const { deal, fetching } = this.state;
    const { user, darkMode } = this.props;

    // console.log(deal);

    return (
      <div className='DealDetail__Container'>
        {fetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 100px)',
            }}
          >
            <AppLoader color={darkMode ? '#fff' : FEDERAL_BLUE} size='big' />
          </div>
        ) : null}
        {!fetching && deal ? <VotingItem isDone isDeal item={deal} darkMode={darkMode} user={user} /> : null}
        {!fetching && !deal ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 100px)',
            }}
          >
            <NotFoundItem type='deals' isBlue={darkMode} text={i18n.t('DEALS.notFound')} />
          </div>
        ) : null}
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() => this.setState({ isAlertOpen: false })}
        />
      </div>
    );
  }
}

const DealDetailsWithRouter = withRouter(DealDetails);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DealDetailsWithRouter);
