import React, { useState, useCallback, useEffect } from "react";
import { FEDERAL_BLUE } from "../../utils/color-palatte";
import OrnamentBlue from "../../assets/ornament-profile/ornament_blue.svg";
import OrnamentWhite from "../../assets/ornament-profile/ornament_white.svg";
import DefaultPresident from "../../assets/ornament-profile/default_president.png";
import DefaultVicepresident from "../../assets/ornament-profile/default_vice-president.png";
import DefaultCitizen from "../../assets/ornament-profile/default_citizen.png";
import "./ornament-profile.css";
import { Translation } from "react-i18next";
import { AppLoader } from "../../utils/animations/animations";

const OrnamentProfile = ({
  isWhite,
  userPicture,
  profileStyles,
  onlyRank,
  noText,
  userName,
  userRank,
  style,
  className,
  loading,
  capturable,
  userNameStyles,
  userRankStyles,
  onClick
}) => {
  const [src, setSrc] = useState(userPicture);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    setSrc(userPicture)
  }, [userPicture])
  
  const defaultSrc =
    userRank?.toLowerCase() === "president"
      ? DefaultPresident
      : userRank?.toLowerCase() === "vicepresident"
      ? DefaultVicepresident
      : DefaultCitizen;

  const onError = useCallback(() => {
    if (!errored) {
      setSrc(defaultSrc);
      setErrored(true);
    }
  }, [defaultSrc, errored]);

  return (
    <Translation>
      {(t) => (
        <div onClick={onClick} className={`Ornament__Container ${className}`} style={style}>
          <div className="Ornament__Area">
            <img
              src={isWhite ? OrnamentWhite : OrnamentBlue}
              alt=""
              className="Ornament"
              draggable="false"
            />
            <div
              className="Ornament__Profile-border"
              style={{
                border: `4px solid ${isWhite ? "white" : FEDERAL_BLUE}`,
                backgroundImage: capturable ? `url(${src})` : "unset",
                backgroundPosition: capturable ? "center" : "unset",
                backgroundSize: capturable ? "cover" : "unset",
              }}
            >
              {!capturable && (
                <>
                  <img
                    src={loading ? defaultSrc : src || defaultSrc}
                    onError={!loading ? src && onError : null}
                    alt=""
                    className="Ornament__Profile"
                    style={profileStyles}
                    draggable="false"
                  />
                  {loading && (
                    <AppLoader
                      color="#fff"
                      style={{
                        zIndex: "1",
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="Ornament__Profile-data">
            {!onlyRank && !noText && (
              <p
                style={Object.assign(
                  {
                    color: isWhite ? "white" : FEDERAL_BLUE,
                    fontSize:
                      userName && userName.length >= 15 ? "1.3em" : "1.8em",
                    marginTop: userName && userName.length >= 15 ? "10px" : "0",
                  },
                  userNameStyles
                )}
                className="Ornament__UserName"
              >
                {userName}
              </p>
            )}
            {!onlyRank && !noText && (
              <p
                style={Object.assign(
                  { color: isWhite ? "white" : FEDERAL_BLUE },
                  userRankStyles
                )}
                className="Ornament__UserRank-small"
              >
                {userRank && userRank.toLowerCase() === "president"
                  ? t("GENERAL_TEXT.president")
                  : userRank && userRank.toLowerCase() === "vicepresident"
                  ? t("GENERAL_TEXT.vicepresident")
                  : userRank && userRank.toLowerCase() === "citizen"
                  ? t("GENERAL_TEXT.citizen")
                  : userRank
                  ? userRank
                  : t("GENERAL_TEXT.citizen")}
              </p>
            )}
            {onlyRank && !noText && (
              <p
                style={Object.assign(
                  { color: isWhite ? "white" : FEDERAL_BLUE },
                  userRankStyles
                )}
                className="Ornament__UserRank-big"
              >
                {userRank ?? "Citizen"}
              </p>
            )}
          </div>
        </div>
      )}
    </Translation>
  );
};

export default OrnamentProfile;
