import * as types from '../../types/achievements/types'

const initialState = {
  isNewAchievementOpen: false,
  newAchievement: {}
}

const openNewAchievementModal = (state, payload) => ({
  ...state,
  newAchievement: payload.data.newAchievement,
  isNewAchievementOpen: true
})

const closeNewAchievementModal = state => ({...state, isNewAchievementOpen: false })

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_NEW_ACHIEVEMENT_MODAL:
      return openNewAchievementModal(state, action)
    case types.CLOSE_NEW_ACHIEVEMENT_MODAL:
      return closeNewAchievementModal(state, action)
    default:
      return state
  }
}

export default reducer