import { getData } from '../../fetch';

export const getExpenses = (skipItems, limitItems, search, admin) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/gg-expenses/get-expenses-web/?skipItems=${skipItems}&limitItems=${limitItems}&search=${search}&admin=${admin}`
      );
      // console.log("getPosts / response: ", response);
      const expenses = response.body;
      return resolve(expenses);
    } catch (e) {
      // console.log("getPosts / error: ", e);
      return reject(e);
    }
  });
};

export const getExpenseDetails = (expenseId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getData(
        `/gg-expenses/get-expense-details-web/?expenseId=${expenseId}`
      );
      // console.log("getVotingDetails / response: ", response);
      const expense = response.body;
      return resolve(expense);
    } catch (e) {
      // console.log("getVotingDetails / error: ", e);
      return reject(e);
    }
  });
};
