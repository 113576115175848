import React from 'react';
import i18n from '../../i18n/i18n';
import moment from 'moment';
import './communityPosts.css';
import PostCard from '../../components/post/postcard';
import { getPosts, like, unlike, getPost, deletePost, reportPost } from '../dashboard/dashboard-actions';
import { withRouter } from 'react-router-dom';
import { messaging } from 'firebase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { throttle } from 'lodash';
import * as colors from '../../utils/color-palatte';
import * as routes from '../../routes/routes';
import getStat from '../../utils/getStat';
import { isUserValid } from '../../utils/isUserValid';
import editUser from '../../utils/editUser';
import ScrollableDiv from '../../components/scrollable-div/scrollableDiv';
import SearchFilter from '../../components/search-filter/SearchFilter';
import { AppLoader } from '../../utils/animations/animations';
import { NotFoundItem } from '../../components/not-found-item/notFoundItem';
// import UpdateModal from '../../components/update/UpdateModal';

class CommunityPosts extends React.PureComponent {
  state = {
    fetching: false,
    showPositionsPosts: '',
    skipItems: 0,
    limitItems: 5,
    plusPostNumber: 5,
    keepIncreasingCounter: false,
    rangeDatesFileringOpen: false,
    search: '',
    toDate: '',
    sinceDate: '',
    searchBy: '',
    publicUserPosts: [],
    isThereUnreadMessages: '',
    // communityPosts: [],
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    if (this._isMounted) {
      const { user } = this.props;
      const { communityId } = this.props.match.params;
      if (user?.community?._id !== communityId) {
        this.props.history.push(routes.COMMUNITIES_ROUTE);
      }

      const isThereUnreadMessages = localStorage.getItem('isThereUnreadMessages');
      this.setState({ isThereUnreadMessages: isThereUnreadMessages });
    }
    if (this._isMounted) await this.getStats();
    if (this._isMounted) await this.checkPermission();
    isUserValid(false, this._isMounted);
    if (this._isMounted) this.onRefreshPosts();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  // saveAdminCode = async () => {
  //   if (this.state.adminCode) {
  //     localStorage.setItem('adminCode', this.state.adminCode);
  //     this.toggleAdminModal();
  //   } else {
  //     this.toggleMessageModal('warning', true, i18n.t('SIGNIN.needAdminCode'));
  //   }
  // };

  checkPermission = () => {
    return new Promise(async (resolve, _) => {
      try {
        const enabled = await messaging().hasPermission();
        if (enabled) {
          await this.getToken();
        } else {
          await this.requestUserPermission();
        }
        resolve();
      } catch (e) {
        resolve();
      }
    });
  };

  getToken = () => {
    return new Promise(async (resolve, _) => {
      try {
        const pushToken = localStorage.getItem('pushToken');
        // console.log("Dashboard / getToken / pushToken: ", pushToken);
        if (!pushToken) {
          const fcmToken = await messaging().getToken();
          // console.log("Dashboard / getToken / fcmToken: ", fcmToken);
          if (fcmToken) {
            await editUser({ prop: 'pushToken', value: fcmToken });
            localStorage.getItem('pushToken', fcmToken);
          }
        }

        resolve();
      } catch (e) {
        resolve();
      }
    });
  };

  getStats = () => {
    return new Promise((resolve, _) => {
      this.setState({ loading: true }, async () => {
        try {
          const users = await getStat('users');
          const positions = await getStat('positions');
          // console.log("officialVotings: ", officialVotings);
          this.setState({
            positions,
            users,
          });
          resolve();
        } catch (e) {
          resolve();
          // console.log("getStats / error: ", e);
        }
      });
    });
  };

  fetchPosts = () => {
    const {
      skipItems,
      limitItems,
      fetching,
      search,
      searchBy,
      toDate,
      sinceDate,
      showPositionsPosts,
      // publicUserPosts = [],
      // communityPosts = [],
    } = this.state;
    const { saveCommunityPosts, user, isHome, communityPosts } = this.props;
    const userId = this.props.match?.params?.userId;
    const community = this.props.match?.params?.communityId;
    // const isHome = this.props.route.name === DASHBOARD_ROUTE
    // console.log("this.props.route.params.userId: ", userId)
    // console.log("userPosts: ", userPosts)
    // console.log("isHome: ", isHome)
    // console.log("user: ", user)
    if (!fetching) {
      // console.log('fetchPosts');
      this.setState({ fetching: true }, async () => {
        try {
          const newPosts = await getPosts({
            userId: userId ? userId : isHome ? user._id : '',
            skipItems,
            limitItems,
            search,
            searchBy,
            sinceDate: sinceDate ? sinceDate : '',
            toDate: toDate ? toDate : '',
            showPositionsPosts,
            userPosts: userId || '',
            communityPost: community || '',
            blockList: user.blockList
          });
          // console.log("PostsSearch / getPosts / newPosts: ", newPosts);
          let finalPosts = [];
          const data = communityPosts;
          // MORE RECENT AND SEARCH ACTIVE
          if (search && !searchBy) {
            if (newPosts.length > 0) {
              finalPosts = newPosts
                .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
                .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
            } else {
              finalPosts = data
                .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
                .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
            }
          }
          // MORE RECENT ACTIVE
          if (!search && !searchBy) {
            finalPosts = newPosts
              .concat(data)
              .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
              .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
          }
          // SEARH BY ACTIVE
          if (searchBy) {
            finalPosts = newPosts.concat(data).filter((item, index, self) => index === self.findIndex((t) => t._id === item._id));
            // .sort((a, b) => moment(b.creationDate) - moment(a.creationDate))
          }

          saveCommunityPosts(finalPosts);

          this.setState({
            fetching: false,
            keepIncreasingCounter: newPosts.length > 0,
          });
        } catch (e) {
          // console.log("PostsSearch / fetchPosts / error: ", e)
          this.setState({ fetching: false });
          const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
          this.setState({
            isMessageModalOpen: true,
            messageModalMessage: error,
            messageModalType: 'error',
          });
        }
      });
    }
  };

  onRefreshPosts = (hardRefres = true) => {
    const { saveCommunityPosts } = this.props;
    const { plusPostNumber } = this.state;

    if (hardRefres) {
      saveCommunityPosts([]);
    }

    this.setState({ skipItems: 0, limitItems: plusPostNumber }, () => {
      this.fetchPosts();
    });
  };

  onEndReached = () => {
    if (!this._isMounted) return;
    // console.log("onEndReached");
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchPosts();
        }
      );
    } else {
      this.fetchPosts();
    }
  };

  likeFunction = (postId) => {
    const {
      //updateSearchPostsItem,
      //updateUserPostsItem,
      user,
    } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        // await like(userId, postId);
        await like(user._id, postId);
        // console.log("likeFunction / response: ", response);
        await this.updatePostItem(postId);
        this.fetchPosts();
        resolve();
      } catch (e) {
        // console.log("likeFunction / error: ", e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          reject()
          return;
        }
        this.setState({
          isMessageModalOpen: true,
          messageModalMessage: error,
          messageModalType: 'error',
        });
        reject();
      }
    });
  };

  unlikeFunction = (postId) => {
    const {
      // updateSearchPostsItem,
      // updateUserPostsItem,
      user,
    } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        // await unlike(userId, postId);
        await unlike(user._id, postId);
        // console.log("unlikeFunction / response: ", response);
        await this.updatePostItem(postId);
        resolve();
      } catch (e) {
        // console.log("unlikeFunction / error: ", e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
        if (e?.data?.error?.code === 'user-not-verified' || e?.data?.error?.details?.code === 'user-not-verified') {
          this.props.openVerifyModal();
          reject()
          return;
        }
        this.setState({
          isMessageModalOpen: true,
          messageModalMessage: error,
          messageModalType: 'error',
        });
        reject();
      }
    });
  };

  throtled = throttle(async (hardRefres = false) => {
    this.onRefreshPosts(hardRefres);
  }, 1000);

  removePost = (postId, userId, files) => {
    const { removeSearchPostsItem, removeUserPostsItem, removeProfilePostsItem, removeCommunityPostsItem } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await deletePost(postId, userId, files);
        this.setState({
          isMessageModalOpen: true,
          messageModalMessage: response.body,
          messageModalType: 'success',
        });
        removeSearchPostsItem(postId);
        removeUserPostsItem(postId);
        removeProfilePostsItem(postId);
        removeCommunityPostsItem(postId);
        this.onRefreshPosts();
        resolve();
      } catch (e) {
        // console.log("removePost / error: ", e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
        this.setState({
          isMessageModalOpen: true,
          messageModalMessage: error,
          messageModalType: 'error',
        });
        reject(e);
      }
    });
  };

  reportPost = (postId) => {
    const { user } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await reportPost(user._id, postId);
        this.setState({
          isMessageModalOpen: true,
          messageModalMessage: response.body,
          messageModalType: 'success',
        });
        resolve();
      } catch (e) {
        // console.log("removePost / error: ", e);
        const error = e && e.data && e.data.error ? e.data.error.message : i18n.t('ERRORS.serverError');
        this.setState({
          isMessageModalOpen: true,
          messageModalMessage: error,
          messageModalType: 'error',
        });
        reject(e);
      }
    });
  };

  searchByFunction = (name) => {
    if (name === 'positions') {
      this.setState({ showPositionsPosts: true });
    } else {
      this.setState({ showPositionsPosts: false });
    }

    this.setState({ searchBy: name === 'positions' ? '' : name }, () => {
      this.onRefreshPosts(true);
    });
  };

  //VOTING

  updatePostItem = async (postId) => {
    const { updateUserPostsItem, updateSearchPostsItem, updateCommunityPostsItem } = this.props;
    // console.log("updatePostItem / postId: ", postId)
    const postUdated = await getPost(postId);
    // console.log("postUdated: ", postUdated);
    updateUserPostsItem(postUdated[0]);
    updateSearchPostsItem(postUdated[0]);
    updateCommunityPostsItem(postUdated[0]);
    this.fetchPosts();
  };

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  setValue = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { fetching } = this.state;
    const {
      user,
      darkMode,
      communityPosts,
      // showFilter,
      // isHome
    } = this.props;
    // console.log("this.props.route.name: ", this.props.route.name)
    // const isHome = this.props.route.name === DASHBOARD_ROUTE
    // console.log("isHome: ", isHome)
    const data = communityPosts;
    // console.log("data", data);
    // console.log("searchPosts: ", searchPosts);
    return (
      <ScrollableDiv
        onEndReach={this.onEndReached}
        style={{
          boxSizing: 'border-box',
          padding: 10,
        }}
      >
        <SearchFilter
          onRefresh={this.onRefreshPosts}
          setValue={this.setValue}
          inputPlaceholder={i18n.t('COMMUNITIES.inputPlaceholderPosts')}
        />
        {fetching && (data?.length === 0 || !data) ? (
          <div
            style={{
              width: '100%',
              height: 'calc(100vh - 200px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AppLoader size='big' color={darkMode ? '#FFF' : colors.FEDERAL_BLUE} />
          </div>
        ) : null}
        {!fetching && (data?.length === 0 || !data) ? (
          <div
            style={{
              width: '100%',
              height: 'calc(100vh - 200px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NotFoundItem text={i18n.t('DASHBOARD.noPostFound')} type='post' isBlue={darkMode} />
          </div>
        ) : null}
        {data ? data.map((item, i) => <PostCard item={item} key={i} user={user} darkMode={darkMode} />) : null}
        {fetching && data?.length > 0 ? (
          <div
            style={{
              width: '100%',
              heigth: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AppLoader size='40px' color={darkMode ? '#fff' : colors.FEDERAL_BLUE} />
          </div>
        ) : null}
        {!fetching && data?.length > 0 ? (
          <div
            style={{
              width: '100%',
              heigth: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ color: darkMode ? '#FFF' : '#000' }}>{i18n.t('COMMUNITIES.noMorePosts')}</p>
          </div>
        ) : null}
      </ScrollableDiv>
    );
  }
}

const CommunityPostWithRouter = withRouter(CommunityPosts);

const mapDispatchToProps = (dispatch) => {
  return {
    saveCommunityPosts: bindActionCreators(actions.saveCommunityPosts, dispatch),
    updateCommunityPostsItem: bindActionCreators(actions.updateCommunityPostsItem, dispatch),
    removeCommunityPostsItem: bindActionCreators(actions.removeCommunityPostsItem, dispatch),
    openVerifyModal: bindActionCreators(actions.openVerifyModal, dispatch)
  };
};

const mapStateToProps = (state) => {
  const { user, darkMode, communityPosts } = state.user;
  return {
    user,
    darkMode,
    communityPosts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityPostWithRouter);
