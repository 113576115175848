import React from 'react';
import CopyLink from '../../../assets/whitepaper/link_icon.svg';
import CopyLinkWhite from '../../../assets/whitepaper/link_icon_white.svg';
import ContentIcon from '../../../assets/whitepaper/content_icon.svg';
import ContentIconWhite from '../../../assets/whitepaper/content_icon_white.svg';
import * as colors from '../../../utils/color-palatte';
import CheckIcon from '../../../assets/home/roadmap/check_icon.svg';
import { ParsedText } from '../../../utils/text-utils/text-utils';
import styles from './styles.module.css';
import i18n from '../../../i18n/i18n';

export const Title = (props) => {
  const { darkMode, text, children, onClick, hash, location, ...rest } = props;

  React.useEffect(() => {
    if (location && location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  });
  return (
    <div className={styles.Title} id={hash} {...rest}>
      <h1 style={{ color: darkMode ? colors.FEDERAL_BLUE_SOFT : colors.FEDERAL_BLUE }}>{children || text}</h1>
      {onClick && (
        <img
          src={darkMode ? CopyLinkWhite : CopyLink}
          alt='copy url'
          onClick={onClick}
          title={i18n.t('WHITEPAPER.copyUrl')}
          draggable='false'
        />
      )}
    </div>
  );
};

export const SubTitle = (props) => {
  const { darkMode, text, children, color, onClick, hash, location, checked, ...rest } = props;

  React.useEffect(() => {
    if (location && location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  });
  return (
    <div className={styles.SubTitle} style={{ scrollMarginTop: 70 }} id={hash} {...rest}>
      <h2 style={{ color: color ? color : darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN }}>{children || text}</h2>
      {checked && (
        <div className={styles.CheckIcon}>
          <img style={{ cursor: "default" }} src={CheckIcon} alt='checked' draggable='false' />
        </div>
      )}
      {onClick && (
        <img
          src={darkMode ? CopyLinkWhite : CopyLink}
          alt='copy url'
          onClick={onClick}
          title={i18n.t('WHITEPAPER.copyUrl')}
          draggable='false'
        />
      )}
    </div>
  );
};

export const LastTitle = (props) => {
  const { darkMode, text, children, onClick, hash, location, ...rest } = props;

  React.useEffect(() => {
    if (location && location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  });
  return (
    <div className={styles.LastTitle} id={hash} style={{ scrollMarginTop: 70 }} {...rest}>
      <h3 style={{ color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN }}>{children || text}</h3>
      {onClick && (
        <img
          src={darkMode ? CopyLinkWhite : CopyLink}
          alt='copy url'
          onClick={onClick}
          title={i18n.t('WHITEPAPER.copyUrl')}
          draggable='false'
        />
      )}
    </div>
  );
};

export const Paragraph = (props) => {
  const { darkMode, text, style, children, ...rest } = props;
  return (
    <p {...rest} style={Object.assign({ color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN }, style)}>
      {children || text}
    </p>
  );
};

export const ParsedParagraph = (props) => {
  const { darkMode, text, style, ...rest } = props;
  return (
    <ParsedText
      style={Object.assign({ color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN, whiteSpace: 'pre-line' }, style)}
      markStyle={{ color: darkMode ? colors.FEDERAL_GREEN : colors.FEDERAL_BLUE, fontWeight: 'bold' }}
      {...rest}
    />
  );
};

export const Divider = (props) => {
  const { darkMode, style, half, ...rest } = props;
  return (
    <div
      className={styles.Divider}
      style={Object.assign(
        { backgroundColor: darkMode ? colors.UNSATURATED_BLACK_BLUE : colors.WHITE_DARK, width: half ? '50%' : '100%' },
        style
      )}
      {...rest}
    />
  );
};

export const Banner = (props) => {
  const { style, src, ...rest } = props;
  return (
    <div className={styles.Banner} {...rest}>
      <img src={src} alt='' draggable='false' />
    </div>
  );
};

export const ContentTitle = (props) => {
  const { darkMode, text, children, ...rest } = props;
  return (
    <div className={styles.ContentTitle} {...rest}>
      <img src={darkMode ? ContentIconWhite : ContentIcon} alt='' draggable='false' />
      <h2 style={{ color: darkMode ? colors.WHITE_MAIN : colors.GRAY_MAIN }}>{children || text}</h2>
    </div>
  );
};

export const ListElement = (props) => {
  const { img, imgStyles, content, ...rest } = props;
  return (
    <div className={styles.ListElement} {...rest}>
      {img && <img className={styles.ListElement__Img} style={imgStyles} src={img} alt='' draggable='false' />}
      <div className={styles.ListElement__Content}>{content}</div>
    </div>
  );
};
