import {
  SAVE_USER,
  LOGOUT,
  SAVE_USER_POSTS,
  TOGGLE_DARK_MODE,
  UPDATE_USER_POSTS_ITEM,
  REMOVE_SEARCH_POSTS_ITEM,
  REMOVE_USER_POSTS_ITEM,
  REMOVE_PROFILE_POSTS_ITEM,
  OPEN_MESSAGE_MODAL,
  CLOSE_MESSAGE_MODAL,
  SHOW_PAYMENT_MODAL_AGREEMENT,
  CLOSE_PAYMENT_MODAL_AGREEMENT,
  SAVE_VOTING_REQUEST,
  SAVE_VOTINGS,
  UPDATE_VOTING_REQUEST,
  UPDATE_VOTING,
  SAVE_MENTIONS,
  UPDATE_SEARCH_POSTS_ITEM,
  SAVE_SEARCH_POSTS,
  SAVE_COMMUNITY_POSTS,
  UPDATE_COMMUNITY_POSTS_ITEM,
  REMOVE_COMMUNITY_POSTS_ITEM,
  SAVE_PROFILE_POSTS,
  SAVE_HAS_NEW_NOTIFICATIONS,
  SAVE_USER_ACHIEVEMENTS,
  SAVE_USER_CHATS,
  OPEN_DELETE_SUCCESS_MODAL,
  CLOSE_DELETE_SUCCESS_MODAL,
  OPEN_VERIFY_MODAL,
  CLOSE_VERIFY_MODAL,
  OPEN_DIALOG_MODAL,
  CLOSE_DIALOG_MODAL
} from "../../types/user/types";

const saveUser = (user) => ({
  type: SAVE_USER,
  data: user,
});

const saveMentions = (mentions) => ({
  type: SAVE_MENTIONS,
  data: mentions,
});

const saveUserPosts = (posts) => ({
  type: SAVE_USER_POSTS,
  data: posts,
});

const saveUserChats = (chats) => ({
  type: SAVE_USER_CHATS,
  data: chats,
});

const saveUserAchievements = (achievements) => ({
  type: SAVE_USER_ACHIEVEMENTS,
  data: achievements,
});

const saveVotingRequests = (voting) => ({
  type: SAVE_VOTING_REQUEST,
  data: voting,
});

const saveVotings = (voting) => ({
  type: SAVE_VOTINGS,
  data: voting,
});

const showPaymentModalAgreement = () => ({
  type: SHOW_PAYMENT_MODAL_AGREEMENT,
});

const updateVotingRequest = (item) => ({
  type: UPDATE_VOTING_REQUEST,
  data: item,
});

const updateVoting = (item) => ({
  type: UPDATE_VOTING,
  data: item,
});

const closePaymentModalAgreement = () => ({
  type: CLOSE_PAYMENT_MODAL_AGREEMENT,
});

const toggleDarkMode = () => ({
  type: TOGGLE_DARK_MODE,
});

const logOut = () => ({
  type: LOGOUT,
});

const updateUserPostsItem = (item) => ({
  type: UPDATE_USER_POSTS_ITEM,
  data: item,
});

const removeSearchPostsItem = (id) => ({
  type: REMOVE_SEARCH_POSTS_ITEM,
  postId: id,
});

const removeUserPostsItem = (id) => ({
  type: REMOVE_USER_POSTS_ITEM,
  postId: id,
});

const removeProfilePostsItem = (item) => ({
  type: REMOVE_PROFILE_POSTS_ITEM,
  postId: item,
});

const openMessageModal = () => ({
  type: OPEN_MESSAGE_MODAL,
});

const closeMessageModal = () => ({
  type: CLOSE_MESSAGE_MODAL,
});

const updateSearchPostsItem = (item) => ({
  type: UPDATE_SEARCH_POSTS_ITEM,
  data: item,
});

const saveSearchPosts = (posts) => ({
  type: SAVE_SEARCH_POSTS,
  data: posts,
});

const saveCommunityPosts = (posts) => ({
  type: SAVE_COMMUNITY_POSTS,
  data: posts,
});

const updateCommunityPostsItem = (item) => ({
  type: UPDATE_COMMUNITY_POSTS_ITEM,
  data: item,
});

const removeCommunityPostsItem = (id) => ({
  type: REMOVE_COMMUNITY_POSTS_ITEM,
  postId: id,
});

const updateSearchPosts = (posts) => ({
  type: SAVE_SEARCH_POSTS,
  data: posts,
});

const updateUserPosts = (posts) => ({
  type: SAVE_USER_POSTS,
  data: posts,
});

const updateProfilePosts = (posts) => ({
  type: SAVE_PROFILE_POSTS,
  data: posts,
});

const saveProfilePosts = (posts) => ({ type: SAVE_PROFILE_POSTS, data: posts });

const saveHasNewNotifications = (boolean) => ({
  type: SAVE_HAS_NEW_NOTIFICATIONS,
  data: boolean,
})

const openDeleteSuccesModal = () => ({
  type: OPEN_DELETE_SUCCESS_MODAL,
})

const closeDeleteSuccesModal = () => ({
  type: CLOSE_DELETE_SUCCESS_MODAL,
})

const openVerifyModal = () => ({
  type: OPEN_VERIFY_MODAL,
})

const closeVerifyModal = () => ({
  type: CLOSE_VERIFY_MODAL,
})

const openDialogModal = () => ({
  type: OPEN_DIALOG_MODAL,
})

const closeDialogModal = () => ({
  type: CLOSE_DIALOG_MODAL,
})

const actionCreators = {
  saveUser,
  logOut,
  saveUserPosts,
  toggleDarkMode,
  updateUserPostsItem,
  removeSearchPostsItem,
  removeUserPostsItem,
  removeProfilePostsItem,
  openMessageModal,
  closeMessageModal,
  showPaymentModalAgreement,
  closePaymentModalAgreement,
  saveVotingRequests,
  saveVotings,
  updateVotingRequest,
  updateVoting,
  saveMentions,
  updateSearchPostsItem,
  saveSearchPosts,
  saveCommunityPosts,
  updateCommunityPostsItem,
  removeCommunityPostsItem,
  updateSearchPosts,
  updateUserPosts,
  updateProfilePosts,
  saveProfilePosts,
  saveHasNewNotifications,
  saveUserAchievements,
  saveUserChats,
  openDeleteSuccesModal,
  closeDeleteSuccesModal,
  openVerifyModal,
  closeVerifyModal,
  openDialogModal,
  closeDialogModal,
};

export { actionCreators };
