import React from 'react';
import './log-in.css';
import SafeLINK from '../../utils/safe-link';
// import loginBg from '../../assets/log-in/login-bg.svg';
import RegularInput from '../../utils/app-inputs/regular-input/regular-input';
import PasswordInput from '../../utils/app-inputs/password-input/password-input';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as actions } from '../../redux/actions/user/actions';
import { emailsRegex } from '../../utils/regex';
import { login } from './login-actions';
import { resendToken } from './login-actions';
import i18n from '../../i18n/i18n';
import Alert from '../../utils/alert/Alert';
import SendButton from '../../utils/send-button/send-button';
import { AppLoader } from '../../utils/animations/animations';
import { withRouter } from 'react-router';
import * as routes from '../../routes/routes';
import Global1 from '../../assets/log-in/global-1.svg';
import Global2 from '../../assets/log-in/global-2.svg';
import Global3 from '../../assets/log-in/global-3.svg';
import Global4 from '../../assets/log-in/global-4.svg';
import Global5 from '../../assets/log-in/global-5.svg';
import bgMobile from '../../assets/log-in/login-bg.png';
import { store } from '../../redux/configureStore';
// import SlideCaptchaModal from '../../components/slide-captcha-modal/SlideCaptchaModal';
import * as types from '../../redux/types/user/types';
import VerificationModal from '../../components/verification-modal/verificationModal';
class Login extends React.Component {
  state = {
    loading: false,
    // isMessageModalOpen: false,
    email: '',
    password: '',
    isMenuOpen: false,
    currentImg: 0,
    images: [Global1, Global2, Global3, Global4, Global5],
    matches: window.matchMedia('(max-width: 750px)').matches,
  };

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    if (this._isMounted) {
      this.interval = setInterval(() => this.changeBackgroundImage(), 3000);
      const handler = (e) => this.setState({ matches: e.matches });
      window.matchMedia('(max-width: 750px)').addEventListener('change', handler);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    clearInterval(this.interval);
    window.matchMedia('(max-width: 750px)').removeEventListener('change', this.handler);
  };

  changeBackgroundImage = () => {
    // console.log("interval");
    let newCurrentImg = 0;
    const { images, currentImg } = this.state;
    const noOfImages = images.length;

    if (currentImg !== noOfImages - 1) {
      newCurrentImg = currentImg + 1;
    }

    this.setState({ currentImg: newCurrentImg });
  };

  resendConfirmEmail = () => {
    const { email } = this.state;
    this.setState({ loading: true }, async () => {
      try {
        // eslint-disable-next-line
        const response = await resendToken(email.trim());
        // console.log("resendConfirmEmail / response: ", response)
        // this.toggleMessageModal('success', true, response.body.message);
        this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'success',
          modalContent: response.body.message,
          modalChoose: false,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      } catch (e) {
        // console.log("resendConfirmEmail / error: ", e)
        this.setState({ loading: false });
        const error = e && e.data && e.data.error ? e.data.error : null;
        const errorMessage =
          error && error.message && error.message[0] && error.message[0].msg
            ? error.message[0].msg
            : error && error.message
              ? error.message
              : i18n.t('ERRORS.serverError');
        // this.toggleMessageModal('error', true, errorMessage);
        this.setState({
          isModalOpen: true,
          modalType: 'wrong',
          modalContent: errorMessage,
          btnAction: () => this.setState({ isModalOpen: false }),
        });
      }
    });
  };

  LoginFunction = (event) => {
    if (event) {
      event.preventDefault();
    }

    // if (!('indexedDB' in window)) {
    //   this.toggleMessageModal('error', true, i18n.t('SIGNIN.ggDontSupportBrower'))
    //   return;
    // }

    const { email, password } = this.state;
    const { toggleDarkMode, darkMode } = this.props;

    if (email && password && email.match(emailsRegex)) {
      this.setState({ loading: true }, async () => {
        try {
          const actualDeviceId = localStorage.getItem("deviceTemporalId")

          
          let data = {
            email: email.trim(),
            password: password.trim()
          };

          if (!actualDeviceId || actualDeviceId === undefined || actualDeviceId === null) {
            const deviceTemporalId = String(Math.random())

            localStorage.setItem("deviceTemporalId", deviceTemporalId)
            data.deviceId = deviceTemporalId
          } else {
            data.deviceId = actualDeviceId
          }

          if (!darkMode || darkMode === false) {
            toggleDarkMode();
          }
          const response = await login(data);
          // console.log('signIn() response: ', response);

          // if(!response?.body?.user?.isVerified){
          //   this.setState({
          //     loading: false,
          //     isModalOpen: true,
          //     modalType: 'warning',
          //     modalTitle: i18n.t('LOGIN.unverifiedTitle'),
          //     modalContent: i18n.t("GENERAL_TEXT.verifyPlease"),
          //     modalChoose: true,
          //     noAction: () => this.setState({ isModalOpen: false }),
          //     noText: i18n.t('LOGIN.cancel'),
          //     yesAction: () => this.resendConfirmEmail(),
          //     yesText: i18n.t('LOGIN.resend'),
          //     // isMessageModalOpen: true,
          //     // title: i18n.t('ALERT.title'),
          //     // messageModalMessage: errorMessage,
          //     // messageModalType: 'warning',
          //     // isYesChooseModal: true,
          //     // yesButtonText: i18n.t('SIGNIN.resend'),
          //     // noButtonText: i18n.t('DASHBOARD.cancel'),
          //   });
          //   return
          // }
          /* if (response.body.user.admin) {
          const adminId = await Network.getMacAddressAsync('wlan0');
          await SecureStore.setItemAsync('adminId', adminId);
        } */

          if (response.body.user.hasTwoStepVerification) {
            this.showVerification(true, response, password)
            this.setState({ loading: false })
          } else {
            this.onLoginSucces(response);
          }
        } catch (e) {
          // console.log("signInFuction error: ", e);
          const error = e && e.data && e.data.error ? e.data.error : null;
          // eslint-disable-next-line
          const errorMessage =
            error && error.message && error.message[0] && error.message[0].msg
              ? error.message[0].msg
              : error && error.message
                ? error.message
                : i18n.t('LOGIN.ERRORS.serverError');
          // this.stopLoader();
          this.setState({ loading: false });
          // console.log('signInFuction error: ', error);
          // if (error && error.code === 'user-not-verified') {
          //   this.setState({
          //     loading: false,
          //     isModalOpen: true,
          //     modalType: 'warning',
          //     modalTitle: i18n.t('LOGIN.unverifiedTitle'),
          //     modalContent: errorMessage,
          //     modalChoose: true,
          //     noAction: () => this.setState({ isModalOpen: false }),
          //     noText: i18n.t('LOGIN.cancel'),
          //     yesAction: () => this.resendConfirmEmail(),
          //     yesText: i18n.t('LOGIN.resend'),
          //   });
          // } else {
          // this.toggleMessageModal('error', true, errorMessage)
          this.setState({
            loading: false,
            isModalOpen: true,
            modalType: 'wrong',
            modalContent: errorMessage,
            modalChoose: false,
            btnAction: () => this.setState({ isModalOpen: false }),
          });
          // console.log('error:', errorMessage)
          // }
        }
      });
    } else {
      //this.stopLoader()
      if (email && !email.match(emailsRegex)) {
        // this.toggleMessageModal('warning', true, i18n.t('ERRORS.invalidEmail'))
        this.setState({
          loading: false,
          isModalOpen: true,
          modalType: 'warning',
          modalContent: i18n.t('LOGIN.ERRORS.invalidEmail'),
          btnAction: () => this.setState({ isModalOpen: false }),
        });
        return false;
      }
      // if(!this.state.captchaSuccess){
      //   this.setState({
      //     slideCaptchaModalIsOpen: true,
      //     loading: false,
      //     isModalOpen: false,
      //    })
      //    return
      // }
      // this.toggleMessageModal('warning', true, i18n.t('ERRORS.missingFields'))
      this.setState({
        loading: false,
        isModalOpen: true,
        modalType: 'warning',
        modalContent: i18n.t('LOGIN.ERRORS.missingFields'),
        btnAction: () => this.setState({ isModalOpen: false }),
      });
    }
  };

  onLoginSucces = (data) => {
    store.dispatch({ type: types.SAVE_USER_HAS_ENTERED, data: true });
    localStorage.setItem('token', data.body.token);
    localStorage.setItem('userId', data.body.user._id);
    this.props.saveUser(data.body.user);
    // this.stopLoader();
    // this.resetState();
    this.setState({ loading: false });
    const query = new URLSearchParams(this.props.location.search)
    const redirect = query.get('redirect')
    if (redirect) {
      this.props.history.push(`/${redirect}`)
    }
    else if (this.props.location.pathname !== routes.LOGIN_ROUTE) {
      this.props.history.push(`${this.props.location.pathname}`);
    } else {
      this.props.history.push(routes.DASHBOAR_ROUTE);
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  showModal = (
    isOpen,
    type,
    title,
    content,
    progress,
    choose,
    NOClick,
    noButtonText,
    YESClick,
    yesButtonText,
    singleButtonText,
    onRequestClose
  ) => {
    const that = this;
    that.setState({
      isModalOpen: isOpen,
      modalType: type,
      modalTitle: title,
      modalContent: content,
      loading: progress,
      modalChoose: choose,
      noAction: NOClick,
      noText: noButtonText,
      yesAction: YESClick,
      yesText: yesButtonText,
      btnTxt: singleButtonText,
      btnAction: onRequestClose,
    });
  };

  // componentDidMount = () => {
  //   this.setState({
  //     loading: false,
  //     isModalOpen: true,
  //     modalType: 'warning',
  //     modalTitle: i18n.t('LOGIN.unverifiedTitle'),
  //     modalContent: 'errorMessage',
  //     modalChoose: true,
  //     noAction: () => this.setState({ isModalOpen: false }),
  //     noText: i18n.t('LOGIN.cancel'),
  //     yesAction: () => this.resendConfirmEmail(),
  //     yesText: i18n.t('LOGIN.resend'),
  //   });
  // }

  showVerification = (isOpen, data, password) => {
    this.setState({
      isVerifyOpen: isOpen,
      verifyData: data,
      verifyPassword: password,
    });
  };

  render() {
    const { email, password, loading, currentImg, matches, isVerifyOpen, verifyData, verifyPassword } = this.state;

    const that = this;

    const images = {
      0: Global1,
      1: Global2,
      3: Global3,
      4: Global4,
      5: Global5,
    };

    // console.log(this.props)

    // console.log(this.props.location);

    return (
      <Translation>
        {(t) => (
          <div className='login'>
            {/* <img src={loginBg} alt='' className='login__BG' /> */}
            <img alt='' src={images[currentImg] || Global1} className='world-image-bg' />
            {matches && <img alt='' src={bgMobile} className='bgMobile' />}
            <form className='Login__Container' onSubmit={(event) => that.LoginFunction(event)}>
              <h1 className='Login__H1'>Login</h1>
              <RegularInput
                placeholder={t('LOGIN.email')}
                isWhite
                name='email'
                value={email}
                onChange={that.handleInputChange}
                disabled={loading}
              />
              <PasswordInput
                placeholder={t('LOGIN.password')}
                isWhite
                name='password'
                value={password}
                onChange={that.handleInputChange}
                disabled={loading}
              />
              <SafeLINK
                dangerousLINK='/restore-password'
                className='login__forgot'
                text={t('LOGIN.forgot')}
                style={loading ? { opacity: '.5', pointerEvents: 'none' } : undefined}
              />
              <SendButton
                className='login__button'
                textContent={t('LOGIN.login')}
                disabled={loading}
                style={{ pointerEvents: loading ? 'none' : undefined }}
                renderDisabled={<AppLoader size='small' />}
              />
              <div className='login__sing-up'>
                <p>{t('LOGIN.anyAccount')}</p>
                <SafeLINK dangerousLINK='/sign-up' className='login__sing-up-Btn' text={t('LOGIN.signUp')} />
              </div>
            </form>
            {/* <button
              className='login__button'
              onClick={() => this.props.logOut()}
            >
              Logout
            </button> */}
            <Alert
              isOpen={that.state.isModalOpen}
              type={that.state.modalType}
              title={that.state.modalTitle}
              content={that.state.modalContent}
              progress={that.state.loading}
              choose={that.state.modalChoose}
              NOClick={that.state.noAction}
              noButtonText={that.state.noText}
              YESClick={that.state.yesAction}
              yesButtonText={that.state.yesText}
              singleButtonText={that.state.btnTxt}
              onRequestClose={that.state.btnAction}
            />
            {/* <SlideCaptchaModal
              isOpen={this.state.slideCaptchaModalIsOpen}
              onRequestClose={() => this.setState({ slideCaptchaModalIsOpen: false})}
              onSuccess={() => this.setState({ captchaSuccess: true }, () => this.LoginFunction())}
            /> */}
            <VerificationModal isOpen={isVerifyOpen} data={verifyData} existPassword={verifyPassword} onRequestClose={() => this.setState({ isVerifyOpen: false })} />
          </div>
        )}
      </Translation>
    );
  }
}

const LoginWhitRouter = withRouter(Login);

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDarkMode: bindActionCreators(actions.toggleDarkMode, dispatch),
    saveUser: bindActionCreators(actions.saveUser, dispatch),
    logOut: bindActionCreators(actions.logOut, dispatch),
  };
};

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginWhitRouter);
