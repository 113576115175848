import i18n from "../i18n/i18n"

const handlePaymentStatus = (type) => {
  if (type === "succeeded") {
    return i18n.t('PAYMENTS_LIST.succeeded')
  }
  if (type === "processing") {
    return i18n.t('PAYMENTS_LIST.processing')
  }
  if (type === "requires_payment_method") {
    return i18n.t('PAYMENTS_LIST.succeeded')
    // return i18n.t('PAYMENTS_LIST.requires_payment_method')
  }
  if (type === "requires_confirmation") {
    return i18n.t('PAYMENTS_LIST.requires_confirmation')
  }
  if (type === "canceled") {
    return i18n.t('PAYMENTS_LIST.canceled')
  }
  return ""
}

export default handlePaymentStatus;