import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import { getCommunity } from '../../../components/community-profile/communityProfileActions';
import { getDealDetails } from '../../../components/deals-list/dealsListActions';
import { getExpenseDetails } from '../../../components/expenses-list/expensesListActions';
import { getIncomeDetails } from '../../../components/incomes-list/incomesListActions';
import { getLawDetails } from '../../../components/laws-list/lawsListActions';
import MessageInput from '../../../components/message-input/messageInput';
import { isUserValid } from '../../../utils/isUserValid';
// import ScrollableDiv from '../../../components/scrollable-div/scrollableDiv';
import { getVotingDetails, getVotingHistoryDetails } from '../../../pages/voting-list/votingListActions';
import { API_URL } from '../../../config';
import i18n from '../../../i18n/i18n';
import { encrypt } from '../../../utils/encrypt';
import getStat from '../../../utils/getStat';
import { getPost } from '../../dashboard/dashboard-actions';
import { getUser } from '../../profile/profileActions';
import throttle from 'lodash';
import './userChat.css';
import MessageItem from '../../../components/chats/messageItem';
import ScrollableDivNested from '../../../components/scrollable-div/scrollableDivNested';
import Alert from '../../../utils/alert/Alert';
import { getChat } from '../../messages/messagesActions';
import { AppLoader } from '../../../utils/animations/animations';
import { WHITE_MAIN } from '../../../utils/color-palatte';
import Postcard from '../../../components/post/postcard';
import * as routes from '../../../routes/routes';
import './userChat.css';
import VotingItem from '../../../components/voting/votingItem';
import UserRef from '../../../components/user-ref/UserRef';
import CommunityItem from '../../../components/community/communityItem';
import IncomesItem from '../../../components/incomes-list/incomesItem';
import { actionCreators as actions } from '../../../redux/actions/utils/actions';
import { actionCreators as userActions } from '../../../redux/actions/user/actions';
import { bindActionCreators } from 'redux';

const socket = socketIOClient(API_URL, {
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
});

class UserChat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      sended: false,
      itemLoading: true,
      skipItems: 0,
      limitItems: 10,
      plusPostNumber: 3,
      keepIncreasingCounter: false,
      showModal: false,
      messages: [],
      sendMessageLoader: false,
      removeRefers: false,
      postItem: null,
      votingItem: null,
      lawItem: null,
      expenseItem: null,
      votingHistoryItem: null,
      incomeItem: null,
      dealItem: null,
      userShared: null,
      boxHeight: 56,
    };

    socket.on('receive-messages', this.onReceivedMessage);
    socket.on('send-message-error', this.sendMessageError);
    socket.on('remove-message-error', this.sendMessageError);
    socket.on('refresh', this.onRefreshMessages2);
    socket.on('refresh-once', this.onRefreshMessagesOnce);
    socket.on('on-removed-message', this.onRemovedMessage);
    //   socket.on("disconnect", function(){
    //     console.log("client disconnected from server");
    // });
  }

  _isMounted = false;

  messageInputRef = React.createRef();
  observer = null;

  componentDidMount = async () => {
    socket.connect();
    this._isMounted = true;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const chat = url.searchParams.get('chat');
    const post = url.searchParams.get('post');
    const voting = url.searchParams.get('voting');
    const law = url.searchParams.get('law');
    const deal = url.searchParams.get('deal');
    const income = url.searchParams.get('income');
    const expense = url.searchParams.get('expense');
    const userRef = url.searchParams.get('userRef');
    const votingHistory = url.searchParams.get('voting-history');
    const community = url.searchParams.get('community');

    // console.log('post: ', post);

    if (this._isMounted) {
      if (this.props.location.state) {
        this.setState(
          {
            chatmembers: this.props.location.state.members,
          },
          () => {
            const { user, saveChatUser } = this.props;
            const { chatmembers } = this.state;
            const chatUser =
              chatmembers && chatmembers.length > 0
                ? chatmembers[0]._id === chatmembers[1]._id
                  ? chatmembers[0]
                  : chatmembers.filter((member) => member._id !== user._id)[0]
                : null;
            /**
             * We do not store the entire user object to avoid security leaks,
             * such as access to passwords or other personal data possibly visible
             * from the localStorage
             */
            saveChatUser({
              username: chatUser.username,
              picture: chatUser.picture ?? null,
              active: chatUser.active,
              isVerifiedUser: chatUser.isVerifiedUser,
              _id: chatUser._id,
            });
          }
        );
      } else {
        const chat = await this.getChat();
        this.setState(
          {
            chatmembers: chat?.length > 0 ? chat[0].members : null,
          },
          () => {
            const { user, saveChatUser } = this.props;
            const { chatmembers } = this.state;
            const chatUser =
              chatmembers && chatmembers.length > 0
                ? chatmembers[0]._id === chatmembers[1]._id
                  ? chatmembers[0]
                  : chatmembers.filter((member) => member._id !== user._id)[0]
                : null;
            /**
             * We do not store the entire user object to avoid security leaks,
             * such as access to passwords or other personal data possibly visible
             * from the localStorage
             */
            saveChatUser({
              username: chatUser.username,
              picture: chatUser.picture ?? '',
              active: chatUser.active,
              isVerifiedUser: chatUser.isVerifiedUser,
              _id: chatUser._id,
            });
          }
        );
      }
    }
    // console.log("userRef: ", userRef)
    if (this._isMounted) localStorage.removeItem(chat);
    isUserValid(false, this._isMounted);
    if (this._isMounted) await this.getStats();
    if (this._isMounted) this.fetchMessages();
    if (this._isMounted) if (voting) this.fetchVoting();
    if (this._isMounted) if (votingHistory) this.fetchVotingHistory();
    if (this._isMounted) if (post) this.fetchPost();
    if (this._isMounted) if (law) this.fetchLaw();
    if (this._isMounted) if (deal) this.fetchDeal();
    if (this._isMounted) if (income) this.fetchIncome();
    if (this._isMounted) if (expense) this.fetchExpense();
    if (this._isMounted) if (userRef) this.fetchUserRef();
    if (this._isMounted) if (community) this.fetchCommunity();
    if (this._isMounted) {
      if (this.props.location.state) {
        this.setState({
          chatmembers: this.props.location.state.members,
        });
      }
    }

    this.observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      this.setState({
        boxHeight: boxElem.contentRect.height,
      });
    });
    this.observer.observe(this.messageInputRef.current);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    const userId = localStorage.getItem('userId');
    const { deleteChatUser } = this.props;

    const obj = { userId };
    socket.emit('logout', encrypt(obj));
    // socket.off('receive-messages');
    // socket.off('send-message-error');
    // socket.off('remove-message-error');
    // socket.off('refresh');
    // socket.off('refresh-once');
    // socket.off('on-removed-message');
    deleteChatUser();
  };

  getChat = () => {
    return new Promise(async (resolve, reject) => {
      const { chat } = this.props.match.params;
      try {
        const chatObj = await getChat(chat);
        // console.log('chatObj', chatObj);
        resolve(chatObj);
      } catch (e) {
        const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
        this.showAlert(true, 'error', error);
        reject(e);
      }
    });
  };

  // POST FUNCTIONS

  fetchPost = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const post = url.searchParams.get('post');
      this.setState({ itemLoading: true }, async () => {
        try {
          const response = await getPost(post);
          // console.log('response: ', response);
          this.setState({
            itemLoading: false,
            postItem: response?.length > 0 ? response[0] : response ? response : [],
          });
          resolve();
        } catch (e) {
          this.setState({ itemLoading: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  fetchLaw = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const law = url.searchParams.get('law');
      this.setState({ itemLoading: true }, async () => {
        try {
          const response = await getLawDetails(law);
          this.setState({
            itemLoading: false,
            lawItem: response?.length > 0 ? response[0] : response ? response : [],
          });
          resolve();
        } catch (e) {
          this.setState({ itemLoading: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  fetchDeal = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const deal = url.searchParams.get('deal');
      this.setState({ itemLoading: true }, async () => {
        try {
          const response = await getDealDetails(deal);
          this.setState({
            itemLoading: false,
            dealItem: response?.length > 0 ? response[0] : response ? response : [],
          });
          resolve();
        } catch (e) {
          this.setState({ itemLoading: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  fetchVoting = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const voting = url.searchParams.get('voting');
      this.setState({ itemLoading: true }, async () => {
        try {
          const response = await getVotingDetails(voting);
          this.setState({
            itemLoading: false,
            votingItem: response?.length > 0 ? response[0] : response ? response : [],
          });
          resolve();
        } catch (e) {
          this.setState({ itemLoading: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  fetchCommunity = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const community = url.searchParams.get('community');
      this.setState({ itemLoading: true }, async () => {
        try {
          const response = await getCommunity(community);
          // console.log('fetchCommunity / response', response)
          this.setState({
            itemLoading: false,
            communityItem: response?.length > 0 ? response[0] : response ? response : [],
          });
          resolve();
        } catch (e) {
          this.setState({ itemLoading: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  fetchVotingHistory = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const votingHistory = url.searchParams.get('voting-history');
      this.setState({ itemLoading: true }, async () => {
        try {
          const response = await getVotingHistoryDetails(votingHistory);
          this.setState({
            itemLoading: false,
            votingHistoryItem: response?.length > 0 ? response[0] : response ? response : [],
          });
          resolve();
        } catch (e) {
          this.setState({ itemLoading: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  fetchIncome = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const income = url.searchParams.get('income');
      this.setState({ itemLoading: true }, async () => {
        try {
          const response = await getIncomeDetails(income);
          this.setState({
            itemLoading: false,
            incomeItem: response?.length > 0 ? response[0] : response ? response : [],
          });
          resolve();
        } catch (e) {
          this.setState({ itemLoading: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  fetchExpense = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const expense = url.searchParams.get('expense');
      this.setState({ itemLoading: true }, async () => {
        try {
          const response = await getExpenseDetails(expense);
          this.setState({
            itemLoading: false,
            expenseItem: response?.length > 0 ? response[0] : response ? response : [],
          });
          resolve();
        } catch (e) {
          this.setState({ itemLoading: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  fetchUserRef = () => {
    return new Promise(async (resolve, reject) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const userRef = url.searchParams.get('userRef');
      this.setState({ itemLoading: true }, async () => {
        try {
          const response = await getUser(userRef);
          this.setState({
            itemLoading: false,
            userShared: response,
          });
          resolve();
        } catch (e) {
          this.setState({ itemLoading: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
          reject(e);
        }
      });
    });
  };

  getStats = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const positions = await getStat('positions');
        const users = await getStat('users');
        const positionsPeriodDays = await getStat('position-expires-period-days');
        const votingExpiresPeriodDays = await getStat('voting-expires-period-days');
        this.setState({ stats: { positions, users }, positionsPeriodDays, votingExpiresPeriodDays });
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  onReceivedMessage = (newMessages) => {
    const { messages } = this.state;
    const finalMessages = messages
      .concat(newMessages)
      .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
      .sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
    this.setState({
      messages: finalMessages,
      keepIncreasingCounter: finalMessages.length > 0,
      fetching: false,
      sendMessageLoader: false,
    });
  };

  fetchMessages = async (messageId) => {
    // console.log("entro a fetchMessages()")
    const { user } = this.props;
    const { skipItems, limitItems, fetching, messages, chatmembers } = this.state;
    const { chat } = this.props.match.params;
    if (!fetching) {
      this.setState({ fetching: true }, async () => {
        try {
          const chatUser =
            chatmembers?.length > 0
              ? chatmembers.filter((member) => member._id !== user._id).length > 0
                ? chatmembers.filter((member) => member._id !== user._id)[0]
                : chatmembers.filter((member) => member._id === user._id).length > 1
                ? chatmembers.filter((member) => member._id === user._id)[0]
                : null
              : null;
          if (messageId) {
            this.setState({
              messages: messages.filter((item) => item._id !== messageId),
            });
          }

          const userId = localStorage.getItem('userId');

          const obj = { userId, chatId: chat, skipItems, limitItems, recipentUserId: chatUser._id };
          // console.log("entro a fetchMessages() / obj: ", obj)
          socket.emit('on-enter-chat', encrypt(obj));
        } catch (e) {
          this.setState({ fetching: false });
          const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
          this.showAlert(true, 'error', error);
        }
      });
    }
  };

  remove = (messageId) => {
    const { user } = this.props;
    socket.emit(
      'remove-message',
      encrypt({
        messageId,
        userId: user._id,
      })
    );
  };

  removeForAll = (messageId, recipentUserId) => {
    const { user } = this.props;
    socket.emit('remove-for-all-message', encrypt({ messageId, user: user._id, recipentUserId: recipentUserId }));
  };

  addMessage = (search) => {
    return new Promise(async (resolve, reject) => {
      const { sended } = this.state;
      const url_string = window.location.href;
      const url = new URL(url_string);
      const post = url.searchParams.get('post');
      const voting = url.searchParams.get('voting');
      const law = url.searchParams.get('law');
      const deal = url.searchParams.get('deal');
      const income = url.searchParams.get('income');
      const expense = url.searchParams.get('expense');
      const userRef = url.searchParams.get('userRef');
      const votingHistory = url.searchParams.get('voting-history');
      const community = url.searchParams.get('community');
      const { chat } = this.props.match.params;
      const { user } = this.props;
      const { removeRefers, chatmembers } = this.state;
      const isRef = voting || post || law || deal || community || expense || income || votingHistory || userRef;
      if (search || (isRef && !sended)) {
        const chatUser =
          chatmembers?.length > 0
            ? chatmembers.filter((member) => member._id !== user._id).length > 0
              ? chatmembers.filter((member) => member._id !== user._id)[0]
              : chatmembers.filter((member) => member._id === user._id).length > 1
              ? chatmembers.filter((member) => member._id === user._id)[0]
              : null
            : null;
        // console.log('chatmembers', chatmembers);
        const itemText = voting
          ? i18n.t('MESSAGES.voting')
          : post
          ? i18n.t('MESSAGES.post')
          : law
          ? i18n.t('MESSAGES.law')
          : deal
          ? i18n.t('MESSAGES.deal')
          : expense
          ? i18n.t('MESSAGES.expense')
          : income
          ? i18n.t('MESSAGES.income')
          : userRef
          ? i18n.t('MESSAGES.userRef')
          : votingHistory
          ? i18n.t('MESSAGES.votingHistory')
          : community
          ? i18n.t('MESSAGES.community')
          : '';

        let data = {
          content: isRef && !removeRefers && !sended ? itemText : search,
          user: user._id,
          chat: chat,
          recipentUserId: chatUser._id,
        };
        if (voting && !sended) {
          data.voting = voting;
        }
        if (post && !sended) {
          data.post = post;
        }
        if (law && !sended) {
          data.law = law;
        }
        if (deal && !sended) {
          data.deal = deal;
        }
        if (expense && !sended) {
          data.expense = expense;
        }
        if (community && !sended) {
          data.community = community;
        }
        if (income && !sended) {
          data.income = income;
        }
        if (userRef && !sended) {
          data.userRef = userRef;
        }
        if (votingHistory && !sended) {
          data.votingHistory = votingHistory;
        }

        // console.log('data: ', data);
        this.setState({ sendMessageLoader: true }, () => {
          try {
            socket.emit('send-message', encrypt(data));
            this.setState({
              skipItems: 0,
              limitItems: 10,
              removeRefers: true,
            });
            this.props.history.replace(`${routes.USER_CHAT}/${chat}`);
            resolve();
            this.setState({ sended: true });
          } catch (e) {
            console.log('addMessage / error: ', e);
            const error = e?.data?.error?.message ?? i18n.t('GENERAL_ERRORS.serverError');
            this.showAlert(true, 'error', error);
            reject(e);
          }
        });
      }
    });
  };

  sendMessageError = (e) => {
    this.setState({ sendMessageLoader: false });
    console.log('send message error', e);
    if (
      e?.details?.code === 'user-not-verified' ||
      e?.data?.error?.code === 'user-not-verified' ||
      e?.data?.error?.details?.code === 'user-not-verified'
    ) {
      this.props.openVerifyModal();
      return;
    }
    const error = e?.data?.error?.message ?? e?.data?.message ?? e?.message ?? i18n.t('GENERAL_ERRORS.serverError');
    this.showAlert(true, 'error', error);
  };

  onRefreshMessages2 = () => {
    this.setState({ skipItems: 0, limitItems: 10, sendMessageLoader: false }, () => {
      this.fetchMessages();
    });
  };

  onRefreshMessagesOnce = ({ messages }) => {
    // console.log("onRefreshMessagesOnce / messages: ", messages)
    const finalMessages = this.state.messages.map((currentMessage) => {
      if (messages?.filter((comingMessage) => comingMessage._id === currentMessage._id).length > 0) {
        return {
          ...currentMessage,
          recipentUserSeen: true,
        };
      } else {
        return currentMessage;
      }
    });
    this.setState({
      messages: finalMessages,
      keepIncreasingCounter: finalMessages.length > 0,
      fetching: false,
      sendMessageLoader: false,
    });
  };

  onRemovedMessage = (messageId) => {
    this.setState({ skipItems: 0, limitItems: 10, sendMessageLoader: false }, () => {
      this.fetchMessages(messageId);
    });
  };

  onRefreshMessages = () => {
    this.setState({ skipItems: 0, limitItems: 10, messages: [], sendMessageLoader: false }, () => {
      this.getStats();
      this.fetchMessages();
    });
  };

  onEndReached = () => {
    if (!this._isMounted) return;
    const { skipItems, limitItems, plusPostNumber, keepIncreasingCounter } = this.state;
    if (keepIncreasingCounter) {
      this.setState(
        {
          skipItems: skipItems + plusPostNumber,
          limitItems: limitItems + plusPostNumber,
        },
        () => {
          this.fetchMessages();
        }
      );
    } else {
      this.fetchMessages();
    }
    // console.log('end!')
  };

  throtled = throttle(async () => {
    this.onEndReached();
  }, 1000);

  showAlert = (isOpen, type, content) => {
    this.setState({
      isAlertOpen: isOpen,
      alertType: type,
      alertContent: content,
    });
  };

  render() {
    const {
      messages,
      fetching,
      stats,
      sendMessageLoader,
      itemLoading,
      // removeRefers,
      postItem,
      votingItem,
      lawItem,
      expenseItem,
      incomeItem,
      dealItem,
      userShared,
      votingHistoryItem,
      communityItem,
      sended,
      positionsPeriodDays,
      votingExpiresPeriodDays,
    } = this.state;

    const { user, darkMode } = this.props;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const post = url.searchParams.get('post');
    const voting = url.searchParams.get('voting');
    const law = url.searchParams.get('law');
    const deal = url.searchParams.get('deal');
    const income = url.searchParams.get('income');
    const expense = url.searchParams.get('expense');
    const userRef = url.searchParams.get('userRef');
    const votingHistory = url.searchParams.get('voting-history');
    const community = url.searchParams.get('community');
    const isRef = (voting || post || law || deal || community || expense || income || votingHistory || userRef) && !sended;

    const { chat } = this.props.match.params;

    // const {
    //   chat,
    //   post,
    //   voting,
    //   law,
    //   deal,
    //   income,
    //   expense,
    //   userRef,
    //   votingHistory,
    //   community,
    // } = this.props.match.params;

    // const chatUser =
    //   chat?.members?.length > 0
    //     ? chat.members.filter((member) => member._id !== user._id)[0]
    //     : null;

    const positions = stats?.positions;
    const users = stats?.users;

    // console.log('communityItem', communityItem);

    return (
      <div>
        <ScrollableDivNested
          reversed
          style={{
            height: `calc(100vh - ${100 + this.state.boxHeight}px)`,
            position: 'relative',
          }}
          onEndReach={this.onEndReached}
        >
          {messages?.length > 0
            ? messages.map((msgs, idx) => (
                <MessageItem
                  userId={user._id}
                  darkMode={darkMode}
                  positions={stats?.positions ?? null}
                  item={msgs}
                  key={idx}
                  user={user}
                  stats={{ positions, user }}
                  history={this.props.history}
                  remove={this.remove}
                  removeForAll={this.removeForAll}
                  positionsPeriodDays={positionsPeriodDays}
                  votingExpiresPeriodDays={votingExpiresPeriodDays}
                />
              ))
            : null}
          {messages?.length <= 5 ? null : (
            <div style={{ height: 100 }}>{fetching && <AppLoader color={darkMode && WHITE_MAIN} />}</div>
          )}
        </ScrollableDivNested>
        <div style={{ width: '100%', boxSizing: 'border-box', padding: '0 10px', paddingTop: '10px', position: 'relative' }}>
          {isRef ? (
            <div className='refContainer'>
              {postItem && !sended ? (
                <Postcard
                  item={postItem}
                  user={user}
                  darkMode={darkMode}
                  positions={stats?.positions}
                  stats={{ positions, users }}
                  removeRefers
                  onClick={() => this.props.history.push(`${routes.POST_ROUTE}/${postItem._id}`)}
                />
              ) : null}
              {community && !sended && communityItem && stats ? (
                <CommunityItem
                  inner
                  darkMode={darkMode}
                  item={communityItem}
                  user={user}
                  positions={stats.positions}
                  onClick={() => this.props.history.push(`${routes.COMMUNITY_PROFILE_ROUTE}/${communityItem._id}`)}
                />
              ) : null}
              {!sended && dealItem && stats ? (
                <VotingItem
                  item={dealItem}
                  user={user}
                  voting={stats}
                  darkMode={darkMode}
                  positionsPeriodDays={positionsPeriodDays}
                  votingExpiresPeriodDays={votingExpiresPeriodDays}
                  isDone
                  isDeal
                  onClick={() => this.props.history.push(`${routes.DEALS_DETAILS_ROUTE}/${dealItem._id}`)}
                  hideMenu
                />
              ) : null}
              {!sended && incomeItem && stats ? (
                <IncomesItem
                  darkMode={darkMode}
                  item={incomeItem}
                  user={user}
                  hideMenu
                  onClick={() => this.props.history.push(`${routes.GG_INCOMES_DETAILS_ROUTE}/${incomeItem._id}`)}
                />
              ) : null}
              {!sended && expenseItem && stats ? (
                <IncomesItem
                  isExpense
                  darkMode={darkMode}
                  item={expenseItem}
                  user={user}
                  hideMenu
                  onClick={() => this.props.history.push(`${routes.GG_EXPENSES_DETAILS_ROUTE}/${expenseItem._id}`)}
                />
              ) : null}
              {votingHistory && !sended && votingHistoryItem && stats ? (
                <VotingItem
                  isHistory
                  item={votingHistoryItem}
                  darkMode
                  user={user}
                  voting={stats}
                  hideMenu
                  onClick={() => this.props.history.push(`${routes.VOTING_HISTORY}/${votingHistoryItem._id}`)}
                  positionsPeriodDays={positionsPeriodDays}
                  votingExpiresPeriodDays={votingExpiresPeriodDays}
                />
              ) : null}
              {!sended && lawItem && stats ? (
                <VotingItem
                  item={lawItem}
                  user={user}
                  voting={stats}
                  darkMode={darkMode}
                  positionsPeriodDays={positionsPeriodDays}
                  votingExpiresPeriodDays={votingExpiresPeriodDays}
                  isDone
                  isLaw
                  onClick={() => this.props.history.push(`${routes.LAWS_DETAILS_ROUTE}/${lawItem._id}`)}
                  hideMenu
                />
              ) : null}
              {!sended && votingItem && stats ? (
                <VotingItem
                  item={votingItem}
                  user={user}
                  voting={stats}
                  darkMode={darkMode}
                  positionsPeriodDays={positionsPeriodDays}
                  votingExpiresPeriodDays={votingExpiresPeriodDays}
                  onClick={() => this.props.history.push(`${routes.VOTING_DETAILS_ROUTE}/${votingItem._id}`)}
                />
              ) : null}
              {!sended && userShared && stats ? (
                <div className='refContainer'>
                  <UserRef
                    style={{ margin: 'auto', marginTop: 10, marginBottom: 10 }}
                    item={userShared}
                    history={this.props.history}
                    darkMode={darkMode}
                    positions={stats.positions}
                  />
                </div>
              ) : null}
              {!fetching && !itemLoading && (
                <div style={{ width: '100%', margin: 10, display: 'flex' }}>
                  <button
                    className='CancelRef__Button'
                    onClick={() => {
                      this.props.history.replace(`${routes.USER_CHAT}/${chat}`);
                      this.setState({ sended: true });
                    }}
                  >
                    {i18n.t('MESSAGES.USER_CHAT.cancel')}
                  </button>
                </div>
              )}
            </div>
          ) : null}
          <MessageInput
            darkMode={darkMode}
            fetching={sendMessageLoader}
            user={''}
            isRef={isRef}
            ref={this.messageInputRef}
            addMessage={this.addMessage}
            placeholder={i18n.t('MESSAGES.USER_CHAT.placeholder')}
          />
        </div>
        <Alert
          isOpen={this.state.isAlertOpen}
          type={this.state.alertType}
          content={this.state.alertContent}
          onRequestClose={() =>
            this.setState({
              isAlertOpen: false,
            })
          }
        />
      </div>
    );
  }
}

const UserChatWithRouter = withRouter(UserChat);

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveChatUser: bindActionCreators(actions.saveChatUser, dispatch),
    deleteChatUser: bindActionCreators(actions.deleteChatUser, dispatch),
    openVerifyModal: bindActionCreators(userActions.openVerifyModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserChatWithRouter);
