import React from "react";
import { connect } from "react-redux";
import "./services.css";
// import EducationIcon from "../../assets/services/templates/education_Icon.svg";
// import HealtIcon from "../../assets/services/templates/healt_icon.png";
// import EntertainamentIcon from "../../assets/services/templates/entertainament_icon.png";
// import OtherServiceIcon from "../../assets/services/templates/other_services_icon.png";
import ServiceItem from "../../components/services/serviceItem";
import { NotFoundItem } from "../../components/not-found-item/notFoundItem";
import SearchInput from "../../utils/app-inputs/search-input/searchInput";
import i18n from "../../i18n/i18n";

class Services extends React.Component {
  render() {
    const {
      // user,
      darkMode,
    } = this.props;

    const ObjServ = [
      // {
      //   img: EducationIcon,
      //   title: 'Education',
      //   content: 'These are services applied to the academic field, this includes: investments for primary and secondary education, libraries, application of university scholarships and professional education.',
      //   name: 'ESTEBAN CONTRERAS',
      //   status: 'President',
      // },
      // {
      //   img: HealtIcon,
      //   title: 'HEALTH CARE',
      //   content: 'These are vital services for clinical and hospital care, distribution and marketing of drugs, vaccinations, and medical interventions of greater relevance.',
      //   name: 'ARIADNA MORA',
      //   status: 'Vice-President',
      // },
      // {
      //   img: EntertainamentIcon,
      //   title: 'ENTERTAIMENT',
      //   content: 'These services are intended for the enjoyment of the community, normally related to the creation or modification of public spaces for recreation and leisure.',
      //   name: 'ARIADNA MORA',
      //   status: 'Vice-President',
      // },
      // {
      //   img: OtherServiceIcon,
      //   title: 'OTHER SERVICES',
      //   content: 'These services are linked to technology or infrastructures such as the internet, space research, geographic management, among others.',
      //   name: 'JOSEPH CRISTOFF',
      //   status: 'Citizen',
      // },
    ];

    return (
      <div className="Service__Container">
        <div className="Service__Search">
          <SearchInput
            darkMode={darkMode}
            placeholder={i18n.t("SERVICES.searchInput")}
          />
        </div>
        {ObjServ.length > 0 ? (
          ObjServ.map((item, i) => <ServiceItem {...item} key={i} />)
        ) : (
          <div className="Service__NFI">
            <NotFoundItem
              type="services"
              isBlue={darkMode}
              text={i18n.t("SERVICES.notServicesFound")}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, darkMode } = state.user;
  return {
    user,
    darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
